import { gql } from 'types/graphql-schemas'

export const UPDATE_SUBJECT = gql(/* GraphQL */ `
  mutation UpdateSubject($id: String!, $data: GQLUpdateSubjectDto!) {
    updateSubject(id: $id, data: $data) {
      id
    }
  }
`)

export const CREATE_SUBJECT = gql(/* GraphQL */ `
  mutation CreateSubject($data: CreateSubjectDto!) {
    createSubject(data: $data) {
      id
    }
  }
`)

export const CREATE_SUBJECT_FROM_TYPE = gql(/* GraphQL */ `
  mutation CreateSubjectFromType($type: String!, $data: CreateSubjectDto!) {
    createSubjectFromType(type: $type, data: $data) {
      id
      railsId
    }
  }
`)

export const BULK_CREATE_SUBJECTS_FROM_TYPE = gql(/* GraphQL */ `
  mutation BulkCreateSubjectsFromType(
    $type: String!
    $data: [CreateSubjectDto!]!
  ) {
    bulkCreateSubjectsFromType(type: $type, data: $data) {
      id
    }
  }
`)

export const DELETE_SUBJECT = gql(/* GraphQL */ `
  mutation DeleteSubject($id: String!) {
    deleteSubject(id: $id) {
      id
    }
  }
`)

export const BULK_IMPORT_SUBJECTS = gql(/* GraphQL */ `
  mutation BulkImportSubjects($data: [BulkCreateSubjectDto!]!) {
    bulkImportSubjects(data: $data) {
      id
      railsId
    }
  }
`)

export const VALIDATE_BULK_IMPORT_SUBJECTS = gql(/* GraphQL */ `
  mutation ValidateBulkImportSubjects($data: [BulkCreateSubjectDto!]!) {
    validateBulkImportSubjects(data: $data) {
      ... on ValidateResult {
        name {
          ...MainAttributeResult
        }
        typeIdentifier {
          ...MainAttributeResult
        }
        parentSubject {
          ...MainAttributeResult
        }
        attributes {
          ...CustomAttributeResult
        }
      }

      ... on ValidateManyResult {
        names {
          name {
            ...MainAttributeResult
          }
          typeIdentifier {
            ...MainAttributeResult
          }
          attributes {
            ...CustomAttributeResult
          }
        }

        parentSubject {
          ...MainAttributeResult
        }
      }
    }
  }
`)

export const EXPORT_SUBJECTS = gql(/* GraphQL */ `
  mutation BulkExportSubjects($query: String) {
    bulkExportSubjects(query: $query) {
      url
    }
  }
`)

export const ADD_SUBJECT_TAGS = gql(/* GraphQL */ `
  mutation AddSubjectTags($id: String!, $tags: [TagDto!]!) {
    addSubjectTags(id: $id, tags: $tags) {
      id
      tags {
        tagId
        tagName
      }
    }
  }
`)

export const REMOVE_SUBJECT_TAGS = gql(/* GraphQL */ `
  mutation RemoveSubjectTags($id: String!, $tags: [String!]!) {
    removeSubjectTags(id: $id, tags: $tags) {
      id
      tags {
        tagId
        tagName
      }
    }
  }
`)
