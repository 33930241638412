import classNames from 'classnames'
import Timeline from 'components/Timeline'
import dayjs from 'dayjs'
import { ReactNode, useCallback, useMemo } from 'react'
import type { IntlShape } from 'react-intl'
import { FormattedDate, FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Column } from 'ui/Table/types'

import sortBy from 'lodash/sortBy'

import AttachmentPreview from 'components/AttachmentPreview'
import { AttachmentPreviewSize } from 'components/AttachmentPreview/AttachmentPreview'
import MoreFiles from 'components/AttachmentPreview/MoreFiles'
import ImagePreview from 'components/ImagePreview'
import { ScheduleType } from 'components/ScheduleUpdateRadio/ScheduleUpdateRadio'
import StatusTag from 'components/StatusTag/StatusTag'
import Tooltip from 'components/Tooltip'
import {
  QuickbooksIcon,
  XeroIcon,
} from 'components/UpdateContent/UpdateContent.styles'
import UpdateFactory from 'components/UpdateFactory'
import UpdateIconFactory from 'components/UpdateIconFactory'
import { ChatLabel } from 'containers/Chat/components/ChatWidgets'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getHasAnErrorConnectingTheUser } from 'features/chatSlice'
import { IntegrationStatus } from 'utils/constants/integrations'
import { MixedContent, isFileFormatImage } from 'utils/types/files'
import {
  CreateUpdateRouteState,
  UpdateCreatedFrom,
} from 'utils/types/updateForm'

import {
  AccountingReportType,
  BalanceDateType,
  PeriodType,
} from 'utils/constants/accounting'
import { maxSize } from 'utils/constants/breakpoint'
import { UpdateTabId, UpdateTypes } from 'utils/constants/updates'
import { useIsPreviewingDraftUpdate } from 'utils/hooks/useIsPreviewingDraftUpdate'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { TopicEntity } from 'utils/types/chatTopicEntity'
import { UpdateType } from 'utils/types/common'
import {
  DraftHashAttributes,
  EmailUpdate,
  IUEReportItem,
  IndexUpdate,
  Update,
  UpdateInfoItemWithId,
} from 'utils/types/update'
import { EmailRecipientStatus } from 'utils/constants/emailRecipientStatus'
import { isIndexFileContent } from 'utils/functions/files'
import { SubjectMatterType } from 'api/UpdateService'
import ExpandArrow from 'ui/Table/Row/ExpandArrow'
import {
  getColorForEmailStatus,
  getIconByUpdateType,
  removeHtmlTags,
  getUpdateType,
} from 'utils/functions/updates'
import Chip from 'components/Chip'
import { getUpdateIcon } from 'components/UpdateIconFactoryV2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChipList from 'containers/Files/components/ChipList'
import { HoldingType } from 'utils/hooks/useOnClickRedirect'
import { StyledIcon } from 'containers/PortfolioDetail/UpdatesTable/UpdatesTable.styles'
import { DraggableOption } from 'components/DragAndDropMenu/DragAndDropMenu'
import theme from 'utils/theme'
import { color } from '../colors'
import {
  Company,
  CompanyAvatar,
  CompanyName,
  DataType,
  DescriptionText,
  UpdateTitle,
  getFieldValue,
} from './renderFundHoldingsHelper'

const sortByDate = (updates) => {
  return sortBy(updates, (el) => -el.date)
}

export const Cell = styled.div`
  color: ${color('darkGray')};
  font-size: 1.4rem;
`

const DUMMY_DATE = '0000-01-01'

// TODO: remove this function after getting rid of the old Updates Feed
export const displayUpdates = ({
  updatesGroupedByDate,
  intl,
  dateField = 'date',
  updatesWithCompany = true,
  withPortfolio,
  selectionMode,
}) => {
  const updatesToDisplay: ReactNode[] = []
  const { today, yesterday, byMonths } = updatesGroupedByDate

  if (today?.length) {
    updatesToDisplay.push(
      <Timeline title={intl.messages['companyProfile.today']} key="today">
        {sortBy(today, (el) => -el.date).map((update) => {
          const updateType = getUpdateType(update.type)
          const withCompany =
            updatesWithCompany && typeof update.companyDatum === 'object'
              ? update.companyDatum
              : {}

          return (
            <Timeline.Item
              key={update.id}
              updateType={updateType}
              withPortfolio={withPortfolio}
              withCompany={withCompany}
              update={update}
              selectionMode={selectionMode}
            >
              <UpdateFactory update={update} />
            </Timeline.Item>
          )
        })}
      </Timeline>
    )
  }

  if (yesterday?.length) {
    updatesToDisplay.push(
      <Timeline
        title={intl.messages['companyProfile.yesterday']}
        key="yesterday"
      >
        {sortBy(yesterday, (el) => -el.date).map((update) => {
          const updateType = getUpdateType(update.type)
          const withCompany =
            updatesWithCompany && typeof update.companyDatum === 'object'
              ? update.companyDatum
              : {}

          return (
            <Timeline.Item
              key={update.id}
              updateType={updateType}
              withPortfolio={withPortfolio}
              withCompany={withCompany}
              update={update}
              selectionMode={selectionMode}
            >
              <UpdateFactory update={update} />
            </Timeline.Item>
          )
        })}
      </Timeline>
    )
  }
  if (Object.keys(byMonths).length) {
    Object.keys(byMonths).forEach((key) => {
      const monthDate = dayjs(byMonths[key][0][dateField])
      let month
      if (monthDate.year() === dayjs().year()) {
        month = monthDate.format('MMMM')
      } else {
        month = monthDate.format('MMMM, YYYY')
      }
      if (!monthDate.isValid()) {
        return
      }
      updatesToDisplay.push(
        <Timeline title={month} key={`${byMonths[key][0].id}`}>
          {sortByDate(byMonths[key]).map((update) => {
            const updateType = getUpdateType(update.type)
            const withCompany =
              updatesWithCompany && typeof update.companyDatum === 'object'
                ? update.companyDatum
                : {}

            return (
              <Timeline.Item
                key={update.id}
                updateType={updateType}
                withPortfolio={withPortfolio}
                withCompany={withCompany}
                update={update}
                selectionMode={selectionMode}
              >
                <UpdateFactory update={update} />
              </Timeline.Item>
            )
          })}
        </Timeline>
      )
    })
  }
  return updatesToDisplay
}

export const displayAttachments = (
  attachmentsInUpdate: MixedContent[],
  size: AttachmentPreviewSize = 'small',
  onClick?: (content: MixedContent) => void,
  showPaperclipOnEmails?: boolean
) => {
  const onPreviewClick = (e: React.MouseEvent, content: MixedContent) => {
    e.stopPropagation()
    onClick?.(content)
  }
  const maxAttachmentsToDisplay = 6
  let attachmentsToDisplay = attachmentsInUpdate
  let shouldDisplayMoreAttachments = false
  let moreAttachmentsAmount = 0

  if (attachmentsInUpdate.length > maxAttachmentsToDisplay) {
    shouldDisplayMoreAttachments = true
    attachmentsToDisplay = attachmentsInUpdate.slice(
      0,
      maxAttachmentsToDisplay - 1
    )
    moreAttachmentsAmount = attachmentsInUpdate.slice(
      maxAttachmentsToDisplay - 1
    ).length
  }

  const attachmentsComponents = attachmentsToDisplay.map((content) => {
    const isFileContent = isIndexFileContent(content)
    if (isFileContent && isFileFormatImage(content.fileFormat)) {
      return (
        <ImagePreview
          key={content.id}
          src={content.url}
          title={content.fileName}
          alt="attachment"
          onClick={(e: React.MouseEvent) => onPreviewClick(e, content)}
        />
      )
    }

    return (
      <AttachmentPreview
        key={content.id}
        content={content}
        size={size}
        onClick={(e: React.MouseEvent) => onPreviewClick(e, content)}
        showPaperclipOnEmails={showPaperclipOnEmails}
      />
    )
  })

  if (shouldDisplayMoreAttachments) {
    attachmentsComponents.push(
      <MoreFiles key="more-files" amount={moreAttachmentsAmount} />
    )
  }

  return attachmentsComponents
}

export const getAccountingReportDropdownOptions = (integrationStatus) => {
  return [
    {
      id: AccountingReportType.XERO_BALANCE_SHEET,
      label: 'Balance Sheet',
      icon: (
        <XeroIcon>
          <UpdateIconFactory updateType="xero" />
        </XeroIcon>
      ),
    },
    {
      id: AccountingReportType.XERO_PROFIT_AND_LOSS,
      label: 'Profit And Loss',
      icon: (
        <XeroIcon>
          <UpdateIconFactory updateType="xero" />
        </XeroIcon>
      ),
    },
    {
      id: AccountingReportType.QUICKBOOKS_BALANCE_SHEET,
      label: 'Balance Sheet',
      icon: (
        <QuickbooksIcon>
          <UpdateIconFactory updateType="quickbooks" />
        </QuickbooksIcon>
      ),
    },
    {
      id: AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS,
      label: 'Profit And Loss',
      icon: (
        <QuickbooksIcon>
          <UpdateIconFactory updateType="quickbooks" />
        </QuickbooksIcon>
      ),
    },
  ].filter((report) => {
    if (report.id.includes('XERO')) {
      return integrationStatus.xero?.status === IntegrationStatus.CONNECTED
    }
    if (report.id.includes('QUICKBOOKS')) {
      return (
        integrationStatus.quickbooks?.status === IntegrationStatus.CONNECTED
      )
    }
    return false
  })
}

export const getBalanceDateDropdownOptions = (scheduleType, intl) => {
  return [
    {
      id: BalanceDateType.SCHEDULED_DATE,
      label:
        scheduleType === ScheduleType.SEND_NOW
          ? intl.messages['general.today']
          : intl.messages['general.scheduledDate'],
    },
    {
      id: BalanceDateType.END_OF_LAST_MONTH,
      label: intl.messages['general.endOfLastMonth'],
    },
    {
      id: BalanceDateType.END_OF_LAST_QUARTER,
      label: intl.messages['general.endOfLastQuarter'],
    },
    {
      id: BalanceDateType.END_OF_LAST_FINANCIAL_YEAR,
      label: intl.messages['general.endOfLastFinancialYear'],
    },
  ]
}

export const recipientsTableColumns = (styles) => [
  {
    sortKey: 'user.email',
    label: <FormattedMessage id="showEmail.recipients" />,
    align: 'left',
    flex: 3,
    cellRenderer: ({ rowData }) => (
      <div className={styles.emailContainer}>
        <div className={styles.avatar}>{rowData?.user?.email[0]}</div>
        <div className={styles.emailColumn} title={rowData?.user?.email}>
          {rowData?.user?.email}
        </div>
      </div>
    ),
  },
  {
    sortKey: 'currentStatus.receivedAt',
    label: <FormattedMessage id="showEmail.dateTime" />,
    align: 'left',
    flex: 2,
    cellRenderer: ({ rowData }) => (
      <span className={styles.date}>
        {dayjs(rowData?.currentStatus?.receivedAt).format(
          'dd. DD MMM. - h:mm A'
        )}
      </span>
    ),
  },
  {
    sortKey: 'currentStatus.status',
    label: <FormattedMessage id="showEmail.state" />,
    align: 'left',
    flex: 1,
    cellRenderer: ({ rowData, rowIndex }) => {
      const status =
        rowData?.currentStatus?.status ?? EmailRecipientStatus.ERROR

      const errorDescription = rowData?.currentStatus?.description ?? (
        <FormattedMessage id="showEmail.emailStatusError" />
      )
      return (
        <Tooltip
          key={`recipientStatus_${rowIndex}`}
          id={`recipientStatus_${rowIndex}`}
          place="right"
          text={
            status === EmailRecipientStatus.ERROR ? (
              errorDescription
            ) : (
              <FormattedMessage id={`showEmail.recipientStatus.${status}`} />
            )
          }
        >
          <StatusTag color={getColorForEmailStatus(status)}>{status}</StatusTag>
        </Tooltip>
      )
    },
  },
]

export const recipientsTableExpandedColumns = (styles) => [
  {
    sortDisabled: true,
    label: <FormattedMessage id="showEmail.recipients" />,
    align: 'left',
    flex: 3,
    cellRenderer: ({ rowData }) => {
      if (rowData.status === 'error') {
        return <span className={styles.statusText}>{rowData?.description}</span>
      }

      return (
        <span className={styles.statusText}>
          <FormattedMessage
            id={`showEmail.recipientStatus.${rowData?.status}`}
          />
        </span>
      )
    },
  },
  {
    sortDisabled: true,
    label: <FormattedMessage id="showEmail.dateTime" />,
    align: 'left',
    flex: 2,
    cellRenderer: ({ rowData }) => (
      <span className={classNames(styles.date, styles.dateExpanded)}>
        {dayjs(rowData?.receivedAt).format('dd. DD MMM. - h:mm A')}
      </span>
    ),
  },
  {
    sortDisabled: true,
    label: <FormattedMessage id="showEmail.state" />,
    align: 'left',
    flex: 1,
    cellRenderer: ({ rowData }) => (
      <StatusTag withBorder textColor={getColorForEmailStatus(rowData?.status)}>
        {rowData?.status}
      </StatusTag>
    ),
  },
]

export const getPeriodsToCompareDropdownOptions = (intl: IntlShape) => {
  return [
    {
      id: PeriodType.NONE,
      label: intl.formatMessage({ id: 'general.none' }),
    },
    {
      id: PeriodType.PREVIOUS_1_PERIOD,
      label: intl.formatMessage({
        id: 'general.previous1Period',
      }),
    },
    {
      id: PeriodType.PREVIOUS_2_PERIOD,
      label: intl.formatMessage({
        id: 'general.previous2Period',
      }),
    },
    {
      id: PeriodType.PREVIOUS_3_PERIOD,
      label: intl.formatMessage({
        id: 'general.previous3Period',
      }),
    },
  ]
}

export const getUpdateFormTitle = ({
  updateCreatedFrom,
  holding,
  portfolio,
  investor,
  investmentVehicle,
}: CreateUpdateRouteState): React.ReactNode => {
  switch (updateCreatedFrom) {
    case UpdateCreatedFrom.HOLDING:
      return holding!.name
    case UpdateCreatedFrom.PORTFOLIO:
      return portfolio!.name
    case UpdateCreatedFrom.INVESTOR:
      return investor!.name
    case UpdateCreatedFrom.INVESTMENT_VEHICLE:
      return investmentVehicle!.name
    case UpdateCreatedFrom.UPDATES:
      return <FormattedMessage id="updates.header.titles.updates" />
    case UpdateCreatedFrom.ORGANIZATION_PROFILE:
      return <FormattedMessage id="updates.header.titles.organization" />
    default:
      return <FormattedMessage id="updates.header.titles.updates" />
  }
}

interface Tab {
  id: string
  title: React.ReactNode
  icon?: string[]
}

interface GetUpdateTabsProps {
  updateType: UpdateType
  isEditMode: boolean
  isShowMode: boolean
  hasEditPermissions: boolean
  hasPublishedUpdate: boolean
  showIcons: boolean
  hasDraftUpdate?: boolean
  hasChatFeature?: boolean
  topic?: TopicEntity
  update?: Update
}

export const useGetUpdateTabs = ({
  updateType,
  isEditMode = false,
  isShowMode = false,
  hasEditPermissions = false,
  hasDraftUpdate = false,
  hasPublishedUpdate = false,
  hasChatFeature = true,
  showIcons = true,
  topic,
  update,
}: GetUpdateTabsProps) => {
  const { matches: isMobile } = useMediaQuery(maxSize.sm)
  const isPreviewingDraft = useIsPreviewingDraftUpdate()
  const publishedWithoutDraft =
    hasPublishedUpdate && !hasDraftUpdate && !isEditMode
  const showAnalytics =
    (publishedWithoutDraft && hasEditPermissions) ||
    (!isPreviewingDraft && (isEditMode || isShowMode) && hasEditPermissions)
  const showLog =
    publishedWithoutDraft || ((isEditMode || isShowMode) && !isPreviewingDraft)
  const hasAnErrorConnectingTheUser = useAppSelector(
    getHasAnErrorConnectingTheUser
  )
  const generalTitle = useCallback(() => {
    let generalTabTitle

    if (isEditMode) {
      generalTabTitle = (
        <FormattedMessage id={`updates.editTitles.${updateType}`} />
      )
    } else if (isShowMode) {
      generalTabTitle = <FormattedMessage id="updates.general" />
    } else {
      generalTabTitle = (
        <FormattedMessage id={`updates.createTitles.${updateType}`} />
      )
    }

    return generalTabTitle
  }, [isEditMode, isShowMode, updateType])

  const getChatLabel = useCallback(() => {
    if (hasAnErrorConnectingTheUser) {
      return <FormattedMessage id="chat.chat" />
    }

    return <ChatLabel topic={topic} update={update} />
  }, [hasAnErrorConnectingTheUser, topic, update])

  const result = useMemo(() => {
    const tabs: Tab[] = [
      {
        id: UpdateTabId.GENERAL,
        title: isMobile ? '' : generalTitle(),
        icon: [
          'fal',
          isShowMode ? 'newspaper' : getIconByUpdateType(updateType),
        ],
      },
    ]

    if (isShowMode && hasChatFeature) {
      tabs.push({
        id: UpdateTabId.CHAT,
        title: isMobile ? '' : getChatLabel(),
        icon: ['fal', 'comment'],
      })
    }

    if (isShowMode && updateType === UpdateTypes.ACCOUNTING) {
      tabs.push({
        id: UpdateTabId.RAW_DATA,
        title: isMobile ? '' : <FormattedMessage id="updates.rawData" />,
        icon: ['fal', 'file-code'],
      })
    }

    if (isShowMode && showAnalytics) {
      tabs.push({
        id: UpdateTabId.ANALYTICS,
        title: isMobile ? '' : <FormattedMessage id="updates.analytics" />,
        icon: ['fal', 'chart-line-up'],
      })
    }

    if (isShowMode && showLog) {
      tabs.push({
        id: UpdateTabId.LOGS,
        title: isMobile ? '' : <FormattedMessage id="logs.title" />,
        icon: ['fal', 'clipboard-list-check'],
      })
    }

    if (!showIcons) {
      tabs.forEach((e) => {
        e.icon = undefined
      })
    }

    return tabs
  }, [
    generalTitle,
    getChatLabel,
    hasChatFeature,
    isMobile,
    isShowMode,
    showAnalytics,
    showIcons,
    showLog,
    updateType,
  ])

  return result
}

const defaultVisibleColumns = Object.freeze({
  updatesTabLastUpdateDate: {
    status: true,
    index: 1,
  },
  updatesTabTotalUpdates: {
    status: true,
    index: 2,
  },
  updatesTabTotalNotes: {
    status: true,
    index: 3,
  },
  updatesTabTotalDocuments: {
    status: true,
    index: 4,
  },
  updatesTabTotalAnnouncements: {
    status: true,
    index: 5,
  },
  updatesTabTotalEmails: {
    status: true,
    index: 6,
  },
  updatesTabCreatedByGroup: {
    status: true,
    index: 7,
  },
  updatesTabSharedWithGroup: {
    status: true,
    index: 8,
  },
})

export const updatesTableColumns = ({
  intl,
  availableColumns,
  isMobile,
  currentPortfolioId,
  handleOnHoldingClick,
}: {
  intl: IntlShape
  availableColumns: DraggableOption[]
  isMobile: boolean
  currentPortfolioId?: string
  handleOnHoldingClick?: (holding: HoldingType) => void
}): Column<UpdateInfoItemWithId>[] => {
  const columnsFromApi = availableColumns?.reduce(
    (acc, { id, status, order }) => {
      acc[id] = {
        status,
        index: order.index,
      }
      return acc
    },
    {}
  )

  const visibleColumns = { ...defaultVisibleColumns, ...columnsFromApi }

  const fixedColumns = [
    {
      id: 'expand',
      sortDisabled: true,
      fixed: !isMobile,
      minWidth: 0,
      cellRenderer: ExpandArrow,
    },
    {
      id: 'name',
      sortKey: 'name',
      label: intl.formatMessage({
        id: 'updates.tableColumns.portfolioHolding',
      }),
      hidden: false,
      fixed: !isMobile,
      minWidth: 215,
      flex: 4,
      cellRenderer: ({ rowData }) => {
        const isFundPortfolio =
          rowData.type === SubjectMatterType.FUND_PORTFOLIO
        const isCompany = rowData.type === SubjectMatterType.COMPANY
        const isCurrentPortfolio = currentPortfolioId === rowData.id

        return (
          <Cell>
            {isCompany || (isFundPortfolio && !isCurrentPortfolio) ? (
              <Company onClick={() => handleOnHoldingClick?.(rowData)}>
                <CompanyAvatar
                  src={rowData?.logo?.smallLogo?.url || rowData?.logo}
                  title={rowData?.name}
                />
                <Tooltip
                  id={`holding-name-${rowData?.name}`}
                  place="bottom"
                  text={rowData?.name}
                  delayShow={500}
                >
                  <CompanyName
                    isNormalFontWeight={currentPortfolioId === rowData.id}
                  >
                    {rowData?.name}
                  </CompanyName>
                </Tooltip>
              </Company>
            ) : (
              <Tooltip
                id={`portfolio-name-${rowData?.name}`}
                place="bottom"
                text={rowData?.name}
                delayShow={500}
              >
                <CompanyName isNormalFontWeight>{rowData?.name}</CompanyName>
              </Tooltip>
            )}
          </Cell>
        )
      },
    },
  ]

  const orderColumns = [
    {
      id: 'updatesTabLastUpdateDate',
      sortKey: 'lastUpdateDate',
      label: intl.formatMessage({ id: 'updates.tableColumns.lastUpdateDate' }),
      maxWidth: '9rem',
      minWidth: 90,
      hidden: !visibleColumns.updatesTabLastUpdateDate.status,
      index: visibleColumns.updatesTabLastUpdateDate.index,
      flex: 1,
      cellRenderer: ({ rowData }) => {
        return (
          <Cell>
            {rowData.lastUpdateDate && rowData.lastUpdateDate !== DUMMY_DATE ? (
              <FormattedDate
                month="short"
                day="2-digit"
                year="numeric"
                value={rowData.lastUpdateDate}
              />
            ) : (
              '-'
            )}
          </Cell>
        )
      },
    },
    {
      id: 'updatesTabTotalUpdates',
      hidden: !visibleColumns.updatesTabTotalUpdates.status,
      index: visibleColumns.updatesTabTotalUpdates.index,
      maxWidth: '9rem',
      minWidth: 80,
      label: intl.formatMessage({
        id: 'updates.tableColumns.totalNumberOfUpdates',
      }),
      flex: 1,
      sortKey: 'totalUpdates',
      cellRenderer: ({ rowData }) => {
        return (
          <Cell>{getFieldValue(rowData?.totalUpdates, DataType.NUMBER)}</Cell>
        )
      },
    },
    {
      id: 'updatesTabTotalNotes',
      hidden: !visibleColumns.updatesTabTotalNotes.status,
      index: visibleColumns.updatesTabTotalNotes.index,
      label: intl.formatMessage({
        id: 'updates.tableColumns.totalNumberOfNotes',
      }),
      flex: 1,
      maxWidth: '8rem',
      minWidth: 70,
      sortKey: 'totalNotes',
      cellRenderer: ({ rowData }) => {
        return (
          <Cell>{getFieldValue(rowData?.totalNotes, DataType.NUMBER)}</Cell>
        )
      },
    },
    {
      id: 'updatesTabTotalDocuments',
      hidden: !visibleColumns.updatesTabTotalDocuments.status,
      index: visibleColumns.updatesTabTotalDocuments.index,
      label: intl.formatMessage({
        id: 'updates.tableColumns.totalNumberOfDocuments',
      }),
      flex: 1,
      maxWidth: '9rem',
      minWidth: 70,
      sortKey: 'totalDocuments',
      cellRenderer: ({ rowData }) => {
        return (
          <Cell>{getFieldValue(rowData?.totalDocuments, DataType.NUMBER)}</Cell>
        )
      },
    },
    {
      id: 'updatesTabTotalAnnouncements',
      hidden: !visibleColumns.updatesTabTotalAnnouncements.status,
      index: visibleColumns.updatesTabTotalAnnouncements.index,
      label: intl.formatMessage({
        id: 'updates.tableColumns.totalNumberOfAnnouncements',
      }),
      flex: 1,
      maxWidth: '12rem',
      minWidth: 105,
      sortKey: 'totalAnnouncements',
      cellRenderer: ({ rowData }) => {
        return (
          <Cell>
            {getFieldValue(rowData?.totalAnnouncements, DataType.NUMBER)}
          </Cell>
        )
      },
    },
    {
      id: 'updatesTabTotalEmails',
      hidden: !visibleColumns.updatesTabTotalEmails.status,
      index: visibleColumns.updatesTabTotalEmails.index,
      label: intl.formatMessage({
        id: 'updates.tableColumns.totalNumberOfEmails',
      }),
      flex: 1,
      maxWidth: '8rem',
      minWidth: 70,
      sortKey: 'totalEmails',
      cellRenderer: ({ rowData }) => {
        return (
          <Cell>{getFieldValue(rowData?.totalEmails, DataType.NUMBER)}</Cell>
        )
      },
    },
    {
      id: 'updatesTabCreatedByGroup',
      hidden: !visibleColumns.updatesTabCreatedByGroup.status,
      index: visibleColumns.updatesTabCreatedByGroup.index,
      label: intl.formatMessage({
        id: 'updates.tableColumns.numberCreatedByGroup',
      }),
      flex: 1,
      maxWidth: '13rem',
      minWidth: 110,
      sortKey: 'createdByGroup',
      cellRenderer: ({ rowData }) => {
        return (
          <Cell>{getFieldValue(rowData?.createdByGroup, DataType.NUMBER)}</Cell>
        )
      },
    },
    {
      id: 'updatesTabSharedWithGroup',
      hidden: !visibleColumns.updatesTabSharedWithGroup.status,
      index: visibleColumns.updatesTabSharedWithGroup.index,
      label: intl.formatMessage({
        id: 'updates.tableColumns.numberSharedWithGroup',
      }),
      flex: 1,
      maxWidth: '13rem',
      minWidth: 110,
      sortKey: 'sharedWithGroup',
      cellRenderer: ({ rowData }) => {
        return (
          <Cell>
            {getFieldValue(rowData?.sharedWithGroup, DataType.NUMBER)}
          </Cell>
        )
      },
    },
  ]
    .filter((column) => !column.hidden)
    .sort((a, b) => {
      return visibleColumns[a.id].index - visibleColumns[b.id].index
    })

  return [...fixedColumns, ...orderColumns]
}

export const nestedUpdateTableColumns = ({
  intl,
  isMobile,
}: {
  intl: IntlShape
  isMobile?: boolean
}): Column<IndexUpdate>[] => [
  {
    id: 'update_title',
    label: intl.formatMessage({ id: 'updates.nestedTableColumns.title' }),
    hidden: false,
    minWidth: isMobile ? 150 : 300,
    sortDisabled: true,
    flex: 1,
    cellRenderer: ({ rowData }) => {
      const isEmail = rowData.updateType === UpdateTypes.IUE

      const { isDraftUpdate: isUpdateDraft } = rowData

      const titleData = isUpdateDraft
        ? (rowData.draftHash as DraftHashAttributes).updatableAttributes.title
        : rowData.item.title

      return (
        <Cell>
          <UpdateTitle>
            {isEmail ? (rowData.item as IUEReportItem).subject : titleData}
          </UpdateTitle>
        </Cell>
      )
    },
  },
  {
    id: 'update_date',
    sortKey: 'createdAt',
    label: intl.formatMessage({
      id: 'updates.nestedTableColumns.date',
    }),
    minWidth: 100,
    hidden: false,
    flex: 1,
    cellRenderer: ({ rowData }) => {
      return (
        <Cell>
          {rowData.item.updatedAt &&
          (rowData.item.createdAt as unknown) !== DUMMY_DATE ? (
            <FormattedDate
              month="short"
              day="2-digit"
              year="numeric"
              value={rowData.item.updatedAt}
            />
          ) : (
            '-'
          )}
        </Cell>
      )
    },
  },
  {
    id: 'update_type',
    label: intl.formatMessage({ id: 'updates.nestedTableColumns.Type' }),
    minWidth: 150,
    hidden: false,
    flex: 1,
    sortDisabled: true,
    cellRenderer: ({ rowData }) => {
      const isEmail = rowData.updateType === UpdateTypes.IUE

      const isDraftEmail =
        isEmail && (rowData as EmailUpdate).item.sentAt === null

      const { isDraftUpdate: isUpdateDraft } = rowData

      const isDraftUpdate = isDraftEmail || isUpdateDraft

      const { icon, color: IconColor } = getUpdateIcon(
        rowData.updateType,
        isDraftUpdate
      )

      const updateTypeText = isEmail
        ? intl.formatMessage({
            id: 'updates.filters.updateTypes.options.email',
          })
        : rowData.updateType

      return (
        <Cell>
          <Chip
            key={rowData.item.id}
            maxWidth="15rem"
            text={updateTypeText}
            isCapitalizedText
            canDelete={false}
            backgroundColor={theme.colors.veryLightBlue40}
            imageComponent={
              <StyledIcon color={IconColor}>
                <FontAwesomeIcon size="xs" icon={['far', icon]} />
              </StyledIcon>
            }
          />
        </Cell>
      )
    },
  },
  {
    id: 'update_tags',
    label: intl.formatMessage({ id: 'updates.nestedTableColumns.tags' }),
    minWidth: 180,
    hidden: false,
    sortDisabled: true,
    flex: 1,
    cellRenderer: ({ rowData }) => {
      const tags = rowData?.taggings.map((tagging) => tagging.tag) ?? []
      return <ChipList entities={tags} tooltipContentType="grid" />
    },
  },
  {
    id: 'update_description',
    label: intl.formatMessage({ id: 'updates.nestedTableColumns.description' }),
    hidden: false,
    sortDisabled: true,
    minWidth: 150,
    flex: 1.5,
    cellRenderer: ({ rowData }) => {
      const isEmail = rowData.updateType === UpdateTypes.IUE

      const { isDraftUpdate: isUpdateDraft } = rowData

      const textData = isUpdateDraft
        ? (rowData.draftHash as DraftHashAttributes).updatableAttributes.text
        : rowData.item.text

      const emailTextData = (rowData as EmailUpdate).item.entriesRawText
        ?.split('\n')[2]
        ?.trim()

      return (
        <Cell>
          <DescriptionText isNormalFontWeight>
            {isEmail ? emailTextData : removeHtmlTags(textData)}
          </DescriptionText>
        </Cell>
      )
    },
  },
]
