import { ApolloError, useApolloClient, useLazyQuery } from '@apollo/client'
import { useCallback, useMemo, useState } from 'react'
import { GET_SUBJECTS } from 'utils/gql/queries/subjects'
import { Optional } from 'utils/types/common'
import {
  getFilters,
  PAGE_SIZE,
  SubjectFilter,
  SubjectQueryProps,
} from 'utils/hooks/queries/subjects/useSubjectsQuery'
import { useQuery } from '@tanstack/react-query'
import { subjectsKeys } from 'utils/queries/subjects'
import { SortDirection } from 'types/graphql-schemas/graphql'
import { isEqual } from 'lodash'

export type QueryOptions = {
  onError?: (error: ApolloError) => void
}

export const useSubjectsLazyQuery = (
  {
    filters = {},
    sorting = {},
    pageSize = PAGE_SIZE,
  }: Optional<SubjectQueryProps, 'filters'> = {},
  { onError }: QueryOptions = {}
) => {
  const queryVariables = useMemo(() => {
    return {
      query: getFilters(filters),
      skip: 0,
      limit: pageSize,
      sortBy: sorting.orderBy,
      sortDirection: sorting.direction,
    }
  }, [filters, pageSize, sorting.direction, sorting.orderBy])

  return useLazyQuery(GET_SUBJECTS, {
    variables: queryVariables,
    onError,
  })
}

export const useBasicSubjectsLazyQuery = ({ onError }: QueryOptions = {}) => {
  const [filters, setFilters] = useState<SubjectFilter>({})
  const [sorting, setSorting] = useState<{
    orderBy?: string
    direction?: SortDirection
  }>({})

  const apolloClient = useApolloClient()

  const queryVariables = useMemo(() => {
    return {
      query: getFilters(filters),
      skip: 0,
      limit: PAGE_SIZE,
      sortBy: sorting.orderBy,
      sortDirection: sorting.direction,
    }
  }, [filters, sorting.direction, sorting.orderBy])

  const query = useQuery({
    enabled: false,
    queryKey: subjectsKeys.byFilters({ filters, sorting }),
    queryFn: async () => {
      const { data } = await apolloClient.query({
        query: GET_SUBJECTS,
        variables: queryVariables,
      })

      return data.subjects
    },
    onError,
  })

  const onFetchSubjects = useCallback(
    async (
      newFilters: SubjectFilter,
      newSorting: {
        orderBy?: string
        direction?: SortDirection
      }
    ) => {
      const hasVariableChanged =
        !isEqual(newFilters, filters) || !isEqual(newSorting, sorting)

      if (hasVariableChanged) {
        setFilters(newFilters)
        setSorting(newSorting)

        if (query.status === 'success') {
          return query.refetch()
        }
      }

      return query
    },
    [setFilters, setSorting, query, sorting, filters]
  )

  return { onFetchSubjects }
}
