import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import GroupLogo from 'components/GroupLogo'
import {
  ActivityDate,
  AnalyticAvatar,
  AnalyticName,
  AnalyticIcon,
  AnalyticNameColumn,
  LastActivityLabel,
  LastActivityWrapper,
  ReshareIconContainer,
} from 'containers/Analytics/Analytics.styles'
import dayjs from 'dayjs'
import { FormattedMessage } from 'react-intl'
import Tooltip from 'components/Tooltip'
import {
  AnalyticAccessType,
  AnalyticsChartData,
  AnalyticsChartParams,
  AnalyticsDisplayBy,
  AnalyticsGroupBy,
  QuarterDayTimeFrame,
} from 'utils/constants/analytics'
import GroupTooltips from 'components/GroupTooltips'
import { TotalAnalytics } from 'utils/types/analytics'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const getGroupByFromDisplayType = (
  displayType: AnalyticsDisplayBy
): AnalyticsGroupBy => {
  if (displayType === AnalyticsDisplayBy.DAY) {
    return AnalyticsGroupBy.HOUR
  }
  if (displayType === AnalyticsDisplayBy.MONTH) {
    return AnalyticsGroupBy.MONTH
  }

  return AnalyticsGroupBy.DAY
}

export const getQuarterDayTimeFrame = (date?: Date): QuarterDayTimeFrame => {
  const currentHour = dayjs(date).hour()
  if (currentHour <= 6) {
    return QuarterDayTimeFrame.FRAME_0_6
  }
  if (currentHour > 6 && currentHour <= 12) {
    return QuarterDayTimeFrame.FRAME_6_12
  }
  if (currentHour > 12 && currentHour <= 18) {
    return QuarterDayTimeFrame.FRAME_12_18
  }

  return QuarterDayTimeFrame.FRAME_18_24
}

export const getChartDateRanges = (
  displayType: AnalyticsDisplayBy,
  page: number
): {
  startDate: Date
  endDate: Date
} => {
  if (displayType === AnalyticsDisplayBy.DAY) {
    const hourFrame = getQuarterDayTimeFrame()
    return {
      startDate: dayjs()
        .startOf('day')
        .add(6 * hourFrame, 'hour')
        .add(page * 6, 'hour')
        .toDate(),
      endDate: dayjs()
        .startOf('day')
        .add(6 * (hourFrame + 1), 'hour')
        .endOf('hour')
        .add(page * 6, 'hour')
        .toDate(),
    }
  }

  if (displayType === AnalyticsDisplayBy.MONTH) {
    return {
      startDate: dayjs().add(page, 'year').startOf('year').toDate(),
      endDate: dayjs().add(page, 'year').endOf('year').toDate(),
    }
  }
  const now = dayjs()
  const startDate = now.add(page, 'week').startOf('isoWeek').toDate()
  const endDate = now.add(page, 'week').endOf('isoWeek').toDate()
  return {
    startDate,
    endDate,
  }
}

const getDateFormatFromDisplayType = (
  displayType: AnalyticsDisplayBy
): string => {
  if (displayType === AnalyticsDisplayBy.DAY) {
    return 'hh:mm A'
  }

  if (displayType === AnalyticsDisplayBy.MONTH) {
    return 'MMM'
  }

  return 'dddd'
}

export const getAnalyticsChartData = (
  totalAnalyticsData: TotalAnalytics = [],
  updateCreationDate: Date,
  chartParams: AnalyticsChartParams
): AnalyticsChartData[] => {
  const chartData: AnalyticsChartData[] = []
  const groupBy = getGroupByFromDisplayType(chartParams.displayType)
  const endDate = dayjs(chartParams.endDate).add(1, groupBy)

  const now = new Date()
  for (
    let currentDate = dayjs(chartParams.startDate);
    !currentDate.isSame(endDate, groupBy);
    currentDate = currentDate.add(1, groupBy)
  ) {
    const dataForDate = totalAnalyticsData.find(([dateStr]) => {
      return dayjs(dateStr).isSame(currentDate, groupBy)
    })
    let yValue
    if (
      currentDate.isBefore(updateCreationDate, groupBy) ||
      currentDate.isAfter(now, groupBy)
    ) {
      yValue = null
    } else {
      yValue = dataForDate?.[1] || 0
    }
    chartData.push({
      x: currentDate.format(
        getDateFormatFromDisplayType(chartParams.displayType)
      ),
      y: yValue,
      plainX: currentDate.toISOString(),
    })
  }

  return chartData
}

export const isChartNextPageDisabled = (
  chartParams: AnalyticsChartParams
): boolean => {
  if (chartParams.displayType === AnalyticsDisplayBy.DAY) {
    const hourFrame = getQuarterDayTimeFrame()
    const endDateHourFrame = getQuarterDayTimeFrame(chartParams.endDate)
    return dayjs(chartParams.endDate).isToday() && hourFrame <= endDateHourFrame
  }
  return (
    dayjs(chartParams.endDate).isToday() ||
    dayjs(chartParams.endDate).isAfter(new Date(), 'day')
  )
}
export const isChartPreviousPageDisabled = (
  chartParams: AnalyticsChartParams,
  updateCreationDate: Date
) => {
  return !dayjs(updateCreationDate).isBefore(chartParams.startDate)
}

export const getTableColumns = () => {
  const getAnalyticsAccesIcon = (access: AnalyticAccessType): IconProp => {
    switch (access) {
      case AnalyticAccessType.DIRECT_SHARE:
        return ['far', 'crosshairs']
      case AnalyticAccessType.PUBLIC_SHARE:
        return ['far', 'globe-americas']
      case AnalyticAccessType.RESHARE:
      case null: // todo: reshared update
        return ['far', 'retweet']
      default:
        throw new Error(`Access ${access} is not valid`)
    }
  }

  const getAnalyticsAccesTooltipText = (access: AnalyticAccessType) => {
    switch (access) {
      case AnalyticAccessType.DIRECT_SHARE:
        return <FormattedMessage id="analytics.directShareTooltip" />
      case AnalyticAccessType.PUBLIC_SHARE:
        return <FormattedMessage id="analytics.publicShareTooltip" />
      case AnalyticAccessType.RESHARE:
      case null: // todo: reshared update
        return <FormattedMessage id="analytics.reshareTooltip" />
      default:
        throw new Error(`Access ${access} is not valid`)
    }
  }

  const getAnalyticLastActivityText = (access: AnalyticAccessType) => {
    switch (access) {
      case AnalyticAccessType.DIRECT_SHARE:
      case AnalyticAccessType.PUBLIC_SHARE:
        return <FormattedMessage id="analytics.viewedUpdate" />
      case AnalyticAccessType.RESHARE:
        return <FormattedMessage id="analytics.viewedViaReshare" />
      case null: // todo: reshared update
        return <FormattedMessage id="analytics.resharedUpdate" />
      default:
        throw new Error(`Access ${access} is not valid`)
    }
  }

  return [
    {
      sortKey: 'name',
      align: 'left',
      label: <FormattedMessage id="analytics.user" />,
      flex: 1,
      cellRenderer: ({ rowData }) => {
        return (
          <AnalyticNameColumn>
            <AnalyticAvatar>
              <GroupLogo forceInitials name={rowData.name} small />
            </AnalyticAvatar>
            <AnalyticName>{rowData.name}</AnalyticName>
            <Tooltip
              id={rowData.id}
              text={getAnalyticsAccesTooltipText(rowData.firstAccess)}
              place="right"
            >
              <AnalyticIcon icon={getAnalyticsAccesIcon(rowData.firstAccess)} />
            </Tooltip>
          </AnalyticNameColumn>
        )
      },
    },
    {
      sortKey: 'groups.length',
      align: 'left',
      label: <FormattedMessage id="analytics.userGroups" />,
      flex: 0.5,
      cellRenderer: ({ rowData }) => {
        return (
          <GroupTooltips
            groups={rowData.groups}
            direction="left"
            maxGroupsToDisplay={3}
            small
          />
        )
      },
    },
    {
      sortKey: 'dateLastActivity',
      align: 'left',
      label: <FormattedMessage id="analytics.date" />,
      flex: 0.5,
      cellRenderer: ({ rowData }) => {
        if (dayjs(rowData.dateLastActivity).isToday()) {
          return (
            <ActivityDate>
              {`Today, ${dayjs(rowData.dateLastActivity).format('h:mm A')}`}
            </ActivityDate>
          )
        }
        return (
          <ActivityDate>
            {dayjs(rowData.dateLastActivity).format('DD MMM, h:mm A')}
          </ActivityDate>
        )
      },
    },
    {
      sortKey: 'lastActivity',
      align: 'left',
      label: <FormattedMessage id="analytics.lastActivity" />,
      flex: 1,
      cellRenderer: ({ rowData }) => {
        return (
          <LastActivityWrapper>
            <LastActivityLabel>
              {getAnalyticLastActivityText(rowData.lastActivity)}
            </LastActivityLabel>
            {(rowData.lastActivity === null ||
              rowData.lastActivity === AnalyticAccessType.RESHARE) && (
              <ReshareIconContainer>
                <FontAwesomeIcon icon={['far', 'retweet']} />
              </ReshareIconContainer>
            )}
          </LastActivityWrapper>
        )
      },
    },
  ]
}
