import {
  AttributeDataType,
  AttributeFragment,
  CreateAttributeDto,
  GqlAttributeDto,
  SubjectAttributesFragment,
  Permission,
} from 'types/graphql-schemas/graphql'
import {
  AttributeValue,
  RefArrayAttribute,
  RefAttribute,
  ScopedRefArrayAttribute,
} from '../types/attributes'
import { PUBLIC_PERMISSION } from './permissions'

export type Attribute<T extends AttributeValue<T>> =
  | (Omit<AttributeFragment, 'value'> & { value?: T })
  | undefined

export const getAttribute = <T extends AttributeValue<T>>(
  subject: SubjectAttributesFragment | undefined | null,
  attributeName: string
): Attribute<T> | null => {
  const attr = subject?.attributes.find(
    (attribute) => attribute.name === attributeName
  ) as Attribute<T> | undefined

  if (!attr) return null

  if (attr.type === AttributeDataType.Ref) {
    return {
      ...attr,
      value: {
        ref: (attr.value as RefAttribute<T>).ref,
        entity: attr.refs?.[0]!,
      } as unknown as T,
    }
  }

  if (attr.type === AttributeDataType.RefArray) {
    return {
      ...attr,
      value: {
        ref: (attr.value as RefArrayAttribute).ref,
        entities: attr.refs!,
      } as unknown as T,
    }
  }

  if (attr.type === AttributeDataType.ScopedRefArray) {
    return {
      ...attr,
      value: {
        ref: (attr.value as ScopedRefArrayAttribute).ref,
        entities: (attr.value as ScopedRefArrayAttribute).entities.map(
          (entity, index) => ({
            entity: attr.refs?.[index]!,
            permissions: entity.permissions,
          })
        ),
      } as T,
    }
  }

  if (attr.type === AttributeDataType.Date) {
    return {
      ...attr,
      value: (attr.value !== null ? new Date(attr.value as string) : null) as T,
    }
  }

  return attr
}

export const getCreateAttribute = (
  key: string,
  value: any,
  type: AttributeDataType,
  required: boolean = false
): CreateAttributeDto => {
  return {
    name: key,
    value,
    type: type.toLowerCase(),
    required,
  }
}

export const getEditAttribute = (key: string, value: any): GqlAttributeDto => {
  return {
    name: key,
    operation: 'update',
    value,
  }
}

export const getCompanySubjectTypeIdentifier = (website: string) => {
  return new URL(website).hostname
}

export const getPermission = (permissions: Permission[], entityId: string) =>
  permissions.find((permission) => permission.entityId === entityId)

export const hasPublicReadPermission = (permissions: Permission[]) =>
  !!getPermission(permissions, PUBLIC_PERMISSION)?.read
