import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import Toast from 'components/Toast'
import { getCurrentGroupId, isActingAsFounder } from 'selectors/auth'
import { orderArrayByObjectPropertyAndDirection } from 'utils/functions/array'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { companyKeys } from 'utils/queries/companies'
import { metricsKeys } from 'utils/queries/metrics'
import { PortfolioMetricsActions } from 'utils/types/portfolios'

import NumbersService from 'api/NumbersService'
import {
  ARCHIVE_DP_METRIC_EVENT,
  DELETED_METRIC_EVENT,
  UN_ARCHIVE_DP_METRIC_EVENT,
} from 'utils/constants/events'
import {
  metricIsReceivingDataFromFounder,
  populateMetricGrowth,
} from 'utils/functions/metrics'
import { useGeneratingHoldingsMetricsQuery } from 'utils/hooks/metrics/useGeneratingHoldingsMetricsQuery'
import { useGetDataPointsByMetricId } from 'utils/hooks/queries/useDataPointsQuery'
import { useGroupsQuery } from 'utils/hooks/queries/useGroupQuery'
import { useGetMilestonesByMetricId } from 'utils/hooks/queries/useMilestonesQuery'
import useMetricQuery from 'utils/hooks/useMetricQuery'
import { useMetricsMode } from 'utils/hooks/useMetricsMode'
import { useMetricsSubjectQuery } from 'utils/hooks/useMetricsSubjectQuery'
import { CompanyData } from 'utils/types/company'
import { MetricSources, MetricsMode } from 'utils/types/metrics'
import { DataPoint, LinkedMetricState, Metric } from 'utils/types/metricsV2'

export const POLLING_METRICS_STATUS_INTERVAL = 9000

export interface SelectableDataPoint extends DataPoint {
  isSelected: boolean
  growth?: boolean
  growthValue?: number
}
type Sort = { sortId: string; sortDirection: 'asc' | 'desc' }

const useMetric = (closeDrawer) => {
  const [dataPoints, setDataPoints] = useState<SelectableDataPoint[]>([])
  const intl = useIntl()
  const history = useHistory()
  const metricsMode = useMetricsMode()
  const isFounder = metricsMode === MetricsMode.FOUNDER
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const isActingAsFounderValue = useAppSelector(isActingAsFounder)
  const [isFounderDataToggled, setIsFounderDataToggled] = useState(false)
  const queryClient = useQueryClient()
  const [showBulkImport, setShowBulkImport] = useState(false)
  const [initialTabIndex, setInitialTabIndex] = useState(0)
  const location = useLocation<{ activeTab: number }>()
  const routeMatch = useRouteMatch()

  const [nonArchivedSort, setNonArchivedSort] = useState<Sort>({
    sortId: 'date',
    sortDirection: 'desc',
  })
  const [archivedSort, setArchivedSort] = useState<Sort>({
    sortId: 'date',
    sortDirection: 'desc',
  })

  useEffect(() => {
    if (location.state?.activeTab) {
      setInitialTabIndex(location.state?.activeTab)
    } else {
      setInitialTabIndex(0)
    }
  }, [location.state])

  const { metricId } = useParams<{ metricId: string }>()

  const onMetricNotFoundError = useCallback(
    (error) => {
      if (error.status === 404) {
        history.push('/metrics')
      }
    },
    [history]
  )

  const { metric, groupedMilestones, isLoading, setMetric, refetchMetric } =
    useMetricQuery({
      metricId,
      onError: onMetricNotFoundError,
      onSuccess: (fetchedMetric: Metric) => {
        setIsFounderDataToggled(metricIsReceivingDataFromFounder(fetchedMetric))
      },
    })

  const { data: sharedGroups, isLoading: isLoadingSharedGroups } =
    useGroupsQuery(
      metric?.senderLinks
        ?.filter(
          (link) =>
            link.state !== LinkedMetricState.UNSHARED &&
            link.state !== LinkedMetricState.SHARE_DENIED &&
            link.state !== LinkedMetricState.REQUEST_DENIED &&
            link.receiverGroupId
        )
        .map((link) => link.receiverGroupId!)
    )

  const { data: subject } = useMetricsSubjectQuery(
    {
      subjectId: metric?.subject?.id!,
      subjectType: metric?.subject?.type!,
    },
    {
      enabled: !!metric,
    }
  )

  const { data: idsStatusMap } = useGeneratingHoldingsMetricsQuery(
    [metric?.subject.id!],
    {
      enabled: !!metric && !isActingAsFounderValue,
      refetchInterval: POLLING_METRICS_STATUS_INTERVAL,
      onSuccess: refetchMetric,
    }
  )

  const isGeneratingMetrics =
    idsStatusMap?.[metric?.subject.id!]?.action ===
    PortfolioMetricsActions.PENDING

  const isSystemMetric = useMemo(
    () => metric?.source === MetricSources.System,
    [metric]
  )
  const { data: fetchedMilestones } = useGetMilestonesByMetricId(metricId)
  useGetDataPointsByMetricId(
    { metricId, isSystemMetric },
    {
      staleTime: 0,
      onSettled: (fetchedDataPoints: DataPoint[]) => {
        setDataPoints(
          fetchedDataPoints.map<SelectableDataPoint>((dp) => ({
            ...dp,
            isSelected: false,
          }))
        )
      },
    }
  )

  const onSortByColumn =
    (isArchivedTableSort) =>
    ({ sortId, sortDirection }) => {
      if (isArchivedTableSort) {
        setArchivedSort({ sortId, sortDirection })
      } else {
        setNonArchivedSort({ sortId, sortDirection })
      }
    }

  const notArchivedDataPointsWithGrowth = useMemo(() => {
    const sortedArr = dataPoints.filter((dp) => !dp.archived).reverse()

    return populateMetricGrowth(sortedArr)
  }, [dataPoints])

  const notArchivedDataPoints = useMemo(() => {
    return orderArrayByObjectPropertyAndDirection<SelectableDataPoint>(
      notArchivedDataPointsWithGrowth,
      nonArchivedSort.sortId,
      nonArchivedSort.sortDirection
    )
  }, [
    nonArchivedSort.sortDirection,
    nonArchivedSort.sortId,
    notArchivedDataPointsWithGrowth,
  ])

  const archivedDataPoints = useMemo(() => {
    return orderArrayByObjectPropertyAndDirection<SelectableDataPoint>(
      dataPoints.filter((dp) => dp.archived),
      archivedSort.sortId,
      archivedSort.sortDirection
    )
  }, [dataPoints, archivedSort.sortDirection, archivedSort.sortId])

  const onEditMetric = useCallback(() => {
    history.replace(`${routeMatch.url}/edit`)
  }, [history, routeMatch.url])

  const onAddNewValue = useCallback(() => {
    history.replace(`${routeMatch.url}/add-value`)
  }, [history, routeMatch.url])

  const onSetMilestone = useCallback(() => {
    history.replace(`${routeMatch.url}/set-milestone`)
  }, [history, routeMatch.url])

  const deleteMetric = useCallback(
    (isFounderTeam) => async (metricData) => {
      try {
        await NumbersService.deleteMetric(metricData.id)
        if (isFounderTeam) {
          queryClient.refetchQueries(
            companyKeys.companyMetrics(metricData.companyData.id)
          )
        } else {
          queryClient.refetchQueries(metricsKeys.getMetrics())
        }
      } catch (error) {
        Toast.display(
          intl.formatMessage({ id: 'metrics.deleteMetricError' }),
          'error'
        )
      }
    },
    [intl, queryClient]
  )

  const onRemoveMetric = (metricData) => {
    dispatchEvent(DELETED_METRIC_EVENT, {
      id: metricData.id,
      hidden: true,
    })
    const deleteMetricFunc = deleteMetric(isFounder)

    Toast.displayAction({
      message: intl.formatMessage(
        { id: 'metrics.toasts.metricDeleted' },
        { metricName: metricData.name }
      ),
      action: () => {
        dispatchEvent(DELETED_METRIC_EVENT, {
          id: metricData.id,
          hidden: false,
        })
      },
      afterClose: () => deleteMetricFunc(metricData),
      label: intl.formatMessage({ id: 'common.undoDelete' }),
    })
    closeDrawer()
  }

  const onGoToCompanyPage = useCallback(
    (metricData) => {
      history.push(`/companies/${metricData?.businessCompanyDatum}`)
    },
    [history]
  )

  const redirectToShareDataPoints = (dataPointsToShare: DataPoint[]) => {
    history.replace(`${routeMatch.url}/share-values`, {
      dataPointsToShare,
      metric,
    })
  }

  const redirectToSetMilestone = () => {
    history.push(`${routeMatch.url}/set-milestone`)
  }

  const redirectToEdit = () => {
    history.push(`${routeMatch.url}/edit`)
  }

  const onChangeFounderDataToggleValue = async (value: boolean) => {
    setIsFounderDataToggled(value)
    try {
      await NumbersService.updateReceiveData(
        metric?.receiverMetricLink?.id!,
        value
      )
      refetchMetric()
    } catch (_) {
      Toast.displayIntl('metrics.errorChangingReceivingData', 'error')
    }
  }

  const archiveDataPoints = async (
    selectedDataPoints: SelectableDataPoint[]
  ) => {
    try {
      setDataPoints((currDataPoints) => {
        return currDataPoints.map((dp) => {
          if (
            selectedDataPoints
              .map<string>((currDp) => currDp.id)
              .includes(dp.id)
          ) {
            return { ...dp, archived: true, isSelected: false }
          }
          return dp
        })
      })
      await NumbersService.archiveDataPoints(
        selectedDataPoints.map((dataPoint) => dataPoint.id),
        true
      )

      refetchMetric()

      dispatchEvent(ARCHIVE_DP_METRIC_EVENT, {
        metricId,
      })

      Toast.displayIntl(
        selectedDataPoints.length === 1
          ? 'metrics.toasts.successArchivingDataPoint'
          : [
              'metrics.toasts.successArchivingDataPoints',
              { count: selectedDataPoints.length },
            ],
        'info'
      )
    } catch (error) {
      Toast.displayIntl('metrics.toasts.errorArchivingDataPoint', 'error')
    }
  }

  const unArchiveDataPoints = async (selectedDataPoints: DataPoint[]) => {
    try {
      setDataPoints((currDataPoints) => {
        return currDataPoints.map((dp) => {
          if (selectedDataPoints.map((currDp) => currDp.id).includes(dp.id)) {
            return { ...dp, archived: false, isSelected: false }
          }
          return dp
        })
      })

      await NumbersService.archiveDataPoints(
        selectedDataPoints.map((dataPoint) => dataPoint.id),
        false
      )

      dispatchEvent(UN_ARCHIVE_DP_METRIC_EVENT, {
        metricId,
      })

      refetchMetric()

      Toast.displayIntl(
        selectedDataPoints.length === 1
          ? 'metrics.toasts.successUnarchivingDataPoint'
          : [
              'metrics.toasts.successUnarchivingDataPoints',
              { count: selectedDataPoints.length },
            ],
        'info'
      )
    } catch (error) {
      Toast.displayIntl('metrics.toasts.errorUnarchivingDataPoint', 'error')
    }
  }

  const deleteDataPoints = async (
    selectedDataPoints: SelectableDataPoint[]
  ) => {
    try {
      setDataPoints((currDataPoints) => {
        return currDataPoints.filter((dp) => {
          const isDeletedDataPoint = selectedDataPoints
            .map((currDp) => currDp.id)
            .includes(dp.id)
          return !isDeletedDataPoint
        })
      })

      await Promise.all(
        selectedDataPoints.map((dataPoint) =>
          NumbersService.deleteDataPoint(dataPoint.id)
        )
      )

      dispatchEvent(ARCHIVE_DP_METRIC_EVENT, {
        metricId,
      })

      refetchMetric()
    } catch (error) {
      Toast.displayIntl('metrics.errorDeletingDataPoints', 'error')
    }
  }

  const editDataPoint = ({ readOnlyMode, dataPoint: currDataPoint }) => {
    history.push(`${routeMatch.url}/edit-value`, {
      dataPoint: currDataPoint,
      readOnlyMode,
      isEditing: true,
    })
  }

  const sendRequestToFounder = async () => {
    try {
      if (metric) {
        const linkedMetric = await NumbersService.sendRequestToFounder({
          receiverMetricId: metric.id,
          senderGroupId: (subject as unknown as CompanyData)?.groupId!,
        })

        const updatedMetric: Metric = {
          ...metric,
          receiverMetricLink: linkedMetric,
        }

        setMetric(updatedMetric)
      }
    } catch (error) {
      Toast.displayIntl('metrics.requestToFounderFailed', 'error')
    }
  }

  const exportMetric = async () => {
    try {
      if (metric) {
        await NumbersService.exportMetric(metric.id, metric.name)
      }
    } catch (err) {
      Toast.displayIntl('metrics.exportMetricsError', 'error')
    }
  }

  return {
    isFounder,
    subject,
    metric,
    groupedMilestones,
    dataPoints,
    isLoadingMetric: isLoading,
    onSortByColumn,
    onEditMetric,
    onAddNewValue,
    onRemoveMetric,
    onGoToCompanyPage,
    currentGroupId,
    sharedGroups,
    isLoadingSharedGroups,
    onSetMilestone,
    isFounderDataToggled,
    onChangeFounderDataToggleValue,
    redirectToSetMilestone,
    setDataPoints,
    archiveDataPoints,
    unArchiveDataPoints,
    deleteDataPoints,
    editDataPoint,
    sendRequestToFounder,
    redirectToEdit,
    fetchedMilestones,
    redirectToShareDataPoints,
    showBulkImport,
    setShowBulkImport,
    initialTabIndex,
    notArchivedDataPoints,
    archivedDataPoints,
    exportMetric,
    isGeneratingMetrics,
    isSystemMetric,
  }
}

export default useMetric
