import { useIntl } from 'react-intl'

import Toggle from 'components/Toggle'
import Tooltip from 'components/Tooltip'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip'
import type { PlacesType } from 'react-tooltip'
import theme from 'utils/theme'

import * as Styles from './ShowInSearch.styles'

interface TooltipStylesType {
  place: PlacesType
  backgroundColor: string
  color: string
  boxShadow: string
  border: string
  maxWidth: string
  margin: string
}

interface ShowInSearchProps {
  hidden: boolean
  isFounderVerified: boolean
  onChange: (hidden: boolean) => void
}

const ShowInSearch: React.FC<ShowInSearchProps> = ({
  hidden,
  isFounderVerified,
  onChange,
}) => {
  const intl = useIntl()

  const tooltipStyle: TooltipStylesType = {
    place: 'bottom',
    backgroundColor: theme.colors.white,
    color: theme.colors.darkGray,
    boxShadow: `0 0 1.5rem 0 ${theme.colors.blackShadow}`,
    border: `0.1rem solid ${theme.colors.veryLightGray}`,
    maxWidth: '24.5rem',
    margin: '-0.5rem 0 0 4rem',
  }

  const content = (
    <Styles.Wrapper>
      <Toggle
        id="showInDiscover"
        name="showInDiscover"
        status={!hidden}
        onChangeStatus={(checked) => {
          onChange(!checked)
        }}
        legend={intl.formatMessage({
          id: 'editCompany.showInDiscover',
        })}
        className="showInDiscover"
        disableAnimationOnMount
      />
    </Styles.Wrapper>
  )

  if (!isFounderVerified) {
    return (
      <ReadOnlyTooltip
        text={intl.formatMessage({
          id: 'companyProfile.showInCompanySearchUnverifiedTooltip',
        })}
        {...tooltipStyle}
      >
        {content}
      </ReadOnlyTooltip>
    )
  }

  return (
    <Tooltip
      text={
        !hidden
          ? intl.formatMessage({ id: 'editCompany.showInDiscoverTooltip' })
          : undefined
      }
      {...tooltipStyle}
    >
      {content}
    </Tooltip>
  )
}

export default ShowInSearch
