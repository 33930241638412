import Avatar from 'components/Avatar'
import { useHistory } from 'react-router-dom'
import { isActingAsInvestorGroup } from 'selectors/auth'
import { useSubjectContext } from 'utils/context/subjects/SubjectContext'
import useSubjectParent from 'utils/context/subjects/hooks/useSubjectParent'
import { getInitials } from 'utils/functions/user'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import {
  BasicInformationWrapper,
  CompanyName,
  ImageWrapper,
} from '../CompanyHeader/HeaderBasicInformation.styles'
import { InvestorGroupName } from './HeaderBasicInformation.styles'

const HeaderBasicInformation = () => {
  const { name, logo } = useSubjectContext()
  const { parentSubject, parentSubjectUrl } = useSubjectParent()
  const history = useHistory()
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const investorGroupProfileUrl = isInvestorGroup
    ? `/profile`
    : (parentSubjectUrl as string)

  return (
    <BasicInformationWrapper>
      <ImageWrapper>
        <Avatar
          avatarStyle="avatarCircleLarge"
          image={logo}
          initials={getInitials(name)}
          grayBorder
        />
      </ImageWrapper>
      <CompanyName>{name}</CompanyName>
      <InvestorGroupName onClick={() => history.push(investorGroupProfileUrl)}>
        {parentSubject?.name}
      </InvestorGroupName>
    </BasicInformationWrapper>
  )
}

export default HeaderBasicInformation
