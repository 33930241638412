import dayjs from 'dayjs'
import { ScheduleRepetitionType } from 'utils/constants/scheduleRepetition'
import { UpdateTypes, UpdateVisibility } from 'utils/constants/updates'
import { Nullable, UpdateType } from 'utils/types/common'
import { ChangeSetItem, EventType, LogTextBuilder } from '../types'

const INSTRUMENT_FIELDS = [
  'valuationCap',
  'discountRate',
  'discountRatePlaceholder',
  'interestCalculationBasis',
  'maturityDate',
  'purchasePricePerShare',
  'sharesPurchased',
  'preMoneyValuation',
  'postMoneyValuation',
  'annualManagementFee',
  'carry',
  'carryHurdleRate',
  'dividend',
  'dividendCalculationBasis',
  'dividendType',
  'cumulative',
  'noncumulative',
  'warrants',
  'vestingCommencementDate',
  'expirationDate',
  'strikePrice',
  'numberOfShares',
]
export class UpdateLogTextBuilder extends LogTextBuilder {
  private entityName: string

  private updateType: UpdateType

  private useQuotes: boolean

  constructor(entityName: string, updateType: UpdateType, useQuotes = false) {
    super()
    this.entityName = entityName
    this.updateType = updateType
    this.useQuotes = useQuotes
  }

  private getCreatedLogText(): React.ReactNode {
    return this.formatMessage('logs.hasCreatedEntity', {
      entity: this.entityName,
    })
  }

  private getUpdatedLogText(): React.ReactNode {
    return this.formatMessage('logs.hasUpdatedEntity', {
      entity: this.entityName,
    })
  }

  private getRemovedFieldLogText(fieldName: string): React.ReactNode {
    return this.formatMessage('logs.hasRemovedField', {
      fieldName,
    })
  }

  private getAddedFieldLog(
    fieldName: string,
    [from, to]: ChangeSetItem
  ): React.ReactNode {
    return this.formatMessage(
      'logs.addedUpdateField',
      {
        updateType: this.entityName,
        fieldName,
        from,
        to,
      },
      this.useQuotes
    )
  }

  private getChangedLogFromTo(
    fieldName: string,
    [from, to]: ChangeSetItem
  ): React.ReactNode {
    return this.formatMessage(
      'logs.updateEditedLogFromTo',
      {
        updateType: this.entityName,
        fieldName,
        from,
        to,
      },
      this.useQuotes
    )
  }

  private getChangedWithoutEntityFromTo(
    fieldName: string,
    [from, to]: ChangeSetItem
  ): React.ReactNode {
    return this.formatMessage(
      'logs.changedLogFromTo',
      {
        fieldName,
        from,
        to,
      },
      this.useQuotes
    )
  }

  private getRemovedLogWithFieldText(
    fieldName: string,
    [from]: ChangeSetItem
  ): React.ReactNode {
    return this.formatMessage(
      'logs.removedField',
      {
        fieldName,
        from,
      },
      this.useQuotes
    )
  }

  private getChangedDateLogFromTo(
    fieldName: string,
    [from, to]: ChangeSetItem
  ): React.ReactNode {
    return this.formatMessage(
      'logs.updateChangedLogFromTo',
      {
        updateType: this.entityName,
        fieldName,
        from: dayjs(from as string).format('MMMM DD, YYYY - h:mm A'),
        to: dayjs(to as string).format('MMMM DD, YYYY - h:mm A'),
      },
      this.useQuotes
    )
  }

  private getTransactionDateChangeLog(
    [from, to]: ChangeSetItem,
    text: Nullable<string>
  ): React.ReactNode {
    if (!text) return null
    return this.formatMessage(
      'logs.changedTransactionDate',
      {
        transactionType: text,
        from: dayjs(from as string).format('MMMM DD, YYYY'),
        to: dayjs(to as string).format('MMMM DD, YYYY'),
      },
      this.useQuotes
    )
  }

  private getVisibilityChangeLog([_, to]: ChangeSetItem): React.ReactNode {
    if (to === UpdateVisibility.PUBLIC)
      return this.formatMessage('logs.setAsPublicUpdate')
    if (to === UpdateVisibility.ONLY_ME)
      return this.formatMessage('logs.setAsOnlyMe')
    if (to === UpdateVisibility.CUSTOM) return null
    return null
  }

  private getSharedUpdateWithLog(
    text: string,
    fromOutsideGroup: boolean
  ): React.ReactNode {
    if (fromOutsideGroup) {
      return this.formatMessage('logs.sharedUpdateWithYouOrYourGroup')
    }
    return this.formatMessage('logs.sharedUpdateWith', { name: text })
  }

  private getUnsharedUpdateWithLog(text: string): React.ReactNode {
    return this.formatMessage('logs.unsharedUpdateWith', { name: text })
  }

  private getOwnedByGroupChangeLog([from, to]: ChangeSetItem): React.ReactNode {
    if (!from && to) {
      return this.formatMessage('logs.setOwnedByGroup')
    }
    if (from && !to) {
      return this.formatMessage('logs.unsetOwnedByGroup')
    }
    return null
  }

  private getAddedAttachmentLog(fileName: string): React.ReactNode {
    return this.formatMessage('logs.addedAttachment', { fileName })
  }

  private getRemovedAttachmentLog(fileName: string): React.ReactNode {
    return this.formatMessage('logs.deletedAttachment', { fileName })
  }

  private getAddedTagLog(tagName: string): React.ReactNode {
    return this.formatMessage('logs.addedTag', { tagName })
  }

  private getRemovedTagLog(tagName: string): React.ReactNode {
    return this.formatMessage('logs.removedTag', { tagName })
  }

  private getRepetitionText(
    repetition: ScheduleRepetitionType
  ): React.ReactNode {
    switch (repetition) {
      case ScheduleRepetitionType.DAILY:
        return this.formatMessage('logs.daily')
      case ScheduleRepetitionType.ANUALLY_ON_DATE:
        return this.formatMessage('logs.annually')
      case ScheduleRepetitionType.MONTHLY_FIRST_DAY:
      case ScheduleRepetitionType.MONTHLY_LAST_DAY:
      case ScheduleRepetitionType.WEEKLY_ON_DATE:
        return this.formatMessage('logs.monthly')
      case ScheduleRepetitionType.WEEKLY_ON_DAY:
        return this.formatMessage('logs.weekly')

      default:
        return ''
    }
  }

  private getAddedScheduleDateLog(
    scheduleDate: string,
    repetition: ScheduleRepetitionType
  ): React.ReactNode {
    if (!repetition || repetition === ScheduleRepetitionType.NO_REPETITION) {
      return this.formatMessage('logs.addedScheduleDate', {
        updateType: this.entityName,
        date: dayjs(scheduleDate).format('MMM DD, YYYY'),
      })
    }

    return this.formatMessage('logs.addedScheduleDateWithRepetition', {
      updateType: this.entityName,
      date: dayjs(scheduleDate).format('MMM DD, YYYY'),
      repetition: this.getRepetitionText(repetition)!.toString(),
    })
  }

  private getNormalizedValueChangeLog(
    fieldName: string,
    [from, to]: ChangeSetItem
  ): React.ReactNode {
    return this.formatMessage(
      'logs.updateChangedLogFromTo',
      {
        updateType: `${this.formatMessage('logs.the')} ${this.entityName}`,
        fieldName,
        from: this.formatMessage(`logs.${from}`)!.toString(),
        to: this.formatMessage(`logs.${to}`)!.toString(),
      },
      this.useQuotes
    )
  }

  private getNormalizedAddedValueChangeLog(
    fieldName: string,
    [_, to]: ChangeSetItem
  ): React.ReactNode {
    return this.formatMessage(
      'logs.updateAddedLogFromTo',
      {
        updateType: this.entityName,
        fieldName,
        to: this.formatMessage(`logs.${to}`)!.toString(),
      },
      this.useQuotes
    )
  }

  private getAddedTransactionToPortfolioLog(
    portfolioName: string
  ): React.ReactNode {
    return this.formatMessage('logs.addedTransactionToPortfolio', {
      portfolioName,
    })
  }

  private getRemovedTransactionFromPortfolioLog(
    portfolioName: string
  ): React.ReactNode {
    return this.formatMessage('logs.removedTransactionToPortfolio', {
      portfolioName,
    })
  }

  public getLogText(
    event: EventType,
    fieldName: string,
    [from, to]: ChangeSetItem,
    text: Nullable<string>,
    fromOutsideGroup: boolean,
    customValue: any
  ): React.ReactNode {
    if (event === EventType.CREATE) {
      return this.getCreatedLogText()
    }
    if (event === EventType.DESTROY) {
      if (INSTRUMENT_FIELDS.includes(fieldName)) {
        return null
      }
      if (
        fieldName === 'repetition' &&
        this.updateType !== UpdateTypes.ACCOUNTING
      ) {
        return null
      }
      return this.getRemovedFieldLogText(fieldName)
    }

    if (event === EventType.UPDATE) {
      if (text?.includes('legacy')) {
        return this.getUpdatedLogText()
      }
      if (from === null) {
        if (to === null) return null

        if (fieldName === 'instrumentType') {
          return this.getNormalizedAddedValueChangeLog(fieldName, [from, to])
        }

        return this.getAddedFieldLog(fieldName, [from, to])
      }

      if (to === null) {
        return this.getRemovedLogWithFieldText(fieldName, [from, to])
      }

      if (fieldName === 'date') {
        if (this.updateType === UpdateTypes.TRANSACTION) {
          return this.getTransactionDateChangeLog([from, to], text)
        }
        return this.getChangedDateLogFromTo(fieldName, [from, to])
      }

      if (fieldName === 'visibility') {
        return this.getVisibilityChangeLog([from, to])
      }

      if (fieldName === 'ownedByGroup') {
        return this.getOwnedByGroupChangeLog([from, to])
      }

      if (fieldName === 'transactionType' || fieldName === 'instrumentType') {
        return this.getNormalizedValueChangeLog(fieldName, [from, to])
      }

      if (
        ['amountCommitted', 'amountDistributed', 'amountInvested'].includes(
          fieldName
        )
      ) {
        return this.getChangedWithoutEntityFromTo(fieldName, [from, to])
      }
    }

    if (event === EventType.ADD_GROUP || event === EventType.ADD_USER) {
      return this.getSharedUpdateWithLog(text!, fromOutsideGroup)
    }

    if (event === EventType.REMOVE_GROUP || event === EventType.REMOVE_USER) {
      return this.getUnsharedUpdateWithLog(text!)
    }

    if (event === EventType.ADD_ATTACHMENT) {
      return this.getAddedAttachmentLog(to as string)
    }

    if (event === EventType.ADD_CONTENT) {
      return this.getAddedAttachmentLog(text as string)
    }

    if (event === EventType.REMOVE_ATTACHMENT) {
      return this.getRemovedAttachmentLog(from as string)
    }

    if (event === EventType.REMOVE_CONTENT) {
      return this.getRemovedAttachmentLog(text as string)
    }

    if (event === EventType.ADD_TAG) {
      return this.getAddedTagLog(text!)
    }

    if (event === EventType.REMOVE_TAG) {
      return this.getRemovedTagLog(text!)
    }

    if (
      event === EventType.ADD_SCHEDULE ||
      (event === EventType.UPDATE && fieldName === 'scheduleDate')
    ) {
      return this.getAddedScheduleDateLog(to as string, customValue)
    }

    if (event === EventType.ADD_TO_PORTFOLIO) {
      return this.getAddedTransactionToPortfolioLog(to as string)
    }

    if (event === EventType.REMOVE_FROM_PORTFOLIO) {
      return this.getRemovedTransactionFromPortfolioLog(to as string)
    }

    if (fieldName === 'repetition' || fieldName === 'scheduleDate') {
      return null
    }

    return this.getChangedLogFromTo(fieldName, [from, to])
  }
}
