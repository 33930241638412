import dayjs from 'dayjs'

import type { SortDirectionType } from 'utils/constants/sortDirection'
import { SortDirection } from 'utils/constants/sortDirection'

import { getQuarterDates, getYearDates } from 'utils/functions/date'
import { ValueOf } from 'utils/types/common'
import { IndexMetric } from 'utils/types/metricsV2'
import {
  ChartMetric,
  ChartPeriods,
  MetricDataPoint,
  MetricTypes,
  MOIC_NAME,
} from '../types'

/*
 * Sorting & Grouping Utils
 ******************************************** */

export const orderNumbersDesc = (num1, num2) => {
  return Number(num2) - Number(num1)
}

/**
 * Groups an array of dataPoints representing different metrics by date,
 * retaining the last updated value for each metric.
 */
export const groupDataPointsByDate = (
  dataPointsProp: MetricDataPoint[]
): MetricDataPoint[] => {
  const newDataPoints = dataPointsProp.reduce(
    (acc, dataPoint) => {
      const date = dayjs(dataPoint.date)
      const updatedAt = dayjs(dataPoint.updatedAt)
      const formattedDate = date.format('YYYY-MM-DD')

      if (acc[formattedDate]) {
        const metricDatapoint = acc[formattedDate].find(
          (point) => dataPoint.metricId === point.metricId
        )
        if (metricDatapoint) {
          if (updatedAt.isAfter(dayjs(metricDatapoint.updatedAt))) {
            acc[formattedDate].push(dataPoint)
            acc[formattedDate] = acc[formattedDate].filter(
              (point) => point.id !== metricDatapoint.id
            )
          }
        } else {
          acc[formattedDate].push(dataPoint)
        }
      } else {
        acc[formattedDate] = [dataPoint]
      }

      return acc
    },
    {} as Record<string, MetricDataPoint[]>
  )

  return Object.values(newDataPoints).flatMap((dataPoints) => dataPoints)
}

export const sortByDate = (
  metric1,
  metric2,
  criteria: SortDirectionType = SortDirection.DESC
) => {
  if (criteria === SortDirection.ASC) {
    return new Date(metric1.date).getTime() - new Date(metric2.date).getTime()
  }

  return new Date(metric2.date).getTime() - new Date(metric1.date).getTime()
}

export const sortChartMetricsByDate = (a: ChartMetric, b: ChartMetric) => {
  const yearA = parseInt(a.year, 10)
  const yearB = parseInt(b.year, 10)
  const quarterA = parseInt(a.quarter.substring(1), 10)
  const quarterB = parseInt(b.quarter.substring(1), 10)

  // Compare years
  if (yearA < yearB) {
    return -1
  }
  if (yearA > yearB) {
    return 1
  }

  // If years are equal, compare quarters
  if (quarterA < quarterB) {
    return -1
  }
  if (quarterA > quarterB) {
    return 1
  }

  // If both year and quarter are equal, maintain current order
  return 0
}

export const getDateRange = (
  start: Date,
  end: Date,
  groupingCriteria: ValueOf<ChartPeriods>
): { start: string; end: string } => {
  if (groupingCriteria === ChartPeriods.QUARTERLY) {
    const firstDateOfQuarter =
      getQuarterDates(start).firstDayOfQuarter.toDateString()
    const lastDateOfQuarter =
      getQuarterDates(end).lastDayOfQuarter.toDateString()

    return {
      start: firstDateOfQuarter,
      end: lastDateOfQuarter,
    }
  }

  if (groupingCriteria === ChartPeriods.YEARLY) {
    const firstDayOfYear = getYearDates(start).firstDayOfYear.toDateString()
    const lastDayOfYear = getYearDates(end).lastDayOfYear.toDateString()

    return {
      start: firstDayOfYear,
      end: lastDayOfYear,
    }
  }

  return {
    start: start.toDateString(),
    end: end.toDateString(),
  }
}

export const isMoicMetric = (metric: IndexMetric) =>
  metric.name === MetricTypes.MOIC || metric.name.includes(MOIC_NAME)
