import { MetricStates } from 'utils/constants/metrics'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { AppGroup } from './user'
import { Company } from './company'
import { IndexMetric, MetricSubjectMatterType } from './metricsV2'

export interface Metric {
  id: string
  name: string
  frequency: string
  dataPoints: DataPoint[]
  receiverGroups?: {
    id: string
    name: string
  }[]
  senderLinkedMetric?: LinkedMetric
  receiverLinkedMetrics?: LinkedMetric[]
  companyData?: Company
  metrizable?: string
  metrizableName?: string
  metrizableType?: PortfolioTypes
  metricSource: MetricSources
  milestones: Milestone[]
  investorsMilestones?: InvestorMilestone[]
  trackingSince?: string
  createdAt: string
}

export enum MetricSources {
  Custom = 'custom',
  System = 'system',
}

export interface DataPoint {
  id: string
  value: number
  date: string
  updatedAt: string
  archive: boolean
  sharedGroups?: AppGroup[]
}

export interface Milestone {
  id: string
  value: number
  date: string
  shared: boolean
  percentage: number
}

export interface InvestorMilestone extends Milestone {
  group: AppGroup
}

export enum MetricsMode {
  FOUNDER = 'FOUNDER',
  CLIENT = 'CLIENT',
}

export interface LinkedMetric {
  id: string
  state: MetricStates
  message?: string
  receiveData: boolean
  receiverGroup?: AppGroup
  receiverMetric?: Metric
  senderGroup?: AppGroup
  senderMetric?: Metric
  createdAt: string
}

export interface MetricsCard {
  id: string
  name: string
  metrics: IndexMetric[]
  metricSource?: MetricSources
  subjectId: string
  subjectType: MetricSubjectMatterType
}
