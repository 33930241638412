import { useCallback } from 'react'
import { useField, useFormikContext } from 'formik'

interface SelectorProps {
  selectorField: ReturnType<typeof useField<unknown>>
}

const useSelector = ({ selectorField }: SelectorProps) => {
  const [{ onChange, ...field }, _, helpers] = selectorField

  const formik = useFormikContext()

  const onSelectOption = useCallback(
    (option) => {
      helpers.setValue(option)
      setTimeout(() => {
        formik.handleBlur({ target: { name: field.name } })
      })
    },
    [field.name, formik, helpers]
  )

  return {
    onSelectOption,
  }
}

export default useSelector
