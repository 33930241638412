/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { Nullable } from 'utils/types/common'

interface RedirectState {
  redirectUrl: Nullable<string>
}

const initialState: RedirectState = {
  redirectUrl: null,
}

export const redirectSlice = createSlice({
  name: 'redirect',
  initialState,
  reducers: {
    setRedirectUrl: (state, action: PayloadAction<string | null>) => {
      state.redirectUrl = action.payload
    },
  },
})

export const { setRedirectUrl } = redirectSlice.actions

export const getRedirectUrl = (state: RootState) => state.redirect.redirectUrl

export default redirectSlice.reducer
