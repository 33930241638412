import { useHoldingSubjectData } from 'utils/hooks/subjects/useHoldingSubjectData'
import { Nullable } from 'utils/types/common'
import { getCompanyHoldingUrl } from 'utils/types/company'
import { useSubjectContext } from '../SubjectContext'

const useSubjectParent = () => {
  const { subject } = useSubjectContext()
  const { isFund, isVehicle } = useHoldingSubjectData(subject) // Add the `isInvestmentVehicle` field on this hook

  const parentSubject = subject.parentSubject || null
  let parentSubjectUrl: Nullable<string> = null

  if (isFund) {
    parentSubjectUrl = getCompanyHoldingUrl(parentSubject?.id!)
  } else if (isVehicle) {
    parentSubjectUrl = `/investor-management/investors/${subject.parentSubject?.id}/profile`
  }

  return {
    parentSubject,
    parentSubjectUrl,
  }
}

export default useSubjectParent
