import { BasicSubjectFragment } from 'types/graphql-schemas/graphql'
import { getInvestmentVehicleSubjectData } from './useInvestmentVehicleSubjectData'

export const useInvestmentVehiclesSubjectData = (
  subjects: BasicSubjectFragment[]
) => {
  const vehicles = subjects?.map((subject) =>
    getInvestmentVehicleSubjectData(subject)
  )

  return vehicles
}
