/* eslint-disable no-unreachable-loop */
/* eslint-disable no-restricted-syntax */

export const getSelectedOptionGroupedOptionIndexes = (
  optionToSearch,
  dropdownOptions
) => {
  let groupIndex
  let optionIndex

  dropdownOptions.forEach((option, indexI) => {
    option.subOptions.forEach((subOption, indexJ) => {
      if (optionToSearch.id === subOption.id) {
        groupIndex = indexI
        optionIndex = indexJ
      }
    })
  })

  return { groupIndex, optionIndex }
}

export const getFirstSubOption = (dropdownOptions) => {
  for (const option of dropdownOptions) {
    for (const subOption of option.subOptions) {
      return subOption
    }
  }
  return null
}

export const getNextSubOption = (groupIndex, optionIndex, dropdownOptions) => {
  if (dropdownOptions?.[groupIndex]?.subOptions?.[optionIndex + 1]) {
    return dropdownOptions[groupIndex].subOptions[optionIndex + 1]
  }
  if (dropdownOptions?.[groupIndex + 1]?.subOptions?.[0]) {
    return dropdownOptions[groupIndex + 1].subOptions[0]
  }
  return dropdownOptions[0].subOptions[0]
}

export const getPreviousSubOption = (
  groupIndex,
  optionIndex,
  dropdownOptions
) => {
  if (dropdownOptions?.[groupIndex]?.subOptions?.[optionIndex - 1]) {
    return dropdownOptions[groupIndex].subOptions[optionIndex - 1]
  }
  if (dropdownOptions?.[groupIndex - 1]?.subOptions?.at(-1)) {
    return dropdownOptions[groupIndex - 1].subOptions.at(-1)
  }

  return null
}

export const scrollDropdownOptionIntoView = (
  nextSelectedOption,
  dropdownContentRef
) => {
  const nextSelectedOptionElement = document.getElementById(
    `dropdownOption_${nextSelectedOption?.id}`
  )
  const selectedRect = nextSelectedOptionElement?.getClientRects()?.[0]
  const dropdownContentRect = dropdownContentRef.current?.getClientRects()?.[0]

  if (selectedRect?.bottom > dropdownContentRect?.bottom) {
    const scrollOffset =
      selectedRect.height - (dropdownContentRect.bottom - selectedRect.top) + 3

    dropdownContentRef.current.scrollBy(0, scrollOffset)
  } else if (selectedRect?.top < dropdownContentRect?.top) {
    const scrollOffset =
      (selectedRect?.top ?? 0) - (dropdownContentRect?.top ?? 0) - 3
    dropdownContentRef.current.scrollBy(0, scrollOffset)
  }
}
