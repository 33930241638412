import { SubjectMatterType } from 'api/UpdateService'
import {
  CalculationType,
  DataPointApi,
  IndexMetricApi,
  LinkedMetricIndexApi,
  MetricApi,
  MilestoneApi,
} from 'utils/types/api/metrics'
import { MetricSources } from 'utils/types/metrics'
import {
  DataPoint,
  IndexMetric,
  LinkedMetric,
  Metric,
  Milestone,
} from 'utils/types/metricsV2'
import { SubjectType } from 'utils/types/subjects'

export const getHoldingTypeBySubjectType = (subjectType?: SubjectType) => {
  switch (subjectType) {
    case SubjectType.COMPANY:
      return SubjectMatterType.COMPANY
    case SubjectType.FUND:
      return SubjectMatterType.FUND_PORTFOLIO
    case SubjectType.DEAL:
      return SubjectMatterType.DEAL_PORTFOLIO
    default:
      return SubjectMatterType.COMPANY
  }
}

export class MetricsNormalizer {
  static metrics = (
    metrics: IndexMetricApi[],
    holding?: {
      id: string
      type: SubjectType
    }
  ): IndexMetric[] => {
    return metrics.map((metric) => {
      const subjectId =
        metric.subjectType === 'Holding'
          ? holding!.id
          : metric.metadata.dependingSubjectId ?? metric.subjectId

      const typeToUse =
        metric.subjectType === 'Holding'
          ? getHoldingTypeBySubjectType(holding!.type)
          : metric.metadata.dependingSubjectType ?? metric.subjectType

      return {
        id: metric.id,
        name: metric.name,
        subject: {
          id: subjectId,
          type: typeToUse,
          name: metric.metadata.subjectName,
        },
        source: metric.metadata.source,
        dataPoints: metric.dataPointObjects
          ? MetricsNormalizer.dataPoints(
              metric.dataPointObjects,
              metric.metadata.source === MetricSources.System
            )
          : [],
        updatedAt: new Date(`${metric.updatedAt}Z`),
        createdAt: new Date(`${metric.createdAt}Z`),
        milestones: metric.milestones,
        receiverMetricLink: MetricsNormalizer.linkedMetricsIndex(
          metric.receiverLinks ?? []
        )[0],
        senderLinkedMetric: metric.senderMetrics
          ?.filter((link) => !!link)
          .map(MetricsNormalizer.metric)[0],
        senderLinks: MetricsNormalizer.linkedMetricsIndex(
          metric.senderLinks ?? []
        ),
        isCumulativeMetric:
          metric.metadata.calculationType ===
          CalculationType.SHALLOW_CALCULATION,
      }
    })
  }

  static dataPoints = (
    dataPoints: DataPointApi[],
    isSystemMetric: boolean = false
  ): DataPoint[] => {
    let filteredDatapoints: DataPointApi[] = dataPoints

    if (isSystemMetric) {
      const seenKeys = new Set<string>()
      const uniqueDataPoints: DataPointApi[] = []

      dataPoints.forEach((dataPoint) => {
        const dateKey = dataPoint.timestamp.split('T')[0]
        const key = `${dateKey}_${dataPoint.value}`

        if (!seenKeys.has(key)) {
          seenKeys.add(key)
          uniqueDataPoints.push(dataPoint)
        }
      })

      filteredDatapoints = uniqueDataPoints
    }

    return filteredDatapoints.map((dataPoint) => {
      return {
        id: dataPoint.id,
        value: dataPoint.value,
        date: new Date(`${dataPoint.timestamp}Z`),
        archived: dataPoint.metadata.archived || false,
        updatedAt: new Date(`${dataPoint.updatedAt}Z`),
        sharedGroups: dataPoint.sharedGroups,
        groupId: dataPoint.groupId,
      }
    })
  }

  static milestones = (milestones: MilestoneApi[]): Milestone[] => {
    return milestones.map((milestone) => {
      return {
        ...milestone,
        id: milestone.id,
        value: milestone.value,
        date: new Date(`${milestone.timestamp}Z`),
        groupId: milestone.metadata.groupId,
      }
    })
  }

  static metric = (metric: MetricApi): Metric => {
    return {
      id: metric.id,
      name: metric.name,
      subject: {
        id: metric.subjectId,
        type: metric.subjectType,
        name: metric.metadata.subjectName,
      },
      frequency: metric.frequency,
      source: metric.metadata.source,
      dataPoints: metric.dataPoints,
      milestones: metric.milestones,
      updatedAt: new Date(`${metric.updatedAt}Z`),
      createdAt: new Date(`${metric.createdAt}Z`),
      receiverMetricLink: MetricsNormalizer.linkedMetricsIndex(
        metric.receiverLinks ?? []
      )[0],
      senderLinkedMetric: metric.senderMetrics
        ?.filter((link) => !!link)
        .map(MetricsNormalizer.metric)[0],
      senderLinks: MetricsNormalizer.linkedMetricsIndex(
        metric.senderLinks ?? []
      ),
      isCumulativeMetric:
        metric.metadata.calculationType === CalculationType.SHALLOW_CALCULATION,
    }
  }

  static linkedMetricsIndex = (
    linkedMetrics: LinkedMetricIndexApi[]
  ): LinkedMetric[] => {
    return linkedMetrics.map((linkedMetric) => {
      return {
        ...linkedMetric,
        updatedAt: new Date(`${linkedMetric.updatedAt}Z`),
        createdAt: new Date(`${linkedMetric.createdAt}Z`),
        senderMetricId:
          linkedMetric.senderMetricId === 'None'
            ? undefined
            : linkedMetric.senderMetricId,
      }
    })
  }
}
