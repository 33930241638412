/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import crunchbaseLogo from 'assets/images/crunchbaselogo.svg'
import xTwitterLogo from 'assets/images/x-twitter.svg'

import Title from 'components/Title'
import Input from 'ui/Input'

import { getUrlFieldsValidationsSchema } from 'utils/schemas/teamMembers'

import { LinksContainer } from './CompanyUrls.styles'

type CompanyUrlsProps = {
  onEditField: (field: { key: string; value: string }) => void
  companyData: {
    linkedinUrl: string
    crunchbaseUrl: string
    twitterUrl: string
  }
  iconStyle: string
}
const CompanyUrls = ({
  onEditField,
  companyData,
  iconStyle,
}: CompanyUrlsProps) => {
  const intl = useIntl()

  const schema = getUrlFieldsValidationsSchema(intl.messages)

  const { errors, register, setValue, getValues } = useForm({
    validationSchema: schema,
    mode: 'onChange',
  })

  useEffect(() => {
    if (companyData) {
      const { linkedinUrl, crunchbaseUrl, twitterUrl } = companyData
      setValue([{ linkedinUrl }, { crunchbaseUrl }, { twitterUrl }])
    }
  }, [companyData])

  const onBlur = (key) => {
    const transformedValues = schema.cast(getValues())!
    onEditField({ key, value: transformedValues[key] })
  }

  return (
    <LinksContainer>
      <Title title={intl.formatMessage({ id: 'editCompany.directories' })} />

      <Input
        id="crunchbaseUrl"
        onBlur={() => !errors.crunchbaseUrl && onBlur('crunchbaseUrl')}
        name="crunchbaseUrl"
        label={intl.formatMessage({ id: 'editCompany.crunchbase' })}
        placeholder={intl.formatMessage({
          id: 'editCompany.companyCrunchbasePlaceholder',
        })}
        error={errors.crunchbaseUrl}
        ref={register}
        prefix={
          <img src={crunchbaseLogo} alt="crunchbase" className={iconStyle} />
        }
      />

      <Title title={intl.formatMessage({ id: 'editCompany.socialMedia' })} />

      <Input
        id="linkedinUrl"
        onBlur={() => !errors.linkedinUrl && onBlur('linkedinUrl')}
        name="linkedinUrl"
        label={intl.formatMessage({ id: 'editCompany.companyLinkedin' })}
        placeholder={intl.formatMessage({
          id: 'editCompany.companyLinkedinPlaceholder',
        })}
        error={errors.linkedinUrl}
        ref={register}
        prefix={
          <FontAwesomeIcon
            icon={['fab', 'linkedin-in']}
            className={iconStyle}
          />
        }
      />

      <Input
        id="twitterUrl"
        onBlur={() => !errors.twitterUrl && onBlur('twitterUrl')}
        name="twitterUrl"
        label={intl.formatMessage({ id: 'editCompany.companyX' })}
        placeholder={intl.formatMessage({
          id: 'editCompany.companyXPlaceholder',
        })}
        error={errors.twitterUrl}
        ref={register}
        prefix={
          <img
            src={xTwitterLogo}
            alt="x twitter"
            style={{
              width: '1.6rem',
              height: '1.6rem',
              marginTop: '3px',
            }}
          />
        }
      />
    </LinksContainer>
  )
}

export default CompanyUrls
