import React, { RefObject, useCallback, useRef } from 'react'
import { VariableSizeList as List } from 'react-window'
import { Nullable } from 'utils/types/common'

export interface TableLayoutHelpers {
  listRef: React.RefObject<List>
  fixedListRef: React.RefObject<List>
  scrollElement: Nullable<HTMLElement>
  listContainerRef: RefObject<HTMLElement>
  fixedListContainerRef: RefObject<HTMLElement>
  fakeScrollRef: RefObject<HTMLElement>
  setSize: (index: number, size: number) => void
  getSize: (index: number) => number
}

export const useTableLayout = ({
  scrollElementId = 'mainLayout',
  rowHeight,
}: {
  scrollElementId?: string
  rowHeight: number
}): TableLayoutHelpers => {
  const listRef = useRef<List>(null)
  const listContainerRef = useRef<HTMLElement>(null)

  const fixedListRef = useRef<List>(null)
  const fixedListContainerRef = useRef<HTMLElement>(null)

  const fakeScrollRef = useRef<HTMLDivElement>(null)

  const sizeMap = useRef({})
  const scrollElement = document.getElementById(scrollElementId)

  const setSize = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size }
    listRef.current?.resetAfterIndex(index)
    fixedListRef.current?.resetAfterIndex(index)
  }, [])

  const getSize = (index: number): number => sizeMap.current[index] || rowHeight

  return {
    listRef,
    fixedListRef,
    scrollElement,
    listContainerRef,
    fixedListContainerRef,
    fakeScrollRef,
    setSize,
    getSize,
  }
}
