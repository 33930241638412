import { useEffect } from 'react'
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll'

import {
  PAGE_SIZE,
  SubjectQueryProps,
  useSubjectsQuery,
} from 'utils/hooks/queries/subjects/useSubjectsQuery'

// interface Props {
//   currentFilters: SubjectQueryProps
//   tagsNameValue?: string[]
// }

export const useSubjectsInfiniteQuery = (filters: SubjectQueryProps) => {
  const {
    ref: endOfPageRef,
    onScreen,
    setInfiniteScrollEnabled,
  } = useInfiniteScroll()

  const query = useSubjectsQuery(filters, {
    onCompleted: (data) => {
      if (data.subjects?.length < (filters?.pageSize || PAGE_SIZE)) {
        setInfiniteScrollEnabled(false)
      }
    },
  })

  useEffect(() => {
    if (onScreen) {
      query.fetchMore()
    }
  }, [onScreen, query])

  return {
    ...query,
    endOfPageRef,
  }
}
