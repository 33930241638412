import { UpdateTypes } from 'utils/constants/updates'

import { getAccountingReportUrlByPlainUpdateType } from 'utils/functions/accounting'
import {
  CreateTransactionUpdatePayload,
  EditTransactionUpdatePayload,
} from 'utils/functions/api/transactions'
import ResponseNormalizer from 'utils/functions/responseNormalizer'
import { getTagsFromTaggings } from 'utils/functions/tags'
import { Nullable, PlainUpdateType, UpdateType } from 'utils/types/common'

import {
  ScheduledUpdate,
  Tag,
  TotalUpdatesReport,
  Update,
} from 'utils/types/update'
import {
  CreateUpdatePayload,
  EditUpdatePayload,
} from 'utils/types/updatePayloads'
import axiosClient from './httpClient'

export enum UpdateFilter {
  PORTFOLIO_OR_COMPANIES = 'q[belonging_to_portfolio_or_companies_in_portfolio]',
  SUBJECT_MATTER_ID_IN = 'q[subject_matter_id_in]',
  SUBJECT_MATTER_ID_EQ = 'q[subject_matter_id_eq]',
  SUBJECT_MATTER_TYPE_EQ = 'q[subject_matter_type_eq]',
  UPDATE_TYPE_IN = 'q[updatable_type_in]',
  TEXT = 'q[text]',
  INVESTOR_MANAGEMENT_ENTITY = 'q[belonging_to_investor_group_or_investment_vehicle]',
}

export enum SubjectMatterType {
  COMPANY = 'Business::CompanyDatum',
  PORTFOLIO = 'Portfolios::Portfolio',
  FUND_PORTFOLIO = 'Portfolios::FundPortfolio',
  INVEST_PORTFOLIO = 'Portfolios::InvestPortfolio',
  TRACK_PORTFOLIO = 'Portfolios::TrackPortfolio',
  DEAL_PORTFOLIO = 'Portfolios::DealPortfolio',
  INVESTOR = 'Investors::InvestorGroup',
}

export interface CommonParams {
  page: number
  tags?: string[]
  types?: string[]
  text?: string
}
class UpdateService {
  static createIUEReport = (id: string, body) => {
    return axiosClient().post(`/companies/${id}/iues`, body)
  }

  static getUpdate = (id: string) => {
    return axiosClient().get(`/updates/${id}`)
  }

  static async fetchUpdate<T extends Update = Update>(id: string) {
    const {
      data: { result, entities },
    } = await axiosClient().get(`/updates/${id}`)

    return ResponseNormalizer.normalizeUpdate<T>(result, entities)
  }

  static async fetchAttachmentZipUrl(id: string): Promise<string> {
    const {
      data: { zipFile },
    } = await axiosClient().get(`/updates/${id}/zip_file_attachment`)
    return zipFile
  }

  static createAnnouncement = (
    entityName,
    entityId: string,
    body: CreateUpdatePayload<'announcement'>
  ) => {
    return axiosClient().post(`/${entityName}/${entityId}/announcements`, body)
  }

  static editAnnouncement = (
    id: string,
    body: EditUpdatePayload<'announcement'>
  ) => {
    return axiosClient().patch(`/announcements/${id}`, body)
  }

  static deleteAnnouncement = (id: string) => {
    return axiosClient().delete(`/announcements/${id}`)
  }

  static createNote = (
    entityName,
    entityId: string,
    body: CreateUpdatePayload<'note'>
  ) => {
    return axiosClient().post(`/${entityName}/${entityId}/notes`, body)
  }

  static editNote = (id: string, body: EditUpdatePayload<'note'>) => {
    return axiosClient().patch(`/notes/${id}`, body)
  }

  static deleteNote = (id: string) => {
    return axiosClient().delete(`/notes/${id}`)
  }

  static createDocument = (
    entityName,
    entityId: string,
    body: CreateUpdatePayload<'document'>
  ) => {
    return axiosClient().post(`/${entityName}/${entityId}/documents`, body)
  }

  static deleteUpdate = (
    id: string,
    updateType: UpdateType,
    plainUpdateType: PlainUpdateType
  ) => {
    let url = `${updateType}s`
    if (updateType === UpdateTypes.ACCOUNTING) {
      url = getAccountingReportUrlByPlainUpdateType(plainUpdateType)
    }
    return axiosClient().delete(`/${url}/${id}`)
  }

  static editDocument = (id: string, body: EditUpdatePayload<'document'>) => {
    return axiosClient().patch(`/documents/${id}`, body)
  }

  static deleteDocument = (id: string) => {
    return axiosClient().delete(`/documents/${id}`)
  }

  static createAccounting = (entityName, entityId, integrationName, body) => {
    switch (integrationName) {
      case 'xero_report':
        return axiosClient().post(
          `/${entityName}/${entityId}/xero_reports`,
          body
        )
      case 'quickbooks_report':
        return axiosClient().post(
          `/${entityName}/${entityId}/quickbooks_reports`,
          body
        )
      default:
        return Promise.reject({ error: 'Integration name was not found' })
    }
  }

  static editAccounting = (id, integrationName, body) => {
    return axiosClient().patch(`/${integrationName}/${id}`, body)
  }

  static deleteAccounting = (id, integrationName) => {
    return axiosClient().delete(`/${integrationName}/${id}`)
  }

  static editEmail = (id: string, body: any) => {
    return axiosClient(true).patch(`/iues/${id}`, body)
  }

  static getLogEvents = async (itemType, itemId, page, perPage) => {
    const {
      data: {
        entities: { events = {}, users = {}, groups = {} },
        result,
      },
    } = await axiosClient().get(
      `/${itemType}/${itemId}/events?page=${page}&per_page=${perPage}`
    )
    return result.map((eventId) => {
      const event = events[eventId]

      return {
        ...event,
        user: users[event?.user],
        group: groups[event.group]?.name,
      }
    })
  }

  static removeTagFromUpdate = async (updateId: string, taggingId: string) => {
    return axiosClient().patch(`/updates/${updateId}/update_group_scoped`, {
      update: {
        taggingsAttributes: [
          {
            id: taggingId,
            _destroy: true,
          },
        ],
      },
    })
  }

  static addTagToUpdate = async (updateId: string, tag: Tag) => {
    const taggingsAttributes: any = []
    if (tag.alreadyExists) {
      taggingsAttributes.push({
        id: null,
        tagId: tag.id,
      })
    } else {
      taggingsAttributes.push({
        tagId: null,
        tagAttributes: { name: tag.name },
      })
    }

    const {
      data: { entities },
    } = await axiosClient().patch(`/updates/${updateId}/update_group_scoped`, {
      update: {
        taggingsAttributes,
      },
    })

    const tags = getTagsFromTaggings(Object.values(entities.taggings))

    const addedTag = tags.find((currTag) => currTag.name === tag.name)
    return {
      ...tag,
      alreadyExists: true,
      taggingId: addedTag.taggingId,
    }
  }

  static updateTagsByUpdateId = (updateId, tag) => {
    type Tag = {
      id?: Nullable<string>
      tag_id?: Nullable<string>
      tag_attributes?: { name: string }
      _destroy?: boolean
    }
    const taggingsAttributes: Tag[] = []
    if (!tag?.taggingId) {
      if (tag.alreadyExists) {
        taggingsAttributes.push({
          id: null,
          tag_id: tag.id,
        })
      }
      if (!tag.alreadyExists) {
        taggingsAttributes.push({
          tag_id: null,
          tag_attributes: { name: tag.name },
        })
      }
      if (tag.destroy) {
        taggingsAttributes.push({
          id: tag.id,
          _destroy: true,
        })
      }
    } else if (tag.destroy) {
      taggingsAttributes.push({
        id: tag.taggingId,
        _destroy: true,
      })
    }

    return axiosClient().patch(`/updates/${updateId}/update_group_scoped`, {
      update: {
        taggingsAttributes,
      },
    })
  }

  static createCompanyTransaction = (
    companyId: string,
    body: CreateTransactionUpdatePayload
  ) => {
    return axiosClient().post(`/companies/${companyId}/transactions`, body)
  }

  static createFundTransaction = (
    fundPortfolioId: string,
    body: CreateTransactionUpdatePayload
  ) => {
    return axiosClient().post(
      `/fund_portfolios/${fundPortfolioId}/transactions`,
      body
    )
  }

  static createInvestorTransaction = (
    investorGroupId: string,
    body: CreateTransactionUpdatePayload
  ) => {
    return axiosClient().post(
      `/investor_groups/${investorGroupId}/transactions`,
      body
    )
  }

  static editTransaction = (id: string, body: EditTransactionUpdatePayload) => {
    return axiosClient().patch(`/transactions/${id}`, body)
  }

  static deleteTransaction = (id: string) => {
    return axiosClient().delete(`/transactions/${id}`)
  }

  static getUpdatesMetrics = (
    portfolioId: string | string[],
    monthAgo: number
  ) => {
    if (Array.isArray(portfolioId)) {
      const portfolioIdsQuery = portfolioId
        .map((id) => `portfolio_ids[]=${id}`)
        .join('&')

      return axiosClient().get(
        `/updates/metrics?month_ago=${monthAgo}&${portfolioIdsQuery}`
      )
    }

    return axiosClient().get(
      `/updates/metrics?month_ago=${monthAgo}&portfolio_ids[]=${portfolioId}`
    )
  }

  static getTotalUpdatesReport = async (
    portfolioId,
    monthA = 0,
    monthB = 1
  ): Promise<TotalUpdatesReport> => {
    const [thisMonthResponse, lastMonthResponse] = await Promise.all([
      this.getUpdatesMetrics(portfolioId, monthA),
      this.getUpdatesMetrics(portfolioId, monthB),
    ])
    const thisMonth = thisMonthResponse.data
    const lastMonth = lastMonthResponse.data

    const totalUpdatesThisMonth = Object.values(thisMonth || {}).reduce<number>(
      (res: number, total: number) => res + total,
      0
    )
    const totalUpdatesLastMonth = Object.values(lastMonth || {}).reduce<number>(
      (res: number, total: number) => res + total,
      0
    )

    const calculateTotals = (updateType) => {
      const noValue = !thisMonth[updateType] && thisMonth[updateType] !== 0
      if (noValue) return null

      return {
        thisMonth: thisMonth[updateType],
        lastMonth: lastMonth[updateType],
        growth:
          thisMonth[updateType] === lastMonth[updateType]
            ? null
            : thisMonth[updateType] > lastMonth[updateType],
      }
    }

    return {
      totalUpdates: {
        thisMonth: totalUpdatesThisMonth,
        lastMonth: totalUpdatesLastMonth,
        growth:
          totalUpdatesThisMonth === totalUpdatesLastMonth
            ? null
            : totalUpdatesThisMonth > totalUpdatesLastMonth,
      },
      announcements: calculateTotals('announcements'),
      documents: calculateTotals('documents'),
      notes: calculateTotals('notes'),
      reports: calculateTotals('reports'),
      transactions: calculateTotals('transactions'),
      accountingReports: calculateTotals('accountingReports'),
    }
  }

  static getScheduledUpdatesByMonth = async (
    from: string,
    to: string,
    companyId: string
  ): Promise<ScheduledUpdate[]> => {
    const response = await axiosClient().get(
      `/updates/scheduled?from=${from}&to=${to}&q[subject_matter_type_eq]=Business::CompanyDatum&q[subject_matter_id_eq]=${companyId}`
    )

    const {
      data: { entities, result },
    } = response

    return result.map((updateId: string) =>
      ResponseNormalizer.normalizeScheduledUpdate(updateId, entities)
    )
  }

  static getTransactionUpdate = async (transactionId) => {
    return axiosClient().get(`/transactions/${transactionId}`)
  }

  static async reshareUpdate(
    updateId: string,
    resharedUserIds: string[],
    resharedGroupIds: string[],
    message: string
  ): Promise<Update> {
    return axiosClient().patch(`/updates/${updateId}/reshare`, {
      update: {
        loggingUpdateGroupsAttributes: resharedGroupIds.map((groupId) => ({
          groupableId: groupId,
          loggingResharesAttributes: [{ message }],
        })),

        loggingUpdateUsersAttributes: resharedUserIds.map((userId) => ({
          id: null,
          userId,
          loggingResharesAttributes: [{ message }],
        })),
      },
    })
  }

  static async editReshare(
    token: string,
    message: string,
    groups: string[],
    users: string[],
    removedGroups: string[],
    removedUsers: string[]
  ) {
    return axiosClient().patch(`/reshares/${token}/update_by_token`, {
      reshare: {
        message,
        loggingUpdateGroupsAttributes: [
          ...groups.map((groupId) => ({ id: null, groupableId: groupId })),
          ...removedGroups.map((loggingUpdateGroupId) => ({
            id: loggingUpdateGroupId,
            _destroy: true,
          })),
        ],
        loggingUpdateUsersAttributes: [
          ...users.map((userId) => ({ id: null, userId })),
          ...removedUsers.map((loggingUpdateUserId) => ({
            id: loggingUpdateUserId,
            _destroy: true,
          })),
        ],
      },
    })
  }
}

export default UpdateService
