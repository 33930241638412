import { useMetricName } from 'utils/hooks/metrics/useMetricName'
import { IndexMetric } from 'utils/types/metricsV2'

import { useMetricsSubjectQuery } from 'utils/hooks/useMetricsSubjectQuery'
import * as Styles from './MetricName.styles'

interface MetricNameProps {
  metric: IndexMetric
  onNameClick?: () => void
  size?: string
  customStyle?: React.CSSProperties
}

const MetricName: React.FC<MetricNameProps> = ({
  metric,
  size,
  customStyle,
  onNameClick,
}) => {
  const { metricName } = useMetricName(metric)
  const { data: subject } = useMetricsSubjectQuery(
    {
      subjectId: metric.subject.id,
      subjectType: metric.subject.type,
    },
    {
      enabled: !metric.subject.name,
    }
  )

  const subjectName = metric.subject.name || subject?.name

  return metricName ? (
    <Styles.MetricName size={size} onClick={onNameClick} style={customStyle}>
      {metricName} ({subjectName})
    </Styles.MetricName>
  ) : null
}

export default MetricName
