import type { IntlShape } from 'react-intl'
import {
  ErrorType,
  ValidateManyResult,
  ValidateResult,
  ValidateSubjectResult,
} from 'types/graphql-schemas/graphql'
import {
  AddHoldingBulkImportFormErrors,
  AddHoldingForm,
  AddHoldingFormErrors,
  BulkImportSuggestionErrors,
  HoldingType,
  ProfileData,
  SuggestionErrors,
} from './types'

export interface DuplicatedErrorData {
  id?: string
  name?: string
}

interface DuplicatedFundNamesError {
  type: 'DuplicatedFundNamesError'
  duplicatedFunds: DuplicatedErrorData[]
  returnedFunds: DuplicatedErrorData[]
}

interface DuplicatedFundManagerWebsiteError {
  type: 'DuplicatedFundManagerWebsiteError'
  company?: DuplicatedErrorData
}

interface DuplicatedCompanyWebsiteError {
  type: 'DuplicatedCompanyWebsiteError'
  company?: DuplicatedErrorData
}

type BackendError =
  | DuplicatedFundNamesError
  | DuplicatedFundManagerWebsiteError
  | DuplicatedCompanyWebsiteError

const isDuplicatedFundNamesError = (
  backendError: BackendError
): backendError is DuplicatedFundNamesError => {
  return backendError.type === 'DuplicatedFundNamesError'
}

const isDuplicatedFundManagerWebsiteError = (
  backendError: BackendError
): backendError is DuplicatedFundManagerWebsiteError => {
  return backendError.type === 'DuplicatedFundManagerWebsiteError'
}

const isDuplicatedCompanyWebsiteError = (
  backendError: BackendError
): backendError is DuplicatedCompanyWebsiteError => {
  return backendError.type === 'DuplicatedCompanyWebsiteError'
}

export const getSuggestionErrors = (
  error: BackendError,
  values: AddHoldingForm
): SuggestionErrors | undefined => {
  if (values.type === HoldingType.FUND) {
    if (isDuplicatedFundNamesError(error)) {
      const names = values.funds?.funds ?? []

      return {
        fundsErrors: names.map((fundName) => {
          if (error.duplicatedFunds.some((fund) => fund.name === fundName!)) {
            const existentHoldingsInfo = error.returnedFunds.find(
              (holdingResponse) => holdingResponse.name === fundName
            )

            if (existentHoldingsInfo) {
              return [
                {
                  id: existentHoldingsInfo.id,
                  name: existentHoldingsInfo.name,
                  url: `/funds/${existentHoldingsInfo.id}`,
                  holding: existentHoldingsInfo,
                },
              ]
            }
            return [
              {
                name: fundName,
              },
            ]
          }

          return []
        }) as ProfileData[][],
      }
    }

    if (isDuplicatedFundManagerWebsiteError(error)) {
      return {
        companyErrors: {
          isHidden: !error.company,
          website: error.company
            ? {
                id: error.company.id,
                name: error.company.name!,
                url: `/companies/${error.company.id}`,
              }
            : undefined,
        },
      }
    }
  }

  if (values.type === HoldingType.COMPANY) {
    if (isDuplicatedCompanyWebsiteError(error)) {
      return {
        companyErrors: {
          isHidden: !error.company,
          website: error.company
            ? {
                id: error.company.id,
                name: error.company.name!,
                url: `/companies/${error.company.id}`,
              }
            : undefined,
        },
      }
    }
  }

  return undefined
}

export const SUGGESTION_ERROR: string = 'SUGGESTION_ERROR'

export const getInitialErrorsFromSuggestionErrors = (
  suggestionErrors: SuggestionErrors
): AddHoldingFormErrors => {
  return {
    funds: {
      funds: (suggestionErrors.fundsErrors || []).map((fundErrors) =>
        fundErrors.length ? SUGGESTION_ERROR : undefined
      ),
      fundManager: {
        website:
          suggestionErrors.companyErrors?.website ||
          suggestionErrors.companyErrors?.isHidden
            ? SUGGESTION_ERROR
            : undefined,
      },
    },
    company: {
      website:
        suggestionErrors.companyErrors?.website ||
        suggestionErrors.companyErrors?.isHidden
          ? SUGGESTION_ERROR
          : undefined,
    },
  }
}

const fundManagerChanged = (
  previousValues: AddHoldingForm,
  values: AddHoldingForm
): boolean => {
  const nowIncludesFundManager =
    !previousValues.funds?.includeFundManager &&
    values.funds?.includeFundManager

  const fundManagerNameChanged =
    previousValues.funds?.fundManager?.name !== values.funds?.fundManager?.name

  const fundManagerWebsiteChanged =
    previousValues.funds?.fundManager?.website !==
    values.funds?.fundManager?.website

  const fundManagerPointOfContactChanged =
    previousValues.funds?.fundManager?.pointOfContact !==
    values.funds?.fundManager?.pointOfContact

  return (
    nowIncludesFundManager ||
    (!!values.funds?.includeFundManager &&
      (fundManagerNameChanged ||
        fundManagerWebsiteChanged ||
        fundManagerPointOfContactChanged))
  )
}

export const validateServerErrors = (
  errors: AddHoldingFormErrors,
  values: AddHoldingForm,
  previousValues: AddHoldingForm
) => {
  if (values.type === HoldingType.COMPANY) {
    if (
      errors?.company?.website &&
      values.company?.website !== previousValues.company?.website
    ) {
      // eslint-disable-next-line no-param-reassign
      delete errors.company.website
    }
  }

  if (values.type === HoldingType.FUND) {
    values.funds?.funds.forEach((fund, index) => {
      if (
        Array.isArray(errors?.funds?.funds) &&
        errors?.funds?.funds?.[index] &&
        (fund !== previousValues.funds?.funds[index] ||
          fundManagerChanged(previousValues, values))
      ) {
        // eslint-disable-next-line no-param-reassign
        errors.funds.funds[index] = undefined
      }
    })

    const changedWebsite =
      values.funds?.fundManager?.website !==
      previousValues.funds?.fundManager?.website
    const removedFundManager = !values.funds?.includeFundManager

    if (
      errors?.funds?.fundManager?.website &&
      (changedWebsite || removedFundManager)
    ) {
      // eslint-disable-next-line no-param-reassign
      delete errors.funds.fundManager.website
    }
  }
}

export const hasFundsErrors = (errors: AddHoldingFormErrors) => {
  const hasDuplicatedFundNames =
    Array.isArray(errors?.funds?.funds) && errors.funds.funds.some(Boolean)
  const hasntFilledAFundName = typeof errors?.funds?.funds === 'string'
  const hasFundManagerWebsiteError = !!errors?.funds?.fundManager?.website

  return (
    hasDuplicatedFundNames || hasntFilledAFundName || hasFundManagerWebsiteError
  )
}

export const hasServerErrors = (
  errors: AddHoldingFormErrors,
  values: AddHoldingForm
) => {
  return values.type === HoldingType.COMPANY
    ? !!errors?.company?.website
    : hasFundsErrors(errors)
}

export const hasBulkImportServerErrors = (
  errors: AddHoldingBulkImportFormErrors,
  values: AddHoldingForm[]
) => {
  const serverErrors = errors.holdings.filter((holdingErrors, index) => {
    return hasServerErrors(holdingErrors, values[index])
  })

  return !!serverErrors.length
}

const fillHoldingErrors = async (
  holding: AddHoldingForm,
  holdingDraft: ValidateSubjectResult,
  index: number,
  errors: AddHoldingBulkImportFormErrors,
  status: BulkImportSuggestionErrors
) => {
  if (holding.type === HoldingType.COMPANY) {
    const companyHoldingDraft = holdingDraft as ValidateResult
    if (
      !companyHoldingDraft.typeIdentifier.isValid &&
      companyHoldingDraft.typeIdentifier.errorType === ErrorType.Duplicated
    ) {
      const error: DuplicatedCompanyWebsiteError = {
        type: 'DuplicatedCompanyWebsiteError',
        company: companyHoldingDraft.typeIdentifier.duplicatedObject
          ? {
              id: companyHoldingDraft.typeIdentifier.duplicatedObject.id,
              name: companyHoldingDraft.typeIdentifier.duplicatedObject.name,
            }
          : undefined,
      }

      const suggestionErrors = await getSuggestionErrors(error, holding)

      if (suggestionErrors) {
        // eslint-disable-next-line no-param-reassign
        errors.holdings[index] =
          getInitialErrorsFromSuggestionErrors(suggestionErrors)
        // eslint-disable-next-line no-param-reassign
        status.holdings[index] = suggestionErrors
      }
    }
  }

  if (holding.type === HoldingType.FUND) {
    let websiteSuggestionError
    let fundNamesSuggestionError
    const fundHoldingDraft = holdingDraft as ValidateManyResult
    if (
      fundHoldingDraft.parentSubject &&
      !fundHoldingDraft.parentSubject?.isValid &&
      holdingDraft.parentSubject?.errorType === ErrorType.Duplicated
    ) {
      const error: DuplicatedFundManagerWebsiteError = {
        type: 'DuplicatedFundManagerWebsiteError',
        company: holdingDraft.parentSubject.duplicatedObject
          ? {
              id: holdingDraft.parentSubject.duplicatedObject?.id!,
              name: holdingDraft.parentSubject.duplicatedObject?.name!,
            }
          : undefined,
      }

      websiteSuggestionError = await getSuggestionErrors(error, holding)
    }
    const duplicatedFunds = fundHoldingDraft.names
      .filter((fundResult) => !fundResult.typeIdentifier.isValid)
      .map((fundResult) => {
        return {
          id: fundResult.typeIdentifier.duplicatedObject?.id,
          name: fundResult.typeIdentifier.duplicatedObject?.name,
        }
      })

    if (duplicatedFunds.length) {
      const error: DuplicatedFundNamesError = {
        type: 'DuplicatedFundNamesError',
        duplicatedFunds,
        returnedFunds: duplicatedFunds,
      }
      fundNamesSuggestionError = await getSuggestionErrors(error, holding)
    }

    let suggestionErrors = {}
    if (websiteSuggestionError) {
      suggestionErrors = { ...websiteSuggestionError }
    }
    if (fundNamesSuggestionError) {
      suggestionErrors = {
        ...suggestionErrors,
        ...fundNamesSuggestionError,
      }
    }

    if (Object.keys(suggestionErrors).length) {
      // eslint-disable-next-line no-param-reassign
      errors.holdings[index] =
        getInitialErrorsFromSuggestionErrors(suggestionErrors)
      // eslint-disable-next-line no-param-reassign
      status.holdings[index] = suggestionErrors
    }
  }
}

export const getHoldingsErrorsFromDrafts = async (
  drafts: ValidateSubjectResult[],
  holdings: AddHoldingForm[]
) => {
  const errors: AddHoldingBulkImportFormErrors = {
    holdings: holdings.map(() => ({
      funds: {
        funds: [],
      },
      company: {},
    })),
  }
  const status: BulkImportSuggestionErrors = {
    holdings: [],
  }

  const promises = drafts.map(async (holdingDraft, index) => {
    const holding = holdings[index]
    await fillHoldingErrors(holding, holdingDraft, index, errors, status)
  })
  await Promise.all(promises)

  return {
    errors,
    status,
  }
}

export const setRepeatedFundErrors = (
  funds: string[],
  errors: AddHoldingFormErrors,
  intl: IntlShape
) => {
  const repeatedIndexes: number[] = []

  funds.forEach((fund, index) => {
    if (fund) {
      for (let i = index + 1; i < funds.length; i++) {
        if (fund.trim() === funds[i].trim()) {
          repeatedIndexes.push(i)
        }
      }
    }
  })

  repeatedIndexes.forEach((index) => {
    const fundsErrors = errors.funds?.funds as string[]
    fundsErrors[index] = intl.formatMessage({
      id: 'addHolding.errors.repeatedFundName',
    })
  })
}

export const setRepeatedBulkErrors = (
  holdings: AddHoldingForm[],
  errors: AddHoldingBulkImportFormErrors,
  intl: IntlShape
) => {
  const repeatedFundNamesMap: Map<
    string,
    { holdingIndex: number; fundIndex: number }[]
  > = new Map()
  const repeatedWebsitesMap: Map<string, number[]> = new Map()

  holdings.forEach((holding, holdingIndex) => {
    if (holding.type === HoldingType.FUND) {
      holding.funds?.funds.forEach((fund, fundIndex) => {
        if (fund) {
          if (repeatedFundNamesMap.get(fund.trim())) {
            repeatedFundNamesMap.set(fund.trim(), [
              ...repeatedFundNamesMap.get(fund.trim())!,
              { holdingIndex, fundIndex },
            ])
          } else {
            repeatedFundNamesMap.set(fund.trim(), [])
          }
        }
      })
    } else if (holding.company?.website) {
      if (repeatedWebsitesMap.get(holding.company.website.trim())) {
        repeatedWebsitesMap.set(holding.company.website.trim(), [
          ...repeatedWebsitesMap.get(holding.company.website.trim())!,
          holdingIndex,
        ])
      } else {
        repeatedWebsitesMap.set(holding.company.website.trim(), [])
      }
    }
  })

  repeatedFundNamesMap.forEach((repeatedIndexes) => {
    repeatedIndexes.forEach(({ holdingIndex, fundIndex }) => {
      if (!errors.holdings[holdingIndex]) {
        // eslint-disable-next-line no-param-reassign
        errors.holdings[holdingIndex] = {
          funds: {
            funds: [],
          },
          company: {},
        }
      }

      const fundsErrors = errors.holdings[holdingIndex].funds.funds as string[]
      fundsErrors[fundIndex] = intl.formatMessage({
        id: 'addHolding.errors.repeatedFundName',
      })
    })
  })

  repeatedWebsitesMap.forEach((repeatedIndexes) => {
    repeatedIndexes.forEach((holdingIndex) => {
      if (!errors.holdings[holdingIndex]) {
        // eslint-disable-next-line no-param-reassign
        errors.holdings[holdingIndex] = {
          funds: {
            funds: [],
          },
          company: {},
        }
      }

      // eslint-disable-next-line no-param-reassign
      errors.holdings[holdingIndex].company.website = intl.formatMessage({
        id: 'addHolding.errors.repeatedWebsite',
      })
    })
  })
}
