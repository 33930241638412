import React, { useId } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import {
  setInitialLinkRedirect,
  setShowInvestorInitialLink,
} from 'reducers/breadcrumbSlice'
import { useIntl } from 'react-intl'
import GroupLogo from 'components/GroupLogo'
import Tooltip from 'components/Tooltip/Tooltip'
import { InvestorGroup } from 'utils/types/user'
import { useSubjectQuery } from 'utils/hooks/queries/subjects/useSubjectQuery'
import * as Styles from './AccountManaged.styles'

interface AccountManagedProps {
  group: InvestorGroup
}

const AccountManaged: React.FC<AccountManagedProps> = ({ group }) => {
  const intl = useIntl()
  const history = useHistory()
  const id = useId()
  const dispatch = useAppDispatch()

  const parentSubjectData = useSubjectQuery(group.parentGroup.fundCompanyId)
  const parentSubject = parentSubjectData.data?.subject

  const handleGroupClick = () => {
    const urlToRedirect = `/companies/${parentSubject?.id}/updates`

    dispatch(setShowInvestorInitialLink(false))
    dispatch(setInitialLinkRedirect(urlToRedirect))

    history.push(urlToRedirect)
  }

  if (parentSubjectData.isLoading) return null

  return (
    <>
      <Styles.Container>
        <Styles.Title>
          {intl.formatMessage({ id: 'sidebar.accountManagedBy' })}:
        </Styles.Title>
        <Styles.GroupLink onClick={handleGroupClick}>
          <Styles.LogoContainer>
            <GroupLogo
              imageUrl={parentSubject?.logo!}
              name={parentSubject?.name!}
              forceInitials={false}
              small
            />
          </Styles.LogoContainer>
          <Tooltip
            id={id}
            text={parentSubject?.name}
            widthAuto
            delayShow={500}
            place="top"
          >
            <Styles.Name>{parentSubject?.name}</Styles.Name>
          </Tooltip>
        </Styles.GroupLink>
      </Styles.Container>
      <Styles.Line />
    </>
  )
}

export default AccountManaged
