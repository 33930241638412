/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import InvestorManagementService, {
  Filters,
} from 'api/InvestorManagementService'
import Toast from 'components/Toast'
import { investmentVehiclesKeys } from 'utils/queries/investmentVehicles'
import { CustomUseQueryOptions } from 'utils/types/common'
import {
  IndexInvestmentVehicle,
  InvestmentVehicle,
  InvestmentVehiclePayload,
} from 'utils/types/investors'
import { SubjectType } from 'utils/types/subjects'

export type InvestmentVehicleFilters = Omit<Filters, 'page'>

/**
 * Query to fetch investment vehicles by page
 *
 * @param filters - Filters to send to the API. Check `InvestmentVehicleFilters` type.
 * @returns An instance of react-query
 *
 */
export const useInvestmentVehiclesQuery = (
  filters: InvestmentVehicleFilters,
  queryOptions = {}
) => {
  return useQuery(
    investmentVehiclesKeys.allWithFilters(filters),
    async () => {
      try {
        return await InvestorManagementService.fetchInvestmentVehicles({
          page: 1,
          type: SubjectType.INVESTMENT_VEHICLE,
        })
      } catch (err) {
        Toast.displayIntl(
          'investorManagement.errors.fetchInvestmentVehicles',
          'error'
        )
        throw err
      }
    },
    { ...queryOptions, staleTime: 500 }
  )
}

/**
 * Query to fetch investment vehicles by id
 *
 * @param investmentVehicleId - The id of the investment vehicle
 * @returns An instance of react-query
 *
 */
export const useInvestmentVehicleQuery = (
  investmentVehicleId: string,
  queryOptions: CustomUseQueryOptions<InvestmentVehicle> = {}
) => {
  return useQuery(
    investmentVehiclesKeys.byId(investmentVehicleId),
    async () => {
      try {
        return await InvestorManagementService.fetchInvestmentVehicle(
          investmentVehicleId
        )
      } catch (err) {
        Toast.displayIntl(
          'investorManagement.errors.fetchInvestmentVehicle',
          'error'
        )
        throw err
      }
    },
    { ...queryOptions, staleTime: 500 }
  )
}

/**
 * Mutation to create an investment vehicle
 *
 * @returns An instance of useMutation
 *
 */
export const useCreateInvestmentVehicleMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: InvestmentVehiclePayload) => {
      return InvestorManagementService.createInvestmentVehicle(payload)
    },
    {
      onSuccess: (newVehicle) => {
        queryClient.invalidateQueries(investmentVehiclesKeys.all())
        queryClient.setQueryData(
          investmentVehiclesKeys.byId(newVehicle.id),
          newVehicle
        )
      },
    }
  )
}

/**
 * Mutation to update an investment vehicle
 *
 * @param investmentVehicleId - The id of the investment vehicle
 * @returns An instance of useMutation
 *
 */
export const useEditInvestmentVehicleMutation = (
  investmentVehicleId: string
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: InvestmentVehiclePayload) => {
      return InvestorManagementService.editInvestmentVehicle(
        investmentVehicleId,
        payload
      )
    },
    {
      onSuccess: (newInvestmentVehicle) => {
        queryClient.refetchQueries(investmentVehiclesKeys.all())
        queryClient.setQueryData(
          investmentVehiclesKeys.byId(newInvestmentVehicle.id),
          newInvestmentVehicle
        )
      },
    }
  )
}

export const useDeleteInvestmentVehiclesMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(InvestorManagementService.deleteInvestmentVehicle, {
    onSuccess: () =>
      queryClient.invalidateQueries(investmentVehiclesKeys.all()),
  })
}

/**
 * Query to fetch all the investment vehicles that has transactions on a fund portfolio.
 * If the request is made by an Investor Group, the results will be scoped to his transactions.
 *
 * @param fundPortfolioId - The id of the fund portfolio
 * @returns An instance of react-query
 *
 */
export const useFundPortfolioInvestmentVehiclesQuery = (
  fundPortfolioId: string,
  queryOptions: CustomUseQueryOptions<IndexInvestmentVehicle[]> = {}
) => {
  return useQuery(
    investmentVehiclesKeys.fundPortfolioInvestmentVehicles(fundPortfolioId),
    async () => {
      try {
        const { results } =
          await InvestorManagementService.fetchInvestmentVehicles({
            fundPortfolioId: [fundPortfolioId],
            page: 1,
            type: SubjectType.INVESTMENT_VEHICLE,
          })
        return results
      } catch (err) {
        Toast.displayIntl(
          'investorManagement.errors.fetchInvestmentVehicles',
          'error'
        )
        return null
      }
    },
    queryOptions
  )
}
