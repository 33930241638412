import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const BasicInformationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const ImageWrapper = styled.div`
  div {
    margin-top: -12rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const nameStyles = css`
  color: ${color('darkBlue')};
  font-weight: 600;
  text-align: center;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    color: ${color('darkGreen')};
    margin-bottom: 0.5rem;
    margin-left: 0.3rem;
  }
`
export const CompanyName = styled.div<{ isVerified?: boolean }>`
  ${nameStyles}
  font-size: 2.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: -webkit-fill-available;
`

export const ParentSubjectName = styled(Link)`
  ${nameStyles}
  margin-top: 0.8rem;
  font-size: 1.4rem;
`
