import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import { itemAnimation, listAnimation } from 'utils/animations/list'

import styles from './List.module.scss'

const List = ({
  elements,
  keyExtractor,
  striped,
  removeTopBorderRadius,
  overflow,
  removeMaxHeight,
  rowHeightAuto,
  rowWidthAuto,
  rowHeight,
}) =>
  elements.length ? (
    <motion.ul
      className={classNames(styles.list, {
        [styles.striped]: striped,
        [styles.noTopBorderRadius]: removeTopBorderRadius,
        [styles.overflowVisible]: overflow === 'visible',
        [styles.maxHeightNone]: removeMaxHeight,
      })}
      initial="hidden"
      animate="visible"
      variants={listAnimation}
    >
      {elements.map((row, index) => (
        <motion.li
          key={keyExtractor ? keyExtractor(index) : index}
          className={classNames(styles.row, {
            [styles.rowHeightAuto]: rowHeightAuto,
            [styles.rowWidthAuto]: rowWidthAuto,
          })}
          variants={itemAnimation}
          style={{ height: rowHeight }}
        >
          {row}
        </motion.li>
      ))}
    </motion.ul>
  ) : null

List.propTypes = {
  elements: PropTypes.array,
  keyExtractor: PropTypes.func,
  striped: PropTypes.bool,
  removeTopBorderRadius: PropTypes.bool,
  overflow: PropTypes.string,
  removeMaxHeight: PropTypes.bool,
  rowHeightAuto: PropTypes.bool,
  rowWidthAuto: PropTypes.bool,
  rowHeight: PropTypes.string,
}

List.defaultProps = {
  elements: [],
  keyExtractor: undefined,
  striped: true,
  removeTopBorderRadius: false,
  overflow: '',
  removeMaxHeight: false,
  rowHeightAuto: false,
  rowWidthAuto: false,
  rowHeight: undefined,
}

export default List
