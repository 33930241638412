import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ImagePicker from 'ui/ImagePicker'
import Input from 'ui/Input'
import { ErrorType } from 'utils/types/common'
import { buildFormError } from 'utils/functions/forms'
import * as Styles from './AddCompanyForm.styles'
import ProfileSuggestion from '../ProfileSuggestion/ProfileSuggestion'
import { SUGGESTION_ERROR } from '../errors.v2'
import { useAddCompanyForm } from './useAddCompanyForm'

const AddCompanyForm: React.FC = () => {
  const intl = useIntl()
  const {
    status,
    legalEntityNameField,
    nameField,
    websiteField,
    websiteMeta,
    pointOfContactField,
    logoField,
    handleBlur,
    existentHoldingContext,
    onSelectImage,
    onSaveImage,
    onDeleteImage,
    onChangeWebsite,
    nameErrors,
    touched,
  } = useAddCompanyForm()

  const renderSuggestionError = () => {
    if (status?.companyErrors?.website)
      return (
        <Styles.SuggestionErrorContainer>
          <ProfileSuggestion
            type="website"
            profiles={[status.companyErrors.website]}
            addHoldingsToPortfolio={
              existentHoldingContext?.addHoldingsToPortfolio
            }
          />
        </Styles.SuggestionErrorContainer>
      )

    if (status?.companyErrors?.isHidden)
      return (
        <Styles.SuggestionErrorContainer>
          <Styles.DefaultError>
            {intl.formatMessage({
              id: 'addHolding.errors.websiteUsed',
            })}
          </Styles.DefaultError>
        </Styles.SuggestionErrorContainer>
      )

    return null
  }

  return (
    <>
      <Styles.CompanyMandatoryInfo>
        <div>
          <Styles.LogoTitle>
            <FormattedMessage id="addHolding.logo" />
          </Styles.LogoTitle>
          <Styles.ImagePickerContainer>
            <ImagePicker
              imageUrl={logoField.value?.url}
              onSelectImage={onSelectImage}
              onSaveImage={onSaveImage}
              helpHidden
              showSelectImageButton={false}
              deleteImageEnabled
              showRemoveImageButton={false}
              onDeleteImage={onDeleteImage}
              gray
            />
          </Styles.ImagePickerContainer>
        </div>
        <Styles.InputsWrapper>
          <Input
            id={legalEntityNameField.name}
            name={legalEntityNameField.name}
            value={legalEntityNameField.value}
            label={intl.formatMessage({ id: 'addHolding.legalEntityName' })}
            placeholder={intl.formatMessage({
              id: 'addHolding.legalEntityName',
            })}
            onChange={legalEntityNameField.onChange}
            error={legalEntityNameField.buildError()}
            onBlur={handleBlur}
          />
          <Input
            id={nameField.name}
            name={nameField.name}
            value={nameField.value}
            label={intl.formatMessage({ id: 'addHolding.name' })}
            placeholder={intl.formatMessage({
              id: 'addHolding.name',
            })}
            onChange={nameField.onChange}
            error={buildFormError(
              nameErrors,
              ErrorType.ERROR,
              nameField.value.length > 0 ? true : touched?.company
            )}
            onBlur={handleBlur}
          />
        </Styles.InputsWrapper>
      </Styles.CompanyMandatoryInfo>
      <Styles.InputsWrapper>
        <div>
          <Input
            id={websiteField.name}
            name={websiteField.name}
            value={websiteField.value}
            label={intl.formatMessage({ id: 'addHolding.website' })}
            placeholder={intl.formatMessage({
              id: 'addHolding.website',
            })}
            onChange={onChangeWebsite}
            error={websiteField.buildError()}
            showErrorMessage={websiteMeta.error !== SUGGESTION_ERROR}
            onBlur={handleBlur}
            isOptional
          />
          {renderSuggestionError()}
        </div>
        <Input
          id={pointOfContactField.name}
          name={pointOfContactField.name}
          value={pointOfContactField.value}
          label={intl.formatMessage({ id: 'addHolding.pointOfContact' })}
          placeholder={intl.formatMessage({
            id: 'addHolding.pointOfContact',
          })}
          onChange={pointOfContactField.onChange}
          error={pointOfContactField.buildError()}
          onBlur={handleBlur}
          isOptional
        />
        <Styles.InvitationInfoContainer>
          <FontAwesomeIcon icon={['far', 'info-circle']} />
          <Styles.InvitationInfoText>
            <FormattedMessage id="addHolding.invitationInfo" />
          </Styles.InvitationInfoText>
        </Styles.InvitationInfoContainer>
      </Styles.InputsWrapper>
    </>
  )
}

export default AddCompanyForm
