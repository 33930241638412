/* eslint-disable react/style-prop-object, react/style-prop-object */

import type { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl'
import type { IntlShape } from 'react-intl/src/types'

import GrowthIndicator from 'components/Dashboard/GrowthIndicator'
import GrowthValue from 'components/Dashboard/GrowthIndicator/GrowthValue'
import Tooltip from 'components/Tooltip/Tooltip'

import FloatColumnWrapper from 'ui/Table/FloatButtonColumn/FloatColumnWrapper'

import OptionsDropdown from 'ui/OptionsDropdown'
import {
  FMVCell,
  FMVDescriptionIcon,
  FMVValue,
  GrowthIndicatorContainer,
} from 'components/FairMarketValueHistory/FairMarketValueHistory.styles'

import ExpandArrow from 'ui/Table/Row/ExpandArrow'
import { Column } from 'ui/Table/types'
import {
  FmvWithGrowth,
  PortfolioCompanyWithFmv,
  PortfolioHoldingsColumn,
  PortfolioHoldingsVisibleColumns,
  UpdatesTypesFilterOptions,
} from 'utils/types/portfolios'

import { PortfolioTypes } from 'utils/constants/portfolio'
import { UpdateTypes } from 'utils/constants/updates'
import { GroupType } from 'utils/types/user'
import {
  ChipIconAsButton,
  ChipIconAsDiv,
} from 'components/AddToPortfolioButton/AddToPortfolioModal/AddToPortfolioModal.styles'
import {
  renderFairMarketValue,
  renderFairMarketValues,
} from 'utils/functions/renderers/renderFairMarketValue'
import { emails } from 'utils/constants/emails'
import {
  Cell,
  DataType,
  getFieldValue,
} from 'utils/functions/renderers/renderFundHoldingsHelper'
import HoldingName from 'utils/functions/renderers/components/HoldingName'
import { displayThousands } from '../number'
import IconCell from './components/IconCell/IconCell'
import {
  TooltipContent,
  TooltipParagraph,
  TooltipTitle,
} from './components/IconCell/styles'

export const DUMMY_DATE = '0000-01-01'
export const FAIR_MARKET_VALUES_DATE_KEY = 'fairMarketValues.0.date'

export const getBasePortfolioHoldingsColumns = ({
  visiblePortfolioHoldingsColumns,
  intl,
  styles,
  isInvestorCalculationDisabled,
  isMobile,
}: {
  visiblePortfolioHoldingsColumns: PortfolioHoldingsVisibleColumns
  intl: IntlShape
  styles: any
  companyProfileUrlPrefix?: string
  isInvestorCalculationDisabled?: boolean
  isMobile?: boolean
}): Column<PortfolioCompanyWithFmv>[] => [
  {
    id: 'company',
    sortKey: 'holding.name',
    label: <FormattedMessage id="portfolioDetail.holdings" />,
    hidden: false,
    fixed: !isMobile,
    minWidth: 215,
    flex: 2,
    cellRenderer: ({ rowData }) => <HoldingName holding={rowData.holding} />,
  },
  {
    id: 'firstInvestmentDate',
    sortKey: 'firstInvestmentDate',
    label: <FormattedMessage id="portfolioDetail.dateFirstInvestment" />,
    minWidth: 110,
    hidden: !visiblePortfolioHoldingsColumns.firstInvestmentDate,
    flex: 1,
    tooltip: intl.formatMessage({
      id: 'portfolioDetail.firstInvestmentTooltip',
    }),
    cellRenderer: ({ rowData }) => {
      return (
        <span className={classNames(styles.cell, 'fs-exclude')}>
          {rowData.firstInvestmentDate &&
          rowData.firstInvestmentDate !== DUMMY_DATE ? (
            <FormattedDate
              year="numeric"
              month="short"
              value={rowData.firstInvestmentDate}
            />
          ) : (
            '-'
          )}
        </span>
      )
    },
  },
  {
    id: 'totalTransactions',
    sortKey: 'totalTransactions',
    minWidth: 100,
    label: <FormattedMessage id="portfolioDetail.totalInvestments" />,
    className: styles.transactionsCell,
    hidden: !visiblePortfolioHoldingsColumns.totalTransactions,
    flex: 1,
    tooltip: intl.formatMessage({
      id: 'portfolioDetail.totalInvestmentTooltip',
    }),
    cellRenderer: ({ rowData }) => {
      return (
        <div className={classNames(styles.cell, 'fs-exclude')}>
          {rowData.totalTransactions || 0}
        </div>
      )
    },
  },
  {
    id: 'unfundedCommitment',
    sortKey: 'unfundedCommitment',
    label: <FormattedMessage id="portfolioDetail.unfundedCommitment" />,
    minWidth: 120,
    hidden: !visiblePortfolioHoldingsColumns.unfundedCommitment,
    flex: 1,
    tooltip: intl.formatMessage({
      id: 'portfolioDetail.unfundedCommitmentTooltip',
    }),
    cellRenderer: ({ rowData }) => (
      <div className={classNames(styles.cell, 'fs-exclude')}>
        {rowData.unfundedCommitment ? (
          <FormattedNumber
            value={rowData.unfundedCommitment}
            style="currency"
            currency="USD"
            currencyDisplay="narrowSymbol"
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        ) : (
          '-'
        )}
      </div>
    ),
  },
  {
    id: 'totalAmountInvested',
    sortKey: 'totalAmountInvested',
    label: <FormattedMessage id="portfolioDetail.amountInvested" />,
    hidden: !visiblePortfolioHoldingsColumns.totalAmountInvested,
    minWidth: 110,
    flex: 1,
    tooltip: intl.formatMessage({
      id: 'portfolioDetail.amountInvestedTooltip',
    }),
    cellRenderer: ({ rowData }) => (
      <div className={classNames(styles.cell, 'fs-exclude')}>
        {rowData.totalAmountInvested ? (
          <FormattedNumber
            value={rowData.totalAmountInvested}
            style="currency"
            currency="USD"
            currencyDisplay="narrowSymbol"
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        ) : (
          '-'
        )}
      </div>
    ),
  },
  {
    id: 'totalFairMarketValue',
    sortKey: 'fairMarketValues.0.value',
    label: (
      <IconCell
        cellNameId="portfolioDetail.fairMarketValue"
        iconName="question-circle"
        tooltipId="fmv-tooltip"
        tooltipText={
          <TooltipContent>
            <TooltipTitle>
              {intl.formatMessage({
                id: 'portfolioDetail.totalUnrealizedValue',
              })}
            </TooltipTitle>
            <TooltipParagraph>
              {intl.formatMessage(
                { id: 'portfolioDetail.carryingValueTooltip' },
                {
                  email: (
                    <a
                      href={emails.MAILTO_SUPPORT}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      support@clockwork.app
                    </a>
                  ),
                }
              )}
            </TooltipParagraph>
          </TooltipContent>
        }
      />
    ),
    hidden: !visiblePortfolioHoldingsColumns.totalFairMarketValue,
    minWidth: 160,
    flex: 1,
    tooltip: intl.formatMessage({
      id: 'portfolioDetail.fairMarketValueTooltip',
    }),
    cellRenderer: ({ rowData }) => {
      return isInvestorCalculationDisabled
        ? renderFairMarketValues(rowData, styles)
        : renderFairMarketValue(rowData, styles)
    },
  },
  {
    id: 'lastCarryingValueUpdate',
    sortKey: FAIR_MARKET_VALUES_DATE_KEY,
    label: <FormattedMessage id="portfolioDetail.lastCarryingValueUpdate" />,
    hidden: !visiblePortfolioHoldingsColumns.lastCarryingValueUpdate,
    minWidth: 100,
    flex: 1,
    tooltip: intl.formatMessage({
      id: 'portfolioDetail.fairMarketValueTooltip',
    }),
    cellRenderer: ({ rowData }) => {
      const [currentFmv] = rowData.fairMarketValues ?? []

      return (
        <div className={classNames(styles.cell, 'fs-exclude')}>
          {currentFmv ? dayjs(currentFmv.date).format('MMM DD, YYYY') : '-'}
        </div>
      )
    },
  },
  {
    id: 'totalAmountDistributed',
    sortKey: 'totalAmountDistributed',
    label: <FormattedMessage id="portfolioDetail.amountDistributed" />,
    minWidth: 120,
    hidden: !visiblePortfolioHoldingsColumns.totalAmountDistributed,
    flex: 1,
    tooltip: intl.formatMessage({
      id: 'portfolioDetail.amountDistributedTooltip',
    }),
    cellRenderer: ({ rowData }) => (
      <div className={classNames(styles.cell, 'fs-exclude')}>
        {rowData.totalAmountDistributed ? (
          <FormattedNumber
            value={rowData.totalAmountDistributed}
            style="currency"
            currency="USD"
            currencyDisplay="narrowSymbol"
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        ) : (
          '-'
        )}
      </div>
    ),
  },
  {
    id: 'totalValue',
    sortKey: 'totalValue',
    label: <FormattedMessage id="portfolioDetail.totalValue" />,
    minWidth: 120,
    hidden: !visiblePortfolioHoldingsColumns.totalValue,
    flex: 1,
    tooltip: intl.formatMessage({
      id: 'portfolioDetail.totalValueTooltip',
    }),
    cellRenderer: ({ rowData }) => (
      <div className={classNames(styles.cell, 'fs-exclude')}>
        <FormattedNumber
          value={rowData.totalValue || 0}
          style="currency"
          currency="USD"
          currencyDisplay="narrowSymbol"
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      </div>
    ),
  },
  {
    id: 'multipleOnInvestedCapital',
    sortKey: 'multipleOnInvestedCapital',
    label: <FormattedMessage id="portfolioDetail.moic" />,
    tooltip: intl.formatMessage({
      id: 'portfolioDetail.moicTooltip',
    }),
    minWidth: 90,
    className: styles.centerText,
    hidden: !visiblePortfolioHoldingsColumns.multipleOnInvestedCapital,
    flex: 1,
    cellRenderer: ({ rowData }) => {
      return (
        <Cell className="fs-exclude">
          {getFieldValue(
            rowData?.multipleOnInvestedCapital,
            DataType.MULTIPLIER
          )}
        </Cell>
      )
    },
  },
  {
    id: 'irr',
    sortKey: 'irr',
    label: <FormattedMessage id="portfolioDetail.irr" />,
    tooltip: intl.formatMessage({
      id: 'portfolioDetail.irrTooltip',
    }),
    minWidth: 90,
    className: styles.centerText,
    hidden: !visiblePortfolioHoldingsColumns.irr,
    flex: 1,
    cellRenderer: ({ rowData }) => {
      return (
        <Cell className="fs-exclude">
          {getFieldValue(rowData?.irr, DataType.PERCENT, 2)}
        </Cell>
      )
    },
  },
  {
    id: 'percentageOfInvested',
    sortKey: 'percentageOfInvested',
    label: <FormattedMessage id="portfolioDetail.percentageOfInvested" />,
    minWidth: 80,
    className: styles.centerText,
    hidden: !visiblePortfolioHoldingsColumns.percentageOfInvested,
    flex: 1,
    tooltip: intl.formatMessage({
      id: 'portfolioDetail.percentageOfInvestedTooltip',
    }),
    cellRenderer: ({ rowData }) => {
      return (
        <Cell className="fs-exclude">
          {getFieldValue(rowData?.percentageOfInvested, DataType.PERCENT, 2)}
        </Cell>
      )
    },
  },
]

export const getInvestPortfolioBreakdownColumns = ({
  visiblePortfolioHoldingsColumns,
  intl,
  styles,
  isMobile,
  isInvestorCalculationDisabled = true,
}: {
  styles: any
  intl: IntlShape
  onAddTransactionalUpdate: (portfolioCompany: PortfolioCompanyWithFmv) => void
  onAddFairMarketValue: (portfolioCompany: PortfolioCompanyWithFmv) => void
  onShowFairMarketValueHistory: (
    portfolioCompany: PortfolioCompanyWithFmv
  ) => void
  onRemoveCompany: (portfolioCompany: PortfolioCompanyWithFmv) => void
  visiblePortfolioHoldingsColumns: PortfolioHoldingsVisibleColumns
  isMobile: boolean
  isSmallDesktop: boolean
  currentGroupType: GroupType
  isInvestorCalculationDisabled: boolean
}): Column<PortfolioCompanyWithFmv>[] => [
  {
    id: 'expand',
    sortDisabled: true,
    fixed: !isMobile,
    minWidth: 13,
    cellRenderer: ExpandArrow,
  },
  ...getBasePortfolioHoldingsColumns({
    visiblePortfolioHoldingsColumns,
    intl,
    styles,
    isInvestorCalculationDisabled,
    isMobile,
  }),
]

export const getFairMarketValueColumns = ({
  intl,
  setEditFmvIndex,
  onDeleteFMV,
  readOnly = false,
}): Column<FmvWithGrowth>[] => [
  {
    id: 'data-column',
    label: 'Date',
    flex: 2,
    sortKey: 'date',
    cellRenderer: ({ rowData }) => (
      <FMVCell>
        {dayjs(rowData.date || undefined).format('MMM DD, YYYY')}
      </FMVCell>
    ),
  },
  {
    id: 'fmv-column',
    label: intl.messages['portfolios.fairMarketValue'],
    flex: 3.2,
    sortKey: 'value',
    cellRenderer: ({ rowData }) => {
      return (
        <FMVCell>
          <FMVValue>
            {displayThousands(rowData.value || 0)?.toString()}
            {rowData.growth !== null && (
              <GrowthIndicatorContainer>
                <GrowthIndicator direction={rowData.growth ? 'up' : 'down'} />
                <GrowthValue
                  growth={rowData.growth}
                  bold
                  value={displayThousands(rowData.growthValue || 0).toString()}
                />
              </GrowthIndicatorContainer>
            )}
          </FMVValue>
        </FMVCell>
      )
    },
  },
  {
    id: 'description-column',
    sortDisabled: true,
    minWidth: 40,
    cellRenderer: ({ rowData }) => {
      if (rowData.description && rowData.description.trim() !== '') {
        return (
          <FMVDescriptionIcon>
            <Tooltip id={rowData.id} text={rowData.description} place="left">
              <FontAwesomeIcon icon={['far', 'comment']} />
            </Tooltip>
          </FMVDescriptionIcon>
        )
      }
      return null
    },
  },
  {
    id: 'value-column',
    sortDisabled: true,
    flex: 0,
    cellRenderer: ({ rowData, rowIndex }) => {
      if (readOnly) return null
      return (
        <FloatColumnWrapper id={rowData.id}>
          <OptionsDropdown id={rowData.id} hoverOpacity="0.9">
            <OptionsDropdown.Item
              label={<FormattedMessage id="portfolioDetail.editValue" />}
              icon={['far', 'pen']}
              onSelectOption={() => setEditFmvIndex(rowIndex)}
            />
            <OptionsDropdown.Item
              data-type="delete"
              label={<FormattedMessage id="portfolioDetail.deleteValue" />}
              icon={['far', 'trash-alt']}
              color="#f94863"
              onSelectOption={() => {
                onDeleteFMV(rowData.id)
              }}
            />
          </OptionsDropdown>
        </FloatColumnWrapper>
      )
    },
  },
]

export const getPortfolioHoldingsColumns = (
  intl: IntlShape,
  visiblePortfolioHoldingsColumns?: PortfolioHoldingsVisibleColumns,
  sharedPortfolioHoldingsColumns?: PortfolioHoldingsVisibleColumns
): PortfolioHoldingsColumn[] =>
  [
    {
      id: 'firstInvestmentDate',
      status: !!visiblePortfolioHoldingsColumns?.firstInvestmentDate,
      label: intl.formatMessage({
        id: 'portfolioDetail.firstInvestmentDate',
      }),
    },
    {
      id: 'totalTransactions',
      status: !!visiblePortfolioHoldingsColumns?.totalTransactions,
      label: intl.formatMessage({
        id: 'portfolioDetail.totalInvestments',
      }),
    },
    {
      id: 'unfundedCommitment',
      status: !!visiblePortfolioHoldingsColumns?.unfundedCommitment,
      label: intl.formatMessage({
        id: 'portfolioDetail.unfundedCommitment',
      }),
    },
    {
      id: 'totalAmountInvested',
      status: !!visiblePortfolioHoldingsColumns?.totalAmountInvested,
      label: intl.formatMessage({
        id: 'portfolioDetail.amountInvested',
      }),
    },
    {
      id: 'totalFairMarketValue',
      status: !!visiblePortfolioHoldingsColumns?.totalFairMarketValue,
      label: intl.formatMessage({
        id: 'portfolioDetail.fairMarketValue',
      }),
    },
    {
      id: 'lastCarryingValueUpdate',
      status: !!visiblePortfolioHoldingsColumns?.lastCarryingValueUpdate,
      label: intl.formatMessage({
        id: 'portfolioDetail.lastCarryingValueUpdate',
      }),
    },
    {
      id: 'totalAmountDistributed',
      status: !!visiblePortfolioHoldingsColumns?.totalAmountDistributed,
      label: intl.formatMessage({
        id: 'portfolioDetail.amountDistributed',
      }),
    },
    {
      id: 'totalValue',
      status: !!visiblePortfolioHoldingsColumns?.totalValue,
      label: intl.formatMessage({
        id: 'portfolioDetail.totalValue',
      }),
    },
    {
      id: 'multipleOnInvestedCapital',
      status: !!visiblePortfolioHoldingsColumns?.multipleOnInvestedCapital,
      label: intl.formatMessage({
        id: 'portfolioDetail.multipleOnInvestedCapital',
      }),
    },
    {
      id: 'irr',
      status: !!visiblePortfolioHoldingsColumns?.irr,
      label: intl.formatMessage({
        id: 'portfolioDetail.irr',
      }),
    },
    {
      id: 'percentageOfInvested',
      status: !!visiblePortfolioHoldingsColumns?.percentageOfInvested,
      label: intl.formatMessage({
        id: 'portfolioDetail.percentageOfInvested',
      }),
    },
  ].filter(
    (col) =>
      !sharedPortfolioHoldingsColumns || sharedPortfolioHoldingsColumns[col.id]
  )

type FilterOptions = {
  intl: IntlShape
  isActingAsClient: boolean
  withTransactionOptions?: boolean
  isActingAsFounder: boolean
  isComingFromUpdatesView: boolean
  isComingFromDashboard: boolean
  isInvestor: boolean
  isLookingAtInvestorProfile: boolean
  isLookingAtFundProfile: boolean
}

export const getUpdateTypesFilterOptions = ({
  intl,
  isActingAsClient,
  withTransactionOptions = true,
  isActingAsFounder,
  isComingFromUpdatesView,
  isComingFromDashboard,
  isInvestor,
  isLookingAtInvestorProfile,
  isLookingAtFundProfile,
}: FilterOptions): UpdatesTypesFilterOptions[] => {
  const transactionOption: UpdatesTypesFilterOptions = {
    id: UpdateTypes.TRANSACTION,
    label: intl.formatMessage({
      id: 'updates.filters.updateTypes.options.transaction',
    }),
    value: 'transaction',
  }

  const showRestOfOptions =
    (!isActingAsClient && !isInvestor && !isLookingAtInvestorProfile) ||
    (isInvestor && isLookingAtFundProfile) ||
    (isActingAsClient && !isComingFromUpdatesView)

  const addTransaction =
    (!isActingAsFounder && withTransactionOptions) ||
    (isActingAsFounder && isComingFromDashboard && withTransactionOptions)

  const options: UpdatesTypesFilterOptions[] = [
    {
      id: UpdateTypes.DOCUMENT,
      label: intl.formatMessage({
        id: 'updates.filters.updateTypes.options.document',
      }),
      value: 'document',
    },
    {
      id: UpdateTypes.NOTE,
      label: intl.formatMessage({
        id: 'updates.filters.updateTypes.options.note',
      }),
      value: 'note',
    },
  ].filter(Boolean) as UpdatesTypesFilterOptions[]

  if (addTransaction) {
    options.push(transactionOption)
  }

  if (showRestOfOptions) {
    options.unshift(
      {
        id: UpdateTypes.ACCOUNTING,
        label: intl.formatMessage({
          id: 'updates.filters.updateTypes.options.accountingReport',
        }),
        value: ['xero_report', 'quickbooks_report'],
      },
      {
        id: UpdateTypes.ANNOUNCEMENT,
        label: intl.formatMessage({
          id: 'updates.filters.updateTypes.options.announcement',
        }),
        value: 'announcement',
      },
      {
        id: UpdateTypes.IUE,
        label: intl.formatMessage({
          id: 'updates.filters.updateTypes.options.email',
        }),
        value: 'report',
      }
    )
  }

  return options
}

export const getPortfolioIconByType = (
  type: PortfolioTypes,
  size?: SizeProp,
  color?: string
) => {
  switch (type) {
    case PortfolioTypes.TRACK:
      return (
        <FontAwesomeIcon
          color={color}
          size={size}
          icon={['fal', 'binoculars']}
        />
      )
    case PortfolioTypes.INVEST:
      return (
        <FontAwesomeIcon
          color={color}
          size={size}
          icon={['fal', 'dollar-sign']}
        />
      )
    case PortfolioTypes.FUND:
      return (
        <FontAwesomeIcon
          color={color}
          size={size}
          icon={['fal', 'sack-dollar']}
        />
      )
    case PortfolioTypes.DEAL:
      return (
        <FontAwesomeIcon
          color={color}
          size={size}
          icon={['fal', 'badge-dollar']}
        />
      )

    default:
      return (
        <FontAwesomeIcon
          color={color}
          size={size}
          icon={['fal', 'briefcase']}
        />
      )
  }
}

export const getPortfolioIcon = (
  pType: PortfolioTypes,
  withButtonTag: boolean
) => {
  if (withButtonTag) {
    return <ChipIconAsButton>{getPortfolioIconByType(pType)}</ChipIconAsButton>
  }
  return <ChipIconAsDiv>{getPortfolioIconByType(pType)}</ChipIconAsDiv>
}
