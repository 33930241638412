import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import { getPortfolioIconByType } from 'utils/functions/renderers/renderPortfolioHelper'
import { useMetricsSubjectQuery } from 'utils/hooks/useMetricsSubjectQuery'
import { Color } from 'utils/theme'
import { MetricSources } from 'utils/types/metrics'
import { getPortfolioTypeBySubjectType, Metric } from 'utils/types/metricsV2'
import {
  Container,
  GroupTooltips,
  Legend,
  MilestoneGroupsContainer,
  PortfolioIconWrapper,
  Progress,
  ProgressValue,
  ProgressWrapper,
  SharedWithLabel,
  Title,
  TitleChildrenWrapper,
} from './MilestoneIndicator.styles'

const MAX_PROGRESS = 100

const MilestoneIndicator = ({ children }: { children: React.ReactNode }) => {
  return <Container>{children}</Container>
}

const MilestoneTitle = ({
  children,
  mode = 'light',
}: {
  children: React.ReactNode
  mode?: 'light' | 'dark'
}) => (
  <Title mode={mode}>
    <FontAwesomeIcon icon={['far', 'trophy-alt']} />
    <TitleChildrenWrapper>{children}</TitleChildrenWrapper>
  </Title>
)

const MilestoneProgress = ({
  value,
  legend,
  mode = 'light',
  color = 'primaryBlue',
}: {
  value: number
  legend?: string
  mode?: 'light' | 'dark'
  color?: Color
}) => (
  <ProgressWrapper>
    <Progress
      color={color}
      mode={mode}
      value={Math.round(value)}
      max={MAX_PROGRESS}
    />
    <ProgressValue mode={mode}>{`${Math.round(value)}%`}</ProgressValue>
    {legend && <span>{legend}</span>}
  </ProgressWrapper>
)

const MilestoneGroups = ({
  shared,
  metric,
}: {
  shared?: boolean
  metric: Metric
}) => {
  const { data: subject } = useMetricsSubjectQuery(
    {
      subjectId: metric.subject.id,
      subjectType: metric.subject.type,
    },
    {
      enabled: shared,
    }
  )

  const groups = useMemo(() => {
    const isCalculatedMetric = metric.source === MetricSources.System
    const group: {
      id: string
      name: string
      logo?: { url: string }
      logoComponent?: React.ReactNode
    } = {
      id: subject?.id || '',
      name: subject?.name || '',
      logo: subject?.primaryLogo,
    }

    if (isCalculatedMetric) {
      group.logoComponent = (
        <PortfolioIconWrapper>
          {getPortfolioIconByType(
            getPortfolioTypeBySubjectType(metric.subject.type)!,
            '1x'
          )}
        </PortfolioIconWrapper>
      )
    }

    return [group]
  }, [metric, subject])

  if (!shared) {
    return (
      <MilestoneGroupsContainer>
        <SharedWithLabel>
          <FormattedMessage id="showMetrics.yourGroupOnly" />
        </SharedWithLabel>
      </MilestoneGroupsContainer>
    )
  }

  return (
    <MilestoneGroupsContainer>
      <SharedWithLabel>
        <FormattedMessage id="showMetrics.sharedWith" />
      </SharedWithLabel>
      <GroupTooltips groups={groups} direction="left" />
    </MilestoneGroupsContainer>
  )
}

MilestoneIndicator.Title = MilestoneTitle
MilestoneIndicator.Legend = Legend
MilestoneIndicator.Progress = MilestoneProgress
MilestoneIndicator.Groups = MilestoneGroups

export default MilestoneIndicator
