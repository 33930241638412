import {
  BulkCreateSubjectDto,
  CreateAttributeDto,
  CreateSubjectDto,
  PermissionDto,
} from 'types/graphql-schemas/graphql'
import { getInvestorSubjectPayload } from 'utils/functions/investorManagement'
import { BulkInvestorFormValues } from 'utils/types/bulkImports/investors'

export const getCompanySubjectMutationPayload = (data: {
  name: string
  legalEntityName?: string
  logo?: Blob
  website?: string
  pointOfContact?: string
  isFundCompany?: boolean
  permissions?: PermissionDto[]
  groupOwned?: boolean
}): CreateSubjectDto => {
  const attributes: CreateAttributeDto[] = [
    {
      name: 'legalEntityName',
      type: 'string',
      value: data.legalEntityName || data.name,
      required: false,
    },
    {
      name: 'website',
      type: 'string',
      value: data.website,
      required: false,
    },
    {
      name: 'pointOfContact',
      type: 'string',
      value: data.pointOfContact,
      required: false,
    },
  ]

  if (data.isFundCompany) {
    attributes.push({
      name: 'isFundCompany',
      type: 'boolean',
      value: true,
      required: true,
    })
  }

  return {
    name: data.name,
    type: 'company',
    logo: data.logo,
    attributes,
    groupOwned: data.groupOwned,
    permissions: data.permissions || [],
  }
}

export const getInvestorSubjectMutationPayload = (
  data: BulkInvestorFormValues,
  currentGroupId: string
): BulkCreateSubjectDto => {
  const payload = getInvestorSubjectPayload<BulkCreateSubjectDto>({
    investorData: data,
    isBulkImport: true,
    currentGroupId,
  })
  return payload.variables.data
}
