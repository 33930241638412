import { QueryClient } from '@tanstack/react-query'
import CompanyService from 'api/CompanyService'
import HoldingsService, { HoldingTypeFilter } from 'api/HoldingsService'
import { SortDirection } from 'types/graphql-schemas/graphql'
import type { IntlShape } from 'react-intl'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { companyKeys } from 'utils/queries/companies'
import { Company, Holding } from 'utils/types/company'
import { Metric } from '../Spreadsheet/CellTemplates/MetricCellTemplate'
import { MetricsSpreadsheetBuilder } from './MetricsSpreadsheetBuilder'
import { MetricsSpreadsheetService } from './MetricsSpreadsheetService'
import { MetricsSpreadsheetEvents } from './useUpdateMetricsGrid'

export enum MetricsSpreadsheetMode {
  SINGLE_HOLDING = 'SINGLE_HOLDING',
  MULTIPLE_HOLDINGS = 'MULTIPLE_HOLDINGS',
}

const getFetchCompanyByIdFunction =
  (queryClient: QueryClient) => async (companyId: string) => {
    const company = await queryClient.fetchQuery({
      queryKey: companyKeys.byId(companyId),
      queryFn: () => CompanyService.fetchCompany(companyId),
      staleTime: 10000,
    })

    return company
  }

const INITIAL_PAGE = 0
const PAGE_SIZE = 20

export const getFetchCompanyFunction =
  (queryClient: QueryClient) => async (inputSearch: string) => {
    const { holdings } = await queryClient.fetchQuery({
      queryKey: ['holdings-companies', inputSearch],
      queryFn: () =>
        HoldingsService.getHoldings({
          filters: {
            name: inputSearch,
            typeIn: [HoldingTypeFilter.COMPANY, HoldingTypeFilter.FUND],
            orderBy: 'name',
            direction: SortDirection.Asc,
          },
          page: INITIAL_PAGE,
          companiesPerPage: PAGE_SIZE,
        }),
      staleTime: 10000,
    })

    return holdings
  }

/**
 * Creates the spreadsheet builder and service according to the spreadsheet mode.
 * Ensures consistency between builder (spreadsheet shape) and service.
 */
export class MetricsSpreadsheetLogic {
  private metricColumnIndex: number

  private holdingColumnIndex: number = 1

  private builder: MetricsSpreadsheetBuilder

  private service: MetricsSpreadsheetService

  private constructor(mode: MetricsSpreadsheetMode) {
    if (mode === MetricsSpreadsheetMode.MULTIPLE_HOLDINGS) {
      this.metricColumnIndex = 2
    } else {
      this.metricColumnIndex = 1
    }
  }

  static forMultipleHoldings = (
    intl: IntlShape,
    queryClient: QueryClient,
    preloadedMetricRows: Metric[],
    preloadedHoldingInfo?: { holding?: Holding; holdingId?: string }
  ) => {
    const mode = MetricsSpreadsheetMode.MULTIPLE_HOLDINGS
    const instance = new MetricsSpreadsheetLogic(mode)

    instance.builder = new MetricsSpreadsheetBuilder(
      mode,
      instance.metricColumnIndex,
      instance.holdingColumnIndex,
      preloadedMetricRows,
      preloadedHoldingInfo?.holding
    )
    instance.service = MetricsSpreadsheetService.forMultipleHoldings(
      instance.metricColumnIndex,
      instance.holdingColumnIndex,
      intl,
      queryClient
    )

    if (preloadedHoldingInfo?.holdingId && !preloadedHoldingInfo.holding) {
      getFetchCompanyByIdFunction(queryClient)(
        preloadedHoldingInfo.holdingId
      ).then((company) => {
        if (company) {
          dispatchEvent(
            MetricsSpreadsheetEvents.PRELOADED_HOLDING_FETCHED_EVENT,
            {
              company,
            }
          )
        }
      })
    }

    return instance
  }

  static forSingleHolding = ({
    company,
    intl,
    queryClient,
    preloadedMetricRows,
  }: {
    company: Company
    intl: IntlShape
    queryClient: QueryClient
    preloadedMetricRows: Metric[]
  }) => {
    const mode = MetricsSpreadsheetMode.SINGLE_HOLDING
    const instance = new MetricsSpreadsheetLogic(mode)

    instance.builder = new MetricsSpreadsheetBuilder(
      mode,
      instance.metricColumnIndex,
      instance.holdingColumnIndex,
      preloadedMetricRows
    )
    instance.service = MetricsSpreadsheetService.forSingleHolding({
      company,
      metricColumnIndex: instance.metricColumnIndex,
      holdingColumnIndex: instance.holdingColumnIndex,
      intl,
      queryClient,
    })

    return instance
  }

  getBuilder = () => {
    return this.builder
  }

  getService = () => {
    return this.service
  }
}
