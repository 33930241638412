import { IFormContext } from 'components/Form/hooks'
import { FIELD_NAMES as AccountingFieldNames } from 'containers/UpdatesV2/Accounting/constants/constants'
import {
  AccountingReportType,
  BalanceDateType,
  PeriodType,
  ReportDatePeriodType,
} from 'utils/constants/accounting'
import { ShareWithOption } from 'containers/UpdatesV2/components/ShareSettingsModal/components/ShareWithDropdown/useShareWithDropdown'
import { ScheduleRepetitionType } from 'utils/constants/scheduleRepetition'
import {
  TransactionFormHolding,
  TransactionFundTypes,
  TransactionTypes,
  UpdateAttachment,
} from 'utils/types/transactions'
import { AssociationType } from 'utils/constants/investorManagement'
import { DraftStatus } from 'containers/UpdatesV2/components/UpdateHeader/components/SaveDraftIcon/SaveDraftIcon'
import { BulkTransactionPortfolio } from 'utils/functions/transactions'
import { Holding } from './company'
import {
  IndexInvestmentVehicle,
  IndexInvestor,
  InvestmentVehicle,
  Investor,
} from './investors'
import { FundPortfolio, IndexPortfolio, Portfolio } from './portfolios'
import {
  BaseTag,
  PortfolioVehicleInvestorTransaction,
  TransactionInstrument,
  TransactionInstrumentType,
  Update,
} from './update'
import { Modify, Nullable, UpdateType, ValueOf } from './common'
import { User } from './user'

export enum AccessType {
  CAN_EDIT = 'CAN_EDIT',
  CAN_VIEW = 'CAN_VIEW',
  NO_ACCESS = 'NO_ACCESS',
}

export interface ShareWithEntity {
  id: string
  name: string
  type: 'user' | 'group'
  imageUrl?: string
}

export interface UpdatePermissions {
  yourGroup: AccessType.CAN_EDIT | AccessType.CAN_VIEW | AccessType.NO_ACCESS
  community: AccessType.CAN_VIEW | AccessType.NO_ACCESS
  investor?: AccessType.CAN_VIEW | AccessType.NO_ACCESS
  sharedWith: ShareWithEntity[]
  recentlyUsedLists?: number[]
  confidentialUpdate: boolean
}

export interface CreateUpdateFormikStatus {
  sharedWithModalCache: ShareWithOption[]
}

export interface CreateUpdateFormValues {
  permissions: UpdatePermissions
  tags: BaseTag[]
  files: UpdateAttachment[]
  updateType: UpdateType
  owner?: User
  portfolio?: Nullable<FundPortfolio>
}

export interface CreateBasicFormValues extends CreateUpdateFormValues {
  associationType?: ValueOf<typeof AssociationType>
  title?: string
  body?: string
  date?: Nullable<Date>
  investor?: Nullable<IndexInvestor>
  portfolio?: Nullable<FundPortfolio>
}

export interface AccountingFormValues extends CreateUpdateFormValues {
  [AccountingFieldNames.updateId]?: string
  [AccountingFieldNames.title]: string
  [AccountingFieldNames.date]: Date
  [AccountingFieldNames.repetition]?: ScheduleRepetitionType
  [AccountingFieldNames.reportType]?: AccountingReportType
  [AccountingFieldNames.periods]?: PeriodType
  [AccountingFieldNames.balanceDate]?: BalanceDateType
  [AccountingFieldNames.standardLayout]: boolean
  [AccountingFieldNames.paymentsOnly]: boolean
  [AccountingFieldNames.reportDatePeriod]?: ReportDatePeriodType
  [AccountingFieldNames.owner]?: User
}

export type CreateNoteFormValues = CreateBasicFormValues & {
  fundPortfolio?: Nullable<IndexPortfolio>
  investor?: Nullable<IndexInvestor>
}

export type CreateDocumentFormValues = CreateBasicFormValues & {
  fundPortfolio?: Nullable<IndexPortfolio>
  investor?: Nullable<IndexInvestor>
}

export type CreateAnnouncementValues = CreateBasicFormValues

export enum UpdateCreatedFrom {
  UPDATES = 'updates',
  ORGANIZATION_PROFILE = 'organization_profile',
  HOLDING = 'holding',
  PORTFOLIO = 'portfolio',
  INVESTMENT_VEHICLE = 'investment_vehicle',
  INVESTOR = 'investor',
}

export interface CreateUpdateRouteState {
  updateCreatedFrom: UpdateCreatedFrom
  holding?: Holding
  portfolio?: Portfolio
  investmentVehicle?: InvestmentVehicle
  investor?: Investor
  showInvestorAndFundSelector?: boolean
}

export interface UpdateFormContext extends IFormContext {
  isEditMode: boolean
  isShowMode: boolean
  updateType: UpdateType
  disabled: boolean
  updateId?: string
  isSavingDraft: boolean
  isUploadingFiles: boolean
  hasEditPermissions: boolean
  update?: Update
  setIsUploadingFiles: (isUploadingFiles: boolean) => void
  isEditingDraft: boolean
  submitDraft?: () => void
  initialDraftStatus?: DraftStatus
  isPublishing: boolean
  setIsPublishing: (isPublishing: boolean) => void
  hasDraftMode: boolean
  isOnline: boolean
  hasDraftUpdate?: boolean
  showInvestorAndFundSelector?: boolean
  hasDealsErrors?: boolean
}

type ModifiedTransactionInstrument = Modify<
  TransactionInstrument,
  {
    discountRate?: string
    interestRate?: string
    interestCalculationBasis?: string
    annualManagementFee?: string
    carry?: string
    carryHurdleRate?: string
    dividend?: string
    dividendCalculationBasis?: string
  }
>

export interface CreateTransactionBaseFormValues<TPortfolio = Portfolio>
  extends ModifiedTransactionInstrument {
  id?: string
  title?: string
  description?: string
  date?: Nullable<Date>
  type?: Nullable<TransactionTypes>
  holding?: Nullable<TransactionFormHolding>
  portfolios?: (TPortfolio & {
    isNewPortfolio?: boolean
  })[]
  fundPortfolio?: Nullable<IndexPortfolio>
  amount?: Nullable<number>
  transactionFundType?: Nullable<TransactionFundTypes>
  investor?: Nullable<
    IndexInvestor & { railsId?: string; externalSubjectId?: string }
  >
  investmentVehicle?: Nullable<
    IndexInvestmentVehicle & { railsId?: string; externalSubjectId?: string }
  >
  drawdownAgainstCommitment?: boolean
  instrument?: TransactionInstrumentType
  portfolioVehicleInvestorTransaction?: PortfolioVehicleInvestorTransaction
  duplicated?: boolean
  updateId?: string
  notShowShareWithInvestorModal?: boolean
}
export type CreateTransactionFormValues = CreateUpdateFormValues &
  CreateTransactionBaseFormValues

export type CreateBulkTransactionFormValues = CreateUpdateFormValues &
  CreateTransactionBaseFormValues<BulkTransactionPortfolio> & {
    id: string
  }

export type CreateUpdateGlobalProps = CreateBasicFormValues &
  CreateTransactionFormValues
