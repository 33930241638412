/* eslint-disable @typescript-eslint/no-use-before-define, react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import { useQueryClient } from '@tanstack/react-query'
import NumbersService from 'api/NumbersService'
import Toast from 'components/Toast'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { getCurrentGroupId } from 'selectors/auth'
import { deleteMilestone, undoDeleteMilestone } from 'utils/functions/metrics'
import { useGetMilestoneById } from 'utils/hooks/queries/useMilestonesQuery'
import useMetricQuery from 'utils/hooks/useMetricQuery'
import { useMetricsMode } from 'utils/hooks/useMetricsMode'
import { metricsKeys } from 'utils/queries/metrics'
import { getSetMilestoneSchema } from 'utils/schemas/metrics'
import { MetricsMode, MetricSources } from 'utils/types/metrics'

const useSetMetricMilestone = (closeDrawer, isEditingMilestone) => {
  const intl = useIntl()
  const [isSaving, setIsSaving] = useState(false)

  const { metricId, milestoneId } = useParams<{
    metricId: string
    milestoneId: string
  }>()
  const { metric, isLoading: isLoadingMetric } = useMetricQuery({
    metricId,
  })

  const metricsMode = useMetricsMode()
  const isFounder = metricsMode === MetricsMode.FOUNDER
  const isSystemMetric = useMemo(
    () => metric?.source === MetricSources.System,
    [metric]
  )

  const queryClient = useQueryClient()
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const initialValues = {
    notifyValueReached: false,
    notifyPercentageReached: false,
    shared: !isFounder,
    date: undefined as Date | undefined,
    value: undefined as number | undefined,
  }

  const formik = useFormik({
    validationSchema: getSetMilestoneSchema(),
    initialValues,
    onSubmit: (val) => onSubmit(val),
  })

  const { data: milestone } = useGetMilestoneById(milestoneId, {
    enabled: !!isEditingMilestone,
  })

  useEffect(() => {
    if (isEditingMilestone && milestone) {
      formik.setValues({
        notifyValueReached: milestone.notifyValueReached,
        notifyPercentageReached: milestone.notifyPercentageReached,
        date: milestone.date,
        value: milestone.value,
        shared: milestone.shared || false,
      })
    }
  }, [milestone, isEditingMilestone])

  const editMilestone = async (data) => {
    await NumbersService.editMilestone(milestoneId, data)
    Toast.display(
      intl.formatMessage({ id: 'editMetric.milestoneUpdatedSuccess' })
    )
  }

  const createMilestone = async (data) => {
    await NumbersService.addMilestone(metricId, data)
    Toast.display(
      intl.formatMessage({ id: 'editMetric.milestoneCreatedSuccess' })
    )
  }

  const onSubmit = async (data) => {
    try {
      setIsSaving(true)
      const milestoneData = {
        notifyValueReached: data.notifyValueReached,
        notifyPercentageReached: data.notifyPercentageReached,
        value: data.value !== null ? Number(data.value) : null,
        timestamp: dayjs(data.date).endOf('day').toDate(),
        shared: data.shared,
        id: milestone?.id,
        metadata: {
          groupId: currentGroupId,
        },
      }

      if (milestoneData.value === 0) {
        formik.setFieldError(
          'value',
          intl.formatMessage({ id: 'metrics.milestoneCannotBeZero' })
        )
        setIsSaving(false)
        return
      }

      if (isEditingMilestone) {
        await editMilestone(milestoneData)
      } else {
        await createMilestone(milestoneData)
      }

      queryClient.invalidateQueries(metricsKeys.getMilestone(metricId))
      queryClient.invalidateQueries(metricsKeys.getMilestones(metricId))

      closeDrawer()
      formik.resetForm()
    } catch (error) {
      Toast.display(intl.formatMessage({ id: 'editMetric.error' }), 'error')
    } finally {
      setIsSaving(false)
    }
  }

  const createDeleteMilestoneFunction = () => async (milestoneIdToDelete) => {
    try {
      await NumbersService.deleteMilestone(milestoneIdToDelete)
      queryClient.invalidateQueries(metricsKeys.getMilestones(metricId))
    } catch (error) {
      Toast.display(
        intl.formatMessage({ id: 'editMetric.milestoneDeletedError' }),
        'error'
      )
    }
  }

  const onDeleteMilestone = async () => {
    deleteMilestone({
      queryClient,
      metricId,
      milestone,
    })
    const deleteMilestoneFunc = createDeleteMilestoneFunction()

    Toast.displayAction({
      message: intl.formatMessage({ id: 'editMetric.milestoneDeletedSuccess' }),
      action: () => {
        undoDeleteMilestone({
          queryClient,
          metricId,
          milestone,
        })
      },
      afterClose: () => deleteMilestoneFunc(milestoneId),
    })

    closeDrawer()
  }

  return {
    formik,
    metric,
    isSystemMetric,
    milestone,
    isEditingMilestone,
    isSaving,
    onDeleteMilestone,
    isLoadingMetric,
    isFounder,
  }
}

export default useSetMetricMilestone
