/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import type { FormikProps } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import useQueryString from 'utils/hooks/useQueryString'
import { ValueOf } from 'utils/types/common'
import {
  InvestorFormValues,
  AddInvestorStepFormValues,
  AddInvestorOtherInfoFormValues,
  AddInvestorSendInvitesFormValues,
  AddInvestorLinksFormValues,
  TeamMembersFormValues,
} from 'utils/types/investors'
import Toast from 'components/Toast'
import { getCreateInvestorInitialValues } from 'utils/constants/investorManagement'
import UserService from 'api/UserService'
import { User } from 'utils/types/user'
import { differenceWith } from 'lodash'
import { getInvestorSubjectPayload } from 'utils/functions/investorManagement'
import { isTypeIdentifierDuplicatedError } from 'utils/functions/errors'
import { useMutation } from '@apollo/client'
import {
  CREATE_SUBJECT_FROM_TYPE,
  UPDATE_SUBJECT,
} from 'utils/gql/mutations/subjects'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCurrentGroupId } from 'selectors/auth'
import { GET_COUNT_SUBJECTS } from 'utils/gql/queries/subjects'
import {
  BasicSubjectFragment,
  CreateSubjectDto,
} from 'types/graphql-schemas/graphql'
import { useSuggestCreateInvestmentVehicleModal } from 'utils/hooks/useSuggestCreateInvestmentVehicleModal'
import { useQueryClient } from '@tanstack/react-query'
import { subjectsKeys } from 'utils/queries/subjects'
import { SubjectType } from 'utils/types/subjects'
import { getCurrentCompany } from 'selectors/company'

export const FormSteps = {
  ADD_INVESTOR: 'add',
  ADD_OTHER_INFO: 'other_info',
  SEND_INVITATION: 'send',
} as const

const useCreateInvestor = (onCloseDrawer: () => void) => {
  const history = useHistory()
  const intl = useIntl()
  const location = useLocation<{ initialInvestorName?: string }>()
  const initialInvestorName = location.state?.initialInvestorName ?? ''
  const { isSuggestionModalEnabled, setSuggestionModalData } =
    useSuggestCreateInvestmentVehicleModal()
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const fundCompany = useAppSelector(getCurrentCompany)

  const [createSubjectFromType, { loading: isLoading }] = useMutation(
    CREATE_SUBJECT_FROM_TYPE,
    {
      refetchQueries: [GET_COUNT_SUBJECTS],
    }
  )
  const [updateSubject, { loading: isLoadingPermissionsGrant }] =
    useMutation(UPDATE_SUBJECT)

  const queryClient = useQueryClient()

  const addInvestorFormRef =
    useRef<FormikProps<AddInvestorStepFormValues>>(null)

  const otherInfoFormRef =
    useRef<
      FormikProps<
        AddInvestorOtherInfoFormValues &
          AddInvestorLinksFormValues &
          TeamMembersFormValues
      >
    >(null)

  const sendInvitesFormRef =
    useRef<FormikProps<AddInvestorSendInvitesFormValues>>(null)

  const currentStep = useQueryString('step')

  const setStep = (step: ValueOf<typeof FormSteps>) => {
    return history.push(`${history.location.pathname}?step=${step}`)
  }

  useEffect(() => {
    if (!currentStep) {
      setStep(FormSteps.ADD_INVESTOR)
    }
  }, [])

  const createInvestor = async () => {
    const values: InvestorFormValues = {
      ...addInvestorFormRef.current?.values!,
      ...otherInfoFormRef.current?.values!,
      ...sendInvitesFormRef.current?.values!,
    }

    const payload = getInvestorSubjectPayload<CreateSubjectDto>({
      currentGroupId,
      investorData: values,
    })

    try {
      const response = await createSubjectFromType(payload)

      if (isSuggestionModalEnabled) {
        setSuggestionModalData({
          type: payload.variables.type,
          name: payload.variables.data.name,
          id: response.data?.createSubjectFromType.id,
        } as BasicSubjectFragment)
      }

      await updateSubject({
        variables: {
          id: fundCompany.id,
          data: {
            permissions: [
              {
                entityId: response.data?.createSubjectFromType.railsId!,
                read: true,
                write: false,
              },
            ],
          },
        },
      })

      const groupMembers = !!values.users.length
      Toast.displayIntl(
        [
          groupMembers
            ? 'investorManagement.success.createdInvestorWithMembers'
            : 'investorManagement.success.createInvestor',
          {
            investorName: payload.variables.data.name,
            membersLength: values.users.length,
          },
        ],
        'success'
      )

      queryClient.invalidateQueries(
        subjectsKeys.byFilters({ filters: { type: SubjectType.INVESTOR } })
      )

      onCloseDrawer()
    } catch (error) {
      if (isTypeIdentifierDuplicatedError(error)) {
        addInvestorFormRef.current?.setFieldError(
          'name',
          intl.formatMessage({
            id: 'investorManagement.investorNameTakenError',
          })
        )
        setStep(FormSteps.ADD_INVESTOR)
      } else {
        Toast.displayIntl('investorManagement.errors.createInvestor', 'error')
      }
    }
  }

  const setInvestorGroupUsers = async (): Promise<void> => {
    const investorEmails = addInvestorFormRef.current?.values.emails ?? []
    const users = sendInvitesFormRef.current?.values.users ?? []
    const removedUsers = sendInvitesFormRef.current?.values.removedUsers ?? []

    const addedEmails = differenceWith<string, User>(
      investorEmails,
      users,
      (investorEmail, investorUser) =>
        investorEmail.toLowerCase() === investorUser.email.toLowerCase()
    ).filter(
      (addedEmail) =>
        !removedUsers.some((removedUser) => removedUser.email === addedEmail)
    )

    if (addedEmails.length) {
      const investorGroupUsers = await UserService.bulkCreateUser({
        emails: addedEmails,
      })

      sendInvitesFormRef.current?.setFieldValue('users', [
        ...users,
        ...investorGroupUsers,
      ])
    }
  }

  const goToNextStep = async () => {
    let nextStep
    switch (currentStep) {
      case FormSteps.ADD_INVESTOR:
        nextStep = FormSteps.ADD_OTHER_INFO
        break
      case FormSteps.ADD_OTHER_INFO:
        nextStep = FormSteps.SEND_INVITATION
        setInvestorGroupUsers()
        break
      default:
        nextStep = FormSteps.ADD_OTHER_INFO
        break
    }

    return setStep(nextStep)
  }

  const goToPreviousStep = () => {
    history.goBack()
  }

  const onCancel = () => {
    onCloseDrawer()
  }

  const handleStepClick = async (nextStep: ValueOf<typeof FormSteps>) => {
    if (nextStep === FormSteps.SEND_INVITATION) {
      if (currentStep === FormSteps.ADD_INVESTOR) {
        await addInvestorFormRef.current?.submitForm()
        if (!addInvestorFormRef.current?.isValid) return false
      }

      await otherInfoFormRef.current?.submitForm()
      return otherInfoFormRef.current?.isValid
    }

    if (nextStep === FormSteps.ADD_OTHER_INFO) {
      await addInvestorFormRef.current?.submitForm()
      return addInvestorFormRef.current?.isValid
    }
    return true
  }

  const initialValues = getCreateInvestorInitialValues(
    intl,
    initialInvestorName
  )

  return {
    currentStep,
    goToNextStep,
    setStep,
    goToPreviousStep,
    createInvestor,
    onCancel,
    handleStepClick,
    addInvestorFormRef,
    otherInfoFormRef,
    sendInvitesFormRef,
    initialValues,
    isLoading: isLoading || isLoadingPermissionsGrant,
  }
}

export default useCreateInvestor
