import { useMutation } from '@apollo/client'
import Toast from 'components/Toast'
import { useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'

import TagService from 'api/TagService'
import {
  ADD_SUBJECT_TAGS,
  REMOVE_SUBJECT_TAGS,
} from 'utils/gql/mutations/subjects'
import { useDynamicWidth } from 'utils/hooks/useDynamicWidth'
import { TagDto, TagFragment } from 'types/graphql-schemas/graphql'
import { useQueryClient } from '@tanstack/react-query'
import { subjectsKeys } from 'utils/queries/subjects'

interface Props {
  holdingId: string
  tags?: TagFragment[]
}

const dynamicWidthConfig = {
  rowsCount: 1,
  baseWidth: 33,
  showMoreWidth: 60,
  paddingGap: 13,
}

const useTags = ({ tags, holdingId }: Props) => {
  const [addSubjectTags] = useMutation(ADD_SUBJECT_TAGS)
  const [removeSubjectTags] = useMutation(REMOVE_SUBJECT_TAGS)
  const [tagsList, setTagsList] = useState(tags || [])
  const tagsToAdd = useRef<TagDto[]>([])
  const tagsToRemove = useRef<string[]>([])
  const queryClient = useQueryClient()

  const totalTags = tagsList.length

  const { containerRef, maxItemsCount: numberOfTagsToShow } = useDynamicWidth(
    tagsList.map((tag) => ({ name: tag.tagName })),
    false,
    dynamicWidthConfig
  )

  const refetchHolding = () => {
    queryClient.invalidateQueries(subjectsKeys.byId(holdingId))
  }

  const addTags = useMemo(
    () =>
      debounce(async () => {
        try {
          const newTags = tagsToAdd.current
          tagsToAdd.current = []
          await addSubjectTags({
            variables: {
              id: holdingId,
              tags: newTags,
            },
          })
          refetchHolding()
        } catch (err) {
          Toast.displayIntl('tags.errorAddingTag', 'error')
        }
      }, 800),
    [addSubjectTags, holdingId]
  )

  const removeTags = useMemo(
    () =>
      debounce(async () => {
        try {
          const removedTags = tagsToRemove.current
          tagsToRemove.current = []
          await removeSubjectTags({
            variables: {
              id: holdingId,
              tags: removedTags,
            },
          })
          refetchHolding()
        } catch (err) {
          Toast.displayIntl('tags.errorRemovingTag', 'error')
        }
      }, 800),
    [holdingId, removeSubjectTags]
  )

  const onSelectTag = async (selectedTag: TagDto) => {
    tagsToAdd.current.push(selectedTag)
    addTags()
    setTagsList((prev) => [...prev, selectedTag])
  }

  const onAddNewTag = async (tagName: string) => {
    try {
      const newTag = await TagService.createTag(tagName)
      onSelectTag({
        tagId: newTag.id,
        tagName: newTag.name,
      })
    } catch (error) {
      Toast.displayIntl('tags.errorAddingTag', 'error')
    }
  }

  const onRemoveTag = async (tagToRemove: TagFragment) => {
    tagsToRemove.current.push(tagToRemove.tagId)
    removeTags()

    setTagsList((prev) => prev.filter((tag) => tag.tagId !== tagToRemove.tagId))
  }

  return {
    tagsList,
    numberOfTagsToShow,
    totalTags,
    containerRef,

    onSelectTag,
    onAddNewTag,
    onRemoveTag,
  }
}

export default useTags
