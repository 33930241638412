import { CompanyData, CompanyHoldingData } from 'utils/types/company'
import { orderByPositionAsc } from 'utils/functions/order'
import ResponseNormalizer from 'utils/functions/responseNormalizer'
import convertJsonToFormData from 'utils/functions/convertJsonToFormData'
import { getNumKeys } from 'containers/Investments/InvestmentsFilters/helpers'
import { InvestmentsFiltersObject } from 'containers/Investments/InvestmentsFilters/types'
import { LogoImage } from 'components/AddHoldingModal/types'
import axiosClient from './httpClient'
import { InvestmentFiltersMapperForQuerying } from './InvestmentsFilterService'

const GROUP_COMPANIES_PAGE_SIZE = 5000

export const getCompaniesParams = (filters: InvestmentsFiltersObject) => {
  const queryParams = {}
  const numKeys = getNumKeys(filters)

  Object.entries(filters).forEach(([key, values]) => {
    if (values && InvestmentFiltersMapperForQuerying[key]) {
      queryParams[InvestmentFiltersMapperForQuerying[key]] = values
    }
  })

  if (numKeys > 1) {
    queryParams['q[m]'] = 'or'
  }

  return queryParams
}

class CompanyService {
  static createCompany = async (companyDatum: any, groupId?: string) => {
    const {
      data: { result: companyId, entities },
    } = await axiosClient(
      false,
      groupId
        ? {
            'group-id': groupId,
          }
        : undefined,
      {},
      true
    ).post('/companies', { companyDatum })
    return entities.companyData[companyId]
  }

  static axiosClientFormData = () => axiosClient(true, {}, undefined, true)

  static createPublicCompany = async ({
    legalEntityName,
    name,
    website,
    pointOfContact,
    logo,
  }: {
    legalEntityName: string
    name: string
    website?: string
    pointOfContact?: string
    logo?: LogoImage
  }) => {
    const url = 'companies'
    const company = {
      company_datum: {
        legal_entity_name: legalEntityName,
        name,
        website,
        point_of_contact: pointOfContact,
        primary_logo: logo?.file,
        verified: true,
        quality_assured: false,
      },
    }

    const companyFormData = convertJsonToFormData(company)
    return this.axiosClientFormData().post(url, companyFormData)
  }

  static getCompanyById = (companyId) => {
    return axiosClient().get(`companies/${companyId}`)
  }

  static getCompanyByWebsite = (website) => {
    return axiosClient().get(`/companies/company_scrapper?website=${website}`)
  }

  static editCompany = (id, body, formData) => {
    return axiosClient(formData).patch(`/companies/${id}`, body)
  }

  static setCompanyImage = (id, imageFile) => {
    const formData = new FormData()
    formData.append('company_datum[primary_logo]', imageFile)
    return this.editCompany(id, formData, true)
  }

  static getGroupCompanies = (groupId) => {
    const params = {
      'q[group_id_eq]': groupId,
      per_page: GROUP_COMPANIES_PAGE_SIZE,
    }

    return axiosClient().get(`/companies`, { params })
  }

  static createCompanyIndustries = (id, body) => {
    return axiosClient().post(
      `/companies/${id}/investment_tool_industries`,
      body
    )
  }

  static removeCompanyIndustry = (id) => {
    return axiosClient().delete(`/investment_tool_industries/${id}`)
  }

  static editCompanyLocation = async (id, body) => {
    const {
      data: { entities },
    } = await axiosClient().patch(`/locations/${id}`, body)

    return orderByPositionAsc(
      Object.values(entities.locations).map((loc) =>
        ResponseNormalizer.normalizeLocation(loc, entities)
      )
    )
  }

  static removeCompanyLocation = (id) => {
    return axiosClient().delete(`/locations/${id}`)
  }

  static createCompanyLocation = (id, body) => {
    return axiosClient().post(`/companies/${id}/locations`, body)
  }

  static createCompanyEmployee = (id, body) => {
    return axiosClient().post(`companies/${id}/employees`, body)
  }

  static createCompanyAnnouncement = (companyId, body) => {
    return axiosClient().post(`/companies/${companyId}/announcements`, body)
  }

  static createCompanyNote = (companyId, body) => {
    return axiosClient().post(`/companies/${companyId}/notes`, body)
  }

  static createCompanyDocument = (companyId, body) => {
    return axiosClient().post(`/companies/${companyId}/documents`, body)
  }

  static getCompanies = (page, params, companiesPerPage) => {
    return axiosClient().get(
      `/companies?page=${page}&per_page=${companiesPerPage}`,
      {
        params,
      }
    )
  }

  static fetchCompanies = async (
    filters: { [key: string]: string | string[] },
    page: number,
    pageSize: number = 20
  ): Promise<CompanyData[]> => {
    const {
      data: { result, entities },
    } = await CompanyService.getCompanies(page, filters, pageSize)
    return result.map((companyId) =>
      ResponseNormalizer.normalizeCompanyData(companyId, entities)
    )
  }

  static fetchCompany = async (
    companyId: string
  ): Promise<CompanyHoldingData> => {
    const {
      data: { result, entities },
    } = await CompanyService.getCompanyById(companyId)

    return ResponseNormalizer.normalizeCompanyData(result, entities)
  }

  static getCompanyPortfolios = async (companyId) => {
    const {
      data: {
        entities: { portfolioCompanies = {}, portfoliables = {} },
      },
    } = await axiosClient().get(
      `/portfolio_companies?q[holding_id_eq]=${companyId}&per_page=100`
    )

    return Object.values(portfolioCompanies).map((portfolioCompany: any) => ({
      ...portfolioCompany,
      portfolioCompanyId: portfolioCompany.id,
      ...portfoliables[portfolioCompany.portfoliable],
    }))
  }

  static getFilteredPortfolioHoldingsIds = async (
    filters: InvestmentsFiltersObject
  ) => {
    const queryParams = getCompaniesParams(filters)

    const {
      data: { result },
    } = await axiosClient().get('/portfolio_companies', {
      params: {
        ...queryParams,
        page: 1,
        per_page: 1000,
      },
    })

    return result
  }

  static updateCompanyPortfolios = (
    companyId,
    newPortfolios,
    deletedPortfolios
  ) => {
    return axiosClient().patch(`/companies/${companyId}/update_group_scoped`, {
      companyDatum: {
        holdingPortfolioCompaniesAttributes: [
          ...newPortfolios.map((portfolio) => ({
            portfoliableId: portfolio.id,
            type: portfolio.type,
          })),
          ...deletedPortfolios.map((portfolio) => ({
            id: portfolio.portfolioCompanyId,
            _destroy: true,
          })),
        ],
      },
    })
  }

  static updateTagsByCompany = (companyId, tag, isFundHolding) => {
    const taggingsAttributes: any[] = []
    if (!tag?.taggingId) {
      if (tag.alreadyExists) {
        taggingsAttributes.push({
          id: null,
          tag_id: tag.id,
        })
      }
      if (!tag.alreadyExists) {
        taggingsAttributes.push({
          tag_id: null,
          tag_attributes: { name: tag.name },
        })
      }
      if (tag.destroy) {
        taggingsAttributes.push({
          id: tag.id,
          _destroy: true,
        })
      }
    } else if (tag.destroy) {
      taggingsAttributes.push({
        id: tag.taggingId,
        _destroy: true,
      })
    }

    if (isFundHolding) {
      return axiosClient().patch(
        `/fund_portfolio_profiles/${companyId}/update_group_scoped`,
        {
          fund_portfolio: {
            taggingsAttributes,
          },
        }
      )
    }

    return axiosClient().patch(`/companies/${companyId}/update_group_scoped`, {
      company_datum: {
        taggingsAttributes,
      },
    })
  }

  static searchPublicProfileByUserId = async (
    userId: string,
    website: string = ''
  ) => {
    let qs = ''
    if (website) {
      qs = `?website=${website}`
    }
    const {
      data: { entities, result: companyId },
    } = await axiosClient().get(`/users/${userId}/company_by_domain${qs}`)

    if (entities?.companyData?.[companyId]) {
      const company = entities.companyData[companyId]
      company.logoUrl = entities.companyData[companyId]?.primaryLogo?.url
      company.claimCompanyProfile = true

      const primaryLocationId = entities.companyData[companyId]?.primaryLocation
      const primaryLocation = entities.primaryLocations?.[primaryLocationId]
      const legalStructureId = entities.companyData[companyId]?.legalStructure

      if (primaryLocation) {
        company.primaryLocation = {
          name: primaryLocation?.name,
          provinceName: primaryLocation?.provinceName,
          countryName: primaryLocation?.countryName,
        }
      }
      company.industries = Object.values(entities.industries || {})
      company.legalStructure = entities.legalStructures?.[legalStructureId]
      return { companyFound: true, company }
    }

    return { companyFound: false }
  }

  static claimCompanyProfile = (groupId, companyId) => {
    return axiosClient().patch(
      `/groups/${groupId}/companies/${companyId}/associate`,
      null,
      {
        headers: {
          'group-id': groupId,
        },
      }
    )
  }

  static crawlCompanyByWebsite = (website) => {
    return axiosClient().get(`/companies/company_crawler?website=${website}`)
  }

  static getPortfolioCompanies = async ({
    page,
    perPage,
    sortBy,
    sortDirection,
    params,
  }) => {
    const {
      data: { result, entities },
    } = await CompanyService.getCompanies(
      page,
      {
        only_portfolio: 1,
        order_by: sortBy,
        direction: sortDirection,
        ...params,
      },
      perPage
    )

    const companies = result.map((companyId) => {
      const companyData = entities.companyData[companyId]
      return {
        ...companyData,
        industries: companyData.industries.map(
          (industryId) => entities.industries[industryId]
        ),
        sectors: companyData.sectors.map(
          (sectorId) => entities.sectors[sectorId]
        ),
        primaryLocation: entities.primaryLocations[companyData.primaryLocation],
        portfolios: companyData.portfolios.map(
          (portfolioId) => entities.portfolios[portfolioId]
        ),
      }
    })

    return { companies, portfolios: entities.portfolios }
  }
}

export default CompanyService
