import { createContext, useContext, useEffect, useMemo } from 'react'
import { useMetrics } from 'containers/Metrics/MetricsList/useMetrics'
import type { InfiniteData } from '@tanstack/react-query'
import { useMetricsQuery } from 'utils/hooks/queries/useMetrics'
import { ReactFCWithChildren } from 'utils/types/common'
import { IndexMetric } from 'utils/types/metricsV2'
import { SubjectType } from 'utils/types/subjects'
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll'
import {
  SortCriteria,
  useMetricsContext,
} from 'containers/Metrics/MetricsContext'
import { MetricSortBy } from 'utils/constants/metrics'
import { SortDirection } from 'utils/constants'
import { useSubjectContext } from '../subjects/SubjectContext'

interface AllMetricsListContextType {
  metrics?: InfiniteData<{
    data: IndexMetric[]
    page: any
  }>
  allMetrics: IndexMetric[]
  isLoading: boolean
  isFetchingNextPage: boolean
  onScreen: boolean
  actions: any
  debouncedSearch: string
  sortCriteria: SortCriteria
  setSortCriteria: (sort: SortCriteria) => void
  infiniteScrollRef: (node: HTMLDivElement) => void
}

const AllMetricsListContext = createContext<AllMetricsListContextType>({
  metrics: {
    pages: [],
    pageParams: [],
  },
  debouncedSearch: '',
  sortCriteria: {
    field: MetricSortBy.NAME,
    direction: SortDirection.ASC,
  },
  allMetrics: [],
  isLoading: false,
  isFetchingNextPage: false,
  onScreen: false,
  actions: {},
  infiniteScrollRef: () => {},
  setSortCriteria: () => {},
})

export const AllMetricsListContextProvider: ReactFCWithChildren<{
  search?: string
}> = ({ children, search }) => {
  const { railsId: holdingId, subject } = useSubjectContext()
  const { debouncedSearch, sortCriteria, setSortCriteria } = useMetricsContext()

  const {
    ref: infiniteScrollRef,
    onScreen,
    setInfiniteScrollEnabled,
  } = useInfiniteScroll()

  const { metrics, isLoading, isFetchingNextPage, fetchNextMetricsPage } =
    useMetricsQuery({
      filters: {
        sortBy: sortCriteria?.field,
        sortDirection: sortCriteria?.direction,
        metricName: search ?? debouncedSearch,
        holding: {
          id: holdingId,
          type: subject.type as SubjectType,
        },
      },
      setInfiniteScrollEnabled,
    })

  const actions = useMetrics()

  const allMetrics: IndexMetric[] = useMemo(
    () =>
      (metrics?.pages?.flatMap((page) => page.data) || []).filter(
        (metric) => !actions.removedMetricsIds.includes(metric.id)
      ),
    [actions.removedMetricsIds, metrics?.pages]
  )

  useEffect(() => {
    if (onScreen) {
      fetchNextMetricsPage()
    }
  }, [fetchNextMetricsPage, onScreen])

  const value: AllMetricsListContextType = useMemo(
    () => ({
      metrics,
      allMetrics,
      isLoading,
      isFetchingNextPage,
      onScreen,
      actions,
      debouncedSearch,
      sortCriteria,
      infiniteScrollRef,
      setSortCriteria,
    }),
    [
      metrics,
      debouncedSearch,
      sortCriteria,
      allMetrics,
      isLoading,
      isFetchingNextPage,
      onScreen,
      actions,
      infiniteScrollRef,
      setSortCriteria,
    ]
  )

  return (
    <AllMetricsListContext.Provider value={value}>
      {children}
    </AllMetricsListContext.Provider>
  )
}

export const useAllMetricsListContext = () => useContext(AllMetricsListContext)
