import sortBy from 'lodash/sortBy'
import { GooglePlaceData, Location } from 'utils/types/locations'
import { InvestorLocation } from 'utils/types/investors'
import { getAddedAndRemovedEntities } from './utils'

interface AddLocationPayload {
  position: number
  placeAttributes: {
    formattedAddress: string
    googlePlaceId: string
    latitude: number
    longitude: number
    types: string[]
    addressComponentsAttributes: {
      long_name: string
      short_name: string
      types: string[]
    }[]
  }
}

interface RemoveLocationPayload {
  id: string
  _destroy: true
}

interface EditLocationPayload {
  id: string
  position: number
}

export type LocationPayload =
  | AddLocationPayload
  | RemoveLocationPayload
  | EditLocationPayload

export const getAddLocationPayload = (
  location: InvestorLocation,
  position: number
): AddLocationPayload => {
  return {
    position,
    placeAttributes: {
      googlePlaceId: location.geocodedPlace!.place_id,
      formattedAddress: location.geocodedPlace!.formatted_address,
      latitude: location.geocodedPlace!.geometry?.location.lat(),
      longitude: location.geocodedPlace!.geometry?.location.lng(),
      types: location.geocodedPlace!.types,
      addressComponentsAttributes: location.geocodedPlace!.address_components,
    },
  }
}

export const getEditLocationPositionPayload = (
  location: Location,
  position: number
): EditLocationPayload => {
  return {
    id: location.id,
    position,
  }
}

export const getRemoveLocationPayload = (
  location: Location
): RemoveLocationPayload => {
  return {
    id: location.id,
    _destroy: true,
  }
}

export const getLocationsPayload = (
  existentLocations: Location[],
  newLocations: InvestorLocation[]
): LocationPayload[] => {
  const { added: addedLocations, removed: removedLocations } =
    getAddedAndRemovedEntities<Location, InvestorLocation>(
      existentLocations,
      newLocations,
      [
        (location) => location.place!.googlePlaceId,
        (investorLocation) => investorLocation.googlePlaceId,
      ]
    )

  const isNewLocation = (location: InvestorLocation) =>
    addedLocations.some((loc) => loc.googlePlaceId === location.googlePlaceId)

  const locations = newLocations.map((investorLocation, index) => {
    if (isNewLocation(investorLocation)) {
      return getAddLocationPayload(investorLocation, index + 1)
    }

    const originalLocation = existentLocations.find(
      (loc) => loc.place!.googlePlaceId === investorLocation.googlePlaceId
    )
    return getEditLocationPositionPayload(originalLocation!, index + 1)
  })

  const locationsToRemove = removedLocations.map((location) =>
    getRemoveLocationPayload(location)
  )

  return [...locations, ...locationsToRemove]
}

export const getInvestorLocations = (
  locations: Location[]
): InvestorLocation[] => {
  return sortBy(locations, (loc) => loc.position).map((location, index) => ({
    id: location.id,
    main: index === 0,
    formattedAddress: location.place?.formattedAddress ?? '',
    googlePlaceId: location.place?.googlePlaceId ?? '',
  }))
}

export const getInvestorLocationsFromSubject = (
  locations: GooglePlaceData[]
): InvestorLocation[] => {
  return locations.map((location, index) => ({
    id: location.googlePlaceId,
    main: index === 0,
    formattedAddress: location.formattedAddress ?? '',
    googlePlaceId: location.googlePlaceId ?? '',
  }))
}
