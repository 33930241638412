import styled, { css, keyframes } from 'styled-components'
import { motion } from 'framer-motion'
import { ReactChildren } from 'utils/types/common'
import { Scroll } from 'App.styles'

export interface DrawerContentProps {
  contentPadding?: string
  overflow?: string
  children?: ReactChildren
  ref?: React.ForwardedRef<HTMLDivElement>
}

export const Container = styled(motion.div)`
  position: fixed;
  right: 0;
  top: 0;
  height: var(--viewHeight, 100vh);

  width: 0rem;
  background: var(--color-white);
  z-index: 99999;
  box-shadow: 0 0.4rem 0.8rem 0 rgba(55, 56, 60, 0.16);
  max-width: 100%;
`

export const DrawerContent = styled.div<DrawerContentProps>`
  ${Scroll}
  height: calc(100vh - 5.8rem);
  overflow: auto;
  overflow: ${({ overflow }) => overflow || 'overlay'};
  padding: ${({ contentPadding }) => contentPadding || '0 2.4rem 2.4rem'};

  &:empty {
    display: none;
  }
`

export const StyledDrawer = styled.aside`
  position: relative;
  height: 100vh;
`

export const DrawerTitle = styled.h2<{ trunc?: boolean }>`
  color: #101527;
  font-size: 1.8rem;
  display: flex;
  align-items: flex-start;

  ${(props) =>
    props.trunc &&
    css`
      max-width: 20rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  > svg {
    font-size: 1.4rem;
    margin-right: 0.8rem;
    margin-bottom: 0.2rem;
    margin-top: 0.3rem;
  }
`

export const DrawerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  min-height: 50vh;
`

export const Sticky = styled.div`
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
`

export const customFadeInKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  
  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`
