import React from 'react'
import InputLabel from 'ui/InputLabel'

import { Container, CheckRadio, Text } from './RadioButton.styles'

export type RadioValue = string | number | boolean

export type RadioOption = {
  id?: string
  label: string | React.ReactNode
  value: RadioValue
}

interface RadioButtonProps {
  name: string
  label?: string
  value: RadioValue
  onChange: (value: RadioValue) => void
  options: RadioOption[]
  direction?: 'row' | 'column'
  marginLeft?: string
  uncheckedWithOpacity?: boolean
  className?: string
  fullWidth?: boolean
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  name,
  label,
  value,
  onChange,
  options,
  direction = 'row',
  marginLeft,
  uncheckedWithOpacity = true,
  className,
  fullWidth,
}) => {
  return (
    <div>
      {label && <InputLabel>{label}</InputLabel>}
      <Container direction={direction} className={className}>
        {options.map((radioOption) => (
          <CheckRadio
            fullWidth={fullWidth}
            key={radioOption.id || String(radioOption.value)}
            id={radioOption.id || String(radioOption.value)}
            name={name}
            unchecked={value !== radioOption.value}
            checked={value === radioOption.value}
            value={String(radioOption.value)}
            mode="custom"
            type="radio"
            onChange={() => onChange(radioOption.value)}
            marginLeft={marginLeft}
            uncheckedWithOpacity={uncheckedWithOpacity}
          >
            <Text>{radioOption.label}</Text>
          </CheckRadio>
        ))}
      </Container>
    </div>
  )
}

export default React.memo(RadioButton)
