import { useIntl } from 'react-intl'
import { Helmet } from 'components/Helmet'
import { getFundCompanySubjectId } from 'selectors/auth'

import { useAppSelector } from 'utils/hooks/reduxToolkit'
import CompanySubjectProfile from '../CompanySubjectProfile'

export const GROUP_PROFILE_PREFIX = 'group-profile'

const FundManagerProfile = () => {
  const subjectId = useAppSelector(getFundCompanySubjectId)
  const intl = useIntl()
  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'metaTitles.organizationProfile' })}
        </title>
      </Helmet>
      <CompanySubjectProfile
        subjectId={subjectId!}
        shouldRenderHelmet={false}
      />
    </>
  )
}

export default FundManagerProfile
