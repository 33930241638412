import { SortDirection } from 'utils/constants'
import { MetricSortBy } from 'utils/constants/metrics'
import { SubjectType } from 'utils/types/subjects'

export const QUERIES = {
  METRIC: 'metric',
  METRICS: 'metrics',
  PERFORMANCE_METRICS: 'performance_metrics',
  PENDING_INVITATIONS: 'pending_invitations',
  INVITATIONS_HISTORY: 'invitations_history',
  FOUNDER_METRICS: 'founder_metrics',
  DATA_POINTS: 'data_points',
  MILESTONES: 'milestones',
  MILESTONE: 'milestone',
} as const

export type MetricsFilters = {
  metricName?: string
  sortBy?: MetricSortBy
  sortDirection?: SortDirection
  companyDatumIdsToExclude?: string[]
  metricIds?: string[]
  selected?: boolean
  selectedUserPortfolioId?: string
  subjectId?: string
  holding?: {
    id: string
    type: SubjectType
  }
}

export const metricsKeys = {
  getMetric: (metricId?: string) =>
    metricId
      ? ([QUERIES.METRIC, metricId] as const)
      : ([QUERIES.METRIC] as const),
  getPerformanceMetrics: (portfolioId: string) =>
    [QUERIES.PERFORMANCE_METRICS, portfolioId] as const,
  getPendingInvitations: () => [QUERIES.PENDING_INVITATIONS] as const,
  getInvitationsHistory: () => [QUERIES.INVITATIONS_HISTORY] as const,
  getDataPoints: (metricId: string) => [QUERIES.DATA_POINTS, metricId] as const,
  getMilestones: (metricId: string) => [QUERIES.MILESTONES, metricId] as const,
  getMilestone: (milestoneId: string) =>
    [QUERIES.MILESTONE, milestoneId] as const,

  getMetrics: (filters?: MetricsFilters) => {
    const { metricIds: _, ...restFilters } = filters || {}

    return filters
      ? ([QUERIES.METRICS, restFilters] as const)
      : ([QUERIES.METRICS] as const)
  },
}
