import Card from 'components/Card'
import { useIntl } from 'react-intl'

import { useSubjectContext } from 'utils/context/subjects/SubjectContext'
import { Employee } from 'utils/types/company'

import HeaderAdditionalInformation from './CompanyHeader/HeaderAdditionalInformation.v2'
import HeaderBasicInformation from './CompanyHeader/HeaderBasicInformation.v2'
import CompanyKeyPeople from './CompanyKeyMembers/CompanyKeyMembers'
import {
  HeaderInfoWrapper,
  SideCardWrapper,
  SideInformationWrapper,
} from './CompanyInformation.styles'
import CompanyDescription from './CompanyDescription/CompanyDescription'

const CompanyInformation = () => {
  const intl = useIntl()

  const { getAttribute } = useSubjectContext()

  const employees = getAttribute<Employee[]>('employees')?.value || []
  const description = getAttribute<string>('description')?.value
  return (
    <SideInformationWrapper>
      <HeaderInfoWrapper>
        <HeaderBasicInformation />
        <HeaderAdditionalInformation />
      </HeaderInfoWrapper>

      {description && (
        <SideCardWrapper>
          <Card isExpandable padding="1.4rem 2.4rem">
            <Card.Header>
              {intl.formatMessage({ id: 'companyProfile.description' })}
            </Card.Header>
            <Card.Body>
              <CompanyDescription content={description} />
            </Card.Body>
          </Card>
        </SideCardWrapper>
      )}

      {!!employees.length && (
        <SideCardWrapper>
          <Card isExpandable padding="1.4rem 2.4rem">
            <Card.Header>
              {intl.formatMessage({ id: 'investorManagement.keyPeople' })}
            </Card.Header>
            <Card.Body>
              <CompanyKeyPeople content={employees} />
            </Card.Body>
          </Card>
        </SideCardWrapper>
      )}
    </SideInformationWrapper>
  )
}

export default CompanyInformation
