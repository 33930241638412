import type { IntlShape } from 'react-intl'

export const InvestorTypes = {
  ORGANIZATION: 'organization',
  INDIVIDUAL: 'individual',
} as const

export const getCreateInvestorInitialValues = (
  intl: IntlShape,
  investorName: string = ''
) => ({
  ADD_INVESTOR: {
    type: InvestorTypes.INDIVIDUAL,
    logo: {
      image: null,
      imageUrl: '',
      cropperUrl: '',
      hasRemovedImage: false,
    },
    name: investorName,
    website: '',
    phone: '',
    emails: [],
    locations: [],
    description: '',
  },
  OTHER_INFO: {
    crunchbaseUrl: '',
    angellistUrl: '',
    linkedinUrl: '',
    twitterUrl: '',
    teamSize: undefined,
    employees: [],
    legalEntityName: '',
    legalStructure: undefined,
    legalCountry: undefined,
    legalState: undefined,
    einTaxId: '',
    bankName: '',
    bankAccountNumber: '',
    routingNumber: '',
    industries: [],
  },
  SEND_INVITATIONS: {
    sendInvitation: false,
    users: [],
    removedUsers: [],
    message: intl.formatMessage({
      id: 'investorManagement.defaultInvitationMessage',
    }),
  },
})

export enum AssociationType {
  VEHICLE = 'vehicle',
  INVESTOR = 'investor',
}
