import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

import Toast from 'components/Toast'
import UpdateService from 'api/UpdateService'
import dayjs from 'dayjs'
import EmailService from 'api/EmailService'
import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { QUERIES } from 'utils/queries/emailUpdates'
import useGroupCompany from './useGroupCompany'

const useCreateEmail = (companyId, redirectAfterCreation = '') => {
  const history = useHistory()
  const intl = useIntl()
  const companyInfo = useGroupCompany()
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()
  return {
    loading,
    onCreateEmail: async () => {
      try {
        setLoading(true)
        const subject = `${companyInfo.name} ${dayjs().format('MMMM')} Update`

        const {
          data: { result: emailId, entities },
        } = await UpdateService.createIUEReport(companyId, {
          report: { subject },
        })
        const loginUpdateId = Object.keys(entities.loggingUpdates)[0]
        const logoUrl =
          companyInfo.primaryLogo?.largeLogo?.url || companyInfo.logoUrl

        await EmailService.createDefaultHeader(emailId, subject, logoUrl)

        history.push(`/emails/${loginUpdateId}/draft?step=Build`, {
          redirectTo: redirectAfterCreation,
        })
        queryClient.refetchQueries([QUERIES.EMAIL_UPDATES])
      } catch (error) {
        if (error?.message?.indexOf('not allowed') >= 0) {
          Toast.display(intl.messages['general.accountNotVerified'], 'error')
        } else {
          Toast.display(intl.messages['updates.errorIUECreate'], 'error')
        }
      } finally {
        setLoading(false)
      }
    },
  }
}

export default useCreateEmail
