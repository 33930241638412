/* eslint-disable react/destructuring-assignment */
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import type { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'

import { DropdownOptionButton } from 'ui/Dropdown/Dropdown.tsstyles'
import CWLoader from 'components/CWLoader'
import { useIntl } from 'react-intl'
import { getGoogleApiKey } from 'utils/functions/env'
import { randomId } from 'utils/functions/number'
import {
  AddLocationIcon,
  controlStyles,
  GooglePlacesAutocompleteWrapper,
  indicatorSeparatorStyles,
  inputStyles,
  loadingIndicatorStyles,
  LocationDropdownOptionsList,
  menuStyles,
  placeholderStyles,
} from './LocationInput.styles'

export interface Location {
  place_id: string
  description: string
}

export interface LocationOption {
  label: string
  value: Location
}

interface LocationInputProps {
  placeholder: string
  value?: LocationOption
  onChange: (selectedOption: LocationOption) => void
  loadingMessage?: string
  disabled?: boolean
  isClearable?: boolean

  /**
   * Restricts predictions by location types.
   * Supported location types: https://developers.google.com/maps/documentation/places/web-service/supported_types
   */
  types?: string[]

  /**
   * Restricts predictions to the specified country (ISO 3166-1 Alpha-2 country code, case insensitive).
   * For example, 'us', 'uy', or 'ar'. You can provide a single one, or an array of up to five country code strings.
   * https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#ComponentRestrictions
   */
  countries?: string[]

  caretIcon?: [IconPrefix, IconName]
  iconFontSize?: string
  hideDropdownIndicator?: boolean
  styles?: CSSProperties
}

const LocationInput: React.FC<LocationInputProps> = ({
  value = null,
  onChange,
  placeholder,
  loadingMessage,
  types,
  countries,
  disabled,
  isClearable,
  caretIcon,
  iconFontSize,
  hideDropdownIndicator,
  styles,
}) => {
  const intl = useIntl()
  const firstChange = useRef(true)
  const [randomKey, setRandomKey] = useState(randomId())

  useEffect(() => {
    const input = document.getElementById(
      'location-input-id'
    ) as HTMLInputElement

    // Hack to prevent browser autocomplete
    input?.addEventListener('change', () => {
      if (firstChange.current) {
        firstChange.current = false
        input.value = ''
        setRandomKey(randomId())
      }
    })
  }, [])

  return (
    <GooglePlacesAutocompleteWrapper disabled={disabled}>
      <GooglePlacesAutocomplete
        key={randomKey}
        apiKey={getGoogleApiKey()}
        apiOptions={{ language: 'en' }}
        autocompletionRequest={{
          types,
          componentRestrictions: {
            country: countries?.map((country) => country.toLowerCase()) ?? [],
          },
        }}
        selectProps={{
          inputId: 'location-input-id',
          inputProps: {
            autocomplete: 'none',
            autocorrect: 'none',
            spellcheck: 'none',
            type: 'text',
          },

          isClearable,
          disabled,
          value,
          placeholder,
          onChange,
          styles: {
            menu: menuStyles,
            input: inputStyles,
            control: (provided) => controlStyles(provided, styles),
            indicatorSeparator: indicatorSeparatorStyles,
            loadingIndicator: loadingIndicatorStyles,
            placeholder: placeholderStyles,
          },
          components: {
            DropdownIndicator: (props) => {
              if (hideDropdownIndicator || (isClearable && props.hasValue)) {
                return null
              }

              return (
                <div {...props}>
                  <AddLocationIcon
                    icon={caretIcon || ['far', 'plus']}
                    iconFontSize={iconFontSize}
                  />
                </div>
              )
            },

            ClearIndicator: (props) => (
              <button type="button" {...props} onClick={props.clearValue}>
                <AddLocationIcon icon={['far', 'times']} />
              </button>
            ),

            Option: (props) => (
              <div {...props}>
                <DropdownOptionButton
                  selected={props.isFocused}
                  onClick={() => {
                    props.selectOption(props.data)
                  }}
                >
                  {props.label}
                </DropdownOptionButton>
              </div>
            ),

            MenuList: ({ children, ...props }) => {
              return (
                <LocationDropdownOptionsList {...props}>
                  {children}
                </LocationDropdownOptionsList>
              )
            },

            LoadingMessage: (props) => (
              <div {...props}>
                <div>
                  <CWLoader
                    logoSize="3rem"
                    text={
                      loadingMessage ||
                      intl.formatMessage({ id: 'general.loading' })
                    }
                  />
                </div>
              </div>
            ),

            NoOptionsMessage: () => null,
          },
        }}
      />
    </GooglePlacesAutocompleteWrapper>
  )
}

export default LocationInput
