import {
  setCurrentCompany as setCurrentCompanyAction,
  setCurrentCompanyLoading,
} from 'actions/companies'
import { setCurrentPortfolio } from 'actions/portfolios'
import CompanyService from 'api/CompanyService'
import { useAppDispatch } from '../reduxToolkit'

const useCompany = () => {
  const dispatch = useAppDispatch()

  const setCurrentCompany = async (data) => {
    dispatch(setCurrentCompanyLoading(true))
    const { railsId } = data.subject
    const company = await CompanyService.fetchCompany(railsId)
    dispatch(setCurrentCompanyAction(company))
    dispatch(setCurrentPortfolio({}))
    dispatch(setCurrentCompanyLoading(false))
  }

  return { setCurrentCompany }
}

export default useCompany
