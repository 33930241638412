import {
  BasicSubjectFragment,
  FullSubjectFragment,
} from 'types/graphql-schemas/graphql'
import { getAttribute } from 'utils/gql/helpers/subjects'

export const getSubjectFinancials = (
  subject: BasicSubjectFragment | FullSubjectFragment
) => {
  const taxId = getAttribute<string>(subject, 'taxId')?.value
  const socialSecurityNumber = getAttribute<string>(
    subject,
    'socialSecurityNumber'
  )?.value
  const bankName = getAttribute<string>(subject, 'bankName')?.value
  const bankAccount = getAttribute<string>(subject, 'bankAccountNumber')?.value
  const routingNumber = getAttribute<string>(
    subject,
    'bankRoutingNumber'
  )?.value

  return {
    taxId,
    socialSecurityNumber,
    bankName,
    bankAccount,
    routingNumber,
  }
}

const useSubjectFinancials = (
  subjectEntity: BasicSubjectFragment | FullSubjectFragment
) => {
  return getSubjectFinancials(subjectEntity)
}

export default useSubjectFinancials
