/* eslint-disable consistent-return, react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import PropTypes from 'prop-types'

import Toast from 'components/Toast'
import { isActingAsClient } from 'selectors/auth'
import {
  DEFAULT_FILTER,
  PAGE_SIZE,
} from 'containers/HoldingIndex/useCompanyIndex'
import PortfolioService, {
  OrderByProps,
  PortfoliosFilters,
} from 'api/PortfolioService'
import { holdingsKeys } from 'utils/queries/holdings'
import HoldingsService from 'api/HoldingsService'
import { SortDirection } from "types/graphql-schemas/graphql"

const DEFAULT_PORTFOLIO_FILTER: PortfoliosFilters = {
  page: 1,
  orderBy: OrderByProps.NAME,
  direction: SortDirection.Asc,
}

const PrefetchQueries = ({ queryClient, children }) => {
  const intl = useIntl()
  const isClient = useAppSelector(isActingAsClient)
  const urlMatchesCompanyIndex = useRouteMatch('/companies')
  const urlMatchesSignup = useRouteMatch('/signup')

  const prefetchCompanies = React.useCallback(async () => {
    if (!urlMatchesCompanyIndex) {
      queryClient.prefetchInfiniteQuery(
        holdingsKeys.portfolioHoldings(DEFAULT_FILTER),
        async () => {
          try {
            const { holdings } = await HoldingsService.getPortfolioHoldings({
              page: 1,
              filters: { ...DEFAULT_FILTER, onlyPortfolio: true },
              companiesPerPage: PAGE_SIZE,
            })

            return { holdings, currentPage: 1 }
          } catch (error) {
            Toast.display(
              intl.messages['companies.errorFetchingPortfolioCompanies']
            )
          }
        }
      )
    }

    queryClient.prefetchInfiniteQuery(
      holdingsKeys.all(DEFAULT_FILTER),
      async () => {
        const { holdings } = await HoldingsService.getHoldings({
          page: 1,
          filters: DEFAULT_FILTER,
          companiesPerPage: PAGE_SIZE,
        })

        return { holdings, currentPage: 1 }
      }
    )
  }, [])

  const prefetchPortfolios = React.useCallback(() => {
    queryClient.prefetchInfiniteQuery(['portfolio_list'], async () => {
      const portfolios = await PortfolioService.fetchPortfolios(
        DEFAULT_PORTFOLIO_FILTER
      )
      return { portfolios, page: 1 }
    })
  }, [])

  useEffect(() => {
    if (isClient && !urlMatchesSignup) {
      prefetchCompanies()
      prefetchPortfolios()
    }
  }, [prefetchCompanies, prefetchPortfolios, isClient])

  return <div>{children}</div>
}

PrefetchQueries.propTypes = {
  queryClient: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}

export default PrefetchQueries
