import styled from 'styled-components'

export const TextArea = styled.textarea`
  border: 0.1rem solid transparent;
  background: rgba(120, 126, 140, 0.1);
  width: 100%;
  padding: 1rem;
  border-radius: 0.8rem;
  font-size: 1.2rem;
`
