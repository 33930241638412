import { UpdateTypes } from 'utils/constants/updates'
import { useFundPortfolioInvestmentVehicleQuery } from 'utils/hooks/investorManagement/useFundPortfolioInvestmentVehicleQuery'
import { useInvestmentVehicleQuery } from 'utils/hooks/investorManagement/useInvestmentVehiclesQuery'
import { useSubjectQuery } from 'utils/hooks/queries/subjects/useSubjectQuery'
import { useSetCurrentInvestmentVehicle } from 'utils/hooks/useCurrentInvestorManagementEntities'
import { UpdateUrlSuffix } from 'utils/hooks/useShowUpdateRoutes/types'
import { useShowUpdateRoute } from 'utils/hooks/useShowUpdateRoutes/useShowUpdateRoute'
import { TransactionItem } from 'utils/types/update'

const useShowInvestmentVehicle = (
  vehicleRailsId: string,
  investorRailsId?: string
) => {
  const showUpdateRoute = useShowUpdateRoute()
  const { data, isLoading } = useSubjectQuery(vehicleRailsId)

  const { data: investmentVehicle } = useInvestmentVehicleQuery(vehicleRailsId)

  useSetCurrentInvestmentVehicle(investmentVehicle)

  const { data: fundsPortfolioInvestor, isLoading: isLoadingFunds } =
    useFundPortfolioInvestmentVehicleQuery(vehicleRailsId)

  const onShowTransaction = (transaction: TransactionItem) => {
    showUpdateRoute.fromInvestmentVehicle({
      updateId: transaction.loggingUpdateId,
      updateType: UpdateTypes.TRANSACTION,
      investmentVehicle: investmentVehicle!,
      investorId: investorRailsId,
    })
  }

  const onEditTransaction = (transaction: TransactionItem) => {
    showUpdateRoute.fromInvestmentVehicle({
      updateId: transaction.loggingUpdateId,
      updateType: UpdateTypes.TRANSACTION,
      investmentVehicle: investmentVehicle!,
      suffix: UpdateUrlSuffix.EDIT,
      investorId: investorRailsId,
    })
  }

  return {
    investmentVehicleSubject: data?.subject,
    investmentVehicle,
    fundsPortfolioInvestor: fundsPortfolioInvestor || [],
    isLoading,
    isLoadingFunds,
    onShowTransaction,
    onEditTransaction,
  }
}

export default useShowInvestmentVehicle
