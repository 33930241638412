import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Avatar from 'components/Avatar'
import CompanySocials from 'components/CompanySocials'
import SubjectStatus from 'components/SubjectStatus'
import Tooltip from 'components/Tooltip'
import { useIntl } from 'react-intl'
import { useSubjectContext } from 'utils/context/subjects/SubjectContext'
import useSubjectParent from 'utils/context/subjects/hooks/useSubjectParent'
import useSubjectSocials from 'utils/context/subjects/hooks/useSubjectSocials'
import { getInitials } from 'utils/functions/user'
import { useHoldingSubjectData } from 'utils/hooks/subjects/useHoldingSubjectData'
import {
  BasicInformationWrapper,
  CompanyName,
  ImageWrapper,
  ParentSubjectName,
} from './HeaderBasicInformation.styles'

const HeaderBasicInformation = () => {
  const { subject, name, logo } = useSubjectContext()
  const { isVerified, isCompany } = useHoldingSubjectData(subject)
  const { parentSubject, parentSubjectUrl } = useSubjectParent()

  const socials = useSubjectSocials(subject)

  const intl = useIntl()
  return (
    <BasicInformationWrapper>
      <ImageWrapper>
        <Avatar
          image={logo}
          initials={getInitials(name)}
          avatarStyle="avatarCircleLarge"
        />
      </ImageWrapper>

      <CompanyName isVerified={isVerified}>
        {name}
        {isVerified && (
          <Tooltip
            place="right"
            id="update-title-tooltip"
            text={
              <>
                <p>
                  {intl.formatMessage({
                    id: 'companyProfile.verifiedProfile1',
                  })}
                </p>
                <p>
                  {intl.formatMessage({
                    id: 'companyProfile.verifiedProfile2',
                  })}
                </p>
              </>
            }
            delayShow={500}
            widthAuto
          >
            <FontAwesomeIcon icon={['fas', 'check-circle']} />
          </Tooltip>
        )}
      </CompanyName>

      {parentSubject && (
        <ParentSubjectName to={parentSubjectUrl!}>
          {parentSubject.name}
        </ParentSubjectName>
      )}

      <CompanySocials centerLinks withTitle={false} companyInfo={socials} />

      {isCompany && <SubjectStatus subject={subject} />}
    </BasicInformationWrapper>
  )
}

export default HeaderBasicInformation
