import { HoldingsFilters, HoldingTypeFilter } from 'api/HoldingsService'
import { getCurrentGroupId } from 'selectors/auth'

import { SubjectFilter } from 'utils/hooks/queries/subjects/useSubjectsQuery'
import { SubjectType } from 'utils/types/subjects'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useSubjectsInfiniteQuery } from 'utils/hooks/queries/subjects/useSubjectsInfiniteQuery'

interface Props {
  textSearch?: string
  currentFilters: HoldingsFilters
  tagsNameValue?: string[]
  companiesPerPage: number
}

const getSubjectTypesFromFilters = (types: HoldingTypeFilter[]) => {
  const subjectTypes = types.reduce<SubjectType[]>((res, holdingType) => {
    switch (holdingType) {
      case HoldingTypeFilter.COMPANY:
        res.push(SubjectType.COMPANY)
        break
      case HoldingTypeFilter.FUND:
        res.push(SubjectType.FUND)
        break
      case HoldingTypeFilter.DEAL:
        res.push(SubjectType.DEAL)
        break
      default:
    }

    return res
  }, [])

  return subjectTypes.length
    ? subjectTypes
    : [SubjectType.COMPANY, SubjectType.FUND, SubjectType.DEAL]
}

export const getHoldingsFilters = (
  filters: HoldingsFilters & { textSearch?: string; groupId: string }
): SubjectFilter => {
  return {
    name: filters.textSearch,
    type: getSubjectTypesFromFilters(filters.typeIn ?? []),
    'attributesMap.isFundCompany.value': filters.typeIn?.includes(
      HoldingTypeFilter.ORGANIZATION
    )
      ? true
      : undefined,
    'attributesMap.industries.value': filters.industryId?.length
      ? {
          $elemMatch: { id: { $in: filters.industryId } },
        }
      : undefined,

    'attributesMap.locations.value': filters.locationPlaceAddress
      ? {
          $elemMatch: {
            formattedAddress: {
              $regex: filters.locationPlaceAddress,
              $options: 'i',
            },
          },
        }
      : undefined,
    'attributesMap.legalStructure.value.id': filters.legalStructureId
      ? { $in: filters.legalStructureId }
      : undefined,
    'attributesMap.private.value': filters.onlyPrivateCompany
      ? true
      : undefined,

    'tags.tagId': filters.tags?.length
      ? { $in: filters.tags.map((tag) => tag.id) }
      : undefined,
  }
}

export const useHoldingsQuery = ({
  textSearch = '',
  currentFilters,
  companiesPerPage,
}: Props) => {
  const groupId = useAppSelector(getCurrentGroupId)
  const subjectFilters: SubjectFilter = getHoldingsFilters({
    ...currentFilters,
    textSearch,
    groupId,
  })

  const {
    data: holdings,
    isLoading,
    isFetchingNextPage,
    error,
    endOfPageRef: infiniteScrollRef,
  } = useSubjectsInfiniteQuery({
    filters: subjectFilters,
    sorting: {
      orderBy: currentFilters.orderBy,
      direction: currentFilters.direction,
    },
    pageSize: companiesPerPage,
  })

  return {
    isLoading,
    isFetchingNextPage,
    holdings,
    error,
    endOfPageRef: infiniteScrollRef,
  }
}
