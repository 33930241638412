import { SingleFundPortfolioSelector } from 'components/TransactionUpdateContent/UpdateFormElements/SingleFundPortfolioSelector'
import InvestorSelector from 'components/Selectors/InvestorSelector'
import { useField, useFormikContext } from 'components/Form/hooks'
import { AccessType } from 'utils/types/updateForm'
import { GridCol, GridRow } from './MultipleInvestorFundSelector.styles'

const MultipleInvestorFundSelector = () => {
  const [{ onChange, ...field }, helpers] = useField('investor')
  const [_, __, investorPermissionsHelper] = useField('permissions.investor')

  const formik = useFormikContext()

  const onSelectOptionHandler = (option) => {
    helpers.value = option
    investorPermissionsHelper.setValue(AccessType.CAN_VIEW)
    setTimeout(() => {
      formik.handleBlur({ target: { name: field.name } })
    })
  }

  return (
    <GridRow>
      <GridCol>
        <InvestorSelector
          isDisabled={false}
          onSelectInvestorCallback={onSelectOptionHandler}
        />
      </GridCol>
      <GridCol>
        <SingleFundPortfolioSelector portfolioKey="portfolio" />
      </GridCol>
    </GridRow>
  )
}

export default MultipleInvestorFundSelector
