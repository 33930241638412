/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { BasicSubjectFragment } from 'types/graphql-schemas/graphql'
import { Nullable } from 'utils/types/common'
import { InvestmentVehicle, Investor } from 'utils/types/investors'

interface InvestorManagementState {
  suggestCreateInvestmentVehicleModalEnabled: boolean
  showSuggestCreateInvestmentVehicleModal: boolean
  suggestedInvestor?: BasicSubjectFragment
  currentInvestor: Nullable<Investor>
  currentInvestmentVehicle: Nullable<InvestmentVehicle>
  isFundSelectedByDefault: boolean // this property is used to initialize the Individual/Fund toggle
}

const initialState: InvestorManagementState = {
  suggestCreateInvestmentVehicleModalEnabled: true,
  showSuggestCreateInvestmentVehicleModal: false,
  currentInvestor: null,
  currentInvestmentVehicle: null,
  isFundSelectedByDefault: false,
}

export const investorManagementSlice = createSlice({
  name: 'investorManagement',
  initialState,
  reducers: {
    setShowSuggestCreateInvestmentVehicleModal: (
      state,
      action: PayloadAction<{
        show: boolean
        investor?: BasicSubjectFragment
      }>
    ) => {
      state.showSuggestCreateInvestmentVehicleModal = action.payload.show
      state.suggestedInvestor = action.payload.investor
    },

    disableSuggestCreateInvestmentVehicleModal: (state) => {
      state.suggestCreateInvestmentVehicleModalEnabled = false
    },

    setCurrentInvestor: (state, action: PayloadAction<Nullable<Investor>>) => {
      state.currentInvestor = action.payload
    },

    setCurrentInvestmentVehicle: (
      state,
      action: PayloadAction<Nullable<InvestmentVehicle>>
    ) => {
      state.currentInvestmentVehicle = action.payload
    },

    setIsFundSelectedByDefault: (state, action: PayloadAction<boolean>) => {
      state.isFundSelectedByDefault = action.payload
    },
  },
})

export const {
  setShowSuggestCreateInvestmentVehicleModal,
  disableSuggestCreateInvestmentVehicleModal,
  setCurrentInvestmentVehicle,
  setCurrentInvestor,
  setIsFundSelectedByDefault,
} = investorManagementSlice.actions

export const getShowSuggestCreateInvestmentVehicleModal = (state: RootState) =>
  state.investorManagement.showSuggestCreateInvestmentVehicleModal

export const getSuggestedInvestor = (state: RootState) =>
  state.investorManagement.suggestedInvestor

export const getIsShowSuggestCreateInvestmentVehicleModalEnabled = (
  state: RootState
) => state.investorManagement.suggestCreateInvestmentVehicleModalEnabled

export const getCurrentInvestor = (state: RootState) =>
  state.investorManagement.currentInvestor

export const getCurrentInvestmentVehicle = (state: RootState) =>
  state.investorManagement.currentInvestmentVehicle

export const getIsFundSelectedByDefault = (state: RootState) =>
  state.investorManagement.isFundSelectedByDefault

export default investorManagementSlice.reducer
