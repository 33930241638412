import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getAccessToken } from 'features/authSlice'
import { DevEnvironment, getDevEnvironment } from 'features/preferenceSlice'
import { getCurrentGroupId } from 'selectors/auth'
import { useAppSelector } from './hooks/reduxToolkit'
import { PROD_ENV, STAGING_ENV } from './functions/env'

const getSubjectsApiUrl = (devEnv?: DevEnvironment) => {
  if (PROD_ENV || STAGING_ENV) {
    return `${process.env.REACT_APP_SUBJECTS_API_URL}/graphql`
  }

  if (devEnv === DevEnvironment.RED) {
    return `${process.env.REACT_APP_DEVELOP_SUBJECTS_API_URL}/graphql`
  }

  if (
    devEnv === DevEnvironment.TESTING ||
    devEnv === DevEnvironment.TESTING_1
  ) {
    return `${process.env.REACT_APP_TESTING_SUBJECTS_API_URL}/graphql`
  }

  return `${process.env.REACT_APP_DEVELOP_SUBJECTS_API_URL}/graphql`
}
const ApolloClientProvider = ({ children }: PropsWithChildren) => {
  const devEnvironment = useAppSelector(getDevEnvironment)

  const uri = getSubjectsApiUrl(devEnvironment)
  const httpLink = useRef(
    createUploadLink({
      uri,
      headers: {
        'Apollo-Require-Preflight': 'true',
      },
    })
  )
  const accessToken = useAppSelector(getAccessToken)
  const groupId = useAppSelector(getCurrentGroupId)

  const [gqlClient, setGqlClient] = useState<ApolloClient<any> | null>(null)

  useEffect(() => {
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          'x-group-id': groupId,
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
      }
    })

    setGqlClient(
      new ApolloClient({
        link: authLink.concat(httpLink.current as unknown as ApolloLink),
        cache: new InMemoryCache(),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'no-cache',
          },
          query: {
            // Strategy: We rely on the React Query cache to handle data fetching and updates.
            // However, if the Apollo cache is enabled, React Query's invalidation mechanism may not function correctly.
            // Problem: After mutating the data, React Query triggers a refetch.
            // But when Apollo is used as the data source, it fetches from its own cache if enabled.
            // This prevents React Query from refetching fresh data, since Apollo believes the data is already up-to-date.
            fetchPolicy: 'no-cache',
          },
        },
        //   cache: new InMemoryCache({
        //     typePolicies: {
        //       Query: {
        //         fields: {
        //           subjects: {
        //             keyArgs: ['query', 'sortBy', 'sortDirection'],
        //             merge(existing = [], incoming) {
        //               return [...existing, ...incoming]
        //             },
        //           },
        //         },
        //       },
        //     },
        //   }),
      })
    )
  }, [groupId, accessToken])

  if (!gqlClient) return null

  return <ApolloProvider client={gqlClient}>{children}</ApolloProvider>
}

export default ApolloClientProvider
