import { useCallback, useEffect, useRef } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useIntl } from 'react-intl'

import TagService from 'api/TagService'

import { getCurrentGroupName } from 'selectors/auth'
import { BaseTag } from 'utils/types/update'
import IconButton from 'ui/IconButton'
import Dropdown from 'ui/Dropdown'
import { TagDto, TagFragment } from 'types/graphql-schemas/graphql'

interface Props {
  onSelectTag: (tag: TagDto) => void
  onAddNewTag: (tagName: string) => void
  validationWhenAddingTag: (tagName: string) => boolean
  tagInputRef?: any
  tagsList: TagFragment[]
}

const TagsInput = ({
  onSelectTag: onSelectTagProp,
  onAddNewTag: onAddNewTagProp,
  validationWhenAddingTag,
  tagInputRef,
  tagsList,
}: Props) => {
  const groupName = useAppSelector(getCurrentGroupName)
  const intl = useIntl()
  const idNotIn = useRef(tagsList?.map((tag) => tag.tagId))

  useEffect(() => {
    idNotIn.current = tagsList?.map((tag) => tag.tagId)
  }, [tagsList])

  const onSelectTag = (selectedTag: BaseTag) => {
    document.getElementById('tags-add')?.blur()

    const doesTagExistInList = tagsList.find(
      (tag) => tag.tagName?.trim() === selectedTag.name?.trim()
    )

    if (!doesTagExistInList) {
      if (!validationWhenAddingTag) {
        onSelectTagProp({
          tagId: selectedTag.id,
          tagName: selectedTag.name,
        })
      } else {
        const isValidTag = validationWhenAddingTag(
          selectedTag.name.replace(/\s/g, '')
        )

        if (isValidTag) {
          onSelectTagProp({
            tagId: selectedTag.id,
            tagName: selectedTag.name,
          })
        }
      }
    }
  }

  const onAddNewTag = (tagName: string) => {
    document.getElementById('tags-add')?.blur()

    const doesTagExistInList = tagsList.find(
      (tag) => tag.tagName?.trim() === tagName?.trim()
    )

    if (!doesTagExistInList) {
      if (!validationWhenAddingTag) {
        onAddNewTagProp(tagName)
      } else {
        const isValidTag = validationWhenAddingTag(tagName.replace(/\s/g, ''))

        if (isValidTag) {
          onAddNewTagProp(tagName)
        }
      }
    }
  }

  const searchTags = useCallback(
    async (value) => {
      const {
        data: { result, entities },
      } = await TagService.getTags(value, idNotIn.current)

      return [
        {
          id: 'group-options',
          icon: ['far', 'tag'],
          group: `${groupName} ${intl.formatMessage({
            id: 'tags.tagsTitle',
          })}`,
          subOptions: result.map((tagId) => {
            return entities?.tags?.[tagId]
          }),
        },
      ]
    },
    [groupName, intl, idNotIn]
  )

  return (
    <Dropdown
      ref={tagInputRef}
      id="tags-add"
      name="companies"
      async
      loadOptions={searchTags}
      getOption={(entity) => ({ label: entity.name, id: entity.id })}
      onSelectOption={(_, __, value) => {
        onSelectTag(value)
      }}
      placeholder={intl.formatMessage({ id: 'tags.addTagPlaceholder' })}
      type="select"
      clearOnSelect
      addOptionEnabled
      withGroupOptions
      hideGroupLabelIfEmpty
      resetSearchOnFocus
      onAddOption={onAddNewTag}
      capitalizeOptions={false}
      optionTitlePadding="0rem 0.8rem 1.1rem 0.8rem"
      attachToDocument
      showLoadingIndicator
      dropdownListWidth="34rem"
      dropdownContentWidth="20rem"
      topOffset={5}
      alignLeft
      customButton={<IconButton icon="tag" color="white" buttonSize="3.6rem" />}
      maxLength={255}
      withCounter
      counterStyle={{ right: '-11.5rem' }}
      simulateIconSpaceForSearchInput
    />
  )
}

export default TagsInput
