import { Helmet } from 'components/Helmet'
import { useIntl } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import { isActingAsInvestorGroup } from 'selectors/auth'
import { isCurrentCompanyFundProfile } from 'selectors/company'

import CWLoader from 'components/CWLoader'
import Celeste from 'components/Celeste'
import { MetricsContextProvider } from 'containers/Metrics/MetricsContext'
import { UpdatesFiltersProvider } from 'containers/UpdatesView/UpdatesFiltersContext'
import { COMPANY_UPDATES_PREFIX } from 'routes/constant/companiesRoutes'
import { GROUP_PROFILE_ALL_UPDATES } from 'routes/constant/fundManagerRoutes'
import { SubjectProvider } from 'utils/context/subjects/SubjectContext'
import { getAttribute } from 'utils/gql/helpers/subjects'
import useCompany from 'utils/hooks/holdings/useCompany'
import { useSubjectQuery } from 'utils/hooks/queries/subjects/useSubjectQuery'
import { ProfileProvider } from './ProfileContext'
import HeaderContent from './components/HeaderContent/HeaderContent.v2'
import Layout from './components/Layout'
import CompanyMainContent from './components/MainContent/CompanyMainContent.v2'
import CompanyInformation from './components/SideInformation/CompanyInformation.v2'
import { ProfileType } from './types'

interface CompanySubjectProfileRouteWrapperProps {
  subjectId: string
  shouldRenderHelmet?: boolean
}

const CompanySubjectProfile = ({
  subjectId,
  shouldRenderHelmet = true,
}: CompanySubjectProfileRouteWrapperProps) => {
  const isFundProfileCompany = useAppSelector(isCurrentCompanyFundProfile)
  const { setCurrentCompany } = useCompany()

  const { data, isLoading } = useSubjectQuery(subjectId, {
    onCompleted: (companyData) => {
      setCurrentCompany(companyData)
    },
  })

  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const intl = useIntl()

  const subject = data?.subject

  if (!subject || isLoading) {
    return <CWLoader />
  }

  const isFundCompany = getAttribute<boolean>(subject, 'isFundCompany')?.value

  const holdingType = isFundCompany
    ? ProfileType.FUND_COMPANY
    : ProfileType.COMPANY

  return (
    <SubjectProvider subject={subject}>
      {shouldRenderHelmet && (
        <Helmet>
          <title>
            {intl.formatMessage(
              {
                id: 'metaTitles.profile',
              },
              { profileName: subject?.name }
            )}
          </title>
        </Helmet>
      )}
      <ProfileProvider>
        <UpdatesFiltersProvider>
          <MetricsContextProvider>
            <Layout>
              <Layout.Header type={holdingType}>
                <HeaderContent />
              </Layout.Header>
              <Layout.SideContent>
                <CompanyInformation />
              </Layout.SideContent>
              <Layout.MainContent>
                <CompanyMainContent />
              </Layout.MainContent>

              {!isInvestorGroup && (
                <Celeste
                  prefixUrl={
                    isFundProfileCompany
                      ? GROUP_PROFILE_ALL_UPDATES
                      : COMPANY_UPDATES_PREFIX.replace(':companyId', subjectId)
                  }
                />
              )}
            </Layout>
          </MetricsContextProvider>
        </UpdatesFiltersProvider>
      </ProfileProvider>
    </SubjectProvider>
  )
}

export default CompanySubjectProfile
