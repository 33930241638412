import { useCallback } from 'react'
import type { ArrayHelpers } from 'formik'

import { AddInvestorStepFormValues } from 'utils/types/investors'
import { useIntl } from 'react-intl'

import { useForm, useFormikContext } from 'components/Form/hooks'
import { EMAIL_REGEX } from 'utils/functions/regex'
import { InvestorTypes } from 'utils/constants/investorManagement'
import { isActingAsFundManager } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import { AddInvestorFormConfig } from './types'

export const useAddInvestorForm = (onAddEmail?: (newEmail: string) => void) => {
  const intl = useIntl()
  const isFundManager = useAppSelector(isActingAsFundManager)

  const { values, setFieldValue, setFieldError, ...formik } =
    useFormikContext<AddInvestorStepFormValues>()
  const {
    showInGrid,
    showPhoneAfterFoundedDate: showPhoneAfterFoundedDateConfig,
    marginBottom,
    descriptionHeight,
  } = useForm<AddInvestorFormConfig>()
  const isGridDisplay = showInGrid
  const showPhoneAfterFoundedDate =
    showPhoneAfterFoundedDateConfig &&
    values.type === InvestorTypes.ORGANIZATION

  const handleAddEmail = async (
    arrayHelpers: ArrayHelpers,
    newEmail: string
  ) => {
    let fieldError
    if (values.emails.includes(newEmail)) {
      fieldError = intl.formatMessage({
        id: 'investorManagement.duplicatedEmailError',
      })
    } else if (!EMAIL_REGEX.test(newEmail)) {
      fieldError = intl.formatMessage({
        id: 'investorManagement.emailFormatError',
      })
    } else {
      arrayHelpers.push(newEmail)
      onAddEmail?.(newEmail)
    }
    setFieldError('emails', fieldError)
  }

  const onSaveImage = (cropperUrl: string, __, image: File) => {
    if (image) {
      setFieldValue('logo.image', image)
      setFieldValue('logo.cropperUrl', cropperUrl)
      setFieldValue('logo.imageUrl', window.URL.createObjectURL(image))
      setFieldValue('logo.hasRemovedImage', false)
    }
  }

  const onDeleteImage = useCallback(() => {
    setFieldValue('logo.image', undefined)
    setFieldValue('logo.cropperUrl', '')
    setFieldValue('logo.imageUrl', undefined)
    setFieldValue('logo.hasRemovedImage', true)
  }, [setFieldValue])

  return {
    ...formik,
    values,
    setFieldValue,
    isGridDisplay,
    descriptionHeight,
    showPhoneAfterFoundedDate,
    marginBottom,
    isFundManager,
    handleAddEmail,
    onSaveImage,
    onDeleteImage,
  }
}
