import React, { useState, useRef, useEffect, useId } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { debounce } from 'lodash'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import theme from 'utils/theme'

import { ReactComponent as AddImageIcon } from 'assets/images/add-image.svg'
import Separator from 'ui/Separator'
import IconButton from 'ui/IconButton'
import Avatar from 'components/Avatar'
import Modal from 'components/Modal'
import Button from 'ui/Button'
import { base64ToFile } from 'utils/functions/files'
import Chip from 'components/Chip'

import {
  AvatarPlaceholder,
  ImageContainer,
  BackgroundImage,
  ImageBorder,
  ImageHelperContainer,
  ImageHelper,
  ImageInfo,
  FileUpload,
  ImageLabelWrapper,
  DeleteImageButtonContainer,
  DeleteImageOverlay,
  FooterContainer,
  CancelSaveWrapper,
  BrowseImageButton,
  Border,
  ImageInformation,
  ImageInfoContainer,
  LargeAvatar,
  CropperContainer,
  ButtonContainer,
  HelpTextContainer,
  StyledTrashIconButton,
  DeleteHoverBorder,
} from './ImagePicker.styles'

const ImagePicker = ({
  className,
  imageUrl: imageUrlProp,
  placeHolderImage,
  onSelectImage,
  helpText,
  disabled,
  helpHidden,
  showSelectImageButton,
  deleteImageEnabled,
  onDeleteImage,
  onSaveImage,
  setHasRemovedImage,
  initials,
  cropperUrl: cropperUrlProp,
  showRemoveImageButton,
  onHideModal,
  aspectRatio,
  initialAspectRatio,
  roundedViewBox,
  showImage,
  showImageChip,
  selectImageText,
  mainLogoImageFileName,
  gray,
  changeImageText,
  imageSize,
  compressionRate,
}) => {
  const randomId = useId()
  const [cropperUrl, setCropperUrl] = useState('')
  const [displayInitials, setDisplayInitials] = useState(false)
  const [fileName, setFileName] = useState('')
  const [croppedImage, setCroppedImage] = useState('')
  const [backgroundImage, setBackgroundImage] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const intl = useIntl()

  const upload = useRef(null)
  const cropperRef = useRef(null)

  const onCrop = debounce(() => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper
    setCroppedImage(
      cropper?.getCroppedCanvas()?.toDataURL('image/jpeg', compressionRate)
    )
  }, 500)

  useEffect(() => {
    if (cropperUrlProp) {
      setCropperUrl(cropperUrlProp)
    }
  }, [cropperUrlProp])

  useEffect(() => {
    const currFile = base64ToFile(croppedImage, fileName || 'croppedImage')
    onSelectImage(currFile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedImage])

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleChange = async ({ target: { files } }) => {
    // if (!imageUrlProp) {
    //   showModal()
    // }
    if (files?.[0]) {
      const reader = new FileReader()
      setFileName(files[0]?.name)
      setHasRemovedImage?.(false)

      reader.onloadend = () => {
        setCropperUrl(reader.result)
      }
      reader.readAsDataURL(files?.[0])
    }
  }

  const onSelectInitialImage = async ({ target: { files } }) => {
    if (files?.[0]) {
      const reader = new FileReader()
      setFileName(files[0]?.name)
      setHasRemovedImage?.(false)

      reader.onloadend = () => {
        setCropperUrl(reader.result)
        showModal()
      }
      reader.readAsDataURL(files?.[0])
    }
  }

  const isMissingImage = !cropperUrl || cropperUrl.indexOf('missing.png') >= 0
  useEffect(() => {
    setBackgroundImage(imageUrlProp)

    if (isMissingImage) {
      setDisplayInitials(true)
    } else {
      setDisplayInitials(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrlProp, isMissingImage])

  const deleteImage = (event) => {
    event.stopPropagation()
    onDeleteImage()
    setBackgroundImage('')
    setCropperUrl('')
    setFileName('')
  }

  const onHide = () => {
    setIsModalOpen(false)
    onHideModal()
  }

  const saveImage = () => {
    const imageFile = base64ToFile(croppedImage, fileName || 'croppedImage')
    onSaveImage(cropperUrl, croppedImage, imageFile, fileName)
    setIsModalOpen(false)
  }

  const displayImage = () => {
    if (!showImage) {
      return null
    }

    if (showSelectImageButton) {
      if (displayInitials) {
        return (
          <ImageBorder imageSize={imageSize}>
            <Avatar avatarStyle="avatarCircleSM" initials={initials} />
          </ImageBorder>
        )
      }
      return (
        <ImageBorder imageSize={imageSize}>
          <BackgroundImage
            imageSize={imageSize}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          />
        </ImageBorder>
      )
    }
    return (
      <BackgroundImage
        gray={gray}
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
        imageSize={imageSize}
      >
        <ImageBorder imageSize={imageSize}>
          <ImageLabelWrapper onClick={showModal}>
            {!backgroundImage && !placeHolderImage && <AddImageIcon />}
            {!backgroundImage && placeHolderImage && (
              <AvatarPlaceholder backgroundSrc={placeHolderImage} />
            )}
          </ImageLabelWrapper>
        </ImageBorder>
        {deleteImageEnabled && !!backgroundImage && (
          <>
            <DeleteImageButtonContainer>
              <StyledTrashIconButton
                icon="trash-alt"
                color={theme.colors.red}
                onClick={deleteImage}
                danger
                style={{ zIndex: 2 }}
                border={false}
              />
            </DeleteImageButtonContainer>
            <DeleteHoverBorder />
            {deleteImageEnabled && <DeleteImageOverlay />}
          </>
        )}
      </BackgroundImage>
    )
  }

  const closeModal = () => setIsModalOpen(false)

  const onCancel = () => {
    closeModal()
    setFileName('')
    setTimeout(() => {
      setCropperUrl(backgroundImage)
    }, 0)
  }

  return (
    <ImageContainer
      className={className}
      roundedViewBox={roundedViewBox}
      showImage={showImage}
      imageSize={imageSize}
    >
      {displayImage()}

      <ImageInfo showImage={showImage}>
        {!helpHidden && !helpText.length && (
          <ImageHelperContainer>
            <ImageHelper>
              <FormattedMessage id="groupSettings.imageHelpDimensions" />
            </ImageHelper>
            <ImageHelper>
              <FormattedMessage id="groupSettings.imageHelpFormat" />
            </ImageHelper>
          </ImageHelperContainer>
        )}

        {!!helpText.length && (
          <HelpTextContainer>
            {helpText.map((text) => (
              <ImageHelper>{text}</ImageHelper>
            ))}
          </HelpTextContainer>
        )}

        <Separator space="1.5rem" />

        {showSelectImageButton && !disabled && (
          <ButtonContainer>
            {!imageUrlProp ? (
              <BrowseImageButton
                htmlFor={`initial-file-upload_${randomId}`}
                secondary
              >
                <FormattedMessage id="imagePicker.browseImage" />
              </BrowseImageButton>
            ) : (
              <Button onClick={showModal} secondary>
                {selectImageText || (
                  <FormattedMessage id="groupSettings.changeImage" />
                )}
              </Button>
            )}
            {showImageChip && imageUrlProp && (
              <Chip
                image={imageUrlProp}
                text={fileName || mainLogoImageFileName}
                handleDelete={deleteImage}
                backgroundImageMode
                maxWidth="20rem"
              />
            )}
          </ButtonContainer>
        )}
      </ImageInfo>

      <FileUpload
        key={backgroundImage}
        id={`initial-file-upload_${randomId}`}
        accept="image/jpeg, image/png"
        type="file"
        onChange={onSelectInitialImage}
      />

      <Modal show={isModalOpen} onHide={onCancel} hideOnOutsideClick={false}>
        <Modal.Header>
          <Modal.Title>
            <div>
              <h2>{intl.messages['imagePicker.changeImage']}</h2>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!isMissingImage && cropperUrl !== '' && (
            <CropperContainer>
              <Cropper
                src={cropperUrl}
                initialAspectRatio={initialAspectRatio}
                aspectRatio={aspectRatio}
                guides={false}
                crop={onCrop}
                ref={cropperRef}
                dragMode="move"
                viewMode="1"
                minCropBoxHeight={50}
                minCropBoxWidth={50}
                style={{ maxHeight: '60vh', width: 'auto' }}
                checkOrientation={false}
              />
            </CropperContainer>
          )}

          <ImageInfoContainer>
            <BrowseImageButton htmlFor={`file-upload_${randomId}`} secondary>
              <FormattedMessage id="imagePicker.browseImage" />
            </BrowseImageButton>
            <ImageInformation>{fileName}</ImageInformation>
          </ImageInfoContainer>

          <Border />

          <FileUpload
            key={backgroundImage}
            id={`file-upload_${randomId}`}
            accept="image/jpeg, image/png"
            type="file"
            ref={upload}
            onChange={handleChange}
          />
        </Modal.Body>

        <Modal.Footer>
          <FooterContainer showRemoveImageButton={showRemoveImageButton}>
            <Button
              onClick={() => {
                setFileName('')
                setCropperUrl('missing.png')
                setHasRemovedImage?.(true)
              }}
              danger
            >
              <FormattedMessage id="imagePicker.removeImage" />
            </Button>

            <CancelSaveWrapper>
              <Button onClick={onCancel} secondary>
                <FormattedMessage id="imagePicker.cancel" />
              </Button>
              <Button onClick={saveImage} primary>
                <FormattedMessage id="imagePicker.save" />
              </Button>
            </CancelSaveWrapper>
          </FooterContainer>
        </Modal.Footer>
      </Modal>
    </ImageContainer>
  )
}

ImagePicker.propTypes = {
  onSelectImage: PropTypes.func,
  onDeleteImage: PropTypes.func,
  imageUrl: PropTypes.string,
  placeHolderImage: PropTypes.string,
  selectImageText: PropTypes.string,
  className: PropTypes.string,
  helpText: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  deleteImageEnabled: PropTypes.bool,
  helpHidden: PropTypes.bool,
  showSelectImageButton: PropTypes.bool,
  onSaveImage: PropTypes.func.isRequired,
  initials: PropTypes.string,
  setHasRemovedImage: PropTypes.func,
  showRemoveImageButton: PropTypes.bool,
  cropperUrl: PropTypes.string,
  onHideModal: PropTypes.func,
  initialAspectRatio: PropTypes.number,
  aspectRatio: PropTypes.number,
  roundedViewBox: PropTypes.bool,
  showImage: PropTypes.bool,
  showImageChip: PropTypes.bool,
  gray: PropTypes.bool,
  mainLogoImageFileName: PropTypes.string,
  changeImageText: PropTypes.string,
  imageSize: PropTypes.string,
  compressionRate: PropTypes.number,
}

ImagePicker.defaultProps = {
  imageUrl: '',
  placeHolderImage: '',
  selectImageText: '',
  className: '',
  helpText: [],
  initials: '',
  disabled: false,
  helpHidden: false,
  deleteImageEnabled: false,
  showSelectImageButton: true,
  onDeleteImage: () => {},
  showRemoveImageButton: true,
  cropperUrl: '',
  onHideModal: () => {},
  setHasRemovedImage: () => {},
  onSelectImage: () => {},
  initialAspectRatio: 16 / 9,
  aspectRatio: 1,
  roundedViewBox: true,
  showImage: true,
  showImageChip: false,
  mainLogoImageFileName: '',
  gray: false,
  imageSize: undefined,
  compressionRate: 0.9,
}

export default ImagePicker
