/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

import {
  CHAT_LAYOUT_MOBILE_POSITION,
  CHAT_LAYOUT_PADDING_LEFT,
  CHAT_LAYOUT_POSITION,
  GAP_BETWEEN_CHAT_POPUPS,
  MAXIMIZED_CHAT_WIDTH,
  MINIMIZED_CHAT_WIDTH,
} from 'utils/constants/chat'
import { ElementSize } from 'utils/types/common'

interface ChatState {
  chatsPopupIds?: string[]
  hasAnErrorConnectingTheUser: boolean
  containerSize?: ElementSize
  maximizedChatId?: string
  unreadMessagesCount: number
}

const initialState: ChatState = {
  chatsPopupIds: [],
  hasAnErrorConnectingTheUser: false,
  unreadMessagesCount: 0,
}

// The width of the container for a tablet resolution is bigger than the width of the container for a mobile resolution.
// This constant is used to determine the width of the container for a mobile resolution.
// This is a value between the desktop min width and the mobile max width.
const MOBILE_CONTAINER_WIDTH = 741

const getPopupChatsForASpecificWidth = (
  chatsPopupIds: string[],
  containerWidth: number = 0,
  maximizedChatId?: string
) => {
  let popupsWidth = chatsPopupIds.length * MINIMIZED_CHAT_WIDTH
  popupsWidth += maximizedChatId
    ? MAXIMIZED_CHAT_WIDTH - MINIMIZED_CHAT_WIDTH
    : 0
  popupsWidth += (chatsPopupIds.length - 1) * GAP_BETWEEN_CHAT_POPUPS

  const isUsingMobileLayout = containerWidth < MOBILE_CONTAINER_WIDTH

  containerWidth -= isUsingMobileLayout
    ? CHAT_LAYOUT_MOBILE_POSITION
    : CHAT_LAYOUT_POSITION
  containerWidth -= CHAT_LAYOUT_PADDING_LEFT

  while (containerWidth < popupsWidth && chatsPopupIds.length > 1) {
    let chatId: string | undefined
    const lastChatId = chatsPopupIds[chatsPopupIds.length - 1]

    if (lastChatId !== maximizedChatId) {
      chatId = chatsPopupIds.pop()
    } else {
      const secondToLastChatIdPosition = chatsPopupIds.length - 2
      ;[chatId] = chatsPopupIds.splice(secondToLastChatIdPosition, 1)
    }

    if (chatId === maximizedChatId) {
      popupsWidth -= MAXIMIZED_CHAT_WIDTH
    } else {
      popupsWidth -= MINIMIZED_CHAT_WIDTH
    }
    popupsWidth -= GAP_BETWEEN_CHAT_POPUPS
  }

  return chatsPopupIds
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setHasAnErrorConnectingTheUser: (state, action: PayloadAction<boolean>) => {
      state.hasAnErrorConnectingTheUser = action.payload
    },
    maximizeChatPopupById: (state, action: PayloadAction<string>) => {
      const channelId = action.payload
      state.maximizedChatId = channelId

      const index = state.chatsPopupIds?.findIndex(
        (chatId) => chatId === channelId
      )

      if (index === -1) {
        state.chatsPopupIds?.unshift(channelId)
      }

      const chatPopupIds = [...(state.chatsPopupIds ?? [])]

      state.chatsPopupIds = getPopupChatsForASpecificWidth(
        chatPopupIds,
        state.containerSize?.width,
        state.maximizedChatId
      )
    },
    minimizeChatPopup: (state) => {
      state.maximizedChatId = undefined
    },
    closeChatPopupById: (state, action: PayloadAction<string>) => {
      const channelId = action.payload

      const index = (state.chatsPopupIds ?? []).findIndex(
        (chatId) => chatId === channelId
      )

      if (index >= 0) {
        state.chatsPopupIds?.splice(index, 1)
      }

      if (state.maximizedChatId === channelId) {
        state.maximizedChatId = undefined
      }
    },
    setChatLayoutContainer: (state, action: PayloadAction<ElementSize>) => {
      state.containerSize = action.payload

      const chatsPopupIds = [...(state.chatsPopupIds ?? [])]
      state.chatsPopupIds = getPopupChatsForASpecificWidth(
        chatsPopupIds,
        action.payload.width,
        state.maximizedChatId
      )
    },
    setUnreadMessagesCount: (state, action: PayloadAction<number>) => {
      state.unreadMessagesCount = action.payload
    },
  },
})

export const {
  maximizeChatPopupById,
  setHasAnErrorConnectingTheUser,
  minimizeChatPopup,
  closeChatPopupById,
  setChatLayoutContainer,
  setUnreadMessagesCount,
} = chatSlice.actions

export const getHasAnErrorConnectingTheUser = (state: RootState) =>
  state.chat.hasAnErrorConnectingTheUser

export const getChatPopupIds = (state: RootState): string[] =>
  [...(state.chat.chatsPopupIds ?? [])].reverse()

export const getMaximizedChatId = (state: RootState): string | undefined =>
  state.chat.maximizedChatId

export const getUnreadMessagesCount = (state: RootState): number =>
  state.chat.unreadMessagesCount

export default chatSlice.reducer
