import Card from 'components/Card'
import Toast from 'components/Toast'
import HeaderAdditionalInformation from 'containers/Profiles/components/SideInformation/CompanyHeader/HeaderAdditionalInformation.v2'
import HeaderBasicInformation from 'containers/Profiles/components/SideInformation/CompanyHeader/HeaderBasicInformation.v2'
import { useIntl } from 'react-intl'
import { SortDirection } from 'types/graphql-schemas/graphql'
import { useSubjectContext } from 'utils/context/subjects/SubjectContext'
import { useSubjectsQuery } from 'utils/hooks/queries/subjects/useSubjectsQuery'
import { useInvestmentVehiclesSubjectData } from 'utils/hooks/subjects/useInvestmentVehiclesSubjectData'
import { useInvestorSubjectData } from 'utils/hooks/subjects/useInvestorSubjectData'
import { SubjectType } from 'utils/types/subjects'
import CompanyDescription from './CompanyDescription/CompanyDescription'
import {
  HeaderInfoWrapper,
  SideCardWrapper,
  SideInformationWrapper,
} from './CompanyInformation.styles'
import CompanyKeyPeople from './CompanyKeyMembers/CompanyKeyMembers'
import InvestmentVehicles from './InvestmentVehicles/InvestmentVehicles'

const InvestorInformation = () => {
  const intl = useIntl()
  const { subject } = useSubjectContext()

  const { data, isLoading: isLoadingInvestmentVehicles } = useSubjectsQuery(
    {
      filters: {
        type: SubjectType.INVESTMENT_VEHICLE,
        parentSubject: subject.id,
      },
      sorting: {
        orderBy: 'name',
        direction: SortDirection.Asc,
      },
      fetchAll: true,
    },
    {
      onError: () => {
        Toast.displayIntl('investorManagement.errors.fetchInvestor', 'error')
      },
    }
  )
  const vehicles = data?.pages?.flatMap((page) => page)
  const investmentVehicles = useInvestmentVehiclesSubjectData(vehicles ?? [])

  const investorId = vehicles?.[0]?.parentSubject?.id
  // TODO: TAS-13594
  // const { taxId, bankName, bankAccount, routingNumber } =
  //   useSubjectFinancials(subject)
  // const hasFinancialInformation =
  //   !!taxId || !!bankName || !!bankAccount || !!routingNumber

  const { description, employees } = useInvestorSubjectData(subject)

  if (!subject) {
    return null
  }

  return (
    <SideInformationWrapper>
      <HeaderInfoWrapper>
        <HeaderBasicInformation />
        <HeaderAdditionalInformation />
      </HeaderInfoWrapper>

      {description && (
        <SideCardWrapper>
          <Card isExpandable padding="1.4rem 2.4rem">
            <Card.Header>
              {intl.formatMessage({ id: 'companyProfile.description' })}
            </Card.Header>
            <Card.Body>
              <CompanyDescription content={description} />
            </Card.Body>
          </Card>
        </SideCardWrapper>
      )}

      {!isLoadingInvestmentVehicles && !!investmentVehicles.length && (
        <SideCardWrapper>
          <Card isExpandable padding="1.4rem 2.4rem">
            <Card.Header>
              {intl.formatMessage(
                {
                  id: 'investorManagement.list.extended.investmentVehicles',
                },
                { count: investmentVehicles.length }
              )}
            </Card.Header>
            <Card.Body>
              <InvestmentVehicles
                investorId={investorId!}
                investmentVehicles={investmentVehicles}
              />
            </Card.Body>
          </Card>
        </SideCardWrapper>
      )}

      {/* TODO: TAS-13594 */}
      {/* 
      {hasFinancialInformation && (
        <SideCardWrapper>
          <Card isExpandable padding="1.4rem 2.4rem">
            <Card.Header>
              {intl.formatMessage({
                id: 'investorManagement.list.extended.financialInformation',
              })}
            </Card.Header>
            <Card.Body>
              <FinancialInfo
                taxId={taxId}
                bankAccount={bankAccount}
                bankName={bankName}
                routingNumber={routingNumber}
              />
            </Card.Body>
          </Card>
        </SideCardWrapper>
      )} */}

      {!!employees.length && (
        <SideCardWrapper>
          <Card isExpandable padding="1.4rem 2.4rem">
            <Card.Header>
              {intl.formatMessage({ id: 'investorManagement.keyPeople' })}
            </Card.Header>
            <Card.Body>
              <CompanyKeyPeople content={employees} />
            </Card.Body>
          </Card>
        </SideCardWrapper>
      )}
    </SideInformationWrapper>
  )
}

export default InvestorInformation
