import Drawer from 'components/Drawer'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from 'ui/Button'
import Dropdown from 'ui/Dropdown'
import Input from 'ui/Input'

import { HoldingsFilters, HoldingTypeFilter } from 'api/HoldingsService'
import Checkbox from 'components/Checkbox'
import Collapsable from 'components/Collapsable'
import Toggle from 'components/Toggle'
import theme from 'utils/theme'
import * as Styles from './FiltersDrawer.styles'
import { useFiltersDrawer } from './useFiltersDrawer'

interface FiltersDrawerProps {
  isOpen: boolean
  onClose: () => void
  currentFilters: HoldingsFilters
  onChangeFilters: (activeFilters: HoldingsFilters) => void
}

const FiltersDrawer: React.FC<FiltersDrawerProps> = ({
  isOpen,
  onClose,
  currentFilters,
  onChangeFilters,
}) => {
  const {
    initialData,
    tags,
    filtersToApply,
    totalHoldings,
    allSectors,
    isLoading,
    filtersApplied,
    firstFiltered,
    locationPlaceAddressSearchTerm,
    getIndustries,
    onTagChange,
    onLegalStructureChange,
    onClearFilters,
    // onOnlyPortfolioChange,
    onOnlyPrivateCompanyChange,
    onApplyFilters,
    onCloseHandler,
    onSelectSector,
    onSelectIndustry,
    onSelectType,
    loadLegalStructureOptions,
    getLegalStructureOption,
    onLocationPlaceAddressChange,
  } = useFiltersDrawer({
    currentFilters,
    onChangeFilters,
    onClose,
  })

  const intl = useIntl()

  const footerContent = (
    <Styles.Actions>
      <Button onClick={onClearFilters} back>
        <FormattedMessage id="companyList.clearAll" />
      </Button>
      <Button
        primary
        onClick={onApplyFilters}
        isLoading={isLoading}
        isDisabled={!filtersApplied && !firstFiltered}
      >
        {isLoading
          ? intl.formatMessage({ id: 'holdings.loadingOpportunities' })
          : intl.formatMessage(
              { id: 'companyList.showOpportunities' },
              { totalHoldings }
            )}
      </Button>
    </Styles.Actions>
  )

  return (
    <Drawer
      isOpen={isOpen}
      onCloseDrawer={onCloseHandler}
      headerTitle={intl.formatMessage({ id: 'companyList.filters' })}
    >
      <Drawer.Content contentPadding="1rem 2.4rem">
        <Styles.DrawerContent>
          {/* // The portfolioHoldings filter was removed until supported by the subjects API */}
          {/* <Collapsable
            id="companyList.portfolioHoldings"
            titleCustomStyles={
              filtersToApply?.onlyPortfolio
                ? Styles.AppliedFilterTitle
                : undefined
            }
          >
            <Toggle
              id="portfolioHoldingsOnly"
              name="portfolioHoldingsOnly"
              status={!!filtersToApply.onlyPortfolio}
              disableAnimationOnMount
              color={theme.colors.green}
              legend={intl.formatMessage({
                id: 'companyList.portfolioHoldingsOnly',
              })}
              onChangeStatus={onOnlyPortfolioChange}
            />
          </Collapsable> */}
          <Collapsable
            id="companyList.privateCompany"
            titleCustomStyles={
              filtersToApply?.onlyPrivateCompany
                ? Styles.AppliedFilterTitle
                : undefined
            }
          >
            <Toggle
              id="privateCompanyOnly"
              name="privateCompanyOnly"
              status={!!filtersToApply.onlyPrivateCompany}
              disableAnimationOnMount
              color={theme.colors.green}
              legend={intl.formatMessage({
                id: 'companyList.privateCompanyOnly',
              })}
              onChangeStatus={onOnlyPrivateCompanyChange}
            />
          </Collapsable>
          <Collapsable
            id="companyList.type"
            titleCustomStyles={
              filtersToApply?.typeIn?.length
                ? Styles.AppliedFilterTitle
                : undefined
            }
            isDisabled={!!filtersToApply?.onlyPrivateCompany}
          >
            <Styles.CheckboxWrapper>
              <Checkbox
                key="company_type"
                id="company_type"
                name="company_type"
                label={intl.formatMessage({
                  id: 'companyList.company',
                })}
                onChange={() => onSelectType(HoldingTypeFilter.COMPANY)}
                checked={filtersToApply?.typeIn?.includes(
                  HoldingTypeFilter.COMPANY
                )}
                disabled={!!filtersToApply?.onlyPrivateCompany}
              />
            </Styles.CheckboxWrapper>
            <Styles.CheckboxWrapper>
              <Checkbox
                key="fundGroup_type"
                id="fundGroup_type"
                name="fundGroup_type"
                label={intl.formatMessage({
                  id: 'companyList.organization',
                })}
                onChange={() => onSelectType(HoldingTypeFilter.ORGANIZATION)}
                checked={filtersToApply?.typeIn?.includes(
                  HoldingTypeFilter.ORGANIZATION
                )}
                disabled={!!filtersToApply?.onlyPrivateCompany}
              />
            </Styles.CheckboxWrapper>
            <Styles.CheckboxWrapper>
              <Checkbox
                key="fundPortfolio_type"
                id="fundPortfolio_type"
                name="fundPortfolio_type"
                label={intl.formatMessage({
                  id: 'companyList.fund',
                })}
                onChange={() => onSelectType(HoldingTypeFilter.FUND)}
                checked={filtersToApply?.typeIn?.includes(
                  HoldingTypeFilter.FUND
                )}
                disabled={!!filtersToApply?.onlyPrivateCompany}
              />
            </Styles.CheckboxWrapper>

            <Styles.CheckboxWrapper>
              <Checkbox
                key="deal_type"
                id="deal_type"
                name="deal_type"
                label={intl.formatMessage({
                  id: 'companyList.deal',
                })}
                onChange={() => onSelectType(HoldingTypeFilter.DEAL)}
                checked={filtersToApply?.typeIn?.includes(
                  HoldingTypeFilter.DEAL
                )}
              />
            </Styles.CheckboxWrapper>
          </Collapsable>
          <Collapsable
            id="companyList.sectorAndIndustry"
            titleCustomStyles={
              filtersToApply?.sectorId?.length ||
              filtersToApply?.industryId?.length
                ? Styles.AppliedFilterTitle
                : undefined
            }
          >
            {allSectors?.map((sector) => (
              <>
                <Styles.CheckboxWrapper>
                  <Checkbox
                    key={sector.id}
                    id={`showTitle_${sector.id}`}
                    name={`showTitle_${sector.id}`}
                    label={sector.name}
                    onChange={() => onSelectSector(sector.id)}
                    checked={filtersToApply?.sectorId?.includes(sector.id)}
                  />
                </Styles.CheckboxWrapper>
                <Styles.Industries
                  show={
                    filtersToApply?.sectorId?.includes(sector.id) ||
                    getIndustries(sector.id).some((industry) =>
                      filtersToApply?.industryId?.includes(industry.id)
                    )
                  }
                >
                  {getIndustries(sector.id)?.map((industry) => (
                    <Styles.Industry>
                      <Checkbox
                        key={industry.id}
                        id={`showTitle_${industry.id}`}
                        name={`showTitle_${industry.id}`}
                        label={industry.name}
                        onChange={() =>
                          onSelectIndustry({
                            industryId: industry.id,
                            sectorId: sector.id,
                          })
                        }
                        checked={filtersToApply?.industryId?.includes(
                          industry.id
                        )}
                      />
                    </Styles.Industry>
                  ))}
                </Styles.Industries>
              </>
            ))}
          </Collapsable>
          <Collapsable
            id="companyList.location"
            titleCustomStyles={
              filtersToApply.locationPlaceAddress
                ? Styles.AppliedFilterTitle
                : undefined
            }
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'companyList.search',
              })}
              value={locationPlaceAddressSearchTerm}
              onChange={onLocationPlaceAddressChange}
              icon={['far', 'search']}
              iconFontSize="1.5rem"
            />
          </Collapsable>
          <Collapsable
            id="companyList.groupTags"
            titleCustomStyles={
              filtersToApply?.tags?.length
                ? Styles.AppliedFilterTitle
                : undefined
            }
          >
            <Styles.TagContainer>
              {tags?.map((tag) => {
                return (
                  <Styles.Tag
                    id={tag.id}
                    key={tag.id}
                    selected={filtersToApply.tags?.some(
                      (currTag) => currTag.id === tag.id
                    )}
                    onClick={() => onTagChange(tag)}
                  >
                    {tag.name}
                  </Styles.Tag>
                )
              })}
            </Styles.TagContainer>
          </Collapsable>
          <Collapsable
            id="companyList.legalStructure"
            titleCustomStyles={
              filtersToApply?.legalStructureId?.length
                ? Styles.AppliedFilterTitle
                : undefined
            }
          >
            <Dropdown
              name="legalStructure"
              type="select"
              async
              placeholder={intl.formatMessage({
                id: 'investorManagement.addLegalStructure',
              })}
              loadOptions={loadLegalStructureOptions(
                initialData?.legalStructures
              )}
              getOption={getLegalStructureOption}
              value={filtersToApply?.legalStructureId?.[0]}
              onSelectOption={(_, __, option) =>
                onLegalStructureChange(option.id)
              }
              preventAutocomplete
            />
          </Collapsable>
        </Styles.DrawerContent>
      </Drawer.Content>

      <Drawer.Footer>{footerContent}</Drawer.Footer>
    </Drawer>
  )
}

export default FiltersDrawer
