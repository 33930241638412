import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Chip from 'components/Chip/Chip'
import Timeline from 'components/Timeline/Timeline'
import { Input } from 'containers/UpdatesV2/components/commons'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import Dropdown from 'ui/Dropdown'
import Separator from 'ui/Separator'
import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'
import { MetricFrequency } from 'utils/constants/metrics'
import { buildFormError } from 'utils/functions/forms'
import { getFrequencyNames } from 'utils/functions/metrics'
import { useMetricsSubjectQuery } from 'utils/hooks/useMetricsSubjectQuery'
import { ErrorType } from 'utils/types/common'
import { Metric } from 'utils/types/metricsV2'
import {
  Capitalize,
  ChipWrapper,
  Icon,
  Message,
  MessageTitle,
  TextLabel,
} from '../EditMetric.styles'
import useEditInvestorMetricForm from './useEditInvestorMetricForm'

interface Props {
  showTimeline: boolean
  showFounderTimelineItem: boolean
  showTimelineMessage: boolean
  metric: Metric
}

const EditInvestorMetricForm = ({
  showTimeline,
  showFounderTimelineItem,
  showTimelineMessage,
  metric,
}: Props) => {
  const intl = useIntl()

  const { data: subject } = useMetricsSubjectQuery({
    subjectId: metric.subject.id,
    subjectType: metric.subject.type,
  })

  const {
    nameField,
    frequencyField,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useEditInvestorMetricForm()

  const senderMetric = metric.senderLinkedMetric
  const senderMetricName = showTimeline ? metric.senderLinkedMetric?.name : ''
  const message = showTimelineMessage ? metric.receiverMetricLink?.message : ''
  const trackingSince = metric.receiverMetricLink?.updatedAt

  return (
    <>
      <Input
        id={nameField.name}
        name={nameField.name}
        error={buildFormError(errors.name, ErrorType.ERROR, true)}
        onBlur={handleBlur}
        title={intl.formatMessage({ id: 'metrics.name' })}
        label={intl.formatMessage({ id: 'metrics.name' })}
        placeholder={intl.formatMessage({ id: 'metrics.namePlaceholder' })}
        value={nameField.value}
        onChange={handleChange}
        withCounter
        customMaxCounterCharacters={MAX_COUNTER_CHARACTERS}
        counterSubtractNumber={nameField.value?.length}
        simulateIconSpace
        counterStyle={{
          right: '2rem',
          top: '1.2rem',
          opacity: 0.5,
        }}
        extraPaddingRight={1.5}
      />

      <Separator space="1rem" />

      <Dropdown
        id={frequencyField.name}
        name={frequencyField.name}
        label={intl.formatMessage({ id: 'metrics.frequency' })}
        placeholder={intl.formatMessage({ id: 'metrics.frequencyPlaceholder' })}
        onSelectOption={(_, value) => {
          setFieldValue(frequencyField.name, value)
        }}
        showBorder
        options={getFrequencyNames(intl)}
        error={buildFormError(errors.frequency, ErrorType.ERROR, true)}
        value={frequencyField.value}
      />

      <Separator space="1rem" />

      <TextLabel>{intl.formatMessage({ id: 'metrics.from' })}</TextLabel>

      {subject && (
        <ChipWrapper>
          <Chip
            image={subject.primaryLogo?.url}
            text={subject.name}
            canDelete={false}
          />
        </ChipWrapper>
      )}

      <Separator space="2.3rem" />

      {showTimeline && (
        <TextLabel>
          {intl.formatMessage({ id: 'metrics.founderInformation' })}
        </TextLabel>
      )}

      <Timeline renderChildrenDuration={0.1}>
        {showFounderTimelineItem && (
          <Timeline.Item
            key="data"
            icon={
              <Icon>
                <FontAwesomeIcon icon={['far', 'analytics']} />
              </Icon>
            }
            updateType="log"
            left="1.6rem"
            padding="0 0 1.8rem 1.4rem"
          >
            <MessageTitle>
              {intl.formatMessage({ id: 'metrics.receiveDataFrom' })}
            </MessageTitle>

            <Message marginTop="0.2rem">
              {senderMetricName}{' '}
              {senderMetric?.frequency &&
              senderMetric.frequency !== MetricFrequency.NONE ? (
                <Capitalize>
                  {intl.formatMessage(
                    { id: 'metrics.frequencyValue' },
                    { frequency: senderMetric.frequency }
                  )}
                </Capitalize>
              ) : (
                ''
              )}
            </Message>
            <Message marginTop="0">
              {intl.formatMessage({ id: 'metrics.since' })}{' '}
              {dayjs(trackingSince).format('MMM DD, YYYY - hh:mm A')}
            </Message>
          </Timeline.Item>
        )}
        {showTimelineMessage && (
          <Timeline.Item
            key="message"
            icon={
              <Icon>
                <FontAwesomeIcon icon={['far', 'envelope']} />
              </Icon>
            }
            left="1.6rem"
            padding="0 0 0 1.4rem"
          >
            <MessageTitle>
              {intl.formatMessage(
                { id: 'metrics.messageSent' },
                {
                  date: dayjs(metric.senderLinkedMetric?.createdAt).format(
                    'MMM DD, YYYY - hh:mm A'
                  ),
                }
              )}
            </MessageTitle>
            <Message marginTop="0.2rem">{message}</Message>
          </Timeline.Item>
        )}
      </Timeline>

      <Separator space="3.6rem" />
    </>
  )
}

export default EditInvestorMetricForm
