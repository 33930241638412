import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import { useIntl } from 'react-intl'

import Avatar from 'components/Avatar'
import SubjectStatus from 'components/SubjectStatus'

import { isEnterCode } from 'utils/functions/keyboardEvents'
import { getInitials } from 'utils/functions/user'
import AddToPortfolioButton from 'components/AddToPortfolioButton'

import { HoldingSubjectCardProps } from './HoldingSubjectCard'
import { useHoldingSubjectCard } from './useHoldingSubjectCard'
import {
  CompanyInfo,
  CompanyLocation,
  CompanyRowName,
  CompanyWrapperRow,
  FundManagerName,
  Industries,
  IndustriesText,
  LogoWrapper,
  PortfolioWrapper,
} from './HoldingSubjectRowCard.styles'

const cardVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 50, velocity: -100 },
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 50 },
    },
  },
}

const HoldingSubjectRowCard = ({
  subject,
  onClick,
  hideAddToPortfolioButton = false,
}: Omit<HoldingSubjectCardProps, 'showRemoveButton' | 'onRemoveCompany'>) => {
  const intl = useIntl()
  const { isFund, location, fundCompany, companyIndustries, logo } =
    useHoldingSubjectCard(subject)

  const handleKeyUp = (event) => {
    if (isEnterCode(event)) {
      onClick(subject)
    }
  }

  const companyLocation =
    location || intl.formatMessage({ id: 'companyProfile.noLocation' })

  return (
    <motion.li key={subject.name} variants={cardVariants}>
      <CompanyWrapperRow
        onClick={() => onClick(subject)}
        onKeyUp={handleKeyUp}
        role="button"
      >
        {!hideAddToPortfolioButton && (
          <PortfolioWrapper>
            <AddToPortfolioButton
              holdingId={subject.id}
              holdingName={subject.name}
              showTooltip={false}
              holdingPortfolios={
                [
                  // TODO: Fix this
                  // ...(holding.holdingPortfolios ?? []),
                  // ...(holding.holdingFundPortfolios ?? []),
                ]
              }
              isFundHolding={isFund}
              // forceFetch
            />
          </PortfolioWrapper>
        )}
        <LogoWrapper>
          <Avatar
            image={logo}
            initials={getInitials(subject.name)}
            grayBorder={false}
            avatarStyle="avatarCircleFundLogo"
          />
        </LogoWrapper>

        <CompanyInfo>
          <CompanyRowName>{subject.name}</CompanyRowName>

          <div>
            {companyLocation && !isFund && (
              <CompanyLocation>
                <FontAwesomeIcon icon={['far', 'map-marker-alt']} />
                {companyLocation}
              </CompanyLocation>
            )}

            {isFund && !!fundCompany && (
              <FundManagerName>
                <FontAwesomeIcon icon={['far', 'user-tie']} />
                {fundCompany.name}
              </FundManagerName>
            )}
            <Industries title={companyIndustries}>
              <IndustriesText as="span">{companyIndustries}</IndustriesText>
            </Industries>
            <SubjectStatus subject={subject} displayLastUpdate />
          </div>
        </CompanyInfo>
      </CompanyWrapperRow>
    </motion.li>
  )
}

export default HoldingSubjectRowCard
