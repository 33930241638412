// @import './HoldingCard.module.scss';
import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import * as HoldingSubjectCardStyles from './HoldingSubjectCard.styles'

export const PortfolioWrapper = styled(
  HoldingSubjectCardStyles.PortfolioWrapper
)`
  @media ${maxSize.md} {
    right: 2.4rem;
    top: 1.8rem;
  }
`

export const CompanyWrapperRow = styled.div`
  background: white;
  border-radius: 1rem;
  position: relative;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease-in-out;
  padding: 2rem;
  border: 0.2rem solid transparent;
  align-items: center;
  display: grid;
  grid-template-columns: 90px auto;
  column-gap: 1.5rem;

  &:hover {
    border: 0.2rem solid var(--color-button-back-outline);
  }

  @media ${maxSize.md} {
    padding: 2.2rem 2.4rem 2.2rem 2.8rem;
  }
`

export const LogoWrapper = styled.div`
  border: 0.1rem solid #e9e9e9;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 9rem;
  width: 9rem;
`
export const CompanyInfo = styled.div`
  text-align: center;
  overflow: hidden;
  text-align: start;
`

export const CompanyRowName = styled.div`
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
  color: ${color('darkBlue')};
  font-weight: 600;
  max-width: calc(100% - 4rem);

  @media (max-width: 575px) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const Label = styled.p`
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 0.03em;
  align-items: center;
  color: #787e8c;
`

export const FundManagerName = styled(Label)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.6rem;
`

export const CompanyLocation = styled(Label)`
  margin: 0;
  width: 100%;
  text-align: initial;
  overflow: hidden;
  text-overflow: ellipsis;

  > svg {
    margin-right: 0.6rem;
    color: #787e8c;
  }
`
export const IndustriesText = styled(Label)`
  line-height: 1.5;
  font-size: 1.4rem;
`

export const Industries = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  color: #787e8c;
  margin-top: 0.8rem;

  max-width: 80%;
  @media (max-width: $breakpoint-md) {
    display: none;
  }
`
