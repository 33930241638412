import styled, { css } from 'styled-components'
import IconButton from 'ui/IconButton'
import { color } from 'utils/functions/colors'

export const AvatarPlaceholder = styled.div`
  background: url(${(props) => props.backgroundSrc}) round;
  border-radius: 100%;
  height: 100%;
  width: 100%;
`

export const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.showImage ? `auto 1fr` : '1fr')};
  align-items: center;

  div[class*='Modal_modalBody'] {
    padding: 2.4rem 3.1rem;
  }

  ${(props) =>
    props.roundedViewBox &&
    css`
      span[class='cropper-view-box'] {
        border-radius: 50%;
      }
    `};

  div[class='cropper-container'] {
    height: 460px !important;
  }

  div[class^='Avatar'] {
    width: 100%;
    margin: 0;
    height: 100%;
    background: ${color('primaryBlue')};

    > span {
      font-size: 4.5rem;
      font-weight: 400;
    }
  }
`

export const DeleteImageButtonContainer = styled.div`
  position: absolute;
  display: none;
  z-index: 3;
`

export const DeleteHoverBorder = styled.div`
  border-radius: 100%;
  border: 0.2rem solid ${color('veryLightBlue')};
  display: none;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
`

export const StyledTrashIconButton = styled(IconButton)`
  &:focus {
    border: none;
    background-color: transparent;
  }

  &:active {
    box-shadow: none;
  }

  &:hover {
    background-color: transparent;
    background: transparent;

    > svg {
      color: var(--color-red-default);
    }
  }
`

export const DeleteImageOverlay = styled.div`
  background: ${color('veryLightGray', 0.8)};
  z-index: 1;
  border-radius: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: none;
`

export const BackgroundImage = styled.div`
  height: ${({ imageSize }) => imageSize || '9rem'};
  width: ${({ imageSize }) => imageSize || '9rem'};

  background-repeat: no-repeat;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  &:hover {
    ${DeleteImageButtonContainer}, ${DeleteImageOverlay}, ${DeleteHoverBorder} {
      display: block;
    }
  }

  ${(props) =>
    props.gray &&
    css`
      background: ${color('lightGray', 0.1)};
    `}
  background-size: cover;
`
export const ImageBorder = styled.div`
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 100%;
  height: ${({ imageSize }) => imageSize || '9rem'};
  width: ${({ imageSize }) => imageSize || '9rem'};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ImageHelperContainer = styled.div``

export const ImageHelper = styled.p`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${color('lightGray')};
`

export const ImageInfo = styled.div`
  width: 100%;

  ${(props) =>
    props.showImage &&
    css`
      margin-left: 1.5rem;
    `};
`

export const ImageLabelWrapper = styled.label`
  cursor: pointer;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BrowseImageContainer = styled.div`
  padding-top: 1.6rem;
`

export const BrowseImageButton = styled.label`
  display: block;
  max-width: fit-content;
  cursor: pointer;
  color: ${color('darkBlue')};
  font-weight: bold;
  background-color: ${color('white')};
  border: 0.1rem solid ${color('veryLightBlue')};
  padding: 1rem 1.6rem;
  border-radius: 0.8rem;
  font-size: 1.4rem;
  width: 13.2rem;
  min-width: 12.2rem;
  &:hover {
    background-color: ${color('veryLightBlue', 0.4)};
  }
`

export const FileUpload = styled.input`
  display: none;
`

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${(props) =>
    !props.showRemoveImageButton &&
    css`
      > button {
        visibility: hidden;
      }
    `}
`

export const CancelSaveWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
`

export const Border = styled.div`
  background: #dfe6ed;
  position: absolute;
  bottom: 8.5rem;
  left: 0;
  height: 0.1rem;
  width: 100%;
`

export const ImageInfoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`

export const ImageInformation = styled.p`
  color: ${color('darkGray')};
  font-weight: 700;
  font-size: 1.2rem;
`

export const LargeAvatar = styled.div`
  width: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid #e9e9e9;
  padding: 2.5rem 0;
  border-radius: 0.8rem;

  div[class^='Avatar'] {
    width: 32.8rem;
    height: 32.8rem;
    background: ${color('primaryBlue')};
    margin-left: 0;
    margin: 0;

    > span {
      font-size: 13.9rem;
      font-weight: 400;
    }
  }
`

export const CropperContainer = styled.div`
  margin-bottom: 1.7rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2.4rem;
`

export const HelpTextContainer = styled.div`
  padding-top: 1.6rem;
`
