import PulseIndicator from 'components/PulseIndicator'
import Tooltip from 'components/Tooltip'
import Actions from 'containers/Metrics/AllMetrics/components/MetricActions'
import MetricsChipList from 'containers/Metrics/AllMetrics/components/MetricsChipList'
import { MetricsTableColumn } from 'containers/Metrics/AllMetrics/useAllMetricsTable'
import { MAX_SANDBOX_METRICS } from 'containers/Metrics/SelectedMetrics/useMetricsSandbox'
import humps from 'humps'
import { FormattedMessage, type IntlShape } from 'react-intl'
import styled from 'styled-components'
import CurrentValue from 'containers/Metrics/AllMetrics/components/CurrentValue'
import PreviousValue from 'containers/Metrics/AllMetrics/components/PreviousValue'
import Checkbox from 'ui/Checkbox'
import FloatColumnWrapper from 'ui/Table/FloatButtonColumn/FloatColumnWrapper'
import { Column } from 'ui/Table/types'
import theme from 'utils/theme'
import { ReactChildren } from 'utils/types/common'
import { MetricSources } from 'utils/types/metrics'
import {
  IndexMetric,
  LinkedMetricState,
  Milestone,
} from 'utils/types/metricsV2'

import * as ProfileMetricStyles from 'components/ProfileMetrics/components/ProfileMetric/ProfileMetric.styles'
import { color } from '../colors'

const MetricName = styled.span`
  color: ${color('darkBlue')};
  font-size: 1.4rem;
  font-weight: 700;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const TooltipContent = styled.div`
  align-items: center;
  color: ${color('darkGray')};
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
`

interface MetricActions {
  onRemoveMetric: (metric: IndexMetric) => void
  onAddNewValue: (metric: IndexMetric) => void
  onEditMetric: (metric: IndexMetric) => void
  onSetMilestone: (metric: IndexMetric) => void
  onEditMilestone: (metric: IndexMetric, milestone: Milestone) => void
  onViewMetricDetail: (metricId?: string) => void
  onImportCsv: (metricData: any) => void
  onExportCsv: (metric: IndexMetric) => Promise<void>
}

interface MetricsColumns {
  intl: IntlShape
  Cell: React.FC<{ children: ReactChildren; maxLines?: number }>
  metricActions: MetricActions
  visibleColumns: Record<MetricsTableColumn, boolean>
  isMetricInSandbox: (metric: IndexMetric) => boolean
  toggleMetricInSandbox: (metric: IndexMetric) => void
  isAddMetricToSandboxDisabled: () => boolean
}

export const getMetricsColumns = ({
  intl,
  Cell,
  metricActions,
  visibleColumns,
  isMetricInSandbox,
  toggleMetricInSandbox,
  isAddMetricToSandboxDisabled,
}: MetricsColumns): Column<IndexMetric>[] => {
  const columns: Column<IndexMetric>[] = [
    {
      id: 'checkbox',
      sortDisabled: true,
      flex: 0,
      minWidth: 30,
      cellRenderer: ({ rowData: metric }) => {
        const isChecked = isMetricInSandbox(metric)
        const isDisabled = !isChecked && isAddMetricToSandboxDisabled()

        const onToggleMetric = () => {
          if (isChecked) {
            toggleMetricInSandbox(metric)
          } else if (!isAddMetricToSandboxDisabled()) {
            toggleMetricInSandbox(metric)
          }
        }

        const DisabledSelectMetricCheckbox = (
          <Tooltip
            text={intl.formatMessage(
              {
                id: 'metrics.showInSandboxDisabled',
              },
              {
                count: MAX_SANDBOX_METRICS,
              }
            )}
            backgroundColor={color('white')({ theme })}
            color={color('darkGray')({ theme })}
            place="bottom"
            tooltipStyle={{ maxWidth: '28.5rem' }}
            parentTooltipStyles={{
              border: `1px solid ${theme.colors.veryLightGray}`,
              padding: '0.6rem 0.8rem !important',
              boxShadow: 'box-shadow: 0px 0px 15px 0px rgba(16, 21, 39, 0.10)',
            }}
          >
            <Checkbox
              id={metric.id}
              name={metric.id}
              label=""
              onChange={onToggleMetric}
              checked={isChecked}
              disabled={isDisabled}
            />
          </Tooltip>
        )

        if (isDisabled) {
          return DisabledSelectMetricCheckbox
        }

        return (
          <Checkbox
            id={metric.id}
            name={metric.id}
            label=""
            onChange={onToggleMetric}
            checked={isChecked}
            disabled={isDisabled}
          />
        )
      },
    },
    {
      id: 'name',
      sortKey: 'name',
      label: <FormattedMessage id="metrics.allMetrics.name" />,
      maxWidth: '26rem',
      minWidth: 260,
      flex: 1,
      hidden: !visibleColumns.name,
      cellRenderer: ({ rowData: metric }) => {
        const isCalculatedMetric = metric?.source === MetricSources.System
        const metricName = isCalculatedMetric
          ? intl.formatMessage({
              id: `portfolios.${humps.camelize(metric.name)}`,
            })
          : metric?.name

        return (
          <Tooltip
            backgroundColor={color('white')({ theme })}
            boxShadow="0px 0px 15px 0px #1015271a"
            color={color('darkGray')({ theme })}
            id={`metric-name-${metric.id}`}
            place="top"
            text={<TooltipContent>{metricName}</TooltipContent>}
          >
            <MetricName>{metricName}</MetricName>
          </Tooltip>
        )
      },
    },
    {
      id: 'portfolioHolding',
      sortKey: 'metadata.subject_name',
      label: <FormattedMessage id="metrics.allMetrics.portfolioHolding" />,
      maxWidth: '21.8rem',
      minWidth: 218,
      flex: 1,
      hidden: !visibleColumns.portfolioHolding,
      cellRenderer: ({ rowData }) => {
        return <MetricsChipList metric={rowData} />
      },
    },
    {
      id: 'source',
      sortKey: 'metadata.source',
      label: <FormattedMessage id="metrics.allMetrics.source" />,
      maxWidth: '20.8rem',
      minWidth: 208,
      flex: 1,
      hidden: !visibleColumns.source,
      cellRenderer: ({ rowData }) => {
        const isCalculatedMetric = rowData?.source === MetricSources.System
        const showFounderDataPulse =
          rowData?.receiverMetricLink?.receiveData &&
          (rowData?.receiverMetricLink?.state ===
            LinkedMetricState.SHARE_ACCEPTED ||
            rowData?.receiverMetricLink?.state ===
              LinkedMetricState.REQUEST_ACCEPTED)

        if (showFounderDataPulse) {
          return (
            <Cell>
              <ProfileMetricStyles.ReceivingDataWrapper>
                <PulseIndicator color={theme.colors.primaryBlue} />
                <FormattedMessage id="metrics.receivingData" />
              </ProfileMetricStyles.ReceivingDataWrapper>
            </Cell>
          )
        }

        if (isCalculatedMetric) {
          return (
            <Cell>
              <ProfileMetricStyles.CalculatedMetricContainer>
                <ProfileMetricStyles.LockIcon icon={['fal', 'lock']} />
                <ProfileMetricStyles.Subtitle>
                  <FormattedMessage id="metrics.cwUniverseCalculated" />
                </ProfileMetricStyles.Subtitle>
              </ProfileMetricStyles.CalculatedMetricContainer>
            </Cell>
          )
        }

        return null
      },
    },
    {
      id: 'currentValue',
      sortKey: 'currentValue',
      label: <FormattedMessage id="metrics.allMetrics.currentValue" />,
      sortDisabled: true,
      maxWidth: '11.5rem',
      minWidth: 115,
      flex: 1,
      hidden: !visibleColumns.currentValue,
      cellRenderer: CurrentValue,
    },
    {
      id: 'previousValue',
      sortKey: 'previousValue',
      label: <FormattedMessage id="metrics.allMetrics.previousValue" />,
      sortDisabled: true,
      flex: 1,
      minWidth: 115,
      hidden: !visibleColumns.previousValue,
      cellRenderer: PreviousValue,
    },
    {
      id: 'actions-column',
      sortDisabled: true,
      flex: 0,
      cellRenderer: ({ rowData }) => {
        return (
          <FloatColumnWrapper id={rowData.id}>
            <Actions metric={rowData} {...metricActions} />
          </FloatColumnWrapper>
        )
      },
    },
  ]

  return columns.filter((column) => !column.hidden)
}
