import * as yup from 'yup'
import type { IntlShape } from 'react-intl'

import {
  InvestmentVehicleFormValues,
  AddInvestorStepFormValues,
  AddInvestorOtherInfoFormValues,
  AddInvestorSendInvitesFormValues,
} from 'utils/types/investors'
import {
  CRUNCHBASE_URL,
  LINKEDIN_URL,
  X_TWITTER_URL,
} from 'utils/functions/regex'
import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'

export const financialSchema = (_intl: IntlShape) => ({
  einTaxId: yup.string().nullable(),
  bankName: yup.string().nullable(),
  bankAccountNumber: yup.string().nullable(),
  routingNumber: yup.string().nullable(),
})

export const getInvestmentVehicleSchema = (intl: IntlShape) => {
  return yup.object<InvestmentVehicleFormValues>().shape({
    name: yup
      .string()
      .required(intl.formatMessage({ id: 'general.requiredField' }))
      .max(
        MAX_COUNTER_CHARACTERS,
        intl.formatMessage(
          { id: 'general.youHaveExceededCharacterLimit' },
          { limit: MAX_COUNTER_CHARACTERS }
        )
      ),
    investor: yup
      .object()
      .required(intl.formatMessage({ id: 'general.requiredField' }))
      .nullable(),
    shareInvestorData: yup.boolean(),
    phone: yup.string().nullable(),
    locations: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        main: yup.boolean(),
        description: yup.string(),
      })
    ),
    ...financialSchema,
  })
}
export const getAddInvestorStepSchema = (intl: IntlShape) => {
  return yup.object<AddInvestorStepFormValues>().shape({
    type: yup
      .string()
      .required(intl.formatMessage({ id: 'general.requiredField' })),
    logo: yup.string().nullable(),
    name: yup
      .string()
      .required(intl.formatMessage({ id: 'general.requiredField' }))
      .max(
        MAX_COUNTER_CHARACTERS,
        intl.formatMessage(
          { id: 'general.youHaveExceededCharacterLimit' },
          { limit: MAX_COUNTER_CHARACTERS }
        )
      ),

    website: yup
      .string()
      .url(intl.formatMessage({ id: 'general.notValidUrl' }))
      .nullable(),
    phone: yup.string().nullable(),
    emails: yup.array().of(yup.string()),
    foundedDate: yup.date().nullable(),
    locations: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        main: yup.boolean(),
        description: yup.string(),
      })
    ),
    description: yup.string().nullable(),
  })
}

export const getEmployeeSchema = (intl: IntlShape) => {
  return yup.object().shape({
    firstName: yup
      .string()
      .required(intl.formatMessage({ id: 'general.requiredField' })),
    lastName: yup
      .string()
      .required(intl.formatMessage({ id: 'general.requiredField' })),
    email: yup.string().email().nullable(),
    title: yup.string().nullable(),
    linkedinUrl: yup
      .string()
      .matches(LINKEDIN_URL, {
        message: intl.formatMessage({ id: 'general.invalidLinkedinUrl' }),
        excludeEmptyString: true,
      })
      .nullable(),
    founder: yup.boolean(),
  })
}

export const getInvestorAddOtherInfoSchema = (intl: IntlShape) => {
  return yup.object<AddInvestorOtherInfoFormValues>().shape({
    crunchbaseUrl: yup
      .string()
      .matches(CRUNCHBASE_URL, {
        message: intl.formatMessage({ id: 'general.invalidCrunchbaseUrl' }),
        excludeEmptyString: true,
      })
      .nullable(),
    linkedinUrl: yup
      .string()
      .matches(LINKEDIN_URL, {
        message: intl.formatMessage({ id: 'general.invalidLinkedinUrl' }),
        excludeEmptyString: true,
      })
      .nullable(),
    twitterUrl: yup
      .string()
      .matches(X_TWITTER_URL, {
        message: intl.formatMessage({ id: 'general.invalidTwitterUrl' }),
        excludeEmptyString: true,
      })
      .nullable(),
    teamSize: yup
      .number()
      .transform((currentValue: number, originalValue: string) => {
        return originalValue === '' ? null : currentValue
      })
      .nullable(),
    employees: yup.array().of(getEmployeeSchema(intl)),
    leganEntityName: yup.string().nullable(),
    legalStructure: yup.object().nullable(),
    legalCountry: yup.object().nullable(),
    legalState: yup.object().nullable(),
    industrieS: yup.array().of(yup.object()),
    ...financialSchema,
  })
}

export const getSendInvitesSchema = () => {
  return yup.object<AddInvestorSendInvitesFormValues>().shape({
    sendInvitation: yup.boolean(),
    users: yup.array().of(yup.object()),
    message: yup.string().nullable(),
  })
}

export const getEditInvestorSchema = (intl: IntlShape) => {
  return yup
    .object()
    .concat(getAddInvestorStepSchema(intl))
    .concat(getInvestorAddOtherInfoSchema(intl))
}

export const getBulkImportInvestorsSchema = (intl: IntlShape) => {
  return yup.object().shape({
    investors: yup.array().of(getEditInvestorSchema(intl)),
  })
}

export const getBulkImportInvestmentVehicleSchema = (intl: IntlShape) => {
  return yup.object().shape({
    investmentVehicles: yup.array().of(getInvestmentVehicleSchema(intl)),
  })
}
