import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SubjectType } from 'utils/types/subjects'
import { StyledPortfolioIcon } from './PortfolioSubjectIcon.styles'

interface PortfolioIconProps {
  type:
    | SubjectType.TRACK
    | SubjectType.INVEST
    | SubjectType.FUND
    | SubjectType.DEAL
  isActive?: boolean
  noBackground?: boolean
  backgroundColor?: string
  className?: string
}

const portfoliosIconConfig = {
  [`${SubjectType.TRACK}`]: <FontAwesomeIcon icon={['far', 'binoculars']} />,
  [`${SubjectType.INVEST}`]: <FontAwesomeIcon icon={['far', 'dollar-sign']} />,
  [`${SubjectType.FUND}`]: <FontAwesomeIcon icon={['far', 'sack-dollar']} />,
  [`${SubjectType.DEAL}`]: <FontAwesomeIcon icon={['far', 'badge-dollar']} />,
}

const PortfolioSubjectIcon = ({
  type,
  isActive = true,
  noBackground = false,
  backgroundColor,
  className,
}: PortfolioIconProps) => {
  return (
    <StyledPortfolioIcon
      isActive={isActive}
      noBackground={noBackground}
      backgroundColor={backgroundColor}
      className={className}
    >
      {portfoliosIconConfig[type]}
    </StyledPortfolioIcon>
  )
}

export default PortfolioSubjectIcon
