import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'

import AddToPortfolioButton from 'components/AddToPortfolioButton'
import Avatar from 'components/Avatar'
import { isEnterCode } from 'utils/functions/keyboardEvents'

import { gridItemAnimation } from 'utils/animations/grid'
import { getInitials } from 'utils/functions/user'

import { BasicSubjectFragment } from 'types/graphql-schemas/graphql'
import SubjectStatus from 'components/SubjectStatus'
import * as Styles from './HoldingSubjectCard.styles'
import { useHoldingSubjectCard } from './useHoldingSubjectCard'

export type HoldingSubjectCardProps = {
  subject: BasicSubjectFragment
  onClick: (subject: BasicSubjectFragment) => void
  hideAddToPortfolioButton: boolean
  showRemoveButton?: boolean
  onRemoveCompany?: (subject: BasicSubjectFragment) => void
}

const HoldingSubjectCard = ({
  subject,
  onClick,
  hideAddToPortfolioButton = false,
  showRemoveButton = false,
  onRemoveCompany,
}: HoldingSubjectCardProps) => {
  const { isFund, isDeal, location, fundCompany, railsId } =
    useHoldingSubjectCard(subject)

  const handleKeyUp = (event) => {
    if (isEnterCode(event)) {
      onClick(subject)
    }
  }

  return (
    <motion.li key={subject.id} variants={gridItemAnimation}>
      <Styles.CardWrapper
        onClick={() => onClick(subject)}
        onKeyUp={handleKeyUp}
        role="button"
      >
        {!hideAddToPortfolioButton && (
          <Styles.PortfolioWrapper>
            <AddToPortfolioButton
              holdingId={railsId!}
              holdingName={subject.name}
              showTooltip={false}
              isFundHolding={isFund}
            />
          </Styles.PortfolioWrapper>
        )}
        {showRemoveButton && (
          <Styles.RemoveCompanyButton
            onClick={(event) => {
              event.stopPropagation()
              onRemoveCompany?.(subject)
            }}
            type="button"
          >
            <FontAwesomeIcon size="5x" icon={['fas', 'times']} />
          </Styles.RemoveCompanyButton>
        )}

        <Styles.ImageWrapper>
          <Avatar
            image={subject.logo || subject.parentSubject?.logo}
            initials={getInitials(subject.name)}
            avatarStyle="avatarCircleLarge"
          />
        </Styles.ImageWrapper>

        <div>
          <Styles.CompanyName
            level="h4"
            title={subject.name}
            className="holding-name"
          >
            {subject.name}
          </Styles.CompanyName>

          {!!location && (
            <Styles.LegendContainer title={location}>
              <FontAwesomeIcon icon={['far', 'map-marker-alt']} />
              {location}
            </Styles.LegendContainer>
          )}
        </div>

        {(isDeal || isFund) && !!fundCompany && (
          <Styles.LegendContainer title={fundCompany.name}>
            <FontAwesomeIcon icon={['far', 'user-tie']} />
            {fundCompany.name}
          </Styles.LegendContainer>
        )}

        <SubjectStatus subject={subject} displayLastUpdate />
      </Styles.CardWrapper>
    </motion.li>
  )
}

export default HoldingSubjectCard
