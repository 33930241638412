import ChipList from 'containers/Files/components/ChipList'
import { useMetricsSubjectQuery } from 'utils/hooks/useMetricsSubjectQuery'
import {
  getPortfolioTypeBySubjectType,
  IndexMetric,
} from 'utils/types/metricsV2'

interface MetricsChipListProps {
  metric: IndexMetric
}

const MetricsChipList = ({ metric }: MetricsChipListProps) => {
  const { data: subject } = useMetricsSubjectQuery(
    {
      subjectId: metric.subject.id,
      subjectType: metric.subject.type,
    },
    {
      staleTime: 1 * 1000 * 60,
    }
  )

  const entityType = getPortfolioTypeBySubjectType(metric.subject.type)

  if (!subject) {
    return null
  }

  return (
    <ChipList
      key={'metrics-chip-list-'.concat(metric.id)}
      entities={[
        {
          id: subject.id,
          name: subject.name,
          type: entityType,
          primaryLogo: subject.primaryLogo,
        },
      ]}
      tooltipContentType="list"
    />
  )
}

export default MetricsChipList
