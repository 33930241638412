/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useIntl } from 'react-intl'
import { Portfolio } from 'utils/types/portfolios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import useUserData from 'utils/hooks/useUserData'
import {
  isActingAsFundManager,
  getCurrentGroupData,
  getUserGroups,
  isActingAsFounder,
} from 'selectors/auth'
import Title from 'components/Title'
import Checkbox from 'components/Checkbox'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'
import EditPermissionsLoader from 'components/loaders/editPermissionsLoader'
import {
  PermissionItem,
  UpdateVisibilityType,
  UpdatePermissions,
  UpdateType,
  Nullable,
  PermissionItemType,
} from 'utils/types/common'
import UpdateCardBox from 'containers/Updates/UpdateCardBox/UpdateCardBox'
import { AppGroup } from 'utils/types/user'
import {
  isCurrentCompanyFundProfile,
  isCurrentCompanyPrivate,
} from 'selectors/company'
import useSettings from 'utils/hooks/useSettings'
import {
  isSharableOutsideGroupDeprecated,
  UpdateTypes,
  UpdateVisibility,
} from 'utils/constants/updates'

import { CUSTOM, CUSTOM_GROUP } from 'utils/functions/updates'
import CustomPermissions from './components/CustomPermissions'
import CheckboxTextContent from './components/CheckboxTextContent'
import styles from './SetUpdatePermissions.module.scss'

const removeItem = (itemId, state) => state?.filter?.((id) => id !== itemId)

export interface SetUpdatePermissionsProps {
  baseVisibility?: string
  groupCreatedBy?: string
  initialItems?: PermissionItem[]
  loading?: boolean
  setSharedGroups: React.Dispatch<React.SetStateAction<string[]>>
  setSharedUsers: React.Dispatch<React.SetStateAction<string[]>>
  sharedUsers: string[]
  setVisibility?: React.Dispatch<React.SetStateAction<UpdateVisibilityType>>
  setOwnByGroup?: React.Dispatch<React.SetStateAction<boolean>>
  ownByGroup?: boolean
  visibility?: UpdateVisibilityType
  setValue?: (key: string, value: any) => void
  forceCurrentGroupSelected?: boolean
  itemToAdd?: PermissionItem
  onlyCustomPermissions?: boolean
  onlyCustomPermissionsText?: string
  onCardExpanded: () => void
  isCardExpanded?: boolean
  onlyMeHidden?: boolean
  groupOwnedDisabled?: boolean
  isResharingUpdate?: boolean
  isCreatingUpdate?: boolean
  updatePermissions?: UpdatePermissions
  portfolios?: Portfolio[]
  updateType?: UpdateType
  isPortfolioUpdate?: boolean
  isFundPortfolioUpdate?: boolean
  isInvestorUpdate?: boolean
}

const SetUpdatePermissions: React.FC<SetUpdatePermissionsProps> = ({
  baseVisibility,
  groupCreatedBy,
  initialItems,
  loading,
  setSharedGroups,
  setSharedUsers,
  setVisibility,
  setOwnByGroup,
  ownByGroup,
  sharedUsers,
  visibility,
  onCardExpanded,
  isCardExpanded,
  setValue,
  forceCurrentGroupSelected,
  itemToAdd,
  onlyCustomPermissions,
  onlyCustomPermissionsText,
  updatePermissions,
  onlyMeHidden,
  groupOwnedDisabled,
  portfolios,
  isResharingUpdate,
  isCreatingUpdate,
  updateType,
  isPortfolioUpdate,
  isFundPortfolioUpdate,
  isInvestorUpdate,
}) => {
  const intl = useIntl()
  const { isCurrentUserActingAsFounder, isFounderVerified } = useUserData()
  const { settings } = useSettings()

  const currentUserGroup = useAppSelector(getCurrentGroupData)
  const userGroups = useAppSelector(getUserGroups)
  const isCurrentGroupFounder = useAppSelector(isActingAsFounder)
  const isCurrentGroupFundManager = useAppSelector(isActingAsFundManager)

  const location = useLocation()

  const [items, setItems] = useState<
    (PermissionItem & {
      canDelete?: boolean
      listId?: string
      listName?: string
    })[]
  >([])

  /* 
      ownedByGroup is used to set the group id of the owner of the update 
      in order to disable the removal of the group from the permissions
    */
  const [ownedByGroup, setOwnedByGroup] = useState<Nullable<string>>('')

  const currentCompanyPrivate = useAppSelector(isCurrentCompanyPrivate)
  const currentCompanyIsFundCompany = useAppSelector(
    isCurrentCompanyFundProfile
  )

  const isAddingUpdateFromPortfolio =
    location.pathname.indexOf('portfolios') >= 0
  const hasAtLeastOnePortfolio = !!portfolios?.length && portfolios.length > 0

  const isSharableOutsideTheGroup = isSharableOutsideGroupDeprecated(
    isPortfolioUpdate && !isFundPortfolioUpdate,
    location.pathname.includes('updates'),
    location.pathname.includes('companies'),
    updateType
  )

  const isIUEUpdate = updateType === UpdateTypes.IUE

  const isCustom = !!(isSharableOutsideTheGroup || currentCompanyPrivate)
  const isCustomOnPortfolio =
    !isFundPortfolioUpdate &&
    !isSharableOutsideTheGroup &&
    !currentCompanyPrivate &&
    !isInvestorUpdate

  const handleAddGroup = (group: AppGroup) => {
    const newItem: PermissionItem = {
      id: group.id,
      name: group.name,
      logo: group.logo,
      type: PermissionItemType.GROUP,
      canDelete: true,
    }

    setSharedGroups((state) => {
      if (!state.includes(group.id)) {
        return [group.id, ...state]
      }
      return state
    })

    setItems((state) => {
      if (!state.find((item) => item.id === group.id)) {
        return [newItem, ...state]
      }

      return state
    })
  }

  const selectDefaultGroup = () => {
    handleAddGroup(currentUserGroup!)
    setOwnByGroup?.(true)
    setOwnedByGroup(currentUserGroup!.id)
    setVisibility?.(UpdateVisibility.CUSTOM)
  }

  useEffect(() => {
    if (itemToAdd) {
      setItems((currItems) => {
        return [...currItems, itemToAdd]
      })
    }
  }, [itemToAdd])

  useEffect(() => {
    if (
      !isResharingUpdate &&
      isAddingUpdateFromPortfolio &&
      updateType === UpdateTypes.TRANSACTION
    ) {
      setVisibility!(UpdateVisibility.CUSTOM)
      handleAddGroup(currentUserGroup!)
    }
  }, [isAddingUpdateFromPortfolio])

  useEffect(() => {
    setItems(
      initialItems?.map?.((item) => ({
        ...item,
        canDelete: false,
      })) ?? []
    )
  }, [initialItems, isCurrentGroupFounder])

  useEffect(() => {
    setOwnedByGroup('')
    if (hasAtLeastOnePortfolio) {
      selectDefaultGroup()
    }
  }, [hasAtLeastOnePortfolio])

  useEffect(() => {
    if (ownByGroup) {
      setOwnedByGroup(currentUserGroup!.id)
    } else {
      setOwnedByGroup('')
    }
  }, [ownByGroup])

  const handleChangeCheckbox = ({ target: { name } }) => {
    setVisibility!(name)

    if (
      name === UpdateVisibility.CUSTOM &&
      (currentCompanyPrivate || isAddingUpdateFromPortfolio)
    ) {
      handleAddGroup(currentUserGroup!)
    }

    if (name === UpdateVisibility.ONLY_ME) {
      if (setValue) {
        setValue('ownByGroup', false)
      }
      if (setOwnByGroup) {
        setOwnByGroup(false)
      }
    }
  }

  const handleAddUser = (user) => {
    if (!sharedUsers.includes(user.id)) {
      const newItem: PermissionItem = {
        id: user.id,
        name: user.name || user.email,
        logo: user.logo,
        type: user.name ? PermissionItemType.USER : PermissionItemType.EMAIL,
        canDelete: true,
      }
      setItems((state) => [newItem, ...state])
      setSharedUsers((state) => [user.id, ...state])
    }
  }

  const handleBulkAddUsers = (users) => {
    const usersToAdd = users
      .filter((user) => !sharedUsers.includes(user.id))
      .map((user) => ({
        id: user.id,
        name: user.name,
        logo: user.logo,
        type: PermissionItemType.USER,
      }))
    setItems((state) => [...usersToAdd, ...state])
    setSharedUsers((state) => [...usersToAdd.map((user) => user.id), ...state])
  }

  const onChangeOwnedByGroup = () => {
    const isChecked = (
      document.getElementById('ownByGroup') as HTMLInputElement
    )?.checked

    if (isChecked) {
      setOwnedByGroup((state) =>
        state ? null : groupCreatedBy || currentUserGroup!.id
      )
      handleAddGroup(userGroups[groupCreatedBy!] || currentUserGroup)

      if (isAddingUpdateFromPortfolio && !isFundPortfolioUpdate) {
        setVisibility!(UpdateVisibility.CUSTOM)
      }
    } else {
      setOwnedByGroup('')
      setItems((currItems) => {
        return currItems.map((item) => {
          if (item.id === currentUserGroup!.id) {
            return { ...item, canDelete: true }
          }
          return item
        })
      })
    }
    if (setOwnByGroup) {
      setOwnByGroup(isChecked)
    }
  }

  const handleDelete = (itemId: string) => {
    setItems((state) => state.filter(({ id }) => id !== itemId))
    setSharedGroups((state) => removeItem(itemId, state))
    setSharedUsers((state) => removeItem(itemId, state))
  }

  const handleEditItemList = ({
    id,
    listId,
    listName,
  }: {
    id: string
    listId: string
    listName: string
  }) => {
    setItems((state) =>
      state.map((item) =>
        item.id === id ? { ...item, listId, listName } : item
      )
    )
  }

  const isCustomEnabled = () => {
    if (!updatePermissions || updatePermissions.canDowngradePermissions) {
      return true
    }
    return baseVisibility !== UpdateVisibility.PUBLIC
  }

  const isOnlyMeEnabled = () => {
    if (hasAtLeastOnePortfolio) return false

    if (!updatePermissions) return true

    if (updatePermissions.canDowngradePermissions) {
      return !onlyMeHidden
    }
    return !onlyMeHidden && baseVisibility === UpdateVisibility.ONLY_ME
  }

  useEffect(() => {
    if (forceCurrentGroupSelected) {
      handleAddGroup(userGroups![groupCreatedBy!] || currentUserGroup)
    }
  }, [forceCurrentGroupSelected])

  useEffect(() => {
    if (
      (isInvestorUpdate || settings?.updatesPermissions?.groupOwned) &&
      setOwnByGroup &&
      isCreatingUpdate &&
      isFounderVerified
    ) {
      selectDefaultGroup()
    }
  }, [settings?.updatesPermissions?.groupOwned, isInvestorUpdate])

  if (isCardExpanded) {
    return (
      <UpdateCardBox shrink id="permissions-box">
        <FontAwesomeIcon
          onClick={onCardExpanded}
          className={styles.leftChevron}
          icon="chevron-left"
        />
        <div className={styles.iconsContainer}>
          <FontAwesomeIcon className={styles.lockIcon} icon="lock-alt" />
          <FontAwesomeIcon className={styles.lockIcon} icon={['fas', 'tag']} />
        </div>
      </UpdateCardBox>
    )
  }

  if (onlyCustomPermissions) {
    return (
      <UpdateCardBox id="permissions-box">
        <Title
          title={intl.formatMessage({ id: 'updates.permissions' })}
          icon={['far', 'lock-alt']}
        />
        {onlyCustomPermissionsText && (
          <p className={styles.customPermissionsText}>
            {onlyCustomPermissionsText}
          </p>
        )}
        <CustomPermissions
          handleAddGroup={handleAddGroup}
          handleAddUser={handleAddUser}
          handleBulkAddUsers={handleBulkAddUsers}
          handleDelete={handleDelete}
          items={items}
          ownedByGroup={ownedByGroup}
          handleEditItemList={handleEditItemList}
        />
      </UpdateCardBox>
    )
  }

  const renderOwnedByGroupCheckbox = () => {
    return (
      <Checkbox
        id="ownByGroup"
        name="ownByGroup"
        onChange={onChangeOwnedByGroup}
        label={intl.formatMessage(
          { id: 'updates.groupManage' },
          {
            name: groupCreatedBy
              ? userGroups?.[groupCreatedBy].name
              : currentUserGroup?.name,
          }
        )}
        checked={ownByGroup}
        disabled={
          updatePermissions?.groupOwnedDisabled ||
          groupOwnedDisabled ||
          isInvestorUpdate
        }
      />
    )
  }

  const showPublicOption =
    (currentCompanyIsFundCompany && updateType !== UpdateTypes.TRANSACTION) ||
    (isCurrentGroupFounder && !currentCompanyPrivate) ||
    (isCurrentGroupFundManager &&
      isFundPortfolioUpdate &&
      updateType !== UpdateTypes.TRANSACTION)

  return (
    <UpdateCardBox id="permissions-box">
      <Title
        title={intl.formatMessage({ id: 'updates.permissions' })}
        icon={['far', 'lock-alt']}
      />

      {loading ? (
        <EditPermissionsLoader />
      ) : (
        <div className={styles.checkboxContainer}>
          {/* Only me */}
          {!isInvestorUpdate &&
            !isIUEUpdate &&
            (!updatePermissions || updatePermissions?.setAsDraftVisible) && (
              <Checkbox
                id={UpdateVisibility.ONLY_ME}
                name={UpdateVisibility.ONLY_ME}
                onChange={handleChangeCheckbox}
                mode="custom"
                checked={visibility === UpdateVisibility.ONLY_ME}
                disabled={!isOnlyMeEnabled()}
              >
                <CheckboxTextContent
                  type={UpdateVisibility.ONLY_ME}
                  icon={['far', 'user']}
                  checked={visibility === UpdateVisibility.ONLY_ME}
                />
              </Checkbox>
            )}

          {/* Public */}
          {showPublicOption && (
            <>
              <ReadOnlyTooltip
                place="left"
                text={intl.messages['updates.readOnlyPublic']}
                disabled={!isFounderVerified && isCurrentUserActingAsFounder}
              >
                <Checkbox
                  className="permission-checkbox"
                  id={UpdateVisibility.PUBLIC}
                  name={UpdateVisibility.PUBLIC}
                  onChange={handleChangeCheckbox}
                  mode="custom"
                  checked={visibility === UpdateVisibility.PUBLIC}
                >
                  <CheckboxTextContent
                    type={UpdateVisibility.PUBLIC}
                    icon={['far', 'users']}
                    checked={visibility === UpdateVisibility.PUBLIC}
                  />
                </Checkbox>
              </ReadOnlyTooltip>
              {visibility === UpdateVisibility.PUBLIC && (
                <div className={styles.publicGroupVisibilityWrapper}>
                  {renderOwnedByGroupCheckbox()}
                </div>
              )}
            </>
          )}

          {/* Custom */}
          {(isFundPortfolioUpdate || isCustom || isInvestorUpdate) && (
            <ReadOnlyTooltip
              place="left"
              text={intl.messages['updates.readOnlyCustom']}
              disabled={!isFounderVerified && isCurrentUserActingAsFounder}
            >
              {({ disabled: tooltipDisabled }) => (
                <Checkbox
                  className="permission-checkbox"
                  id={UpdateVisibility.CUSTOM}
                  name={UpdateVisibility.CUSTOM}
                  onChange={handleChangeCheckbox}
                  mode="custom"
                  checked={visibility === UpdateVisibility.CUSTOM}
                  disabled={tooltipDisabled || !isCustomEnabled()}
                >
                  {!isFundPortfolioUpdate &&
                  (currentCompanyPrivate || isInvestorUpdate) ? (
                    <CheckboxTextContent
                      type={UpdateVisibility.CUSTOM_GROUP}
                      name={currentUserGroup?.name}
                      checked={visibility === UpdateVisibility.CUSTOM}
                    />
                  ) : (
                    <CheckboxTextContent
                      type={UpdateVisibility.CUSTOM}
                      icon={['far', 'user-lock']}
                      checked={visibility === UpdateVisibility.CUSTOM}
                    />
                  )}
                </Checkbox>
              )}
            </ReadOnlyTooltip>
          )}

          {/* Custom on portfolio */}
          {isCustomOnPortfolio && !isInvestorUpdate && (
            <Checkbox
              className="permission-checkbox"
              id={CUSTOM}
              name={CUSTOM}
              onChange={handleChangeCheckbox}
              mode="custom"
              checked={visibility === CUSTOM}
            >
              <CheckboxTextContent
                type={CUSTOM_GROUP}
                name={currentUserGroup?.name}
                checked={visibility === CUSTOM}
              />
            </Checkbox>
          )}

          {visibility === UpdateVisibility.CUSTOM &&
            !currentCompanyPrivate &&
            isSharableOutsideTheGroup &&
            !isInvestorUpdate && (
              <div className={styles.customPermissionsWrapper}>
                <CustomPermissions
                  handleAddGroup={handleAddGroup}
                  handleAddUser={handleAddUser}
                  handleBulkAddUsers={handleBulkAddUsers}
                  handleDelete={handleDelete}
                  items={items}
                  ownedByGroup={ownedByGroup}
                  handleEditItemList={handleEditItemList}
                />
              </div>
            )}

          {(visibility === UpdateVisibility.CUSTOM || isInvestorUpdate) && (
            <div
              className={classNames({
                [styles.customPermissionsWrapper]: !!isInvestorUpdate,
                [styles.customPermissionsContentWrapper]:
                  !currentCompanyPrivate && !isInvestorUpdate,
                [styles.publicGroupVisibilityWrapper]: currentCompanyPrivate,
              })}
            >
              {renderOwnedByGroupCheckbox()}
            </div>
          )}
        </div>
      )}
    </UpdateCardBox>
  )
}

SetUpdatePermissions.defaultProps = {
  baseVisibility: UpdateVisibility.ONLY_ME,
  setVisibility: undefined,
  initialItems: [],
  loading: false,
  setValue: () => {},
  setOwnByGroup: undefined,
  ownByGroup: undefined,
  visibility: UpdateVisibility.ONLY_ME,
  isCardExpanded: false,
  forceCurrentGroupSelected: false,
  itemToAdd: undefined,
  onlyMeHidden: false,
  onlyCustomPermissions: false,
  isCreatingUpdate: false,
  isInvestorUpdate: false,
  onlyCustomPermissionsText: '',
  groupCreatedBy: '',
  portfolios: [],
}

export default SetUpdatePermissions
