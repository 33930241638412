import CreateEmail from 'containers/CreateEmail'
import { lazy } from 'react'
import { Redirect, Route, useRouteMatch } from 'react-router-dom'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'
import { RouteType } from 'routes/types/common'
import useRoute from './hooks/useRoutes'
import SubjectIdRoutes from './SubjectIdRoutes'

const NotesRoutes = lazy(() => import('./NotesRoutes'))
const AccountingRoutes = lazy(() => import('./AccountingRoutes'))
const DocumentsRoutes = lazy(() => import('./DocumentsRoutes'))
const AnnouncementsRoutes = lazy(() => import('./AnnouncementsRoutes'))
const TransactionsRoutes = lazy(() => import('./TransactionsRoutes'))
const UpdatesView = lazy(() => import('containers/UpdatesView'))
const CompanyProfileRouteWrapper = lazy(
  () => import('containers/Profiles/CompanyProfile/CompanyProfileRouteWrapper')
)
const FundHoldingProfile = lazy(
  () => import('containers/Profiles/FundHoldingProfile')
)

const EmailsRoutes = lazy(() => import('./EmailsRoutes'))

const UpdatesRoutes = () => {
  const { path: subPath } = useRouteMatch()
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const { getPaths, renderPaths } = useRoute()

  const paddedRoutes: RouteType[] = [
    {
      path: `${subPath}/announcements`,
      key: 'announcements',
      component: <AnnouncementsRoutes />,
    },
    {
      path: `${subPath}/notes`,
      key: 'notes',
      component: <NotesRoutes />,
    },
    {
      path: `${subPath}/documents`,
      key: 'documents',
      component: <DocumentsRoutes />,
    },
    {
      path: `${subPath}/emails`,
      key: 'emails',
      component: <EmailsRoutes />,
    },
    {
      path: `${subPath}/accounting`,
      key: 'accounting',
      component: <AccountingRoutes />,
    },
    {
      path: `${subPath}/transactions`,
      key: 'transactions',
      component: <TransactionsRoutes />,
    },
    {
      path: subPath,
      key: 'updates',
      exact: true,
      component: isInvestorGroup ? <Redirect to="" /> : <UpdatesView />,
    },
  ].filter((route: RouteType) => !route.hidden)

  const nonPaddedRoutes: RouteType[] = [
    {
      path: `${subPath}/drafts/:updateId`,
      key: 'emailId',
      component: <CreateEmail />,
      exact: true,
    },
    {
      path: `${subPath}/companies/:companyId`,
      key: 'companyId',
      component: <CompanyProfileRouteWrapper />,
      exact: true,
    },
    {
      path: `${subPath}/funds/:fundId`,
      key: 'fundId',
      component: <FundHoldingProfile />,
      exact: true,
    },
  ]

  return (
    <Route path={[...getPaths(nonPaddedRoutes), ...getPaths(paddedRoutes)]}>
      <SubjectIdRoutes>
        <Route path={getPaths(nonPaddedRoutes)}>
          {renderPaths({ routes: nonPaddedRoutes, addPaddedLayout: false })}
        </Route>

        <Route path={getPaths(paddedRoutes)}>
          {renderPaths({ routes: paddedRoutes, addPaddedLayout: true })}
        </Route>
      </SubjectIdRoutes>
    </Route>
  )
}
export default UpdatesRoutes
