import { useEffect, useMemo } from 'react'
import {
  ApolloError,
  useApolloClient,
  type DocumentNode,
  type TypedDocumentNode,
} from '@apollo/client'

import {
  BasicSubjectFragment,
  FullSubjectFragment,
  SortDirection,
  SubjectsQuery,
} from 'types/graphql-schemas/graphql'
import { GET_FULL_SUBJECTS, GET_SUBJECTS } from 'utils/gql/queries/subjects'
import { SubjectType } from 'utils/types/subjects'
import { subjectsKeys } from 'utils/queries/subjects'
import { useInfiniteQuery } from '@tanstack/react-query'

export const PAGE_SIZE = 35

export type SubjectFilter = {
  id?: string | string[]
  idNotIn?: string[]
  railsId?: string | string[]
  railsIdNotIn?: string[]
  name?: string
  type?: SubjectType | SubjectType[]
  [x: string]: any
}

export type SubjectQueryProps = {
  filters: SubjectFilter
  sorting?: { orderBy?: string; direction?: SortDirection }
  pageSize?: number
  fetchAll?: boolean
}

export type QueryOptions = {
  skip?: boolean
  shouldSetCurrentCompany?: boolean
  onCompleted?: (data: SubjectsQuery) => void
  onError?: (error: ApolloError) => void
}

export const getSubjectTypeFilter = (type?: SubjectType | SubjectType[]) => {
  if (type?.length) {
    return {
      $in: type,
    }
  }
  if (type) {
    return type
  }
  return undefined
}

export const getFilters = (filters: SubjectFilter) => {
  const {
    name = '',
    type = [],
    id,
    idNotIn,
    railsId,
    railsIdNotIn,
    ...otherFilters
  } = filters
  const query: any = {
    railsId: Array.isArray(railsId) ? { $in: railsId } : railsId,
    name: name ? { $regex: name, $options: 'i' } : undefined,
    type: getSubjectTypeFilter(filters.type),
    ...otherFilters,
  }

  if (id) {
    query._id = Array.isArray(id) ? { $in: id } : id
  } else if (idNotIn) {
    query._id = { $nin: idNotIn }
  }

  if (railsId) {
    query.railsId = Array.isArray(railsId) ? { $in: railsId } : railsId
  } else if (railsIdNotIn) {
    query.railsId = { $nin: railsIdNotIn }
  }

  return JSON.stringify(query)
}

function useRawSubjectsQuery<
  TFragment extends BasicSubjectFragment | FullSubjectFragment,
>(
  {
    filters,
    sorting,
    pageSize = PAGE_SIZE,
    fetchAll = false,
  }: SubjectQueryProps,
  { skip = false, onCompleted, onError }: QueryOptions = {},
  query:
    | DocumentNode
    | TypedDocumentNode<
        TFragment,
        {
          skip: number
          query: string
          limit: number
          sortBy: string | undefined
          sortDirection: SortDirection
        }
      > = GET_SUBJECTS
) {
  const apolloClient = useApolloClient()

  const queryVariables = useMemo(() => {
    return {
      query: getFilters(filters ?? {}),
      skip: 0,
      limit: pageSize,
      sortBy: sorting?.orderBy,
      sortDirection: sorting?.direction?.toUpperCase() as SortDirection,
    }
  }, [filters, pageSize, sorting?.direction, sorting?.orderBy])

  const {
    data,
    error,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery<TFragment[]>({
    enabled: !skip,
    queryKey: subjectsKeys.byFilters({ filters, sorting }),
    queryFn: async (params) => {
      const response = await apolloClient.query<{ subjects: TFragment[] }>({
        query,
        variables: {
          ...queryVariables,
          skip: params.pageParam * pageSize,
        },
      })

      return response.data.subjects
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length < pageSize) {
        return undefined
      }
      return pages.length
    },
    onSuccess: (response) => {
      onCompleted?.({
        subjects: response?.pages?.flatMap((page) => page),
      })
    },
    onError,
  })

  useEffect(() => {
    if (fetchAll) {
      if (hasNextPage && !isFetching) {
        fetchNextPage()
      }
    }
  }, [fetchAll, isFetching, hasNextPage, fetchNextPage])

  return {
    data,
    error,
    isLoading,
    isFetchingNextPage,
    fetchMore: fetchNextPage,
    refetch,
  }
}
export const useSubjectsQuery = (
  params: SubjectQueryProps,
  options: QueryOptions = {}
) => {
  return useRawSubjectsQuery<BasicSubjectFragment>(params, options)
}
export const useFullSubjectsQuery = (
  params: SubjectQueryProps,
  options: QueryOptions = {}
) => {
  return useRawSubjectsQuery<FullSubjectFragment>(
    params,
    options,
    GET_FULL_SUBJECTS
  )
}
