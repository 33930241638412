import { useCallback, useMemo } from 'react'
import { ReactFCWithChildren } from 'utils/types/common'
import { CHART_MODE } from 'components/Charts/MultiViewChart/types'
import {
  MAX_SANDBOX_METRICS,
  MetricSandboxContext,
  MetricSandboxContextType,
} from 'containers/Metrics/SelectedMetrics/useMetricsSandbox'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import {
  getSelectedMetricsByHoldingId,
  onClearHoldingMetrics,
  onAddMetric,
  onRemoveMetric,
} from 'reducers/selectedMetricsSlice'
import useMetricsColor from 'containers/Metrics/SelectedMetrics/useMetricsColor'
import { IndexMetric } from 'utils/types/metricsV2'
import { getSelectedMetricsProfileCards } from 'utils/functions/metricsCard'
import { MetricSortBy } from 'utils/constants/metrics'
import { SortDirection } from 'utils/constants'

export const ProfileMetricsContextProvider: ReactFCWithChildren<{
  holdingId: string
}> = ({ children, holdingId }) => {
  const allMetrics = useAppSelector(getSelectedMetricsByHoldingId(holdingId))
  const dispatch = useAppDispatch()

  const handleOnAddMetric = useCallback(
    (metric: IndexMetric) => {
      dispatch(onAddMetric({ holdingId, metric }))
    },
    [holdingId, dispatch]
  )

  const handleOnRemoveMetric = useCallback(
    (metric: IndexMetric) => {
      dispatch(onRemoveMetric({ holdingId, metric }))
    },
    [holdingId, dispatch]
  )

  const clearSandbox = useCallback(() => {
    dispatch(onClearHoldingMetrics(holdingId))
  }, [holdingId, dispatch])

  const {
    selectedMetrics,
    isMetricInSandbox,
    changeMetricVisibility,
    getMetricColor,
    isMetricVisible,
  } = useMetricsColor({
    metrics: allMetrics,
  })

  const hasSelectedMetrics = selectedMetrics.length > 0

  const selectedMetricsCount = useMemo(
    () => selectedMetrics.length,
    [selectedMetrics]
  )

  const isAddMetricToSandboxDisabled = useCallback(() => {
    return selectedMetrics.length >= MAX_SANDBOX_METRICS
  }, [selectedMetrics.length])

  const toggleMetricInSandbox = useCallback(
    (metric: IndexMetric) => {
      if (isMetricInSandbox(metric)) {
        dispatch(onRemoveMetric({ holdingId, metric }))
      } else {
        dispatch(onAddMetric({ holdingId, metric }))
      }
    },
    [dispatch, holdingId, isMetricInSandbox]
  )

  const metricsForCars = useMemo(() => {
    return selectedMetrics.map((selected) => selected.metric)
  }, [selectedMetrics])

  const metricsCards = useMemo(
    () =>
      metricsForCars
        ? getSelectedMetricsProfileCards(metricsForCars, {
            sortBy: MetricSortBy.NAME,
            direction: SortDirection.ASC,
          }).sort((a, b) => a.name.localeCompare(b.name))
        : [],
    [metricsForCars]
  )

  const value: MetricSandboxContextType = useMemo(
    () => ({
      selectedMetrics,
      hasSelectedMetrics,
      metricsCards,
      hasMetrics: true,
      selectedMetricsCount,
      defaultChartMode: CHART_MODE.LINE,
      clearSandbox,
      getMetricColor,
      isAddMetricToSandboxDisabled,
      changeMetricVisibility,
      isMetricVisible,
      isMetricInSandbox,
      removeMetrics: clearSandbox,
      onAddMetric: handleOnAddMetric,
      onRemoveMetric: handleOnRemoveMetric,
      toggleMetricInSandbox,
    }),
    [
      selectedMetrics,
      hasSelectedMetrics,
      selectedMetricsCount,
      metricsCards,
      clearSandbox,
      getMetricColor,
      isAddMetricToSandboxDisabled,
      changeMetricVisibility,
      isMetricVisible,
      isMetricInSandbox,
      handleOnAddMetric,
      handleOnRemoveMetric,
      toggleMetricInSandbox,
    ]
  )

  return (
    <MetricSandboxContext.Provider value={value}>
      {children}
    </MetricSandboxContext.Provider>
  )
}
