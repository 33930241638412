import { useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import { FullSubjectFragment } from 'types/graphql-schemas/graphql'

import Toast from 'components/Toast'
import debounce from 'lodash/debounce'
import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { getAttribute } from 'utils/gql/helpers/subjects'
import { UPDATE_SUBJECT } from 'utils/gql/mutations/subjects'
import { useSubjectQuery } from 'utils/hooks/queries/subjects/useSubjectQuery'
import useInitialData from 'utils/hooks/useInitialData'
import { Nullable } from 'utils/types/common'
import { Employee } from 'utils/types/company'
import NumbersService from 'api/NumbersService'
import { PUBLIC_PERMISSION } from 'utils/gql/helpers/permissions'

export interface CompanyEmployee extends Employee {
  hidden?: boolean
}

const useCompanyProfileDrawer = () => {
  const [selectedCompany, setSelectedCompany] = useState<Nullable<string>>(null)
  const [image, setImage] = useState(null)

  const [subjectData, setSubjectData] = useState<FullSubjectFragment | null>(
    null
  )

  const {
    data,
    isLoading: loadingSubject,
    error,
    refetch,
  } = useSubjectQuery(selectedCompany!, {
    skip: !selectedCompany,
    onCompleted: (queryData) => {
      setSubjectData(queryData.subject)
    },
  })

  const subject = data?.subject

  const [updateSubject] = useMutation(UPDATE_SUBJECT)

  const { loading, initialData } = useInitialData()

  const intl = useIntl()

  const refetchSubject = useCallback(async () => {
    const response = await refetch()
    setSubjectData(response.data?.subject!)
  }, [refetch])

  const onEditField = useMemo(
    () =>
      debounce(
        async (attributeName: string, value: any, shouldRefetch = true) => {
          try {
            await updateSubject({
              variables: {
                id: selectedCompany!,
                data: {
                  attributes: [
                    {
                      name: attributeName,
                      operation: 'update',
                      value,
                    },
                  ],
                },
              },
            })

            if (shouldRefetch) {
              refetchSubject()
            }
          } catch (errors) {
            Toast.display(
              intl.formatMessage(
                { id: 'errors.editItemError' },
                { name: '', item: attributeName }
              ),
              'error'
            )
          }
        },
        800
      ),
    [intl, refetchSubject, selectedCompany, updateSubject]
  )

  const setAttribute = (
    key: string,
    value: any,
    {
      shouldSubmit = true,
      shouldRefetch = true,
    }: { shouldSubmit?: boolean; shouldRefetch?: boolean } = {}
  ) => {
    setSubjectData((prev) => ({
      ...prev!,
      attributes: prev!.attributes.map((attr) => {
        if (attr.name === key) {
          return {
            ...attr,
            value,
          }
        }
        return attr
      }),
    }))

    if (shouldSubmit) {
      onEditField(key, value, shouldRefetch)
    }
  }

  const setMainAndCustomAttribute = useMemo(
    () =>
      debounce(
        async (params: {
          mainKey: string
          mainValue: any
          customKey: string
          customValue: any
        }) => {
          setSubjectData((prev) => ({
            ...prev!,
            [params.mainKey]: params.mainValue,
            attributes: prev!.attributes.map((attr) => {
              if (attr.name === params.customKey) {
                return {
                  ...attr,
                  value: params.customValue,
                }
              }
              return attr
            }),
          }))

          try {
            await updateSubject({
              variables: {
                id: selectedCompany!,
                data: {
                  [params.mainKey]: params.mainValue,
                  attributes: [
                    {
                      name: params.customKey,
                      operation: 'update',
                      value: params.customValue,
                    },
                  ],
                },
              },
            })

            if (params.mainKey === 'name') {
              // TODO: review if this is correct
              await NumbersService.updateMetrics({
                subjectId: selectedCompany!, // Or railsId? I'm guessing it's railsId if it's an "old" metric, and subjectId if it was created after the migration, but how can we know that? Maybe we need to update both
                newName: params.mainValue,
              })
            }
            refetchSubject()
          } catch (errors) {
            Toast.display(
              intl.formatMessage(
                { id: 'errors.editItemError' },
                { name: '', item: params.customKey }
              ),
              'error'
            )
          }
        },
        800
      ),
    [intl, refetchSubject, selectedCompany, updateSubject]
  )

  const onChangeHidden = useMemo(
    () =>
      debounce(async (value: boolean) => {
        try {
          await updateSubject({
            variables: {
              id: selectedCompany!,
              data: {
                permissions: [
                  {
                    entityId: PUBLIC_PERMISSION,
                    read: !value,
                    write: false,
                  },
                ],
              },
            },
          })
          refetchSubject()
        } catch (errors) {
          Toast.display(
            intl.formatMessage({ id: 'errors.editHiddenError' }),
            'error'
          )
        }
      }, 800),
    [intl, refetchSubject, selectedCompany, updateSubject]
  )

  const setMainAttribute = useMemo(
    () =>
      debounce(async (attributeName: string, value: any) => {
        try {
          await updateSubject({
            variables: {
              id: selectedCompany!,
              data: {
                [attributeName]: value,
              },
            },
          })
          refetchSubject()
        } catch (errors) {
          Toast.display(
            intl.formatMessage(
              { id: 'errors.editItemError' },
              { name: '', item: attributeName }
            ),
            'error'
          )
        }
      }, 800),
    [intl, refetchSubject, selectedCompany, updateSubject]
  )

  const handleChangeEmployees = (employees: CompanyEmployee[]) => {
    setAttribute('employees', employees)
  }

  const onAddNewTeamMember = async (employeeData: CompanyEmployee) => {
    const employees =
      getAttribute<CompanyEmployee[]>(subjectData, 'employees')?.value ?? []
    setAttribute('employees', [...employees, { ...employeeData, id: uuidv4() }])
  }

  const toggleHideTeamMember = (employeeId: string, hidden: boolean) => {
    const employees =
      getAttribute<CompanyEmployee[]>(subjectData, 'employees')?.value ?? []

    const newEmployees = employees.map((emp) => {
      if (emp.id === employeeId) {
        return { ...emp, hidden }
      }
      return emp
    })
    setAttribute('employees', newEmployees, {
      shouldRefetch: false,
    })
  }

  const onEditTeamMember = async (
    employeeId: string,
    employeeData: CompanyEmployee
  ) => {
    const employees =
      getAttribute<CompanyEmployee[]>(subjectData, 'employees')?.value ?? []
    const newEmployees = employees.map((emp) => {
      if (emp.id === employeeId) {
        return employeeData
      }
      return emp
    })
    setAttribute('employees', newEmployees)
  }

  const onDeleteTeamMember = (deletedEmployee: CompanyEmployee) => {
    const employees =
      getAttribute<CompanyEmployee[]>(subjectData, 'employees')?.value ?? []
    const newEmployees = employees.filter(
      (emp) => emp.id !== deletedEmployee.id
    )
    setAttribute('employees', newEmployees)
  }

  const onSaveImage = async () => {
    setMainAttribute('logo', image!)
  }

  const onSelectImage = (file) => {
    setImage(file)
  }

  return {
    subject,
    initialData,
    isLoading: loading || loadingSubject,
    isCompanyError: !!error,
    companyError: error,
    selectedCompany,
    setSelectedCompany,
    handleChangeEmployees,
    onAddNewTeamMember,
    toggleHideTeamMember,
    onEditTeamMember,
    onDeleteTeamMember,
    onSaveImage,
    onSelectImage,
    refetchSubject,
    updateSubject,
    onEditField,
    setAttribute,
    setMainAttribute,
    setMainAndCustomAttribute,
    subjectData,
    onChangeHidden,
  }
}

export default useCompanyProfileDrawer
