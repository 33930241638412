import { PropsWithChildren, createContext, useContext, useMemo } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCurrentGroupId, getUserId } from 'selectors/auth'
import { FullSubjectFragment } from 'types/graphql-schemas/graphql'
import {
  Attribute,
  getAttribute as getAttributeFn,
} from 'utils/gql/helpers/subjects'
import { Nullable } from 'utils/types/common'
import { AttributeValue } from 'utils/gql/types/attributes'
import { hasWritePermissions } from 'utils/gql/helpers/permissions'

type SubjectContextType = {
  subject: FullSubjectFragment
  name: string
  logo?: Nullable<string>
  railsId: string
  getAttribute: <T extends AttributeValue<T>>(
    key: string
  ) => Attribute<T> | null
  hasWritePermissions: boolean
}

const SubjectContext = createContext({} as SubjectContextType)

type Props = PropsWithChildren & {
  subject: FullSubjectFragment
}

export const SubjectProvider = ({ subject, children }: Props) => {
  const groupId = useAppSelector(getCurrentGroupId)
  const userId = useAppSelector(getUserId)
  const railsId = subject.railsId!

  const context = useMemo(
    () => ({
      subject,
      name: subject.name,
      logo: subject.logo ?? subject.parentSubject?.logo,
      railsId,
      getAttribute<T extends AttributeValue<T>>(key: string) {
        return getAttributeFn<T>(subject, key)
      },
      hasWritePermissions:
        hasWritePermissions(groupId, subject.permissions) ||
        hasWritePermissions(userId, subject.permissions),
    }),
    [groupId, railsId, subject, userId]
  )

  return (
    <SubjectContext.Provider value={context}>
      {children}
    </SubjectContext.Provider>
  )
}

export const useSubjectContext = () => {
  return useContext(SubjectContext)
}
