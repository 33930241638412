import { useAuth0 } from '@auth0/auth0-react'
import {
  setAuth0AccessToken,
  setAuth0AccessTokenExpiration,
  setAuth0IdToken,
} from 'features/authSlice'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthentication } from 'utils/hooks/auth'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'

export const useRefreshAuth0Tokens = () => {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0()
  const { isAuthenticated } = useAuthentication()
  const dispatch = useAppDispatch()
  const [refreshingTokens, setRefreshingTokens] = useState(isAuthenticated)
  const history = useHistory()

  const refreshTokens = async () => {
    let accessToken: string | undefined
    try {
      accessToken = await getAccessTokenSilently()
      const claims = await getIdTokenClaims()
      await dispatch(setAuth0IdToken(claims?.__raw!))
      await dispatch(setAuth0AccessToken(accessToken))
      await dispatch(setAuth0AccessTokenExpiration(claims?.exp!))
    } catch (e) {
      history.replace('/sign-out')
    } finally {
      setRefreshingTokens(false)
    }
    return {
      accessToken,
    }
  }

  return {
    refreshingTokens,
    setRefreshingTokens,
    refreshTokens,
  }
}
