import Checkbox from 'components/Checkbox'
import FadeIn from 'components/FadeIn/FadeIn'
import { useFormikContext } from 'components/Form/hooks'
import Title from 'components/Title'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { SortDirection } from 'types/graphql-schemas/graphql'
import Dropdown from 'ui/Dropdown'
import Input from 'ui/Input'
import Separator from 'ui/Separator'
import { buildFormError } from 'utils/functions/forms'
import { useSubjectsLazyQuery } from 'utils/hooks/queries/subjects/useSubjectLazyQuery'
import { ErrorType } from 'utils/types/common'
import { InvestmentVehicleFormValues } from 'utils/types/investors'
import { SubjectType } from 'utils/types/subjects'
import { getCurrentGroupId } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getFilters } from 'utils/hooks/queries/subjects/useSubjectsQuery'
import { InvestmentVehicleErrors } from '../CreateInvestmentVehicle/useCreateInvestmentVehicle'
import LocationsList from '../components/LocationsList'
import {
  FormContainer,
  FormContent,
} from '../CreateInvestmentVehicle/CreateInvestmentVehicle.styles'

interface InvestmentVehicleFormProps {
  onCreateNewInvestor?: (name: string) => void
  isInvestorGroupsSelectorVisible?: boolean
  className?: string
  customError?: InvestmentVehicleErrors
}

const InvestmentVehicleForm: React.FC<InvestmentVehicleFormProps> = ({
  onCreateNewInvestor,
  isInvestorGroupsSelectorVisible = true,
  className = '',
  customError,
}) => {
  const intl = useIntl()
  const currentGroupId = useAppSelector(getCurrentGroupId)

  const investorFilters = useMemo(
    () => ({
      type: SubjectType.INVESTOR,
      groupOwner: currentGroupId,
    }),
    [currentGroupId]
  )

  const [getInvestors] = useSubjectsLazyQuery({
    filters: investorFilters,
    sorting: {
      orderBy: 'name',
      direction: SortDirection.Asc,
    },
  })

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    getFieldName,
    setFieldError,
  } = useFormikContext<InvestmentVehicleFormValues>()

  const getInvestorCustomErrors = useCallback(() => {
    if (!customError) return

    if (customError.name) {
      setFieldError('name', customError.name)
    }
  }, [customError, setFieldError])

  useEffect(() => {
    getInvestorCustomErrors()
  }, [getInvestorCustomErrors])

  const loadInvestors = useCallback(
    async (inputSearch) => {
      const response = await getInvestors({
        variables: {
          query: getFilters({ ...investorFilters, name: inputSearch }),
          sortBy: 'name',
          sortDirection: SortDirection.Asc,
        },
      })
      return response.data?.subjects || []
    },
    [investorFilters, getInvestors]
  )

  return (
    <FormContainer className={className}>
      <FormContent autoComplete="off">
        <Title
          title={intl.formatMessage({
            id: 'investorManagement.vehicleName',
          })}
          subLabel={intl.formatMessage({
            id: 'common.requiredField',
          })}
        />

        <Separator />

        <Input
          name="name"
          placeholder={intl.formatMessage({ id: 'investorManagement.addName' })}
          value={values.name}
          onChange={handleChange}
          error={buildFormError(errors?.name, ErrorType.ERROR, true)}
          onBlur={handleBlur}
        />

        <Separator />

        {isInvestorGroupsSelectorVisible && (
          <>
            <Title
              title={intl.formatMessage({
                id: 'investorManagement.investmentVehicleInvestor',
              })}
              subLabel={intl.formatMessage({
                id: 'common.requiredField',
              })}
            />

            <Separator />

            <Dropdown
              id={getFieldName('investor-selector')}
              name="investor"
              onSelectOption={(_, __, value) => {
                setFieldValue('investor', value)
              }}
              value={values.investor}
              placeholder={intl.formatMessage({
                id: 'investorManagement.selectInvestor',
              })}
              // error={
              // TODO: TAS-13658 bulk import
              // values.investor?.isPopulated
              // ?
              // buildFormError(
              //     intl.formatMessage({
              //       id: 'investorManagement.investorWarning',
              //     }),
              //     ErrorType.WARNING,
              //     true
              //   )
              // : buildFormError(
              //     errors.investor,
              //     ErrorType.ERROR,
              //     touched?.investor
              //   )
              // }
              type="select"
              clearOnSelect
              addOptionEnabled={!!onCreateNewInvestor}
              onAddOption={onCreateNewInvestor}
              async
              loadOptions={loadInvestors}
              getOption={(value) => ({ label: value.name, id: value.id })}
            />
            <Separator />
          </>
        )}

        <Checkbox
          id={getFieldName('shareInvestorData')}
          name={getFieldName('shareInvestorData')}
          label={intl.formatMessage({
            id: 'investorManagement.shareInvestorData',
          })}
          checked={values.shareInvestorData}
          onChange={handleChange}
        />

        {!values.shareInvestorData && (
          <FadeIn>
            <Separator />

            <Title
              title={intl.formatMessage({
                id: 'investorManagement.phone',
              })}
            />

            <Separator />

            <Input
              name="phone"
              type="text"
              placeholder={intl.formatMessage({
                id: 'investorManagement.addPhone',
              })}
              value={values.phone}
              onChange={handleChange}
              error={buildFormError(
                errors?.phone,
                ErrorType.ERROR,
                touched?.phone
              )}
              onBlur={handleBlur}
            />

            <Separator />

            <LocationsList />

            <Separator />

            {/* TODO: TAS-13594 */}
            {/* <Title
              title={intl.formatMessage({
                id: 'investorManagement.financialInfo',
              })}
            /> */}

            {/* <Separator /> */}

            {/* <FinancialInformationForm
              investorType={InvestorTypes.ORGANIZATION}
            /> */}
          </FadeIn>
        )}
      </FormContent>
    </FormContainer>
  )
}

export default InvestmentVehicleForm
