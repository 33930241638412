import humps from 'humps'
import { isEmpty } from 'lodash'
import {
  AttributeDataType,
  BasicSubjectFragment,
  BulkCreateSubjectDto,
  CreateSubjectDto,
  FullSubjectFragment,
  GqlAttributeDto,
  GqlUpdateSubjectDto,
} from 'types/graphql-schemas/graphql'
import { InvestorTypes } from 'utils/constants/investorManagement'
import { getAddedAndRemovedEntities } from 'utils/functions/utils'
import {
  getAttribute,
  getCreateAttribute,
  getEditAttribute,
} from 'utils/gql/helpers/subjects'
import {
  BulkInvestmentVehicleFormDraftValues,
  InvestmentVehicleDraft,
} from 'utils/types/bulkImports/investmentVehicles'
import {
  BulkInvestorFormDraftValues,
  BulkInvestorFormValues,
  InvestorDraft,
} from 'utils/types/bulkImports/investors'
import { DraftValidation, PropertyMapper } from 'utils/types/common'
import {
  Country,
  Employee,
  Industry,
  LegalStructure,
  Province,
} from 'utils/types/company'
import {
  BulkInvestmentVehicleFormValues,
  InvestmentVehicleFormValues,
  Investor,
  InvestorFormValues,
  InvestorGroupUser,
  InvestorGroupUserPayload,
  InvestorType,
} from 'utils/types/investors'
import { GooglePlaceData } from 'utils/types/locations'
import { SubjectType } from 'utils/types/subjects'
import { User } from 'utils/types/user'
import {
  getInvestorLocationsFromSubject,
  getLocationsPayload,
} from './locations'
import { randomId } from './number'

export const getEditInvestorInitialValuesFromSubject = (
  investorSubject: FullSubjectFragment
): InvestorFormValues => {
  const logo = investorSubject.logo || ''
  const name = investorSubject.name || ''
  const type = getAttribute<InvestorType>(
    investorSubject,
    'investorType'
  )?.value!
  const website = getAttribute<string>(investorSubject, 'website')?.value || ''
  const phone = getAttribute<string>(investorSubject, 'phone')?.value || ''
  const description =
    getAttribute<string>(investorSubject, 'description')?.value || ''
  const foundedDate = getAttribute<Date>(investorSubject, 'foundedDate')?.value
  const crunchbaseUrl =
    getAttribute<string>(investorSubject, 'crunchBaseUrl')?.value || ''

  const linkedinUrl =
    getAttribute<string>(investorSubject, 'linkedinUrl')?.value || ''
  const twitterUrl =
    getAttribute<string>(investorSubject, 'twitterUrl')?.value || ''
  const teamSize =
    getAttribute<number>(investorSubject, 'employeesCount')?.value || 0
  const employees =
    getAttribute<Employee[]>(investorSubject, 'employees')?.value || []
  const emails = getAttribute<string[]>(investorSubject, 'emails')?.value || []
  const locations =
    getAttribute<GooglePlaceData[]>(investorSubject, 'locations')?.value || []
  const legalEntityName =
    getAttribute<string>(investorSubject, 'legalEntityName')?.value || ''
  const legalStructure =
    getAttribute<LegalStructure>(investorSubject, 'legalStructure')?.value ||
    undefined
  const legalCountry =
    getAttribute<Country>(investorSubject, 'legalCountry')?.value || undefined
  const legalState =
    getAttribute<Province>(investorSubject, 'legalState')?.value || undefined
  const einTaxId = getAttribute<string>(investorSubject, 'taxId')?.value || ''
  const bankName =
    getAttribute<string>(investorSubject, 'bankName')?.value || ''
  const bankAccountNumber =
    getAttribute<string>(investorSubject, 'bankAccountNumber')?.value || ''
  const routingNumber =
    getAttribute<string>(investorSubject, 'bankRoutingNumber')?.value || ''

  const hasImageProfile = !investorSubject.logo?.includes('missing.png')

  const industries =
    getAttribute<Industry[]>(investorSubject, 'industries')?.value || []
  const users = []

  return {
    type,
    logo: {
      imageUrl: hasImageProfile ? logo : '',
      cropperUrl: logo,
      hasRemovedImage: false,
    },
    name,
    users,
    website,
    foundedDate,
    phone,
    description,
    crunchbaseUrl,
    linkedinUrl,
    twitterUrl,
    teamSize,
    employees,
    emails,
    locations: getInvestorLocationsFromSubject(locations),
    legalEntityName,
    legalStructure,
    legalCountry,
    legalState,
    einTaxId,
    bankName,
    bankAccountNumber,
    routingNumber,
    industries,
    sendInvitation: false,
  }
}

export const getEditInvestmentVehicleInitialValues = (
  investmentVehicle: BasicSubjectFragment
): InvestmentVehicleFormValues => {
  const shareInvestorData =
    getAttribute<boolean>(investmentVehicle, 'useInvestorInformation')?.value ||
    false
  const phone = getAttribute<string>(investmentVehicle, 'phone')?.value || ''
  const einTaxId = getAttribute<string>(investmentVehicle, 'taxId')?.value || ''
  const bankName =
    getAttribute<string>(investmentVehicle, 'bankName')?.value || ''
  const bankAccountNumber =
    getAttribute<string>(investmentVehicle, 'bankAccountNumber')?.value || ''
  const routingNumber =
    getAttribute<string>(investmentVehicle, 'bankRoutingNumber')?.value || ''
  const locations =
    getAttribute<GooglePlaceData[]>(investmentVehicle, 'locations')?.value || []

  return {
    id: investmentVehicle.id,
    name: investmentVehicle.name,
    investor: investmentVehicle.parentSubject!,
    shareInvestorData: !!shareInvestorData,
    phone,
    einTaxId: shareInvestorData ? '' : einTaxId,
    bankName: shareInvestorData ? '' : bankName,
    bankAccountNumber: shareInvestorData ? '' : bankAccountNumber,
    routingNumber: shareInvestorData ? '' : routingNumber,
    locations: getInvestorLocationsFromSubject(locations),
  }
}

export const getDeleteInvestorGroupUsersPayload = (
  investorGroupUsers: InvestorGroupUser[]
): {
  investorGroupUsersAttributes: InvestorGroupUserPayload[]
} => ({
  investorGroupUsersAttributes: investorGroupUsers.map((investorGroupUser) => ({
    id: investorGroupUser.id,
    _destroy: true,
  })),
})

export const getAddInvestorGroupUsersPayload = (
  users: User[],
  message?: string
): {
  invitationMessage?: string
  investorGroupUsersAttributes: InvestorGroupUserPayload[]
} => ({
  invitationMessage: message,
  investorGroupUsersAttributes: users.map((user) => ({
    id: null,
    userId: user.id,
  })),
})

const getLogo = (investorData: InvestorFormValues) => {
  const { logo } = investorData

  if (logo.hasRemovedImage) {
    return {
      remove_logo: true,
    }
  }

  if (logo?.image instanceof File) {
    return { logo: logo.image }
  }

  return undefined
}

interface GetInvestorPayloadProps {
  investorData: InvestorFormValues
  oldInvestor?: Investor
  isBulkImport?: boolean
}

interface GetInvestorSubjectPayloadProps {
  investorData: InvestorFormValues
  currentGroupId?: string
  investorId?: string
  isBulkImport?: boolean
}

interface GetDraftInvestorSubjectPayloadProps {
  investorData: InvestorDraft
  currentGroupId?: string
}

interface GetInvestmentVehicleSubjectPayloadProps {
  investmentVehicleData: InvestmentVehicleFormValues | InvestmentVehicleDraft
  currentGroupId?: string
  investmentVehicleId?: string
}

// TODO: remove this once is no longer used
export const getInvestorPayload = ({
  investorData,
  oldInvestor,
  isBulkImport,
}: GetInvestorPayloadProps) => {
  const isAnOrganizationInvestor =
    investorData.type === InvestorTypes.ORGANIZATION

  const hasFinancialAttributes =
    investorData.einTaxId ||
    investorData.bankName ||
    investorData.bankAccountNumber ||
    investorData.routingNumber

  const { removed: removedEmployees } = getAddedAndRemovedEntities<Employee>(
    oldInvestor?.employees ?? [],
    isAnOrganizationInvestor ? investorData.employees : []
  )

  const { added: addedIndustries, removed: removedIndustries } =
    getAddedAndRemovedEntities<Industry>(
      oldInvestor?.generalInformation.investmentToolIndustries ?? [],
      investorData.industries
    )

  const { added: addedUsers } = getAddedAndRemovedEntities<
    InvestorGroupUser,
    User
  >(oldInvestor?.investorGroupUsers ?? [], investorData.users, [
    (investorUserGroup) => investorUserGroup.user.id,
    (user) => user.id,
  ])

  const { investorGroupUsersAttributes: addedUsersPayload } =
    getAddInvestorGroupUsersPayload(addedUsers)

  const isNewEmployee = (employee: Employee): boolean =>
    !oldInvestor?.employees.some((emp) => emp.id === employee.id)

  const payload = humps.decamelizeKeys({
    name: investorData.name,
    investorType: investorData.type,
    website: investorData.website,
    phone: investorData.phone,
    description: investorData.description,
    foundedDate: investorData.foundedDate,
    crunchbaseUrl: investorData.crunchbaseUrl,
    angellistUrl: investorData.angellistUrl,
    linkedinUrl: investorData.linkedinUrl,
    twitterUrl: investorData.twitterUrl,
    employeesNumber: isAnOrganizationInvestor ? investorData.teamSize : null,
    legalEntityName: investorData.legalEntityName,
    legalStructureId: investorData.legalStructure?.id,
    legalCountryId: investorData.legalCountry?.id,
    legalProvinceId: investorData.legalState?.id,
    userEmails: investorData.emails?.length ? investorData.emails : [''],
    investorGroupUsersAttributes: investorData.sendInvitation
      ? addedUsersPayload
      : [''],
    invitationMessage: investorData.message,
    investmentToolIndustriesAttributes: [
      ...addedIndustries.map((industry) => ({
        id: null,
        industryId: industry.id,
      })),
      ...removedIndustries.map((industry) => ({
        id: industry.id,
        _destroy: true,
      })),
    ],
    financialAttributes:
      hasFinancialAttributes || oldInvestor?.financialInformation?.id
        ? {
            id: oldInvestor?.financialInformation?.id,
            taxId: investorData.einTaxId,
            bankName: investorData.bankName,
            bankAccount: investorData.bankAccountNumber,
            routingNumber: investorData.routingNumber,
          }
        : undefined,
    employeesAttributes: [
      ...investorData.employees.map((employee, index) => ({
        id: isNewEmployee(employee) ? null : employee.id,
        position: index + 1,
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        title: employee.title,
        founder: employee.founder,
        linkedinUrl: employee.linkedinUrl,
      })),
      ...removedEmployees.map((employee) => ({
        id: employee.id,
        _destroy: true,
      })),
    ],
    locationsAttributes: getLocationsPayload(
      oldInvestor?.locations ?? [],
      investorData.locations
    ),
  })

  if (isBulkImport) {
    payload.inviteMembers = !!investorData?.sendInvitation
  }

  return {
    ...payload,

    ...getLogo(investorData),
  }
}

export const getInvestorSubjectPayload = <
  T extends CreateSubjectDto | GqlUpdateSubjectDto | BulkCreateSubjectDto,
>({
  currentGroupId,
  investorData,
  investorId,
}: GetInvestorSubjectPayloadProps) => {
  const isEditing = !!investorId
  const getAttributeFn = isEditing ? getEditAttribute : getCreateAttribute

  const id = investorId || ''

  const subjectAttributes: T = {
    type: SubjectType.INVESTOR,
    logo: investorData.logo.image,
    name: investorData.name,
    permissions: currentGroupId
      ? [{ entityId: currentGroupId, read: true, write: true }]
      : undefined,
    attributes: [
      getAttributeFn(
        'investorType',
        investorData.type,
        AttributeDataType.String
      ),
      ...(investorData.sendInvitation
        ? [
            getAttributeFn(
              'sendInvitation',
              investorData.sendInvitation,
              AttributeDataType.Boolean
            ),
            getAttributeFn(
              'invitationMessage',
              investorData.message,
              AttributeDataType.String
            ),
            getAttributeFn(
              'invitedUsers',
              investorData.users.map((user) => user.id),
              AttributeDataType.Array
            ),
          ]
        : []),
      getAttributeFn('website', investorData.website, AttributeDataType.String),
      getAttributeFn('phone', investorData.phone, AttributeDataType.String),
      getAttributeFn(
        'description',
        investorData.description,
        AttributeDataType.String
      ),
      getAttributeFn(
        'crunchBaseUrl',
        investorData.crunchbaseUrl,
        AttributeDataType.String
      ),
      getAttributeFn(
        'angelListUrl',
        investorData.angellistUrl,
        AttributeDataType.String
      ),
      getAttributeFn(
        'linkedinUrl',
        investorData.linkedinUrl,
        AttributeDataType.String
      ),
      getAttributeFn(
        'twitterUrl',
        investorData.twitterUrl,
        AttributeDataType.String
      ),
      getAttributeFn(
        'legalEntityName',
        investorData.legalEntityName,
        AttributeDataType.String
      ),
      getAttributeFn(
        'legalStructure',
        investorData.legalStructure || null,
        AttributeDataType.Object
      ),
      getAttributeFn(
        'legalCountry',
        investorData.legalCountry || null,
        AttributeDataType.Object
      ),
      getAttributeFn(
        'legalProvince',
        investorData.legalState || null,
        AttributeDataType.Object
      ),
      getAttributeFn('emails', investorData.emails, AttributeDataType.Array),
      getAttributeFn(
        'industries',
        investorData.industries.map((industry) => ({
          id: industry.id,
          name: industry.name,
          sectorId: industry.sectorId,
        })) ?? [],
        AttributeDataType.Array
      ),
      getAttributeFn(
        'foundedDate',
        investorData.foundedDate,
        AttributeDataType.Date
      ),
      getAttributeFn(
        'employees',
        investorData.employees,
        AttributeDataType.Array
      ),
      getAttributeFn(
        'employeesCount',
        Number(investorData.teamSize),
        AttributeDataType.Number
      ),
      getAttributeFn('taxId', investorData.einTaxId, AttributeDataType.String),
      getAttributeFn(
        'socialSecurityNumber',
        investorData.einTaxId,
        AttributeDataType.String
      ), // not used, its value is saved in taxId for individuals and organizations
      getAttributeFn(
        'locations',
        investorData.locations,
        AttributeDataType.Array
      ),
      getAttributeFn(
        'bankName',
        investorData.bankName,
        AttributeDataType.String
      ),
      getAttributeFn(
        'bankAccountNumber',
        investorData.bankAccountNumber,
        AttributeDataType.String
      ),
      getAttributeFn(
        'bankRoutingNumber',
        investorData.routingNumber,
        AttributeDataType.String
      ),
    ] as GqlAttributeDto[],
  } as T

  return {
    variables: {
      id,
      type: SubjectType.INVESTOR,
      data: subjectAttributes,
    },
  }
}

export const getDraftInvestorSubjectPayload = ({
  currentGroupId,
  investorData,
}: GetDraftInvestorSubjectPayloadProps): BulkCreateSubjectDto => {
  const subjectAttributes: BulkCreateSubjectDto = {
    type: SubjectType.INVESTOR,
    name: investorData.name,
    permissions: currentGroupId
      ? [{ entityId: currentGroupId, read: true, write: true }]
      : undefined,
    attributes: [
      getCreateAttribute(
        'investorType',
        investorData.investorType,
        AttributeDataType.String
      ),
      getCreateAttribute(
        'description',
        investorData.description,
        AttributeDataType.String
      ),
      getCreateAttribute(
        'website',
        investorData.website,
        AttributeDataType.String
      ),
      getCreateAttribute('phone', investorData.phone, AttributeDataType.String),
      getCreateAttribute(
        'crunchbaseUrl',
        investorData.crunchbaseUrl,
        AttributeDataType.String
      ),
      getCreateAttribute(
        'angellistUrl',
        investorData.angellistUrl,
        AttributeDataType.String
      ),
      getCreateAttribute(
        'linkedinUrl',
        investorData.linkedinUrl,
        AttributeDataType.String
      ),
      getCreateAttribute(
        'twitterUrl',
        investorData.twitterUrl,
        AttributeDataType.String
      ),
      getCreateAttribute(
        'employeesNumber',
        investorData.employeesNumber,
        AttributeDataType.Number
      ),
    ],
  }

  return subjectAttributes
}

export const getInvestmentVehicleSubjectPayload = <
  T extends CreateSubjectDto | GqlUpdateSubjectDto | BulkCreateSubjectDto,
>({
  currentGroupId,
  investmentVehicleData,
  investmentVehicleId,
}: GetInvestmentVehicleSubjectPayloadProps) => {
  const isEditing = !!investmentVehicleId
  const id = investmentVehicleId || ''
  const shareInvestorData =
    (investmentVehicleData as InvestmentVehicleFormValues).shareInvestorData ||
    (investmentVehicleData as InvestmentVehicleDraft).shareData
  const parentSubjectId =
    (investmentVehicleData as InvestmentVehicleFormValues).investor?.id ||
    (investmentVehicleData as InvestmentVehicleDraft)?.investorGroupId

  const getAttributeFn = isEditing ? getEditAttribute : getCreateAttribute

  const subjectAttributes: T = {
    type: SubjectType.INVESTMENT_VEHICLE,
    name: investmentVehicleData.name,
    permissions: currentGroupId
      ? [{ entityId: currentGroupId, read: true, write: true }]
      : undefined,
    parentSubject: parentSubjectId,
    attributes: [
      getAttributeFn(
        'phone',
        shareInvestorData ? undefined : investmentVehicleData.phone,
        AttributeDataType.String
      ),
      getAttributeFn(
        'useInvestorInformation',
        shareInvestorData,
        AttributeDataType.Boolean
      ),
      getAttributeFn(
        'bankName',
        shareInvestorData
          ? undefined
          : (investmentVehicleData as InvestmentVehicleFormValues).bankName ||
              (investmentVehicleData as InvestmentVehicleDraft)
                .financialAttributes?.bankName,
        AttributeDataType.String
      ),
      getAttributeFn(
        'bankAccountNumber',
        shareInvestorData
          ? undefined
          : (investmentVehicleData as InvestmentVehicleFormValues)
              .bankAccountNumber ||
              (investmentVehicleData as InvestmentVehicleDraft)
                .financialAttributes?.bankAccount,
        AttributeDataType.String
      ),
      getAttributeFn(
        'bankRoutingNumber',
        shareInvestorData
          ? undefined
          : (investmentVehicleData as InvestmentVehicleFormValues)
              .routingNumber ||
              (investmentVehicleData as InvestmentVehicleDraft)
                .financialAttributes?.routingNumber,
        AttributeDataType.String
      ),
      getAttributeFn(
        'taxId',
        shareInvestorData
          ? undefined
          : (investmentVehicleData as InvestmentVehicleFormValues).einTaxId ||
              (investmentVehicleData as InvestmentVehicleDraft)
                .financialAttributes?.taxId,
        AttributeDataType.String
      ),
      getAttributeFn(
        'locations',
        shareInvestorData
          ? undefined
          : (investmentVehicleData as InvestmentVehicleFormValues).locations ||
              (investmentVehicleData as InvestmentVehicleDraft).addresses,
        AttributeDataType.Array
      ),
      getAttributeFn(
        'address',
        shareInvestorData
          ? undefined
          : (investmentVehicleData as InvestmentVehicleFormValues)
              .locations?.[0]?.formattedAddress ||
              (investmentVehicleData as InvestmentVehicleDraft).addresses,
        AttributeDataType.String
      ),
    ] as GqlAttributeDto[],
  } as T

  return {
    variables: {
      id,
      type: SubjectType.INVESTMENT_VEHICLE,
      data: subjectAttributes,
    },
  }
}

type ValidatedErrors<T> = {
  [key in keyof T]?: string
}

type ValidatedInvestorsErrors = ValidatedErrors<InvestorFormValues>
type ValidatedInvestmentVehiclesErrors =
  ValidatedErrors<InvestmentVehicleFormValues>

export const getBulkImportInvestorsData = (
  validatedInvestors: DraftValidation<BulkInvestorFormDraftValues>[],
  defaultMessage: string
) => {
  return validatedInvestors.reduce<{
    investors: BulkInvestorFormValues[]
    errors: ValidatedInvestorsErrors[]
  }>(
    (result, draftValidation) => {
      const { errors: errorsToParse, object: investor } = draftValidation
      const propertyMapper: PropertyMapper<
        BulkInvestorFormDraftValues,
        BulkInvestorFormValues
      > = {
        investorType: 'type',
        invitationMessage: 'message',
        userEmails: 'users',
      }

      const errors = Object.keys(
        errorsToParse
      ).reduce<ValidatedInvestorsErrors>((currentErrors, key) => {
        const property = propertyMapper[key] ?? key

        if (key !== 'email') {
          return {
            ...currentErrors,
            [property]: errorsToParse[key]?.join(', '),
          }
        }

        return currentErrors
      }, {})

      const parsedInvestor: BulkInvestorFormValues = {
        id: randomId(),
        type: investor.investorType ?? 'organization',
        logo: investor?.logo ?? {
          cropperUrl: '',
          hasRemovedImage: false,
          imageUrl: '',
        },
        name: investor.name ?? '',
        emails: investor.emails ?? [],
        website: investor.website ?? '',
        foundedDate: investor.foundedDate,
        phone: investor.phone ?? '',
        description: investor.description ?? '',
        locations: investor.locations ?? [],

        crunchbaseUrl: investor.crunchbaseUrl ?? '',
        angellistUrl: investor.angellistUrl ?? '',
        linkedinUrl: investor.linkedinUrl ?? '',
        twitterUrl: investor.twitterUrl ?? '',
        teamSize: Number(investor.employeesNumber) ?? 0,
        employees: investor.employees ?? [],
        legalEntityName: investor.legalEntityName ?? '',
        legalStructure: investor.legalStructure,
        legalCountry: investor.legalCountry,
        legalState: investor.legalState,
        einTaxId: investor.einTaxId ?? '',
        bankName: investor.bankName ?? '',
        bankAccountNumber: investor.bankAccountNumber ?? '',
        routingNumber: investor.routingNumber ?? '',
        industries: investor.industries ?? [],

        sendInvitation: false,
        users: [],
        message: investor.invitationMessage ?? defaultMessage,
      }

      return {
        investors: [...result.investors, parsedInvestor],
        errors: [...result.errors, errors],
      }
    },
    {
      investors: [],
      errors: [],
    }
  )
}

export const getBulkImportInvestmentVehicleData = (
  validatedInvestmentVehicles: DraftValidation<BulkInvestmentVehicleFormDraftValues>[]
) => {
  return validatedInvestmentVehicles.reduce<{
    investmentVehicles: BulkInvestmentVehicleFormValues[]
    errors: ValidatedInvestmentVehiclesErrors[]
  }>(
    (result, draftValidation) => {
      const { errors: errorsToParse, object: vehicle } = draftValidation
      const errors = Object.keys(
        errorsToParse
      ).reduce<ValidatedInvestmentVehiclesErrors>((currentErrors, key) => {
        return { ...currentErrors, [key]: errorsToParse[key]?.join(', ') }
      }, {})

      const parsedInvestmentVehicle: BulkInvestmentVehicleFormValues = {
        id: randomId(),
        name: vehicle.name ?? '',
        phone: vehicle.phone ?? '',
        investor: vehicle.investor,
        locations: vehicle.locations,
        shareInvestorData: vehicle.shareInvestorData,
        einTaxId: vehicle.einTaxId ?? '',
        bankName: vehicle.bankName ?? '',
        bankAccountNumber: vehicle.bankAccountNumber ?? '',
        routingNumber: vehicle.routingNumber ?? '',
      }

      return {
        investmentVehicles: [
          ...result.investmentVehicles,
          parsedInvestmentVehicle,
        ],
        errors: [...result.errors, errors],
      }
    },
    { investmentVehicles: [], errors: [] }
  )
}

export const hasData = (data: any[]) => {
  return data.some((item) => !isEmpty(item))
}

export const hasGeneralInformation = (investor) => {
  return hasData([
    investor.address,
    investor.emails,
    investor.foundedDate,
    investor.industries,
    investor.legalName,
    investor.locations,
    investor.phone,
    investor.teamSize,
    investor.website,
  ])
}

export const hasExtendedInformation = (investor, isDetailsScreen: boolean) => {
  return (
    hasData([
      investor.description,
      investor.investmentVehicles,
      investor.employees,
      investor.taxId,
      investor.bankName,
      investor.bankAccount,
      investor.routingNumber,
    ]) ||
    (hasData([
      investor.linkedinUrl,
      investor.twitterUrl,
      investor.website,
      investor.crunchBaseUrl,
      investor.angelListUrl,
    ]) &&
      !isDetailsScreen)
  )
}
