import { sortBy } from 'lodash'
import { useMemo } from 'react'
import { useSubjectContext } from 'utils/context/subjects/SubjectContext'
import { useCalculatedDataPointsByMetricId } from 'utils/hooks/queries/useDataPointsQuery'
import { IndexMetric } from 'utils/types/metricsV2'

interface Props {
  metric: IndexMetric
}

const useCumulativeMetric = ({ metric }: Props) => {
  const isCumulativeMetric = metric?.isCumulativeMetric
  const { railsId: holdingId } = useSubjectContext()

  const { data: allDataPoints, isLoading: isLoadingCumulativeValue } =
    useCalculatedDataPointsByMetricId(
      { metricId: metric.id, subjectId: holdingId },
      {
        staleTime: 1000,
        enabled: isCumulativeMetric,
      }
    )

  const sortedDataPoints = useMemo(() => {
    return isCumulativeMetric
      ? sortBy(allDataPoints, (dp) => dp.date)
      : sortBy(metric.dataPoints, (dp) => dp.date)
  }, [allDataPoints, isCumulativeMetric, metric.dataPoints])

  const reversedDataPoints = useMemo(() => {
    return sortedDataPoints.slice().reverse()
  }, [sortedDataPoints])

  const currentValueIndex = useMemo(() => {
    return reversedDataPoints.findIndex((dp) => !dp.archived) ?? null
  }, [reversedDataPoints])

  const previousValue = useMemo(() => {
    return (
      reversedDataPoints
        .slice(currentValueIndex + 1)
        .find((dp) => !dp.archived) ?? null
    )
  }, [currentValueIndex, reversedDataPoints])

  const currentValue = useMemo(() => {
    return reversedDataPoints.find((dp) => !dp.archived) ?? null
  }, [reversedDataPoints])

  return {
    isCumulativeMetric,
    isLoadingCumulativeValue,
    currentValue,
    previousValue,
  }
}

export default useCumulativeMetric
