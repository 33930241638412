import { ApiEntity } from 'api/types'
import { sortBy } from 'lodash'
import { Integrations } from 'utils/constants/integrations'
import { AssociationType } from 'utils/constants/investorManagement'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { TransactionFundType } from 'utils/constants/transactionTypes'
import {
  parseEventLogType,
  parseUpdateType,
  PlainUpdateTypes,
  PortfoliosTypeMap,
} from 'utils/constants/updates'
import {
  DraftContentApi,
  EmailContentApi,
  FileContentApi,
} from 'utils/types/api/files'
import { getPortfolioTypeFromApiType } from 'utils/types/api/updates'
import {
  Company,
  CompanyHoldingData,
  Employee,
  FundProfile,
  Holding,
  HoldingType,
} from 'utils/types/company'
import {
  FundPortfolioInvestor,
  IndexFundPortfolioInvestor,
  SharePortfolioSettings,
} from 'utils/types/funds'
import {
  BaseInvestmentVehicle,
  ContactDetails,
  FinancialInformation,
  GeneralInformation,
  IndexInvestmentVehicle,
  IndexInvestor,
  InvestmentVehicle,
  InvestmentVehicleInvestor,
  Investor,
  InvestorInvestmentVehicle,
} from 'utils/types/investors'
import { GooglePlaceData, Location } from 'utils/types/locations'
import {
  FundPortfolioInvestors,
  IndexFundPortfolio,
  IndexPortfolio,
  Portfolio,
  PortfolioCompany,
  PortfolioHolding,
  PortfolioMetricsStatus,
} from 'utils/types/portfolios'
import {
  BasicUpdatableAttributes,
  DraftHashData,
  DraftHashEditor,
  IndexUpdate,
  LoggingUpdateGroup,
  LoggingUpdateUser,
  ScheduledUpdate,
  TransactionItem,
  TransactionUpdatableAttributes,
  Update,
  UpdateItem,
} from 'utils/types/update'

import { CreateFundPortfolioResponse } from 'api/HoldingsService'
import { ContentAnalytic } from 'utils/types/analytics'
import { GroupApi } from 'utils/types/api/groups'
import {
  LoggingUpdateGroupApi,
  LoggingUpdateUserApi,
} from 'utils/types/api/udpates'
import { getCurrentGroupId } from 'selectors/auth'
import { store } from 'store'
import { UserApi } from 'utils/types/api/user'
import { SubjectMatterType } from 'api/UpdateService'
import { getAccountingReportType } from './accounting'
import { getDateIgnoringTimezone } from './date'
import {
  ContentsEntities,
  FileContentNormalizer,
} from './normalizers/fileContentNormalizer'
import { getTagsFromTaggings } from './tags'

type IndexApiEntities = {
  [key: string]: any
  loggingUpdateGroups: ApiEntity<LoggingUpdateGroupApi>
  loggingUpdateUsers: ApiEntity<LoggingUpdateUserApi>
  s3contents: ApiEntity<FileContentApi>
  contents: ApiEntity<FileContentApi>
  emailContents: ApiEntity<EmailContentApi>
  contentsDrafts: ApiEntity<DraftContentApi>
  groupsDrafts: ApiEntity<GroupApi>
  usersDrafts: ApiEntity<UserApi>
}
export default class ResponseNormalizer {
  static normalizeUpdate<T extends Update>(
    result: string,
    entities: IndexApiEntities
  ): T {
    const update = entities.updates[result]
    const currentGroupId = getCurrentGroupId(store.getState())

    const portfolios = update.portfolios?.map((portfolioId: string) =>
      this.normalizePortfolio(portfolioId, entities, update)
    )

    const fundPortfolios = update.fundPortfolios?.map((fundId: string) =>
      this.normalizeIndexFundPortfolio(fundId, entities, update)
    )

    const updatePortfolios =
      portfolios || fundPortfolios
        ? [...(portfolios || []), ...(fundPortfolios || [])]
        : undefined

    let holdingData
    if (update.companyDatum || update.fundPortfolio) {
      holdingData = update.companyDatum
        ? entities.companyData?.[update.companyDatum]
        : entities.fundPortfolios?.[update.fundPortfolio]
    }

    const updateItem = this.getUpdateItem(update, entities)

    const normalizedDraftHash =
      update.type === PlainUpdateTypes.TRANSACTION
        ? this.normalizeDraftHash<TransactionUpdatableAttributes>(
            update,
            updateItem,
            entities
          )
        : this.normalizeDraftHash(update, updateItem, entities)

    const s3Contents =
      update?.s3Contents?.map((contentId: string) =>
        FileContentNormalizer.normalizeFileContent<ContentsEntities>(
          contentId,
          entities
        )
      ) || []
    const emailContents = FileContentNormalizer.normalizeEmailContents(
      entities.emailContents,
      update?.emailContents
    )

    let fundPortfolio
    if (typeof update.fundPortfolio === 'string') {
      fundPortfolio = entities.portfolios?.[update.fundPortfolio]
        ? {
            ...entities.portfolios?.[update.fundPortfolio],
            type:
              entities.portfolios?.[update.fundPortfolio]?.classType ===
              SubjectMatterType.DEAL_PORTFOLIO
                ? PortfolioTypes.DEAL
                : PortfolioTypes.FUND,
          }
        : undefined
    } else {
      fundPortfolio = update.fundPortfolio
        ? {
            ...update.fundPortfolio,
            type:
              entities.portfolios?.[update.fundPortfolio]?.classType ===
              SubjectMatterType.DEAL_PORTFOLIO
                ? PortfolioTypes.DEAL
                : PortfolioTypes.FUND,
          }
        : undefined
    }

    let updatePortfolio

    if (typeof update.portfolio === 'string') {
      updatePortfolio = entities.portfolios?.[update.portfolio]
    } else {
      updatePortfolio =
        update.portfolio?.groupId === currentGroupId ||
        update.fundPortfolio?.group?.id === currentGroupId ||
        update.portfolio?.type === PortfolioTypes.TRACK ||
        update.portfolio?.type === PortfolioTypes.INVEST
          ? {
              ...update.portfolio,
              type:
                update.portfolio?.type ||
                fundPortfolio?.type ||
                PortfolioTypes.INVEST,
            }
          : undefined
    }

    const normalizedUpdate = {
      ...update,
      user: entities.users[update.user],
      userId: update.userId ?? update.user?.id ?? update.user,
      group: entities.groups[update.group],
      contents: [...s3Contents, ...emailContents],
      loggingUpdateGroups:
        update.loggingUpdateGroups.map(
          (loggingUpdateGroupId: string) =>
            entities.loggingUpdateGroups[loggingUpdateGroupId]
        ) ?? [],
      loggingUpdateUsers: update.loggingUpdateUsers.map(
        (loggingUpdateUserId: string) =>
          entities.loggingUpdateUsers[loggingUpdateUserId]
      ),
      loggingUpdateSchedule:
        entities.loggingUpdateSchedules?.[update.loggingUpdateSchedule] ?? {},
      tags: getTagsFromTaggings(
        update.taggings.map((tagId: string) => entities.taggings[tagId])
      ),
      isPortfolioUpdate: updatePortfolio,
      portfolios: updatePortfolios,
      fundPortfolio:
        entities.fundPortfolios?.[
          update.fundPortfolios?.[0] ?? update.fundPortfolio
        ],
      updateType: parseUpdateType(update.type),
      eventLogType: parseEventLogType(update.type)!,
      subjectMatter: entities.subjectMatters?.[update.subjectMatter],
      portfolio: entities.portfolios?.[update.portfolio],
      companyDatum: entities.companyData?.[update.companyDatum],
      holding: holdingData ? this.normalizeHolding(holdingData) : undefined,
      item: this.getUpdateItem(update, entities),
      ...normalizedDraftHash,
    }

    return normalizedUpdate
  }

  static normalizeDraftHash<
    T extends BasicUpdatableAttributes = BasicUpdatableAttributes,
  >(
    update: any,
    updateItem: UpdateItem,
    entities: IndexApiEntities
  ): DraftHashData<T> {
    let updateDraftHash

    if (Object.keys(update.draftHash || {}).length > 1) {
      updateDraftHash = update.draftHash
    } else if (
      Object.keys(updateItem.loggingUpdate?.draftHash ?? {}).length > 1
    ) {
      updateDraftHash = updateItem.loggingUpdate.draftHash!
    }

    const isDraftUpdate = !!updateDraftHash
    const isEditBlocked = !!(updateDraftHash as DraftHashEditor)?.userEditing

    if (!isDraftUpdate) {
      return {
        isDraftUpdate: false,
        isEditBlocked: false,
      }
    }

    if (isEditBlocked) {
      return {
        isDraftUpdate,
        isEditBlocked,
        draftHash: updateDraftHash,
      }
    }

    const getDraftGroupData = (groupId: string, group) => {
      return {
        id: group.id,
        createdAt: group.createdAt,
        updatedAt: group.updatedAt,
        loggingReshares: [],
        original: true, // TODO: check if this is needed
        groupableId: groupId,
        groupName: group.name,
        groupLogo: group.logo,
      }
    }

    const loggingUpdateInvestorDraftGroups = (update.investorGroupsDraft?.map(
      (groupId) => {
        const group = entities.investorGroupsDrafts?.[groupId] || {}
        return getDraftGroupData(groupId, group)
      }
    ) ?? []) as LoggingUpdateGroup[]

    const loggingUpdateDraftGroups = (update.groupsDraft?.map((groupId) => {
      const group = entities.groupsDrafts?.[groupId] || {}
      return getDraftGroupData(groupId, group)
    }) ?? []) as LoggingUpdateGroup[]

    const loggingUpdateGroups = [
      ...loggingUpdateDraftGroups,
      ...loggingUpdateInvestorDraftGroups,
    ] as LoggingUpdateGroup[]

    const updatableAttributes =
      update.type === PlainUpdateTypes.TRANSACTION
        ? {
            ...updateDraftHash.updatableAttributes,
            portfolioVehicleInvestorTransactionAttributes: {
              ...updateDraftHash.updatableAttributes
                .portfolioVehicleInvestorTransactionAttributes,
              id:
                (updateItem as TransactionItem)
                  ?.portfolioVehicleInvestorTransaction?.id || '',
            },
            instrumentType:
              updateDraftHash.updatableAttributes?.instrumentAttributes
                ?.instrumentType,
            instrument:
              updateDraftHash.updatableAttributes?.instrumentAttributes,
          }
        : { ...updateDraftHash.updatableAttributes }

    return {
      isDraftUpdate,
      isEditBlocked,
      draftHash: {
        ...updateDraftHash,
        updatableAttributes,
        loggingUpdateGroups,
        loggingUpdateUsers: (update.usersDraft?.map((userId) => {
          const user = entities.usersDrafts?.[userId] || {}
          return {
            id: user.id,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
            loggingReshares: [],
            original: true, // TODO: check if this is needed
            userId: user.id,
            userName: user.name,
            userEmail: user.email,
            userImage: user.image,
          }
        }) ?? []) as LoggingUpdateUser[],
        contents:
          update.contentsDraft?.map((contentId) => {
            const content = entities.contentsDrafts?.[contentId] || {}
            const isEmailContent = !!content.emailContent

            return {
              ...content.s3Content,
              ...(isEmailContent && {
                ...content.emailContent,
                s3Contents: [],
              }),
            }
          }) ?? [],
        investmentVehicle: update.investmentVehicleDraft,
        investor:
          Array.isArray(update.investorGroupDraft) &&
          update.investorGroupDraft.length === 0
            ? undefined
            : update.investorGroupDraft,
        holding: update.holdingDraft,
        tags: update.tagsDraft.filter((tag) => !!tag.name),
        fundPortfolio:
          update.fundPortfolioDraft.length > 0
            ? update.fundPortfolioDraft[0]
            : undefined,
        portfolios:
          update.portfoliableDraft && Array.isArray(update.portfoliableDraft)
            ? [...update.portfoliableDraft]
            : [],
      },
    }
  }

  static normalizePortfolioCompany(
    id: string,
    entities: any
  ): PortfolioCompany {
    const portfolioCompany = entities.portfolioCompanies[id]
    return {
      ...portfolioCompany,
      holding: portfolioCompany.holding?.id
        ? ResponseNormalizer.normalizeHolding(portfolioCompany.holding)
        : this.normalizeCompany(portfolioCompany.id, entities),
    }
  }

  static normalizePortfolio<T extends Portfolio = Portfolio>(
    id: string,
    entities: any,
    update?: any
  ): T {
    const portfolio = entities.portfolios[id]
    const portfolioCompanyTransactions =
      entities.transactions?.[update.transaction]?.portfolioCompanyTransactions

    const portfolioCompanyTransaction = portfolioCompanyTransactions?.find(
      (currPortfolioCompany) => currPortfolioCompany.investPortfolio.id === id
    )
    const portfolioCompanies: PortfolioCompany[] = entities.portfolioCompanies
      ? portfolio.portfolioCompanies.map((portCompany) =>
          this.normalizePortfolioCompany(portCompany, entities)
        )
      : portfolio.portfolioCompanies

    const investorPortfolioCompanies: PortfolioCompany[] =
      entities.investorPortfolioCompanies
        ? portfolio.investorPortfolioCompanies.map((portCompany) =>
            this.normalizePortfolioCompany(portCompany, {
              portfolioCompanies: entities.investorPortfolioCompanies,
            })
          )
        : portfolio.investorPortfolioCompanies

    const userPortfolio = entities.userPortfolios?.[portfolio.userPortfolio]

    const type = portfolio.type || PortfolioTypes.FUND

    const sharePortfolioSetting = entities.sharePortfolioSettings?.[
      portfolio.sharePortfolioSetting
    ] as SharePortfolioSettings

    return {
      ...portfolio,
      type,
      userPortfolio,
      portfolioCompanyTransaction,
      portfolioCompanies,
      investorPortfolioCompanies,
      totalTransactions: portfolio.totalInvestments,
      firstInvestmentDate: portfolio.firstInvestmentDate,
      sharePortfolioSetting,
    }
  }

  static normalizeIndexFundPortfolio = (
    id: string,
    entities: any,
    update?: any
  ): IndexFundPortfolio => {
    const portfolio = entities.fundPortfolios[id]
    const portfolioCompanyTransactions =
      entities.transactions?.[update.transaction]?.portfolioCompanyTransactions

    const portfolioCompanyTransaction = portfolioCompanyTransactions?.find(
      (currPortfolioCompany) => currPortfolioCompany.investPortfolio.id === id
    )

    return {
      ...portfolio,
      portfolioCompanyTransaction,
    }
  }

  static normalizeIndexPortfolio = <T extends IndexPortfolio = IndexPortfolio>(
    portfolio
  ): T => {
    return {
      ...portfolio,
      totalTransactions: portfolio.totalInvestments,
      portfolioCompanies: portfolio.portfolioCompanies.map(
        (portfolioCompany) => ({
          ...portfolioCompany,
          holding: this.normalizeHolding(portfolioCompany.holding),
        })
      ),
    }
  }

  static normalizeFundPortfolioInvestors = (
    portfolioId,
    entities
  ): FundPortfolioInvestors => {
    const portfolioEntity =
      entities.fundPortfolios?.[portfolioId] ||
      entities.dealPortfolios?.[portfolioId]

    const fundPortfolio = {
      ...portfolioEntity,
      totalInvestors: portfolioEntity.fundPortfolioInvestors.length,
    }

    const investors = portfolioEntity.fundPortfolioInvestors.map((investorId) =>
      this.normalizeFundPortfolioInvestor(investorId, entities)
    )

    return {
      fundPortfolio,
      investors,
    }
  }

  static normalizeIndexFundPortfolioInvestor = (
    fundPortfolioInvestorId,
    entities
  ): IndexFundPortfolioInvestor => {
    return {
      ...this.normalizeFundPortfolioInvestor(fundPortfolioInvestorId, entities),
      sharePortfolioSetting:
        entities.fundPortfolioInvestors[fundPortfolioInvestorId]
          .sharePortfolioSetting,
    }
  }

  static normalizePortfolioMetricsStatus = (
    result: string,
    entities: any
  ): PortfolioMetricsStatus => {
    const portfolioStatus =
      entities.investPortfolios?.[result] || entities.fundPortfolios?.[result]
    const metricsStatus: PortfolioMetricsStatus = {
      action: portfolioStatus.metricsStatus.action,
      lastUpdatedAt: new Date(portfolioStatus.metricsStatus.lastUpdatedAt),
    }

    return metricsStatus
  }

  static normalizeIndexUpdate<T extends IndexUpdate>(
    result: string,
    entities: any
  ): T {
    const update = entities.updates[result]

    let holdingData
    if (update.companyDatum || update.fundPortfolio) {
      holdingData = update.companyDatum
        ? update.companyDatum
        : {
            ...update.fundPortfolio,
            type: PortfolioTypes.FUND,
          }
    }

    const item = this.getIndexUpdateItem(update, entities)

    return {
      ...update,
      attachments: update.attachments?.map(
        (attId: string) => entities.attachments[attId]
      ),
      contents: update.s3Contents?.map(
        (contentId: string) => entities.s3Contents[contentId]
      ),
      loggingUpdateSchedule:
        entities.loggingUpdateSchedules?.[update.loggingUpdateSchedule] ?? {},
      taggings: update.taggings?.map(
        (taggingId: string) => entities.taggings[taggingId]
      ),
      updateType: parseUpdateType(update.type),

      loggingUpdateGroups: update.loggingUpdateGroups.map(
        (loggingUpdateGroupId: string) =>
          entities.loggingUpdateGroups[loggingUpdateGroupId]
      ),
      loggingUpdateUsers: update.loggingUpdateUsers.map(
        (loggingUpdateUserId: string) =>
          entities.loggingUpdateUsers[loggingUpdateUserId]
      ),
      userId: update.userId ?? update.user?.id ?? update.user,
      isPortfolioUpdate: !!update.portfolio || !!update.fundPortfolio,
      item,
      iueReport:
        typeof update.iueReport === 'string'
          ? entities.iueReports?.[update.iueReport]
          : update.iueReport,
      holding: holdingData ? this.normalizeHolding(holdingData) : undefined,
      ...this.normalizeDraftHash(update, item, entities),
    }
  }

  static normalizeScheduledUpdate(
    result: string,
    entities: any
  ): ScheduledUpdate {
    const update = entities.updates[result]

    const isDraftUpdate = Object.keys(update.draftHash || {}).length > 0
    const isEditBlocked = !!(update.draftHash as DraftHashEditor)?.userEditing

    return {
      ...update,
      updateType: parseUpdateType(update.type),
      loggingUpdateGroups: update.loggingUpdateGroups.map(
        (loggingUpdateGroupId: string) =>
          entities.loggingUpdateGroups[loggingUpdateGroupId]
      ),
      loggingUpdateUsers: update.loggingUpdateUsers.map(
        (loggingUpdateUserId: string) =>
          entities.loggingUpdateUsers[loggingUpdateUserId]
      ),
      isDraftUpdate: isDraftUpdate && !isEditBlocked,
      isEditBlocked,
    }
  }

  static normalizeCompany(id: string, entities: any): Company {
    return entities.portfolioCompanies[id] as Company
  }

  static normalizeTransactionItem(
    transactionItemId: string,
    entities,
    updateId?: string
  ): TransactionItem {
    const transaction =
      entities.transactions?.[transactionItemId] ??
      entities.loggingTransactions?.[transactionItemId]

    let transactionVehicle =
      transaction.portfolioVehicleInvestorTransaction ??
      transaction.portfolioVehicleInvestorTransactions?.[0]

    if (typeof transactionVehicle === 'string') {
      transactionVehicle =
        entities.portfolioVehicleInvestorTransactions[transactionVehicle]
    }

    const investmentVehicle = transactionVehicle?.investmentVehicle

    const investor = transactionVehicle?.investorGroup

    return {
      ...transaction,
      instrumentType: transaction.instrumentFormattedType,
      investor,
      investmentVehicle,
      transactionFundType:
        transaction.associationType === AssociationType.VEHICLE
          ? TransactionFundType.INVESTOR
          : TransactionFundType.HOLDING,
      portfolioVehicleInvestorTransaction: {
        portfolioVehicleInvestorTransactionId: transactionVehicle?.id,
        fundPortfolioInvestorId: transactionVehicle?.fundPortfolioInvestorId,
        fundPortfolioId:
          transactionVehicle?.fundPortfolioInvestor?.fundPortfolioId,
        investmentVehicleInvestorId:
          transactionVehicle?.investmentVehicleInvestorId,
        id: transactionVehicle?.id || '',
      },
      loggingUpdateId: updateId || transaction.loggingUpdateId,
      amount:
        transaction.amountInvested ||
        transaction.amountCommitted ||
        transaction.amountDistributed ||
        0,
    }
  }

  static getUpdateItem<K extends UpdateItem>(update, entities): K {
    let item
    switch (update.type) {
      case PlainUpdateTypes.NOTE:
        item = entities.notes[update.note]
        return {
          ...item,
          investor: item.investorGroup,
        } as K
      case PlainUpdateTypes.DOCUMENT:
        item = entities.documents[update.document]
        return {
          ...item,
          date: update.date,
          investor: item.investorGroup,
        } as K
      case PlainUpdateTypes.ANNOUNCEMENT:
        return {
          ...entities.announcements[update.announcement],
          date: update.date,
        } as K
      case PlainUpdateTypes.TRANSACTION:
        item = this.normalizeTransactionItem(
          update.transaction,
          entities,
          update.id
        ) as unknown
        return item as K
      case PlainUpdateTypes.IUE:
        return entities.iueReports[update.iueReport] as K
      case PlainUpdateTypes.QUICKBOOKS_REPORT:
        item = entities.quickbooksReports[update.quickbooksReport]
        return {
          ...item,
          reportType: getAccountingReportType(
            item.reportType,
            Integrations.QUICKBOOKS
          ),
          integrationType: Integrations.QUICKBOOKS,
        } as K
      case PlainUpdateTypes.XERO_REPORT:
        item = entities.xeroReports[update.xeroReport]
        return {
          ...item,
          reportType: getAccountingReportType(
            item.reportType,
            Integrations.XERO
          ),
          integrationType: Integrations.XERO,
        } as K
      default:
        throw new Error(`Unexpected update type ${update.type}`)
    }
  }

  static normalizeDraftTransactionItem(update) {
    const isDraftUpdate = Object.keys(update.draftHash || {}).length > 0

    if (isDraftUpdate) {
      const draftTransactionItem = {
        ...update.transaction,
        portfolioNamesAndTypes:
          update.portfoliableDraft?.map(
            ({ name, type: draftPortfolioType }) => {
              return {
                name,
                type: PortfoliosTypeMap[draftPortfolioType],
              }
            }
          ) || [],
        transactionType:
          update.draftHash.updatableAttributes?.transactionType ||
          update.transaction.transactionType ||
          '',
      }

      return draftTransactionItem
    }

    return update.transaction
  }

  static getIndexUpdateItem<K extends UpdateItem>(update, entities = {}): K {
    switch (update.type) {
      case PlainUpdateTypes.NOTE:
        return update.note as K
      case PlainUpdateTypes.DOCUMENT:
        return update.document as K
      case PlainUpdateTypes.ANNOUNCEMENT:
        return update.announcement as K
      case PlainUpdateTypes.TRANSACTION: {
        const transactionItem =
          typeof update.transaction === 'string'
            ? ResponseNormalizer.normalizeTransactionItem(
                update.transaction,
                entities,
                update.id
              )
            : ResponseNormalizer.normalizeDraftTransactionItem(update)

        transactionItem.portfolioNamesAndTypes =
          transactionItem.portfolioNamesAndTypes?.map((portfolioInfo) => ({
            ...portfolioInfo,
            type: getPortfolioTypeFromApiType(portfolioInfo.type),
          }))

        return transactionItem
      }
      case PlainUpdateTypes.IUE:
        return update.iueReport as K
      case PlainUpdateTypes.QUICKBOOKS_REPORT:
        return update.quickbooksReport as K
      case PlainUpdateTypes.XERO_REPORT:
        return update.xeroReport as K
      default:
        throw new Error(`Unexpected update type ${update.type}`)
    }
  }

  static normalizeGooglePlaceData(
    location: Location | GooglePlaceData
  ): GooglePlaceData | undefined {
    if (!location) return undefined

    if ((location as Location).place) {
      return (location as Location).place
    }

    return location as GooglePlaceData
  }

  static normalizeCompanyData(companyId, entities): CompanyHoldingData {
    const companyData = entities.companyData[companyId]
    const owner = entities.groups[companyData.groupId]

    return {
      ...this.normalizeHolding(companyData),
      owner,
      group: owner,
      industries: companyData.industries.map(
        (industryId) => entities.industries[industryId]
      ),
      sectors: companyData.sectors.map(
        (sectorId) => entities.sectors[sectorId]
      ),
      primaryLocation: this.normalizeGooglePlaceData(
        entities.primaryLocations[companyData.primaryLocation]
      ),
      portfolios: companyData.holdingPortfolios?.map(
        (portfolioId) => entities.holdingPortfolios[portfolioId]
      ),
      employees: companyData.employees?.map(
        (employeeId) => entities.employees[employeeId]
      ),
      tags: getTagsFromTaggings(Object.values(entities.taggings)),

      legalStructure: entities.legalStructures[companyData.legalStructure],

      investmentToolIndustries: companyData.investmentToolIndustries.map(
        (industryId) => entities.investmentToolIndustries[industryId]
      ),

      locationEntities: companyData.locations.map(
        (locationId) => entities.locations[locationId]
      ),
    } as CompanyHoldingData
  }

  static normalizeLocation(location, entities): Location {
    return {
      ...location,
      place: entities.places[location.place],
    }
  }

  static normalizeIndexInvestmentVehicle<T extends BaseInvestmentVehicle>(
    vehicle: string,
    entities
  ): T {
    const vehicleData = entities.investmentVehicles[vehicle]

    const uniquePortfolios = Array.from(
      new Set<string>(vehicleData.fundPortfolios ?? [])
    )
    return {
      ...vehicleData,
      totalCapital: vehicleData.portfoliosTotalCapitalCalled,
      totalCommitment: vehicleData.portfoliosTotalCommittedCapital,
      unfundedCommitment:
        vehicleData.portfoliosInvestorsTotalUnfundedCommitment,
      distributions: vehicleData.portfoliosTotalDistributions,
      totalInvestment: vehicleData.portfoliosTotalInvestments,
      portfolios:
        uniquePortfolios.map((portfolioId) => {
          return entities.fundPortfolios[portfolioId]
        }) ?? [],
      investmentVehicleInvestor:
        entities.investmentVehicleInvestors?.[
          vehicleData.investmentVehicleInvestor
        ],
      investorGroup: entities.investorGroups[vehicleData.investorGroup],
    }
  }

  static normalizeIndexInvestor(investor: string, entities): IndexInvestor {
    const {
      id,
      logo,
      name,
      invstorType,
      fundPortfolios: investorFoundPortfolios,
      portfoliosTotalCommittedCapital,
      portfoliosTotalCapitalCalled,
      investorGroupUsers,
    } = entities.investorGroups[investor]

    return {
      ...entities.investorGroups[investor],
      id,
      logo,
      name,
      invstorType,
      portfolios:
        investorFoundPortfolios?.map(
          (portfolioId) => entities.fundPortfolios[portfolioId]
        ) ?? [],
      totalCapital: portfoliosTotalCapitalCalled || 0,
      totalCommitment: portfoliosTotalCommittedCapital || 0,
      investorGroupUsers:
        investorGroupUsers?.map(
          (userId) => entities.investorGroupUsers[userId]
        ) ?? [],
    }
  }

  static normalizeInvestor(investorId: string, entities): Investor {
    const {
      angellistUrl,
      crunchbaseUrl,
      linkedinUrl,
      twitterUrl,
      description,
      legalEntityName,
      email,
      phone,
      website,
      employeesNumber,
      financial,
      employees,
      investmentToolIndustries,
      investmentVehicles,
      portfoliosTotalCommittedCapital,
      portfoliosTotalCapitalCalled,
      investorGroupUsers,
      userEmails,
      locations,
      legalStructure,
    } = entities.investorGroups[investorId]

    const sortedEmployees = sortBy<Employee>(
      employees
        .filter((employeeId) => !!employeeId)
        .map((employeeId) => entities.employees[employeeId]),
      (employee) => employee.position
    )

    const contactDetails: ContactDetails = {
      angellistUrl,
      crunchbaseUrl,
      linkedinUrl,
      twitterUrl,
    }

    const generalInformation: GeneralInformation = {
      address:
        locations.map((locationId) => entities.locations[locationId])[0]?.place
          ?.formattedAddress || '',
      investmentToolIndustries: investmentToolIndustries.map(
        (industryId) => entities.investmentToolIndustries[industryId]
      ),
      legalName: legalEntityName!,
      email: email!,
      phone: phone!,
      website: website!,
      teamSize: employeesNumber,
    }

    const investor = entities.investorGroups[investorId]
    // for some reasons sometime the service returns duplicated investment vehicles
    // it just avoid that
    const uniqueInvestmentVehiclesIds = new Set(investmentVehicles).values()

    const normalizedInvestmentVehicles = Array.from(
      uniqueInvestmentVehiclesIds
    ).map((vehicleId: string) =>
      ResponseNormalizer.normalizeIndexInvestmentVehicle<InvestorInvestmentVehicle>(
        vehicleId,
        entities
      )
    )

    const normalizedInvestor: Investor = {
      ...investor,
      totalCapital: portfoliosTotalCapitalCalled || 0,
      totalCommitment: portfoliosTotalCommittedCapital || 0,
      foundedDate: investor.foundedDate
        ? getDateIgnoringTimezone(investor.foundedDate)
        : undefined,
      contactDetails,
      description,
      financialInformation: entities.financials?.[financial],
      generalInformation,
      investmentVehicles: normalizedInvestmentVehicles,
      employees: sortedEmployees,
      investorGroupUsers:
        investorGroupUsers.map(
          (userId) => entities.investorGroupUsers?.[userId]
        ) ?? [],
      emails: userEmails,
      locations: locations.map((locationId) => entities.locations[locationId]),
      legalStructure: entities.legalStructures[legalStructure],
    }

    return normalizedInvestor
  }

  static normalizeInvestmentVehicle(
    vehicleId: string,
    entities: any
  ): InvestmentVehicle {
    const vehicleData = entities.investmentVehicles[vehicleId]

    const financialInformation: FinancialInformation =
      entities.financials?.[vehicleData.financial]

    const investmentVehicleInvestor: InvestmentVehicleInvestor =
      entities.investmentVehicleInvestors?.[
        vehicleData.investmentVehicleInvestor
      ]

    return {
      ...vehicleData,
      ...this.normalizeIndexInvestmentVehicle<IndexInvestmentVehicle>(
        vehicleId,
        entities
      ),
      investorGroup: this.normalizeIndexInvestor(
        vehicleData.investorGroup,
        entities
      ),
      investmentVehicleInvestor,
      locations: vehicleData.locations.map(
        (locationId) => entities.locations[locationId]
      ),
      financialInformation,
    }
  }

  static normalizeFundPortfolioInvestor(
    fundPortfolioInvestorId: string,
    entities: any
  ): FundPortfolioInvestor {
    const {
      fundPortfolioName,
      fundPortfolioId,
      investorCapitalCalled,
      investorCommittedCapital,
      investorDistributions,
      investorProRataHoldingValue,
      investorTotalInvestments,
      investorUnfundedCommitment,
      investorGroupId,
      investorGroupName,
      investorGroupLogoUrl,
      fundPortfolioType,
    } = entities.fundPortfolioInvestors[fundPortfolioInvestorId]

    return {
      fundPortfolioType,
      fundPortfolioId,
      fundPortfolioName: fundPortfolioName || investorGroupName,
      id: fundPortfolioInvestorId,
      investorGroupId,
      investorGroupName,
      investorCapitalCalled,
      investorCommittedCapital,
      investorDistributions,
      investorProRataHoldingValue,
      investorTotalInvestments,
      investorUnfundedCommitment,
      investorGroupLogo: {
        smallLogo: { url: investorGroupLogoUrl },
        mediumLogo: { url: investorGroupLogoUrl },
        largeLogo: { url: investorGroupLogoUrl },
        url: investorGroupLogoUrl,
      },
    }
  }

  static normalizeHolding(holding: any): Holding {
    const holdingTypes = {
      [PortfolioTypes.DEAL]: HoldingType.DEAL,
      [PortfolioTypes.FUND]: HoldingType.FUND,
    }

    const primaryLocation = holding.primaryLocation
      ? this.normalizeGooglePlaceData(holding.primaryLocation)
      : undefined

    if (holding.logoFullUrl) {
      return {
        ...holding,
        primaryLogo: {
          smallLogo: { url: holding.logoFullUrl },
          mediumLogo: { url: holding.logoFullUrl },
          largeLogo: { url: holding.logoFullUrl },
          url: holding.logoFullUrl,
        },
        primaryLocation,
        holdingType: holdingTypes[holding.type] || HoldingType.COMPANY,
      }
    }

    return {
      ...holding,
      primaryLocation,
      holdingType: holdingTypes[holding.type] || HoldingType.COMPANY,
    }
  }

  static normalizePublicFundProfile(
    profileId: string,
    entities: any
  ): FundProfile {
    const profile =
      entities.fundPortfolios?.[profileId] ??
      entities.dealPortfolios?.[profileId]
    const { taggings, ...rest } = profile
    return {
      ...rest,
      fundCompany: entities.fundCompanies?.[profile.fundCompany],
      group: entities.groups?.[profile.group],
      tags: getTagsFromTaggings(Object.values(entities.taggings)),
    }
  }

  static normalizeFundPortfolioProfile(
    ids: string[],
    entities: { fundPortfolios: Record<string, CreateFundPortfolioResponse> }
  ): CreateFundPortfolioResponse[] {
    return ids.map((id) => entities.fundPortfolios[id])
  }

  static normalizeContentAnalytic(id: string, entities: any): ContentAnalytic {
    const analytic = entities.contentUserMetrics[id]
    return {
      ...analytic,
      user: entities.users[analytic.user],
      accessedGroups: entities.accessedGroups[analytic.group],
    }
  }

  static normalizePortfolioHolding(
    id: string,
    entities: any
  ): PortfolioHolding {
    const entity = entities[id]
    const totalFiles =
      entity.totalPdfs +
      entity.totalImages +
      entity.totalDocuments +
      entity.totalSpreadsheets +
      entity.totalPresentations +
      entity.totalAudios +
      entity.totalVideos +
      entity.totalCompressedFiles +
      entity.totalOtherTypes

    return {
      id,
      totalFiles,
      ...entity,
    }
  }
}
