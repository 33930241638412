import { SortDirection } from 'types/graphql-schemas/graphql'
import { SubjectFilter } from 'utils/hooks/queries/subjects/useSubjectsQuery'
import { SubjectType } from 'utils/types/subjects'

interface FilterByProps {
  filters: Partial<SubjectFilter>
  sorting?: {
    orderBy?: string
    direction?: SortDirection
  }
}

type SubjectsQueryKey = (SubjectType | SubjectType[] | string | object)[]

export const metricsSubjectKeys = {
  byMetricSubject: (id: string, type?: string) => [id, type] as const,
}

export const subjectsKeys = {
  byId: (id: string) => ['subjects', id] as const,
  byFilters: ({ filters, sorting = {} }: FilterByProps) => {
    const { type, name = '', ...others } = filters
    const sort = Object.keys(sorting).length ? sorting : null

    const othersKey: Record<keyof SubjectFilter, any> = Object.keys(
      others
    ).reduce((acc, key) => {
      if (others[key] !== undefined) {
        acc[key] = others[key]
      }
      return acc
    }, {})

    let typeKey = type

    if (Array.isArray(type)) {
      typeKey = [...type].sort()
    }

    const key: SubjectsQueryKey = ['subjects']

    if (typeKey) {
      key.push(typeKey)
    }

    if (name) {
      key.push(name)
    }

    if (Object.keys(othersKey).length) {
      key.push(othersKey)
    }

    if (sort) {
      key.push(sort)
    }

    return key
  },
}
