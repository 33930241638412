import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import MetricsSandbox from 'containers/Metrics/MetricsSandbox'
import { useSubjectContext } from 'utils/context/subjects/SubjectContext'
import { AllMetricsListContextProvider } from 'utils/context/metrics/AllMetricsListContext'
import SelectedMetrics from 'containers/Metrics/SelectedMetrics'
import AllMetricsTable from 'containers/Metrics/AllMetrics/AllMetricsTable'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getSelectedMetricsCountByHoldingId } from 'reducers/selectedMetricsSlice'
import Tabs from 'components/Tabs'
import { ProfileMetricsContextProvider } from './useProfileMetricsContext'
import { AllMetricsContainer } from './ProfileMetrics.styles'

type Tab = {
  id: string
  title: string
}

const ProfileMetrics: React.FC = () => {
  const intl = useIntl()

  const { railsId: subjectId } = useSubjectContext()

  const count = useAppSelector(getSelectedMetricsCountByHoldingId(subjectId))

  const TABS_BY_COMPANY_ID = useMemo(() => {
    return {
      SELECTED_METRICS: {
        id: 'SELECTED_METRICS',
        url: `/companies/${subjectId}/metrics/selected`,
      },
      ALL_METRICS: {
        id: 'ALL_METRICS',
        url: `/companies/${subjectId}/metrics/all`,
      },
    } as const
  }, [subjectId])

  const tabs = useMemo(() => {
    const currentTabs: Tab[] = [
      {
        id: TABS_BY_COMPANY_ID.SELECTED_METRICS.id,
        title: `${intl.formatMessage({ id: 'metrics.tabs.selectedMetrics' })}${
          count ? ` (${count})` : ''
        }`,
      },
      {
        id: TABS_BY_COMPANY_ID.ALL_METRICS.id,
        title: intl.formatMessage({ id: 'metrics.tabs.allMetrics' }),
      },
    ]

    return currentTabs
  }, [
    TABS_BY_COMPANY_ID.ALL_METRICS.id,
    TABS_BY_COMPANY_ID.SELECTED_METRICS.id,
    intl,
    count,
  ])
  return (
    <ProfileMetricsContextProvider holdingId={subjectId}>
      <MetricsSandbox />
      <Tabs withBorder initialIndex={0} items={tabs}>
        <Tabs.TabContent id={TABS_BY_COMPANY_ID.SELECTED_METRICS.id}>
          <SelectedMetrics />
        </Tabs.TabContent>

        <Tabs.TabContent id={TABS_BY_COMPANY_ID.ALL_METRICS.id}>
          <AllMetricsListContextProvider>
            <AllMetricsContainer>
              <AllMetricsTable />
            </AllMetricsContainer>
          </AllMetricsListContextProvider>
        </Tabs.TabContent>
      </Tabs>
    </ProfileMetricsContextProvider>
  )
}

export default ProfileMetrics
