import React, { ReactNode } from 'react'

import Tooltip, { PlacesType } from 'components/Tooltip'
import { FormattedMessage, useIntl } from 'react-intl'
import { BasicSubjectFragment } from 'types/graphql-schemas/graphql'
import { SubjectType } from 'utils/types/subjects'
import { getAttribute } from 'utils/gql/helpers/subjects'
import TooltipContent from 'containers/Chat/components/TooltipContent'
import {
  Legend,
  PendingValidationBadge,
  PrivateCompanyBadge,
  TooltipWrapper,
} from './SubjectStatus.styles'

interface SubjectStatusProps {
  subject: BasicSubjectFragment
  displayLastUpdate?: boolean
  tooltipPlace?: PlacesType
  statusMargin?: string
}

const SubjectStatus: React.FC<SubjectStatusProps> = ({
  subject,
  displayLastUpdate = false,
  tooltipPlace,
  ...rest
}) => {
  const intl = useIntl()
  const isFundPortfolio = subject.type === SubjectType.FUND
  const isDealPortfolio = subject.type === SubjectType.DEAL
  const isCompany = subject.type === SubjectType.COMPANY

  let qualityAssuredValue
  let isPrivate
  let label: ReactNode
  let tooltipText: string = ''

  if (isCompany) {
    qualityAssuredValue = getAttribute<boolean>(
      subject,
      'qualityAssured'
    )?.value
    isPrivate = getAttribute<boolean>(subject, 'private')?.value
  }
  if (!isFundPortfolio && !isDealPortfolio) {
    if (qualityAssuredValue) {
      if (isPrivate) {
        tooltipText = intl.formatMessage({
          id: 'companyList.privateCompanyTooltip',
        })
        label = (
          <PrivateCompanyBadge>
            <FormattedMessage id="companyList.privateProfile" />
          </PrivateCompanyBadge>
        )
      }
    } else {
      tooltipText = intl.formatMessage({
        id: 'companyList.pendingValidationTooltip',
      })
      label = (
        <PendingValidationBadge>
          <FormattedMessage id="companyList.pendingValidation" />
        </PendingValidationBadge>
      )
    }
  }

  return label ? (
    <Legend {...rest}>
      <TooltipWrapper>
        <Tooltip
          id={subject.id}
          place={tooltipPlace || 'bottom'}
          delayShow={500}
          event="mouseover mouseenter"
          eventOff="mouseleave mouseout scroll mousewheel blur"
          backgroundColor="transparent"
          text={<TooltipContent>{tooltipText}</TooltipContent>}
        >
          {label}
        </Tooltip>
      </TooltipWrapper>
    </Legend>
  ) : null
}

export default SubjectStatus
