import { useMemo } from 'react'

import { BasicSubjectFragment } from 'types/graphql-schemas/graphql'
import { useSubjectsQuery } from 'utils/hooks/queries/subjects/useSubjectsQuery'

const PAGE_SIZE = 50

type Params<T> = {
  data?: T[]
  getRailsId: (item: T) => string
  isLoadingData?: boolean
}
/**
 * Hook to fetch and populate the data with the corresponding subjects.
 * If the number of subjects to fetch exceeds the page size, it will fetch the next page until all subjects are fetched.
 *
 * @param data - The data to populate
 * @param getRailsId - A function to extract the rails id from the data
 * @returns An array with the data populated with the subjects
 *
 */
export function useDataWithSubjects<T>({ data, getRailsId }: Params<T>) {
  const subjectIds = useMemo(
    // TODO: remove filter(Boolean) once fund portfolio and investors come with externalSubjectId inside the portfolio
    () => {
      return data?.map(getRailsId)?.filter(Boolean)
    },
    [data, getRailsId]
  )

  const filters = useMemo(() => ({ railsId: subjectIds }), [subjectIds])

  const { data: subjectsQuery, isLoading } = useSubjectsQuery(
    {
      filters,
      pageSize: PAGE_SIZE,
      fetchAll: true,
    },
    {
      skip: !subjectIds?.length,
    }
  )

  const dataWithSubjects = useMemo(() => {
    return (
      data?.map<
        T & {
          subject?: BasicSubjectFragment
        }
      >((entity) => {
        const subjectsFetched =
          subjectsQuery?.pages?.flatMap((page) => page) ?? []
        const subject = subjectsFetched.find(
          (fetchedSubject) => fetchedSubject.railsId === getRailsId(entity)
        )

        return {
          ...entity,
          subject,
        }
      }) || []
    )
  }, [data, getRailsId, subjectsQuery])

  return {
    dataWithSubjects,
    loadingSubjects: isLoading,
  }
}
