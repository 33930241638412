import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import Toast from 'components/Toast'
import { sortBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { getPortfolioInvestorSettings } from 'selectors/portfolio'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import CompanyService from 'api/CompanyService'
import PortfolioService, { OrderByProps } from 'api/PortfolioService'
import { CreatePortfolioFromFilterFormValues } from 'containers/Investments/CustomFilterView/Modals/CreateNewPortfolioModal/useSaveAsNewPortfolioModal'
import { InvestmentsNavigationOption } from 'containers/Investments/types'
import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { companyKeys } from 'utils/queries/companies'
import { metricsKeys } from 'utils/queries/metrics'
import { portfolioKeys } from 'utils/queries/portfolios'
import { Nullable } from 'utils/types/common'
import { CompanyData, FundProfile } from 'utils/types/company'
import {
  FairMarketValue,
  IndexPortfolio,
  Portfolio,
  PortfolioCompany,
  PortfolioCompanyWithFmv,
  PortfolioType,
} from 'utils/types/portfolios'
import { SortDirection } from "types/graphql-schemas/graphql"

type PortfolioCompanyFairMarketValues = {
  id: string
  fairMarketValues: FairMarketValue[]
}

export const mapFmvsToPortfolioCompanies = (
  portfolioCompanies: PortfolioCompany[],
  fmvs: PortfolioCompanyFairMarketValues[]
): PortfolioCompanyWithFmv[] => {
  return portfolioCompanies.map((portfolioCompany) => {
    const portfolioCompanyFmvs =
      fmvs.find((companyFmv) => companyFmv.id === portfolioCompany.id)
        ?.fairMarketValues ?? []

    const companyFmvs = sortBy(
      portfolioCompanyFmvs,
      (fmv) => -new Date(fmv.date)
    )

    return {
      ...portfolioCompany,
      fairMarketValues: companyFmvs,
    }
  })
}

export const checkIfPortfolioNameExists = async (
  name: string
): Promise<boolean> => {
  if (!name) return false

  const portfolios: Portfolio[] =
    await PortfolioService.getPortfoliosByName(name)

  return portfolios.length > 0
}

export function usePublicFundProfileQuery(
  portfolioId: string,
  queryOptions = {}
) {
  const { data: publicFundProfile } = useQuery<FundProfile>(
    portfolioKeys.publicFundProfileById(portfolioId),
    () => {
      return PortfolioService.getPublicFundProfile(portfolioId)
    },
    queryOptions
  )

  const fundCompanyId = publicFundProfile?.fundCompany?.id

  const { data: fundCompanyData } = useQuery<CompanyData>(
    companyKeys.byId(fundCompanyId!),
    () => {
      return CompanyService.fetchCompany(fundCompanyId!)
    },
    { enabled: !!fundCompanyId }
  )

  return {
    publicFundProfile,
    fundCompanyData,
  }
}

export function usePortfolioQuery<T extends Portfolio = Portfolio>(
  portfolioId: string,
  portfolioType: PortfolioType,
  queryOptions = {}
) {
  const portfolioInvestorSettings = useAppSelector(
    getPortfolioInvestorSettings(portfolioId)
  )

  const isInvestorCalculations =
    !portfolioInvestorSettings?.isInvestorCalculationDisabled

  const fetchPortfolio = async (): Promise<T> => {
    return PortfolioService.getPortfolio<T>(
      portfolioId,
      portfolioType,
      isInvestorCalculations
    )
  }

  return useQuery(
    portfolioKeys.byId(portfolioId, isInvestorCalculations),
    fetchPortfolio,
    queryOptions
  )
}

export const usePortfolioFmvQuery = (
  portfolioId: string,
  queryOptions = {}
) => {
  const fetchPortfolioFmvs = async (): Promise<
    PortfolioCompanyFairMarketValues[]
  > => {
    return PortfolioService.getPortfolioLastsFairMarketValues(portfolioId)
  }

  return useQuery(
    portfolioKeys.fairMarketValuesByPortfolioId(portfolioId),
    fetchPortfolioFmvs,
    {
      retry: false,
      ...queryOptions,
    }
  )
}

export function usePortfolioWithFmvQuery<T extends Portfolio = Portfolio>(
  portfolioId: string,
  portfolioType: PortfolioType,
  queryOptions = {}
) {
  const portfolioQuery = usePortfolioQuery<T>(
    portfolioId,
    portfolioType,
    queryOptions
  )

  const fmvQuery = usePortfolioFmvQuery(portfolioId, queryOptions)

  const [lastFetchedPortfolio, setLastFetchedPortfolio] =
    useState<Nullable<T>>(null)

  const portfolio = useMemo(() => {
    const portfolioData =
      !portfolioQuery.data && portfolioId === lastFetchedPortfolio?.id
        ? lastFetchedPortfolio
        : portfolioQuery.data

    if (
      !portfolioData ||
      portfolioQuery.isLoading ||
      fmvQuery.isLoading ||
      fmvQuery.isError
    ) {
      return portfolioData
    }

    const portfolioCompanies = mapFmvsToPortfolioCompanies(
      portfolioData.portfolioCompanies,
      fmvQuery.data ?? []
    )

    return { ...portfolioData, portfolioCompanies }
  }, [
    fmvQuery.data,
    fmvQuery.isError,
    fmvQuery.isLoading,
    portfolioQuery.data,
    portfolioQuery.isLoading,
    lastFetchedPortfolio,
    portfolioId,
  ])

  useEffect(() => {
    if (portfolioQuery.data && !portfolioQuery.isLoading) {
      setLastFetchedPortfolio(portfolioQuery.data)
    }
  }, [portfolioQuery.data, portfolioQuery.isLoading])

  return {
    ...portfolioQuery,
    data: portfolio,
  }
}

export const useCreatePortfolioMutationFromFilter = ({
  setFormikNameError,
  callback,
}: {
  setFormikNameError: (error: string) => void
  callback: (portfolio: Portfolio<PortfolioCompany>) => void
}) => {
  const intl = useIntl()

  return useMutation({
    mutationFn: async (data: CreatePortfolioFromFilterFormValues) => {
      const portfolioNameExists = await checkIfPortfolioNameExists(data.name)

      if (portfolioNameExists) {
        setFormikNameError(
          intl.formatMessage({ id: 'createPortfolio.portfolioAlreadyExists' })
        )

        return Promise.reject()
      }

      const portfolio = await PortfolioService.createPortfolio({
        type: data.type,
        name: data.name,
        portfolioCompanyIds: data.portfolioCompanyIds,
        excludedHoldingIds: data.excludedHoldingIds,
        includeTransactions: data.includeTransactions.toString(),
      })

      return callback(portfolio)
    },
    onSuccess: async () => {
      return Toast.displayIntl(
        'createPortfolio.portfolioCreatedSuccess',
        'success'
      )
    },
    onError: () => {
      return Toast.displayIntl('createPortfolio.createPortfolioError', 'error')
    },
  })
}

export const useChangePortfolioNameMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      portfolio,
      value,
    }: {
      portfolio: IndexPortfolio
      value: string
    }) => {
      if (value.length > MAX_COUNTER_CHARACTERS) return

      if (portfolio.type === PortfolioTypes.TRACK) {
        await PortfolioService.editTrackPortfolio(portfolio.id, {
          name: value,
        })
      }

      if (portfolio.type === PortfolioTypes.INVEST) {
        await PortfolioService.editInvestPortfolio(portfolio.id, {
          name: value,
        })
      }

      if (
        portfolio.type === PortfolioTypes.FUND ||
        portfolio.type === PortfolioTypes.DEAL
      ) {
        await PortfolioService.editFundPortfolio(portfolio.id, {
          name: value,
        })
      }
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries(
        portfolioKeys.fundPortfolioHoldings({
          direction: SortDirection.Desc,
          orderBy: OrderByProps.TYPE,
          perPage: 500,
        })
      )
    },

    onError: () => {
      Toast.displayIntl('portfolioDetail.editPortfolioNameError', 'error')
    },
  })
}

export const usePinnedPortfolioMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      option,
      value,
    }: {
      option: InvestmentsNavigationOption
      value: boolean
    }) => {
      if (option.type === PortfolioTypes.TRACK) {
        await PortfolioService.editTrackPortfolio(option.id, {
          pinned: value,
        })
      }

      if (option.type === PortfolioTypes.INVEST) {
        await PortfolioService.editInvestPortfolio(option.id, {
          pinned: value,
        })
      }

      if (
        option.type === PortfolioTypes.FUND ||
        option.type === PortfolioTypes.DEAL
      ) {
        await PortfolioService.editFundPortfolio(option.id, {
          pinned: value,
        })
      }

      queryClient.invalidateQueries(
        portfolioKeys.fundPortfolioHoldings({
          direction: SortDirection.Desc,
          orderBy: OrderByProps.TYPE,
          perPage: 500,
        })
      )
    },
    onError: () => {
      Toast.displayIntl('portfolioDetail.pinnedPortfolioError', 'error')
    },
  })
}

export const useDeletePortfolioMutation = () => {
  const queryClient = useQueryClient()
  const history = useHistory()

  return useMutation({
    mutationFn: async ({
      portfolioId,
      portfolioType,
    }: {
      portfolioId: string
      portfolioType: PortfolioTypes
    }) => {
      return PortfolioService.removePortfolio(portfolioId, portfolioType)
    },

    onSuccess: async () => {
      queryClient.removeQueries(metricsKeys.getMetrics())
      await queryClient.invalidateQueries(
        portfolioKeys.fundPortfolioHoldings({
          direction: SortDirection.Desc,
          orderBy: OrderByProps.TYPE,
          perPage: 500,
        })
      )

      history.push('/investments')
      Toast.displayIntl('portfolioDetail.portfolioRemoved', 'success')
    },
    onError: () => {
      Toast.displayIntl('portfolioDetail.errorRemoving', 'error')
    },
  })
}
