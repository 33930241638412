import Title from 'components/Title'
import dayjs from 'dayjs'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import InputLabel from 'ui/InputLabel'
import Separator from 'ui/Separator'
import { formatPercentage } from 'utils/functions/number'
import {
  instrumentFieldIsDefined,
  instrumentHasData,
} from 'utils/functions/transactions'
import theme from 'utils/theme'
import { CreateTransactionFormValues } from 'utils/types/updateForm'
import UpdateText from 'containers/UpdatesV2/ShowUpdateContent/UpdateText'
import {
  Content,
  Row,
  TransactionContent,
} from '../ShowTransactionContent.styles'

const ConvertibleNoteInstrumentContent = ({
  transaction,
}: {
  transaction: CreateTransactionFormValues
}) => {
  if (
    !instrumentFieldIsDefined(transaction.description) &&
    !instrumentHasData(transaction.instrument!, transaction)
  ) {
    return null
  }

  return (
    <TransactionContent>
      <Row gridColumn="1 / 3">
        <Title
          title={<FormattedMessage id="transactions.additionalInformation" />}
          color={theme.colors.lightGray}
        />
        <Separator />
      </Row>

      {instrumentFieldIsDefined(transaction.description) && (
        <Row gridColumn="1 / 3">
          <InputLabel marginLeft="1.4rem">
            <FormattedMessage id="updates.description" />
          </InputLabel>
          <Content>
            <UpdateText text={transaction.description} />
          </Content>
        </Row>
      )}

      {instrumentFieldIsDefined(transaction?.valuationCap) && (
        <Row>
          <InputLabel marginLeft="1.4rem">
            <FormattedMessage id="transactions.valuationCap" />
          </InputLabel>
          <Content capitalize>
            $
            <FormattedNumber
              value={transaction?.valuationCap || 0}
              maximumFractionDigits={2}
            />
          </Content>
        </Row>
      )}

      {instrumentFieldIsDefined(transaction?.discountRate) && (
        <Row>
          <InputLabel marginLeft="1.4rem">
            <FormattedMessage id="transactions.discountRate" />
          </InputLabel>
          <Content capitalize>
            {formatPercentage(transaction?.discountRate)}
          </Content>
        </Row>
      )}

      {instrumentFieldIsDefined(transaction?.interestRate) && (
        <Row>
          <InputLabel marginLeft="1.4rem">
            <FormattedMessage id="transactions.interestRate" />
          </InputLabel>
          <Content capitalize>
            {formatPercentage(transaction?.interestRate)}
          </Content>
        </Row>
      )}

      {instrumentFieldIsDefined(transaction?.interestCalculationBasis) && (
        <Row>
          <InputLabel marginLeft="1.4rem">
            <FormattedMessage id="transactions.interestCalculationBasis" />
          </InputLabel>
          <Content capitalize>{transaction?.interestCalculationBasis}</Content>
        </Row>
      )}

      {instrumentFieldIsDefined(transaction?.maturityDate) && (
        <Row>
          <InputLabel marginLeft="1.4rem">
            <FormattedMessage id="transactions.maturityDate" />
          </InputLabel>
          <Content capitalize>
            {dayjs(transaction?.maturityDate).format('DD MMM, YYYY')}
          </Content>
        </Row>
      )}
    </TransactionContent>
  )
}

export default ConvertibleNoteInstrumentContent
