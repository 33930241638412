import React from 'react'
import PropTypes from 'prop-types'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Card from 'components/Card'
import EmailSectionAccordion from 'components/EmailSectionAccordion'
import EmailSectionsLoader from 'components/loaders/emailSectionsLoader'
import { ReactDndDroppableWrapper } from 'components/ReactDndDroppableWrapper'
import Button from 'components/Button'

import styles from './SelectSections.module.scss'

const SelectSections = ({
  emailSections = [],
  isLoading,
  handleRemoveSection,
  onAddCustomSectionToRemove,
  onRemoveCustomSectionToRemove,
  handleChangeDraftSectionName,
  onAddNewSection,
  onSaveNewDraftSection,
  onDiscardNewDraftSection,
}) => {
  const handleChangeSectionName = (sectionId, event) => {
    handleChangeDraftSectionName(sectionId, event)
  }
  return (
    <Card>
      <Card.Header>
        <FormattedMessage id="createEmail.selectSections" />
      </Card.Header>
      <ReactDndDroppableWrapper droppableId="emailSections">
        {(draggableProvided) => (
          <Card.Body
            id="sectionsBody"
            ref={draggableProvided.innerRef}
            className={styles.emailSelectionCardBody}
          >
            {isLoading ? (
              <EmailSectionsLoader />
            ) : (
              emailSections.map((emailSection, index) => {
                const accordion = (
                  <EmailSectionAccordion
                    emailSectionId={emailSection.id}
                    handleRemoveSection={handleRemoveSection}
                    name={emailSection.title}
                    onAddCustomSectionToRemove={onAddCustomSectionToRemove}
                    onRemoveCustomSectionToRemove={
                      onRemoveCustomSectionToRemove
                    }
                    showUndoAction
                    isSelectSections
                    isDraftMode={emailSection.isDraftMode}
                    onSaveNewDraftSection={onSaveNewDraftSection}
                    onDiscardNewDraftSection={onDiscardNewDraftSection}
                    handleChangeSectionName={
                      emailSection.isDraftMode ? handleChangeSectionName : null
                    }
                    showTitle={emailSection.showTitle}
                  />
                )
                return emailSection.isDraftMode ? (
                  <div className={styles.emailSectionAccordionWrapper}>
                    {accordion}
                  </div>
                ) : (
                  <Draggable
                    draggableId={`emailSection-${emailSection.id}`}
                    index={index}
                    key={emailSection.id}
                  >
                    {(provided) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={styles.emailSectionAccordionWrapper}
                        >
                          {accordion}
                          {provided.placeholder}
                        </div>
                      )
                    }}
                  </Draggable>
                )
              })
            )}
            {draggableProvided.placeholder}
          </Card.Body>
        )}
      </ReactDndDroppableWrapper>
      <div className={styles.addSectionWrapper}>
        <Button
          className={styles.btnAddNewSection}
          primary
          blue
          onClick={() => {
            onAddNewSection()
          }}
        >
          <FontAwesomeIcon icon={['fal', 'plus']} />
          <FormattedMessage id="createEmail.addNew" />
        </Button>
      </div>
    </Card>
  )
}

SelectSections.defaultProps = {
  emailSections: [],
  isLoading: false,
}

SelectSections.propTypes = {
  emailSections: PropTypes.array,
  isLoading: PropTypes.bool,
  handleRemoveSection: PropTypes.func.isRequired,
  onAddCustomSectionToRemove: PropTypes.func.isRequired,
  onRemoveCustomSectionToRemove: PropTypes.func.isRequired,
  handleChangeDraftSectionName: PropTypes.func.isRequired,
  onAddNewSection: PropTypes.func.isRequired,
  onSaveNewDraftSection: PropTypes.func.isRequired,
  onDiscardNewDraftSection: PropTypes.func.isRequired,
}

export default SelectSections
