import { LinkedMetric, MetricSubjectMatterType } from 'utils/types/metricsV2'
import { MetricSources } from 'utils/types/metrics'
import { MetricFrequency } from 'utils/constants/metrics'

export interface DataPointApi {
  id: string
  value: number
  timestamp: string
  updatedAt: string
  sharedGroups: string[]
  groupId?: string
  metadata: {
    archived?: boolean
  }
}

export interface MilestoneApi {
  id: string
  date: string
  metadata: {
    groupId: string
  }
  metricId: string
  notifyPercentageReached: boolean
  notifyValueReached: boolean
  percentage: number
  shared: boolean
  timestamp: string
  value: number
}

export enum CalculationType {
  CUSTOM_FORMULA = 'custom_formula',
  SHALLOW_CALCULATION = 'shallow_calculation',
}

export interface IndexMetricApi {
  id: string
  name: string
  subjectId: string
  subjectType: MetricSubjectMatterType | 'Holding'
  metadata: {
    source: MetricSources
    subjectName: string
    dependingSubjectType?: MetricSubjectMatterType
    dependingSubjectId?: string
    calculationType?: CalculationType
  }
  dataPointObjects: DataPointApi[]
  milestones: string[]
  updatedAt: string
  createdAt: string
  frequency?: MetricFrequency

  // The backend support multiple linked metrics, but the frontend only supports one
  // in the normalizer we will only return the first one
  receiverLinks?: LinkedMetricIndexApi[]
  senderMetrics?: MetricApi[]
  senderLinks?: LinkedMetricIndexApi[]
}

export interface MetricApi extends IndexMetricApi {
  milestones: string[]
  dataPoints: string[]
}

export interface LinkedMetricIndexApi
  extends Omit<LinkedMetric, 'senderMetricId'> {
  senderMetricId: string | 'None'
}
