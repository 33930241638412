import { gql } from 'types/graphql-schemas/gql'

export const GET_MINIMAL_SUBJECTS = gql(/* GraphQL */ `
  query GetMinimalSubjects(
    $limit: Int
    $query: String
    $skip: Int
    $sortBy: String
    $sortDirection: SortDirection
  ) {
    subjects(
      limit: $limit
      query: $query
      skip: $skip
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      id
      railsId
      name
      type
      groupOwner
      logo
    }
  }
`)

export const GET_SUBJECTS = gql(/* GraphQL */ `
  query Subjects(
    $limit: Int
    $query: String
    $skip: Int
    $sortBy: String
    $sortDirection: SortDirection
  ) {
    subjects(
      limit: $limit
      query: $query
      skip: $skip
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      ...BasicSubject
    }
  }
`)

export const GET_FULL_SUBJECTS = gql(/* GraphQL */ `
  query FullSubjects(
    $limit: Int
    $query: String
    $skip: Int
    $sortBy: String
    $sortDirection: SortDirection
  ) {
    subjects(
      limit: $limit
      query: $query
      skip: $skip
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      ...FullSubject
    }
  }
`)

export const GET_COUNT_SUBJECTS = gql(/* GraphQL */ `
  query CountSubjects($query: String) {
    countSubjects(query: $query) {
      count
    }
  }
`)

export const GET_SUBJECT = gql(/* GraphQL */ `
  query Subject($id: String!) {
    subject(id: $id) {
      ...FullSubject
    }
  }
`)
