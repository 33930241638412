import {
  BasicSubjectFragment,
  FullSubjectFragment,
} from 'types/graphql-schemas/graphql'
import { getAttribute } from 'utils/gql/helpers/subjects'
import { Employee, Industry } from 'utils/types/company'
import { IndexInvestmentVehicle } from 'utils/types/investors'
import { Location } from 'utils/types/locations'

export const useInvestorSubjectData = (
  subject?: BasicSubjectFragment | FullSubjectFragment
) => {
  const id = subject?.id || ''
  const address =
    getAttribute<Location>(subject, 'locations')?.value?.[0]
      ?.formattedAddress || ''
  const emails = getAttribute<string[]>(subject, 'emails')?.value || ''
  const description = getAttribute<string>(subject, 'description')?.value || ''
  const employees = getAttribute<Employee[]>(subject, 'employees')?.value || []
  const foundedDate = getAttribute<Date>(subject, 'foundedDate')?.value || ''
  const industries =
    getAttribute<Industry[]>(subject, 'industries')?.value || []
  const investmentVehicles =
    getAttribute<IndexInvestmentVehicle[]>(subject, 'investmentVehicles')
      ?.value || []
  const legalName =
    getAttribute<string>(subject, 'legalEntityName')?.value || ''
  const locations = getAttribute<string[]>(subject, 'locations')?.value || []
  const logo = subject?.logo || ''
  const name = subject?.name || ''
  const phone = getAttribute<string>(subject, 'phone')?.value || ''
  const railsId = subject?.railsId
  const teamSize = getAttribute<number>(subject, 'employeesCount')?.value || 0
  const website = getAttribute<string>(subject, 'website')?.value || ''

  return {
    id,
    address,
    emails,
    employees,
    description,
    foundedDate,
    industries,
    investmentVehicles,
    legalName,
    locations,
    logo,
    name,
    phone,
    railsId,
    teamSize,
    website,
  }
}

export type InvestorSubjectData = ReturnType<typeof useInvestorSubjectData>
