import { useScrollToTop } from 'utils/hooks/useScrollToTop'
import CompanySubjectProfile from 'containers/Profiles/CompanySubjectProfile'
import { useParams } from 'react-router-dom'

const CompanyProfileRouteWrapper = ({
  prefix: _prefix, // TODO: use (or refactor) this prefix when implementing routing navigation on new subjects profiles (TAS-13616)
}: {
  prefix?: string
}) => {
  const { companyId } = useParams<{ companyId: string }>()

  useScrollToTop()

  return <CompanySubjectProfile subjectId={companyId} />
}

export default CompanyProfileRouteWrapper
