/* eslint-disable no-nested-ternary, no-case-declarations */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import companyLogoPlaceholder from 'assets/images/missing.png'
import quickbooks from 'assets/images/quickbooks.svg'
import xero from 'assets/images/xero.svg'
import PortfolioIcon from 'components/PortfolioIcon'

import { CelesteActionType } from 'components/Celeste/components/CelesteActionItem/CelesteActionItem'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'
import styles from './UpdateIconFactory.module.scss'

const UpdateIconFactory = ({
  updateType,
  withCompany,
  withPortfolio,
  withGroup,
  update,
  onClickIcon,
  showSimpleIcon,
  selectionMode,
  hoverState,
  checkState,
  setHoverState,
  updateFeedIcon,
  isDraftUpdate,
}) => {
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const companyLogo =
    withCompany?.primaryLogo?.url === 'missing.png'
      ? companyLogoPlaceholder
      : withCompany?.primaryLogo?.url
  const groupLogo = withGroup?.logo?.url

  const showCompanyLogo = !!Object.keys(withCompany || {}).length
  const showPortfolioLogo = !!Object.keys(withPortfolio || {}).length
  const showGroupLogo = !!Object.keys(withGroup || {}).length

  const displayPortfolioLogo = (updateContainer, icon) => (
    <div className={styles.portfolioIconContainer}>
      {' '}
      <PortfolioIcon
        type={withPortfolio?.type}
        updateFeedIcon={updateFeedIcon}
        investorGroup={update.investorGroup}
      />
      <div className={`${updateContainer} ${styles.withCompanyLogo}`}>
        <FontAwesomeIcon icon={icon} />
      </div>
    </div>
  )

  const displayCompanyLogo = (updateContainer, icon) => {
    const baseHoldingUrl = `/companies/${withCompany.id}`

    return (
      <Link to={baseHoldingUrl}>
        <div
          style={{
            backgroundImage: `url(${companyLogo})`,
          }}
          className={classNames(styles.companyLogoStyles, 'fs-exclude')}
        >
          <div className={`${updateContainer} ${styles.withCompanyLogo}`}>
            <FontAwesomeIcon icon={icon} />
          </div>
        </div>
      </Link>
    )
  }

  const displayGroupLogo = (updateContainer, icon) => {
    return (
      <div
        style={{
          backgroundImage: `url(${groupLogo})`,
        }}
        className={styles.groupLogoStyles}
      >
        <div className={`${updateContainer} ${styles.withGroupLogo}`}>
          <FontAwesomeIcon icon={icon} />
        </div>
      </div>
    )
  }

  switch (updateType) {
    case 'announcement':
    case CelesteActionType.CREATE_ANNOUNCEMENT:
      return showCompanyLogo ? (
        displayCompanyLogo(styles.announcementContainer, ['far', 'megaphone'])
      ) : showPortfolioLogo ? (
        displayPortfolioLogo(styles.announcementContainer, ['far', 'megaphone'])
      ) : showGroupLogo ? (
        displayGroupLogo(styles.announcementContainer, ['far', 'megaphone'])
      ) : (
        <div
          className={classNames(styles.announcementContainer, {
            [styles.draftAnnouncement]: isDraftUpdate,
          })}
        >
          <FontAwesomeIcon icon={['far', 'megaphone']} />
        </div>
      )
    case 'note':
    case CelesteActionType.CREATE_NOTE:
      return showCompanyLogo ? (
        displayCompanyLogo(styles.noteContainer, ['far', 'sticky-note'])
      ) : showPortfolioLogo ? (
        displayPortfolioLogo(styles.noteContainer, ['far', 'sticky-note'])
      ) : showGroupLogo ? (
        displayGroupLogo(styles.noteContainer, ['far', 'sticky-note'])
      ) : (
        <div
          className={classNames(styles.noteContainer, {
            [styles.drafNote]: isDraftUpdate,
          })}
        >
          <FontAwesomeIcon icon={['far', 'sticky-note']} />
        </div>
      )
    case 'report':
    case 'iue':
    case CelesteActionType.CREATE_EMAIL:
      const isDraftEmail = update?.sentAt === null
      return showCompanyLogo ? (
        displayCompanyLogo(styles.iueContainer, ['far', 'envelope'])
      ) : showGroupLogo ? (
        displayGroupLogo(styles.iueContainer, ['far', 'envelope'])
      ) : showSimpleIcon ? (
        <div
          className={classNames(styles.iueContainer, {
            [styles.draftIue]: isDraftUpdate,
          })}
        >
          <FontAwesomeIcon icon={['far', 'envelope']} />
        </div>
      ) : (
        <button
          type="button"
          onFocus={() => {}}
          onMouseOver={() => {
            if (isDraftEmail) {
              setHoverState(true)
            }
          }}
          onMouseLeave={() => {
            if (isDraftEmail) {
              setHoverState(false)
            }
          }}
          onClick={() => {
            if (isDraftEmail) {
              onClickIcon(update)
            }
          }}
          className={classNames(
            {
              [styles.iueContainer]: true,
              [styles.checkIcon]: hoverState || selectionMode,
              [styles.checkedIcon]: checkState,
            },
            styles.noFocus
          )}
        >
          {!hoverState && !checkState && !selectionMode && (
            <FontAwesomeIcon icon={['far', 'envelope']} />
          )}
          {(hoverState || checkState || selectionMode) && (
            <FontAwesomeIcon icon={['far', 'check']} />
          )}
        </button>
      )
    case 'document':
    case CelesteActionType.CREATE_DOCUMENT:
      return showCompanyLogo ? (
        displayCompanyLogo(styles.documentContainer, ['far', 'file'])
      ) : showPortfolioLogo ? (
        displayPortfolioLogo(styles.documentContainer, ['far', 'file'])
      ) : showGroupLogo ? (
        displayGroupLogo(styles.documentContainer, ['far', 'file'])
      ) : (
        <div
          className={classNames(styles.documentContainer, {
            [styles.draftDocument]: isDraftUpdate,
          })}
        >
          <FontAwesomeIcon icon={['far', 'file']} />
        </div>
      )
    case 'transaction':
    case CelesteActionType.CREATE_TRANSACTION:
      return showCompanyLogo ? (
        displayCompanyLogo(styles.transactionContainer, [
          'far',
          'money-check-alt',
        ])
      ) : showPortfolioLogo ? (
        displayPortfolioLogo(styles.transactionContainer, [
          'far',
          'money-check-alt',
        ])
      ) : (
        <div
          className={classNames(styles.transactionContainer, {
            [styles.draftTransaction]: isDraftUpdate,
          })}
        >
          <FontAwesomeIcon icon={['far', 'money-check-alt']} />
        </div>
      )
    case 'xeroreport':
    case 'quickbooksreport':
    case 'accounting':
    case CelesteActionType.CREATE_ACCOUNTING_REPORT:
      return showCompanyLogo ? (
        displayCompanyLogo(styles.accountingContainer, [
          'far',
          'file-invoice-dollar',
        ])
      ) : showPortfolioLogo ? (
        displayPortfolioLogo(styles.accountingContainer, [
          'far',
          'file-invoice-dollar',
        ])
      ) : showGroupLogo ? (
        displayGroupLogo(styles.accountingContainer, [
          'far',
          'file-invoice-dollar',
        ])
      ) : (
        <div
          className={classNames(styles.accountingContainer, {
            [styles.draftAccounting]: isDraftUpdate,
          })}
        >
          <FontAwesomeIcon icon={['far', 'file-invoice-dollar']} />
        </div>
      )
    case 'xero':
      return (
        <div className={classNames(styles.xeroContainer)}>
          <img alt="xero" src={xero} />
        </div>
      )
    case 'quickbooks':
      return (
        <div className={classNames(styles.quickbooksContainer)}>
          <img alt="quickbooks" src={quickbooks} />
        </div>
      )
    case 'metric':
    case CelesteActionType.ADD_METRIC:
    case CelesteActionType.UPDATE_METRICS:
    case CelesteActionType.EXPORT_ALL_METRICS: {
      let icon = ['fal', 'chart-mixed']

      if (updateType === CelesteActionType.EXPORT_ALL_METRICS) {
        icon = ['fal', 'file-export']
      } else if (updateType === CelesteActionType.UPDATE_METRICS) {
        icon = ['fal', 'up']
      }

      return (
        <div className={classNames(styles.metricsContainer)}>
          <FontAwesomeIcon icon={icon} />
        </div>
      )
    }
    case CelesteActionType.CREATE_PORTFOLIO:
      return (
        <div className={classNames(styles.analyticsContainer)}>
          <FontAwesomeIcon icon={['far', 'briefcase']} />
        </div>
      )
    case CelesteActionType.UPLOAD_FILE:
      return (
        <div className={classNames(styles.uploadFileContainer)}>
          <FontAwesomeIcon icon={['far', 'file-plus']} />
        </div>
      )
    default:
      return null
  }
}

UpdateIconFactory.propTypes = {
  updateType: PropTypes.string.isRequired,
  withCompany: PropTypes.object,
  withPortfolio: PropTypes.object,
  withGroup: PropTypes.object,
  update: PropTypes.object,
  onClickIcon: PropTypes.func,
  showSimpleIcon: PropTypes.bool,
  selectionMode: PropTypes.bool,
  isDraftUpdate: PropTypes.bool,
  hoverState: PropTypes.bool,
  checkState: PropTypes.bool,
  setHoverState: PropTypes.func,
  updateFeedIcon: PropTypes.bool,
}

UpdateIconFactory.defaultProps = {
  withCompany: {},
  withPortfolio: {},
  withGroup: {},
  onClickIcon: () => {},
  update: {},
  showSimpleIcon: false,
  selectionMode: false,
  hoverState: false,
  checkState: false,
  isDraftUpdate: false,
  updateFeedIcon: false,
  setHoverState: () => {},
}

export default UpdateIconFactory
