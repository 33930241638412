/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'

import { GridType } from 'utils/constants/gridType'

import { useHighlightText } from 'utils/hooks/useHighlightText'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'
import { usePreference } from 'utils/hooks/usePreference'
import useSettings from 'utils/hooks/useSettings'

import { resetCurrentCompany } from 'actions/companies'
import SettingsService from 'api/SettingsService'
import { useDebouncedState } from 'utils/hooks/useDebouncedState'

import { HoldingsFilters, OrderByProps } from 'api/HoldingsService'
import { SortDirection } from 'types/graphql-schemas/graphql'
import { useHoldingsQuery } from './useHoldingsQuery'

export const DEFAULT_FILTER: HoldingsFilters = {
  orderBy: 'name',
  direction: SortDirection.Asc,
  industryId: [],
  sectorId: [],
  locationPlaceAddress: '',
}

export const SortByOptions = {
  NAME: 'name',
} as const

export const PAGE_SIZE = 35

export const useCompanyIndex = () => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const { settings } = useSettings()
  const { matches: isMobile } = useMediaQuery(Media.MOBILE)
  const [isInitialFetch, setIsInitialFetch] = useState(true)
  const [gridType, setGridType] = usePreference<GridType>('companyListGridType')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showZeroState, setShowZeroState] = useState(false)

  const [currentFilters, setCurrentFilters] = useState(DEFAULT_FILTER)
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const [isHoldingFilterDrawerOpen, setIsHoldingFilterDrawerOpen] =
    useState(false)

  const [search, debouncedSearch, setSearch] = useDebouncedState('')

  const {
    holdings: data,
    isLoading,
    isFetchingNextPage,
    error,
    endOfPageRef,
  } = useHoldingsQuery({
    textSearch: debouncedSearch,
    currentFilters,
    companiesPerPage: PAGE_SIZE,
  })

  const holdings = useMemo(
    () => data?.pages.flatMap((page) => page) || [],
    [data]
  )

  useHighlightText(
    {
      elementClass: '.holding-name',
      text: debouncedSearch,
    },
    [debouncedSearch, holdings]
  )

  useEffect(() => {
    if (!isLoading) {
      setIsInitialFetch(false)
    }
  }, [isLoading])

  useEffect(() => {
    if (settings) {
      setShowZeroState(!settings?.dismissMessages?.companyIndex)
    }
  }, [settings])

  useEffect(() => {
    dispatch(resetCurrentCompany())
  }, [])

  const onChangeSortBy = useCallback(
    (orderBy: OrderByProps, direction: SortDirection) => {
      setCurrentFilters((prev) => {
        return {
          ...prev,
          orderBy,
          direction,
        }
      })
    },
    []
  )

  const onClickTab = (tabIndex: number) => {
    setActiveTabIndex(tabIndex)
  }

  useEffect(() => {
    if (isMobile) {
      setGridType(GridType.ROWS)
    }
  }, [isMobile])

  const onChangeGridType = useCallback((type: GridType) => {
    setGridType(type)
  }, [])

  const onDismissMessage = async () => {
    setShowZeroState(false)
    await SettingsService.dismissMessages('companyIndex')
  }

  const onToggleFiltersDrawer = () => {
    setIsHoldingFilterDrawerOpen((isOpen) => !isOpen)
  }

  const onChangeSearch = (event) => {
    setSearch(event.target.value)
  }

  const hasActiveFilters = !!Object.keys(currentFilters).length

  const showNoResults = !holdings.length && hasActiveFilters && !isLoading

  const onClearFilters = () => {
    setCurrentFilters({
      ...DEFAULT_FILTER,
      orderBy: currentFilters.orderBy,
      direction: currentFilters.direction,
    })
    setSearch('')
  }

  const showCompanyList = !isLoading && !error

  return {
    intl,

    holdings,
    isLoading,
    isInitialFetch,
    currentFilters,

    isFetchingNextPage,

    gridType,
    activeTabIndex,
    endOfPageRef,
    isModalOpen,
    isHoldingFilterDrawerOpen,
    showZeroState,

    showNoResults,

    showCompanyList,

    setCurrentFilters,
    onDismissMessage,
    setIsModalOpen,
    setIsHoldingFilterDrawerOpen,
    onChangeSortBy,
    onChangeGridType,
    onClickTab,

    onToggleFiltersDrawer,
    search,
    debouncedSearch,
    onChangeSearch,
    onClearFilters,
    isMobile,
  }
}
