import Avatar from 'components/Avatar'
import { useLocation } from 'react-router-dom'
import { getCurrentGroupId } from 'selectors/auth'
import { getInitials } from 'utils/functions/user'
import { useSubjectQuery } from 'utils/hooks/queries/subjects/useSubjectQuery'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import useParseCurrentUrl from '../useParseCurrentUrl'

interface InvestorInformationProps {
  investorIdFromUrlState?: string
  fetchInvestor?: boolean
}

const useInvestorInformation = ({
  investorIdFromUrlState,
  fetchInvestor,
}: InvestorInformationProps) => {
  const location = useLocation()
  const investorGroupId = useAppSelector(getCurrentGroupId)
  const { investorId } = useParseCurrentUrl()

  const getInvestorFromUrl = location.pathname.includes('investors')

  const useInvestorIdOrInvestorGroupId = getInvestorFromUrl
    ? investorId
    : investorGroupId

  const investorIdToUse =
    investorIdFromUrlState ?? useInvestorIdOrInvestorGroupId

  const { data } = useSubjectQuery(investorIdToUse, {
    skip: !(investorIdToUse && fetchInvestor),
  })

  const investor = data?.subject
    ? {
        id: data.subject.id,
        name: data.subject.name,
        logo: data.subject.logo,
        type: data.subject.type,
      }
    : undefined

  const getInvestorAvatar = () => {
    if (!investor) return null

    if (!investor.logo) {
      return (
        <Avatar
          initials={getInitials(investor.name)}
          avatarStyle="avatarCircleXS"
        />
      )
    }

    return <Avatar image={investor.logo} avatarStyle="avatarCircleXS" />
  }

  return {
    investor,
    getInvestorAvatar,
  }
}

export default useInvestorInformation
