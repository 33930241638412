import { SubjectMatterType } from 'api/UpdateService'
import { useHistory, useLocation } from 'react-router-dom'
import {
  DefaultRoutes,
  FounderRoutes,
  FundManagerRoutes,
  InvestorRoutes,
  getCurrentBaseRoute,
  getUrl,
  setRouteConfiguration,
} from 'reducers/breadcrumbSlice'
import {
  INVESTMENT_VEHICLES,
  PROFILE,
} from 'routes/constant/InvestorGroupRoutes'
import {
  INVESTMENT_VEHICLE_PROFILE,
  INVESTOR_PROFILE,
} from 'routes/constant/investorManagementRoutes'
import {
  isActingAsClient,
  isActingAsFounder,
  isActingAsFundManager,
  isActingAsInvestorGroup,
} from 'selectors/auth'
import { BasicSubjectFragment } from 'types/graphql-schemas/graphql'
import { PlainUpdateTypes } from 'utils/constants/updates'
import { getUpdateUrlType } from 'utils/functions/updates'
import { FundProfile, Holding, isCompanyHolding } from 'utils/types/company'
import { LoggingUpdateContent } from 'utils/types/files'
import { PortfolioCompany } from 'utils/types/portfolios'
import { SubjectType } from 'utils/types/subjects'
import { IndexUpdate, NavigationUpdate } from 'utils/types/update'
import { useAppDispatch, useAppSelector } from './reduxToolkit'
import { UpdateUrlSuffix } from './useShowUpdateRoutes/types'

export type HoldingType = Holding | PortfolioCompany | FundProfile

const openNewWindow = (url: string) => {
  localStorage.setItem('newWindowOpening', 'true')
  const newWindow = window.open(url, '_blank')
  if (newWindow) {
    newWindow.onload = () => {
      localStorage.removeItem('newWindowOpening')
    }
  } else {
    localStorage.removeItem('newWindowOpening')
  }
}

interface OnUpdateClickRedirect {
  update: IndexUpdate | LoggingUpdateContent
  updateSuffix?: UpdateUrlSuffix
  isRedirectingAfterReshare?: boolean
}

const useOnClickRedirect = () => {
  const history = useHistory()
  const location = useLocation()

  const dispatch = useAppDispatch()

  const isInvestor = useAppSelector(isActingAsInvestorGroup)
  const isFundManager = useAppSelector(isActingAsFundManager)
  const isClient = useAppSelector(isActingAsClient)
  const isFounder = useAppSelector(isActingAsFounder)
  const url = useAppSelector(getUrl)
  const baseRoute = useAppSelector(getCurrentBaseRoute)

  const openInNewTab =
    baseRoute === DefaultRoutes.METRICS ||
    baseRoute === DefaultRoutes.FILES ||
    baseRoute === DefaultRoutes.CHAT

  const isUpdateRoute = baseRoute === DefaultRoutes.UPDATES

  const holdingClick = (
    id: string,
    isCompany: boolean,
    entityType: 'companies' | 'funds'
  ) => {
    if (isUpdateRoute) {
      return isFundManager || isClient
        ? history.push(`/${entityType}/${id}/updates`)
        : history.push(`${url.current}/${entityType}/${id}`)
    }

    if (isFundManager || isClient) {
      if (baseRoute === FundManagerRoutes.INVESTMENTS) {
        return history.push(
          isCompany ? `/companies/${id}/updates` : `/funds/${id}/updates`
        )
      }

      if (baseRoute === FundManagerRoutes.ORGANIZATION_PROFILE) {
        return history.push(`/${entityType}/${id}/updates`)
      }

      if (baseRoute === FundManagerRoutes.DISCOVER) {
        return isCompany
          ? history.push(`/companies/${id}/updates`)
          : history.push(`/funds/${id}/updates`)
      }

      if (openInNewTab) {
        return openNewWindow(isCompany ? `/companies/${id}` : `/funds/${id}`)
      }
    }

    if (isInvestor) {
      if (
        baseRoute === InvestorRoutes.INVESTMENTS ||
        baseRoute === InvestorRoutes.HOLDINGS
      ) {
        return history.push(`/${entityType}/${id}/updates`)
      }

      return history.push(`${url.current}/${entityType}/${id}`)
    }

    if (isFounder) {
      // 1) we can not click on andy holding on Dashboard since is the founder profile itself

      // 2) on updates we can't also click on any holding since the icons we have in the
      // update feed is only representing the type of the update and not the holding
      // or portfolio company itself

      // 3) On emails, metrics and files we are opening a new tab and redirecting to the
      // holding or portfolio company itself
      if (openInNewTab) {
        return openNewWindow(isCompany ? `/companies/${id}` : `/funds/${id}`)
      }
    }

    return null
  }

  const handleOnHoldingClick = (holding: HoldingType) => {
    const isHolding = !!holding.id

    const isCompany = isHolding
      ? isCompanyHolding(holding as Holding)
      : holding.holdingType === SubjectMatterType.COMPANY

    const entityType = isCompany ? 'companies' : 'funds'

    return holdingClick(
      (holding.id || holding.holdingId)!,
      isCompany,
      entityType
    )
  }

  const handleOnHoldingSubjectClick = (subject: BasicSubjectFragment) => {
    const { id, type } = subject

    const isCompany = type === SubjectType.COMPANY

    const entityType = isCompany ? 'companies' : 'funds'
    return holdingClick(id, isCompany, entityType)
  }

  const handleOnUpdateClick = ({
    update,
    updateSuffix,
    isRedirectingAfterReshare,
  }: OnUpdateClickRedirect): {
    url: string
    redirectFunction: () => void
  } => {
    if (openInNewTab) {
      if ('subjectMatterInfo' in update && 'type' in update) {
        const loggingUpdate = update as LoggingUpdateContent

        const isCompany =
          loggingUpdate?.subjectMatterInfo?.type === SubjectMatterType.COMPANY

        const entityId = loggingUpdate?.subjectMatterInfo?.id
        const updateId = loggingUpdate.id
        const updateType = getUpdateUrlType(loggingUpdate.type)

        const entity = isCompany ? 'companies' : 'funds'

        const urlToRedirect = isInvestor
          ? `/${entity}/${entityId}/updates/${updateType}/${updateId}`
          : `/${entity}/${entityId}/all-updates/${updateType}/${updateId}`

        return {
          url: urlToRedirect,
          redirectFunction: () => {
            openNewWindow(urlToRedirect)
          },
        }
      }
    }

    const updateTyped = update as IndexUpdate
    const urlType = getUpdateUrlType(updateTyped.updateType)

    if (isUpdateRoute) {
      let urlToRedirect = updateSuffix
        ? `${url.current}/${urlType}/${update.id}/${updateSuffix}`
        : `${url.current}/${urlType}/${update.id}`

      const isEmail = update.type === PlainUpdateTypes.IUE
      const isDraftEmail = isEmail && (update as any).item.sentAt === null

      if (isEmail && isDraftEmail) {
        urlToRedirect = `/emails/${update.id}/draft`
      }

      return {
        url: urlToRedirect,
        redirectFunction: () => {
          history.push(urlToRedirect)
        },
      }
    }

    if (isFundManager || isClient) {
      const isFundSelected = location.pathname.includes('/funds')
      const isCompanySelected = location.pathname.includes('/companies')

      const isEntitySelected = isFundSelected || isCompanySelected

      if (baseRoute === FundManagerRoutes.INVESTMENTS) {
        if (isEntitySelected) {
          const urlWithoutLastPart = url.current
            .split('/')
            .slice(0, -1)
            .join('/')

          const urlToRedirect = updateSuffix
            ? `${urlWithoutLastPart}/all-updates/${urlType}/${update.id}/${updateSuffix}`
            : `${urlWithoutLastPart}/all-updates/${urlType}/${update.id}`

          return {
            url: urlToRedirect,
            redirectFunction: () => {
              history.push(urlToRedirect)
            },
          }
        }

        const urlToRedirect = updateSuffix
          ? `/updates/${urlType}/${update.id}/${updateSuffix}`
          : `/updates/${urlType}/${update.id}`

        return {
          url: urlToRedirect,
          redirectFunction: () => {
            history.push(urlToRedirect)
          },
        }
      }

      if (baseRoute === FundManagerRoutes.ORGANIZATION_PROFILE) {
        const urlWithoutLastPart = url.current.split('/').slice(0, -1).join('/')

        let urlToRedirect = updateSuffix
          ? `${urlWithoutLastPart}/all-updates/${urlType}/${update.id}/${updateSuffix}`
          : `${urlWithoutLastPart}/all-updates/${urlType}/${update.id}`

        const isOnUpdateView = url.current.includes('/all-updates')

        if (isRedirectingAfterReshare && isOnUpdateView) {
          urlToRedirect = url.current
        }

        return {
          url: urlToRedirect,
          redirectFunction: () => {
            history.push(urlToRedirect)
          },
        }
      }

      if (baseRoute === FundManagerRoutes.INVESTOR_MANAGEMENT) {
        const urlToRedirect = updateSuffix
          ? `${url.current}/all-updates/${urlType}/${update.id}/${updateSuffix}`
          : `${url.current}/all-updates/${urlType}/${update.id}`

        return {
          url: urlToRedirect,
          redirectFunction: () => {
            history.push(urlToRedirect)
          },
        }
      }

      if (baseRoute === FundManagerRoutes.DISCOVER) {
        const urlWithoutLastPart = url.current.split('/').slice(0, -1).join('/')

        let urlToRedirect = updateSuffix
          ? `${urlWithoutLastPart}/all-updates/${urlType}/${update.id}/${updateSuffix}`
          : `${urlWithoutLastPart}/all-updates/${urlType}/${update.id}`

        const isOnUpdateView = url.current.includes('/all-updates')
        const isOnHoldingProfile = isFundSelected || isCompanySelected

        if (isRedirectingAfterReshare) {
          if (isOnHoldingProfile && !isOnUpdateView) {
            urlToRedirect = `${urlWithoutLastPart}/all-updates/${urlType}/${update.id}`
          } else {
            urlToRedirect = url.current
          }
        }

        return {
          url: urlToRedirect,
          redirectFunction: () => {
            history.push(urlToRedirect)
          },
        }
      }

      const urlToRedirect = updateSuffix
        ? `${url.current}/${urlType}/${update.id}/${updateSuffix}`
        : `${url.current}/${urlType}/${update.id}`

      return {
        url: urlToRedirect,
        redirectFunction: () => {
          history.push(urlToRedirect)
        },
      }
    }

    if (isInvestor) {
      const funds = location.pathname.includes('funds')
      const companies = location.pathname.includes('companies')

      const userHasSelectedCompanyOrFund = funds || companies

      if (baseRoute === InvestorRoutes.INVESTMENTS) {
        if (userHasSelectedCompanyOrFund) {
          const redirectUrl = updateSuffix
            ? `${url.current}/${urlType}/${update.id}/${updateSuffix}`
            : `${url.current}/${urlType}/${update.id}`

          return {
            url: redirectUrl,
            redirectFunction: () => {
              history.push(redirectUrl)
            },
          }
        }

        const redirectUrl = updateSuffix
          ? `/investments/holdings/updates/${urlType}/${update.id}/${updateSuffix}`
          : `/investments/holdings/updates/${urlType}/${update.id}`

        return {
          url: redirectUrl,
          redirectFunction: () => {
            history.push(redirectUrl)
          },
        }
      }

      const redirectUrl = updateSuffix
        ? `${url.current}/${urlType}/${update.id}/${updateSuffix}`
        : `${url.current}/${urlType}/${update.id}`

      return {
        url: redirectUrl,
        redirectFunction: () => {
          history.push(redirectUrl)
        },
      }
    }

    if (isFounder) {
      if (baseRoute === FounderRoutes.dashboard) {
        const redirectUrl = updateSuffix
          ? `/dashboard/all-updates/${urlType}/${update.id}/${updateSuffix}`
          : `/dashboard/all-updates/${urlType}/${update.id}`

        return {
          url: redirectUrl,
          redirectFunction: () => {
            history.push(redirectUrl)
          },
        }
      }
    }

    return {
      url: '',
      redirectFunction: () => {},
    }
  }

  const handleInvestmentVehicleClick = (vehicleId: string) => {
    if (isInvestor) {
      if (baseRoute !== InvestorRoutes.INVESTOR_PROFILE) {
        dispatch(
          setRouteConfiguration({
            route: InvestorRoutes.INVESTOR_PROFILE,
            initialLinkRedirect: `/profile`,
          })
        )
      }

      history.push(
        INVESTMENT_VEHICLES.replace(':investmentVehicleId', vehicleId)
      )
      return
    }

    dispatch(
      setRouteConfiguration({
        route: FundManagerRoutes.INVESTOR_VEHICLES,
        initialLinkRedirect: `/investor-management/vehicles`,
      })
    )

    history.push(
      INVESTMENT_VEHICLE_PROFILE.replace(':investmentVehicleId', vehicleId)
    )
  }

  const handleInvestorClick = (investorId: string) => {
    if (isInvestor) {
      if (baseRoute !== InvestorRoutes.INVESTOR_PROFILE) {
        dispatch(
          setRouteConfiguration({
            route: InvestorRoutes.INVESTOR_PROFILE,
            initialLinkRedirect: `/profile`,
          })
        )
      }

      history.push(PROFILE)
      return
    }

    dispatch(
      setRouteConfiguration({
        route: FundManagerRoutes.INVESTOR_MANAGEMENT,
        initialLinkRedirect: `/investor-management/investors`,
      })
    )

    history.push({
      pathname: INVESTOR_PROFILE.replace(':investorId', investorId),
      state: {
        investorId,
      },
    })
  }

  const handleUpdateNavigation = ({ update }: { update: NavigationUpdate }) => {
    let urlToRedirect = url.current
    const isEmailRoute = baseRoute === DefaultRoutes.EMAILS

    const isComingFromDraftUpdate = urlToRedirect.includes('/preview-draft')

    if (isComingFromDraftUpdate) {
      urlToRedirect = urlToRedirect.split('/').slice(0, -1).join('/')
    }

    const urlWithoutId = urlToRedirect.split('/').slice(0, -1).join('/')
    const urlWithoutUpdateType = urlWithoutId.split('/').slice(0, -1).join('/')

    const { updateId, updateType } = update
    const urlType = getUpdateUrlType(updateType)

    if (update.isDraftUpdate) {
      urlToRedirect = `${urlWithoutUpdateType}/${urlType}/${updateId}/preview-draft`

      return {
        url: urlToRedirect,
        redirectFunction: () => {
          history.push(urlToRedirect)
        },
      }
    }

    if (isEmailRoute) {
      urlToRedirect = `/emails/${updateId}`

      return {
        url: urlToRedirect,
        redirectFunction: () => {
          history.push(urlToRedirect)
        },
      }
    }

    urlToRedirect = `${urlWithoutUpdateType}/${urlType}/${updateId}`

    return {
      url: urlToRedirect,
      redirectFunction: () => {
        history.push(urlToRedirect)
      },
    }
  }

  return {
    handleOnHoldingClick,
    handleOnHoldingSubjectClick,
    handleOnUpdateClick,
    handleInvestmentVehicleClick,
    handleInvestorClick,
    handleUpdateNavigation,
  }
}

export default useOnClickRedirect
