import { get } from 'lodash'
import type { IntlShape } from 'react-intl'
import { TransactionInstruments } from 'utils/constants/transactionInstruments'
import {
  CalculationBasisTypes,
  DividendTypes,
  TransactionFieldsPerInstrument,
  TransactionFundType,
  TransactionTypes,
} from 'utils/constants/transactionTypes'
import { UpdateTypes } from 'utils/constants/updates'
import { Nullable } from 'utils/types/common'
import { IndexFundPortfolio, PortfolioType } from 'utils/types/portfolios'
import {
  InvestorTransactionFormValues,
  ParsedInvestorTransaction,
  ParsedTransaction,
  TransactionFormHolding,
} from 'utils/types/transactions'
import {
  DividendType,
  DraftHashAttributes,
  TransactionInstrumentType,
  TransactionType,
  TransactionUpdatableAttributes,
  TransactionUpdate,
} from 'utils/types/update'
import {
  AccessType,
  CreateBulkTransactionFormValues,
  CreateTransactionFormValues,
} from 'utils/types/updateForm'
import dayjs from 'dayjs'
import {
  getPermissionsFromDraftHash,
  getPermissionsFromServerUpdate,
} from './api/updates'
import { formatPercentage, randomId } from './number'

export const getTransactionTypeLabels = (
  transactionType: TransactionType,
  intl: IntlShape
) => {
  switch (transactionType) {
    case TransactionTypes.COMMITMENT:
      return {
        typeLabel: intl.formatMessage({ id: 'transactions.commitment' }),
        dateLabel: intl.formatMessage({ id: 'transactions.commitmentDate' }),
        amountLabel: intl.formatMessage({ id: 'transactions.amountCommited' }),
      }
    case TransactionTypes.INVESTMENT:
      return {
        typeLabel: intl.formatMessage({ id: 'transactions.investment' }),
        dateLabel: intl.formatMessage({ id: 'transactions.investmentDate' }),
        amountLabel: intl.formatMessage({ id: 'transactions.amountInvested' }),
      }
    case TransactionTypes.DISTRIBUTION:
      return {
        typeLabel: intl.formatMessage({ id: 'transactions.distribution' }),
        dateLabel: intl.formatMessage({ id: 'transactions.distributionDate' }),
        amountLabel: intl.formatMessage({
          id: 'transactions.amountDistributed',
        }),
      }
    default:
      return { typeLabel: '', dateLabel: '', amountLabel: '' }
  }
}

export const getDividendTypeLabel = (
  dividendType: DividendType,
  intl: IntlShape
) => {
  switch (dividendType) {
    case DividendTypes.CUMULATIVE:
      return intl.formatMessage({ id: 'transactions.cumulative' })
    case DividendTypes.NON_CUMULATIVE:
      return intl.formatMessage({ id: 'transactions.noncumulative' })
    case DividendTypes.N_A:
      return intl.formatMessage({ id: 'transactions.na' })
    default:
      return ''
  }
}

export const getNormalizedTransactionInstrument = (
  instrument: TransactionInstrumentType,
  intl: IntlShape
) => {
  switch (instrument) {
    case TransactionInstruments.CONVERTIBLE_NOTE:
      return intl.formatMessage({ id: 'transactions.convertibleNote' })
    case TransactionInstruments.DEBT_CREDIT:
      return intl.formatMessage({ id: 'transactions.debtCredit' })
    case TransactionInstruments.EQUITY:
      return intl.formatMessage({ id: 'transactions.equity' })
    case TransactionInstruments.OTHER:
      return intl.formatMessage({ id: 'transactions.other' })
    case TransactionInstruments.SAFE:
      return intl.formatMessage({ id: 'transactions.safe' })
    case TransactionInstruments.PREFERRED_EQUITY:
      return intl.formatMessage({ id: 'transactions.preferredEquity' })
    case TransactionInstruments.FUND_INVESTMENT:
      return intl.formatMessage({ id: 'transactions.fundInvestment' })
    case TransactionInstruments.WARRANTS:
      return intl.formatMessage({ id: 'transactions.warrants' })
    default:
      return ''
  }
}

const isTransactionInstrumentFieldDefined = (value) =>
  value !== undefined && value !== null

export const getTransactionInitialFormValues = (
  update: TransactionUpdate,
  getPublishedValues?: boolean
): CreateTransactionFormValues => {
  let transactionFormValues: CreateTransactionFormValues

  if (update.isDraftUpdate && !getPublishedValues) {
    const draftHash =
      update.draftHash as DraftHashAttributes<TransactionUpdatableAttributes>
    const {
      amountCommitted,
      amountDistributed,
      amountInvested,
      date,
      drawdownAgainstCommitment,
      instrumentType,
      instrument,
      portfolioVehicleInvestorTransactionAttributes,
      text,
      title,
      transactionFundType,
      transactionType,
    } = draftHash.updatableAttributes

    const { holding, investmentVehicle, investor, portfolios, fundPortfolio } =
      draftHash

    transactionFormValues = {
      title,
      updateType: UpdateTypes.TRANSACTION,
      type: transactionType,
      holding: holding ?? null,
      portfolios: portfolios ?? [],
      fundPortfolio: fundPortfolio ?? null,
      investmentVehicle,
      investor,
      transactionFundType,
      portfolioVehicleInvestorTransaction:
        portfolioVehicleInvestorTransactionAttributes,
      date: date ? new Date(date) : null,
      amount: amountDistributed || amountInvested || amountCommitted,
      description: text ?? '',
      instrument: instrumentType,
      drawdownAgainstCommitment:
        instrumentType === TransactionInstruments.FUND_INVESTMENT ||
        transactionFundType === TransactionFundType.INVESTOR
          ? !!drawdownAgainstCommitment
          : true,
      valuationCap: instrument?.valuationCap,
      discountRate: formatPercentage(instrument?.discountRate),
      interestRate: formatPercentage(instrument?.interestRate),
      interestCalculationBasis:
        instrument?.interestCalculationBasis ?? CalculationBasisTypes.N_A,
      maturityDate: isTransactionInstrumentFieldDefined(
        instrument?.maturityDate
      )
        ? new Date(instrument?.maturityDate!)
        : undefined,
      purchasePricePerShare: instrument?.purchasePricePerShare,
      sharesPurchased: instrument?.sharesPurchased,
      preMoneyValuation: instrument?.preMoneyValuation,
      postMoneyValuation: instrument?.postMoneyValuation,
      annualManagementFee: formatPercentage(instrument?.annualManagementFee),
      carry: formatPercentage(instrument?.carry),
      carryHurdleRate: formatPercentage(
        instrument?.carryHurdleRate?.toString()
      ),
      dividend: formatPercentage(instrument?.dividend),
      dividendCalculationBasis:
        instrument?.dividendCalculationBasis ?? CalculationBasisTypes.N_A,
      dividendType: instrument?.dividendType ?? DividendTypes.N_A,
      vestingCommencementDate: isTransactionInstrumentFieldDefined(
        instrument?.vestingCommencementDate
      )
        ? new Date(instrument?.vestingCommencementDate!)
        : undefined,
      expirationDate: isTransactionInstrumentFieldDefined(
        instrument?.expirationDate
      )
        ? new Date(instrument?.expirationDate!)
        : undefined,
      strikePrice: instrument?.strikePrice,
      numberOfShares: instrument?.numberOfShares,
      tags:
        draftHash.tags?.map((tag) => ({
          ...tag,
          alreadyExists: true,
        })) ?? [],
      permissions: getPermissionsFromDraftHash(
        draftHash,
        update.group?.id,
        investor?.id
      ),
      files: draftHash.contents,
      updateId: update.id,
      owner: update.user,
    }
  } else {
    transactionFormValues = {
      title: update.item.title,
      updateType: UpdateTypes.TRANSACTION,
      type: update.item.transactionType,
      holding: update.holding ?? null,
      portfolios: update.portfolios ?? [],
      fundPortfolio: update.fundPortfolio,
      investmentVehicle: update.item.investmentVehicle,
      investor: update.item.investor,
      transactionFundType: update.item.transactionFundType,
      portfolioVehicleInvestorTransaction:
        update.item.portfolioVehicleInvestorTransaction,
      date: new Date(update.item.date),
      amount:
        update.item.amountDistributed ||
        update.item.amountInvested ||
        update.item.amountCommitted ||
        0,
      description: update.item.text ?? '',
      instrument: update.item.instrumentType,
      drawdownAgainstCommitment:
        update.item.instrumentType === TransactionInstruments.FUND_INVESTMENT ||
        update.item.transactionFundType === TransactionFundType.INVESTOR
          ? !!update.item.drawdownAgainstCommitment
          : true,
      valuationCap: update.item.instrument?.valuationCap,
      discountRate: formatPercentage(update.item.instrument?.discountRate),
      interestRate: formatPercentage(update.item.instrument?.interestRate),
      interestCalculationBasis:
        update.item.instrument?.interestCalculationBasis ??
        CalculationBasisTypes.N_A,
      maturityDate: isTransactionInstrumentFieldDefined(
        update.item.instrument?.maturityDate
      )
        ? new Date(update.item.instrument?.maturityDate!)
        : undefined,
      purchasePricePerShare: update.item.instrument?.purchasePricePerShare,
      sharesPurchased: update.item.instrument?.sharesPurchased,
      preMoneyValuation: update.item.instrument?.preMoneyValuation,
      postMoneyValuation: update.item.instrument?.postMoneyValuation,
      annualManagementFee: formatPercentage(
        update.item.instrument?.annualManagementFee
      ),
      carry: formatPercentage(update.item.instrument?.carry),
      carryHurdleRate: formatPercentage(
        update.item.instrument?.carryHurdleRate?.toString()
      ),
      dividend: formatPercentage(update.item.instrument?.dividend),
      dividendCalculationBasis:
        update.item.instrument?.dividendCalculationBasis ??
        CalculationBasisTypes.N_A,
      dividendType: update.item.instrument?.dividendType ?? DividendTypes.N_A,
      vestingCommencementDate: isTransactionInstrumentFieldDefined(
        update.item.instrument?.vestingCommencementDate
      )
        ? new Date(update.item.instrument!.vestingCommencementDate!)
        : undefined,
      expirationDate: isTransactionInstrumentFieldDefined(
        update.item.instrument?.expirationDate
      )
        ? new Date(update.item.instrument!.expirationDate!)
        : undefined,
      strikePrice: update.item.instrument?.strikePrice,
      numberOfShares: update.item.instrument?.numberOfShares,
      tags: update.tags,
      permissions: getPermissionsFromServerUpdate(update),
      files: update.contents,
      updateId: update.item.id,
      owner: update.user,
    }
  }

  return transactionFormValues
}

export const instrumentFieldIsDefined = (
  value: Nullable<number | string | Date | undefined>
) =>
  value !== '' &&
  value !== null &&
  value !== undefined &&
  value !== CalculationBasisTypes.N_A &&
  !(value instanceof Date && !dayjs(value).isValid())

export const instrumentHasData = (
  instrumentType: TransactionInstrumentType,
  instrument: CreateTransactionFormValues
): boolean => {
  return TransactionFieldsPerInstrument[instrumentType].some((field) =>
    instrumentFieldIsDefined(instrument[field])
  )
}

export const getFieldName = (name: string, prefix?: string) => {
  if (prefix) {
    return `${prefix}.${name}`
  }
  return name
}

export const getFielValue = (values, name: string, prefix?: string) => {
  const nameWithPrefix = getFieldName(name, prefix)
  return get(values, nameWithPrefix)
}

export type BulkTransactionPortfolio = {
  id: string
  name: string
  type: PortfolioType
  holdingsIds: string[]
}

export const getBulkImportTransactionsData = (
  parsedTransactions: ParsedTransaction[],
  defaultPortfolio: BulkTransactionPortfolio
): CreateBulkTransactionFormValues[] | InvestorTransactionFormValues[] => {
  return parsedTransactions.map((trn) => {
    return {
      id: randomId(),
      updateType: UpdateTypes.TRANSACTION,
      title: trn.transactionName,
      type: trn.transactionType,
      holding: trn.companyId
        ? ({
            id: trn.companyId!,
            name: trn.companyName!,
            primaryLogo: trn.companyLogo!,
            populated: trn.subjectMatterPopulated,
          } as TransactionFormHolding)
        : null,
      portfolios: !trn.portfolios?.length ? [defaultPortfolio] : trn.portfolios,
      transactionFundType: TransactionFundType.HOLDING,
      date: trn.date ? new Date(trn.date) : null,

      amount:
        Number(trn.amountCommitted) ||
        Number(trn.amountDistributed) ||
        Number(trn.amountInvested) ||
        null,
      description: trn.text,
      attachments: [],
      instrument: trn.instrumentAttributes?.instrumentType,

      drawdownAgainstCommitment:
        trn.drawdownAgainstCommitment?.toLowerCase?.() === 'true',

      valuationCap:
        trn.instrumentAttributes?.valuationCap !== undefined
          ? Number(trn.instrumentAttributes?.valuationCap)
          : undefined,
      discountRate: formatPercentage(trn.instrumentAttributes?.discountRate),
      interestRate: formatPercentage(trn.instrumentAttributes?.interestRate),
      interestCalculationBasis:
        trn.instrumentAttributes?.interestCalculationBasis ||
        CalculationBasisTypes.N_A,
      maturityDate: trn.instrumentAttributes?.maturityDate
        ? new Date(trn.instrumentAttributes?.maturityDate)
        : undefined,
      purchasePricePerShare: trn.instrumentAttributes?.purchasePricePerShare
        ? Number(trn.instrumentAttributes?.purchasePricePerShare)
        : undefined,
      sharesPurchased: trn.instrumentAttributes?.sharesPurchased
        ? Number(trn.instrumentAttributes?.sharesPurchased)
        : undefined,
      preMoneyValuation: trn.instrumentAttributes?.preMoneyValuation
        ? Number(trn.instrumentAttributes?.preMoneyValuation)
        : undefined,
      postMoneyValuation: trn.instrumentAttributes?.postMoneyValuation
        ? Number(trn.instrumentAttributes?.postMoneyValuation)
        : undefined,
      annualManagementFee: formatPercentage(
        trn.instrumentAttributes?.annualManagementFee
      ),
      carry: formatPercentage(trn.instrumentAttributes?.carry),
      carryHurdleRate: formatPercentage(
        trn.instrumentAttributes?.carryHurdleRate
      ),
      dividend: formatPercentage(trn.instrumentAttributes?.dividend),
      dividendCalculationBasis:
        trn.instrumentAttributes?.dividendCalculationBasis ||
        CalculationBasisTypes.N_A,
      dividendType:
        (trn.instrumentAttributes?.dividendType as DividendType) ||
        DividendTypes.N_A,
      vestingCommencementDate: trn.instrumentAttributes?.vestingCommencementDate
        ? new Date(trn.instrumentAttributes?.vestingCommencementDate)
        : undefined,
      expirationDate: trn.instrumentAttributes?.expirationDate
        ? new Date(trn.instrumentAttributes?.expirationDate)
        : undefined,
      strikePrice:
        trn.instrumentAttributes?.strikePrice !== undefined
          ? Number(trn.instrumentAttributes?.strikePrice)
          : undefined,
      numberOfShares:
        trn.instrumentAttributes?.numberOfShares !== undefined
          ? Number(trn.instrumentAttributes?.numberOfShares)
          : undefined,

      transactionDuplicated: true,
      tags: [],
      permissions: {
        community: AccessType.NO_ACCESS,
        yourGroup: AccessType.CAN_VIEW,
        confidentialUpdate: false,
        sharedWith: [],
      },
      files: [],
    }
  })
}

export const getBulkImportInvestorsTransactionsData = (
  parsedTransactions: ParsedInvestorTransaction[],
  fundPortfolio: Pick<
    IndexFundPortfolio,
    'id' | 'name' | 'type' | 'portfolioCompanies'
  >
): InvestorTransactionFormValues[] => {
  const bulkPortfolio = {
    id: fundPortfolio.id,
    name: fundPortfolio.name,
    type: fundPortfolio.type,
    holdingsIds: fundPortfolio.portfolioCompanies.map(
      (company) => company.holdingId || company.holding.id
    ),
  }
  return getBulkImportTransactionsData(parsedTransactions, bulkPortfolio).map(
    (trn, index) => {
      const originalTransaction = parsedTransactions[index]

      return {
        ...trn,
        company: null,
        transactionFundType: TransactionFundType.INVESTOR,
        transactionDuplicated: trn.duplicated,
        investor: originalTransaction.investorGroupId
          ? {
              id: originalTransaction.investorGroupId,
              name: originalTransaction.investorGroupName || '',
            }
          : null,
        investmentVehicle: originalTransaction.investmentVehicleId
          ? {
              id: originalTransaction.investmentVehicleId,
              name: originalTransaction.investmentVehicleName,
              investmentVehicleInvestor: {
                id: originalTransaction.investmentVehicleInvestorId || '',
              },
            }
          : null,
        fundPortfolio,
      }
    }
  )
}
