import { getIntlConfig } from 'languageProvider'
import { ReactNode } from 'react'
import { createIntl } from 'react-intl'
import type { IntlConfig, IntlShape } from 'react-intl'
import { Image, Nullable } from 'utils/types/common'

export type FieldValue = Nullable<string | number | string[]>
export type ChangeSetItem = [FieldValue, FieldValue]
export type FieldName<T> = Extract<keyof T, string>

export type ChangeSet<T> = {
  [fieldName in FieldName<T>]: ChangeSetItem
}

export enum LogType {
  INVESTORS = 'investor_groups',
  VEHICLE = 'investment_vehicles',
  PIPELINE_PORTFOLIOS = 'track_portfolios',
  INVEST_PORTFOLIOS = 'invest_portfolios',
  FUND_PORTFOLIOS = 'fund_portfolios',
  DEAL_PORTFOLIOS = 'deal_portfolios',
  NOTES = 'notes',
  DOCUMENTS = 'documents',
  ANNOUNCEMENTS = 'announcements',
  TRANSACTIONS = 'transactions',
  XERO_REPORTS = 'xero_reports',
  QUICKBOOKS_REPORTS = 'quickbooks_reports',
}

export interface LogUser {
  id: string
  name: string
  email: string
  image: Image
  handle: string
}

export enum EventType {
  UPDATE = 'update',
  CREATE = 'create',
  DESTROY = 'destroy',
  REMOVE_MEMBER = 'remove_member',
  REMOVE_INDUSTRY = 'remove_industry',
  ADD_INDUSTRY = 'add_industry',
  ADD_LOCATION = 'add_location',
  REMOVE_LOCATION = 'remove_location',
  UPDATE_LOCATION = 'update_location',
  ADD_MEMBER = 'add_member',
  INVITE_MEMBERS = 'invite_members',
  ADD_COMPANY = 'add_company',
  REMOVE_COMPANY = 'remove_company',
  ADD_TRANSACTION = 'add_transaction',
  REMOVE_TRANSACTION = 'remove_transaction',
  ADD_FMV = 'add_fmv',
  REMOVE_FMV = 'remove_fmv',
  UPDATE_FMV = 'update_fmv',
  ADD_INVESTOR = 'add_investor',
  REMOVE_INVESTOR = 'remove_investor',
  ADD_GROUP = 'add_group',
  REMOVE_GROUP = 'remove_group',
  ADD_USER = 'add_user',
  REMOVE_USER = 'remove_user',
  ADD_ATTACHMENT = 'add_attachment',
  ADD_CONTENT = 'add_content_update',
  REMOVE_ATTACHMENT = 'remove_attachment',
  REMOVE_CONTENT = 'remove_content_update',
  ADD_TAG = 'add_tag',
  REMOVE_TAG = 'remove_tag',
  ADD_SCHEDULE = 'add_schedule',
  REMOVE_SCHEDULE = 'remove_schedule',
  ADD_TO_PORTFOLIO = 'add_to_portfolio',
  REMOVE_FROM_PORTFOLIO = 'remove_from_portfolio',
  UPDATE_COMPANY = 'update_company',
}

export type FieldChangeSet = {
  fieldName: string
  changeSetItem: ChangeSetItem
  event: EventType
}

export interface Log<TChangeSet> {
  id: string
  event: EventType
  fieldName: FieldName<TChangeSet>
  change: ChangeSetItem
  createdAt: string
  text: Nullable<string>
  user?: LogUser
  groupLogo?: string
  groupName?: string
  customValue?: any
}

export interface LogsByDate<TChangeSet> {
  date: string
  logs: Log<TChangeSet>[]
}

export interface ILogFactory {
  getLogoUrl(): string
  getOwner(): string
  getDate(): string
  getText(): ReactNode
  getOperationIcon(): ReactNode
}

export interface ILogTextBuilder {
  getLogText(
    event: EventType,
    fieldName: string,
    changeSetItem: ChangeSetItem,
    text: Nullable<string>
  ): ReactNode
}

export abstract class LogTextBuilder implements ILogTextBuilder {
  protected intl: IntlShape = createIntl(getIntlConfig() as IntlConfig)

  public abstract getLogText(
    event: EventType,
    fieldName: string,
    changeSetItem: ChangeSetItem,
    text: Nullable<string>,
    fromOutsideGroup?: boolean,
    customValues?: any
  ): ReactNode

  formatMessage(
    id: string,
    values?: Record<string, FieldValue | ((key: string) => ReactNode)>,
    useQuotes?: boolean
  ): ReactNode {
    const newValues = { ...values }

    if (newValues.fieldName)
      newValues.fieldName = this.intl.formatMessage({
        id: `logs.fields.${newValues.fieldName}`,
      })

    newValues.quotes = (msg) => (useQuotes ? `"${msg}"` : msg)

    return this.intl.formatMessage(
      { id },
      { bold: (msg) => <b>{msg}</b>, ...newValues }
    )
  }
}
