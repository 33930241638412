export const COMPANIES = '/companies'

export const COMPANY_PROFILE = `${COMPANIES}/:companyId`
export const COMPANY_UPDATES_PREFIX = `${COMPANY_PROFILE}/all-updates`

export const COMPANY_PROFILE_ANNOUNCEMENTS_UPDATES = `${COMPANY_UPDATES_PREFIX}/announcements`
export const COMPANY_PROFILE_NOTES_UPDATES = `${COMPANY_UPDATES_PREFIX}/notes`
export const COMPANY_PROFILE_DOCUMENTS_UPDATES = `${COMPANY_UPDATES_PREFIX}/documents`
export const COMPANY_PROFILE_TRANSACTIONS_UPDATES = `${COMPANY_UPDATES_PREFIX}/transactions`
export const COMPANY_PROFILE_EMAILS_UPDATES = `${COMPANY_UPDATES_PREFIX}/emails`
export const COMPANY_PROFILE_ACCOUNTING_UPDATES = `${COMPANY_UPDATES_PREFIX}/accounting`

export const CREATE_NOTE_FROM_SUGGESTED = `${COMPANY_PROFILE}/notes`
export const CREATE_DOCUMENT_FROM_SUGGESTED = `${COMPANY_PROFILE}/documents`
export const CREATE_TRANSACTION_FROM_SUGGESTED = `${COMPANY_PROFILE}/transactions`

export const EMAIL_CREATION = `${COMPANY_PROFILE}/drafts/:updateId`

export const COMPANIES_UPDATES = `${COMPANY_PROFILE}/updates`
export const COMPANIES_INVESTMENTS = `${COMPANY_PROFILE}/investments`
export const COMPANIES_METRICS = `${COMPANY_PROFILE}/metrics`
export const COMPANIES_CHAT = `${COMPANY_PROFILE}/chat`

export const COMPANIES_ALL_TABS_EDIT = `${COMPANY_PROFILE}/:someTab/edit`

export const COMPANIES_UPDATES_EDIT = `${COMPANIES_UPDATES}/edit`
export const COMPANIES_METRICS_EDIT = `${COMPANIES_METRICS}/edit`
export const COMPANIES_CHAT_EDIT = `${COMPANIES_CHAT}/edit`
export const COMPANIES_METRICS_UPDATE_ALL = `${COMPANIES_METRICS}/update-all`

export const COMPANIES_METRICS_ID = `${COMPANIES_METRICS}/:metricId`
export const COMPANIES_METRICS_ID_EDIT = `${COMPANIES_METRICS_ID}/edit`
export const COMPANIES_METRICS_ID_SET_MILESTONE = `${COMPANIES_METRICS_ID}/set-milestone`
export const COMPANIES_METRICS_ID_EDIT_MILESTONE = `${COMPANIES_METRICS_ID}/edit-milestone/:milestoneId`
export const COMPANIES_METRICS_ID_ADD_VALUE = `${COMPANIES_METRICS_ID}/add-value`

export const COMPANY_PROFILE_ROUTES = [
  COMPANY_PROFILE,
  COMPANIES_UPDATES,
  COMPANIES_INVESTMENTS,
  COMPANIES_UPDATES_EDIT,
  COMPANIES_METRICS,
  COMPANIES_METRICS_EDIT,
  COMPANIES_METRICS_ID_EDIT,
  COMPANIES_METRICS_ID_SET_MILESTONE,
  COMPANIES_METRICS_ID_EDIT_MILESTONE,
  COMPANIES_METRICS_ID_ADD_VALUE,
  COMPANIES_METRICS_UPDATE_ALL,
  COMPANIES_METRICS_ID,
  COMPANIES_CHAT,
  COMPANIES_CHAT_EDIT,
]

const companiesRoutes = [
  COMPANIES,
  COMPANY_PROFILE,
  COMPANY_PROFILE_ANNOUNCEMENTS_UPDATES,
  COMPANY_PROFILE_NOTES_UPDATES,
  COMPANY_PROFILE_DOCUMENTS_UPDATES,
  COMPANY_PROFILE_TRANSACTIONS_UPDATES,
  COMPANY_PROFILE_EMAILS_UPDATES,
  COMPANY_PROFILE_ACCOUNTING_UPDATES,
  EMAIL_CREATION,
  ...COMPANY_PROFILE_ROUTES,
]

export default companiesRoutes
