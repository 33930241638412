export const PROFILE = '/profile'
export const PROFILE_EDIT = '/profile/edit'

export const FUND_HOLDINGS_FUNDS_UPDATES = '/funds/:fundId/updates'

export const INVESTMENT_VEHICLES = '/investment-vehicles/:investmentVehicleId'

export const INVESTMENT_VEHICLES_EDIT =
  '/investment-vehicles/:investmentVehicleId/edit'

export const FUND_HOLDINGS_FUNDS_INVESTMENTS = '/funds/:fundId/investments'

export const FUND_HOLDINGS_COMPANIES_UPDATES = '/companies/:companyId/updates'

export const FUND_HOLDINGS_COMPANIES_INVESTMENTS =
  '/companies/:companyId/investments'

export const PREFIX_FUND_HOLDINGS_COMPANIES = '/companies'
export const PREFIX_FUND_HOLDINGS_FUNDS = '/funds'

const INVESTOR_GROUPS_ROUTES = [
  PROFILE,
  PROFILE_EDIT,
  FUND_HOLDINGS_FUNDS_UPDATES,
  FUND_HOLDINGS_COMPANIES_UPDATES,
  FUND_HOLDINGS_FUNDS_INVESTMENTS,
  FUND_HOLDINGS_COMPANIES_INVESTMENTS,
  INVESTMENT_VEHICLES,
  INVESTMENT_VEHICLES_EDIT,
]

export default INVESTOR_GROUPS_ROUTES
