import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import InvestorManagementService, {
  Filters,
} from 'api/InvestorManagementService'

import Toast from 'components/Toast'
import PortfolioService from 'api/PortfolioService'
import { AddInvestorToFundPayload } from 'utils/types/investors'
import UpdateService from 'api/UpdateService'
import { CapitalStatement, FundPortfolioInvestor } from 'utils/types/funds'
import { TotalUpdatesReport } from 'utils/types/update'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { FundPortfolio, PortfolioType } from 'utils/types/portfolios'
import { CustomUseQueryOptions } from 'utils/types/common'
import { SubjectType } from 'utils/types/subjects'
import { portfolioKeys } from '../../queries/portfolios'

/**
 * Query to fetch all the fund portfolios where a specific investor has transactions
 *
 * @param investorId - The id of the investor
 * @param filters - Custom filters to send to the API
 * @returns An instance of react-query
 *
 */
export const useFundPortfoliosByInvestorQuery = (
  investorId: string,
  filters?: Omit<Filters, 'page'>
) => {
  return useQuery(
    portfolioKeys.allFundPortfoliosByInvestorId(investorId),
    () => {
      try {
        return InvestorManagementService.fetchFundsPortfolioInvestors({
          investorId: [investorId],
          ...filters,
          page: 1,
          type: SubjectType.FUND,
        })
      } catch (err) {
        Toast.displayIntl(
          'investorManagement.errors.fetchFundPortfolios',
          'error'
        )
        return null
      }
    }
  )
}

/**
 * Query to fetch an specific portfolio investor by an specific portfolio id
 *
 * @param investorId - The id of the investor
 * @returns An instance of react-query
 *
 */
export const useFundPortfolioInvestorsByPortfolioIdQuery = (
  investorId: string,
  filters?: Omit<Filters, 'page'>
) => {
  return useQuery(
    portfolioKeys.allFundPortfoliosByInvestorId(investorId),
    () => {
      try {
        return InvestorManagementService.fetchFundsPortfolioInvestors({
          ...filters,
          type: SubjectType.FUND,
        })
      } catch (err) {
        Toast.displayIntl(
          'investorManagement.errors.fetchFundPortfolios',
          'error'
        )
        return null
      }
    }
  )
}

/**
 * Query to fetch the Capital statement by fund portfolio investor id
 *
 * @param fundPortfolioInvestorId - The id of the fund portfolio investor
 * @returns An instance of react-query of `CapitalStatement` interface
 *
 */
export const useCapitalStatementFundPortfolioInvestorIdQuery = (
  fundPortfolioInvestorId: string,
  queryOptions: CustomUseQueryOptions<CapitalStatement> = {}
) => {
  return useQuery(
    portfolioKeys.capitalStatementForFundPortfolioByInvestorId(
      fundPortfolioInvestorId
    ),
    async () => {
      return InvestorManagementService.fetchCapitalStatementFromFundPortfolioInvestor(
        fundPortfolioInvestorId
      )
    },
    {
      enabled: !!fundPortfolioInvestorId,
      ...queryOptions,
    }
  )
}

/**
 * Query to fetch Last Month Updates by fund portfolio investor id
 *
 * @param fundPortfolioInvestorId - The id of the fund portfolio investor
 * @returns An instance of react-query of `CapitalStatement` interface
 *
 */
export const useLastMonthUpdatesFundPortfolioInvestorIdQuery = (
  fundPortfolioInvestorId: string,
  queryOptions: CustomUseQueryOptions<TotalUpdatesReport> = {}
) => {
  return useQuery(
    portfolioKeys.lastMonthUpdatesByInvestor(fundPortfolioInvestorId),
    async () => {
      return UpdateService.getTotalUpdatesReport(fundPortfolioInvestorId)
    },
    {
      enabled: !!fundPortfolioInvestorId,
      ...queryOptions,
    }
  )
}

/**
 * Query to fetch the Capital statement for all the funds of the investor
 *
 * @returns An instance of react-query of `CapitalStatement` interface
 *
 */
export const useCapitalStatementForInvestorQuery = (
  queryOptions: CustomUseQueryOptions<CapitalStatement> = {}
) => {
  return useQuery(
    portfolioKeys.capitalStatementForInvestor(),
    async () => {
      return InvestorManagementService.fetchCapitalStatementForInvestor()
    },
    queryOptions
  )
}

/**
 * Query to fetch all the FundPortfolioInvestor of a specific fund portfolio
 *
 * @param portfolioId - The id of the fund portfolio
 * @param portfolioType - The type of the portfolio. Needed in the new /investments tab where we have all types and need to restrict this call to funds only
 * @returns An instance of react-query of `FundPortfolioInvestors` interface
 *
 */
export const useFundPortfolioInvestorsQuery = (
  portfolioId: string,
  portfolioType?: PortfolioType
) => {
  return useQuery(
    portfolioKeys.fundPortfolioInvestorsByFundId(portfolioId),
    () => {
      try {
        if (
          portfolioType === PortfolioTypes.FUND ||
          portfolioType === PortfolioTypes.DEAL
        ) {
          return PortfolioService.getFundPortfolioInvestors(portfolioId)
        }
        return null
      } catch (err) {
        Toast.displayIntl(
          'portfolioDetail.investorSummary.fetchFundPortfolioInvestorsError',
          'error'
        )
        return null
      }
    }
  )
}

/**
 * Query to fetch the index of FundPortfolioInvestor for a specific fund portfolio
 *
 * @param portfolioId - The id of the fund portfolio
 * @returns An instance of react-query of `FundPortfolioInvestor[]` interface
 *
 */
export const useFundPortfolioInvestorsIndexQuery = (portfolioId: string) => {
  return useQuery(
    portfolioKeys.fundPortfolioInvestorsIndexByFundId(portfolioId),
    () => {
      try {
        return PortfolioService.fetchFundPortfolioInvestors(portfolioId)
      } catch (err) {
        Toast.displayIntl(
          'portfolioDetail.investorSummary.fetchFundPortfolioInvestorsError',
          'error'
        )
        return null
      }
    }
  )
}

/**
 * Query to fetch all the FundPortfolioInvestor by fund portfolio investor id
 *
 * @param fundPortfolioInvestorId - The id of the fund portfolio investor
 * @returns An instance of react-query of `FundPortfolioInvestors` interface
 *
 */
export const useFundPortfolioInvestorIdQuery = (
  fundPortfolioInvestorId?: string,
  queryOptions: CustomUseQueryOptions<FundPortfolioInvestor> = {}
) => {
  return useQuery(
    portfolioKeys.fundPortfolioByInvestorId(fundPortfolioInvestorId!),
    async () => {
      return InvestorManagementService.fetchFundPortfolioInvestor(
        fundPortfolioInvestorId!
      )
    },
    {
      enabled: !!fundPortfolioInvestorId,
      ...queryOptions,
    }
  )
}

export const useAddInvestorToFundPortfolioMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: AddInvestorToFundPayload) =>
      PortfolioService.addInvestorToFundPortfolio(
        payload.portfolioId,
        payload.investorId
      ),
    {
      onSuccess: (_response: undefined, payload: AddInvestorToFundPayload) => {
        queryClient.invalidateQueries(
          portfolioKeys.fundPortfolioInvestorsByFundId(payload.portfolioId)
        )
      },
    }
  )
}

export const useDeleteInvestorFromFundPortfolioMutation = (
  currentGroupId: string,
  currentPortfolioId?: string
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (investorId: string) =>
      PortfolioService.deleteInvestorFromFundPortfolio(
        investorId,
        currentGroupId
      ),
    {
      onSuccess: () => {
        if (currentPortfolioId) {
          queryClient.invalidateQueries(
            portfolioKeys.fundPortfolioInvestorsByFundId(currentPortfolioId)
          )
        }
      },
    }
  )
}

/**
 * Query to fetch the preview of a investor on a fund portfolio
 *
 * @returns An instance of react-query of `FundPortfolio with optional fields` interface
 *
 */
export const useFundPortfolioPreviewQuery = (
  portfolioId: string,
  fundPortfolioInvestorId: string,
  queryOptions: CustomUseQueryOptions<FundPortfolio> = {}
) => {
  return useQuery(
    portfolioKeys.fundPortfolioPreviewByFundIdAndInvestorId(
      portfolioId,
      fundPortfolioInvestorId
    ),
    async () => {
      return InvestorManagementService.fetchFundPortfolioPreview(
        portfolioId,
        fundPortfolioInvestorId
      )
    },
    queryOptions
  )
}

/**
 * Query to fetch the preview of the preview of an investor's capital statement
 *
 * @returns An instance of react-query of `CapitalStatement` interface
 *
 */
export const useCapitalStatementPreviewQuery = (
  portfolioId: string,
  fundPortfolioInvestorId: string,
  queryOptions: CustomUseQueryOptions<CapitalStatement> = {}
) => {
  return useQuery(
    portfolioKeys.capitalStatementPreviewByFundIdAndInvestorId(
      portfolioId,
      fundPortfolioInvestorId
    ),
    async () => {
      return InvestorManagementService.fetchCapitalStatementPreview(
        portfolioId,
        fundPortfolioInvestorId
      )
    },
    queryOptions
  )
}
