import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { isActingAsFundManager } from 'selectors/auth'
import { useSubjectContext } from 'utils/context/subjects/SubjectContext'
import { useSubjectQuery } from 'utils/hooks/queries/subjects/useSubjectQuery'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useInvestorSubjectData } from 'utils/hooks/subjects/useInvestorSubjectData'
import { useInvestmentVehicleSubjectData } from '../../../../utils/hooks/subjects/useInvestmentVehicleSubjectData'
import {
  EditButton,
  HeaderInfoWrapper,
  SideInformationWrapper,
} from './CompanyInformation.styles'
import HeaderBasicInformation from './InvestmentVehicleHeader/HeaderBasicInformation'
import HeaderAdditionalInformation from './InvestorHeader/HeaderAdditionalInformation'

const InvestmentVehicleInformation = () => {
  const history = useHistory()
  const { subject } = useSubjectContext()
  const isFundManager = useAppSelector(isActingAsFundManager)

  const { data: investorData } = useSubjectQuery(subject?.parentSubject?.id!)

  const investor = useInvestorSubjectData(investorData?.subject)
  const investmentVehicle = useInvestmentVehicleSubjectData(subject)
  // TODO: TAS-13594
  // const { taxId, bankName, bankAccount, routingNumber } =
  //   useSubjectFinancials(subject)
  // const hasFinancialInformation =
  //   !!taxId || !!bankName || !!bankAccount || !!routingNumber

  if (!subject) {
    return null
  }

  const onEditInvestmentVehicleClickHandler = () => {
    const editInvestmentVehicleUrl = isFundManager
      ? `/investor-management/vehicles/${subject.id}/profile/edit`
      : `/investment-vehicles/${subject.id}/edit`
    history.push(editInvestmentVehicleUrl, {
      investmentVehicleId: subject.id,
    })
  }

  return (
    <SideInformationWrapper>
      <HeaderInfoWrapper>
        <HeaderBasicInformation />

        {!!investor && (
          <HeaderAdditionalInformation
            isShowingInvestmentVehicle
            investor={investor}
            investmentVehicle={investmentVehicle}
          />
        )}

        <EditButton secondary onClick={onEditInvestmentVehicleClickHandler}>
          <FormattedMessage id="investorManagement.profiles.edit" />
        </EditButton>
      </HeaderInfoWrapper>
      {/* TODO: TAS-13594 */}
      {/* {hasFinancialInformation && (
        <SideCardWrapper>
          <Card isExpandable padding="1.4rem 2.4rem">
            <Card.Header>
              {intl.formatMessage({
                id: 'investorManagement.list.extended.financialInformation',
              })}
            </Card.Header>
            <Card.Body>
              <FinancialInfo
                taxId={taxId}
                bankAccount={bankAccount}
                bankName={bankName}
                routingNumber={routingNumber}
              />
            </Card.Body>
          </Card>
        </SideCardWrapper>
      )} */}
    </SideInformationWrapper>
  )
}

export default InvestmentVehicleInformation
