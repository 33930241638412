import { useAppSelector } from 'utils/hooks/reduxToolkit'

import { useLocation, useParams } from 'react-router-dom'
import { getCurrentCompany } from 'selectors/company'
import { getCurrentPortfolio } from 'selectors/portfolio'
import { getUpdateTypeFromUrl } from 'utils/functions/updates'
import { UpdateType } from 'utils/types/common'
import useGroupCompany from './useGroupCompany'

export enum EntityName {
  PORTFOLIOS = 'portfolios',
  COMPANIES = 'companies',
  INVESTORS = 'investor_groups',
  FUNDS = 'funds',
  INVESTMENTS = 'investments',
  NONE = '',
}

export type EntityFromUrlType = {
  name: EntityName
  id: string
  type?: string
  updateType?: UpdateType
}

const useEntityFromUrl = (): EntityFromUrlType => {
  const portfolio = useAppSelector(getCurrentPortfolio)
  const location = useLocation<{ companyId?: string; portfolioId?: string }>()
  const params = useParams<{
    id?: string
    companyId?: string
    portfolioId?: string
    fundId?: string
  }>()
  const currentCompany = useAppSelector(getCurrentCompany) // client
  const groupCompany = useGroupCompany() // GP || Founder
  const company = groupCompany || currentCompany

  const updateType = getUpdateTypeFromUrl(location.pathname)

  if (location.pathname.indexOf('portfolios') >= 0 && portfolio) {
    return {
      name: EntityName.PORTFOLIOS,
      id: params.id || params.portfolioId!,
      type: portfolio?.type,
      updateType,
    }
  }

  if (location.pathname.indexOf('investments') >= 0 && portfolio) {
    return {
      name: EntityName.PORTFOLIOS,
      id: params.id || params.portfolioId || portfolio?.id,
      type: portfolio?.type,
      updateType,
    }
  }

  if (location.pathname.indexOf('funds') >= 0 && portfolio) {
    return {
      name: EntityName.FUNDS,
      id: params.id || params.fundId!,
      updateType,
    }
  }

  if (params.companyId || location?.state?.companyId || company?.id) {
    return {
      name: EntityName.COMPANIES,
      id: params.companyId || location?.state?.companyId || company?.id,
      updateType,
    }
  }

  return {
    name: EntityName.NONE,
    id: '',
  }
}

export default useEntityFromUrl
