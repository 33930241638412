import { FormattedMessage } from 'react-intl'
import IconButton from 'ui/IconButton'
import Heading from 'components/Heading'
import crunchbaseLogo from 'assets/images/crunchbaselogo.svg'
import xTwitterLogo from 'assets/images/x-twitter.svg'

import {
  StyledTitleWrapper,
  SocialIcon,
  SocialButtons,
} from './CompanySocials.styles'

type CompanySocialsProps = {
  companyInfo?: {
    linkedinUrl?: string
    angellistUrl?: string
    crunchBaseUrl?: string
    twitterUrl?: string
  }
  withTitle?: boolean
  centerLinks?: boolean
  [key: string]: any
}

const CompanySocials = ({
  companyInfo,
  withTitle = true,
  centerLinks = false,
  ...props
}: CompanySocialsProps) => {
  const { linkedinUrl, crunchBaseUrl, twitterUrl } = companyInfo || {}

  if (!linkedinUrl && !crunchBaseUrl && !twitterUrl) {
    return null
  }

  return (
    <StyledTitleWrapper {...props}>
      {withTitle && (
        <Heading className="" level="h4">
          <FormattedMessage id="companyProfile.contactDetails" />
        </Heading>
      )}

      <SocialButtons centerLinks={centerLinks}>
        {linkedinUrl && (
          <IconButton
            iconFamily="fab"
            icon="linkedin-in"
            title={linkedinUrl}
            onClick={() => window.open(linkedinUrl)}
          />
        )}
        {twitterUrl && (
          <IconButton
            iconFamily="fab"
            customIcon={<SocialIcon src={xTwitterLogo} alt="X logo" />}
            title={twitterUrl}
            onClick={() => window.open(twitterUrl)}
          />
        )}
        {crunchBaseUrl && (
          <IconButton
            iconFamily="fab"
            customIcon={<SocialIcon src={crunchbaseLogo} alt="crunchbase" />}
            title={crunchBaseUrl}
            onClick={() => window.open(crunchBaseUrl)}
          />
        )}
      </SocialButtons>
    </StyledTitleWrapper>
  )
}

export default CompanySocials
