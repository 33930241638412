import Avatar from 'components/AvatarSVG/Avatar'
import {
  ShareWithOptionType,
  getImageComponent,
} from 'containers/UpdatesV2/components/ShareSettingsModal'
import { HeaderShareWithEntity } from 'containers/UpdatesV2/components/UpdateHeader/components/UpdateVisibility/UpdateVisibility'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { getCurrentGroupData, getUser } from 'selectors/auth'
import { EMAIL_REGEX } from 'utils/functions/regex'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { PermissionItem, PermissionItemType } from 'utils/types/common'
import { AccessType, CreateUpdateGlobalProps } from 'utils/types/updateForm'
import { getPermissionsFromServerUpdate } from 'utils/functions/api/updates'
import { Update } from 'utils/types/update'
import { Globe, GlobeContainer } from '../UpdateActions.styles'

interface UseVisibilityHelpersProps {
  values: CreateUpdateGlobalProps
  isEditMode: boolean
  isShowMode: boolean
  update: Update
}

const useVisibilityHelpers = ({
  values,
  isEditMode,
  isShowMode,
  update,
}: UseVisibilityHelpersProps) => {
  const intl = useIntl()
  const currentGroup = useAppSelector(getCurrentGroupData)
  const currentUser = useAppSelector(getUser)

  const { permissions: permissionsFromValues } = values
  const [permissions, setPermissions] = useState(permissionsFromValues)

  useEffect(() => {
    if (update && !update.isDraftUpdate && !isEditMode) {
      setPermissions(getPermissionsFromServerUpdate(update))
      return
    }

    setPermissions(permissionsFromValues)
  }, [isEditMode, permissionsFromValues, update])

  const investor = values?.investor
  const updateOwner = values?.owner

  const getCommunityGroup = useCallback((): HeaderShareWithEntity[] => {
    if (permissions.community === AccessType.CAN_VIEW) {
      return [
        {
          id: 'community',
          name: intl.formatMessage({
            id: 'updates.clockworkUniverseCommunity',
          }),
          description: intl.formatMessage({
            id: 'updates.header.public',
          }),
          logoComponent: (
            <Avatar size="2.1rem">
              <GlobeContainer>
                <Globe color="white" fill="red" icon={['far', 'globe']} />
              </GlobeContainer>
            </Avatar>
          ),
        },
      ]
    }

    return []
  }, [permissions.community, intl])

  const getInvestorGroup = useCallback((): HeaderShareWithEntity[] => {
    if (permissions.investor === AccessType.CAN_VIEW && investor?.id) {
      return [
        {
          id: 'investor',
          name: investor?.name!,
          logo: investor?.logo,
        },
      ]
    }

    return []
  }, [permissions.investor, investor?.id, investor?.name, investor?.logo])

  const getCurrentGroupPermission = useCallback((): PermissionItem[] => {
    if (
      (permissions.yourGroup === AccessType.CAN_VIEW ||
        permissions.yourGroup === AccessType.CAN_EDIT) &&
      currentGroup
    ) {
      return [
        {
          id: currentGroup.id,
          name: currentGroup.name,
          type: PermissionItemType.GROUP,
          logo: currentGroup.logo,
        },
      ]
    }

    return []
  }, [permissions.yourGroup, currentGroup])

  const groupsAndUsersPermissions = useMemo<HeaderShareWithEntity[]>(() => {
    return permissions.sharedWith.map((entity) => ({
      id: entity.id,
      name: entity.name,
      logoComponent: getImageComponent(
        EMAIL_REGEX.test(entity.name) ? ShareWithOptionType.Email : entity.type,
        entity.name,
        entity.imageUrl
      ),
    }))
  }, [permissions.sharedWith])

  const defaultGroupsPermissions = useMemo<HeaderShareWithEntity[]>(() => {
    let userPermission: HeaderShareWithEntity

    if (isEditMode || isShowMode) {
      userPermission = {
        id: updateOwner?.id!,
        name: updateOwner?.name!,
        logo: updateOwner?.image!,
        description: intl.formatMessage({
          id: 'updates.header.owner',
        }),
      }
    } else {
      userPermission = {
        id: currentUser.id,
        name: currentUser.name,
        logo: currentUser.image,
        description: intl.formatMessage({
          id: 'updates.header.owner',
        }),
      }
    }

    return [
      userPermission,
      ...getCommunityGroup(),
      ...getInvestorGroup(),
      ...getCurrentGroupPermission(),
    ]
  }, [
    currentUser.id,
    currentUser.image,
    currentUser.name,
    getCommunityGroup,
    getCurrentGroupPermission,
    getInvestorGroup,
    intl,
    isEditMode,
    isShowMode,
    updateOwner?.id,
    updateOwner?.image,
    updateOwner?.name,
  ])

  return {
    groupsAndUsersPermissions,
    defaultGroupsPermissions,
  }
}

export default useVisibilityHelpers
