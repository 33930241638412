/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import RichTextEditor from 'components/RichTextEditor'
import AddSectionEntry from 'components/MJMLGenerator/AddSectionEntry'
import { setupMJMLEmailPreview, TEXT } from 'utils/functions/mjml'
import DraggableCircles from 'components/EmailSectionAccordion/components/DraggableCircles'
import ColorPicker, {
  needContrast,
  getContrastColor,
} from 'components/ColorPicker/ColorPicker'
import Checkbox from 'components/Checkbox'

import SectionImage from '../SectionImage'
import styles from './SectionPreview.module.scss'

const SectionPreview = ({
  section,
  index,
  onChangeSectionName,
  onChangeSection,
  createAssetToEmail,
  onAddEntryToSection,
  readOnly,
  handleRemoveSection,
  onRemoveEntryFromSection,
  onSelectColor,
  onChangeImageSize,
  onChangeShowTitle,
  onChangeTextAlign,
}) => {
  const [title, setTitle] = useState(section.title)
  const [focus, setFocus] = useState({})
  const tags = setupMJMLEmailPreview(section.text ?? '', false)
  const intl = useIntl()
  const sectionTextColor = needContrast(section.backgroundColor)
    ? getContrastColor(section.backgroundColor)
    : '#000000'

  useEffect(() => {
    setTitle(section.title)
  }, [section.title])

  if (readOnly) {
    return (
      <>
        {index !== 0 && <div className={styles.readOnlySeparator} />}
        <div
          className={classNames(styles.section, styles.readOnlySection)}
          style={{
            backgroundColor: section.backgroundColor,
            color: sectionTextColor,
          }}
        >
          {title !== null && section.showTitle && (
            <div
              className={styles.sectionTitle}
              style={{ color: sectionTextColor }}
            >
              {title}
            </div>
          )}
          {tags.map((tag, tagIndex) =>
            tag.type === 'img' ? (
              <SectionImage
                key={tag.id}
                entryId={tag.id}
                readOnly
                fileUrl={tag.fileUrl}
                sectionId={section.id}
                index={tagIndex}
                imageSize={tag.imageSize}
              />
            ) : (
              <div
                key={tag}
                className={classNames(styles.htmlPreview)}
                style={{ color: sectionTextColor }}
                dangerouslySetInnerHTML={{ __html: tag }}
              />
            )
          )}
        </div>
      </>
    )
  }

  return (
    <div className={styles.sectionPreviewContainer}>
      {index !== 0 && <div className={styles.separator} />}
      <div className={styles.draggableContainer}>
        <div className={styles.dragAndDropIcon}>
          <DraggableCircles />
        </div>
        <div
          className={classNames(styles.section, styles.sectionTitleContainer)}
          style={{
            backgroundColor: section.backgroundColor,
          }}
        >
          <div className={styles.sectionContent}>
            <div className={styles.sectionContentWrapper}>
              <div className={styles.removeSection}>
                <Checkbox
                  id={`showTitle_${section.id}`}
                  name={`showTitle_${section.id}`}
                  label={intl.messages['createEmail.showTitle']}
                  onChange={(event) =>
                    onChangeShowTitle(section.id, event.target.checked)
                  }
                  checked={section.showTitle}
                />
                <ColorPicker
                  color={section.backgroundColor}
                  onChange={onSelectColor}
                />
                <button
                  className={styles.btnRemoveSection}
                  type="button"
                  onClick={() => handleRemoveSection(section.id)}
                >
                  <FontAwesomeIcon icon={['fas', 'times-circle']} />
                </button>
              </div>
              {section.showTitle && (
                <div className={styles.sectionTitleWrapper}>
                  <input
                    id={`sectionTitle_${section.id}`}
                    className={classNames(styles.sectionTitle, styles.editable)}
                    style={{ color: sectionTextColor }}
                    value={title}
                    onChange={(event) => {
                      setTitle(event.target.value)
                      onChangeSectionName(event.target.value, section.id)
                    }}
                  />
                </div>
              )}
            </div>

            {section?.entries?.map((entry, entryIndex) => {
              if (entry.content?.fileUrl) {
                return (
                  <SectionImage
                    key={entry.id}
                    entryId={entry.id}
                    index={entryIndex}
                    readOnly={readOnly}
                    fileUrl={entry.content?.fileUrl}
                    createAssetToEmail={createAssetToEmail}
                    sectionId={section.id}
                    onRemoveEntryFromSection={onRemoveEntryFromSection}
                    imageSize={entry.imageSize}
                    onChangeImageSize={onChangeImageSize}
                  />
                )
              }

              if (readOnly) {
                return (
                  <div
                    key={entry.id}
                    className={styles.htmlPreview}
                    dangerouslySetInnerHTML={{ __html: entry?.content }}
                  />
                )
              }

              return (
                <div
                  className={classNames(
                    'richEditor',
                    `richEditor_${section.id}`,
                    styles.richEditorContainer,
                    {
                      [styles.focused]: focus[index],
                    }
                  )}
                  key={entry.id}
                >
                  <RichTextEditor
                    onFocus={() =>
                      setFocus((current) => ({ ...current, [index]: true }))
                    }
                    onBlur={() =>
                      setFocus((current) => ({ ...current, [index]: false }))
                    }
                    index={entryIndex}
                    id={`sectionPreview_${section.id}_${index}`}
                    text={entry?.content}
                    backgroundFocus
                    textColor={sectionTextColor}
                    backgroundColor={section.backgroundColor}
                    onChange={(html) => {
                      onChangeSection({
                        value: html,
                        sectionId: section.id,
                        entryId: entry.id,
                      })
                    }}
                    showAlignmentToolbar
                    onChangeTextAlign={(textAlign) =>
                      onChangeTextAlign(section.id, entry.id, textAlign)
                    }
                    textAlign={entry.textAlign}
                  />

                  <div>
                    <button
                      className={styles.btnRemoveSection}
                      type="button"
                      onClick={() =>
                        onRemoveEntryFromSection(section.id, entry.id)
                      }
                    >
                      <FontAwesomeIcon icon={['fas', 'times-circle']} />
                    </button>
                  </div>
                </div>
              )
            })}

            {!readOnly && (
              <div className={styles.addSectionEntryContainer}>
                <AddSectionEntry
                  onAddText={() => onAddEntryToSection(section.id, TEXT)}
                  onAddImage={(image) => createAssetToEmail(image, section.id)}
                  onRemoveSection={() => handleRemoveSection(section.id)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

SectionPreview.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    backgroundColor: PropTypes.string,
    entries: PropTypes.array,
    showTitle: PropTypes.bool,
  }).isRequired,
  onChangeSectionName: PropTypes.func,
  onChangeSection: PropTypes.func,
  createAssetToEmail: PropTypes.func,
  onAddEntryToSection: PropTypes.func,
  handleRemoveSection: PropTypes.func,
  onRemoveEntryFromSection: PropTypes.func,
  onChangeImageSize: PropTypes.func,
  onChangeShowTitle: PropTypes.func,
  onChangeTextAlign: PropTypes.func,
  readOnly: PropTypes.bool,
  onSelectColor: PropTypes.func,
  index: PropTypes.number.isRequired,
}

SectionPreview.defaultProps = {
  readOnly: false,
  onChangeSectionName: () => {},
  onChangeSection: () => {},
  createAssetToEmail: () => {},
  onAddEntryToSection: () => {},
  handleRemoveSection: () => {},
  onRemoveEntryFromSection: () => {},
  onChangeImageSize: () => {},
  onChangeShowTitle: () => {},
  onChangeTextAlign: () => {},
  onSelectColor: () => {},
}
export default SectionPreview
