import { Item } from 'containers/InvestorManagement/components'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { useSubjectContext } from 'utils/context/subjects/SubjectContext'
import { removeHttp } from 'utils/functions/strings'
import { Employee, Industry, LegalStructure } from 'utils/types/company'
import { GooglePlaceData } from 'utils/types/locations'
import { SubjectType } from 'utils/types/subjects'
import {
  AdditionInformation,
  AdditionInformationWrapper,
  EditCompanyButton,
} from './HeaderAdditionalInformation.styles'

const HeaderAdditionalInformation = () => {
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()

  const { subject, getAttribute, hasWritePermissions } = useSubjectContext()

  const employees = getAttribute<Employee[]>('employees')?.value || []

  const email = getAttribute<string>('emails')?.value?.[0]
  const phone = getAttribute<string>('phone')?.value
  const website = getAttribute<string>('website')?.value
  const companyLocation =
    getAttribute<GooglePlaceData[]>('locations')?.value?.[0]
  const foundedDate = getAttribute<Date>('foundedDate')?.value
  const employeesCount =
    getAttribute<number>('employeesCount')?.value ?? employees.length
  const legalStructure = getAttribute<LegalStructure>('legalStructure')?.value
  const industries = getAttribute<Industry[]>('industries')?.value

  const redirectToEditCompanyProfile = () => {
    const currentPath = location.pathname
    const relativePath = 'edit'

    if (currentPath.includes(relativePath)) return

    const newPath = `${currentPath}/${relativePath}`

    const idMapper = {
      [SubjectType.COMPANY]: 'companyId',
      [SubjectType.INVESTOR]: 'investorId',
      [SubjectType.INVESTMENT_VEHICLE]: 'investmentVehicleId',
    }

    const routeState = {
      [idMapper[subject.type]]: subject.id,
    }

    history.push(newPath, routeState)
  }

  return (
    <AdditionInformationWrapper>
      <AdditionInformation>
        {email && (
          <Item
            isDark
            icon={['far', 'envelope']}
            value={email}
            type="darkLink"
            maxLines={1}
            id="investor_email"
          />
        )}

        {website && (
          <Item
            isDark
            icon={['far', 'link']}
            value={removeHttp(website)}
            type="darkLink"
            href={website}
            maxLines={1}
            id="company_website"
            topOffset={40}
          />
        )}

        {phone && (
          <Item icon={['far', 'phone-alt']} value={phone} type="text" />
        )}

        {companyLocation?.formattedAddress && (
          <Item
            icon={['far', 'map-marker-alt']}
            value={companyLocation.formattedAddress}
            type="text"
          />
        )}

        {foundedDate && (
          <Item
            icon={['far', 'birthday-cake']}
            value={`Founded ${dayjs(foundedDate).format('MMMM YYYY')}`}
            type="text"
          />
        )}

        {employeesCount > 0 && (
          <Item
            icon={['far', 'users']}
            value={
              employeesCount > 1
                ? intl.formatMessage(
                    { id: 'profiles.employees' },
                    { employeesCount }
                  )
                : intl.formatMessage(
                    { id: 'profiles.employee' },
                    { employeesCount }
                  )
            }
            type="text"
          />
        )}

        {legalStructure?.name && (
          <Item
            icon={['far', 'list-ul']}
            value={legalStructure.name}
            type="text"
          />
        )}

        {!!industries?.length && (
          <Item
            isDark
            icon={['far', 'industry-alt']}
            value={industries.map((ind) => ind.name).join(', ')}
            type="tooltip"
            maxLines={3}
          />
        )}
      </AdditionInformation>
      {hasWritePermissions && (
        <EditCompanyButton secondary onClick={redirectToEditCompanyProfile}>
          {intl.formatMessage({ id: 'companyProfile.editProfile' })}
        </EditCompanyButton>
      )}
    </AdditionInformationWrapper>
  )
}

export default HeaderAdditionalInformation
