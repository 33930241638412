import {
  ALREADY_TAKEN_ERROR,
  TERMS_OF_USE_NOT_ACCEPTED,
} from 'utils/constants/errors'

export const isDuplicatedFieldError = (
  fieldName: string,
  error: Error
): boolean => {
  try {
    if (typeof error.message !== 'string') return false

    const jsonErr = JSON.parse(error.message)
    if (jsonErr?.[fieldName]) {
      if (Array.isArray(jsonErr[fieldName])) {
        return jsonErr[fieldName].includes?.(ALREADY_TAKEN_ERROR)
      }
      return jsonErr[fieldName] === ALREADY_TAKEN_ERROR
    }
    return false
  } catch (err) {
    return false
  }
}

export const isTermsOfUseError = (error: Error): boolean => {
  return error.message.includes(TERMS_OF_USE_NOT_ACCEPTED)
}

export const isTypeIdentifierDuplicatedErrorMessage = (errMsg: string) => {
  return errMsg.includes('typeIdentifier is duplicated')
}

export const isTypeIdentifierDuplicatedError = (error) => {
  return isTypeIdentifierDuplicatedErrorMessage(error.message)
}
