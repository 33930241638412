import { SkeletonItem } from 'components/Skeletons/Skeletons.styles'
import Tooltip from 'components/Tooltip'
import { FormattedDate, FormattedNumber, useIntl } from 'react-intl'
import { color } from 'utils/functions/colors'
import theme from 'utils/theme'
import { IndexMetric } from 'utils/types/metricsV2'
import { Cell, TooltipContent } from '../AllMetrics.styles'
import useCumulativeMetric from '../useCumulativeMetric'

interface Props {
  rowData: IndexMetric
}

const CurrentValue = ({ rowData: metric }: Props) => {
  const intl = useIntl()

  const { isCumulativeMetric, isLoadingCumulativeValue, currentValue } =
    useCumulativeMetric({
      metric,
    })

  if (isCumulativeMetric && isLoadingCumulativeValue) {
    return (
      <Cell>
        <SkeletonItem width="5rem" height="1.8rem" />
      </Cell>
    )
  }

  return currentValue ? (
    <Cell>
      <Tooltip
        backgroundColor={color('white')({ theme })}
        boxShadow="0px 0px 15px 0px #1015271a"
        color={color('darkGray')({ theme })}
        id={`metric-current-value-date-${metric.id}`}
        place="top"
        text={
          <TooltipContent>
            <span>
              {intl.formatMessage({
                id: 'metrics.allMetrics.latestValueDate',
              })}
            </span>
            <FormattedDate
              value={currentValue.date}
              day="numeric"
              month="short"
              year="numeric"
            />
          </TooltipContent>
        }
      >
        <FormattedNumber value={currentValue.value} />
      </Tooltip>
    </Cell>
  ) : (
    <Cell>-</Cell>
  )
}

export default CurrentValue
