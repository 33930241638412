import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import AddInput from 'components/AddInput'
import List from 'components/List'
import SetAsMainRow from 'components/List/SetAsMainRow'
import Title from 'components/Title'

import * as Styles from '../common/styles'

type Props = {
  companyNames: string[]
  onChange: (companyNames: string[]) => void
  onSetMainName: (name: string) => void
}
const CompanyName = ({ companyNames, onSetMainName, onChange }: Props) => {
  const intl = useIntl()

  const setAsMain = (index) => {
    if (index < 0 || index >= companyNames.length) {
      return
    }

    const rows = [...companyNames]

    ;[rows[0], rows[index]] = [rows[index], rows[0]]
    onChange(rows)
    onSetMainName(rows[0])
  }

  const removeItem = (index: number) => {
    const rows = [...companyNames]
    rows.splice(index, 1)
    onChange(rows)
  }

  const getRows = useMemo(
    () =>
      companyNames.map((elem, index) => (
        <SetAsMainRow
          key={elem}
          text={elem}
          isMain={!index}
          deletable={!!index}
          setAsMain={() => setAsMain(index)}
          removeItem={() => removeItem(index)}
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyNames]
  )
  return (
    <Styles.Item>
      <Title title={intl.formatMessage({ id: 'editCompany.name' })} />
      <AddInput
        handleAdd={(value: string) => onChange([...companyNames, value])}
        placeholder={intl.formatMessage({ id: 'editCompany.doingBusinessAs' })}
        forceShowIcon
        checkLengthError
      />
      <List elements={getRows} striped={false} rowHeightAuto rowWidthAuto />
    </Styles.Item>
  )
}

export default CompanyName
