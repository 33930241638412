import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { usePreference } from 'utils/hooks/usePreference'

import Modal from 'components/Modal'
import { getAccessToken } from 'features/authSlice'
import { ApiEnv, DevEnvironment } from 'features/preferenceSlice'
import Button from 'ui/Button'
import Dropdown from 'ui/Dropdown'
import { Label } from 'ui/InputLabel/InputLabel.styles'
import Separator from 'ui/Separator'
import { PROD_ENV } from 'utils/functions/env'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { TextArea } from './DevEnvironmentSelector.styles'
import packageJson from '../../../package.json'

type EnvOption<T = ApiEnv | DevEnvironment> = {
  id: string
  label: string
  value: T
}

const envs: EnvOption<ApiEnv>[] = [
  { id: 'develop', label: 'Develop', value: ApiEnv.DEVELOP },
  { id: 'testing', label: 'Testing', value: ApiEnv.TESTING },
  { id: 'staging', label: 'Staging', value: ApiEnv.STAGING },
  { id: 'production', label: 'Production', value: ApiEnv.PRODUCTION },
]

const devEnvironments: EnvOption<DevEnvironment>[] = Object.values(
  DevEnvironment
).map((env) => ({
  id: env,
  label: env,
  value: env,
}))

const DevEnvironmentSelector = () => {
  const [devEnvironment, setDevEnvironment] =
    usePreference<DevEnvironment>('devEnvironment')

  const [customApiEnv, setCustomApiEnv] = usePreference<ApiEnv>('customApiEnv')

  const [showMenu, setShowMenu] = useState(true)

  const history = useHistory()

  const accessToken = useAppSelector(getAccessToken)

  const onConfirmDevEnvironment = async () => {
    setShowMenu(false)
    setTimeout(() => {
      history.replace('/sign-out')
    }, 500)
  }

  if (PROD_ENV) {
    return null
  }

  return (
    <Modal show={showMenu}>
      <Modal.Header>
        Customize Environment - v{packageJson.version}
      </Modal.Header>
      <Modal.Body>
        <Dropdown
          options={envs}
          getOption={(option) => option}
          value={customApiEnv}
          onSelectOption={(_, __, selectedOption) => {
            setCustomApiEnv(selectedOption.value)
          }}
          showBorder
          iconFontSize="2.3rem"
          minWidth="auto"
          showIcon
          iconOnLeft
          label="API environment"
        />
        {(customApiEnv === ApiEnv.DEVELOP ||
          customApiEnv === ApiEnv.TESTING) && (
          <>
            <Separator space="1rem" />

            <Dropdown
              options={devEnvironments}
              getOption={(option) => option}
              value={devEnvironment}
              onSelectOption={(_, __, selectedOption) => {
                setDevEnvironment(selectedOption.value)
              }}
              showBorder
              iconFontSize="2.3rem"
              minWidth="auto"
              showIcon
              iconOnLeft
              label="Environment header"
            />
          </>
        )}

        {accessToken && (
          <div>
            <Separator space="1rem" />
            <Label>Access Token</Label>
            <div>
              <TextArea>{accessToken}</TextArea>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.RowFooter>
        <Button onClick={onConfirmDevEnvironment}>Reload App</Button>
      </Modal.RowFooter>
    </Modal>
  )
}

export default DevEnvironmentSelector
