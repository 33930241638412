import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import PortfolioSubjectIcon from 'components/PortfolioSubjectIcon'
import Tooltip from 'components/Tooltip'
import { useProfileContext } from 'containers/Profiles/ProfileContext'
import {
  FullSubjectFragment,
  SortDirection,
} from 'types/graphql-schemas/graphql'
import { maxSize } from 'utils/constants/breakpoint'
import { useFullSubjectsQuery } from 'utils/hooks/queries/subjects/useSubjectsQuery'
import { useHighlightText } from 'utils/hooks/useHighlightText'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import useOnClickRedirect from 'utils/hooks/useOnClickRedirect'
import theme from 'utils/theme'
import { SubjectType } from 'utils/types/subjects'
import { getPermission } from 'utils/gql/helpers/subjects'
import { PUBLIC_PERMISSION } from 'utils/gql/helpers/permissions'
import * as Styles from './FundsList.styles'

export const SLIDER_CONTAINER_ID = 'public-funds-list'

export const useFundsList = (fundCompanyId: string) => {
  const { handleOnHoldingSubjectClick } = useOnClickRedirect()
  const { matches: isMobile } = useMediaQuery(maxSize.sm)
  const { debouncedSearch } = useProfileContext()
  const intl = useIntl()

  const { data, isLoading } = useFullSubjectsQuery({
    filters: {
      name: debouncedSearch,
      type: [SubjectType.FUND, SubjectType.DEAL],
      parentSubject: fundCompanyId,
    },
    sorting: {
      orderBy: 'name',
      direction: SortDirection.Asc,
    },
  })

  const funds = useMemo(
    () => data?.pages?.flatMap((page) => page) ?? [],
    [data]
  )

  useHighlightText(
    {
      elementClass: '.fund-name',
      text: debouncedSearch,
    },
    [debouncedSearch, isLoading, funds]
  )

  const checkArrowsVisibility = useCallback(() => {
    const publicFundsList = document.getElementById(SLIDER_CONTAINER_ID)
    const activeSlidesArray =
      publicFundsList?.getElementsByClassName('slick-active')

    if (publicFundsList && activeSlidesArray) {
      const firstActive = Number(
        activeSlidesArray[0]?.getAttribute('data-index')
      )
      const lastActive = Number(
        activeSlidesArray[activeSlidesArray.length - 1]?.getAttribute(
          'data-index'
        )
      )

      const showPrevArrow = firstActive !== 0
      const showNextArrow = lastActive !== funds.length - 1

      if (!showPrevArrow) {
        publicFundsList
          .getElementsByClassName('slick-prev')[0]
          ?.setAttribute('style', 'display: none')
      } else {
        publicFundsList
          .getElementsByClassName('slick-prev')[0]
          ?.removeAttribute('style')
      }

      if (!showNextArrow) {
        publicFundsList
          .getElementsByClassName('slick-next')[0]
          ?.setAttribute('style', 'display: none')
      } else {
        publicFundsList
          .getElementsByClassName('slick-next')[0]
          ?.removeAttribute('style')
      }
    }
  }, [funds.length])

  const renderFund = useCallback(
    (fund: FullSubjectFragment) => {
      const isAPrivateFund = !getPermission(fund.permissions, PUBLIC_PERMISSION)
        ?.read

      return (
        <Styles.Fund
          key={fund.id}
          isMobile={isMobile}
          onClick={() => handleOnHoldingSubjectClick(fund)}
        >
          <PortfolioSubjectIcon
            type={fund.type as SubjectType.FUND | SubjectType.DEAL}
            backgroundColor={
              isAPrivateFund ? theme.colors.lightGray : undefined
            }
          />
          <Styles.FundName className="fs-exclude fund-name">
            <Tooltip
              id={`metric-name-${fund.id}`}
              place="bottom"
              text={fund.name}
              delayShow={500}
            >
              {fund.name}
            </Tooltip>
          </Styles.FundName>
          {isAPrivateFund && (
            <Styles.PrivateFundEye>
              <Tooltip
                id={`private-fund-${fund.id}`}
                place="left"
                text={intl.formatMessage({
                  id: 'profiles.privateFundTooltip',
                })}
              >
                <Styles.EyeSlash icon={['far', 'eye-slash']} />
              </Tooltip>
            </Styles.PrivateFundEye>
          )}
        </Styles.Fund>
      )
    },
    [isMobile, intl, handleOnHoldingSubjectClick]
  )

  return {
    funds,
    isLoading,
    checkArrowsVisibility,
    renderFund,
  }
}

export const SLIDER_SETTINGS = {
  infinite: false,
  draggable: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  nextArrow: (
    <Styles.Arrow>
      <FontAwesomeIcon icon={['far', 'chevron-right']} />
    </Styles.Arrow>
  ),
  prevArrow: (
    <Styles.Arrow>
      <FontAwesomeIcon icon={['far', 'chevron-left']} />
    </Styles.Arrow>
  ),
  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1220,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1149,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 530,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}
