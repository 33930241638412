import { useState, useEffect } from 'react'
import { Droppable, type DroppableProps } from 'react-beautiful-dnd'

// Fixes React 18 issue: https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
export const ReactDndDroppableWrapper = ({
  children,
  ...props
}: DroppableProps) => {
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))

    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])

  if (!enabled) {
    return null
  }

  return <Droppable {...props}>{children}</Droppable>
}
