import { combineReducers } from 'redux'
import types from 'actions/types'
import { ActionType } from 'redux-promise-middleware'

import investorManagementSlice from 'features/investorManagementSlice'
import signUpSlice from 'features/signUpSlice'
import createNewGroupSlice from 'features/createNewGroupSlice'
import sidebarSlice from 'features/sideBarSlice'
import routesSlice from 'features/routesSlice'
import authSlice from 'features/authSlice'
import preferenceSlice, {
  getInitialPreferencesState,
} from 'features/preferenceSlice'
import chatSlice from 'features/chatSlice'
import limitationsSlice from 'features/limitationsSlice'
import redirectSlice from 'features/redirectSlice'

import serviceWorker from './serviceWorker'
import companies from './companies'
import portfolios from './portfolios'
import emails from './emails'
import university from './university'
import metrics from './metrics'
import uploadsSlice from './uploadsSlice'
import breadcrumbSlice from './breadcrumbSlice'
import selectedMetricsSlice from './selectedMetricsSlice'

const { Fulfilled } = ActionType
const appReducer = combineReducers({
  auth: authSlice,
  serviceWorker,
  sideBar: sidebarSlice,
  companies,
  portfolios,
  preferences: preferenceSlice,
  emails,
  university,
  metrics,
  investorManagement: investorManagementSlice,
  signUp: signUpSlice,
  uploads: uploadsSlice,
  breadcrumbState: breadcrumbSlice,
  chat: chatSlice,
  limitations: limitationsSlice,
  routes: routesSlice,
  createNewGroup: createNewGroupSlice,
  redirect: redirectSlice,
  selectedMetrics: selectedMetricsSlice,
})

const rootReducer = (state, action) => {
  if (action.type === 'auth/signOut') {
    return appReducer(
      {
        preferences: getInitialPreferencesState(state.preferences),
        redirect: state.redirect,
      },
      action
    )
  }

  return appReducer(state, action)
}

export default rootReducer
