import SubjectSelector from 'components/Selectors/SubjectSelector'
import { getCurrentGroupId } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { SubjectType } from 'utils/types/subjects'
import useInvestorSelector from './useInvestorSelector'

interface InvestorsProps {
  isDisabled: boolean
  onSelectInvestorCallback?: (option: any) => void
}

const InvestorSelector: React.FC<InvestorsProps> = ({
  isDisabled,
  onSelectInvestorCallback,
}) => {
  const { field, messages, onSelectOption } = useInvestorSelector()
  const currentGroupId = useAppSelector(getCurrentGroupId)

  return (
    <SubjectSelector
      disabled={isDisabled}
      label={messages.label}
      placeholder={messages.placeholder}
      searchPlaceholder={messages.searchPlaceholder}
      filters={{
        type: [SubjectType.INVESTOR],
        groupOwner: currentGroupId,
      }}
      selectOptionHandler={(option) => {
        onSelectOption(option)
        onSelectInvestorCallback?.(option)
      }}
      {...field[0]}
    />
  )
}

export default InvestorSelector
