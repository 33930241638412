import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import {
  ArrowWrapper,
  NavigationWrapper,
} from 'components/Breadcrumb/Breadcrumb.styles'
import EntityLink from 'components/Breadcrumb/Components/EntityLink'
import InitialLink from 'components/Breadcrumb/Components/InitialLink'
import UpdateLink from 'components/Breadcrumb/Components/UpdateLink'
import { Update } from 'utils/types/update'
import { Nullable } from 'utils/types/common'
import { URL } from 'reducers/breadcrumbSlice'
import { BreadcrumbEntity } from '../useEntityInformation'

interface Props {
  update?: Update
  entityToShow?: BreadcrumbEntity
  url: URL
  isShowingCompany: boolean
  initialLinkRedirect: string
  isCreatingNewUpdate: boolean
  getAvatar: () => Nullable<JSX.Element>
}

const UpdatesBreadcrumb = ({
  update,
  entityToShow,
  url,
  isShowingCompany,
  initialLinkRedirect,
  isCreatingNewUpdate,
  getAvatar,
}: Props) => {
  const intl = useIntl()
  const history = useHistory()

  return (
    <NavigationWrapper>
      <>
        <InitialLink
          isActive={false}
          label={intl.formatMessage({ id: 'breadcrumb.updates' })}
          onClickHandler={() => history.push(initialLinkRedirect)}
        />

        <ArrowWrapper>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </ArrowWrapper>
      </>

      {entityToShow && (
        <>
          <EntityLink
            isActive={
              isShowingCompany
                ? url.current === `/updates/companies/${entityToShow.id}`
                : url.current === `updates/funds/${entityToShow.id}`
            }
            label={entityToShow.name}
            entityLogo={getAvatar()}
            onClickHandler={() => {
              const entityUrl = isShowingCompany
                ? `/updates/companies/${entityToShow.id}`
                : `updates/funds/${entityToShow.id}`

              if (url.current !== entityUrl) {
                history.push(entityUrl)
              }
            }}
          />

          {update && (
            <ArrowWrapper>
              <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </ArrowWrapper>
          )}
        </>
      )}

      <UpdateLink update={update} isCreatingNewUpdate={isCreatingNewUpdate} />
    </NavigationWrapper>
  )
}

export default UpdatesBreadcrumb
