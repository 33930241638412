import Avatar from 'components/Avatar'
import { getCurrentGroupData, isActingAsFounder } from 'selectors/auth'
import { getInitials } from 'utils/functions/user'
import { useSubjectQuery } from 'utils/hooks/queries/subjects/useSubjectQuery'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { SubjectType } from 'utils/types/subjects'
import { Group } from 'utils/types/user'
import useParseCurrentUrl from '../useParseCurrentUrl'

export type BreadcrumbEntity = {
  id: string
  name: string
  logo?: string | null
  type: string
  parentSubject?: {
    id: string
    name: string
    logo?: string | null
    type: string
  }
}
const useEntityInformation = () => {
  const groupData = useAppSelector(getCurrentGroupData)
  const isFounder = useAppSelector(isActingAsFounder)

  const { companyId, fundId } = useParseCurrentUrl()

  const founderCompanyId = (groupData as Group)?.companyData?.[0]?.id
  const companyIdToUse = isFounder ? founderCompanyId : companyId
  const entityId = companyIdToUse

  const { data } = useSubjectQuery(entityId || fundId)

  const info: BreadcrumbEntity | undefined = data?.subject
    ? {
        id: data?.subject.id!,
        name: data?.subject.name!,
        logo: data?.subject.logo!,
        type: data?.subject.type!,
        parentSubject: data?.subject.parentSubject
          ? {
              id: data?.subject.parentSubject?.id!,
              name: data?.subject.parentSubject?.name!,
              logo: data?.subject.parentSubject?.logo!,
              type: data?.subject.parentSubject?.type!,
            }
          : undefined,
      }
    : undefined

  const companyLogo = info?.logo
  const fundLogo = info?.parentSubject?.logo

  const logoToShow = fundLogo ?? companyLogo

  const isShowingCompany = info?.type === SubjectType.COMPANY

  const getAvatar = () => {
    if (!data) return null

    if (!logoToShow) {
      return (
        <Avatar
          initials={getInitials(info?.name)}
          avatarStyle="avatarCircleXS"
        />
      )
    }
    return <Avatar image={logoToShow} avatarStyle="avatarCircleXS" />
  }

  return {
    companyLogo: info?.logo,
    fundLogo: info?.logo,
    companyInfo: info,
    companyId,
    publicFundProfile: info,
    isShowingCompany,
    entityToShow: info,
    getAvatar,
  }
}

export default useEntityInformation
