import React from 'react'
import { Helmet } from 'components/Helmet'
import Drawer from 'components/Drawer'
import { useIntl } from 'react-intl'
import Tabs from 'components/Tabs'
import { InvestorManagementSidebarProps } from 'utils/types/investors'
import { isActingAsFundManager } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useEditInvestor } from './useEditInvestor'
import EditInvestorInformation from './EditInvestorInformation'
import EditInvestorUsers from './EditInvestorUsers'

import {
  EditInvestorTabs,
  InvestorGeneralInformation,
} from './EditInvestor.styles'

const EditInvestor: React.FC<InvestorManagementSidebarProps> = ({
  onCloseDrawer,
  isDrawerOpen,
}) => {
  const intl = useIntl()
  const isFundManager = useAppSelector(isActingAsFundManager)

  const {
    investorSubject,
    investorRailsId,
    isLoading,
    editInvestor,
    resendInvite,
    addInvestorGroupUsers,
    deleteInvestorGroupUser,
    formRef,
  } = useEditInvestor(onCloseDrawer)

  if (!investorSubject) return null

  return (
    <Drawer
      isOpen={isDrawerOpen}
      isLoading={isLoading}
      onCloseDrawer={onCloseDrawer}
      headerTitle={intl.formatMessage({
        id: isFundManager
          ? 'investorManagement.editInvestor.editInvestorInformation'
          : 'investorManagement.editInvestor.editProfile',
      })}
    >
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'metaTitles.editInvestor',
          })}
        </title>
      </Helmet>

      <Drawer.Content contentPadding="0 1.6rem 2.4rem 2.4rem">
        {isFundManager ? (
          <EditInvestorTabs
            tabOffset={24}
            items={[
              {
                id: 'INFORMATION',
                title: intl.formatMessage({
                  id: 'investorManagement.editInvestor.information',
                }),
              },
              {
                id: 'MEMBERS',
                title: intl.formatMessage({
                  id: 'investorManagement.editInvestor.members',
                }),
              },
            ]}
            withBorder
          >
            <Tabs.TabContent id="INFORMATION">
              <EditInvestorInformation
                formRef={formRef}
                subject={investorSubject!}
                editInvestor={editInvestor}
                onCloseDrawer={onCloseDrawer}
              />
            </Tabs.TabContent>
            <Tabs.TabContent id="MEMBERS">
              <EditInvestorUsers
                investorRailsId={investorRailsId}
                handleAddUsers={addInvestorGroupUsers}
                handleDeleteUser={deleteInvestorGroupUser}
                handleResendInvite={resendInvite}
              />
            </Tabs.TabContent>
          </EditInvestorTabs>
        ) : (
          <InvestorGeneralInformation>
            <EditInvestorInformation
              formRef={formRef}
              subject={investorSubject}
              editInvestor={editInvestor}
              onCloseDrawer={onCloseDrawer}
            />
          </InvestorGeneralInformation>
        )}
      </Drawer.Content>
    </Drawer>
  )
}

export default EditInvestor
