import Card from 'components/Card'
import Tabs from 'components/Tabs'
import updatesFeedStyles from 'components/UpdatesFeed/UpdatesFeed.module.scss'
import UpdatesFeed, { UpdateEntityType } from 'components/UpdatesFeedV2'
import FundsTable from 'containers/InvestorManagement/ShowInvestor/FundsTable'
import InvestmentVehiclesTable from 'containers/InvestorManagement/ShowInvestor/InvestmentVehiclesTable'
import { useIntl } from 'react-intl'
import { useSubjectContext } from 'utils/context/subjects/SubjectContext'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'
import { FundPortfolioInvestor } from 'utils/types/funds'
import { InvestorInvestmentVehicle } from 'utils/types/investors'
import { IndexUpdate, TransactionItem } from 'utils/types/update'
import useProfileUpdates from '../../useProfileUpdates'
import { MainContentCardWrapper } from './CompanyMainContent.styles'
import { FilterOptionsWrapper } from './CompanyUpdates/CompanyUpdates.style'
import UpdatesFilterOptions from './CompanyUpdates/UpdatesFilterOptions'
import { useGetFilterOptions } from './useGetFilterOptions'

interface Props {
  isLoadingInvestor: boolean
  isLoadingFunds: boolean
  fundsPortfolioInvestor: FundPortfolioInvestor[]
  vehicles: InvestorInvestmentVehicle[]
  onShowFundTransaction: (transaction: TransactionItem) => void
  onShowUpdate: (update: IndexUpdate) => void
  onShowInvestmentVehicleTransaction: (transaction: TransactionItem) => void
  onEditUpdate?: (update: IndexUpdate) => void
}

const InvestorMainContent = ({
  isLoadingInvestor,
  isLoadingFunds,
  vehicles,
  fundsPortfolioInvestor,
  onShowFundTransaction,
  onShowUpdate,
  onShowInvestmentVehicleTransaction,
  onEditUpdate,
}: Props) => {
  const intl = useIntl()
  const { matches: isMobile } = useMediaQuery(Media.MAX_MD)
  const { railsId } = useSubjectContext()

  const {
    filters,
    filterDataSet,
    onChangeTypeFilters,
    onChangeTagFilters,
    hasMixedUpdates,
  } = useProfileUpdates({
    entityType: UpdateEntityType.INVESTOR,
    entityId: railsId,
  })

  const filterOptionsToShow = useGetFilterOptions()

  return (
    <MainContentCardWrapper>
      <Tabs
        tabOffset={24}
        items={[
          {
            id: 'UPDATES',
            title: intl.formatMessage({
              id: 'portfolioDetail.updates',
            }),
          },
          {
            id: 'INVESTMENTS',
            title: intl.formatMessage({
              id: 'portfolioDetail.investments',
            }),
          },
          {
            id: 'FUNDS',
            title: intl.formatMessage({
              id: 'portfolioDetail.fundsAndDeals',
            }),
          },
        ].filter(Boolean)}
        withBorder
      >
        <Tabs.TabContent id="UPDATES">
          <MainContentCardWrapper>
            <Card isExpandable padding="1.4rem 2.4rem">
              <Card.Header>
                {intl.formatMessage({ id: 'updates.updateFeed' })}

                <FilterOptionsWrapper>
                  <UpdatesFilterOptions
                    filters={filters}
                    filterDataSet={filterDataSet}
                    handleTypeFilters={onChangeTypeFilters}
                    handleTagFilters={onChangeTagFilters}
                    isMobile={isMobile}
                    hasUpdates={hasMixedUpdates}
                    optionsToShow={filterOptionsToShow}
                  />
                </FilterOptionsWrapper>
              </Card.Header>
              <Card.Body>
                <div className={updatesFeedStyles.updatesFeedContainer}>
                  <UpdatesFeed
                    entityType={UpdateEntityType.INVESTOR}
                    entityId={railsId}
                    onShowUpdate={onShowUpdate}
                    onEditUpdate={onEditUpdate}
                  />
                </div>
              </Card.Body>
            </Card>
          </MainContentCardWrapper>
        </Tabs.TabContent>

        <Tabs.TabContent id="FUNDS">
          <MainContentCardWrapper>
            <FundsTable
              isLoading={isLoadingFunds}
              funds={fundsPortfolioInvestor}
              onShowTransaction={onShowFundTransaction}
            />
          </MainContentCardWrapper>
        </Tabs.TabContent>

        <Tabs.TabContent id="INVESTMENTS">
          <InvestmentVehiclesTable
            vehicles={vehicles}
            isLoading={isLoadingInvestor}
            onShowTransaction={onShowInvestmentVehicleTransaction}
          />
        </Tabs.TabContent>
      </Tabs>
    </MainContentCardWrapper>
  )
}

export default InvestorMainContent
