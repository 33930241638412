import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  padding: 0 1.6rem 0.9rem 1.6rem;
  margin: 0 0.6rem 0.6rem 0.6rem;
`

export const Title = styled.h1`
  text-transform: uppercase;
  color: ${color('lightGray')};
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin: 1rem 0;
`

export const GroupLink = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${color('white')};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const LogoContainer = styled.div``

export const Name = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
  max-width: 20rem;
`

export const Line = styled.div`
  height: 0.1rem;
  border-bottom: 1px solid #313748;
`
