/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode, useCallback, useId, useState } from 'react'
import ReactDOM from 'react-dom'
import { IconProp } from '@fortawesome/fontawesome-svg-core/index'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import type { PlacesType, IPosition } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './Tooltip.module.scss'

interface TooltipProps {
  id?: string
  className?: string
  class?: string
  text: ReactNode
  place?: PlacesType
  icon?: IconProp
  children?: ReactNode
  /** @deprecated Prefer `offset` field */
  topOffset?: number
  /** @deprecated Prefer `offset` field */
  leftOffset?: number
  offset?: number
  float?: boolean
  iconClassName?: string
  backgroundColor?: string
  position?: IPosition
  widthAuto?: boolean
  arrowColor?: string
  maxWidth?: string
  tooltipStyle?: React.CSSProperties
  delayHide?: number
  color?: string
  border?: string
  boxShadow?: string
  clickable?: boolean
  margin?: string
  wordBreakAll?: boolean
  forceOverridePosition?: boolean
  parentTooltipStyles?: React.CSSProperties
  [x: string]: any
}

const Tooltip: React.FC<TooltipProps> = ({
  id,
  className = '',
  text,
  place = 'left',
  icon = ['far', 'question-circle'],
  iconClassName = '',
  children = null,
  topOffset = 0,
  leftOffset = 0,
  float,
  widthAuto = false,
  backgroundColor = '#194590',
  color = 'white',
  boxShadow = 'none',
  margin = '0',
  border = 'none',
  tooltipStyle,
  position,
  inline,
  maxWidth,
  padding,
  forceOverridePosition = false,
  parentTooltipStyles = {},
  ...props
}) => {
  const domElement = document.getElementById('tooltip-container')

  const useOverridePosition =
    topOffset !== 0 || leftOffset !== 0 || forceOverridePosition

  const defaultTooltipId = useId()
  const tooltipId = id?.replace?.(/'/g, '') || defaultTooltipId

  const [renderTooltip, setRenderTooltip] = useState(false)

  const overridePosition = () => {
    const div = document.getElementById(`${tooltipId}`)
    const rect = div?.getBoundingClientRect()
    return {
      x: (rect?.x ?? 0) + leftOffset,
      y: (rect?.y ?? 0) - topOffset,
    }
  }
  const onMountTooltipItem = useCallback(() => setRenderTooltip(true), [])

  return (
    <span className={className}>
      <a
        ref={onMountTooltipItem}
        id={tooltipId}
        data-testid="tooltip-testid"
        className={classNames({
          [styles.ellipsis]: props.ellipsis,
          [styles.inline]: inline,
        })}
      >
        {children ||
          (icon && <FontAwesomeIcon className={styles.icon} icon={icon} />)}
      </a>
      {domElement && !!text && renderTooltip
        ? ReactDOM.createPortal(
            <ReactTooltip
              className={classNames(styles.tooltip, {
                [styles.widthAuto]: widthAuto,
                [styles.wordBreakAll]: props.wordBreakAll,
                [props.class!]: !!props.class,
              })}
              classNameArrow={classNames(styles.arrow)}
              id={tooltipId}
              place={place}
              anchorId={tooltipId}
              variant="dark"
              float={float}
              style={{
                backgroundColor,
                color,
                maxWidth,
                boxShadow,
                margin,
                border,
                ...parentTooltipStyles,
              }}
              position={useOverridePosition ? overridePosition() : position}
              {...props}
            >
              <div className={styles.text} style={tooltipStyle}>
                {text}
              </div>
            </ReactTooltip>,
            domElement
          )
        : null}
    </span>
  )
}

export default Tooltip
