/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment BasicAttribute on Attribute {\n    name\n    value\n    type\n  }\n": types.BasicAttributeFragmentDoc,
    "\n  fragment Attribute on Attribute {\n    ...BasicAttribute\n    refs {\n      ...AttributeRef\n    }\n  }\n": types.AttributeFragmentDoc,
    "\n  fragment FullAttribute on Attribute {\n    ...BasicAttribute\n    refs {\n      ...FullAttributeRef\n    }\n  }\n": types.FullAttributeFragmentDoc,
    "\n  fragment AttributeRef on Subject {\n    id\n    name\n    type\n    logo\n  }\n": types.AttributeRefFragmentDoc,
    "\n  fragment FullAttributeRef on Subject {\n    ...AttributeRef\n    attributes {\n      ...BasicAttribute\n    }\n  }\n": types.FullAttributeRefFragmentDoc,
    "\n  fragment DuplicatedObject on DuplicatedEntity {\n    id\n    name\n    entityType\n  }\n": types.DuplicatedObjectFragmentDoc,
    "\n  fragment CustomAttributeResult on ValidateCustomAttributeResult {\n    attributeName\n    isValid\n    error\n    errorType\n  }\n": types.CustomAttributeResultFragmentDoc,
    "\n  fragment MainAttributeResult on ValidateMainAttributeResult {\n    isValid\n    error\n    errorType\n    duplicatedObject {\n      ...DuplicatedObject\n    }\n    groupOwned\n  }\n": types.MainAttributeResultFragmentDoc,
    "\n  fragment Permission on Permission {\n    entityId\n    read\n    write\n  }\n": types.PermissionFragmentDoc,
    "\n  fragment SubjectAttributes on Subject {\n    attributes {\n      ...Attribute\n    }\n  }\n": types.SubjectAttributesFragmentDoc,
    "\n  fragment SubjectPermissions on Subject {\n    permissions {\n      ...Permission\n    }\n  }\n": types.SubjectPermissionsFragmentDoc,
    "\n  fragment ParentSubject on Subject {\n    id\n    name\n    logo\n    railsId\n    type\n    ...SubjectAttributes\n  }\n": types.ParentSubjectFragmentDoc,
    "\n  fragment BasicSubject on Subject {\n    id\n    name\n    logo\n    type\n    railsId\n    groupOwner\n    parentSubject {\n      ...ParentSubject\n    }\n    ...SubjectAttributes\n  }\n": types.BasicSubjectFragmentDoc,
    "\n  fragment FullSubjectAttributes on Subject {\n    attributes {\n      ...FullAttribute\n    }\n  }\n": types.FullSubjectAttributesFragmentDoc,
    "\n  fragment FullSubject on Subject {\n    id\n    name\n    logo\n    type\n    railsId\n    tags {\n      ...Tag\n    }\n    parentSubject {\n      ...ParentSubject\n    }\n    ...FullSubjectAttributes\n    ...SubjectPermissions\n  }\n": types.FullSubjectFragmentDoc,
    "\n  fragment Tag on Tag {\n    tagId\n    tagName\n  }\n": types.TagFragmentDoc,
    "\n  mutation UpdateSubject($id: String!, $data: GQLUpdateSubjectDto!) {\n    updateSubject(id: $id, data: $data) {\n      id\n    }\n  }\n": types.UpdateSubjectDocument,
    "\n  mutation CreateSubject($data: CreateSubjectDto!) {\n    createSubject(data: $data) {\n      id\n    }\n  }\n": types.CreateSubjectDocument,
    "\n  mutation CreateSubjectFromType($type: String!, $data: CreateSubjectDto!) {\n    createSubjectFromType(type: $type, data: $data) {\n      id\n      railsId\n    }\n  }\n": types.CreateSubjectFromTypeDocument,
    "\n  mutation BulkCreateSubjectsFromType(\n    $type: String!\n    $data: [CreateSubjectDto!]!\n  ) {\n    bulkCreateSubjectsFromType(type: $type, data: $data) {\n      id\n    }\n  }\n": types.BulkCreateSubjectsFromTypeDocument,
    "\n  mutation DeleteSubject($id: String!) {\n    deleteSubject(id: $id) {\n      id\n    }\n  }\n": types.DeleteSubjectDocument,
    "\n  mutation BulkImportSubjects($data: [BulkCreateSubjectDto!]!) {\n    bulkImportSubjects(data: $data) {\n      id\n      railsId\n    }\n  }\n": types.BulkImportSubjectsDocument,
    "\n  mutation ValidateBulkImportSubjects($data: [BulkCreateSubjectDto!]!) {\n    validateBulkImportSubjects(data: $data) {\n      ... on ValidateResult {\n        name {\n          ...MainAttributeResult\n        }\n        typeIdentifier {\n          ...MainAttributeResult\n        }\n        parentSubject {\n          ...MainAttributeResult\n        }\n        attributes {\n          ...CustomAttributeResult\n        }\n      }\n\n      ... on ValidateManyResult {\n        names {\n          name {\n            ...MainAttributeResult\n          }\n          typeIdentifier {\n            ...MainAttributeResult\n          }\n          attributes {\n            ...CustomAttributeResult\n          }\n        }\n\n        parentSubject {\n          ...MainAttributeResult\n        }\n      }\n    }\n  }\n": types.ValidateBulkImportSubjectsDocument,
    "\n  mutation BulkExportSubjects($query: String) {\n    bulkExportSubjects(query: $query) {\n      url\n    }\n  }\n": types.BulkExportSubjectsDocument,
    "\n  mutation AddSubjectTags($id: String!, $tags: [TagDto!]!) {\n    addSubjectTags(id: $id, tags: $tags) {\n      id\n      tags {\n        tagId\n        tagName\n      }\n    }\n  }\n": types.AddSubjectTagsDocument,
    "\n  mutation RemoveSubjectTags($id: String!, $tags: [String!]!) {\n    removeSubjectTags(id: $id, tags: $tags) {\n      id\n      tags {\n        tagId\n        tagName\n      }\n    }\n  }\n": types.RemoveSubjectTagsDocument,
    "\n  query GetMinimalSubjects(\n    $limit: Int\n    $query: String\n    $skip: Int\n    $sortBy: String\n    $sortDirection: SortDirection\n  ) {\n    subjects(\n      limit: $limit\n      query: $query\n      skip: $skip\n      sortBy: $sortBy\n      sortDirection: $sortDirection\n    ) {\n      id\n      railsId\n      name\n      type\n      groupOwner\n      logo\n    }\n  }\n": types.GetMinimalSubjectsDocument,
    "\n  query Subjects(\n    $limit: Int\n    $query: String\n    $skip: Int\n    $sortBy: String\n    $sortDirection: SortDirection\n  ) {\n    subjects(\n      limit: $limit\n      query: $query\n      skip: $skip\n      sortBy: $sortBy\n      sortDirection: $sortDirection\n    ) {\n      ...BasicSubject\n    }\n  }\n": types.SubjectsDocument,
    "\n  query FullSubjects(\n    $limit: Int\n    $query: String\n    $skip: Int\n    $sortBy: String\n    $sortDirection: SortDirection\n  ) {\n    subjects(\n      limit: $limit\n      query: $query\n      skip: $skip\n      sortBy: $sortBy\n      sortDirection: $sortDirection\n    ) {\n      ...FullSubject\n    }\n  }\n": types.FullSubjectsDocument,
    "\n  query CountSubjects($query: String) {\n    countSubjects(query: $query) {\n      count\n    }\n  }\n": types.CountSubjectsDocument,
    "\n  query Subject($id: String!) {\n    subject(id: $id) {\n      ...FullSubject\n    }\n  }\n": types.SubjectDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BasicAttribute on Attribute {\n    name\n    value\n    type\n  }\n"): (typeof documents)["\n  fragment BasicAttribute on Attribute {\n    name\n    value\n    type\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Attribute on Attribute {\n    ...BasicAttribute\n    refs {\n      ...AttributeRef\n    }\n  }\n"): (typeof documents)["\n  fragment Attribute on Attribute {\n    ...BasicAttribute\n    refs {\n      ...AttributeRef\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment FullAttribute on Attribute {\n    ...BasicAttribute\n    refs {\n      ...FullAttributeRef\n    }\n  }\n"): (typeof documents)["\n  fragment FullAttribute on Attribute {\n    ...BasicAttribute\n    refs {\n      ...FullAttributeRef\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AttributeRef on Subject {\n    id\n    name\n    type\n    logo\n  }\n"): (typeof documents)["\n  fragment AttributeRef on Subject {\n    id\n    name\n    type\n    logo\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment FullAttributeRef on Subject {\n    ...AttributeRef\n    attributes {\n      ...BasicAttribute\n    }\n  }\n"): (typeof documents)["\n  fragment FullAttributeRef on Subject {\n    ...AttributeRef\n    attributes {\n      ...BasicAttribute\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment DuplicatedObject on DuplicatedEntity {\n    id\n    name\n    entityType\n  }\n"): (typeof documents)["\n  fragment DuplicatedObject on DuplicatedEntity {\n    id\n    name\n    entityType\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CustomAttributeResult on ValidateCustomAttributeResult {\n    attributeName\n    isValid\n    error\n    errorType\n  }\n"): (typeof documents)["\n  fragment CustomAttributeResult on ValidateCustomAttributeResult {\n    attributeName\n    isValid\n    error\n    errorType\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MainAttributeResult on ValidateMainAttributeResult {\n    isValid\n    error\n    errorType\n    duplicatedObject {\n      ...DuplicatedObject\n    }\n    groupOwned\n  }\n"): (typeof documents)["\n  fragment MainAttributeResult on ValidateMainAttributeResult {\n    isValid\n    error\n    errorType\n    duplicatedObject {\n      ...DuplicatedObject\n    }\n    groupOwned\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Permission on Permission {\n    entityId\n    read\n    write\n  }\n"): (typeof documents)["\n  fragment Permission on Permission {\n    entityId\n    read\n    write\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SubjectAttributes on Subject {\n    attributes {\n      ...Attribute\n    }\n  }\n"): (typeof documents)["\n  fragment SubjectAttributes on Subject {\n    attributes {\n      ...Attribute\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SubjectPermissions on Subject {\n    permissions {\n      ...Permission\n    }\n  }\n"): (typeof documents)["\n  fragment SubjectPermissions on Subject {\n    permissions {\n      ...Permission\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ParentSubject on Subject {\n    id\n    name\n    logo\n    railsId\n    type\n    ...SubjectAttributes\n  }\n"): (typeof documents)["\n  fragment ParentSubject on Subject {\n    id\n    name\n    logo\n    railsId\n    type\n    ...SubjectAttributes\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BasicSubject on Subject {\n    id\n    name\n    logo\n    type\n    railsId\n    groupOwner\n    parentSubject {\n      ...ParentSubject\n    }\n    ...SubjectAttributes\n  }\n"): (typeof documents)["\n  fragment BasicSubject on Subject {\n    id\n    name\n    logo\n    type\n    railsId\n    groupOwner\n    parentSubject {\n      ...ParentSubject\n    }\n    ...SubjectAttributes\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment FullSubjectAttributes on Subject {\n    attributes {\n      ...FullAttribute\n    }\n  }\n"): (typeof documents)["\n  fragment FullSubjectAttributes on Subject {\n    attributes {\n      ...FullAttribute\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment FullSubject on Subject {\n    id\n    name\n    logo\n    type\n    railsId\n    tags {\n      ...Tag\n    }\n    parentSubject {\n      ...ParentSubject\n    }\n    ...FullSubjectAttributes\n    ...SubjectPermissions\n  }\n"): (typeof documents)["\n  fragment FullSubject on Subject {\n    id\n    name\n    logo\n    type\n    railsId\n    tags {\n      ...Tag\n    }\n    parentSubject {\n      ...ParentSubject\n    }\n    ...FullSubjectAttributes\n    ...SubjectPermissions\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Tag on Tag {\n    tagId\n    tagName\n  }\n"): (typeof documents)["\n  fragment Tag on Tag {\n    tagId\n    tagName\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateSubject($id: String!, $data: GQLUpdateSubjectDto!) {\n    updateSubject(id: $id, data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSubject($id: String!, $data: GQLUpdateSubjectDto!) {\n    updateSubject(id: $id, data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateSubject($data: CreateSubjectDto!) {\n    createSubject(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSubject($data: CreateSubjectDto!) {\n    createSubject(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateSubjectFromType($type: String!, $data: CreateSubjectDto!) {\n    createSubjectFromType(type: $type, data: $data) {\n      id\n      railsId\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSubjectFromType($type: String!, $data: CreateSubjectDto!) {\n    createSubjectFromType(type: $type, data: $data) {\n      id\n      railsId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation BulkCreateSubjectsFromType(\n    $type: String!\n    $data: [CreateSubjectDto!]!\n  ) {\n    bulkCreateSubjectsFromType(type: $type, data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation BulkCreateSubjectsFromType(\n    $type: String!\n    $data: [CreateSubjectDto!]!\n  ) {\n    bulkCreateSubjectsFromType(type: $type, data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteSubject($id: String!) {\n    deleteSubject(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteSubject($id: String!) {\n    deleteSubject(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation BulkImportSubjects($data: [BulkCreateSubjectDto!]!) {\n    bulkImportSubjects(data: $data) {\n      id\n      railsId\n    }\n  }\n"): (typeof documents)["\n  mutation BulkImportSubjects($data: [BulkCreateSubjectDto!]!) {\n    bulkImportSubjects(data: $data) {\n      id\n      railsId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ValidateBulkImportSubjects($data: [BulkCreateSubjectDto!]!) {\n    validateBulkImportSubjects(data: $data) {\n      ... on ValidateResult {\n        name {\n          ...MainAttributeResult\n        }\n        typeIdentifier {\n          ...MainAttributeResult\n        }\n        parentSubject {\n          ...MainAttributeResult\n        }\n        attributes {\n          ...CustomAttributeResult\n        }\n      }\n\n      ... on ValidateManyResult {\n        names {\n          name {\n            ...MainAttributeResult\n          }\n          typeIdentifier {\n            ...MainAttributeResult\n          }\n          attributes {\n            ...CustomAttributeResult\n          }\n        }\n\n        parentSubject {\n          ...MainAttributeResult\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ValidateBulkImportSubjects($data: [BulkCreateSubjectDto!]!) {\n    validateBulkImportSubjects(data: $data) {\n      ... on ValidateResult {\n        name {\n          ...MainAttributeResult\n        }\n        typeIdentifier {\n          ...MainAttributeResult\n        }\n        parentSubject {\n          ...MainAttributeResult\n        }\n        attributes {\n          ...CustomAttributeResult\n        }\n      }\n\n      ... on ValidateManyResult {\n        names {\n          name {\n            ...MainAttributeResult\n          }\n          typeIdentifier {\n            ...MainAttributeResult\n          }\n          attributes {\n            ...CustomAttributeResult\n          }\n        }\n\n        parentSubject {\n          ...MainAttributeResult\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation BulkExportSubjects($query: String) {\n    bulkExportSubjects(query: $query) {\n      url\n    }\n  }\n"): (typeof documents)["\n  mutation BulkExportSubjects($query: String) {\n    bulkExportSubjects(query: $query) {\n      url\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddSubjectTags($id: String!, $tags: [TagDto!]!) {\n    addSubjectTags(id: $id, tags: $tags) {\n      id\n      tags {\n        tagId\n        tagName\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddSubjectTags($id: String!, $tags: [TagDto!]!) {\n    addSubjectTags(id: $id, tags: $tags) {\n      id\n      tags {\n        tagId\n        tagName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RemoveSubjectTags($id: String!, $tags: [String!]!) {\n    removeSubjectTags(id: $id, tags: $tags) {\n      id\n      tags {\n        tagId\n        tagName\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveSubjectTags($id: String!, $tags: [String!]!) {\n    removeSubjectTags(id: $id, tags: $tags) {\n      id\n      tags {\n        tagId\n        tagName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMinimalSubjects(\n    $limit: Int\n    $query: String\n    $skip: Int\n    $sortBy: String\n    $sortDirection: SortDirection\n  ) {\n    subjects(\n      limit: $limit\n      query: $query\n      skip: $skip\n      sortBy: $sortBy\n      sortDirection: $sortDirection\n    ) {\n      id\n      railsId\n      name\n      type\n      groupOwner\n      logo\n    }\n  }\n"): (typeof documents)["\n  query GetMinimalSubjects(\n    $limit: Int\n    $query: String\n    $skip: Int\n    $sortBy: String\n    $sortDirection: SortDirection\n  ) {\n    subjects(\n      limit: $limit\n      query: $query\n      skip: $skip\n      sortBy: $sortBy\n      sortDirection: $sortDirection\n    ) {\n      id\n      railsId\n      name\n      type\n      groupOwner\n      logo\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Subjects(\n    $limit: Int\n    $query: String\n    $skip: Int\n    $sortBy: String\n    $sortDirection: SortDirection\n  ) {\n    subjects(\n      limit: $limit\n      query: $query\n      skip: $skip\n      sortBy: $sortBy\n      sortDirection: $sortDirection\n    ) {\n      ...BasicSubject\n    }\n  }\n"): (typeof documents)["\n  query Subjects(\n    $limit: Int\n    $query: String\n    $skip: Int\n    $sortBy: String\n    $sortDirection: SortDirection\n  ) {\n    subjects(\n      limit: $limit\n      query: $query\n      skip: $skip\n      sortBy: $sortBy\n      sortDirection: $sortDirection\n    ) {\n      ...BasicSubject\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query FullSubjects(\n    $limit: Int\n    $query: String\n    $skip: Int\n    $sortBy: String\n    $sortDirection: SortDirection\n  ) {\n    subjects(\n      limit: $limit\n      query: $query\n      skip: $skip\n      sortBy: $sortBy\n      sortDirection: $sortDirection\n    ) {\n      ...FullSubject\n    }\n  }\n"): (typeof documents)["\n  query FullSubjects(\n    $limit: Int\n    $query: String\n    $skip: Int\n    $sortBy: String\n    $sortDirection: SortDirection\n  ) {\n    subjects(\n      limit: $limit\n      query: $query\n      skip: $skip\n      sortBy: $sortBy\n      sortDirection: $sortDirection\n    ) {\n      ...FullSubject\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CountSubjects($query: String) {\n    countSubjects(query: $query) {\n      count\n    }\n  }\n"): (typeof documents)["\n  query CountSubjects($query: String) {\n    countSubjects(query: $query) {\n      count\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Subject($id: String!) {\n    subject(id: $id) {\n      ...FullSubject\n    }\n  }\n"): (typeof documents)["\n  query Subject($id: String!) {\n    subject(id: $id) {\n      ...FullSubject\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;