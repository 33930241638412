import { MutableRefObject, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import ReactDOM from 'react-dom'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'

import { isValidTag } from 'utils/functions/tags'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'
import { Nullable } from 'utils/types/common'
import { TagFragment } from 'types/graphql-schemas/graphql'
import TagsInput from './TagsInput.v2'
import {
  TooltipTagContainer,
  Tag,
  TagsList,
  TagsTooltipContent,
  TooltipTagWrapper,
} from './index.styles'
import useTags from './useTags.v2'
import { TagsWrapper } from './TagsInput.styles'

interface Props {
  holdingId: string
  tags?: TagFragment[]
}

const getPosition = (
  buttonRef: MutableRefObject<Nullable<HTMLDivElement>>,
  tooltipTagRef: MutableRefObject<Nullable<HTMLDivElement>>
) => {
  let position = { left: 0, top: 0 }

  if (buttonRef.current) {
    const boundingRect = buttonRef.current.getBoundingClientRect()
    position = { left: boundingRect.left, top: boundingRect.top }

    if (tooltipTagRef.current) {
      const emptySpace = 10
      const windowWidth = window.innerWidth
      const tooltipWidth = tooltipTagRef.current?.offsetWidth

      position.left =
        windowWidth < position.left + tooltipWidth
          ? windowWidth - tooltipWidth - emptySpace
          : position.left
    }
  }

  return position
}

const Tags = ({ tags, holdingId }: Props) => {
  const intl = useIntl()
  const dropdownContainer = document.getElementById('dropdown-container')
  const [showTooltip, setShowTooltip] = useState(false)
  const setDebouncedShowExtraTags = debounce((value) => {
    setShowTooltip(value)
  }, 600)
  const openTooltip = throttle(() => {
    setShowTooltip(true)
  }, 400)
  const { matches: isMobile } = useMediaQuery(Media.MAX_MD)
  const { matches: isTablet } = useMediaQuery(Media.MAX_LG)
  const {
    tagsList,
    numberOfTagsToShow,
    totalTags,
    containerRef,

    onSelectTag,
    onAddNewTag,
    onRemoveTag,
  } = useTags({
    tags,
    holdingId,
  })

  const buttonRef = useRef<Nullable<HTMLDivElement>>(null)
  const tooltipTagRef = useRef<Nullable<HTMLDivElement>>(null)
  const position = getPosition(buttonRef, tooltipTagRef)

  const getTagList = (tagList: TagFragment[]) => {
    const tagsToShow =
      tagList.length > numberOfTagsToShow
        ? tagList?.slice(0, numberOfTagsToShow)
        : tagList
    return tagsToShow?.map((tag) => (
      <Tag
        key={tag.tagId}
        titleColor="white"
        text={tag.tagName}
        handleDelete={() => {
          onRemoveTag(tag)
        }}
      />
    ))
  }

  const getTooltipContent = () => {
    const tagsToShow = tagsList?.slice(numberOfTagsToShow)
    return (
      <TooltipTagContainer>
        <TooltipTagWrapper
          onMouseOver={() => {
            setDebouncedShowExtraTags(true)
          }}
          onMouseLeave={() => {
            setDebouncedShowExtraTags(false)
          }}
        >
          {tagsToShow?.map((tag) => (
            <Tag
              key={tag.tagId}
              text={tag.tagName}
              isFromTooltip
              handleDelete={() => {
                onRemoveTag(tag)
              }}
            />
          ))}
        </TooltipTagWrapper>
      </TooltipTagContainer>
    )
  }

  return (
    <TagsWrapper>
      <TagsInput
        tagsList={tagsList}
        onSelectTag={onSelectTag}
        onAddNewTag={onAddNewTag}
        validationWhenAddingTag={(tag) => isValidTag(tag, intl)}
      />
      <TagsList ref={containerRef}>
        {getTagList(tagsList)}
        {totalTags > numberOfTagsToShow && (
          <div ref={buttonRef}>
            <div
              onMouseOver={() => {
                openTooltip()
              }}
              onFocus={() => {
                openTooltip()
              }}
              onMouseLeave={() => {
                setDebouncedShowExtraTags(false)
              }}
            >
              <Tag
                titleColor="white"
                text={`+${totalTags - numberOfTagsToShow}`}
                canDelete={false}
                transparent
              />
            </div>
            {dropdownContainer &&
              ReactDOM.createPortal(
                <TagsTooltipContent
                  ref={tooltipTagRef}
                  position={position}
                  closed={!showTooltip}
                  isMobile={isMobile || isTablet}
                >
                  {getTooltipContent()}
                </TagsTooltipContent>,
                dropdownContainer
              )}
          </div>
        )}
      </TagsList>
    </TagsWrapper>
  )
}

export default Tags
