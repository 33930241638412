import { CHART_MODE } from 'components/Charts/MultiViewChart/types'
import { createContext, useContext } from 'react'
import type { DataVisualizationColor } from 'utils/theme'
import { Nullable } from 'utils/types/common'
import { MetricsCard } from 'utils/types/metrics'
import { BaseMetric, IndexMetric } from 'utils/types/metricsV2'
import { SelectedMetrics } from './useMetricsColor'

export const MAX_SANDBOX_METRICS = 8

export type MetricSandboxContextType = {
  selectedMetrics: SelectedMetrics[]
  hasSelectedMetrics: boolean
  metricsCards: MetricsCard[]
  defaultChartMode: Nullable<CHART_MODE>
  selectedMetricsCount?: number
  hasMetrics?: boolean
  isLoading?: boolean
  clearSandbox: () => void
  getMetricColor: (metric: IndexMetric) => DataVisualizationColor
  isAddMetricToSandboxDisabled: () => boolean
  isMetricInSandbox: (metric: BaseMetric) => boolean
  removeMetrics: (metrics: IndexMetric[]) => void
  toggleMetricInSandbox: (metric: BaseMetric) => void
  changeMetricVisibility: (metricId: string) => void
  isMetricVisible: (metricId: string) => boolean
}

export const MetricSandboxContext = createContext<MetricSandboxContextType>({
  selectedMetrics: [],
  hasSelectedMetrics: false,
  hasMetrics: false,
  isLoading: false,
  metricsCards: [],
  defaultChartMode: CHART_MODE.LINE,
  selectedMetricsCount: 0,
  clearSandbox: () => {},
  getMetricColor: () => 'blue',
  isAddMetricToSandboxDisabled: () => false,
  isMetricInSandbox: () => false,
  removeMetrics: () => {},
  toggleMetricInSandbox: () => {},
  changeMetricVisibility: () => {},
  isMetricVisible: () => true,
})

export const useMetricsSandbox = () => useContext(MetricSandboxContext)
