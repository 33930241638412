import { PropsWithChildren } from 'react'
import { useParams } from 'react-router-dom'
import useCompany from 'utils/hooks/holdings/useCompany'

import { useSubjectQuery } from 'utils/hooks/queries/subjects/useSubjectQuery'

const SubjectIdRoutes = ({ children }: PropsWithChildren) => {
  const { companyId, fundId } = useParams<{
    companyId?: string
    fundId?: string
  }>()

  const { setCurrentCompany } = useCompany()

  useSubjectQuery((companyId || fundId)!, {
    onCompleted: (data) => {
      setCurrentCompany(data)
    },
  })

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default SubjectIdRoutes
