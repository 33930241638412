import { lazy, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { InvestmentVehicle } from 'utils/types/investors'
import CreateInvestmentVehicle from 'containers/InvestorManagement/CreateInvestmentVehicle'
import CreateInvestor from 'containers/InvestorManagement/CreateInvestor'
import EditInvestor from 'containers/InvestorManagement/EditInvestor'
import EditInvestmentVehicle from 'containers/InvestorManagement/EditInvestmentVehicle'
import InvestorProfile from 'containers/Profiles/InvestorProfile'
import InvestmentVehicleProfile from 'containers/Profiles/InvestmentVehicleProfile'
import UpdatesRoutes from './UpdatesRoutes'
import {
  INVESTMENT_VEHICLE_PROFILE_EDIT,
  INVESTOR_PROFILE_EDIT,
  INVESTOR_MANAGEMENT_INDEX_ROUTES,
  INVESTOR_PROFILE_ROUTES,
  INVESTORS_CREATE,
  INVESTORS_EDIT,
  VEHICLE_PROFILE_ROUTES,
  VEHICLES_CREATE,
  VEHICLES_EDIT,
  INVESTOR_ALL_UPDATES,
  INVESTOR_PROFILE_VEHICLE_PROFILE_ALL_UPDATES,
  VEHICLES_ALL_UPDATES,
  INVESTOR_PROFILE_VEHICLE_PROFILE,
  INVESTORS,
  VEHICLES,
  INVESTOR_PROFILE,
  INVESTMENT_VEHICLE_PROFILE,
} from './constant/investorManagementRoutes'

const InvestorManagement = lazy(() => import('containers/InvestorManagement'))

const InvestorManagementRoutes = () => {
  const history = useHistory<{
    investorId?: string
    investmentVehicle?: InvestmentVehicle
    investmentVehicleId?: string
  }>()
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const vehicleId =
    history.location.state?.investmentVehicle?.id ||
    history.location.state?.investmentVehicleId
  const investorId = history.location.state?.investorId

  const onCloseDrawer = () => {
    setIsDrawerOpen(false)
    setTimeout(() => {
      if (history.location.pathname.includes('/vehicles/')) {
        if (vehicleId) {
          const path = INVESTMENT_VEHICLE_PROFILE.replace(
            ':investmentVehicleId',
            vehicleId
          )
          history.replace(path)
        } else {
          history.replace(VEHICLES)
        }
      } else if (investorId) {
        const path = INVESTOR_PROFILE.replace(':investorId', investorId)
        history.replace(path)
      } else {
        history.replace(INVESTORS)
      }
      setIsDrawerOpen(true)
    }, 300)
  }

  return (
    <>
      <Switch>
        <Route exact path={INVESTOR_MANAGEMENT_INDEX_ROUTES}>
          <InvestorManagement />
        </Route>
        <Route exact path={INVESTOR_PROFILE_ROUTES}>
          <InvestorProfile />
        </Route>
        <Route exact path={INVESTOR_PROFILE_VEHICLE_PROFILE}>
          <InvestmentVehicleProfile />
        </Route>
        <Route exact path={VEHICLE_PROFILE_ROUTES}>
          <InvestmentVehicleProfile />
        </Route>
      </Switch>

      <Switch>
        <Route
          path={[
            INVESTOR_ALL_UPDATES,
            INVESTOR_PROFILE_VEHICLE_PROFILE_ALL_UPDATES,
            VEHICLES_ALL_UPDATES,
          ]}
        >
          <UpdatesRoutes />
        </Route>
      </Switch>

      <Switch>
        <Route exact path={INVESTORS_CREATE}>
          <CreateInvestor
            isDrawerOpen={isDrawerOpen}
            onCloseDrawer={onCloseDrawer}
          />
        </Route>
        <Route exact path={VEHICLES_CREATE}>
          <CreateInvestmentVehicle
            isDrawerOpen={isDrawerOpen}
            onCloseDrawer={onCloseDrawer}
          />
        </Route>
        <Route exact path={[INVESTORS_EDIT, INVESTOR_PROFILE_EDIT]}>
          <EditInvestor
            isDrawerOpen={isDrawerOpen}
            onCloseDrawer={onCloseDrawer}
          />
        </Route>
        <Route exact path={[VEHICLES_EDIT, INVESTMENT_VEHICLE_PROFILE_EDIT]}>
          <EditInvestmentVehicle
            isDrawerOpen={isDrawerOpen}
            onCloseDrawer={onCloseDrawer}
          />
        </Route>
      </Switch>
    </>
  )
}

export default InvestorManagementRoutes
