import { useApolloClient } from '@apollo/client'
import { useQuery } from '@tanstack/react-query'
import { SubjectQuery } from 'types/graphql-schemas/graphql'
import { GET_SUBJECT } from 'utils/gql/queries/subjects'
import { subjectsKeys } from 'utils/queries/subjects'

// TODO: use RQ QueryOptions type
export type QueryOptions = {
  skip?: boolean
  shouldSetCurrentCompany?: boolean
  onCompleted?: (data: SubjectQuery) => void
  onError?: (error: Error) => void
  staleTime?: number
}

export const useSubjectQuery = (
  subjectId: string,
  { skip = false, onCompleted, onError, staleTime }: QueryOptions = {}
) => {
  const apolloClient = useApolloClient()

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: subjectsKeys.byId(subjectId),
    queryFn: async () => {
      const response = await apolloClient.query({
        query: GET_SUBJECT,
        variables: {
          id: subjectId,
        },
      })

      // TODO: Return always data.subject?
      return response.data
    },
    staleTime,
    enabled: !!subjectId && !skip,
    onSuccess: onCompleted,
    onError,
  })

  return {
    data,
    isLoading,
    error,
    refetch,
  }
}
