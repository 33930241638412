import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'
import Input from 'ui/Input'
import { color } from 'utils/functions/colors'

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
`

export const VerifiedCheckIcon = styled(FontAwesomeIcon)`
  color: ${color('green')};
  min-width: 1.8rem;
  height: 1.8rem;
`

export const VerifiedInput = styled(Input)<{ isVerified: boolean }>`
  ${(props) =>
    props.isVerified &&
    css`
      border: none;
      cursor: not-allowed;
      pointer-events: none;
      background: transparent;
      &:hover,
      &:focus {
        border: none;
      }
    `}
`
