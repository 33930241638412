import { useState, useEffect } from 'react'

import EmailService from 'api/EmailService'
import Toast from 'components/Toast'
import { FileContent } from 'utils/types/files'
import {
  useFileUploadedEventListener,
  useUploadFile,
} from 'utils/hooks/useUploadFile'
import { useRandomId } from 'utils/hooks/useRandomId'
import { EmailUpdate } from 'utils/types/update'
import {
  TEN_MB,
  fileSizesMoreThanXMb,
  filterFilesBySize,
} from 'containers/CreateEmail/helpers'
import { useFormikContext } from 'formik'
import { CreateEmailFormValues } from 'utils/types/emailForm'

const useAttachments = (emailUpdate: EmailUpdate, contents: FileContent[]) => {
  const [attachments, setAttachments] = useState<FileContent[]>([])
  const fileUploadContextId = useRandomId()
  const [isAssociatingAttachments, setIsAssociatingAttachments] =
    useState(false)

  const { setFieldValue } = useFormikContext<CreateEmailFormValues>()

  const { uploadFiles } = useUploadFile()

  const attachFileContentToEmail = (fileContents: FileContent[]) => {
    const newAttachmentsArr = [...attachments, ...fileContents]

    if (fileSizesMoreThanXMb(newAttachmentsArr, TEN_MB)) {
      const filteredFiles = filterFilesBySize(newAttachmentsArr, TEN_MB)
      setAttachments(filteredFiles)
      Toast.displayIntl('createEmail.errors.mbExceeded', 'error')

      setFieldValue('files', filteredFiles)

      return EmailService.addEmailAttachments(
        emailUpdate.id,
        emailUpdate.item.id,
        filteredFiles
      )
    }

    setAttachments(newAttachmentsArr)
    setFieldValue('files', newAttachmentsArr)
    return EmailService.addEmailAttachments(
      emailUpdate.id,
      emailUpdate.item.id,
      fileContents
    )
  }
  useFileUploadedEventListener(
    fileUploadContextId,
    (fileContent: FileContent) => {
      attachFileContentToEmail([fileContent])
    }
  )

  useEffect(() => {
    setAttachments(contents ?? [])
  }, [contents])

  const handleRemoveFile = async (file: FileContent) => {
    try {
      await EmailService.removeEmailAttachments(
        emailUpdate.id,
        emailUpdate.item.id,
        [file]
      )
      const newAttachments = attachments.filter(
        (attachment) => attachment.id !== file.id
      )
      setAttachments(newAttachments)
      setFieldValue('files', newAttachments)
    } catch (err) {
      Toast.displayIntl('createEmail.removeFileError', 'error')
    }
  }

  const handleFileUploads = async ({
    acceptedFiles,
    rejectedFiles,
  }: {
    acceptedFiles: File[]
    rejectedFiles: File[]
  }) => {
    if (rejectedFiles.length) {
      Toast.displayIntl('errors.fileUpload', 'error')
    } else {
      uploadFiles(acceptedFiles, fileUploadContextId)
    }
  }

  const handleSelectExistentFiles = (fileContents: FileContent[]) => {
    setIsAssociatingAttachments(true)
    attachFileContentToEmail(fileContents).finally(() => {
      setIsAssociatingAttachments(false)
    })
  }

  return {
    handleFileUploads,
    handleSelectExistentFiles,
    attachments,
    handleRemoveFile,
    isAssociatingAttachments,
  }
}

export default useAttachments
