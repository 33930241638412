import React from 'react'
import Drawer from 'components/Drawer'
import { Helmet } from 'components/Helmet'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Wizard from 'components/Wizard'
import { InvestorManagementSidebarProps } from 'utils/types/investors'
import useCreateInvestor, { FormSteps } from './useCreateInvestor'
import { WizardHeader } from './CreateInvestor.styles'
import AddInvestorStep from './steps/AddInvestorStep'
import AddOtherInfoStep from './steps/AddOtherInfoStep'
import SendInvites from './steps/SendInvitesStep'

const CreateInvestor: React.FC<InvestorManagementSidebarProps> = ({
  onCloseDrawer,
  isDrawerOpen,
}) => {
  const intl = useIntl()

  const {
    currentStep,
    goToNextStep,
    createInvestor,
    onCancel,
    handleStepClick,
    addInvestorFormRef,
    otherInfoFormRef,
    sendInvitesFormRef,
    initialValues,
    isLoading,
  } = useCreateInvestor(onCloseDrawer)

  return (
    <Drawer
      isOpen={isDrawerOpen}
      onCloseDrawer={onCloseDrawer}
      headerTitle={intl.formatMessage({
        id: 'investorManagement.addInvestor',
      })}
    >
      <Helmet>
        <title>
          {`${intl.formatMessage({
            id: 'metaTitles.createInvestor',
          })}`}
        </title>
      </Helmet>

      <Drawer.Content>
        <WizardHeader>
          <Wizard activeStep={currentStep} onSelectStep={handleStepClick}>
            <Wizard.Step
              id={FormSteps.ADD_INVESTOR}
              name={intl.formatMessage({
                id: 'investorManagement.addInvestor',
              })}
            >
              <FontAwesomeIcon icon={['far', 'user-tie']} />
            </Wizard.Step>

            <Wizard.Step
              id={FormSteps.ADD_OTHER_INFO}
              name={intl.formatMessage({
                id: 'investorManagement.addAdditionalInfo',
              })}
            >
              <FontAwesomeIcon icon={['far', 'money-check-edit']} />
            </Wizard.Step>

            <Wizard.Step
              id={FormSteps.SEND_INVITATION}
              name={intl.formatMessage({
                id: 'investorManagement.sendInvitation',
              })}
            >
              <FontAwesomeIcon icon={['far', 'envelope']} />
            </Wizard.Step>
          </Wizard>
        </WizardHeader>

        <Wizard.Content id={FormSteps.ADD_INVESTOR} activeStep={currentStep!}>
          <AddInvestorStep
            ref={addInvestorFormRef}
            initialValues={initialValues.ADD_INVESTOR}
            onSubmit={goToNextStep}
            onCancel={onCancel}
          />
        </Wizard.Content>

        <Wizard.Content id={FormSteps.ADD_OTHER_INFO} activeStep={currentStep!}>
          <AddOtherInfoStep
            ref={otherInfoFormRef}
            initialValues={initialValues.OTHER_INFO}
            onSubmit={goToNextStep}
            onCancel={onCancel}
            investorType={addInvestorFormRef.current?.values?.type}
          />
        </Wizard.Content>

        <Wizard.Content
          id={FormSteps.SEND_INVITATION}
          activeStep={currentStep!}
        >
          <SendInvites
            ref={sendInvitesFormRef}
            initialValues={initialValues.SEND_INVITATIONS}
            onSubmit={createInvestor}
            onCancel={onCancel}
            isLoading={isLoading}
          />
        </Wizard.Content>
      </Drawer.Content>
    </Drawer>
  )
}

export default CreateInvestor
