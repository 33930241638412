import { useCallback } from 'react'
import {
  BasicSubjectFragment,
  SortDirection,
} from 'types/graphql-schemas/graphql'
import { useSubjectsLazyQuery } from 'utils/hooks/queries/subjects/useSubjectLazyQuery'
import {
  getFilters,
  SubjectFilter,
} from 'utils/hooks/queries/subjects/useSubjectsQuery'
import { OptionContainer, OptionText } from './SubjectSelector.styles'

interface Props {
  filters: SubjectFilter
  displayAvatar: boolean
  resultsValidations?: (
    results: Partial<BasicSubjectFragment>[],
    search: string
  ) => void
}

const useSubjectSelector = ({
  filters,
  displayAvatar,
  resultsValidations,
}: Props) => {
  const [getSubjects] = useSubjectsLazyQuery({
    filters,
    sorting: {
      orderBy: 'name',
      direction: SortDirection.Asc,
    },
  })

  const loadOptions = useCallback(
    async (name) => {
      const response = await getSubjects({
        variables: {
          query: getFilters({
            ...filters,
            name,
          }),
        },
      })

      resultsValidations?.(response.data?.subjects || [], name)

      return response.data?.subjects || []
    },
    [filters, getSubjects, resultsValidations]
  )

  const getOption = useCallback(
    (subject: BasicSubjectFragment) => {
      if (displayAvatar) {
        return {
          id: subject.railsId,
          label: subject.name,
          icon: subject.logo || '/image-placeholder.png',
        }
      }

      return {
        id: subject.railsId,
        label: subject.name,
      }
    },
    [displayAvatar]
  )

  const renderOption = (option: {
    id: string
    logo: string
    label: string
  }) => {
    return (
      <OptionContainer>
        <OptionText>{option.label}</OptionText>
      </OptionContainer>
    )
  }

  return {
    loadOptions,
    getOption,
    renderOption,
  }
}

export default useSubjectSelector
