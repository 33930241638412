import CWLoader from 'components/CWLoader'
import Celeste from 'components/Celeste'
import { StyledShowInvestorLoading } from 'containers/InvestorManagement/ShowInvestor/ShowInvestor.styles'
import { ProfileProvider } from 'containers/Profiles/ProfileContext'
import HeaderContent from 'containers/Profiles/components/HeaderContent/HeaderContent.v2'
import Layout from 'containers/Profiles/components/Layout'
import InvestmentVehicleMainContent from 'containers/Profiles/components/MainContent/InvestmentVehicleMainContent'
import InvestmentVehicleInformation from 'containers/Profiles/components/SideInformation/InvestmentVehicleInformation'
import { ProfileType } from 'containers/Profiles/types'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import {
  INVESTOR_PROFILE_VEHICLE_PROFILE_ALL_UPDATES,
  VEHICLES_ALL_UPDATES,
} from 'routes/constant/investorManagementRoutes'
import { isActingAsInvestorGroup } from 'selectors/auth'
import { SubjectProvider } from 'utils/context/subjects/SubjectContext'
import { useSubjectQuery } from 'utils/hooks/queries/subjects/useSubjectQuery'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import useInvestmentVehicleProfile from './useInvestmentVehicleProfile'

const InvestmentVehicleProfile = () => {
  const { investmentVehicleId, investorId } = useParams<{
    investmentVehicleId: string
    investorId?: string
  }>()
  const { data, isLoading: isLoadingSubject } =
    useSubjectQuery(investmentVehicleId)
  const subject = data?.subject
  const railsId = subject?.railsId

  const isComingFromInvestorProfile = !!investorId

  const {
    fundsPortfolioInvestor,
    isLoading,
    isLoadingFunds,
    onShowTransaction,
    onEditTransaction,
  } = useInvestmentVehicleProfile({
    investmentVehicleId: railsId || '',
    investorId: subject?.parentSubject?.railsId,
  })

  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  if (isLoading || isLoadingFunds || isLoadingSubject) {
    return (
      <StyledShowInvestorLoading>
        <CWLoader />
      </StyledShowInvestorLoading>
    )
  }

  if (!isLoadingSubject && !subject) {
    return (
      <span>
        <FormattedMessage id="investorManagement.errors.fetchInvestmentVehicle" />
      </span>
    )
  }

  return (
    <SubjectProvider subject={subject!}>
      <ProfileProvider>
        <Layout>
          <Layout.Header type={ProfileType.INVESTMENT_VEHICLE}>
            <HeaderContent />
          </Layout.Header>

          <Layout.SideContent>
            <InvestmentVehicleInformation />
          </Layout.SideContent>

          <Layout.MainContent>
            <InvestmentVehicleMainContent
              investmentVehicleId={railsId!}
              isLoadingFunds={isLoadingFunds}
              fundsPortfolioInvestor={fundsPortfolioInvestor}
              onShowTransaction={onShowTransaction}
              onEditTransaction={onEditTransaction}
            />
          </Layout.MainContent>

          {!isInvestorGroup && (
            <Celeste
              prefixUrl={
                isComingFromInvestorProfile
                  ? INVESTOR_PROFILE_VEHICLE_PROFILE_ALL_UPDATES.replace(
                      ':investorId',
                      investorId
                    ).replace(':investmentVehicleId', investmentVehicleId)
                  : VEHICLES_ALL_UPDATES.replace(
                      ':investmentVehicleId',
                      investmentVehicleId
                    )
              }
              showCreateTransactionOption
            />
          )}
        </Layout>
      </ProfileProvider>
    </SubjectProvider>
  )
}

export default InvestmentVehicleProfile
