/* eslint-disable react/jsx-no-useless-fragment */

import React from 'react'
import PropTypes from 'prop-types'

import Logo from 'components/Logo'
import { accentColorIsDefaultColor } from 'utils/functions/groups'
import {
  BigLogoImage,
  Overlay,
} from 'containers/CustomLogin/CustomLoginPreview/CustomLoginPreview.styles'
import CWLoader from 'components/CWLoader'
import { useCustomLogin } from 'utils/hooks/useCustomLogin'
import { LOGO_SIZE } from 'components/Logo/Logo'
import {
  LeftSideBackground,
  LeftSideLogoContainer,
  LoginContainer,
  RightSideBackground,
  LoaderWrapper,
} from './AuthLayout.styles'

const AuthLayout = ({ children }) => {
  const { color, loginImage, isCustomLogin, isLoadingCustomLogin } =
    useCustomLogin()

  if (isLoadingCustomLogin) {
    return (
      <LoaderWrapper>
        <CWLoader />
      </LoaderWrapper>
    )
  }

  return (
    <LoginContainer>
      <LeftSideBackground>
        <LeftSideLogoContainer>
          {isCustomLogin &&
          loginImage?.url &&
          !loginImage.url.includes('missing.png') ? (
            <BigLogoImage url={loginImage.url} />
          ) : (
            <>
              {isCustomLogin ? (
                <>
                  {color && !accentColorIsDefaultColor(color) && (
                    <>
                      <Overlay color={color} />
                      <Overlay />
                    </>
                  )}
                  <Logo withAnimation size={LOGO_SIZE.LARGE} />
                </>
              ) : (
                <Logo withAnimation size={LOGO_SIZE.LARGE} />
              )}
            </>
          )}
        </LeftSideLogoContainer>
      </LeftSideBackground>
      <RightSideBackground>{children}</RightSideBackground>
    </LoginContainer>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthLayout
