/* eslint-disable react/style-prop-object, react/style-prop-object */

import classNames from 'classnames'

import { FormattedNumber } from 'react-intl'

import Tooltip from 'components/Tooltip/Tooltip'
import GrowthIndicator from 'components/Dashboard/GrowthIndicator'
import GrowthValue from 'components/Dashboard/GrowthIndicator/GrowthValue'

import FMVHistoryTooltip from 'components/PortfolioHoldings/FMVTooltip'

import {
  FMVCell,
  FMVValue,
} from 'components/FairMarketValueHistory/FairMarketValueHistory.styles'

import { PortfolioCompanyWithFmv } from 'utils/types/portfolios'

import { Nullable } from 'utils/types/common'
import { displayThousands } from 'utils/functions/number'

export const renderFairMarketValues = (
  rowData: PortfolioCompanyWithFmv,
  styles: any
) => {
  const { fairMarketValues } = rowData
  let lastFmv
  let currentFmv
  if (fairMarketValues) {
    if (fairMarketValues.length > 1) {
      ;[currentFmv, lastFmv] = fairMarketValues
    } else {
      ;[currentFmv] = fairMarketValues
    }
  }

  let growth: Nullable<boolean> = null
  let growthValue: Nullable<number> = null
  if (lastFmv && currentFmv.value !== lastFmv.value) {
    growth = currentFmv.value > lastFmv.value
    growthValue = Math.abs(lastFmv.value - currentFmv.value)
  }

  return (
    <div
      className={classNames(
        styles.cell,
        styles.boldCell,
        styles.noWrapCell,
        styles.fmvCell,
        'fs-exclude'
      )}
    >
      <FMVCell>
        <FMVValue>
          <FormattedNumber
            value={currentFmv?.value || 0}
            style="currency"
            currency="USD"
            currencyDisplay="narrowSymbol"
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />

          {growth !== null && (
            <Tooltip
              className={styles.fmvTooltip}
              place="top"
              widthAuto
              text={
                <FMVHistoryTooltip
                  currentFmv={currentFmv?.value ?? '-'}
                  currentFmvDate={currentFmv?.date}
                  lastFmv={lastFmv?.value ?? ''}
                  lastFmvDate={lastFmv?.date}
                />
              }
            >
              <div className={styles.fmvIndicator}>
                <GrowthIndicator direction={growth ? 'up' : 'down'} />
                <GrowthValue
                  growth={growth}
                  bold
                  value={displayThousands(growthValue || 0).toString()}
                />
              </div>
            </Tooltip>
          )}
        </FMVValue>
      </FMVCell>
    </div>
  )
}

export const renderFairMarketValue = (
  rowData: PortfolioCompanyWithFmv,
  styles: any
) => {
  return (
    <div className={classNames(styles.cell, 'fs-exclude')}>
      <FormattedNumber
        value={rowData.totalFairMarketValue || 0}
        style="currency"
        currency="USD"
        currencyDisplay="narrowSymbol"
        minimumFractionDigits={0}
        maximumFractionDigits={0}
      />
    </div>
  )
}
