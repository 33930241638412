import type { SizeProp } from '@fortawesome/fontawesome-svg-core'

import { getPortfolioIconByType } from 'utils/functions/renderers/renderPortfolioHelper'
import { getInitials } from 'utils/functions/user'
import { useMetricsSubjectQuery } from 'utils/hooks/useMetricsSubjectQuery'
import { MetricSources } from 'utils/types/metrics'
import {
  getPortfolioTypeBySubjectType,
  MetricSubjectMatterType,
} from 'utils/types/metricsV2'
import * as Styles from './MetricHoldingAvatar.styles'

const SizeConfig = {
  default: {
    size: '2.8rem',
    iconSize: '1x' as SizeProp,
  },
  small: {
    size: '2.1rem',
    iconSize: 'sm' as SizeProp,
  },
}

type MetricHoldingAvatarProps = {
  size?: keyof typeof SizeConfig
  hasPortfolioIconBorder?: boolean
  metricSource?: MetricSources
  subjectType: MetricSubjectMatterType
  subjectId: string
}

const MetricHoldingAvatar = ({
  size = 'default',
  metricSource,
  subjectId,
  subjectType,
  hasPortfolioIconBorder = true,
}: MetricHoldingAvatarProps) => {
  const isCalculatedMetric = metricSource === MetricSources.System
  const sizeConfig = SizeConfig[size]
  const { data: subject } = useMetricsSubjectQuery({
    subjectId,
    subjectType,
  })

  return isCalculatedMetric ? (
    <Styles.PortfolioIconWrapper
      size={sizeConfig.size}
      hasPortfolioIconBorder={hasPortfolioIconBorder}
    >
      {getPortfolioIconByType(
        getPortfolioTypeBySubjectType(subjectType)!,
        sizeConfig.iconSize
      )}
    </Styles.PortfolioIconWrapper>
  ) : (
    <Styles.Avatar
      size={sizeConfig.size}
      image={subject?.primaryLogo?.url}
      initials={getInitials(subject?.name)}
      avatarStyle="avatarCircleLogs"
    />
  )
}

export default MetricHoldingAvatar
