import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const AllMetricsContainer = styled.div`
  padding-top: 0.4rem;
`

export const FounderMetricsCardContainer = styled.div`
  margin-bottom: 1.6rem;
`

export const MetricsCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`

export const ZeroStateContainer = styled.div`
  margin-top: 10.2rem;
`
export const ScrollWrapper = styled.div`
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Cell = styled.div`
  color: ${color('darkGray')};
  display: flex;
  font-size: 1.4rem;
  justify-content: start;
  overflow-wrap: anywhere;
`

export const TooltipContent = styled.div`
  align-items: center;
  color: ${color('darkGray')};
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
`
