const investorManagement = {
  title: 'Investor Management',
  investors: 'Investors',
  countInvestors: 'Investors ({count})',
  investmentVehicles: 'Investment Vehicles',
  countInvestmentVehicles: 'Investment Vehicles ({count})',
  addNew: 'Add New',
  addInvestor: 'Add Investor',
  addInvestmentVehicle: 'Add Investment Vehicle',
  vehicleName: 'Name',
  addName: 'Add name',
  investmentVehicleInvestor: 'Investor',
  investorWarning:
    'Please double-check if the selected Investor is the correct one.',
  addressWarning:
    'Please double-check if the added address is the correct one.',
  selectInvestor: 'Select Investor',
  shareInvestorData:
    'Use Investor contact and financial information for the investment vehicle.',
  phone: 'Phone',
  addPhone: 'Add Phone',
  address: 'Address',
  addAddress: 'Add address',
  addCountry: 'Add country',
  addState: 'Add state / province',
  addCity: 'Add city',
  addPostalCode: 'Add postal code',
  financialInfo: 'Financial Info',
  einTaxId: 'EIN / Tax ID',
  socialSecurityNumber: 'Social Security Number',
  addSSN: 'Add SSN',
  addEinTaxId: 'Add EIN / Tax ID',
  addTaxId: 'Add tax ID',
  bankName: 'Bank Name',
  addBankName: 'Add bank name',
  bankAccountNumber: 'Bank Account Number',
  addBankAccountNumber: 'Add bank account number',
  routingNumber: 'Routing number',
  addRoutingNumber: 'Add routing number',
  loadingAddresses: 'Loading addresses...',
  noAddressYet: 'No address yet',
  addAdditionalInfo: 'Add Other Info',
  sendInvitation: 'Send Invitation',
  name: 'Name',
  organizationName: 'Organization Name',
  addFullName: 'Add full name',
  addOrganizationName: 'Add organization name',
  individual: 'Individual',
  organization: 'Organization',
  website: 'Website',
  addWebsite: 'Add website',
  emailAddress: 'Email Address',
  description: 'Description',
  addDescription: 'Add description',
  writeYourDescriptionHere: 'Write your description here...',
  email: 'Email Address',
  addEmail: 'Add email',
  pointOfContact: 'Point of Contact',
  addPointOfContact: 'Add email',
  emailFormatError: 'Email format is not valid',
  duplicatedEmailError: 'Email already added',
  foundedDate: 'Founded date',
  selectFoundedDate: 'Select founded date',
  directories: 'Directories',
  crunchbaseProfile: 'Crunchbase Profile',
  angelListProfile: 'Angellist Profile',
  socialMedia: 'Social Media',
  linkedinProfile: 'LinkedIn Profile',
  twitterProfile: 'X Profile',
  investorTeam: 'Investor Team',
  teamSize: 'Total Number of Employees',
  addTotalEmployees: 'Add total number of employees',
  keyPeople: 'Key People',
  noKeePeople: 'You have no key people',
  legalStructure: 'Legal Structure',
  legalEntityName: 'Legal Entity Name',
  addLegalEntityName: 'Add legal entity name',
  addLegalStructure: 'Select legal structure',
  legalCountry: 'Legal Country',
  addLegalCountry: 'Type and select country',
  legalState: 'Legal State',
  addLegalState: 'Type and select state',
  targetIndustries: 'Target Industries',
  addIndustry: 'Add Industry...',
  twitterProfilePlaceholder: 'https://www.x.com/username',
  noKeyPeople: 'You have no key people yet.',
  addPeople: 'Add New Member',
  sendInvitationToJoin: 'Send invitation to join Clockwork Universe ',
  inviteGroupMembers: 'Invite Group Members',
  invitationMessage: 'Invitation message',
  addInvitationMessage: 'Add invitation message',
  defaultInvitationMessage:
    'Hi, we’ve created this group on Clockwork Universe for you.Through it, we will share fund-related updates with you, such as announcements, documents, and your investment activity. Never miss an update again!',
  addbankName: 'Add Bank Name',
  noAddresses: "You don't have any address yet.",
  addAndSendInvitation: 'Add & Send invitation',
  add: 'Add',
  investorNameTakenError: 'The investor name is already taken',
  editInvestmentVehicle: 'Edit Investment Vehicle',
  vehicleErrors: {
    vehicleNameTaken: 'The vehicle name is already taken.',
  },
  list: {
    addedTo: 'Added to',
    totalCommitment: 'Total Commitment',
    totalCapital: 'Total Capital called',
    showMore: 'Show More',
    showLess: 'Show Less',
    viewProfile: 'View Profile',
    editInvestor: 'Edit Investor',
    editInvestmentVehicle: 'Edit Investment Vehicle',
    investorInformationLog: 'Investor Information Log',
    vehicleInformationLog: 'Investment Vehicle Information Log',
    addTransaction: 'Add Transaction',
    delete: 'Delete',
    loadingInvestors: 'Loading investors...',
    loadingInvestmentVehicles: 'Loading investment vehicles...',
    noFundsYet: 'No funds yet',
    noAdditionalDataAboutThisInvestor:
      'You have no additional data about this investor',
    extended: {
      generalInformation: 'General Information',
      description: 'Description',
      investmentVehicles: 'Investment Vehicles ({count})',
      financialInformation: 'Financial Information',
      foundedDate: `Founded {date}`,
      keyMembers: 'Key Members',
      teamSize: 'Team Size: {size}',
      taxId: 'EIN / Tax ID',
      bankName: 'Bank Name',
      bankAccount: 'Bank Account Number',
      routingNumber: 'Routing Number',
      contactInformation: 'Contact information',
      noAdditionalInformation:
        'You have no data added about this investment vehicle.',
    },
  },
  investmentVehicle: {
    title: 'investment vehicles',
    name: 'name',
    committedCapital: 'committed capital',
    capitalCalled: 'capital called',
    unfundedCommitment: 'unfunded commitment',
    amountDistributed: 'amount distributed',
    totalInvestment: 'total investments',
    prorataHoldingValue: 'pro-rata holding value',
    errorFetchingTransactions:
      'An error occurred when getting the transactions, try again later',
    loadingTransactions: 'Loading Transactions...',
    loadMoreTransactions: 'Load More Transactions',
    noVehiclesInInvestorFunds:
      'This investor has no vehicles related to a fund.',
  },
  funds: {
    title: 'funds & deals',
    name: 'name',
    committedCapital: 'committed capital',
    capitalCalled: 'capital called',
    unfundedCommitment: 'unfunded commitment',
    amountDistributed: 'distributions',
    totalInvestment: 'total investments',
    errorFetchingTransactions:
      'An error occurred when getting the transactions, try again later',
    loadingTransactions: 'Loading Transactions...',
    loadMoreTransactions: 'Load More Transactions',
    notFundsForVehicles: 'This vehicle is not related to any fund.',
    notFundsForInvestors: 'This investor is not added to any fund.',
  },
  fundHoldings: {
    title: 'Holdings',
    search: 'Search...',
    fundHoldingNameSort: 'Fund Name',
    nameSort: 'Name',
    lastUpdateSort: 'Last Update',
    somethingWentWrong: 'Something went wrong. Please, try again later.',

    company: {
      location: 'Location',
      industries: 'Industries',
      lastUpdate: 'Last update',
    },
  },
  updates: {
    search: 'Search...',
    searchBy: 'Search by',
  },
  errors: {
    fetchInvestors:
      'An error occurred fetching the investors, try again later.',
    fetchInvestor: 'An error occurred fetching the investor, try again later.',
    fetchInvestmentVehicles:
      'An error occurred fetching the investment vehicles, try again later.',
    fetchInvestmentVehicle:
      'An error occurred fetching the investment vehicle, try again later.',
    createInvestor: 'An error occurred creating the investor, try again later.',
    editInvestor: 'An error occurred updating the investor, try again later.',
    createInvestmentVehicle:
      'An error occurred creating the investment vehicle, try again later.',
    editInvestmentVehicle:
      'An error occurred editing the investment vehicle, try again later.',
    resendInvite:
      'An error occurred sending the invitation to {email}, try again later.',
    invitesSent: 'An error occurred adding the new members, try again later.',
    userRemoved: 'An error occurred removing the member, try again later.',
    undoRemoved: 'Undo removed',
    deleteInvestor: 'An error occurred removing the investor, try again later.',
    deleteInvestmentVehicle:
      'An error occurred removing the investment vehicle, try again later.',
    fetchFundPortfolios:
      'An error occurred fetching the funds portfolios, try again later.',
  },
  success: {
    createInvestor: 'You have successfully created {investorName}',
    removeInvestor: '{investorName} has been removed from {fundName}.',
    createdInvestorWithMembers:
      'You have successfully created {investorName} and invited {membersLength} members',
    createdInvestmentVehicle: 'You have successfully created {vehicleName}',
    editInvestor: 'Your changes on {investorName} were successfully saved',
    editInvestmentVehicle:
      'Your changes on {investmentVehicleName} were successfully saved',
    resendInvite: 'Invitation to {email} sent successfully',

    invitesSent:
      'You have successfully added {countUsers, plural, one {1 new member} other { # new members}} to {investorName}',
    userRemoved: 'You have removed {userName} successfully from {investorName}',
    deleteInvestor: 'You have successfully deleted {name}',
    deleteInvestmentVehicle: 'You have successfully deleted {name}',
  },

  editInvestor: {
    generalInformation: 'General Information',
    editInvestorInformation: 'Edit Investor',
    editProfile: 'Edit Profile',
    information: 'Information',
    members: 'Members',
    groupMembers: 'Group Members',
    otherInformation: 'Other Information',
    investorLinks: 'Links',
    awaitingSignup: 'Awaiting Signup',
    emails: 'Email(s)',
    addEmail: 'Add email address',
    addMember: 'Add Member',
    team: 'Team',
  },
  filterByFound: 'Filter by Fund',
  sortOptions: {
    totalCommitment: 'Total Commitment',
    totalCapitalCall: 'Total Capital Called',
    name: 'Name',
  },
  actions: {
    import: 'Import',
    export: 'Export All',
  },
  deleteInvestorModal: {
    deleteInvestor: 'Delete Investor',
    areYouSure: 'Are you sure you want to delete',
    confirmationText: 'To delete the investor, please type its name.',
    cancel: 'Cancel',
    delete: 'Delete',
    warningWithVehicles:
      'By deleting this investor, you will be deleting {investmentVehiclesLength} and all the related transactions. Also, all members of this group won’t be able to access the information anymore. Be aware that you cannot undo this action.',
    typeInvestorName: 'Type investor name...',
    investmentVehicles: 'investment vehicles',
    warningWithoutVehicles:
      'All members of this group won’t be able to access the information anymore. Be aware that you cannot undo this action.',
  },
  deleteInvestmentVehicleModal: {
    removeInvestmentVehicle: 'Remove Investment Vehicle',
    deleteInvestmentVehicle: 'Delete Investment vehicle',
    areYouSure: 'Are you sure you want to delete',
    confirmationText: 'To delete the investment vehicle, please type its name.',
    cancel: 'Cancel',
    delete: 'Delete',
    warning:
      'By deleting this investment vehicle, you will delete all general information and related transactions. Be aware that you cannot undo this action.',
    typeInvestmentVehicleName: 'Type investment vehicle name...',
  },
  suggestCreateInvestmentVehicleModal: {
    title: 'Investment Vehicle',
    firstLine: 'You have successfully added {investorName}',
    secondLine:
      'Would you like to create an investment vehicle with the same data?',
    dontShowAgain: "Don't show me this message again",
  },
  createTransaction: {
    holding: 'Holding {type}',
    investor: 'Investor {type}',
    investment: 'Investment',
    additionalInformation: 'ADDITIONAL INFORMATION',
    fundManager: {
      investor: 'Investor',
      investmentVehicle: 'Investment Vehicle',
      selectInvestor: 'Select investor',
      selectInvestmentVehicle: 'Select investment vehicle',
    },
  },
  profiles: {
    foundedDate: 'Founded {date}',
    teamSize: 'Team Size: {employeesCount} Employees',
    noUpdates: 'No updates.',
    edit: 'Edit Profile',
  },
}

export default investorManagement
