import { useFetchUpdateQuery } from 'utils/hooks/queries/useUpdateQuery'
import useParseCurrentUrl from '../useParseCurrentUrl'

const useUpdateInformation = () => {
  const { updateId, updateType } = useParseCurrentUrl()

  const { data: update } = useFetchUpdateQuery({
    updateId: updateId ?? '',
    updateType: updateType ?? '',
    enabled: !!updateId && updateId !== 'new',
  })

  return { update }
}

export default useUpdateInformation
