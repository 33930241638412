/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { FileContent } from 'utils/types/files'
import { RootState } from 'store'
import {
  FileUploadStatus,
  Upload,
  isUploadFinished,
} from '../utils/types/uploads'

export type FileToUpload = {
  id: string
  file: File
  abortController: AbortController
  fileUploadContextId: string
}

const initialState = {
  uploadsMenuOpen: false,
  uploadsMenuExpanded: true,
  files: [] as Upload[],
}

type UploadsState = typeof initialState

const calculateUploadTimeLeft = (
  startDate: Date,
  uploadProgress: number,
  uploadTotal: number
): number => {
  if (!uploadProgress) return Infinity

  const currentDate = new Date()
  const uploadTime = currentDate.getTime() - startDate.getTime()
  const percentUploaded = Math.round((uploadProgress * 100) / uploadTotal)
  const percentLeft = 100 - percentUploaded
  const timeLeft = (percentLeft * uploadTime) / percentUploaded
  return Math.round(timeLeft / 1000)
}

export const uploadsSlice = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    showUploadsMenu: (state, action: PayloadAction<boolean>) => {
      state.uploadsMenuOpen = action.payload
    },
    toggleExpandMenu: (state) => {
      state.uploadsMenuExpanded = !state.uploadsMenuExpanded
    },
    addUploads: (state, action: PayloadAction<FileToUpload[]>) => {
      state.files.push(
        ...action.payload.map((fileData) => ({
          id: fileData.id,
          file: fileData.file,
          status: FileUploadStatus.UPLOADING,
          uploadProgress: 0,
          uploadStartDate: new Date(),
          abortController: fileData.abortController,
          fileUploadContextId: fileData.fileUploadContextId,
        }))
      )
    },
    restartUpload: (state, action: PayloadAction<FileToUpload>) => {
      const fileData = action.payload
      const prevUploadIndex = state.files.findIndex(
        (file) => file.id === fileData.id
      )
      if (prevUploadIndex === -1) return
      state.files[prevUploadIndex] = {
        id: fileData.id,
        file: fileData.file,
        status: FileUploadStatus.UPLOADING,
        uploadProgress: 0,
        uploadStartDate: new Date(),
        abortController: fileData.abortController,
        fileUploadContextId: fileData.fileUploadContextId,
      }
    },
    cancelUploads: (state: UploadsState) => {
      state.files.forEach((file) => {
        file.abortController.abort()
      })
      state.files = []
    },

    setUploadStatus(
      state,
      action: PayloadAction<{
        fileId: string
        status: FileUploadStatus
        error?: string
      }>
    ) {
      const file = state.files.find(
        (stateFile) => stateFile.id === action.payload.fileId
      )!
      file.status = action.payload.status
      file.error = action.payload.error
    },
    setUploadCompleted(
      state,
      action: PayloadAction<{
        fileId: string
        fileContent: FileContent
      }>
    ) {
      const file = state.files.find(
        (stateFile) => stateFile.id === action.payload.fileId
      )!
      file.status = FileUploadStatus.FINISHED

      if (isUploadFinished(file)) {
        file.fileContent = action.payload.fileContent
      }
    },

    setUploadProgress(
      state,
      action: PayloadAction<{
        fileId: string
        uploadProgress: number
        uploadTotal: number
      }>
    ) {
      const file = state.files.find(
        (stateFile) => stateFile.id === action.payload.fileId
      )
      if (!file) return

      if (file.id === action.payload.fileId) {
        file.uploadProgress = action.payload.uploadProgress
        file.uploadTotal = action.payload.uploadTotal
        file.uploadTimeLeft = calculateUploadTimeLeft(
          file.uploadStartDate,
          action.payload.uploadProgress,
          action.payload.uploadTotal
        )
      }
    },
    removeUpload(state, action: PayloadAction<{ fileId: string }>) {
      state.files = state.files.filter(
        (file) => file.id !== action.payload.fileId
      )
      if (state.files.length === 0) {
        state.uploadsMenuOpen = false
      }
    },
    clearUploads(state) {
      state.files = state.files.filter(
        (file) => file.status === FileUploadStatus.UPLOADING
      )
    },
  },
})

export const {
  showUploadsMenu,
  toggleExpandMenu,
  addUploads,
  setUploadProgress,
  setUploadStatus,
  restartUpload,
  removeUpload,
  clearUploads,
  cancelUploads,
  setUploadCompleted,
} = uploadsSlice.actions

export const getIsMenuOpen = (state: RootState) => state.uploads.uploadsMenuOpen
export const getIsMenuExpanded = (state: RootState) =>
  state.uploads.uploadsMenuExpanded

export const getIsUploadingFiles = (state: RootState) =>
  state.uploads.files.some((file) => file.status === FileUploadStatus.UPLOADING)

export const getUploads = (state: RootState): Upload[] => state.uploads.files

export const getUploadsInProgress = (state: RootState): boolean => {
  return state.uploads.files.some(
    (file: Upload) => file.status === FileUploadStatus.UPLOADING
  )
}

export const getUploadsByContext =
  (contextId: string) =>
  (state: RootState): Upload[] =>
    state.uploads.files.filter((file) => file.fileUploadContextId === contextId)

export default uploadsSlice.reducer
