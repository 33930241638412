import {
  BasicSubjectFragment,
  FullSubjectFragment,
} from 'types/graphql-schemas/graphql'
import { getAttribute } from 'utils/gql/helpers/subjects'
import { RefAttribute } from 'utils/gql/types/attributes'
import { Nullable } from 'utils/types/common'
import { SubjectType } from 'utils/types/subjects'

export const getSubjectSocials = (
  subject?: Nullable<BasicSubjectFragment | FullSubjectFragment>
) => {
  const isFund = subject?.type === SubjectType.FUND

  if (isFund) {
    const fundManager = getAttribute<RefAttribute<BasicSubjectFragment>>(
      subject,
      'organization'
    )?.value?.entity

    return {
      linkedinUrl: getAttribute<string>(fundManager, 'linkedinUrl')?.value,
      twitterUrl: getAttribute<string>(fundManager, 'twitterUrl')?.value,
      website: getAttribute<string>(fundManager, 'website')?.value,
      crunchBaseUrl: getAttribute<string>(fundManager, 'crunchBaseUrl')?.value,
      angelListUrl: getAttribute<string>(fundManager, 'angelListUrl')?.value,
    }
  }

  return {
    linkedinUrl: getAttribute<string>(subject, 'linkedinUrl')?.value,
    twitterUrl: getAttribute<string>(subject, 'twitterUrl')?.value,
    crunchBaseUrl: getAttribute<string>(subject, 'crunchBaseUrl')?.value,
    angelListUrl: getAttribute<string>(subject, 'angelListUrl')?.value,
    website: getAttribute<string>(subject, 'website')?.value,
  }
}

const useSubjectSocials = (
  subjectEntity: Nullable<BasicSubjectFragment | FullSubjectFragment>
) => {
  return getSubjectSocials(subjectEntity)
}

export default useSubjectSocials
