import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useApolloClient } from '@apollo/client'
import sortBy from 'lodash/sortBy'

import {
  getCurrentGroupId,
  getPreviousGroupId,
  getUserGroups,
} from 'selectors/auth'
import { switchGroup } from 'features/authSlice'

import { setCurrentPortfolio } from 'actions/portfolios'
import { resetCurrentCompany, setCurrentCompany } from 'actions/companies'
import { GroupTypes } from 'utils/constants/groups'

import { AppGroup, Group } from 'utils/types/user'
import { useCurrentInvestorManagementEntities } from 'utils/hooks/useCurrentInvestorManagementEntities'

import { portfolioKeys } from 'utils/queries/portfolios'

export const useGroupSelector = () => {
  const dispatch = useAppDispatch()

  const history = useHistory()

  const queryClient = useQueryClient()

  const apolloClient = useApolloClient()

  const userGroups = useAppSelector(getUserGroups)

  const previousGroupId = useAppSelector(getPreviousGroupId)

  const currentGroupId = useAppSelector(getCurrentGroupId)

  const { removeCurrentInvestor, removeCurrentInvestmentVehicle } =
    useCurrentInvestorManagementEntities()

  const onSelectGroup = useCallback(
    async (groupId: string) => {
      queryClient.removeQueries()
      await apolloClient.clearStore()
      removeCurrentInvestor()
      removeCurrentInvestmentVehicle()
      dispatch(switchGroup(groupId))
      const newGroup = userGroups?.[groupId] as Group

      dispatch(setCurrentPortfolio({}))
      queryClient.invalidateQueries(portfolioKeys.allInvestments())

      if (newGroup.type === GroupTypes.FOUNDER) {
        const [newCompany] = newGroup.companyData
        dispatch(setCurrentCompany(newCompany))
      } else if (newGroup.type === GroupTypes.FUND_MANAGER) {
        const newCompany = newGroup.companyData.find((company) => company.fund)
        dispatch(setCurrentCompany(newCompany))
      } else {
        dispatch(resetCurrentCompany())
      }

      history.replace('/')
    },
    [
      queryClient,
      apolloClient,
      removeCurrentInvestor,
      removeCurrentInvestmentVehicle,
      dispatch,
      userGroups,
      history,
    ]
  )

  const groups = useMemo<{
    currentGroup?: AppGroup
    previousGroup?: AppGroup
    groups: AppGroup[]
  }>(() => {
    if (!userGroups) {
      return {
        groups: [],
      }
    }
    const newGroupsOrder = Object.values(userGroups)
    const currentGroup = currentGroupId
      ? newGroupsOrder.find((group) => group.id === currentGroupId)
      : newGroupsOrder[0]

    const previousGroup = newGroupsOrder.find(
      (group) => group.id === previousGroupId
    )

    return {
      currentGroup,
      previousGroup,
      groups: sortBy(newGroupsOrder, (group) => group.name),
    }
  }, [currentGroupId, previousGroupId, userGroups])

  return {
    ...groups,
    onSelectGroup,
  }
}
