import { useEffect } from 'react'
import { useAuthentication } from 'utils/hooks/auth'
import { useRefreshAuth0Tokens } from './utils/hooks/useRefreshAuth0Tokens'

const RefreshAuth0Tokens = ({ children }) => {
  const { isAuthenticated } = useAuthentication()

  const { refreshTokens, refreshingTokens, setRefreshingTokens } =
    useRefreshAuth0Tokens()
    
  useEffect(() => {
    if (isAuthenticated) {
      refreshTokens()
    } else {
      setRefreshingTokens(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (refreshingTokens) return null

  return children
}

export default RefreshAuth0Tokens
