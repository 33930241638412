import { useId } from 'react'
import FileIcon from 'components/FileIcon'
import {
  AttachedEmailFileContent,
  FileContent,
  FileExtension,
  IndexFileContent,
  UserContentStatus,
} from 'utils/types/files'
import {
  isAttachedEmailFileContent,
  isIndexEmailContent,
} from 'utils/functions/files'
import { FileViewEnum } from 'components/FileViewSelector'
import {
  SkeletonAvatar,
  SkeletonItem,
  SkeletonThumbnailContainer,
  SkeletonThumbnailContentWrapper,
} from 'components/Skeletons/Skeletons.styles'
import {
  CheckIcon,
  ContentNameContainer,
  ContentSelectedContainer,
  ContentWrapper,
  FadeIn,
  ContentName,
  TooltipName,
  AlreadyAddedIcon,
  AlreadySelectedOverlay,
  OptionsButton,
} from '../../Content.styles'
import UnreconciledIndicator from '../UnreconciledIndicator'
import SharedIndicator from '../SharedIndicator'
import FileActions from '../FileActions'

interface Props {
  file: IndexFileContent | AttachedEmailFileContent
  isSelected?: boolean
  onClickFile?: (file: FileContent) => void
  showDropdown?: boolean
  showUnreconciledIndicator?: boolean
  showSharedIndicator?: boolean
  isLoading: boolean
  size: FileViewEnum
  isMenuOpen?: boolean
  onCloseContentMenu?: () => void
  onDownloadFile?: (file: FileContent) => void
  onPreviewFile?: (file: FileContent) => void
  onDeleteFile?: (file: FileContent) => void
  onRightClick?: () => void
  onClickGeneralInformation?: (file: FileContent) => void
  filesAlreadyAdded?: string[]
}

const FileContentComponent = ({
  file,
  isSelected,
  onClickFile,
  showDropdown = true,
  showUnreconciledIndicator = true,
  showSharedIndicator = true,
  size,
  isLoading,
  isMenuOpen,
  onDownloadFile,
  onPreviewFile,
  onRightClick,
  onCloseContentMenu,
  onDeleteFile,
  onClickGeneralInformation,
  filesAlreadyAdded,
}: Props) => {
  const id = useId()

  const isFileAlreadyAdded = filesAlreadyAdded?.includes(file.id)
  const isEmailContent = isIndexEmailContent(file)

  const rawFileName = isEmailContent ? file.data.title : file.fileName

  const fileName =
    rawFileName.includes(file.fileFormat) ||
    file.fileFormat === FileExtension.UNKNOWN
      ? rawFileName
      : `${rawFileName}.${file.fileFormat}`

  const isFileFromSharedUpdate =
    !isAttachedEmailFileContent(file) &&
    file.userContentStatus === UserContentStatus.SHARED_UPDATE &&
    showSharedIndicator

  if (isLoading) {
    return (
      <SkeletonThumbnailContainer>
        <SkeletonThumbnailContentWrapper size={size}>
          <SkeletonAvatar
            borderWidth="0.3rem"
            height={size === FileViewEnum.LARGE ? '6.8rem' : '4.8rem'}
            width={size === FileViewEnum.LARGE ? '6.8rem' : '4.8rem'}
          />
          <SkeletonItem width="10rem" height="1rem" />
        </SkeletonThumbnailContentWrapper>
      </SkeletonThumbnailContainer>
    )
  }

  return (
    <FadeIn>
      <ContentSelectedContainer isSelected={isSelected}>
        <ContentWrapper
          isContentFromSharedUpdate={isFileFromSharedUpdate}
          isSelected={isSelected}
          size={size}
          showHoverState={!isSelected && !isFileAlreadyAdded}
          onClick={() => {
            if (isFileAlreadyAdded) return
            onClickFile?.(file)
          }}
          onDoubleClick={() => {
            if (isFileAlreadyAdded) return
            onPreviewFile?.(file)
          }}
          onContextMenu={(e) => {
            e.preventDefault()
            onRightClick?.()
          }}
        >
          <AlreadySelectedOverlay isVisible={!!isFileAlreadyAdded} />

          {isSelected && <CheckIcon icon={['far', 'check']} />}
          {isFileAlreadyAdded && <AlreadyAddedIcon icon={['far', 'check']} />}

          <FileIcon
            type={file.fileFormat}
            src={file.url}
            large={size === FileViewEnum.LARGE}
            hasBorder
          />

          <ContentNameContainer large={size === FileViewEnum.LARGE}>
            <TooltipName place="bottom" id={id} text={fileName} wordBreakAll>
              <ContentName>{fileName}</ContentName>
            </TooltipName>
            {showUnreconciledIndicator && (
              <UnreconciledIndicator content={file} />
            )}
            {isFileFromSharedUpdate && <SharedIndicator content={file} />}
          </ContentNameContainer>

          {showDropdown && (
            <OptionsButton>
              <FileActions
                isContentMenuOpen={isMenuOpen}
                onCloseContentMenu={onCloseContentMenu}
                file={file}
                onDownloadFile={onDownloadFile}
                onPreviewFile={onPreviewFile}
                onDeleteFile={onDeleteFile}
                onClickGeneralInformation={onClickGeneralInformation}
              />
            </OptionsButton>
          )}
        </ContentWrapper>
      </ContentSelectedContainer>
    </FadeIn>
  )
}

export default FileContentComponent
