import { useCallback, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'

import { useField, useFormikContext } from 'components/Form/hooks'
import { UpdateTypes } from 'utils/constants/updates'
import { SuggestedUpdateType, UpdateType } from 'utils/types/common'
import {
  CreateUpdateFromRules,
  SuggestedUpdateFields,
  SuggestedUpdateForm,
} from 'utils/types/suggestedUpdate'
import { SuggestedUpdate } from 'utils/types/update'
import { useAvailableUpdateTypes } from 'utils/hooks/useAvailableUpdateTypes'
import { type DropdownRef } from 'ui/Dropdown'

import InvestorManagementService from 'api/InvestorManagementService'
import { TransactionFundTypes } from 'utils/types/transactions'
import { isCompanyHoldingTopic, TopicEntity } from 'utils/types/chatTopicEntity'
import { SubjectType } from "utils/types/subjects"
import { useSuggestedHolding } from './useSuggestedHolding'
import { useCurrentGroupRules } from '../useCurrentGroupRules'

export const useSuggestedUpdateForm = (suggestedUpdate: SuggestedUpdate) => {
  const intl = useIntl()
  const [updateTypeField] = useField<SuggestedUpdateType>(
    SuggestedUpdateFields.type
  )
  const [transactionFundTypeField] = useField<TransactionFundTypes>(
    SuggestedUpdateFields.transactionFundType
  )
  const investorField = useField('investor')

  const formik = useFormikContext<SuggestedUpdateForm>()
  const { getAvailableUpdateTypesByCompany } = useAvailableUpdateTypes()

  const { currentGroupRules } = useCurrentGroupRules()
  const updateType = updateTypeField.value
  const dropdownUpdateFromRef = useRef<DropdownRef>(null)

  const { entity: suggestedHolding } = useSuggestedHolding({
    suggestedUpdate,
  })

  const createUpdateFromRules: CreateUpdateFromRules | undefined =
    currentGroupRules[updateType]

  const isSelectFromAvailable =
    createUpdateFromRules?.selectablePortfolios?.length ||
    createUpdateFromRules?.areHoldingsVisible ||
    createUpdateFromRules?.isOwnOrganizationVisible

  const isTransactionFundTypeAvailable =
    createUpdateFromRules?.isTransactionTypeVisible &&
    formik.values[SuggestedUpdateFields.type] === UpdateTypes.TRANSACTION

  const selectableUpdateTypes = useMemo(() => {
    const updateTypes = Object.keys(currentGroupRules) as UpdateType[]

    return updateTypes
      .filter(
        (type) =>
          type !== UpdateTypes.DOCUMENT || suggestedUpdate.contents.length <= 1
      )
      .sort((a, b) => a.localeCompare(b))
      .map((updType) => ({
        id: updType,
        label: intl.formatMessage({
          id: `suggestedUpdates.selectUpdateType.options.${updType}`,
        }),
      }))
  }, [currentGroupRules, intl, suggestedUpdate.contents.length])

  const tryToSelectSuggestedHoldingByDefault = useCallback(
    (value: UpdateType, currentUpdateFrom?: TopicEntity) => {
      if (suggestedHolding?.id !== currentUpdateFrom?.id) {
        const suggestedCompany = suggestedHolding?.company!
        const suggestedUpdateTypes =
          getAvailableUpdateTypesByCompany(suggestedCompany)
        return suggestedUpdateTypes[value] ? suggestedHolding : undefined
      }

      return undefined
    },
    [getAvailableUpdateTypesByCompany, suggestedHolding]
  )

  const onUpdateTypeChange = useCallback(
    (value: UpdateType) => {
      const currentUpdateFrom = formik.values.updateFrom
      let updateFrom: TopicEntity | undefined =
        tryToSelectSuggestedHoldingByDefault(value, currentUpdateFrom)

      if (isCompanyHoldingTopic(currentUpdateFrom?.entityType)) {
        const hasACompanySelected = !!currentUpdateFrom?.company
        if (hasACompanySelected) {
          const availableUpdateTypes = getAvailableUpdateTypesByCompany(
            currentUpdateFrom.company!
          )

          updateFrom = availableUpdateTypes[value]
            ? currentUpdateFrom
            : updateFrom
        }
      }

      if (!updateFrom) {
        dropdownUpdateFromRef.current?.clear()
      }

      formik.setValues({
        ...formik.values,
        [SuggestedUpdateFields.updateFrom]: updateFrom,
        [SuggestedUpdateFields.investor]: undefined,
        [SuggestedUpdateFields.type]: value,
      })
    },
    [
      formik,
      getAvailableUpdateTypesByCompany,
      tryToSelectSuggestedHoldingByDefault,
    ]
  )

  const onTransactionTypeChange = useCallback(
    (value: TransactionFundTypes) => {
      formik.setValues({
        ...formik.values,
        [SuggestedUpdateFields.transactionFundType]: value,
      })
    },
    [formik]
  )

  const loadInvestors = useCallback(async (inputSearch) => {
    const { results } = await InvestorManagementService.fetchInvestors({
      page: 1,
      name: inputSearch,
      orderBy: 'name',
      type: SubjectType.INVESTOR
    })
    return results
  }, [])

  return {
    updateTypeField,
    isSelectFromAvailable,
    isTransactionFundTypeAvailable,
    onUpdateTypeChange,
    onTransactionTypeChange,
    selectedTransactionFundType: transactionFundTypeField.value,
    investorField,
    loadInvestors,
    selectableUpdateTypes,
    selectablePortfolios: createUpdateFromRules?.selectablePortfolios,
    areHoldingsSelectable: !!createUpdateFromRules?.areHoldingsVisible,
    dropdownUpdateFromRef,
  }
}
