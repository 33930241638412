import type { CellChange, EventHandlers } from '@silevis/reactgrid'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsFounder } from 'selectors/auth'
import { SortDirection } from 'utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import { METRICS_PAGE_SIZE, MetricSortBy } from 'utils/constants/metrics'
import { dispatchEvent, useEventListener } from 'utils/hooks/useEventListener'
import { MetricsMode } from 'utils/types/metrics'
import { MetricsFilters, metricsKeys } from 'utils/queries/metrics'
import NumbersService from 'api/NumbersService'
import { BasicSubjectFragment } from 'types/graphql-schemas/graphql'
import {
  HOLDING_FETCHED_EVENT,
  HoldingCell,
  UnknownHoldingData,
  HoldingFetchedEventType,
} from 'components/TransactionsSpeadsheetImportModal/components/CustomHoldingCellTemplate'
import { INITIAL_HOLDINGS_FETCHED } from 'components/TransactionsSpeadsheetImportModal/components/useTransactionsSpreadsheet'
import useSubjectSelector from 'components/Selectors/SubjectSelector/useSubjectSelector'
import { SubjectType } from 'utils/types/subjects'
import { Holding } from 'utils/types/company'
import { InfiniteQueryData } from 'api/types'
import { IndexMetric } from 'utils/types/metricsV2'
import {
  METRIC_FETCHED_EVENT,
  Metric,
  MetricCell,
  UNKNOWN_METRIC_ID,
} from '../Spreadsheet/CellTemplates/MetricCellTemplate'
import {
  CustomDateCell,
  CustomNumberCell,
  CustomTextCell,
  RowNumberCell,
} from '../Spreadsheet/types'
import { MetricsSpreadsheetBuilder } from './MetricsSpreadsheetBuilder'
import { MetricsSpreadsheetMode } from './MetricsSpreadsheetLogic'

/**
 * Fixed cell (and column) width for every type of cell.
 */
export const CELL_WIDTH = 165

/**
 * Vertical padding of metric cells. Means (top + bottom) or (left + right).
 */
export const CELL_PADDING = 16

/**
 * Width of the arrow icon of the dropdown cell.
 */
export const ARROW_WIDTH = 17

export const MetricsSpreadsheetEvents = {
  INITIAL_METRICS_FETCHED: 'MSS_INITIAL_METRICS_FETCHED',
  METRIC_FOR_HOLDING_FETCHED: 'MSS_METRIC_FOR_HOLDING_FETCHED',
  SPREADSHEET_WITH_ERRORS: 'MSS_SPREADSHEET_WITH_ERRORS',
  PRELOADED_HOLDING_FETCHED_EVENT: 'MSS_PRELOADED_HOLDING_FETCHED_EVENT',
} as const

export type CellType =
  | RowNumberCell
  | CustomTextCell
  | CustomNumberCell
  | CustomDateCell
  | MetricCell
  | HoldingCell
export type GridType = CellType[][]

export type MetricForHoldingFetched = {
  metric?: Metric
  metricName: string
  rowIndex: number
}

export interface UpdateMetricsGridProps {
  companyId?: string
  disableAddingColumns?: boolean
  isInDrawer?: boolean
  onPasteOutsideGrid?: (metrics: GridType) => void
  onGridChange?: (metrics: GridType) => void
  initialGrid?: GridType
  mode?: MetricsMode
  spreadsheetBuilder: MetricsSpreadsheetBuilder
  shouldDisplayDisclaimer?: boolean
}

const getMetricsFromIndexMetrics = (indexMetrics: IndexMetric[]): Metric[] => {
  if (Array.isArray(indexMetrics)) {
    return indexMetrics.map((indexMetric) => ({
      id: indexMetric.id,
      name: indexMetric.name,
      companyData: indexMetric.subject,
      receiverGroups: indexMetric.senderLinks
        ?.filter((senderLink) => senderLink.receiverGroupId)
        .map((senderLink) => ({
          id: senderLink.receiverGroupId!,
        })),
    }))
  }

  return []
}

const getFetchMetrics =
  (queryClient: QueryClient) =>
  async (metricName: string, companyId: string): Promise<Metric[]> => {
    const filters: MetricsFilters = {
      metricName,
      sortBy: MetricSortBy.NAME,
      sortDirection: SortDirection.ASC,
      subjectId: companyId,
    }

    const metricsData = await queryClient.fetchQuery({
      queryKey: metricsKeys.getMetrics(filters),
      queryFn: async ({ queryKey }) => {
        const { metricName: name, sortDirection } = queryKey[1] || {}

        const metrics = await NumbersService.getMetrics({
          page: 1,
          pageSize: METRICS_PAGE_SIZE,
          metricName: name,
          sortBy: MetricSortBy.NAME,
          sortDirection,
          subjectId: companyId,
        })

        return {
          pages: [{ data: metrics, page: 1 }],
          pageParams: [],
        }
      },
      staleTime: 10000,
    })

    return getMetricsFromIndexMetrics(metricsData.pages[0].data)
  }

const getFetchIndexMetrics =
  (queryClient: QueryClient) =>
  async (metricName: string, companyId: string): Promise<IndexMetric[]> => {
    const filters: MetricsFilters = {
      metricName,
      sortBy: MetricSortBy.NAME,
      sortDirection: SortDirection.ASC,
      subjectId: companyId,
    }

    const metricsData = await queryClient.fetchQuery({
      queryKey: metricsKeys.getMetrics(filters),
      queryFn: async ({ queryKey }) => {
        const { metricName: name, sortDirection } = queryKey[1] || {}

        const metrics = await NumbersService.getMetrics({
          page: 1,
          pageSize: METRICS_PAGE_SIZE,
          metricName: name,
          sortBy: MetricSortBy.NAME,
          sortDirection,
          subjectId: companyId,
        })

        return {
          pages: [{ data: metrics, page: 1 }],
          pageParams: [],
        }
      },
      staleTime: 10000,
    })

    return metricsData.pages.flatMap((page) => page.data)
  }

const updateGridsInitialMetrics = (
  metrics: Metric[],
  grid: GridType,
  metricCellIndex: number
) => {
  for (let rowIdx = 1; rowIdx < grid.length; rowIdx++) {
    const dropdownCell = grid[rowIdx][metricCellIndex] as MetricCell
    dropdownCell.initialMetrics = metrics
    dropdownCell.loading = false
  }
}

const updateGridsInitialHoldings = (
  holdings: BasicSubjectFragment[],
  grid: GridType,
  holdingColumnIndex: number
) => {
  for (let rowIdx = 1; rowIdx < grid.length; rowIdx++) {
    const dropdownCell = grid[rowIdx][holdingColumnIndex] as HoldingCell
    dropdownCell.initialHoldings = holdings
    dropdownCell.loading = false
  }
}
const getFiltersForInitialMetrics = (companyId: string): MetricsFilters => {
  return {
    metricName: '',
    sortBy: MetricSortBy.NAME,
    sortDirection: SortDirection.ASC,
    subjectId: companyId,
  }
}

export const useUpdateMetricsGrid = ({
  companyId,
  disableAddingColumns,
  onPasteOutsideGrid,
  onGridChange,
  initialGrid,
  mode,
  spreadsheetBuilder,
}: UpdateMetricsGridProps) => {
  const queryClient = useQueryClient()
  const eventHandler = useRef<EventHandlers>()
  const [grid, setGrid] = useState<GridType>(
    cloneDeep(initialGrid) ||
      spreadsheetBuilder.getSpreadsheet(
        spreadsheetBuilder.getMode() === MetricsSpreadsheetMode.SINGLE_HOLDING
      )
  )
  const isActingAsFounderValue = useAppSelector(isActingAsFounder)
  const isFounder = mode === MetricsMode.FOUNDER || isActingAsFounderValue

  const { loadOptions: fetchHoldings } = useSubjectSelector({
    filters: {
      type: [SubjectType.COMPANY, SubjectType.FUND, SubjectType.DEAL],
    },
    displayAvatar: true,
  })

  const fetchMetrics = useCallback(
    (metricName: string) => {
      return getFetchMetrics(queryClient)(metricName, companyId!)
    },
    [queryClient, companyId]
  )

  const fetchMetricsForCompany = useMemo(
    () => getFetchIndexMetrics(queryClient),
    [queryClient]
  )

  const updateInitialMetricsForHolding = useCallback(
    (holdingId: string, filters: MetricsFilters) => {
      fetchMetricsForCompany('', holdingId).then((fetchedMetrics) => {
        queryClient.setQueryData<InfiniteQueryData<IndexMetric>>(
          metricsKeys.getMetrics(filters),
          { pages: [{ data: fetchedMetrics, page: 1 }], pageParams: [] }
        )
        setGrid((currentGrid) => {
          const newGrid = [...currentGrid]

          for (let i = 1; i < newGrid.length; i++) {
            const holdingCell = newGrid[i][
              spreadsheetBuilder.getHoldingColumnIndex()
            ] as HoldingCell

            if (holdingCell.holding?.id === holdingId) {
              const metricCell = newGrid[i][
                spreadsheetBuilder.getMetricColumnIndex()
              ] as MetricCell
              metricCell.initialMetrics = fetchedMetrics
            }
          }

          return newGrid
        })
      })
    },
    [fetchMetricsForCompany, spreadsheetBuilder, queryClient]
  )

  const getInitialMetricsForHolding = useCallback(
    (holdingId: string): Metric[] => {
      const filters = getFiltersForInitialMetrics(holdingId)
      const initialMetrics = queryClient.getQueryData<
        InfiniteQueryData<IndexMetric>
      >(metricsKeys.getMetrics(filters))

      if (!initialMetrics) {
        updateInitialMetricsForHolding(holdingId, filters)
      }

      const metrics =
        initialMetrics?.pages.flatMap((page) =>
          getMetricsFromIndexMetrics(page.data)
        ) || []

      return metrics
    },
    [queryClient, updateInitialMetricsForHolding]
  )

  useEffect(() => {
    if (
      spreadsheetBuilder.getMode() === MetricsSpreadsheetMode.SINGLE_HOLDING
    ) {
      fetchMetrics('').then((fetchedMetrics) => {
        dispatchEvent(
          MetricsSpreadsheetEvents.INITIAL_METRICS_FETCHED,
          fetchedMetrics
        )
        setGrid((currentGrid) => {
          const newGrid = [...currentGrid]
          updateGridsInitialMetrics(
            fetchedMetrics,
            newGrid,
            spreadsheetBuilder.getMetricColumnIndex()
          )
          return newGrid
        })
      })
    }

    if (
      spreadsheetBuilder.getMode() ===
        MetricsSpreadsheetMode.MULTIPLE_HOLDINGS &&
      companyId
    ) {
      updateInitialMetricsForHolding(
        companyId,
        getFiltersForInitialMetrics(companyId)
      )
    }
  }, [
    fetchMetrics,
    spreadsheetBuilder,
    updateInitialMetricsForHolding,
    companyId,
  ])

  useEffect(() => {
    if (!isFounder) {
      fetchHoldings('').then((fetchedHoldings) => {
        dispatchEvent(INITIAL_HOLDINGS_FETCHED, fetchedHoldings)
        setGrid((currentGrid) => {
          const newGrid = [...currentGrid]
          updateGridsInitialHoldings(
            fetchedHoldings,
            newGrid,
            spreadsheetBuilder.getHoldingColumnIndex()
          )
          return newGrid
        })
      })
    }
  }, [spreadsheetBuilder, isFounder])

  const rows = useMemo(
    () => spreadsheetBuilder.getRows(grid),
    [grid, spreadsheetBuilder]
  )
  const columns = useMemo(
    () => spreadsheetBuilder.getColumns(grid),
    [grid, spreadsheetBuilder]
  )

  const handleChanges = useCallback(
    (changes: CellChange<CellType>[]) => {
      setGrid((currentGrid) => {
        const hasChangesOutsideGrid = changes.some((change) => {
          return (change.columnId as number) >= currentGrid[0].length
        })

        if (disableAddingColumns && hasChangesOutsideGrid) {
          onPasteOutsideGrid?.(
            spreadsheetBuilder.applyChangesToGrid(
              changes,
              currentGrid,
              getFetchMetrics(queryClient),
              getInitialMetricsForHolding
            )
          )
          return currentGrid
        }

        const newMetrics = spreadsheetBuilder.applyChangesToGrid(
          changes,
          currentGrid,
          getFetchMetrics(queryClient),
          getInitialMetricsForHolding
        )
        onGridChange?.(newMetrics)
        return newMetrics
      })
    },
    [
      onGridChange,
      disableAddingColumns,
      onPasteOutsideGrid,
      spreadsheetBuilder,
      queryClient,
      getInitialMetricsForHolding,
    ]
  )

  const addRow = useCallback(() => {
    setGrid((currentMetrics) => [
      ...currentMetrics,
      spreadsheetBuilder.createRow(currentMetrics),
    ])
  }, [spreadsheetBuilder])

  const addColumn = useCallback(() => {
    setGrid((currentMetrics) => {
      const newMetrics = [...currentMetrics]
      currentMetrics.forEach((row, index) => {
        const cell = spreadsheetBuilder.createColumnCell(index)
        const newRow = [...row, cell]
        newMetrics[index] = newRow
      })
      return newMetrics
    })
  }, [spreadsheetBuilder])

  const getEventHandler = useCallback((gridEventHandler) => {
    eventHandler.current = gridEventHandler
  }, [])

  useEventListener(
    METRIC_FETCHED_EVENT,
    ({ metric, metricName }: { metric?: Metric; metricName: string }) => {
      if (metric) {
        const newMetrics = [...grid]

        // Go through all of the metrics because it might be repeated
        for (let i = 1; i < newMetrics.length; i++) {
          const dropdownCell = newMetrics[i][
            spreadsheetBuilder.getMetricColumnIndex()
          ] as MetricCell

          if (dropdownCell.metric?.name === metric.name) {
            dropdownCell.metric = metric
          }
        }

        setGrid(newMetrics)
      } else {
        const newMetrics = [...grid]

        for (let i = 1; i < newMetrics.length; i++) {
          const dropdownCell = newMetrics[i][
            spreadsheetBuilder.getMetricColumnIndex()
          ] as MetricCell

          if (dropdownCell.metric?.name === metricName) {
            dropdownCell.metric = {
              id: UNKNOWN_METRIC_ID,
              name: metricName,
              toCreate: true,
            }
          }
        }

        setGrid(newMetrics)
      }
    }
  )

  useEventListener<HoldingFetchedEventType>(
    HOLDING_FETCHED_EVENT,
    ({ holding, holdingName }) => {
      const newSpreadsheet = [...grid]

      if (holding) {
        for (let i = 1; i < newSpreadsheet.length; i++) {
          const holdingDropdownCell = newSpreadsheet[i][
            spreadsheetBuilder.getHoldingColumnIndex()
          ] as HoldingCell

          if (
            holdingDropdownCell.holding?.name === holding.name &&
            (holdingDropdownCell.holding as UnknownHoldingData).fetchingHolding
          ) {
            holdingDropdownCell.holding = holding

            const metricCell = newSpreadsheet[i][
              spreadsheetBuilder.getMetricColumnIndex()
            ] as MetricCell

            if (metricCell.metric?.name) {
              const metricName = metricCell.metric.name
              fetchMetricsForCompany(metricName, holding.railsId).then(
                (metrics) => {
                  const metric = metrics.find((m) => m.name === metricName)
                  dispatchEvent<MetricForHoldingFetched>(
                    MetricsSpreadsheetEvents.METRIC_FOR_HOLDING_FETCHED,
                    {
                      metric,
                      metricName,
                      rowIndex: i,
                    }
                  )
                }
              )
            }
          }
        }
      } else {
        for (let i = 1; i < newSpreadsheet.length; i++) {
          const holdingDropdownCell = newSpreadsheet[i][
            spreadsheetBuilder.getHoldingColumnIndex()
          ] as HoldingCell
          if (holdingDropdownCell.holding?.name === holdingName) {
            const rowNumberCell = newSpreadsheet[i][0] as RowNumberCell
            rowNumberCell.errors.add(spreadsheetBuilder.getHoldingColumnIndex())
            holdingDropdownCell.error =
              'spreadsheet.metrics.errors.invalidHolding'
            holdingDropdownCell.holding = {
              id: 'invalid',
              name: holdingName,
              invalid: true,
            }
          }
        }
      }

      setGrid(newSpreadsheet)
      onGridChange?.(newSpreadsheet)
    }
  )

  useEventListener(
    MetricsSpreadsheetEvents.METRIC_FOR_HOLDING_FETCHED,
    ({ metric, metricName, rowIndex }: MetricForHoldingFetched) => {
      const newSpreadsheet = [...grid]
      const metricCell = newSpreadsheet[rowIndex][
        spreadsheetBuilder.getMetricColumnIndex()
      ] as MetricCell
      metricCell.error = undefined

      if (metric) {
        metricCell.metric = metric
      } else {
        metricCell.metric = {
          id: UNKNOWN_METRIC_ID,
          name: metricName,
          toCreate: true,
        }
      }

      setGrid(newSpreadsheet)
      onGridChange?.(newSpreadsheet)
    }
  )

  useEventListener(
    MetricsSpreadsheetEvents.PRELOADED_HOLDING_FETCHED_EVENT,
    ({ company }: { company: Holding }) => {
      const newSpreadsheet = [...grid]

      for (let i = 1; i < newSpreadsheet.length; i++) {
        const dropdownCell = newSpreadsheet[i][
          spreadsheetBuilder.getHoldingColumnIndex()
        ] as HoldingCell
        dropdownCell.holding = company
      }

      setGrid(newSpreadsheet)
      onGridChange?.(newSpreadsheet)
    }
  )

  useEventListener(
    MetricsSpreadsheetEvents.SPREADSHEET_WITH_ERRORS,
    ({ spreadsheet }: { spreadsheet: GridType }) => {
      const newSpreadsheet = [...spreadsheet]
      setGrid(newSpreadsheet)
      onGridChange?.(newSpreadsheet)
    }
  )

  return {
    fetchHoldings,
    handleChanges,
    addRow,
    addColumn,
    rows,
    columns,
    fetchMetrics,
    getEventHandler,
    eventHandler,
    fetchMetricsForCompany,
  }
}
