import Dropdown from 'ui/Dropdown'
import { BasicSubjectFragment } from 'types/graphql-schemas/graphql'
import { SubjectFilter } from 'utils/hooks/queries/subjects/useSubjectsQuery'
import { IError } from 'utils/types/common'
import useSubjectSelector from './useSubjectSelector'

interface SubjectSelectorProps {
  filters: SubjectFilter
  label?: string
  placeholder: string
  searchPlaceholder?: string
  selectOptionHandler: (option: BasicSubjectFragment) => void
  errorHandler?: () => IError | void
  displayAvatar?: boolean
  [x: string]: any
}

const SubjectSelector: React.FC<SubjectSelectorProps> = ({
  filters,
  label,
  placeholder,
  searchPlaceholder,
  resultsValidations,
  selectOptionHandler,
  errorHandler,
  displayAvatar = false,
  ...rest
}) => {
  const { loadOptions, getOption, renderOption } = useSubjectSelector({
    filters,
    resultsValidations,
    displayAvatar,
  })

  return (
    <Dropdown
      highlightEnabled
      highlightSelectedOption={false}
      label={label}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
      type="select"
      onSelectOption={(_, __, option) => {
        selectOptionHandler(option)
      }}
      loadOptions={loadOptions}
      error={errorHandler?.()}
      borderBold
      showIconOnSelectedOption={false}
      async
      searchEnabled
      showBorder
      getOption={getOption}
      addNewOptionEnabled={false}
      arrowNavigationEnabled
      formatSelectedOptionComponent={renderOption}
      {...rest}
    />
  )
}

export default SubjectSelector
