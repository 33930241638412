import CWLoader from 'components/CWLoader'
import Celeste from 'components/Celeste'
import { Helmet } from 'components/Helmet'
import { StyledShowInvestorLoading } from 'containers/InvestorManagement/ShowInvestor/ShowInvestor.styles'
import useInvestorGroup from 'containers/InvestorManagement/hooks/useInvestorGroup'
import { ProfileProvider } from 'containers/Profiles/ProfileContext'
import HeaderContent from 'containers/Profiles/components/HeaderContent/HeaderContent.v2'
import Layout from 'containers/Profiles/components/Layout'
import InvestorMainContent from 'containers/Profiles/components/MainContent/InvestorMainContent'
import { INVESTOR_ALL_UPDATES } from 'routes/constant/investorManagementRoutes'
import InvestorInformation from 'containers/Profiles/components/SideInformation/InvestorInformation'
import { ProfileType } from 'containers/Profiles/types'
import { UpdatesFiltersProvider } from 'containers/UpdatesView/UpdatesFiltersContext'
import { FormattedMessage, useIntl } from 'react-intl'
import { isActingAsInvestorGroup } from 'selectors/auth'
import { SubjectProvider } from 'utils/context/subjects/SubjectContext'
import { useSubjectQuery } from 'utils/hooks/queries/subjects/useSubjectQuery'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import useInvestorProfile from './useInvestorProfile'

const InvestorProfile = () => {
  const intl = useIntl()
  const { id: investorGroupId } = useInvestorGroup()
  const { data, isLoading: isLoadingSubject } = useSubjectQuery(investorGroupId)
  const subject = data?.subject
  const railsId = subject?.railsId

  const {
    fundsPortfolioInvestor,
    onShowFundTransaction,
    onShowInvestmentVehicleTransaction,
    onClickUpdate,
    onEditUpdate,
    isLoadingInvestor,
    isLoadingFunds,
    vehicles,
  } = useInvestorProfile({ investorGroupId: railsId || '' })

  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  if (isLoadingInvestor || isLoadingFunds || isLoadingSubject) {
    return (
      <>
        <Helmet>
          <title>
            {intl.formatMessage({ id: 'metaTitles.investorProfile' })}
          </title>
        </Helmet>
        <StyledShowInvestorLoading>
          <CWLoader />
        </StyledShowInvestorLoading>
      </>
    )
  }

  if (!isLoadingSubject && !subject) {
    return (
      <span>
        <FormattedMessage id="investorManagement.errors.fetchInvestor" />
      </span>
    )
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'metaTitles.investorProfile' })}
        </title>
      </Helmet>

      <SubjectProvider subject={subject!}>
        <UpdatesFiltersProvider>
          <ProfileProvider>
            <Layout>
              <Layout.Header type={ProfileType.INVESTOR}>
                <HeaderContent />
              </Layout.Header>

              <Layout.SideContent>
                <InvestorInformation />
              </Layout.SideContent>

              <Layout.MainContent>
                <InvestorMainContent
                  vehicles={vehicles}
                  isLoadingInvestor={isLoadingInvestor}
                  isLoadingFunds={isLoadingFunds}
                  fundsPortfolioInvestor={fundsPortfolioInvestor}
                  onShowFundTransaction={onShowFundTransaction}
                  onShowInvestmentVehicleTransaction={
                    onShowInvestmentVehicleTransaction
                  }
                  onEditUpdate={onEditUpdate}
                  onShowUpdate={onClickUpdate}
                />
              </Layout.MainContent>

              {!isInvestorGroup && (
                <Celeste
                  prefixUrl={INVESTOR_ALL_UPDATES.replace(
                    ':investorId',
                    investorGroupId
                  )}
                  showCreateTransactionOption
                />
              )}
            </Layout>
          </ProfileProvider>
        </UpdatesFiltersProvider>
      </SubjectProvider>
    </>
  )
}

export default InvestorProfile
