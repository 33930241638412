import Heading from 'components/Heading'
import styled from 'styled-components'

export const CardWrapper = styled.div`
  background: white;
  align-items: center;
  border-radius: 1rem;
  border: 0.2rem solid transparent;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  transition: transform 0.2s ease-in-out;
  width: 19.8rem;
  height: 26.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    border: 0.2rem solid var(--color-button-back-outline);
  }

  &[type='button'] {
    -webkit-appearance: none;
  }
`

export const PortfolioWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`

export const CompanyName = styled(Heading)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: var(--color-blue-strong);
  display: -webkit-box;
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 1.23rem;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  line-break: anywhere;
  width: 16.4rem;
`

export const LegendContainer = styled.p`
  color: #787e8c;
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 0.64rem;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  width: 16.4rem;

  > svg {
    color: #787e8c;
    font-size: 1.6rem;
    margin-right: 0.6rem;
  }
`

export const ImageWrapper = styled.div`
  border-radius: 100rem;
  border: 1px solid #e9e9e9;
  padding: 0.4rem;
`

export const RemoveCompanyButton = styled.button`
  background: var(--color-gray-300);
  border-radius: 10rem;
  height: 2.5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 2.5rem;

  &:focus {
    outline: none;
  }

  > svg {
    color: var(--color-white);
    font-size: 1.6rem;
    margin-top: 0.1rem;
  }
`

export const FundManagerContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 0.8rem;
  gap: 0.5rem;
  color: #787e8c;

  svg {
    font-size: 1.6rem;
  }
`

export const FundCompanyName = styled.p`
  max-width: 13rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
