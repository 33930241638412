import { useQueryClient } from '@tanstack/react-query'
import { cloneDeep } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useLocation, useParams } from 'react-router-dom'

import GroupService from 'api/GroupService'
import NumbersService from 'api/NumbersService'
import Toast from 'components/Toast'
import { isActingAsFounder } from 'selectors/auth'
import { searchMatchesGroup } from 'utils/functions/updates'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useFetchUniversity } from 'utils/hooks/useFetchUniversity'
import useMetricGroups from 'utils/hooks/useMetricGroups'
import useMetricQuery from 'utils/hooks/useMetricQuery'
import useUserData from 'utils/hooks/useUserData'
import {
  getEditValueMetricSchema,
  getSetNewValueMetricSchema,
} from 'utils/schemas/metrics'
import { MetricsMode } from 'utils/types/metrics'

import { type DropdownRef } from 'ui/Dropdown'
import { useMetricsMode } from 'utils/hooks/useMetricsMode'
import { QUERIES } from 'utils/queries/metrics'
import { Company } from 'utils/types/company'
import {
  DataPoint,
  IndexMetric,
  LinkedMetricState,
} from 'utils/types/metricsV2'
import { Group } from 'utils/types/user'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import {
  CREATE_DP_METRIC_EVENT,
  EDIT_DP_METRIC_EVENT,
} from 'utils/constants/events'
import { useGroupsQuery } from 'utils/hooks/queries/useGroupQuery'

const useAddMetricValue = (closeDrawer) => {
  const intl = useIntl()
  const [groups, setGroups] = useState<Array<Group<Company>>>([])
  const dropdownRef = useRef<DropdownRef>(null)
  const queryClient = useQueryClient()

  const { metricId } = useParams<{ metricId: string }>()
  const { metric, refetchMetric } = useMetricQuery({
    metricId,
  })
  const { fetchUniversity } = useFetchUniversity()
  const location = useLocation<{
    isEditing: boolean
    dataPoint?: DataPoint
    readOnlyMode?: boolean
  }>()
  const isEditing = location.state?.isEditing

  const { register, handleSubmit, errors, setValue, reset, formState, watch } =
    useForm({
      validationSchema: isEditing
        ? getEditValueMetricSchema()
        : getSetNewValueMetricSchema(),
      mode: 'onBlur',
    })

  const { isCurrentUserActingAsFounder, isFounderVerified } = useUserData()
  const founderUnverified = !isFounderVerified && isCurrentUserActingAsFounder

  const { data: receiverGroups } = useGroupsQuery(
    metric?.senderLinks
      ?.filter(
        (link) =>
          link.state !== LinkedMetricState.UNSHARED &&
          link.state !== LinkedMetricState.SHARE_DENIED &&
          link.state !== LinkedMetricState.REQUEST_DENIED &&
          link.receiverGroupId
      )
      .map((link) => link.receiverGroupId!)
  )

  const { handleAddGroup, handleRemoveGroup, currentGroups, loadGroups } =
    useMetricGroups(
      !isEditing && !founderUnverified ? receiverGroups : undefined
    )

  const metricsMode = useMetricsMode()
  const isFounder = metricsMode === MetricsMode.FOUNDER
  const isEditingMetricAsFounder = useAppSelector(isActingAsFounder)

  const date = watch('date')

  const getGroups = async () => {
    try {
      const groupsResponse = await GroupService.fetchUserGroups()

      setGroups(groupsResponse)
    } catch (error) {
      Toast.display(intl.messages['metrics.errorFetchingGroups'], 'error')
    }
  }

  useEffect(() => {
    if (!location.state?.dataPoint) {
      setTimeout(() => {
        setValue('date', new Date(), true)
      }, 2)
    }
    getGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const dataPoint = location.state?.dataPoint

    if (dataPoint) {
      reset({
        metricValue: dataPoint.value,
        date: dataPoint.date,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createDataPoint = async (dataPoint) => {
    const addedDataPoint = await NumbersService.addDataPoint({
      metricId,
      dataPoint,
      sharedGroupsIds: isFounder
        ? currentGroups.map((group) => group.id)
        : undefined,
    })

    if (addedDataPoint) {
      const allQueryKeys = queryClient
        .getQueryCache()
        .getAll()
        .map((query) => query.queryKey)

      const allMetricsKeys = isEditingMetricAsFounder
        ? allQueryKeys.filter(
            (key) => key.indexOf(QUERIES.FOUNDER_METRICS) !== -1
          )
        : allQueryKeys.filter((key) => key.indexOf(QUERIES.METRICS) !== -1)

      allMetricsKeys.forEach((key) => {
        queryClient.setQueryData<{
          pages: {
            data: IndexMetric[]
          }[]
        }>(key, (metrics) => {
          const updatedMetrics = cloneDeep(metrics)

          updatedMetrics?.pages?.forEach((page) => {
            const metricIndex = page.data.findIndex(
              (currentMetric) => currentMetric.id === metricId
            )
            if (metricIndex !== -1) {
              page.data[metricIndex].dataPoints.push(addedDataPoint)
            }
          })

          return updatedMetrics
        })
      })
    }

    Toast.displayIntl('metrics.toasts.successNewMetricValue', 'success')
  }

  const editDataPoint = async (dataPoint: DataPoint) => {
    NumbersService.editDataPoint({
      ...dataPoint,
      sharedGroups: isFounder
        ? [
            ...(dataPoint.sharedGroups ?? []),
            ...currentGroups.map((group) => group.id),
          ]
        : undefined,
    })

    dispatchEvent(EDIT_DP_METRIC_EVENT)
  }

  const onSubmit = async (data) => {
    const normalizedMetricValue = data.metricValue?.replace(/,/g, '')

    try {
      let dataPoint: Pick<DataPoint, 'date' | 'value'> & { id?: string } = {
        date: data.date,
        value: normalizedMetricValue,
      }
      if (location?.state?.dataPoint) {
        dataPoint = {
          ...location?.state?.dataPoint,
          id: location?.state?.dataPoint?.id,
          date: data.date,
          value: normalizedMetricValue ?? location?.state?.dataPoint?.value,
        }
      }

      if (isEditing) {
        await editDataPoint(dataPoint as DataPoint)
      } else {
        await createDataPoint(dataPoint)
      }

      refetchMetric()

      dispatchEvent(CREATE_DP_METRIC_EVENT, {
        metricId,
      })

      fetchUniversity()
      closeDrawer()
      reset()
    } catch (error) {
      Toast.display(intl.messages['editMetric.error'], 'error')
    }
  }

  const onDateChange = (currDate) => {
    setValue('date', currDate, true)
  }

  const { isValid } = formState

  const clearDropdown = () => {
    dropdownRef.current?.clear()
    dropdownRef.current?.close()
  }

  const onSelectGroup = (...params) => {
    handleAddGroup(...params)
    setTimeout(clearDropdown)
  }

  const handlePressEnter = async (inputValue, dropdownOptions) => {
    if (
      dropdownOptions.length === 1 &&
      searchMatchesGroup(dropdownOptions[0], inputValue)
    ) {
      onSelectGroup(null, null, dropdownOptions[0])
    }
  }

  return {
    metric,
    onSubmit,
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    setValue,
    onDateChange,
    isValid,
    date,
    isFounder,
    groups,
    handleAddGroup,
    handlePressEnter,
    handleRemoveGroup,
    currentGroups,
    readOnlyMode: location.state?.readOnlyMode,
    dataPoint: location.state?.dataPoint,
    isEditing: location.state?.isEditing,
    loadGroups,
    founderUnverified,
    dropdownRef,
  }
}

export default useAddMetricValue
