/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IndexMetric } from 'utils/types/metricsV2'

interface SelectedMetricsState {
  [key: string]: {
    metrics: IndexMetric[]
  }
}

const initialState: SelectedMetricsState = {}

export const selectedMetricsSlice = createSlice({
  name: 'selectedMetrics',
  initialState,
  reducers: {
    onAddMetric: (
      state,
      action: PayloadAction<{ holdingId: string; metric: IndexMetric }>
    ) => {
      const { holdingId, metric } = action.payload

      if (!state[holdingId]) {
        state[holdingId] = {
          metrics: [metric],
        }
        return
      }

      state[holdingId].metrics.push(metric)
    },

    onRemoveMetric: (
      state,
      action: PayloadAction<{ holdingId: string; metric: IndexMetric }>
    ) => {
      const { holdingId, metric } = action.payload

      state[holdingId].metrics = state[holdingId].metrics.filter(
        (metricToRemove) => metricToRemove.id !== metric.id
      )

      if (state[holdingId].metrics.length === 0) {
        delete state[holdingId]
      }
    },

    onClearHoldingMetrics: (state, action: PayloadAction<string>) => {
      const holdingId = action.payload
      delete state[holdingId]
    },
  },
})

export const { onAddMetric, onRemoveMetric, onClearHoldingMetrics } =
  selectedMetricsSlice.actions

export const getSelectedMetricsByHoldingId = (holdingId: string) => {
  return (state: RootState) => state.selectedMetrics[holdingId]?.metrics || []
}

export const getSelectedMetricsCountByHoldingId = (holdingId: string) => {
  return (state: RootState) =>
    state.selectedMetrics[holdingId]?.metrics.length || 0
}

export default selectedMetricsSlice.reducer
