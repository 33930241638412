/* eslint-disable class-methods-use-this */

import React from 'react'
import {
  type Uncertain,
  type CellTemplate,
  type Compatible,
  type UncertainCompatible,
} from '@silevis/reactgrid'
import * as yup from 'yup'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { Holding } from 'utils/types/company'
import { CELL_ERROR, CustomCell } from 'components/Spreadsheet/types'
import { DisabledCell } from 'components/Spreadsheet/CellTemplates/Template.styles'
import { SuggestionErrors } from 'components/AddHoldingModal/types'
import {
  defaultTextCellRender,
  getCompatibleTextCell,
  handleKeyDown,
} from 'components/Spreadsheet/utils'
import WebsiteCellDisplayValue from './WebsiteCellDisplayValue'
import { HoldingsSpreadsheetEvents } from '../../useAddHoldingsSpreadsheet'

type CustomError =
  | {
      type: 'INVALID_URL'
      error: string
    }
  | {
      type: 'DUPLICATE_WEBSITE'
      error: SuggestionErrors
    }

export interface WebsiteCell extends CustomCell {
  type: 'website'
  text: string
  placeholder?: string
  holding?: Holding
  customError?: CustomError
}

export class WebsiteCellTemplate implements CellTemplate<WebsiteCell> {
  update(
    currentCell: Compatible<WebsiteCell>,
    cellToMerge: UncertainCompatible<WebsiteCell>
  ): Compatible<WebsiteCell> {
    let customError: CustomError | undefined
    const newValue = cellToMerge.text
    const urlSchema = yup.string().url()

    if (!urlSchema.isValidSync(newValue)) {
      customError = { type: 'INVALID_URL', error: 'general.invalidUrl' }
    }

    if (cellToMerge.holding) {
      // Wait for cell to change after returning from this method,
      // to be sure that the auto filled values are not overwritten
      setTimeout(() => {
        dispatchEvent(
          HoldingsSpreadsheetEvents.CompanyHoldingSelectedFromWebsiteError,
          {
            holding: cellToMerge.holding,
            rowIndex: cellToMerge.rowIndex,
          }
        )
      }, 0)
    }

    return this.getCompatibleCell({
      ...currentCell,
      text: cellToMerge.text,
      customError,
      error: customError ? CELL_ERROR : '',
      placeholder: cellToMerge.placeholder || currentCell.placeholder,
    })
  }

  getCompatibleCell(
    uncertainCell: Uncertain<WebsiteCell>
  ): Compatible<WebsiteCell> {
    return {
      ...getCompatibleTextCell(uncertainCell),
      type: 'website',
      rowIndex: uncertainCell.rowIndex || 0,
    }
  }

  handleKeyDown(
    cell: Compatible<WebsiteCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<WebsiteCell>; enableEditMode: boolean } {
    const { cell: cellResult, enableEditMode } = handleKeyDown(
      cell,
      keyCode,
      ctrl,
      shift,
      alt,
      this.getCompatibleCell
    )

    return {
      cell: {
        ...cellResult,
        type: 'website',
        rowIndex: cell.rowIndex,
      },
      enableEditMode,
    }
  }

  render(
    cell: Compatible<WebsiteCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<WebsiteCell>, commit: boolean) => void
  ): React.ReactNode {
    if (cell.disabled) {
      return <DisabledCell centered={cell.centered}>-</DisabledCell>
    }

    if (!isInEditMode || cell.autoFilled) {
      return (
        <WebsiteCellDisplayValue
          cell={cell}
          onCellChanged={onCellChanged}
          getCompatibleCell={this.getCompatibleCell}
        />
      )
    }

    return defaultTextCellRender(
      cell,
      isInEditMode,
      onCellChanged,
      this.getCompatibleCell
    )
  }
}
