/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import InvestorManagementService, {
  Filters,
} from 'api/InvestorManagementService'
import { useEffect } from 'react'

import Toast from 'components/Toast'

import {
  QUERIES as investorQueries,
  investorsKeys,
} from 'utils/queries/investors'
import { portfolioKeys } from 'utils/queries/portfolios'
import { CustomUseQueryOptions } from 'utils/types/common'
import { FundSummary } from 'utils/types/funds'
import { Investor, InvestorPayload } from 'utils/types/investors'

export type InvestorFilters = Omit<Filters, 'page'>

export const useInvestorQuery = (
  investorId: string,
  queryOptions: CustomUseQueryOptions<Investor> = {}
) => {
  return useQuery(
    investorsKeys.byId(investorId),
    async () => {
      try {
        return await InvestorManagementService.fetchInvestor(investorId)
      } catch (err) {
        Toast.displayIntl('investorManagement.errors.fetchInvestor', 'error')
        return null
      }
    },
    { ...queryOptions, staleTime: 500 }
  )
}

export const useInvestorFundPortfoliosSummaryQuery = (
  investorId: string,
  investorOnlyNumbers: boolean = false,
  queryOptions: CustomUseQueryOptions<FundSummary> = {}
) => {
  const queryClient = useQueryClient()

  const fetchFundSummary = () => {
    try {
      return InvestorManagementService.fetchFundPortfoliosSummary(
        investorOnlyNumbers
      )
    } catch (err) {
      Toast.displayIntl('investorManagement.errors.fetchInvestor', 'error')
      return null
    }
  }

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: portfolioKeys.fundPortfolioSummaryByInvestor(
        investorId,
        !investorOnlyNumbers
      ),
      queryFn: fetchFundSummary,
    })
  }, [])

  return useQuery(
    portfolioKeys.fundPortfolioSummaryByInvestor(
      investorId,
      investorOnlyNumbers
    ),
    fetchFundSummary,
    { ...queryOptions, staleTime: 500 }
  )
}

export const useCreateInvestorMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (investorData: InvestorPayload) => {
      return InvestorManagementService.createInvestor(investorData)
    },
    {
      onSuccess: (newInvestor) => {
        queryClient.invalidateQueries([investorQueries.INVESTORS])
        queryClient.setQueryData(
          investorsKeys.byId(newInvestor.id),
          newInvestor
        )
      },
    }
  )
}

export const useEditInvestorMutation = (
  investorId: string,
  currentGroupId?: string
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (investorData: Partial<InvestorPayload>) => {
      return InvestorManagementService.editInvestor(
        investorId,
        investorData,
        currentGroupId
      )
    },
    {
      onSuccess: (newInvestor) => {
        queryClient.refetchQueries([investorQueries.INVESTORS])
        queryClient.setQueryData(investorsKeys.byId(investorId), newInvestor)
      },
    }
  )
}

export const useDeleteInvestorMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(InvestorManagementService.deleteInvestor, {
    onSuccess: () => queryClient.invalidateQueries([investorQueries.INVESTORS]),
  })
}
