import { useMutation } from '@apollo/client'
import Toast from 'components/Toast'
import type {
  FieldArrayRenderProps,
  FormikErrors,
  FormikHelpers,
  FormikTouched,
} from 'formik'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { getCurrentGroupId } from 'selectors/auth'
import { ValidateManyResult } from 'types/graphql-schemas/graphql'
import { convertToFormikTouched } from 'utils/forms/formik'
import { randomId } from 'utils/functions/number'
import { getCompanySubjectMutationPayload } from 'utils/gql/helpers/subject-mutation-payload-helper'
import {
  BULK_IMPORT_SUBJECTS,
  VALIDATE_BULK_IMPORT_SUBJECTS,
} from 'utils/gql/mutations/subjects'
import { GET_SUBJECTS } from 'utils/gql/queries/subjects'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import {
  getHoldingsErrorsFromDrafts,
  hasBulkImportServerErrors,
  hasFundsErrors,
  setRepeatedBulkErrors,
  validateServerErrors,
} from '../errors.v2'
import { getArraySchema } from '../schemas'
import {
  AddHoldingBulkImportForm,
  AddHoldingBulkImportFormErrors,
  AddHoldingFormErrors,
  AddHoldingFormId,
  HoldingType,
  SuggestionErrors,
} from '../types'
import { getDefaultHoldingValues } from '../utils'

export interface ImportHoldingsProps {
  onHideModal: () => void
  onCreateNewHoldings?: (holdingIds: string[]) => void
  fileName: string
  holdingsFromCSV: AddHoldingFormId[]
  initialErrors: AddHoldingBulkImportFormErrors
  initialStatus: { holdings: SuggestionErrors[] }
}

export const useImportHoldingsModal = ({
  onHideModal,
  onCreateNewHoldings,
  holdingsFromCSV,
  initialErrors,
  initialStatus,
}: ImportHoldingsProps) => {
  const intl = useIntl()
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const initialValues = useMemo(
    () => ({ holdings: holdingsFromCSV }),
    [holdingsFromCSV]
  )

  const [validateBulkImportSubjects] = useMutation(
    VALIDATE_BULK_IMPORT_SUBJECTS
  )

  const [bulkImportSubjects] = useMutation(BULK_IMPORT_SUBJECTS, {
    refetchQueries: [GET_SUBJECTS],
  })

  const initialTouched = useMemo(
    () =>
      convertToFormikTouched<AddHoldingBulkImportForm>({
        holdings: holdingsFromCSV,
      }),
    [holdingsFromCSV]
  )
  const validationSchema = useMemo(() => getArraySchema(intl), [intl])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const csvHoldingsCount = useRef(holdingsFromCSV.length)
  const lastHoldingsSentToServer = useRef(holdingsFromCSV)
  const currentStatus = useRef(initialStatus)
  const serverErrors = useRef<AddHoldingBulkImportFormErrors>(initialErrors)
  const [currentHolding, setCurrentHolding] = useState<AddHoldingFormId>(
    holdingsFromCSV[0]
  )

  const isHoldingValid = useCallback(
    (
      holding: AddHoldingFormId,
      errors?: AddHoldingFormErrors,
      touched?: FormikTouched<AddHoldingFormId>
    ) => {
      if (holding.type === HoldingType.FUND) {
        return !(errors && hasFundsErrors(errors) && touched?.funds)
      }

      return !(Object.keys(errors?.company || {}).length && touched?.company)
    },
    []
  )

  const onAddHolding = useCallback((arrayHelpers: FieldArrayRenderProps) => {
    const newHolding = {
      id: randomId(),
      ...getDefaultHoldingValues(),
    }
    arrayHelpers.push(newHolding)
    setCurrentHolding(newHolding)
    currentStatus.current.holdings.push({})
    serverErrors.current.holdings.push({
      company: {},
      funds: {
        funds: [],
      },
    })
  }, [])

  const deleteHoldingErrors = useCallback((holdingIndex: number) => {
    serverErrors.current = {
      holdings: serverErrors.current.holdings.filter(
        (_, index) => index !== holdingIndex
      ),
    }
  }, [])

  const onDeleteHolding = useCallback(
    (
      values: AddHoldingBulkImportForm,
      arrayHelpers: FieldArrayRenderProps,
      validateForm: (
        values?: AddHoldingBulkImportForm
      ) => Promise<FormikErrors<AddHoldingBulkImportForm>>,
      setStatus: (status?: any) => void
    ) => {
      const currentHoldingIndex = values.holdings?.findIndex?.(
        (holding) => holding.id === currentHolding?.id
      )
      const newIndex = currentHoldingIndex === 0 ? 1 : currentHoldingIndex - 1
      const newSelectedHolding = values.holdings[newIndex]

      deleteHoldingErrors(currentHoldingIndex)
      lastHoldingsSentToServer.current =
        lastHoldingsSentToServer.current.filter(
          (_, index) => index !== currentHoldingIndex
        )
      currentStatus.current = {
        holdings: currentStatus.current.holdings.filter(
          (_, index) => index !== currentHoldingIndex
        ),
      }
      setStatus(currentStatus.current)

      setCurrentHolding(newSelectedHolding)
      arrayHelpers.remove(currentHoldingIndex)
      validateForm()
    },
    [currentHolding?.id, deleteHoldingErrors]
  )

  const validate = useCallback(
    (values: AddHoldingBulkImportForm) => {
      const errors = cloneDeep(serverErrors.current)

      values.holdings.forEach((holding, index) => {
        if (
          lastHoldingsSentToServer.current[index] &&
          errors.holdings?.[index]
        ) {
          validateServerErrors(
            errors.holdings?.[index],
            holding,
            lastHoldingsSentToServer.current[index]
          )
        }
      })

      setRepeatedBulkErrors(values.holdings, errors, intl)

      return hasBulkImportServerErrors(errors, values.holdings) ? errors : {}
    },
    [lastHoldingsSentToServer, serverErrors, intl]
  )

  const onSubmit = useCallback(
    async (
      values: AddHoldingBulkImportForm,
      formikHelpers: FormikHelpers<AddHoldingBulkImportForm>
    ) => {
      try {
        setIsSubmitting(true)
        lastHoldingsSentToServer.current = cloneDeep(values.holdings)

        const bulkImportValidationPayload = {
          data: values.holdings.map((holding) => {
            if (holding.type === HoldingType.COMPANY) {
              return {
                type: 'company',
                name: holding.company?.name,
                groupOwned: false,
                attributes: [
                  {
                    name: 'website',
                    type: 'string',
                    value: holding.company?.website,
                  },
                  {
                    name: 'pointOfContact',
                    type: 'string',
                    value: holding.company?.pointOfContact,
                  },
                  {
                    name: 'legalEntityName',
                    type: 'string',
                    value: holding.company?.legalEntityName,
                  },
                ],
                permissions: [
                  { entityId: currentGroupId, read: true, write: true },
                ],
              }
            }
            return {
              type: 'fund',
              names: holding.funds?.funds.filter((fundName) => !!fundName),
              groupOwned: false,
              parentSubjectData: holding.funds?.includeFundManager
                ? getCompanySubjectMutationPayload({
                    name: holding.funds?.fundManager?.name!,
                    website: holding.funds?.fundManager?.website,
                    pointOfContact: holding.funds?.fundManager?.pointOfContact,
                    isFundCompany: true,
                    groupOwned: false,
                    permissions: [
                      { entityId: currentGroupId, read: true, write: true },
                    ],
                  })
                : undefined,
              permissions: [
                { entityId: currentGroupId, read: true, write: true },
              ],
            }
          }),
        }

        const results = await validateBulkImportSubjects({
          variables: bulkImportValidationPayload,
        })

        const validatedHoldings = results.data?.validateBulkImportSubjects!
        const { errors, status } = await getHoldingsErrorsFromDrafts(
          validatedHoldings,
          values.holdings
        )

        const hasErrors = hasBulkImportServerErrors(errors, values.holdings)
        if (hasErrors) {
          serverErrors.current = errors
          formikHelpers.setStatus(status)
        } else {
          const bulkImportPayload = {
            data: bulkImportValidationPayload.data.map(
              (holdingPayload, index) => {
                if (holdingPayload.type === 'fund') {
                  const fundValidationResult = validatedHoldings[
                    index
                  ] as ValidateManyResult

                  return {
                    ...holdingPayload,
                    groupOwned:
                      !!fundValidationResult.parentSubject?.groupOwned,
                  }
                }

                return holdingPayload
              }
            ),
          }

          const result = await bulkImportSubjects({
            variables: bulkImportPayload,
          })

          const holdingsIds = result.data!.bulkImportSubjects.map(
            (subject) => subject.id
          )
          Toast.displayIntl('addHolding.bulkImportSuccess', 'success')
          onCreateNewHoldings?.(holdingsIds)
          onHideModal()
        }
      } catch (error) {
        Toast.displayIntl('addHolding.bulkImportError', 'error')
      } finally {
        setIsSubmitting(false)
      }
    },
    [
      bulkImportSubjects,
      currentGroupId,
      onCreateNewHoldings,
      onHideModal,
      validateBulkImportSubjects,
    ]
  )

  return {
    initialValues,
    initialTouched,
    validationSchema,
    validate,
    onSubmit,
    csvHoldingsCount: csvHoldingsCount.current,
    currentHolding,
    setCurrentHolding,
    onAddHolding,
    onDeleteHolding,
    isHoldingValid,
    isSubmitting,
  }
}
