import { useMutation } from '@apollo/client'
import { useQueryClient } from '@tanstack/react-query'
import Toast from 'components/Toast'
import { useState } from 'react'
import type { IntlShape } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { getCurrentGroupId } from 'selectors/auth'
import {
  BasicSubjectFragment,
  CreateSubjectDto,
} from 'types/graphql-schemas/graphql'
import { isTypeIdentifierDuplicatedError } from 'utils/functions/errors'
import { getInvestmentVehicleSubjectPayload } from 'utils/functions/investorManagement'
import { CREATE_SUBJECT_FROM_TYPE } from 'utils/gql/mutations/subjects'
import { GET_COUNT_SUBJECTS } from 'utils/gql/queries/subjects'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { subjectsKeys } from 'utils/queries/subjects'
import { InvestmentVehicleFormValues } from 'utils/types/investors'
import { SubjectType } from 'utils/types/subjects'

export type InvestmentVehicleErrors = {
  [key in keyof InvestmentVehicleFormValues]?: string
}

const useCreateInvestmentVehicle = (
  onCloseDrawer: () => void,
  intl: IntlShape
) => {
  const location = useLocation<{ investor?: BasicSubjectFragment }>()
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const investor = location.state?.investor
  const history = useHistory()
  const queryClient = useQueryClient()

  const [customError, setCustomError] = useState<InvestmentVehicleErrors>({
    name: '',
  })

  const [createSubjectFromType, { loading: isLoading }] = useMutation(
    CREATE_SUBJECT_FROM_TYPE,
    {
      refetchQueries: [GET_COUNT_SUBJECTS],
    }
  )

  const createInvestmentVehicle = async (
    values: InvestmentVehicleFormValues
  ) => {
    const payload = getInvestmentVehicleSubjectPayload<CreateSubjectDto>({
      currentGroupId,
      investmentVehicleData: values,
    })

    try {
      await createSubjectFromType(payload)

      Toast.displayIntl(
        [
          'investorManagement.success.createdInvestmentVehicle',
          { vehicleName: payload.variables.data.name },
        ],
        'success'
      )

      queryClient.invalidateQueries(
        subjectsKeys.byFilters({
          filters: { type: SubjectType.INVESTMENT_VEHICLE },
        })
      )

      onCloseDrawer()
    } catch (error) {
      if (isTypeIdentifierDuplicatedError(error)) {
        setCustomError((prev) => {
          return {
            ...prev,
            name: intl.formatMessage({
              id: 'investorManagement.vehicleErrors.vehicleNameTaken',
            }),
          }
        })
        return
      }
      Toast.displayIntl(
        'investorManagement.errors.createInvestmentVehicle',
        'error'
      )
    }
  }

  const onCancel = () => {
    onCloseDrawer()
  }

  const onCreateNewInvestor = (investorName: string) => {
    history.replace('/investor-management/investors/create', {
      initialInvestorName: investorName,
    })
  }

  const initialData: InvestmentVehicleFormValues = {
    name: investor?.name ?? '',
    investor: investor ?? null,
    shareInvestorData: true,
    locations: [],
  }

  return {
    createInvestmentVehicle,
    onCancel,
    onCreateNewInvestor,
    initialData,
    customError,
    isLoading,
  }
}

export default useCreateInvestmentVehicle
