import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import InitialLink from 'components/Breadcrumb/Components/InitialLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EntityLink from 'components/Breadcrumb/Components/EntityLink'
import UpdateLink from 'components/Breadcrumb/Components/UpdateLink'
import {
  COMPANIES_CHAT,
  COMPANIES_CHAT_EDIT,
  COMPANIES_INVESTMENTS,
  COMPANIES_METRICS,
  COMPANIES_METRICS_EDIT,
  COMPANIES_UPDATES,
  COMPANIES_UPDATES_EDIT,
} from 'routes/constant/companiesRoutes'
import {
  FUNDS_CHAT,
  FUNDS_CHAT_EDIT,
  FUNDS_METRICS,
  FUNDS_METRICS_EDIT,
  FUNDS_UPDATES,
  FUNDS_UPDATES_EDIT,
} from 'routes/constant/fundManagerRoutes'
import {
  ArrowWrapper,
  NavigationWrapper,
} from 'components/Breadcrumb/Breadcrumb.styles'
import { Update } from 'utils/types/update'
import { Nullable } from 'utils/types/common'
import { URL } from 'reducers/breadcrumbSlice'
import { BreadcrumbEntity } from '../useEntityInformation'

interface Props {
  update?: Update
  entityToShow?: BreadcrumbEntity
  url: URL
  initialLinkRedirect: string
  isCreatingNewUpdate: boolean
  getAvatar: () => Nullable<JSX.Element>
  isShowingCompany: boolean
}

const DiscoverBreadcrumb = ({
  update,
  entityToShow,
  url,
  initialLinkRedirect,
  isCreatingNewUpdate,
  getAvatar,
  isShowingCompany,
}: Props) => {
  const intl = useIntl()
  const history = useHistory()

  if (!entityToShow) {
    return null
  }

  const companiesActiveTabs = [
    COMPANIES_UPDATES.replace(':companyId', entityToShow.id),
    COMPANIES_INVESTMENTS.replace(':companyId', entityToShow.id),
    COMPANIES_METRICS.replace(':companyId', entityToShow.id),
    COMPANIES_CHAT.replace(':companyId', entityToShow.id),
    COMPANIES_UPDATES_EDIT.replace(':companyId', entityToShow.id),
    COMPANIES_METRICS_EDIT.replace(':companyId', entityToShow.id),
    COMPANIES_CHAT_EDIT.replace(':companyId', entityToShow.id),
  ]

  const fundsActiveTabs = [
    FUNDS_UPDATES.replace(':fundId', entityToShow.id),
    FUNDS_METRICS.replace(':fundId', entityToShow.id),
    FUNDS_CHAT.replace(':fundId', entityToShow.id),
    FUNDS_UPDATES_EDIT.replace(':fundId', entityToShow.id),
    FUNDS_METRICS_EDIT.replace(':fundId', entityToShow.id),
    FUNDS_CHAT_EDIT.replace(':fundId', entityToShow.id),
  ]

  return (
    <NavigationWrapper>
      <>
        <InitialLink
          isActive={false}
          label={intl.formatMessage({ id: 'breadcrumb.discover' })}
          onClickHandler={() => history.push(initialLinkRedirect)}
        />

        <ArrowWrapper>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </ArrowWrapper>
      </>

      {entityToShow && (
        <>
          <EntityLink
            isActive={
              isShowingCompany
                ? companiesActiveTabs.some((tab) => url.current.includes(tab))
                : fundsActiveTabs.some((tab) => url.current.includes(tab))
            }
            label={entityToShow.name}
            entityLogo={getAvatar()}
            onClickHandler={() => {
              const entityUrl = isShowingCompany
                ? COMPANIES_UPDATES.replace(':companyId', entityToShow.id)
                : FUNDS_UPDATES.replace(':fundId', entityToShow.id)

              if (url.current !== entityUrl) {
                history.push(entityUrl)
              }
            }}
          />

          {(update || isCreatingNewUpdate) && (
            <ArrowWrapper>
              <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </ArrowWrapper>
          )}
        </>
      )}

      <UpdateLink update={update} isCreatingNewUpdate={isCreatingNewUpdate} />
    </NavigationWrapper>
  )
}

export default DiscoverBreadcrumb
