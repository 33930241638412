import Avatar from 'components/Avatar'
import { getInitials } from 'utils/functions/user'
import { useSubjectQuery } from 'utils/hooks/queries/subjects/useSubjectQuery'
import useParseCurrentUrl from '../useParseCurrentUrl'

interface VehicleInformationProps {
  fetchInvestmentVehicle?: boolean
}

const useVehicleInformation = ({
  fetchInvestmentVehicle,
}: VehicleInformationProps) => {
  const { investmentVehicleId } = useParseCurrentUrl()

  const { data } = useSubjectQuery(investmentVehicleId, {
    skip: !(investmentVehicleId && fetchInvestmentVehicle),
  })

  const investmentVehicle = data?.subject
    ? {
        id: data.subject.id,
        name: data.subject.name,
        logo: data.subject.logo ?? data.subject.parentSubject?.logo,
        type: data.subject.type,
      }
    : undefined

  const getVehicleAvatar = () => {
    if (!investmentVehicle) return null

    if (!investmentVehicle.logo) {
      return (
        <Avatar
          initials={getInitials(investmentVehicle.name)}
          avatarStyle="avatarCircleXS"
        />
      )
    }

    return (
      <Avatar image={investmentVehicle.logo} avatarStyle="avatarCircleXS" />
    )
  }

  return {
    investmentVehicle,
    getVehicleAvatar,
  }
}

export default useVehicleInformation
