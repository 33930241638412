import { BasicSubjectFragment } from 'types/graphql-schemas/graphql'
import { getSubjectFinancials } from 'utils/context/subjects/hooks/useSubjectFinancials'
import { getSubjectSocials } from 'utils/context/subjects/hooks/useSubjectSocials'
import { getAttribute } from 'utils/gql/helpers/subjects'
import { Employee, Industry } from 'utils/types/company'
import { GooglePlaceData } from 'utils/types/locations'

export const getInvestmentVehicleSubjectData = (
  subject: BasicSubjectFragment
) => {
  // TODO: add type?
  // ): InvestmentVehicle => {
  const financial = getSubjectFinancials(subject)
  const socials = getSubjectSocials(subject)

  const id = subject?.id || ''
  const address =
    getAttribute<GooglePlaceData[]>(subject, 'locations')?.value?.[0]
      ?.formattedAddress || ''
  const emails = getAttribute<string[]>(subject, 'emails')?.value || []
  const description = getAttribute<string>(subject, 'description')?.value || ''
  const employees = getAttribute<Employee[]>(subject, 'employees')?.value || []
  const foundedDate = getAttribute<Date>(subject, 'foundedDate')?.value
  const industries =
    getAttribute<Industry[]>(subject, 'industries')?.value || []
  const legalName =
    getAttribute<string>(subject, 'legalEntityName')?.value || ''
  const locations = getAttribute<GooglePlaceData[]>(subject, 'locations')
  const logo = subject?.logo || ''
  const name = subject?.name || ''
  const phone = getAttribute<string>(subject, 'phone')?.value || ''
  const teamSize = getAttribute<number>(subject, 'employeesCount')?.value || 0
  const useInvestorInformation =
    getAttribute<boolean>(subject, 'useInvestorInformation')?.value || false
  const investorGroup = subject?.parentSubject

  return {
    id,
    address,
    emails,
    employees,
    description,
    foundedDate,
    industries,
    legalName,
    locations,
    logo,
    name,
    phone,
    railsId: subject?.railsId,
    teamSize,
    investorGroup,
    useInvestorInformation,
    ...financial,
    ...socials,
  }
}

export const useInvestmentVehicleSubjectData = (
  subjectEntity: BasicSubjectFragment
) => {
  return getInvestmentVehicleSubjectData(subjectEntity)
}

export type InvestmentVehicleSubjectData = ReturnType<
  typeof getInvestmentVehicleSubjectData
>
