import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import NumbersService from 'api/NumbersService'
import Toast from 'components/Toast'
import { AddToExistingMetricFormValues } from 'containers/Metrics/AddMetricToExistingMetric/useAddToExistingMetric'
import { CreateFounderMetricFormType } from 'containers/Metrics/CreateMetric/CreateFounderMetric/useCreateFounderMetric'
import { CreateInvestorMetricFormType } from 'containers/Metrics/CreateMetric/CreateInvestorMetric/useCreateInvestorMetric'
import { EditFounderMetricFormType } from 'containers/Metrics/EditMetric/EditFounderMetric/useEditFounderMetric'
import { EditInvestorMetricFormType } from 'containers/Metrics/EditMetric/EditInvestorMetric/useEditInvestorMetric'
import { getCurrentGroupId, getUserId } from 'selectors/auth'
import { getCurrentCompany } from 'selectors/company'
import { SortDirection } from 'utils/constants'
import { CREATED_METRIC_EVENT } from 'utils/constants/events'
import { MetricFrequency, METRICS_PAGE_SIZE } from 'utils/constants/metrics'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { companyKeys } from 'utils/queries/companies'
import { groupKeys } from 'utils/queries/groups'
import { MetricsFilters, metricsKeys } from 'utils/queries/metrics'
import {
  IndexMetric,
  LinkedMetric,
  LinkedMetricState,
  Metric,
} from 'utils/types/metricsV2'
import { UserGroup } from 'utils/types/user'

import { useInvalidateMetricsInvitationsQueries } from '../metrics/useMetricsInvitationsQueries'
import { useAppSelector } from '../reduxToolkit'
import { useFetchUniversity } from '../useFetchUniversity'
import { useObserveQuery } from '../useObserveQuery'
import { useLinkedMetric } from './useLinkedMetric'

const getFounderMetricErrorMessage = (error: any, type: 'create' | 'edit') => {
  const defaultErrorMessage =
    type === 'create' ? 'metrics.errorCreatingMetric' : 'editMetric.error'
  try {
    const parsed = JSON.parse(error.message)

    if (
      parsed['receiver_linked_metrics.sender_metric']?.[0] ===
      'company_datum.not_verified'
    ) {
      return 'metrics.toasts.errorMetricCompanyNotVerified'
    }

    return defaultErrorMessage
  } catch {
    return defaultErrorMessage
  }
}

interface CreateFounderMetricProps {
  closeDrawer: () => void
  onSuccess?: (metric: IndexMetric) => Promise<void>
}

export const useCreateFounderMetricQuery = ({
  closeDrawer,
  onSuccess,
}: CreateFounderMetricProps) => {
  const currentCompany = useAppSelector(getCurrentCompany)

  const { invalidateInvitationsQueries } =
    useInvalidateMetricsInvitationsQueries()
  const queryClient = useQueryClient()

  return useMutation(
    async (values: CreateFounderMetricFormType) => {
      const metrics = (await NumbersService.createMetric({
        ...values,
        companiesOrPortfolios: [
          {
            id: currentCompany?.id,
            classType: currentCompany?.classType,
            groupId: currentCompany?.groupId,
            name: currentCompany?.name,
          },
        ],
      })) as any

      return metrics[0]
    },
    {
      onSuccess: async (metric: IndexMetric) => {
        closeDrawer()
        invalidateInvitationsQueries()
        queryClient.invalidateQueries(
          companyKeys.companyMetrics(metric.subject.id!)
        )

        dispatchEvent(CREATED_METRIC_EVENT)

        queryClient.invalidateQueries(metricsKeys.getMetrics())
        await onSuccess?.(metric)

        Toast.displayIntl([
          'metrics.toasts.successCreatingMetric',
          { name: metric.name },
        ])
      },
      onError: (error) => {
        Toast.displayIntl(
          getFounderMetricErrorMessage(error, 'create'),
          'error'
        )
      },
    }
  )
}

interface EditFounderMetricProps {
  metric?: Metric
  groupIdsToAdd: string[]
  groupIdsToRemove: string[]
  closeDrawer: () => void
}

export const useEditFounderMetricQuery = ({
  metric,
  groupIdsToAdd,
  groupIdsToRemove,
  closeDrawer,
}: EditFounderMetricProps) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (values: EditFounderMetricFormType) => {
      if (metric) {
        const newMetric = {
          name: values.name,
          frequency: values.frequency as MetricFrequency,
        }

        const result = await NumbersService.updateMetric(metric.id, newMetric)

        await Promise.all(
          groupIdsToAdd.map((groupId) =>
            NumbersService.shareMetric({
              senderMetricId: result.id,
              receiverGroupId: groupId,
            })
          )
        )

        await Promise.all(
          groupIdsToRemove.map((groupId) =>
            NumbersService.processALinkedMetric({
              state: LinkedMetricState.UNSHARED,
              id: metric.senderLinks?.find(
                (link) => link.receiverGroupId === groupId
              )?.id!,
            })
          )
        )

        return result
      }

      return undefined
    },
    {
      onSuccess: (editedMetric: Metric) => {
        queryClient.invalidateQueries(metricsKeys.getMetric(metric?.id))
        queryClient.invalidateQueries(metricsKeys.getMetrics())

        closeDrawer()
        Toast.displayIntl(
          ['metrics.toasts.successEditingMetric', { name: editedMetric?.name }],
          'success'
        )
      },
      onError: (error) => {
        Toast.displayIntl(getFounderMetricErrorMessage(error, 'edit'), 'error')
      },
    }
  )
}

export const useCreateInvestorMetricQuery = ({
  closeDrawer,
  isCreatingMetricFromSharedMetric,
  linkedMetric,
}: {
  closeDrawer: () => void
  isCreatingMetricFromSharedMetric: boolean
  linkedMetric?: LinkedMetric
}) => {
  const queryClient = useQueryClient()
  const { fetchUniversity } = useFetchUniversity()

  return useMutation(
    async (values: CreateInvestorMetricFormType) => {
      const result = (await NumbersService.createMetric({ ...values })) ?? []

      if (isCreatingMetricFromSharedMetric) {
        const metric = result[0]

        await NumbersService.processALinkedMetric({
          id: linkedMetric?.id!,
          state: LinkedMetricState.SHARE_ACCEPTED,
          metricId: metric.id,
          groupId: linkedMetric?.receiverGroupId!,
        })
      }

      const metrizableIds = result?.map((metric) => metric.subject.id!)

      return {
        name: values.name,
        metrizableIds: metrizableIds || [],
      }
    },
    {
      onSuccess: (metricData) => {
        metricData.metrizableIds.forEach((metrizableId) => {
          queryClient.invalidateQueries(
            companyKeys.companyMetrics(metrizableId)
          )
        })

        queryClient.invalidateQueries(metricsKeys.getMetrics())

        if (isCreatingMetricFromSharedMetric) {
          queryClient.invalidateQueries(metricsKeys.getPendingInvitations())
          queryClient.invalidateQueries(metricsKeys.getInvitationsHistory())
        }

        closeDrawer()
        fetchUniversity()
        Toast.displayIntl(
          [
            'metrics.toasts.successCreatingMetric',
            {
              name: metricData.name,
            },
          ],
          'success'
        )
      },
      onError: () => {
        Toast.displayIntl('metrics.errorCreatingMetric', 'error')
      },
    }
  )
}

export const useEditInvestorMetricQuery = ({
  closeDrawer,
  metricId,
}: {
  closeDrawer: () => void
  metricId: string
}) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (values: EditInvestorMetricFormType) => {
      const metricEdited = {
        name: values.name,
        frequency: values.frequency as MetricFrequency,
      }

      const result = await NumbersService.updateMetric(metricId, metricEdited)
      return result
    },
    {
      onError: () => {
        Toast.displayIntl('editMetric.error', 'error')
      },
      onSuccess: (editedMetric) => {
        queryClient.invalidateQueries(metricsKeys.getMetric(metricId))
        queryClient.invalidateQueries(metricsKeys.getMetrics())

        closeDrawer()
        Toast.displayIntl(
          ['metrics.toasts.successEditingMetric', { name: editedMetric.name }],
          'success'
        )
      },
    }
  )
}

export const useAddToExistingMetricQuery = ({
  closeDrawer,
  linkedMetricId,
}: {
  closeDrawer: () => void
  linkedMetricId: string
}) => {
  const { mutateAsync } = useLinkedMetric(linkedMetricId)

  return useMutation(
    async (values: AddToExistingMetricFormValues) => {
      await mutateAsync({
        metricId: values.id!,
        newState: LinkedMetricState.SHARE_ACCEPTED,
      })
    },
    {
      onSuccess: () => {
        closeDrawer()
        Toast.displayIntl('metrics.metricLinkedSuccess', 'success')
      },
      onError: () => {
        Toast.displayIntl('metrics.errorLinkingMetric', 'error')
      },
    }
  )
}

interface SettingsUseMetricsQuery {
  includeLinkedMetrics?: boolean
}

/**
 * @param filters
 * @param queryOptions
 * @param selectedMetricsRef This is used for when we invalidate the selected metrics query, the ref is needed because it persists the real state of the selected metrics
 * @param settings includeLinkedMetrics: boolean
 */
export const useMetricsQuery = ({
  filters = {},
  queryOptions = {},
  selectedMetricsRef,
  settings,
  setInfiniteScrollEnabled,
}: {
  filters?: MetricsFilters
  queryOptions?: any
  selectedMetricsRef?: Record<string, Date>
  settings?: SettingsUseMetricsQuery
  setInfiniteScrollEnabled?: (enabled: boolean) => void
}) => {
  const {
    data: metrics,
    fetchNextPage: fetchNextMetricsPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery(
    metricsKeys.getMetrics(filters),
    async ({ pageParam = 1, queryKey }) => {
      const { metricName, sortDirection }: MetricsFilters = (queryKey[1] ||
        {}) as MetricsFilters

      const metricIds = selectedMetricsRef
        ? Object.keys(selectedMetricsRef)
        : filters.metricIds

      const metricsData = await NumbersService.getMetrics({
        page: pageParam,
        metricName,
        sortDirection: sortDirection || SortDirection.ASC,
        metricIds,
        subjectId: filters.subjectId,
        holding: filters.holding,
        sortBy: filters.sortBy,
        includeLinkedMetrics: settings?.includeLinkedMetrics,
      })

      const isScrollEnabled = metricsData.length === METRICS_PAGE_SIZE
      setInfiniteScrollEnabled?.(isScrollEnabled)

      return { data: metricsData, page: pageParam }
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.length === 0) {
          return lastPage.page
        }

        return (lastPage?.page ?? 0) + 1
      },
      ...queryOptions,
    }
  )

  return {
    isLoading,
    metrics,
    fetchNextMetricsPage,
    refetch,
    isFetchingNextPage,
  }
}

export const useSelectedMetricsQuery = (
  filters: MetricsFilters = {},
  queryOptions: any = {},
  selectedMetricIdsRef?: Record<string, Date>
) => {
  return useMetricsQuery({
    filters: {
      ...filters,
      selected: true,
    },
    queryOptions,
    selectedMetricsRef: selectedMetricIdsRef,
    settings: {
      includeLinkedMetrics: false,
    },
  })
}

export const useSelectedMetricsCount = () => {
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const currentUserId = useAppSelector(getUserId)

  const { data: userGroup } = useObserveQuery<UserGroup | undefined>(
    groupKeys.userGroup(currentUserId, currentGroupId, true)
  )

  return userGroup?.settings.metrics.selectedMetrics.length || 0
}
