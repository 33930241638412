import { BasicSubjectFragment } from 'types/graphql-schemas/graphql'
import { getAttribute } from 'utils/gql/helpers/subjects'
import { SubjectType } from 'utils/types/subjects'

export const useHoldingSubjectData = (subject: BasicSubjectFragment) => {
  const isFund = subject?.type === SubjectType.FUND
  const isDeal = subject?.type === SubjectType.DEAL
  const isCompany = subject?.type === SubjectType.COMPANY
  const isVehicle = subject?.type === SubjectType.INVESTMENT_VEHICLE

  const isQualityAssured = !!getAttribute<boolean>(subject, 'qualityAssured')
    ?.value
  const isPrivate = !!getAttribute<boolean>(subject, 'private')?.value
  const isVerified = !!getAttribute<boolean>(subject, 'verified')?.value

  return {
    name: subject?.name,
    logo: subject?.logo,
    railsId: subject?.railsId,
    isFund,
    isDeal,
    isCompany,
    isQualityAssured,
    isPrivate,
    isVerified,
    isVehicle,
  }
}
