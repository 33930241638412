import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EntityLink from 'components/Breadcrumb/Components/EntityLink'
import UpdateLink from 'components/Breadcrumb/Components/UpdateLink'
import {
  PREFIX_FUND_HOLDINGS_COMPANIES,
  PREFIX_FUND_HOLDINGS_FUNDS,
} from 'routes/constant/InvestorGroupRoutes'
import { COMPANIES } from 'routes/constant/companiesRoutes'
import { FUNDS } from 'routes/constant/fundManagerRoutes'
import {
  ArrowWrapper,
  NavigationWrapper,
} from 'components/Breadcrumb/Breadcrumb.styles'
import { Update } from 'utils/types/update'
import { Nullable } from 'utils/types/common'
import {
  FounderRoutes,
  setRouteConfiguration,
  URL,
} from 'reducers/breadcrumbSlice'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { BreadcrumbEntity } from '../useEntityInformation'

interface Props {
  update?: Update
  entityToShow?: BreadcrumbEntity
  url: URL
  isShowingCompany: boolean
  isCreatingNewUpdate: boolean
  isInvestor: boolean
  isFounder: boolean
  getAvatar: () => Nullable<JSX.Element>
}

const NewTabBreadcrumb = ({
  update,
  entityToShow,
  url,
  isShowingCompany,
  isCreatingNewUpdate,
  isInvestor,
  isFounder,
  getAvatar,
}: Props) => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const companyUrl = isInvestor ? PREFIX_FUND_HOLDINGS_COMPANIES : COMPANIES
  const fundUrl = isInvestor ? PREFIX_FUND_HOLDINGS_FUNDS : FUNDS

  if (!entityToShow) {
    return null
  }

  const activeTabs = [
    `${companyUrl}/${entityToShow.id}/updates`,
    `${fundUrl}/${entityToShow.id}/updates`,
    `${companyUrl}/${entityToShow.id}/investments`,
    `${fundUrl}/${entityToShow.id}/investments`,
  ]

  return (
    <NavigationWrapper>
      <>
        <EntityLink
          isActive={activeTabs.some((tab) => tab === url.current)}
          label={entityToShow.name}
          entityLogo={getAvatar()}
          onClickHandler={() => {
            let entityUrl = isShowingCompany
              ? `${companyUrl}/${entityToShow.id}/updates`
              : `${fundUrl}/${entityToShow.id}/updates`

            if (isFounder) {
              entityUrl = '/dashboard/updates'
              dispatch(
                setRouteConfiguration({
                  route: FounderRoutes.dashboard,
                  initialLinkRedirect: `/dashboard/updates`,
                })
              )
            }

            if (url.current !== entityUrl) {
              history.push(entityUrl)
            }
          }}
        />

        {update && (
          <ArrowWrapper>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
          </ArrowWrapper>
        )}
      </>

      <UpdateLink update={update} isCreatingNewUpdate={isCreatingNewUpdate} />
    </NavigationWrapper>
  )
}

export default NewTabBreadcrumb
