import type { CellChange, Column, EventHandlers, Row } from '@silevis/reactgrid'
import { useIntl } from 'react-intl'
import type { IntlShape } from 'react-intl'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useEventListener, dispatchEvent } from 'utils/hooks/useEventListener'
import { StaticDropdownCell } from 'components/Spreadsheet/CellTemplates/CustomStaticDropdownCellTemplate'
import { BooleanDropdownCell } from 'components/Spreadsheet/CellTemplates/BooleanDropdownCellTemplate'
import { CellErrorTooltipColor } from 'components/Spreadsheet/CellTemplates/components/CellErrorTooltip/CellErrorTooltip'
import {
  CalculationBasisTypes,
  DividendTypes,
  TransactionTypes,
} from 'utils/constants/transactionTypes'
import { getNormalizedTransactionInstrument } from 'utils/functions/transactions'
import { TransactionInstruments } from 'utils/constants/transactionInstruments'
import {
  CustomCell,
  CustomDateCell,
  CustomNumberCell,
  CustomTextCell,
  RowNumberCell,
} from 'components/Spreadsheet/types'
import useSubjectSelector from 'components/Selectors/SubjectSelector/useSubjectSelector'
import { SubjectType } from 'utils/types/subjects'
import { assertUnreachable } from 'utils/functions/utils'
import {
  BaseColumn,
  BooleanColumn,
  DEFAULT_CELL_HEIGHT,
  DateColumn,
  NumberColumn,
  OptionColumn,
  PercentageColumn,
  RowNumberColumn,
  SpreadsheetBorders,
  TextColumn,
  getDateCell,
  getNumberCell,
  getStaticDropdownCell,
} from 'components/Spreadsheet/utils'
import {
  HoldingInfo,
  HoldingCell,
  HOLDING_FETCHED_EVENT,
  HoldingFetchedEventType,
} from './CustomHoldingCellTemplate'
import { getCellHeightFromAmountOfLines } from '../../UpdateMetricsGrid/utils/CellHeightCalculator'

/**
 * Default cell (and column) width
 */
export const CELL_WIDTH = 165

export const INITIAL_HOLDINGS_FETCHED = 'INITIAL_HOLDINGS_FETCHED'
export const DRAW_AGAINST_COMMITMENT_ERROR = 'DRAW_AGAINST_COMMITMENT_ERROR'

type HoldingColumn = BaseColumn & {
  type: 'holding'
}

export type ColumnType =
  | NumberColumn
  | PercentageColumn
  | TextColumn
  | DateColumn
  | OptionColumn
  | BooleanColumn
  | HoldingColumn
  | RowNumberColumn

type ColumnNames =
  | 'RowNumber'
  | 'Title'
  | 'Holding'
  | 'Date'
  | 'TransactionType'
  | 'Instrument'
  | 'Amount'
  | 'DrawAgainstCommitment'
  | 'Description'
  | 'DividendType'
  | 'ValuationCap'
  | 'DiscountRate'
  | 'InterestRate'
  | 'InterestCalculationBasis'
  | 'MaturityDate'
  | 'PurchasePricePerShare'
  | 'SharesPurchased'
  | 'PreMoneyValuation'
  | 'PostMoneyValuation'
  | 'AnnualManagementFee'
  | 'Carry'
  | 'CarryHurdleRate'
  | 'Dividend'
  | 'DividendCalculationBasis'
  | 'DividendType'
  | 'VestingCommencementDate'
  | 'ExpirationDate'
  | 'StrikePrice'
  | 'NumberOfShares'

export const Columns: Record<ColumnNames, ColumnType> = {
  RowNumber: {
    index: 0,
    type: 'rowNumber',
    label: '#',
    width: 42,
  },
  Title: {
    index: 1,
    type: 'text',
    label: 'spreadsheet.transactions.columns.title',
    width: 200,
  },
  Holding: {
    index: 2,
    type: 'holding',
    label: 'spreadsheet.transactions.columns.holding',
    width: 200,
  },
  Date: {
    index: 3,
    type: 'date',
    label: 'spreadsheet.transactions.columns.date',
    width: 140,
  },
  TransactionType: {
    index: 4,
    type: 'option',
    label: 'spreadsheet.transactions.columns.transactionType',
    placeholder: 'spreadsheet.transactions.columns.transactionTypePlaceholder',
    getOptions: (intl: IntlShape) => [
      {
        id: TransactionTypes.COMMITMENT,
        label: intl.formatMessage({
          id: `transactions.${TransactionTypes.COMMITMENT}`,
        }),
        value: TransactionTypes.COMMITMENT,
      },
      {
        id: TransactionTypes.DISTRIBUTION,
        label: intl.formatMessage({
          id: `transactions.${TransactionTypes.DISTRIBUTION}`,
        }),
        value: TransactionTypes.DISTRIBUTION,
      },
      {
        id: TransactionTypes.INVESTMENT,
        label: intl.formatMessage({
          id: `transactions.${TransactionTypes.INVESTMENT}`,
        }),
        value: TransactionTypes.INVESTMENT,
      },
    ],
  },
  Instrument: {
    index: 5,
    type: 'option',
    label: 'spreadsheet.transactions.columns.instrument',
    placeholder: 'spreadsheet.transactions.columns.instrumentPlaceholder',
    getOptions: (intl: IntlShape) => [
      {
        id: TransactionInstruments.CONVERTIBLE_NOTE,
        label: getNormalizedTransactionInstrument(
          TransactionInstruments.CONVERTIBLE_NOTE,
          intl
        ),
        value: TransactionInstruments.CONVERTIBLE_NOTE,
      },
      {
        id: TransactionInstruments.DEBT_CREDIT,
        label: getNormalizedTransactionInstrument(
          TransactionInstruments.DEBT_CREDIT,
          intl
        ),
        value: TransactionInstruments.DEBT_CREDIT,
      },
      {
        id: TransactionInstruments.EQUITY,
        label: getNormalizedTransactionInstrument(
          TransactionInstruments.EQUITY,
          intl
        ),
        value: TransactionInstruments.EQUITY,
      },
      {
        id: TransactionInstruments.FUND_INVESTMENT,
        label: getNormalizedTransactionInstrument(
          TransactionInstruments.FUND_INVESTMENT,
          intl
        ),
        value: TransactionInstruments.FUND_INVESTMENT,
      },
      {
        id: TransactionInstruments.PREFERRED_EQUITY,
        label: getNormalizedTransactionInstrument(
          TransactionInstruments.PREFERRED_EQUITY,
          intl
        ),
        value: TransactionInstruments.PREFERRED_EQUITY,
      },
      {
        id: TransactionInstruments.SAFE,
        label: getNormalizedTransactionInstrument(
          TransactionInstruments.SAFE,
          intl
        ),
        value: TransactionInstruments.SAFE,
      },
      {
        id: TransactionInstruments.WARRANTS,
        label: getNormalizedTransactionInstrument(
          TransactionInstruments.WARRANTS,
          intl
        ),
        value: TransactionInstruments.WARRANTS,
      },
      {
        id: TransactionInstruments.OTHER,
        label: getNormalizedTransactionInstrument(
          TransactionInstruments.OTHER,
          intl
        ),
        value: TransactionInstruments.OTHER,
      },
    ],
  },
  Amount: {
    index: 6,
    type: 'number',
    label: 'spreadsheet.transactions.columns.amount',
    width: 140,
  },
  DrawAgainstCommitment: {
    index: 7,
    type: 'boolean',
    label: 'spreadsheet.transactions.columns.drawAgainstCommitment',
    width: 180,
    getOptions: (intl: IntlShape) => [
      {
        id: 'yes',
        label: intl.formatMessage({ id: 'common.yes' }),
        value: true,
      },
      {
        id: 'no',
        label: intl.formatMessage({ id: 'common.no' }),
        value: false,
      },
    ],
  },
  Description: {
    index: 8,
    type: 'text',
    label: 'spreadsheet.transactions.columns.description',
    width: 200,
  },
  ValuationCap: {
    index: 9,
    type: 'number',
    label: 'spreadsheet.transactions.columns.valuationCap',
  },
  DiscountRate: {
    index: 10,
    type: 'percentage',
    label: 'spreadsheet.transactions.columns.discountRate',
  },
  InterestRate: {
    index: 11,
    type: 'percentage',
    label: 'spreadsheet.transactions.columns.interestRate',
  },
  InterestCalculationBasis: {
    index: 12,
    type: 'option',
    label: 'spreadsheet.transactions.columns.interestCalculationBasis',
    width: 200,
    getOptions: (_intl: IntlShape) => [
      {
        id: CalculationBasisTypes.N_A,
        label: CalculationBasisTypes.N_A,
        value: CalculationBasisTypes.N_A,
      },
      {
        id: CalculationBasisTypes.ACTUAL_365,
        label: CalculationBasisTypes.ACTUAL_365,
        value: CalculationBasisTypes.ACTUAL_365,
      },
      {
        id: CalculationBasisTypes['30_360'],
        label: CalculationBasisTypes['30_360'],
        value: CalculationBasisTypes['30_360'],
      },
    ],
  },
  MaturityDate: {
    index: 13,
    type: 'date',
    label: 'spreadsheet.transactions.columns.maturityDate',
  },
  PurchasePricePerShare: {
    index: 14,
    type: 'number',
    label: 'spreadsheet.transactions.columns.purchasePricePerShare',
    width: 200,
  },
  SharesPurchased: {
    index: 15,
    type: 'number',
    label: 'spreadsheet.transactions.columns.sharesPurchased',
  },
  PreMoneyValuation: {
    index: 16,
    type: 'number',
    label: 'spreadsheet.transactions.columns.preMoneyValuation',
  },
  PostMoneyValuation: {
    index: 17,
    type: 'number',
    label: 'spreadsheet.transactions.columns.postMoneyValuation',
  },
  AnnualManagementFee: {
    index: 18,
    type: 'percentage',
    label: 'spreadsheet.transactions.columns.annualManagementFee',
    width: 180,
  },
  Carry: {
    index: 19,
    type: 'percentage',
    label: 'spreadsheet.transactions.columns.carry',
  },
  CarryHurdleRate: {
    index: 20,
    type: 'percentage',
    label: 'spreadsheet.transactions.columns.carryHurdleRate',
  },
  Dividend: {
    index: 21,
    type: 'percentage',
    label: 'spreadsheet.transactions.columns.dividend',
  },
  DividendCalculationBasis: {
    index: 22,
    type: 'option',
    label: 'spreadsheet.transactions.columns.dividendCalculationBasis',
    width: 200,
    getOptions: (_intl: IntlShape) => [
      {
        id: CalculationBasisTypes.N_A,
        label: CalculationBasisTypes.N_A,
        value: CalculationBasisTypes.N_A,
      },
      {
        id: CalculationBasisTypes.ACTUAL_365,
        label: CalculationBasisTypes.ACTUAL_365,
        value: CalculationBasisTypes.ACTUAL_365,
      },
      {
        id: CalculationBasisTypes['30_360'],
        label: CalculationBasisTypes['30_360'],
        value: CalculationBasisTypes['30_360'],
      },
    ],
  },
  DividendType: {
    index: 23,
    type: 'option',
    label: 'spreadsheet.transactions.columns.dividendType',
    getOptions: (_intl: IntlShape) => [
      {
        id: DividendTypes.N_A,
        label: DividendTypes.N_A,
        value: DividendTypes.N_A,
      },
      {
        id: DividendTypes.CUMULATIVE,
        label: DividendTypes.CUMULATIVE,
        value: DividendTypes.CUMULATIVE,
      },
      {
        id: DividendTypes.NON_CUMULATIVE,
        label: DividendTypes.NON_CUMULATIVE,
        value: DividendTypes.NON_CUMULATIVE,
      },
    ],
  },
  VestingCommencementDate: {
    index: 24,
    type: 'date',
    label: 'spreadsheet.transactions.columns.vestingCommencementDate',
    width: 200,
  },
  ExpirationDate: {
    index: 25,
    type: 'date',
    label: 'spreadsheet.transactions.columns.expirationDate',
  },
  StrikePrice: {
    index: 26,
    type: 'number',
    label: 'spreadsheet.transactions.columns.strikePrice',
  },
  NumberOfShares: {
    index: 27,
    type: 'number',
    label: 'spreadsheet.transactions.columns.numberOfShares',
  },
}

const CommonColumns = [
  Columns.Title.index,
  Columns.Description.index,
  Columns.Date.index,
  Columns.TransactionType.index,
  Columns.Holding.index,
  Columns.Amount.index,
]

// Available columns per transaction type
export const TransactionTypeColumns = {
  [TransactionTypes.COMMITMENT]: [],
  [TransactionTypes.DISTRIBUTION]: [],
  [TransactionTypes.INVESTMENT]: [Columns.Instrument.index],
}

// Available columns per instrument type
export const InstrumentColumns = {
  [TransactionInstruments.CONVERTIBLE_NOTE]: [
    Columns.ValuationCap.index,
    Columns.DiscountRate.index,
    Columns.InterestRate.index,
    Columns.InterestCalculationBasis.index,
    Columns.MaturityDate.index,
  ],
  [TransactionInstruments.DEBT_CREDIT]: [
    Columns.InterestRate.index,
    Columns.InterestCalculationBasis.index,
    Columns.MaturityDate.index,
  ],
  [TransactionInstruments.EQUITY]: [
    Columns.PurchasePricePerShare.index,
    Columns.SharesPurchased.index,
    Columns.PreMoneyValuation.index,
    Columns.PostMoneyValuation.index,
  ],
  [TransactionInstruments.FUND_INVESTMENT]: [
    Columns.DrawAgainstCommitment.index,
    Columns.AnnualManagementFee.index,
    Columns.Carry.index,
    Columns.CarryHurdleRate.index,
  ],
  [TransactionInstruments.PREFERRED_EQUITY]: [
    Columns.PurchasePricePerShare.index,
    Columns.SharesPurchased.index,
    Columns.PreMoneyValuation.index,
    Columns.PostMoneyValuation.index,
    Columns.Dividend.index,
    Columns.DividendCalculationBasis.index,
    Columns.DividendType.index,
  ],
  [TransactionInstruments.SAFE]: [
    Columns.ValuationCap.index,
    Columns.DiscountRate.index,
  ],
  [TransactionInstruments.WARRANTS]: [
    Columns.VestingCommencementDate.index,
    Columns.ExpirationDate.index,
    Columns.StrikePrice.index,
    Columns.NumberOfShares.index,
  ],
  [TransactionInstruments.Other]: [],
}

export type CellType =
  | CustomTextCell
  | CustomNumberCell
  | CustomDateCell
  | StaticDropdownCell
  | BooleanDropdownCell
  | HoldingCell
  | RowNumberCell
export type GridType = CellType[][]

export interface ImportTransactionSpreadsheetProps {
  onGridChange?: (grid: GridType) => void
}

const MODAL_EXTRA_ROWS = 4

const Borders = {
  NoRightBorder: {
    right: {
      width: '0',
    },
  },

  NoLeftBorder: {
    left: {
      width: '0',
    },
  },

  NoTopBorder: {
    top: {
      width: '0',
    },
  },

  ThickRightBorder: {
    right: {
      width: '2px',
    },
  },

  ThickBottomBorder: {
    bottom: {
      width: '2px',
    },
  },
}

const getEmptyRow = (
  intl: IntlShape,
  holdings: HoldingInfo[],
  rowNumber: number,
  fetchingHoldings?: boolean
): CellType[] => {
  const enabledColumns: number[] = [...CommonColumns]

  return Object.values(Columns).map((column, index) => {
    switch (column.type) {
      case 'rowNumber':
        return {
          type: 'rowNumber',
          rowNumber,
          errors: new Set(),
          warnings: new Set(),
        }
      case 'text': {
        const isTitle = index === Columns.Title.index

        return {
          type: 'text',
          text: '',
          placeholder: isTitle
            ? intl.formatMessage({
                id: 'spreadsheet.transactions.addTransactionTitle',
              })
            : intl.formatMessage({ id: 'spreadsheet.optional' }),
          style: {
            border: {
              ...Borders.NoLeftBorder,
              ...(index > Columns.Title.index && Borders.NoRightBorder),
            },
          },
        }
      }
      case 'number': {
        const isAmount = index === Columns.Amount.index
        return {
          ...getNumberCell({
            rowIndex: rowNumber,
          }),
          disabled: !enabledColumns.includes(index),
          optional: !isAmount,
          placeholderMsgId: 'spreadsheet.transactions.addAmount',
        }
      }
      case 'percentage': {
        return {
          ...getNumberCell({
            rowIndex: rowNumber,
          }),
          disabled: !enabledColumns.includes(index),
          isPercentage: true,
          max: 100,
          maxErrorMsgId: 'spreadsheet.transactions.maxPercentageError',
          optional: true,
        }
      }
      case 'date': {
        const isTransactionDate = index === Columns.Date.index

        return {
          ...getDateCell({
            rowIndex: rowNumber,
            optional: !isTransactionDate,
          }),
          maxDate: isTransactionDate ? new Date() : undefined,
          maxDateErrorMsgId: 'spreadsheet.transactions.maxDateError',
          disabled: !enabledColumns.includes(index),
        }
      }
      case 'option': {
        const isTransactionType = index === Columns.TransactionType.index
        const isInstrument = index === Columns.Instrument.index
        const isDrawAgainstCommitment =
          index === Columns.DrawAgainstCommitment.index
        const cell = getStaticDropdownCell({
          rowIndex: rowNumber,
          options: column.getOptions(intl),
          optional: !isTransactionType && !isInstrument,
        })

        return {
          ...cell,
          disabled: !enabledColumns.includes(index),
          placeholder: column.placeholder,
          option: isDrawAgainstCommitment ? cell.options[0] : undefined,
          overrideTooltipBackground: CellErrorTooltipColor.RED,
          style: {
            border: {
              ...SpreadsheetBorders.NoLeftBorder,
              ...SpreadsheetBorders.NoRightBorder,
              ...SpreadsheetBorders.NoTopBorder,
            },
          },
        }
      }
      case 'boolean': {
        const cell = getStaticDropdownCell({
          rowIndex: rowNumber,
          options: column.getOptions(intl),
          optional: true,
        }) as any

        return {
          ...cell,
          type: 'booleanDropdown',
          disabled: !enabledColumns.includes(index),
          option: cell.options[0],
          style: {
            border: {
              ...SpreadsheetBorders.NoLeftBorder,
              ...SpreadsheetBorders.NoRightBorder,
              ...SpreadsheetBorders.NoTopBorder,
            },
          },
        }
      }
      case 'holding':
        return {
          type: 'holdingDropdown',
          initialHoldings: holdings,
          loading: fetchingHoldings,
          style: {
            border: {
              ...Borders.NoLeftBorder,
              ...Borders.NoRightBorder,
              ...Borders.NoTopBorder,
            },
          },
        }
      default:
        return assertUnreachable(column)
    }
  })
}

const getColumns = (currentGrid: GridType): Column[] => {
  return currentGrid[0].map((cell, index) => ({
    columnId: index,
    width: cell.width || CELL_WIDTH,
  }))
}

const getRows = (currentGrid: GridType): Row<CellType>[] => {
  return currentGrid.map<Row<CellType>>((cells, idx) => {
    let rowHeight = DEFAULT_CELL_HEIGHT

    if (idx !== 0) {
      const holdingCell = cells[0] as HoldingCell
      rowHeight = getCellHeightFromAmountOfLines(holdingCell.holding?.name)
    }

    return {
      rowId: idx,
      cells: [...cells],
      height: rowHeight,
    }
  })
}

const createRow = (metrics: GridType, intl: IntlShape): CellType[] => {
  const { initialHoldings } = metrics[1][Columns.Holding.index] as HoldingCell
  return getEmptyRow(intl, initialHoldings, metrics.length)
}

const applyChangesToGrid = (
  changes: CellChange<CellType>[],
  currentMetrics: GridType,
  intl: IntlShape
): GridType => {
  const newMetrics = [...currentMetrics]
  const rowsChanged: Set<number> = new Set()
  const drawAgainstCommitmentChanged: Set<number> = new Set()

  changes.forEach((change) => {
    const rowIndex = change.rowId as number
    const columnIndex = change.columnId as number

    if (rowIndex === 0 && columnIndex === 0) {
      return
    }

    const row = newMetrics[rowIndex]

    if (!row) {
      newMetrics[rowIndex] = createRow(newMetrics, intl)
    }

    if (!rowsChanged.has(rowIndex)) {
      rowsChanged.add(rowIndex)
    }

    const column = newMetrics[rowIndex][columnIndex]

    if (column) {
      if (columnIndex === Columns.DrawAgainstCommitment.index) {
        const prevDrawAgainstCommitment = (
          newMetrics[rowIndex][columnIndex] as StaticDropdownCell
        ).option?.value
        const newDrawAgainstCommitment = (change.newCell as StaticDropdownCell)
          .option?.value

        if (prevDrawAgainstCommitment !== newDrawAgainstCommitment) {
          drawAgainstCommitmentChanged.add(rowIndex)
        }
      }

      newMetrics[rowIndex][columnIndex] = change.newCell
    }
  })

  drawAgainstCommitmentChanged.forEach((rowIndex) => {
    const amountCell = newMetrics[rowIndex][
      Columns.Amount.index
    ] as CustomNumberCell
    amountCell.error = undefined
  })

  rowsChanged.forEach((rowIndex) => {
    const row = newMetrics[rowIndex]
    const transactionTypeCell = row[
      Columns.TransactionType.index
    ] as StaticDropdownCell
    const transactionType = transactionTypeCell.option?.id
    const instrumentCell = row[Columns.Instrument.index] as StaticDropdownCell
    const instrument = instrumentCell.option?.value
    const enabledColumns: number[] = [...CommonColumns]

    if (transactionType) {
      enabledColumns.push(...(TransactionTypeColumns[transactionType] || []))

      if (instrument) {
        enabledColumns.push(...(InstrumentColumns[instrument] || []))
      }
    }

    row.forEach((cell, index) => {
      const customCell = cell as CustomCell

      if (index >= Columns.Instrument.index) {
        const isEnabled = enabledColumns.includes(index)
        customCell.disabled = !isEnabled
      }

      const rowNumberCell = row[Columns.RowNumber.index] as RowNumberCell

      if (customCell.error && !customCell.disabled) {
        rowNumberCell.errors.add(index)
      } else {
        rowNumberCell.errors.delete(index)
      }
    })
  })

  return newMetrics
}

const getBaseGrid = (
  intl: IntlShape,
  holdings: HoldingInfo[],
  fetchingHoldings?: boolean
): GridType => {
  return [
    Object.values(Columns).map((column, index) => {
      if (index === Columns.RowNumber.index) {
        return {
          type: 'rowNumber',
          rowNumber: 0,
          rowIndex: 0,
          width: column.width,
          isHeader: true,
          errors: new Set(),
          warnings: new Set(),
        }
      }

      return {
        type: 'text',
        text: intl.formatMessage({
          id: column.label,
        }),
        nonEditable: true,
        width: column.width,
        rowIndex: 0,
        style: {
          border: {
            ...Borders.NoLeftBorder,
            ...Borders.NoTopBorder,
            ...Borders.ThickBottomBorder,
            ...(index > Columns.Title.index && Borders.NoRightBorder),
          },
        },
      }
    }),
    getEmptyRow(intl, holdings, 1, fetchingHoldings),
  ]
}

const getModalGrid = (
  intl: IntlShape,
  holdings: HoldingInfo[],
  fetchingHoldings?: boolean
): GridType => {
  const grid = getBaseGrid(intl, holdings, fetchingHoldings)

  for (let i = 0; i < MODAL_EXTRA_ROWS; i++) {
    grid.push(getEmptyRow(intl, holdings, grid.length, fetchingHoldings))
  }

  return grid
}

const getGrid = (
  intl: IntlShape,
  holdings: HoldingInfo[],
  fetchingHoldings?: boolean
): GridType => {
  return getModalGrid(intl, holdings, fetchingHoldings)
}

const updateGridsInitialHoldings = (
  holdings: HoldingInfo[],
  grid: GridType
) => {
  for (let rowIdx = 1; rowIdx < grid.length; rowIdx++) {
    const dropdownCell = grid[rowIdx][Columns.Holding.index] as HoldingCell
    dropdownCell.initialHoldings = holdings
    dropdownCell.loading = false
  }
}

export const useTransactionsSpreadsheet = ({
  onGridChange,
}: ImportTransactionSpreadsheetProps) => {
  const intl = useIntl()
  const eventHandler = useRef<EventHandlers>()
  const [grid, setGrid] = useState<GridType>(getGrid(intl, [], true))
  const { loadOptions: fetchHoldings } = useSubjectSelector({
    filters: {
      type: [SubjectType.COMPANY, SubjectType.FUND, SubjectType.DEAL],
    },
    displayAvatar: true,
  })

  useEffect(() => {
    fetchHoldings('').then((fetchedHoldings) => {
      dispatchEvent(INITIAL_HOLDINGS_FETCHED, fetchedHoldings)
      setGrid((currentGrid) => {
        const newGrid = [...currentGrid]
        updateGridsInitialHoldings(fetchedHoldings, newGrid)
        return newGrid
      })
    })
  }, [])

  const rows = useMemo(() => getRows(grid), [grid])
  const columns = useMemo(() => getColumns(grid), [grid])

  const handleChanges = useCallback(
    (changes: CellChange<CellType>[]) => {
      setGrid((currentGrid) => {
        const newMetrics = applyChangesToGrid(changes, currentGrid, intl)
        onGridChange?.(newMetrics)
        return newMetrics
      })
    },
    [onGridChange, intl]
  )

  const addRow = useCallback(() => {
    setGrid((currentMetrics) => [
      ...currentMetrics,
      createRow(currentMetrics, intl),
    ])
  }, [intl])

  const getEventHandler = useCallback((gridEventHandler) => {
    eventHandler.current = gridEventHandler
  }, [])

  useEventListener<HoldingFetchedEventType>(
    HOLDING_FETCHED_EVENT,
    ({ holding, holdingName }) => {
      const newTransactions = [...grid]

      if (holding) {
        // Go through all of the holdings because it might be repeated
        for (let i = 1; i < newTransactions.length; i++) {
          const dropdownCell = newTransactions[i][
            Columns.Holding.index
          ] as HoldingCell

          if (dropdownCell.holding?.name === holding.name) {
            dropdownCell.holding = holding
          }
        }
      } else {
        for (let i = 1; i < newTransactions.length; i++) {
          const dropdownCell = newTransactions[i][
            Columns.Holding.index
          ] as HoldingCell
          if (dropdownCell.holding?.name === holdingName) {
            const rowNumberCell = newTransactions[i][
              Columns.RowNumber.index
            ] as RowNumberCell
            rowNumberCell.errors.add(Columns.Holding.index)
            dropdownCell.error = 'spreadsheet.transactions.invalidHolding'

            dropdownCell.holding = {
              id: 'invalid',
              name: holdingName,
              invalid: true,
            }
          }
        }
      }

      setGrid(newTransactions)
      onGridChange?.(newTransactions)
    }
  )

  useEventListener(
    DRAW_AGAINST_COMMITMENT_ERROR,
    (transactionIndex: number) => {
      const newTransactions = [...grid]
      let count = 0

      // Find the transaction that has the error
      for (let i = 1; i < newTransactions.length; i++) {
        const titleCell = newTransactions[i][
          Columns.Title.index
        ] as CustomTextCell

        // Count only the ones that have title, in case there are empty rows in between
        if (titleCell.text) {
          if (count === transactionIndex) {
            const amountCell = newTransactions[i][
              Columns.Amount.index
            ] as CustomNumberCell
            amountCell.error = intl.formatMessage({
              id: 'transactions.investedExceedsCommitmentError',
            })
            const rowNumberCell = newTransactions[i][
              Columns.RowNumber.index
            ] as RowNumberCell
            rowNumberCell.errors.add(Columns.Amount.index)
            break
          }

          count++
        }
      }

      setGrid(newTransactions)
    }
  )

  return {
    fetchHoldings,
    handleChanges,
    addRow,
    rows,
    columns,
    getEventHandler,
    eventHandler,
  }
}
