import React from 'react'
import { StyledColumn } from './Column.styles'

interface ColumnProps {
  children: React.ReactNode
}

const Column: React.FC<ColumnProps> = ({ children }) => {
  return <StyledColumn>{children}</StyledColumn>
}

export default Column
