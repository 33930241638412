import {
  createContext,
  FC,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react'

import { useDebouncedState } from 'utils/hooks/useDebouncedState'
import { SortDirection } from 'utils/constants/sortDirection'
import { MetricSortBy } from 'utils/constants/metrics'
import { useEventListener } from 'utils/hooks/useEventListener'
import { metricsKeys } from 'utils/queries/metrics'
import { useQueryClient } from '@tanstack/react-query'
import {
  ARCHIVE_DP_METRIC_EVENT,
  CREATED_METRIC_EVENT,
  CREATE_DP_METRIC_EVENT,
  EDIT_DP_METRIC_EVENT,
  IMPORT_METRIC_EVENT,
  UN_ARCHIVE_DP_METRIC_EVENT,
  UPDATE_ALL_METRICS_EVENT,
} from 'utils/constants/events'
import { QUERIES as companyKeys } from 'utils/queries/companies'

interface MetricsContextProps {
  search: string
  debouncedSearch: string
  sortCriteria: SortCriteria
  setSortCriteria: (sort: SortCriteria) => void
  onChange: (value: string) => void
}

export interface SortCriteria {
  field: MetricSortBy
  direction: SortDirection
}

interface MetricsContextProviderProps {
  children: ReactElement | ReactElement[]
}

export const MetricsContext = createContext({} as MetricsContextProps)

export const MetricsContextProvider: FC<MetricsContextProviderProps> = ({
  children,
}) => {
  const [search, debouncedSearch, setSearch] = useDebouncedState('')
  const [sortCriteria, setSortCriteria] = useState<SortCriteria>({
    field: MetricSortBy.NAME,
    direction: SortDirection.ASC,
  })

  const queryClient = useQueryClient()

  const value = useMemo(
    () => ({
      search,
      debouncedSearch,
      sortCriteria,
      setSortCriteria,
      onChange: setSearch,
    }),
    [search, debouncedSearch, sortCriteria, setSearch]
  )

  const invalidateQueries = ({ metricId }: { metricId?: string }) => {
    const filters = {
      metricName: search,
      sortBy: sortCriteria.field,
      sortDirection: sortCriteria.direction,
    }

    queryClient.invalidateQueries(metricsKeys.getMetrics(filters))
    queryClient.invalidateQueries([companyKeys.COMPANY_METRICS])
    queryClient.invalidateQueries(
      metricsKeys.getMetrics({
        selected: true,
      })
    )

    if (metricId) {
      queryClient.invalidateQueries(metricsKeys.getDataPoints(metricId))
      queryClient.invalidateQueries(metricsKeys.getMilestones(metricId))
    }
  }

  useEventListener(CREATED_METRIC_EVENT, invalidateQueries)

  useEventListener(UPDATE_ALL_METRICS_EVENT, invalidateQueries)

  useEventListener(IMPORT_METRIC_EVENT, invalidateQueries)

  useEventListener(ARCHIVE_DP_METRIC_EVENT, invalidateQueries)

  useEventListener(UN_ARCHIVE_DP_METRIC_EVENT, invalidateQueries)

  useEventListener(EDIT_DP_METRIC_EVENT, invalidateQueries)

  useEventListener(CREATE_DP_METRIC_EVENT, invalidateQueries)

  return (
    <MetricsContext.Provider value={value}>{children}</MetricsContext.Provider>
  )
}

export const useMetricsContext = () => useContext(MetricsContext)
