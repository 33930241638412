import { useMutation } from '@apollo/client'
import type { FormikProps } from 'formik'
import { useQueryClient } from '@tanstack/react-query'
import InvestorManagementService from 'api/InvestorManagementService'
import Toast from 'components/Toast'
import { useRef } from 'react'
import { useIntl } from 'react-intl'
import { getCurrentGroupId } from 'selectors/auth'
import { GqlUpdateSubjectDto } from 'types/graphql-schemas/graphql'
import { isDuplicatedFieldError } from 'utils/functions/errors'
import {
  getAddInvestorGroupUsersPayload,
  getDeleteInvestorGroupUsersPayload,
  getInvestorSubjectPayload,
} from 'utils/functions/investorManagement'
import { UPDATE_SUBJECT } from 'utils/gql/mutations/subjects'
import { useEditInvestorMutation } from 'utils/hooks/investorManagement/useInvestorsQuery'
import { useSubjectQuery } from 'utils/hooks/queries/subjects/useSubjectQuery'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useInvestorSubjectData } from 'utils/hooks/subjects/useInvestorSubjectData'
import { InvestorFormValues, InvestorGroupUser } from 'utils/types/investors'
import { User } from 'utils/types/user'
import { subjectsKeys } from 'utils/queries/subjects'
import { SubjectType } from 'utils/types/subjects'
import useInvestorGroup from '../hooks/useInvestorGroup'

export const useEditInvestor = (onCloseDrawer: () => void) => {
  const intl = useIntl()
  const { id: investorId } = useInvestorGroup()
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const formRef = useRef<FormikProps<InvestorFormValues>>(null)
  const [updateSubject] = useMutation(UPDATE_SUBJECT)
  const { data, isLoading, refetch } = useSubjectQuery(investorId)

  const investorSubject = data?.subject

  const { railsId, name: investorName } =
    useInvestorSubjectData(investorSubject)

  const queryClient = useQueryClient()
  const editInvestorMutation = useEditInvestorMutation(railsId!, currentGroupId)

  const editInvestor = async (values: InvestorFormValues) => {
    const payload = getInvestorSubjectPayload<GqlUpdateSubjectDto>({
      investorData: values,
      investorId,
    })

    try {
      await updateSubject(payload)

      refetch()

      queryClient.invalidateQueries(
        subjectsKeys.byFilters({
          filters: { type: SubjectType.INVESTOR },
        })
      )

      onCloseDrawer()

      Toast.displayIntl([
        'investorManagement.success.editInvestor',
        { investorName: values.name },
      ])
    } catch (err) {
      if (isDuplicatedFieldError('name', err)) {
        formRef.current?.setFieldError(
          'name',
          intl.formatMessage({
            id: 'investorManagement.investorNameTakenError',
          })
        )
      } else {
        Toast.displayIntl('investorManagement.errors.editInvestor', 'error')
      }
    }
  }

  const addInvestorGroupUsers = async (users: User[], message?: string) => {
    const payload = getAddInvestorGroupUsersPayload(users, message)

    editInvestorMutation.mutate(payload, {
      onSuccess: () =>
        Toast.displayIntl([
          'investorManagement.success.invitesSent',
          { countUsers: users.length, investorName },
        ]),
      onError: (_error) =>
        Toast.displayIntl('investorManagement.errors.invitesSent', 'error'),
    })
  }

  const deleteInvestorGroupUser = async (
    investorUserGroup: InvestorGroupUser,
    undoDeletionAction: () => void
  ) => {
    Toast.displayAction({
      message: intl.formatMessage(
        { id: 'investorManagement.success.userRemoved' },
        {
          userName: investorUserGroup.user.name || investorUserGroup.user.email,
          investorName,
        }
      ),
      action: undoDeletionAction,
      afterClose: () => {
        const payload = getDeleteInvestorGroupUsersPayload([investorUserGroup])
        editInvestorMutation.mutate(payload, {
          onError: (_error) =>
            Toast.displayIntl('investorManagement.errors.userRemoved', 'error'),
        })
      },
      type: 'info',
      label: intl.formatMessage({
        id: 'investorManagement.errors.undoRemoved',
      }),
    })
  }

  const resendInvite = async (investorUser: InvestorGroupUser) => {
    try {
      await InvestorManagementService.sendInvestorInvites(investorId, [
        investorUser.user.id,
      ])
      Toast.displayIntl([
        'investorManagement.success.resendInvite',
        { email: investorUser.user.email },
      ])
    } catch (err) {
      Toast.displayIntl(
        [
          'investorManagement.errors.resendInvite',
          { email: investorUser.user.email },
        ],
        'error'
      )
    }
  }

  return {
    investorSubject,
    investorRailsId: railsId!,
    isLoading,
    editInvestor,
    resendInvite,
    addInvestorGroupUsers,
    deleteInvestorGroupUser,
    formRef,
  }
}
