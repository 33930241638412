import { BasicSubjectFragment } from 'types/graphql-schemas/graphql'
import { getAttribute } from 'utils/gql/helpers/subjects'
import { Industry } from 'utils/types/company'
import { GooglePlaceData } from 'utils/types/locations'
import { SubjectType } from 'utils/types/subjects'

export const useHoldingSubjectCard = (subject: BasicSubjectFragment) => {
  const isFund = subject.type === SubjectType.FUND
  const isDeal = subject.type === SubjectType.DEAL

  const fundCompany = subject.parentSubject

  const companyIndustries = getAttribute<Industry[]>(subject, 'industries')
    ?.value?.map((industry) => industry.name)
    ?.join(', ')

  const locations = getAttribute<GooglePlaceData[]>(subject, 'locations')
  const location = locations?.value?.[0]?.formattedAddress

  const qualityAssuredValue = getAttribute<boolean>(
    subject,
    'qualityAssured'
  )?.value
  const verified = getAttribute<boolean>(subject, 'verified')?.value

  return {
    isFund,
    isDeal,
    location,
    fundCompany,
    companyIndustries,
    logo: subject.logo ?? subject?.parentSubject?.logo,
    qualityAssuredValue,
    verified,
    railsId: subject.railsId,
  }
}
