import React from 'react'
import classNames from 'classnames'
import missingPNGPath from 'assets/images/missing.png'

import { Nullable } from 'utils/types/common'
import styles from './Avatar.module.scss'

type AvatarStyleType =
  | 'avatarCircleXS'
  | 'avatarCircleXSM'
  | 'avatarCircleSM'
  | 'avatarCircleLarge'
  | 'avatarCircleLogs'
  | 'avatarCircleFundLogo'
  | 'avatarForUpdateFeed'
  | 'avatarCircleHoldingLogo'
  | 'avatarFundHoldingLogo'

interface AvatarProps {
  initials?: string | false
  image?: Nullable<string>
  avatarStyle?: AvatarStyleType
  grayBorder?: boolean
  className?: string
}

const Avatar: React.FC<AvatarProps> = ({
  initials,
  image,
  avatarStyle = 'avatarCircleSM',
  grayBorder,
  className,
}) => {
  const getAvatar = () => {
    if (image) return <img alt="avatar" src={image} />

    if (initials)
      return (
        <span className={styles[`initials_${avatarStyle}`]}>{initials}</span>
      )

    return <img alt="avatar" src={missingPNGPath} />
  }

  return (
    <div
      className={classNames(styles[avatarStyle], className, {
        [styles.avatarInitals]: initials,
        [styles.grayBorder]: grayBorder,
      })}
    >
      {getAvatar()}
    </div>
  )
}

export default Avatar
