import { useIntl } from 'react-intl'

import Title from 'components/Title'
import Dropdown from 'ui/Dropdown'
import { isAmerica } from 'utils/constants/countries'

import Input from 'ui/Input'

import { Country, LegalStructure, Province } from 'utils/types/company'
import * as Styles from '../common/styles'

type CompanyLegalStructureProps = {
  data: {
    legalCountry?: Country
    legalStructure?: LegalStructure
    legalProvince?: Province
    legalEntityName?: string
  }
  legalStructures: LegalStructure[]
  countries: Country[]
  provinces: Province[]
  onChange: (
    key: string,
    value: Country | Province | LegalStructure | string
  ) => void
}
const CompanyLegalStructure = ({
  data: { legalCountry, legalStructure, legalProvince, legalEntityName },
  legalStructures,
  countries,
  provinces,
  onChange,
}: CompanyLegalStructureProps) => {
  const intl = useIntl()

  const selectedCountryIsAmerica = isAmerica(legalCountry)

  return (
    <Styles.Section>
      <Title title={intl.formatMessage({ id: 'editCompany.legalStructure' })} />

      <Styles.Item>
        <Input
          placeholder={intl.formatMessage({
            id: 'editCompany.legalEntityNamePlaceholder',
          })}
          label={intl.formatMessage({
            id: 'additionalInformation.legalEntityName',
          })}
          id="legalEntityName"
          value={legalEntityName}
          onChange={(event) => {
            onChange('legalEntityName', event.target.value)
          }}
        />
      </Styles.Item>
      <Styles.Item>
        <Dropdown
          type="select"
          async
          placeholder={intl.formatMessage({
            id: 'editCompany.legalStructurePlaceholder',
          })}
          label={intl.formatMessage({
            id: 'additionalInformation.legalStructure',
          })}
          loadOptions={(searchValue) =>
            legalStructures
              ?.filter(
                (option) =>
                  option.name
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase()) >= 0
              )
              .map((option) => ({
                ...option,
                label: option.name,
              }))
              .sort((a, b) => (a.name < b.name ? -1 : 1))
          }
          getOption={(structure) => ({
            ...structure,
            label: structure.name,
          })}
          value={legalStructure}
          onSelectOption={(_, __, value) => onChange('legalStructure', value)}
        />
      </Styles.Item>

      <Styles.Item>
        <Dropdown
          type="select"
          async
          placeholder={intl.formatMessage({
            id: 'editCompany.legalCountryPlaceholder',
          })}
          label={intl.formatMessage({
            id: 'additionalInformation.legalCountry',
          })}
          loadOptions={(searchValue) =>
            countries
              ?.filter(
                (option) =>
                  option.name
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase()) >= 0
              )
              .map((option) => ({
                ...option,
                label: option.name,
              }))
          }
          getOption={(country) => ({
            ...country,
            label: country.name,
          })}
          value={legalCountry}
          onSelectOption={(_, __, value) => onChange('legalCountry', value)}
        />
      </Styles.Item>

      <Styles.Item>
        <Dropdown
          type="select"
          async
          placeholder={intl.formatMessage({
            id: 'editCompany.legalStatePlaceholder',
          })}
          label={intl.formatMessage({ id: 'additionalInformation.legalState' })}
          loadOptions={(searchValue) =>
            provinces
              ?.filter(
                (option) =>
                  option.name
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase()) >= 0
              )
              .map((option) => ({
                ...option,
                label: option.name,
              }))
          }
          getOption={(state) => ({
            ...state,
            label: state.name,
          })}
          value={selectedCountryIsAmerica ? legalProvince : { name: '' }}
          onSelectOption={(_, __, value) => onChange('legalProvince', value)}
          disabled={!selectedCountryIsAmerica}
        />
      </Styles.Item>
    </Styles.Section>
  )
}

export default CompanyLegalStructure
