/* eslint-disable class-methods-use-this */
import type { DateCell, NumberCell } from '@silevis/reactgrid'
import { QueryClient } from '@tanstack/react-query'
import NumbersService from 'api/NumbersService'
import { SubjectMatterType } from 'api/UpdateService'
import {
  MetricCell,
  UNKNOWN_METRIC_ID,
} from 'components/Spreadsheet/CellTemplates/MetricCellTemplate'
import { RowNumberCell } from 'components/Spreadsheet/types'
import { getNumberCellValue } from 'components/Spreadsheet/utils'
import {
  HoldingCell,
  UNKNOWN_HOLDING_ID,
} from 'components/TransactionsSpeadsheetImportModal/components/CustomHoldingCellTemplate'
import dayjs from 'dayjs'
import type { IntlShape } from 'react-intl'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { metricsKeys } from 'utils/queries/metrics'
import { Company } from 'utils/types/company'
import { Metric, MetricSubjectMatterType } from 'utils/types/metricsV2'
import { MetricsSpreadsheetMode } from './MetricsSpreadsheetLogic'
import { GridType, MetricsSpreadsheetEvents } from './useUpdateMetricsGrid'

const METRIC_REQUIRED_ERROR = 'spreadsheet.metrics.errors.metricRequired'

type DataPointDTO = {
  date: Date
  value: number
  sharedGroups?: string[]
}

export const createMetric = async ({
  metricName,
  subjectId,
  subjectType,
  subjectName,
}: {
  metricName: string
  subjectId: string
  subjectType: MetricSubjectMatterType
  subjectName: string
}): Promise<Metric> => {
  const metrics = await NumbersService.createMetric({
    name: metricName,
    companiesOrPortfolios: [
      {
        id: subjectId!,
        classType: subjectType,
        groupId: null,
        name: subjectName,
      },
    ],
  })
  return metrics![0]
}

interface BulkCreate {
  metricId: string
  metricName: string
  dataPoints: DataPointDTO[]
  companyId?: string
  subjectType: MetricSubjectMatterType
  subjectName: string
}

export class MetricsSpreadsheetService {
  private mode: MetricsSpreadsheetMode

  private metricColumnIndex: number

  private holdingColumnIndex: number

  private company?: Company

  private intl: IntlShape

  private queryClient: QueryClient

  private constructor(
    mode: MetricsSpreadsheetMode,
    metricColumnIndex: number,
    holdingColumnIndex: number,
    intl: IntlShape,
    queryClient: QueryClient
  ) {
    this.mode = mode
    this.metricColumnIndex = metricColumnIndex
    this.holdingColumnIndex = holdingColumnIndex
    this.intl = intl
    this.queryClient = queryClient
  }

  static forMultipleHoldings = (
    metricColumnIndex: number,
    holdingColumnIndex: number,
    intl: IntlShape,
    queryClient: QueryClient
  ) => {
    const service = new MetricsSpreadsheetService(
      MetricsSpreadsheetMode.MULTIPLE_HOLDINGS,
      metricColumnIndex,
      holdingColumnIndex,
      intl,
      queryClient
    )

    return service
  }

  static forSingleHolding = ({
    company,
    metricColumnIndex,
    holdingColumnIndex,
    intl,
    queryClient,
  }: {
    company: Company
    metricColumnIndex: number
    holdingColumnIndex: number
    intl: IntlShape
    queryClient: QueryClient
  }) => {
    const service = new MetricsSpreadsheetService(
      MetricsSpreadsheetMode.SINGLE_HOLDING,
      metricColumnIndex,
      holdingColumnIndex,
      intl,
      queryClient
    )
    service.company = company
    return service
  }

  createMissingMetrics = async (bulks: BulkCreate[]) => {
    let newMetricsCreated = false

    // eslint-disable-next-line no-restricted-syntax
    for (const bulk of bulks) {
      if (bulk.metricId === UNKNOWN_METRIC_ID) {
        newMetricsCreated = true
        // eslint-disable-next-line no-await-in-loop
        const { id } = await createMetric({
          metricName: bulk.metricName,
          subjectId: bulk.companyId!,
          subjectType: bulk.subjectType,
          subjectName: bulk.subjectName,
        })
        bulk.metricId = id
      }
    }

    return newMetricsCreated
  }

  validateSpreadsheet = (spreadsheet: GridType) => {
    let valid = true

    for (let i = 1; i < spreadsheet.length; i++) {
      if (!this.validateRow(spreadsheet, i)) {
        valid = false
      }
    }

    return valid
  }

  validateRow = (spreadsheet: GridType, rowIndex: number): boolean => {
    const metricCell = spreadsheet[rowIndex][
      this.metricColumnIndex
    ] as MetricCell
    const hasMetricValues = spreadsheet[rowIndex]
      .slice(this.metricColumnIndex + 1)
      .some((_cell, columnIndex) =>
        getNumberCellValue(spreadsheet, rowIndex, columnIndex)
      )

    if (this.mode === MetricsSpreadsheetMode.MULTIPLE_HOLDINGS) {
      const holdingCell = spreadsheet[rowIndex][
        this.holdingColumnIndex
      ] as HoldingCell

      const validMetric = !!metricCell.metric
      const validHolding =
        !!holdingCell.holding && holdingCell.holding.id !== UNKNOWN_HOLDING_ID
      const hasHoldingAndMetric = validMetric && validHolding
      const isEmpty =
        !hasMetricValues && !metricCell.metric && !holdingCell.holding

      const isValid = hasHoldingAndMetric || isEmpty

      if (!isValid) {
        const rowNumberCell = spreadsheet[rowIndex][0] as RowNumberCell

        if (!holdingCell.holding) {
          holdingCell.error = METRIC_REQUIRED_ERROR
          rowNumberCell.errors.add(this.holdingColumnIndex)
        }

        if (!metricCell.metric) {
          metricCell.error = 'spreadsheet.metrics.errors.metricRequired'
          rowNumberCell.errors.add(this.metricColumnIndex)
        }
      }

      return isValid
    }

    const isEmpty = !hasMetricValues && !metricCell.metric
    const isValid = !!metricCell.metric || isEmpty

    if (!isValid) {
      const rowNumberCell = spreadsheet[rowIndex][0] as RowNumberCell

      if (!metricCell.metric) {
        metricCell.error = METRIC_REQUIRED_ERROR
        rowNumberCell.errors.add(this.metricColumnIndex)
      }
    }

    return isValid
  }

  uploadValues = async (spreadsheet: GridType): Promise<boolean> => {
    if (spreadsheet) {
      const bulks: BulkCreate[] = []

      const isValidSpreadsheet = this.validateSpreadsheet(spreadsheet)

      if (!isValidSpreadsheet) {
        dispatchEvent(MetricsSpreadsheetEvents.SPREADSHEET_WITH_ERRORS, {
          spreadsheet,
        })
        throw new Error(
          this.intl.formatMessage({
            id: 'spreadsheet.metrics.errors.youHaveErrors',
          })
        )
      }

      for (let i = 1; i < spreadsheet.length; i++) {
        const { metric } = spreadsheet[i][this.metricColumnIndex] as MetricCell

        if (metric) {
          const metricId = metric.id
          const metricName = metric.name
          const dataPoints: DataPointDTO[] = []

          for (let j = 1; j < spreadsheet[i].length; j++) {
            const { value } = spreadsheet[i][j] as NumberCell
            const { date } = spreadsheet[0][j] as DateCell

            if (!Number.isNaN(value) && date) {
              if (dayjs(date).isAfter(new Date(), 'day')) {
                throw new Error(
                  this.intl.formatMessage({
                    id: 'metrics.update.noFutureDatesAllowed',
                  })
                )
              }

              dataPoints.push({
                date,
                value,
                sharedGroups: metric.receiverGroups?.map((group) => group.id),
              })
            }
          }

          if (dataPoints.length > 0 || metricId === UNKNOWN_METRIC_ID) {
            const bulk: BulkCreate = {
              metricId,
              metricName,
              dataPoints,
              companyId: '',
              subjectType: SubjectMatterType.COMPANY,
              subjectName: '',
            }

            if (this.mode === MetricsSpreadsheetMode.MULTIPLE_HOLDINGS) {
              const { holding } = spreadsheet[i][
                this.holdingColumnIndex
              ] as HoldingCell
              bulk.companyId = holding?.railsId
              bulk.subjectName = String(holding?.name)
            }

            if (this.mode === MetricsSpreadsheetMode.SINGLE_HOLDING) {
              bulk.companyId = this.company?.id
              bulk.subjectName = String(this.company?.name)
            }

            bulks.push(bulk)
          }
        }
      }

      const newMetricsCreated = await this.createMissingMetrics(bulks)

      // eslint-disable-next-line no-restricted-syntax
      for (const bulk of bulks) {
        if (bulk.dataPoints.length > 0) {
          // eslint-disable-next-line no-await-in-loop
          await NumbersService.bulkCreateDataPoints(
            bulk.metricId,
            bulk.dataPoints.map((dataPoint) => ({
              ...dataPoint,
              value: Number(dataPoint.value),
            }))
          )

          this.queryClient.invalidateQueries(
            metricsKeys.getMetric(bulk.metricId)
          )
        }
      }

      if (newMetricsCreated) {
        this.queryClient.invalidateQueries(metricsKeys.getMetrics())
      }
    }

    return true
  }
}
