import * as yup from 'yup'
import {
  CRUNCHBASE_URL,
  LINKEDIN_URL,
  ANGELLIST_URL,
  X_TWITTER_URL,
} from 'utils/functions/regex'
import { emptyToNull } from 'utils/functions/schemas'

export const getTeamMemberFormSchema = (messages) =>
  yup.object().shape({
    firstName: yup.string().trim().required(messages['general.requiredField']),
    lastName: yup.string().required(messages['general.requiredField']),
    email: yup.string().nullable().email(messages['general.invalidEmail']),
    title: yup.string().nullable(),
    linkedinUrl: yup
      .string()
      .matches(LINKEDIN_URL, {
        message: messages['general.invalidLinkedinUrl'],
        excludeEmptyString: true,
      })
      .url(messages['general.invalidUrl'])
      .nullable()
      .transform(emptyToNull),
  })

export const getUrlFieldsValidationsSchema = (messages) =>
  yup.object().shape({
    website: yup
      .string()
      .url(messages['general.invalidUrl'])
      .nullable()
      .transform(emptyToNull),
    crunchbaseUrl: yup
      .string()
      .matches(CRUNCHBASE_URL, {
        message: messages['general.invalidCrunchbaseUrl'],
        excludeEmptyString: true,
      })
      .nullable()
      .transform(emptyToNull),
    linkedinUrl: yup
      .string()
      .matches(LINKEDIN_URL, {
        message: messages['general.invalidLinkedinUrl'],
        excludeEmptyString: true,
      })
      .nullable()
      .transform(emptyToNull),
    twitterUrl: yup
      .string()
      .matches(X_TWITTER_URL, {
        message: messages['general.invalidTwitterUrl'],
        excludeEmptyString: true,
      })
      .nullable()
      .transform(emptyToNull),
    angellistUrl: yup
      .string()
      .matches(ANGELLIST_URL, {
        message: messages['general.invalidAngellistUrl'],
        excludeEmptyString: true,
      })
      .nullable()
      .transform(emptyToNull),
  })

export const getAddTeamMemberSchema = (messages) =>
  yup.object().shape({
    email: yup.string().email().trim().required(messages['general.required']),
    role: yup.string().required(messages['general.required']),
  })
