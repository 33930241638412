import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import DragAndDropApi from 'utils/functions/dragAndDropApi'

import EmployeesList from 'components/EmployeesList'
import Toast from 'components/Toast'

import { Employee } from 'utils/types/company'

type TeamMembersProps = {
  employees: (Employee & { hidden?: boolean })[]
  handleChangeEmployeeOrder: (
    employees: Employee[],
    employee: Employee,
    position: number
  ) => void
  onAddNewTeamMember: (employee: Employee) => void
  onEditTeamMember: (employeeId: string, newEmployee: Employee) => void
  onDeleteTeamMember: (employee: Employee) => void
  toggleHideTeamMember: (employeeId: string, hidden: boolean) => void
}
const TeamMembers = ({
  employees,
  handleChangeEmployeeOrder,
  onAddNewTeamMember,
  onEditTeamMember,
  onDeleteTeamMember,
  toggleHideTeamMember,
}: TeamMembersProps) => {
  const intl = useIntl()

  const handleDelete = async (employee) => {
    try {
      onDeleteTeamMember(employee)
    } catch (error) {
      Toast.display(intl.messages['editCompany.errorDeleteEmployee'], 'error')
    }
  }

  const handleClickDelete = (employee) => {
    toggleHideTeamMember(employee.id, true)
    Toast.displayAction({
      message: intl.formatMessage(
        { id: 'editCompany.keyMemberDeleted' },
        { name: `${employee.firstName} ${employee.lastName}` }
      ),
      action: () => toggleHideTeamMember(employee.id, false),
      afterClose: () => handleDelete(employee),
    })
  }

  const visibleEmployees = useMemo(
    () =>
      employees
        .filter((emp) => !emp.hidden)
        .sort((empA, empB) => empA.position - empB.position),
    [employees]
  )

  const reorderEmployees = async (sourceIndex, destinationIndex) => {
    const reorderedEmployee = visibleEmployees[sourceIndex]

    // EmployeeService.editEmployee(reorderedEmployee.id, {
    //   employee: { position: destinationIndex + 1 },
    // })

    const sortedEmployees = DragAndDropApi.reorder(
      visibleEmployees,
      sourceIndex,
      destinationIndex
    )
    handleChangeEmployeeOrder(
      sortedEmployees.map((emp, index) => ({ ...emp, position: index + 1 })),
      reorderedEmployee,
      destinationIndex
    )
  }

  return (
    <EmployeesList
      buttonProps={{
        add: true,
        link: true,
      }}
      employees={visibleEmployees}
      onReorderEmployees={reorderEmployees}
      onDeleteEmployee={handleClickDelete}
      onAddNewTeamMember={onAddNewTeamMember}
      onEditTeamMember={onEditTeamMember}
    />
  )
}

export default TeamMembers
