import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useField } from 'formik'
import useSelector from '../useSelector'

const useInvestorSelector = () => {
  const field = useField('investor')
  const { onSelectOption } = useSelector({ selectorField: field })

  const intl = useIntl()

  const messages = useMemo(
    () => ({
      label: intl.formatMessage({
        id: 'investorManagement.createTransaction.fundManager.investor',
      }),
      placeholder: intl.formatMessage({
        id: 'investorManagement.createTransaction.fundManager.selectInvestor',
      }),
      searchPlaceholder: intl.formatMessage({ id: 'general.searchByName' }),
      error: intl.formatMessage({ id: 'portfolioDetail.companyWarning' }),
    }),
    [intl]
  )

  return {
    field,
    messages,
    onSelectOption,
  }
}

export default useInvestorSelector
