import { useQuery } from '@apollo/client'

import { CountSubjectsQuery } from 'types/graphql-schemas/graphql'
import { GET_COUNT_SUBJECTS } from 'utils/gql/queries/subjects'
import { SubjectFilter, getFilters } from './useSubjectsQuery'

export type QueryOptions = {
  skip?: boolean
  onCompleted?: (data: CountSubjectsQuery) => void
}
export const useCountSubjectsQuery = (
  filters: SubjectFilter = {},
  { skip = false, onCompleted }: QueryOptions = {}
) => {
  return useQuery(GET_COUNT_SUBJECTS, {
    variables: {
      query: getFilters(filters),
    },
    skip,
    onCompleted,
  })
}
