import Avatar from 'components/Avatar'
import { getInitials } from 'utils/functions/user'
import { useSubjectQuery } from 'utils/hooks/queries/subjects/useSubjectQuery'

interface UseOrganizationProfileBreadcrumbProps {
  companyFundId: string
}

const useOrganizationProfileBreadcrumb = ({
  companyFundId = '',
}: UseOrganizationProfileBreadcrumbProps) => {
  const { data } = useSubjectQuery(companyFundId)

  const fundCompanyLogo = data?.subject?.logo

  const getFundCompanyAvatar = () => {
    if (!data) return null

    if (!fundCompanyLogo) {
      return (
        <Avatar
          initials={getInitials(data?.subject.name)}
          avatarStyle="avatarCircleXS"
        />
      )
    }
    return <Avatar image={fundCompanyLogo} avatarStyle="avatarCircleXS" />
  }

  const fundCompany = data?.subject
    ? {
        id: data?.subject.id!,
        name: data?.subject.name!,
        logo: data?.subject.logo!,
      }
    : undefined

  return {
    fundCompany,
    getFundCompanyAvatar,
  }
}

export default useOrganizationProfileBreadcrumb
