import { Permission } from 'types/graphql-schemas/graphql'

export const PUBLIC_PERMISSION = 'public'

export const hasWritePermissions = (
  entityId: string,
  permissions: Permission[]
) => {
  return permissions.some(
    (permission) =>
      (permission.entityId === entityId ||
        permission.entityId === PUBLIC_PERMISSION) &&
      permission.write
  )
}
