import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import useOutsideClick from 'utils/hooks/useOutsideClick'
import IconButton from 'ui/IconButton/IconButton'
import { useEventListener } from 'utils/hooks/useEventListener'

import Tooltip from 'components/Tooltip'
import { Fund } from 'components/FundCompanyProfileFunds/FundsList/FundsList.styles'
import {
  Dropdown,
  Title,
  DropdownContent,
  DropdownItem,
  Label,
  ItemIcon,
  NoClickableLabel,
  DropdownContentTopOffsetPx,
} from './OptionsDropdown.styles'

const OptionsDropdown = ({
  id,
  hoverColumnId,
  children,
  title,
  icon,
  buttonType,
  border,
  buttonSize,
  topOffset,
  alignLeft,
  attachToDocument,
  onClick,
  dropdownContentMinWidth,
  isScrollable,
  padding,
  margin,
  contentRight,
  iconBackground,
  isActive,
  direction,
  hoverOpacity,
  isOpen,
  onCloseDropdown,
  alignSelf,
  onOpenDropdown,
  overridePosition,
}) => {
  const [dropdownClosed, setDropdownClosed] = useState(true)
  const wrapperRef = useRef(null)
  const contentRef = useRef(null)
  const buttonRef = useRef(null)
  const [manuallyOpened, setManuallyOpened] = useState(false)

  const handleCloseDropdown = () => {
    setDropdownClosed(true)
    setManuallyOpened(false)
    onCloseDropdown?.()
  }

  useEventListener('close_dropdown', () => {
    handleCloseDropdown()
  })

  useEventListener('wheel', () => {
    if (attachToDocument) handleCloseDropdown()
  })

  useOutsideClick(wrapperRef, (event) => {
    if (!dropdownClosed && !contentRef.current.contains(event.target)) {
      handleCloseDropdown()
    }
  })

  const getPosition = () => {
    let newPosition = {}

    if (wrapperRef.current) {
      const buttonRectPosition = wrapperRef.current?.getBoundingClientRect()
      const contentHeight = contentRef.current?.offsetHeight ?? 0
      const topGap = 8
      newPosition = {
        left: buttonRectPosition.left,
        right: buttonRectPosition.right,
        top:
          buttonRectPosition.top +
          topGap +
          buttonRectPosition.height +
          topOffset,
        width: buttonRectPosition.width,
      }

      if (
        buttonRectPosition.top +
          topGap +
          contentHeight +
          topOffset +
          buttonRectPosition.height >
        window.innerHeight
      ) {
        newPosition.top =
          buttonRectPosition.top - topGap - contentHeight - topOffset
      }
    }

    return newPosition
  }

  const toggleDropdown = (event) => {
    event.preventDefault()
    event.stopPropagation()
    onClick?.()

    if (dropdownClosed) {
      onOpenDropdown?.()
      setDropdownClosed((open) => !open)
      setManuallyOpened(true)
    } else {
      handleCloseDropdown()
    }
  }

  useEffect(() => {
    if (isOpen !== undefined) {
      setDropdownClosed(!isOpen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const dropdownContainer = document.getElementById('dropdown-container')
  const dropdownWrapper = document.getElementById(
    hoverColumnId || `editButtonColumn_${id}`
  )

  useEffect(() => {
    if (dropdownWrapper?.style) {
      if (dropdownClosed) {
        dropdownWrapper.style.display = ''
      } else {
        dropdownWrapper.style.display = 'block'
      }
    }
  }, [dropdownClosed, dropdownWrapper])

  const shouldOverridePosition = !manuallyOpened && overridePosition

  const getDropdownContentPosition = () => {
    if (shouldOverridePosition)
      return {
        left: overridePosition.x,
        top: overridePosition.y,
      }

    if (buttonRef.current) {
      return getPosition()
    }

    return { left: 0, right: 0 }
  }

  const newPosition = getDropdownContentPosition()

  const dropdownContentWidth =
    contentRef.current?.getBoundingClientRect?.()?.width ?? 0

  const dropdown = (
    <DropdownContent
      data-testid="dropdown-content"
      ref={contentRef}
      closed={dropdownClosed}
      right={!title}
      position={newPosition}
      alignLeft={alignLeft}
      dropdownContentWidth={dropdownContentWidth}
      attachToDocument={attachToDocument}
      dropdownContentMinWidth={dropdownContentMinWidth}
      isScrollable={isScrollable}
      padding={padding}
      margin={margin}
      contentRight={contentRight}
    >
      {React.Children.map(
        children,
        (child) =>
          child &&
          React.cloneElement(child, {
            closeDropdown: () => {
              handleCloseDropdown()
            },
          })
      )}
    </DropdownContent>
  )

  return (
    <Dropdown ref={wrapperRef} alignSelf={alignSelf}>
      {title ? (
        <Title ref={buttonRef} onClick={toggleDropdown}>
          {typeof title === 'function' ? title(dropdownClosed) : title}
        </Title>
      ) : (
        <IconButton
          ref={buttonRef}
          onClick={toggleDropdown}
          iconFamily={icon[0]}
          icon={icon[1]}
          border={border}
          buttonType={buttonType}
          buttonSize={buttonSize}
          isActive={!dropdownClosed || isActive}
          primary
          aria-label="dropdown-options-button"
          iconBackground={iconBackground}
          direction={direction}
          hoverOpacity={hoverOpacity}
          alignSelf={alignSelf}
        />
      )}
      {attachToDocument && dropdownContainer
        ? ReactDOM.createPortal(dropdown, dropdownContainer)
        : dropdown}
    </Dropdown>
  )
}

const Item = ({
  className,
  label,
  closeDropdown,
  onSelectOption,
  icon,
  color,
  backgroundColor,
  disabled,
  component,
  isSelected,
  closeOnSelectOption,
  ellipsisOnLabel,
  maxWidth,
}) => {
  const onSelect = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      if (closeOnSelectOption) closeDropdown()
      if (onSelectOption) onSelectOption()
    },
    [closeDropdown, onSelectOption, closeOnSelectOption]
  )

  const renderIcon = () => {
    if (component) {
      return component
    }
    if (icon) {
      return <ItemIcon size="lg" style={{ color }} icon={icon} />
    }
    return null
  }

  return (
    <DropdownItem
      className={className}
      type="button"
      aria-label={`${label} Option`}
      style={{ color, backgroundColor }}
      onClick={(event) => {
        if (disabled) {
          event.stopPropagation()
          event.preventDefault()
          return
        }
        onSelect(event)
      }}
      isSelected={isSelected}
      isDisabled={disabled}
      maxWidth={maxWidth}
    >
      {renderIcon()}

      {typeof label === 'string' && label.length > 40 ? (
        <Label style={{ color }}>
          <Tooltip text={label} place="top">
            {label}
          </Tooltip>
        </Label>
      ) : (
        <Label style={{ color }}>{label}</Label>
      )}
    </DropdownItem>
  )
}

const LabelItem = ({ text, className }) => {
  return (
    <NoClickableLabel
      className={className}
      aria-label="non-clickable-read-only-option"
    >
      {text}
    </NoClickableLabel>
  )
}

LabelItem.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}

Item.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  closeDropdown: PropTypes.func,
  onSelectOption: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  component: PropTypes.node,
  closeOnSelectOption: PropTypes.bool,
  isSelected: PropTypes.bool,
  ellipsisOnLabel: PropTypes.bool,
  maxWidth: PropTypes.number,
}

Item.defaultProps = {
  closeDropdown: () => {},
  icon: null,
  color: null,
  backgroundColor: null,
  disabled: false,
  component: null,
  closeOnSelectOption: true,
  isSelected: false,
}

OptionsDropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.array]),
  icon: PropTypes.array,
  buttonType: PropTypes.string,
  id: PropTypes.string,
  hoverColumnId: PropTypes.string,
  buttonSize: PropTypes.string,
  topOffset: PropTypes.number,
  border: PropTypes.bool,
  alignLeft: PropTypes.bool,
  attachToDocument: PropTypes.bool,
  onClick: PropTypes.func,
  dropdownContentMinWidth: PropTypes.string,
  isScrollable: PropTypes.bool,
  padding: PropTypes.string,
  margin: PropTypes.string,
  contentRight: PropTypes.string,
  iconBackground: PropTypes.string,
  isActive: PropTypes.bool,
  direction: PropTypes.string,
  hoverOpacity: PropTypes.string,
  isOpen: PropTypes.bool,
  onCloseDropdown: PropTypes.func,
  alignSelf: PropTypes.string,
  onOpenDropdown: PropTypes.func,
  overridePosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
}

OptionsDropdown.defaultProps = {
  id: null,
  hoverColumnId: null,
  title: null,
  buttonType: 'circle',
  buttonSize: '',
  icon: ['fal', 'ellipsis-stroke-vertical'],
  topOffset: 0,
  border: false,
  alignLeft: false,
  attachToDocument: true,
  iconBackground: null,
  hoverOpacity: '0.4',
}

OptionsDropdown.Item = Item
OptionsDropdown.Label = LabelItem

export default OptionsDropdown
