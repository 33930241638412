import { useMemo, useRef } from 'react'
import useBulkImportMetricValuesModalHandlers from 'containers/Metrics/BulkImportMetricValuesModal/useBulkImportMetricValuesModalHandlers'
import { SortDirectionType } from 'utils/types/common'
import { MetricSortBy } from 'utils/constants/metrics'
import { IndexMetric } from 'utils/types/metricsV2'
import { DELETED_METRIC_EVENT } from 'utils/constants/events'
import { useHighlightText } from 'utils/hooks/useHighlightText'
import { useMetricsSandbox } from 'containers/Metrics/SelectedMetrics/useMetricsSandbox'
import { useEventListener } from 'utils/hooks/useEventListener'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsFounder } from 'selectors/auth'
import { useAllMetricsListContext } from 'utils/context/metrics/AllMetricsListContext'

export type MetricsTableColumn =
  | 'name'
  | 'portfolioHolding'
  | 'source'
  | 'currentValue'
  | 'previousValue'

const useAllMetricsTable = () => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const isFounder = useAppSelector(isActingAsFounder)

  const { metricBulkImportModal, onHideImportCsvModal, onImportCsv } =
    useBulkImportMetricValuesModalHandlers()

  const {
    isMetricInSandbox,
    toggleMetricInSandbox,
    isAddMetricToSandboxDisabled,
  } = useMetricsSandbox()

  const {
    metrics,
    allMetrics,
    isLoading,
    isFetchingNextPage,
    onScreen,
    actions,
    debouncedSearch,
    sortCriteria,
    infiniteScrollRef,
    setSortCriteria,
  } = useAllMetricsListContext()

  const visibleColumns: Record<MetricsTableColumn, boolean> = useMemo(() => {
    return {
      name: true,
      portfolioHolding: !isFounder,
      source: !isFounder,
      currentValue: true,
      previousValue: true,
    }
  }, [isFounder])

  useHighlightText(
    {
      elementClass: '.metric-name',
      text: debouncedSearch,
    },
    [debouncedSearch, metrics]
  )

  const onChangeSortBy = ({
    sortDirection,
    sortId,
  }: {
    sortDirection: SortDirectionType
    sortId: MetricSortBy
  }) => {
    setSortCriteria({ field: sortId, direction: sortDirection })
  }

  const onExportCsv = (metricData: IndexMetric) =>
    actions.exportMetric(metricData)

  useEventListener<{ id: string; hidden: boolean }>(
    DELETED_METRIC_EVENT,
    ({ id, hidden }) => {
      if (hidden) {
        actions.setRemovedMetricsIds((currState) => [...currState, id])
      } else {
        actions.setRemovedMetricsIds((currState) =>
          currState.filter((currId) => currId !== id)
        )
      }
    }
  )

  return {
    allMetrics,
    debouncedSearch,
    isLoading,
    isFetching: isFetchingNextPage,
    onScreen,
    metricBulkImportModal,
    metricActions: {
      ...actions,
      onExportCsv,
      onImportCsv,
      onHideImportCsvModal,
    },
    sortCriteria,
    scrollRef,
    visibleColumns,
    infiniteScrollRef,
    isMetricInSandbox,
    toggleMetricInSandbox,
    isAddMetricToSandboxDisabled,
    onChangeSortBy,
    onHideImportCsvModal,
  }
}

export default useAllMetricsTable
