import { useMutation } from '@apollo/client'
import { useQueryClient } from '@tanstack/react-query'
import Toast from 'components/Toast'
import { useHistory, useParams } from 'react-router-dom'
import { getCurrentGroupId, isActingAsInvestorGroup } from 'selectors/auth'
import { GqlUpdateSubjectDto } from 'types/graphql-schemas/graphql'
import { getInvestmentVehicleSubjectPayload } from 'utils/functions/investorManagement'
import { UPDATE_SUBJECT } from 'utils/gql/mutations/subjects'
import { useSubjectQuery } from 'utils/hooks/queries/subjects/useSubjectQuery'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { subjectsKeys } from 'utils/queries/subjects'
import { InvestmentVehicleFormValues } from 'utils/types/investors'
import { SubjectType } from 'utils/types/subjects'

export const useEditInvestmentVehicle = (onCloseDrawer: () => void) => {
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  const { investmentVehicleId } = useParams<{ investmentVehicleId: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()
  const [updateSubject] = useMutation(UPDATE_SUBJECT)
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const { data, isLoading, refetch } = useSubjectQuery(investmentVehicleId)
  const investmentVehicleSubject = data?.subject

  const editInvestmentVehicle = async (values: InvestmentVehicleFormValues) => {
    const payload = getInvestmentVehicleSubjectPayload<GqlUpdateSubjectDto>({
      investmentVehicleData: values,
      currentGroupId,
      investmentVehicleId,
    })

    try {
      await updateSubject(payload)

      queryClient.invalidateQueries(
        subjectsKeys.byFilters({
          filters: { type: SubjectType.INVESTMENT_VEHICLE },
        })
      )

      onCloseDrawer()

      refetch()

      Toast.displayIntl([
        'investorManagement.success.editInvestmentVehicle',
        { investmentVehicleName: values.name },
      ])
    } catch (err) {
      Toast.displayIntl(
        'investorManagement.errors.editInvestmentVehicle',
        'error'
      )
    }
  }

  const onCreateNewInvestor = (vehicleName: string) => {
    history.replace('/investor-management/investors/create', {
      initialInvestorName: vehicleName,
    })
  }

  return {
    investmentVehicleSubject,
    isLoading,
    isInvestorGroup,
    editInvestmentVehicle,
    onCreateNewInvestor,
  }
}
