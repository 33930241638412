import dayjs from 'dayjs'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHistory } from 'react-router-dom'
import { getCurrentGroupData } from 'selectors/auth'

import InputLabel from 'ui/InputLabel'
import { TransactionInstruments } from 'utils/constants/transactionInstruments'
import {
  TransactionFundType,
  TransactionTypes,
} from 'utils/constants/transactionTypes'
import {
  getNormalizedTransactionInstrument,
  getTransactionInitialFormValues,
  getTransactionTypeLabels,
} from 'utils/functions/transactions'
import { TransactionItem, TransactionUpdate } from 'utils/types/update'
import Separator from 'ui/Separator'

import { useIsPreviewingDraftUpdate } from 'utils/hooks/useIsPreviewingDraftUpdate'
import _ from 'lodash'
import Chip from 'components/Chip'
import { getPortfolioIcon } from 'utils/functions/renderers/renderPortfolioHelper'
import { getImageComponent } from 'containers/UpdatesV2/components/ShareSettingsModal'
import { IconsTypes } from 'containers/UpdatesV2/components/ShareSettingsModal/components/ShareWithDropdown/components/Icons'
import { SubjectMatterType } from 'api/UpdateService'
import useOnClickRedirect from 'utils/hooks/useOnClickRedirect'
import {
  PortfolioTypes,
  getPortfolioTypeFromSubjectMatterType,
} from 'utils/constants/portfolio'
import {
  FundManagerRoutes,
  setRouteConfiguration,
} from 'reducers/breadcrumbSlice'
import {
  Content,
  EmptyDiv,
  Row,
  TransactionContent,
} from './ShowTransactionContent.styles'
import BasicTransactionContent from './TransactionInstrumentsContents/BasicTransactionContent'
import ConvertibleNoteInstrumentContent from './TransactionInstrumentsContents/ConvertibleNoteInstrumentContent'
import DebtCreditInstrumentContent from './TransactionInstrumentsContents/DebtCreditInstrumentContent'
import EquityInstrumentContent from './TransactionInstrumentsContents/EquityInstrumentContent'
import FundInvestmentInstrumentContent from './TransactionInstrumentsContents/FundInvestmentInstrumentContent'
import PreferredEquityInstrumentContent from './TransactionInstrumentsContents/PreferredEquityInstrumentContent'
import SafeInstrumentContent from './TransactionInstrumentsContents/SafeInstrumentContent'
import WarrantsInstrumentContent from './TransactionInstrumentsContents/WarrantsInstrumentContent'

export interface InstrumentContentProps {
  transaction: TransactionItem
}

interface ShowTransactionContentProps {
  update: TransactionUpdate
}
const ShowTransactionContent: React.FC<ShowTransactionContentProps> = ({
  update,
}) => {
  const currentGroup = useAppSelector(getCurrentGroupData)
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const isPreviewingDraft = useIsPreviewingDraftUpdate()

  const transaction = getTransactionInitialFormValues(
    update,
    !isPreviewingDraft
  )
  const investmentVehicle = transaction?.investmentVehicle
  const investor = transaction?.investor

  const isIncludeInPortfoliosVisible =
    (transaction?.portfolios?.length || update.portfolios.length) > 0 &&
    currentGroup!.id === update.groupId

  const { typeLabel, dateLabel, amountLabel } = getTransactionTypeLabels(
    transaction?.type || update.item.transactionType,
    intl
  )

  const { handleInvestmentVehicleClick, handleInvestorClick } =
    useOnClickRedirect()

  const redirectToPortfolioProfile = (
    portfolioType: PortfolioTypes,
    id: string
  ) => {
    dispatch(
      setRouteConfiguration({
        route: FundManagerRoutes.INVESTMENTS,
        initialLinkRedirect: `/investments`,
      })
    )

    history.push(
      `/investments/${portfolioType.toLocaleLowerCase()}/${id}/portfolio`
    )
  }

  const getTransactionContent = () => {
    if (transaction?.type !== TransactionTypes.INVESTMENT) {
      return <BasicTransactionContent text={transaction.description} />
    }

    if (transaction?.transactionFundType === TransactionFundType.INVESTOR) {
      return (
        <>
          <BasicTransactionContent text={transaction.description} />
          {transaction?.type === TransactionTypes.INVESTMENT && (
            <Row gridColumn="1/3">
              <InputLabel marginLeft="1.4rem">
                <FormattedMessage id="transactions.drawdownAgainstCommitment" />
              </InputLabel>
              <Content capitalize>
                <FormattedMessage
                  id={
                    transaction.drawdownAgainstCommitment
                      ? 'common.yes'
                      : 'common.no'
                  }
                />
              </Content>
            </Row>
          )}
        </>
      )
    }

    switch (transaction.instrument) {
      case TransactionInstruments.CONVERTIBLE_NOTE:
        return <ConvertibleNoteInstrumentContent transaction={transaction} />
      case TransactionInstruments.DEBT_CREDIT:
        return <DebtCreditInstrumentContent transaction={transaction} />
      case TransactionInstruments.EQUITY:
        return <EquityInstrumentContent transaction={transaction} />
      case TransactionInstruments.FUND_INVESTMENT:
        return <FundInvestmentInstrumentContent transaction={transaction} />
      case TransactionInstruments.PREFERRED_EQUITY:
        return <PreferredEquityInstrumentContent transaction={transaction} />
      case TransactionInstruments.SAFE:
        return <SafeInstrumentContent transaction={transaction} />
      case TransactionInstruments.WARRANTS:
        return <WarrantsInstrumentContent transaction={transaction} />
      case TransactionInstruments.OTHER:
        return <BasicTransactionContent text={transaction.description} />
      default:
        return null
    }
  }

  const totalPortfolios = isPreviewingDraft
    ? _.uniq(
        [
          ...(transaction?.portfolios
            ? transaction.portfolios.map((portfolio) => ({
                ...portfolio,
                type: getPortfolioTypeFromSubjectMatterType(
                  portfolio.type as unknown as SubjectMatterType
                ),
              }))
            : []),
          ...(transaction?.fundPortfolio
            ? [{ ...transaction.fundPortfolio, type: PortfolioTypes.FUND }]
            : []),
        ].filter(Boolean)
      )
    : _.uniq(transaction?.portfolios || [])

  return (
    <>
      <TransactionContent>
        {isIncludeInPortfoliosVisible && (
          <Row gridColumn="1 / -1">
            <InputLabel marginLeft="1.4rem">
              <FormattedMessage id="transactions.includedIn" />
            </InputLabel>
            <Content display="flex" gap="0.8rem" width="fit-content" cursor>
              {totalPortfolios.map((portfolio) => (
                <Chip
                  key="portfolio_name"
                  maxWidth="15rem"
                  text={portfolio?.name}
                  imageComponent={getPortfolioIcon(portfolio?.type!, false)}
                  canDelete={false}
                  onClick={() =>
                    redirectToPortfolioProfile(portfolio?.type!, portfolio?.id!)
                  }
                />
              ))}
            </Content>
          </Row>
        )}

        {!_.isEmpty(investor) && investor && (
          <Row>
            <InputLabel marginLeft="1.4rem">
              <FormattedMessage id="transactions.investor" />
            </InputLabel>
            <Content
              width="fit-content"
              cursor
              onClick={() =>
                handleInvestorClick(investor.externalSubjectId ?? investor.id)
              }
            >
              <Chip
                key={investor.id}
                maxWidth="15rem"
                text={investor.name}
                imageComponent={getImageComponent(
                  IconsTypes.avatar,
                  investor.name,
                  investor.logo?.url
                )}
                canDelete={false}
              />
            </Content>
          </Row>
        )}

        {!_.isEmpty(investmentVehicle) && investmentVehicle && (
          <Row>
            <InputLabel marginLeft="1.4rem">
              <FormattedMessage id="transactions.investmentVehicle" />
            </InputLabel>
            <Content
              cursor
              width="fit-content"
              onClick={() =>
                handleInvestmentVehicleClick(
                  investmentVehicle.externalSubjectId ?? investmentVehicle.id
                )
              }
            >
              <Chip
                key={investmentVehicle.id}
                maxWidth="15rem"
                text={investmentVehicle.name}
                canDelete={false}
              />
            </Content>
          </Row>
        )}

        {typeLabel && (
          <Row>
            <InputLabel marginLeft="1.4rem">
              <FormattedMessage id="transactions.transactionType" />
            </InputLabel>
            <Content capitalize>{typeLabel}</Content>
          </Row>
        )}

        {transaction.instrument &&
        transaction?.transactionFundType === TransactionFundType.HOLDING &&
        transaction?.type === TransactionTypes.INVESTMENT ? (
          <Row>
            <>
              <InputLabel marginLeft="1.4rem">
                <FormattedMessage id="transactions.instrument" />
              </InputLabel>
              <Content capitalize>
                {getNormalizedTransactionInstrument(
                  transaction.instrument,
                  intl
                )}
              </Content>
            </>
          </Row>
        ) : (
          <EmptyDiv />
        )}

        {_.isDate(transaction?.date) && (
          <Row>
            <>
              <InputLabel marginLeft="1.4rem">{dateLabel}</InputLabel>
              <Content>
                {dayjs(transaction?.date).format('DD MMM, YYYY')}
              </Content>
            </>
          </Row>
        )}

        {transaction?.amount && (
          <Row>
            <InputLabel marginLeft="1.4rem">{amountLabel}</InputLabel>
            <Content>
              $
              <FormattedNumber
                value={transaction?.amount || 0}
                maximumFractionDigits={2}
              />
            </Content>
          </Row>
        )}
      </TransactionContent>

      <Separator />

      {getTransactionContent()}
    </>
  )
}

export default ShowTransactionContent
