import List from 'components/List'
import SetAsMainRow from 'components/List/SetAsMainRow'
import Title from 'components/Title'
import Toast from 'components/Toast'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import GoogleApiService from 'api/GoogleApiService'
import InputError from 'ui/InputError'
import LocationInput, { Location as InputLocation } from 'ui/LocationInput'
import { ErrorType } from 'utils/types/common'
import { GooglePlaceData } from 'utils/types/locations'

import { randomId } from 'utils/functions/number'
import * as Styles from '../common/styles'

export interface CompanyDataValues {
  locations: GooglePlaceData[]
}

interface CompanyLocationProps {
  locations: GooglePlaceData[]
  onChange: (locations: GooglePlaceData[]) => void
}

const CompanyLocation: React.FC<CompanyLocationProps> = ({
  locations,
  onChange,
}) => {
  const intl = useIntl()
  const [locationError, setLocationError] = useState('')

  const setAsMainLocation = async (index: number) => {
    if (index < 0 || index >= locations.length) {
      return
    }

    const newLocations = [...locations]

    ;[newLocations[0], newLocations[index]] = [
      newLocations[index],
      newLocations[0],
    ]

    onChange(newLocations)
  }

  const removeLocation = async (locationId: string) => {
    const newLocations = locations.filter((loc) => loc.id !== locationId)
    onChange(newLocations)
  }

  const createLocation = async (location: InputLocation) => {
    if (locations.some((loc) => loc?.googlePlaceId === location.place_id)) {
      setLocationError(
        intl.formatMessage(
          { id: 'errors.locationAlreadyAdded' },
          { location: location.description }
        )
      )
      return
    }
    setLocationError('')
    try {
      const placeData = await GoogleApiService.getPlaceData(location.place_id)
      const newLocation: GooglePlaceData = {
        id: randomId(),
        googlePlaceId: location.place_id,
        formattedAddress: location.description,
        latitude: placeData.geometry.location.lat(),
        longitude: placeData.geometry.location.lng(),
        types: placeData.types,
      }

      const newLocations = [...locations, newLocation]
      onChange(newLocations)
    } catch (errors) {
      Toast.display(
        intl.formatMessage(
          { id: 'errors.creatingItemError' },
          { item: intl.formatMessage({ id: 'general.location' }) }
        ),
        'error'
      )
    }
  }

  return (
    <Styles.Item>
      <Title title={intl.formatMessage({ id: 'editCompany.locations' })} />
      <LocationInput
        placeholder={intl.formatMessage({
          id: 'editCompany.addLocationPlaceholder',
        })}
        loadingMessage={intl.formatMessage({
          id: 'editCompany.loadingMessage',
        })}
        onChange={(loc) => createLocation(loc.value)}
      />
      <InputError
        type={ErrorType.ERROR}
        hasError={!!locationError}
        displayOnError
      >
        {locationError}
      </InputError>
      <List
        elements={locations.map((location, index) => {
          return (
            <SetAsMainRow
              key={location.id}
              text={location?.formattedAddress ?? ''}
              isMain={!index}
              setAsMain={() => setAsMainLocation(index)}
              removeItem={() => removeLocation(location.id)}
            />
          )
        })}
        striped={false}
        rowHeightAuto
        rowWidthAuto
      />
    </Styles.Item>
  )
}

export default CompanyLocation
