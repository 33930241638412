import { FormattedMessage } from 'react-intl'

import Table from 'components/ExpandableTable/Table/Table'
import ResendIUEModal from 'components/ResendIUEBox/components/ResendIUEModal'
import UpdateLayout from 'components/UpdateLayout'
import Analytics from 'containers/Analytics'
import ShowUpdate from 'containers/Updates/ShowUpdate/ShowUpdate'

import ShowUpdateContent from 'containers/UpdatesV2/ShowUpdateContent'
import { UpdateTypes } from 'utils/constants/updates'
import { getUniqueUserNames } from 'containers/UpdatesV2/utils'
import { isIndexFileContent } from 'utils/functions/files'
import BreadcrumbActionPortal from 'components/Breadcrumb/BreadcrumbActionPortal'
import ShowEmailActions from 'components/Breadcrumb/UpdateActions/ShowEmailActions'
import {
  recipientsTableColumns,
  recipientsTableExpandedColumns,
} from 'utils/functions/renderers/renderUpdatesHelper'

import RecipientsSummary from './RecipientsSummary'
import styles from './ShowEmail.module.scss'
import { useShowEmail } from './useShowEmail'
import ReshareHistoryWrapper from '../ReshareHistory/ReshareHistoryWrapper'

const ShowEmail = () => {
  const {
    update,
    deleteUpdate,
    redirectToReshareView,
    resharesByToken,
    resharesWithMeOrMyGroup,
    recipientsData,
    recipientsDataLoading,
    updatePermissions,
    onChangeSortBy,
    redirectToEditView,
    additionalRecipientsModal,
    toggleAdditionalRecipientsModal,
    duplicateEmail,
    tabs,
    currentGroupIsOwnerOfEmail,
    setActiveTabIndex,
  } = useShowEmail()

  if (!update) {
    return null
  }

  return (
    <>
      <BreadcrumbActionPortal>
        <ShowEmailActions
          update={update}
          hasEditPermissions={updatePermissions.hasEditPermissions}
          canReshare={updatePermissions.canReshare}
          toggleAdditionalRecipientsModal={toggleAdditionalRecipientsModal}
          duplicateEmail={duplicateEmail}
        />
      </BreadcrumbActionPortal>

      <ShowUpdate
        update={update}
        updateType={UpdateTypes.IUE}
        updatePermissions={updatePermissions}
        tabs={tabs}
        dropdownOptions={undefined}
        arrowNavigationDisabled={additionalRecipientsModal}
        onClickTab={setActiveTabIndex}
        onDeleteUpdate={deleteUpdate}
        redirectToEditView={redirectToEditView}
        redirectToReshareView={redirectToReshareView}
        resharesByToken={resharesByToken}
        resharesWithMeOrMyGroup={resharesWithMeOrMyGroup}
      >
        <UpdateLayout.Tab id="general">
          <ReshareHistoryWrapper
            resharesByToken={resharesByToken}
            resharesWithMeOrMyGroup={resharesWithMeOrMyGroup}
          >
            <ShowUpdateContent
              uniqueUserNames={getUniqueUserNames(resharesByToken)}
              update={update}
              updateType={UpdateTypes.IUE}
            />
          </ReshareHistoryWrapper>
        </UpdateLayout.Tab>

        {currentGroupIsOwnerOfEmail && (
          <UpdateLayout.Tab id="recipients">
            <div>
              <RecipientsSummary data={recipientsData} />

              <Table
                keyPath="id"
                data={recipientsData?.data}
                columns={recipientsTableColumns(styles)}
                expandedRowsColumns={recipientsTableExpandedColumns(styles)}
                noResultsLabel={
                  <FormattedMessage id="showEmail.noRecipients" />
                }
                loading={recipientsDataLoading}
                showExpandableColumn
                isRowExpandable={(rowData) =>
                  rowData.recipientEmailStatuses?.length > 0
                }
                getExpandableData={(rowData) => rowData.recipientEmailStatuses}
                onChangeSortBy={onChangeSortBy}
                expandOnRowClick
              />
            </div>
          </UpdateLayout.Tab>
        )}

        {!!updatePermissions.hasEditPermissions && (
          <UpdateLayout.Tab id="analytics" key="analytics">
            <Analytics
              updateId={update.id}
              updateCreationDate={update.createdAt}
              totalViews={update.totalViews}
              uniqueViews={update.uniqueViews}
              attachments={update.contents.filter(isIndexFileContent)} // TODO review this filter
            />
          </UpdateLayout.Tab>
        )}
      </ShowUpdate>
      <ResendIUEModal
        emailId={update.item.id}
        openModal={additionalRecipientsModal}
        setOpenModal={toggleAdditionalRecipientsModal}
      />
    </>
  )
}

export default ShowEmail
