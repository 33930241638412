import { DefaultRoutes, RoutesType } from 'reducers/breadcrumbSlice'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'
import EmailsBreadcrumb from '../FundManager/EmailsBreadcrumb'
import UpdatesBreadcrumb from '../FundManager/UpdatesBreadcrumb'
import NewTabBreadcrumb from '../NewTab/NewTabBreadcrumb'
import FounderUpdatesBreadcrumb from '../Founder/FounderUpdatesBreadcrumb'
import useBreadcrumbNavigation from '../useBreadcrumbNavigation'

const defaultComponent = {
  updates: UpdatesBreadcrumb,
  emails: EmailsBreadcrumb,
  founderUpdates: FounderUpdatesBreadcrumb,
  newTab: NewTabBreadcrumb,
}

interface Props {
  baseRoute: RoutesType
  isFounder?: boolean
}
const useDisplayDefaultBreadcrumbNavigation = ({
  baseRoute,
  isFounder = false,
}: Props) => {
  const isInvestor = useAppSelector(isActingAsInvestorGroup)

  const {
    update,
    entityToShow,
    url,
    isShowingCompany,
    initialLinkRedirect,
    isCreatingNewUpdate,
    getAvatar,
  } = useBreadcrumbNavigation()

  const defaultNavigationComponentFunc = () => {
    switch (baseRoute) {
      case DefaultRoutes.UPDATES: {
        if (isFounder) {
          return defaultComponent.founderUpdates({
            update,
            isCreatingNewUpdate,
          })
        }
        return defaultComponent.updates({
          update,
          entityToShow,
          url,
          isShowingCompany,
          initialLinkRedirect,
          isCreatingNewUpdate,
          getAvatar,
        })
      }
      case DefaultRoutes.EMAILS:
        return defaultComponent.emails({
          update,
          isCreatingNewUpdate,
        })
      case DefaultRoutes.METRICS:
      case DefaultRoutes.FILES:
      case DefaultRoutes.CHAT:
        return defaultComponent.newTab({
          update,
          entityToShow,
          url,
          isShowingCompany,
          isCreatingNewUpdate,
          isInvestor,
          isFounder,
          getAvatar,
        })
      default:
        return null
    }
  }

  return { defaultNavigationComponentFunc }
}

export default useDisplayDefaultBreadcrumbNavigation
